import React, { forwardRef } from 'react';
import {
  useGetUserProfilePictureQuery,
  useGetUserQuery,
} from '@energy-stacks/broker/feature-users-data';
import { getUserInitials } from '@energy-stacks/shared';
import { Avatar, AvatarProps, useTheme } from '@mui/material';

export const BrokerLoggedInUserAvatar = forwardRef<HTMLDivElement, AvatarProps>(
  ({ sx, onClick, onMouseEnter }, ref) => {
    const { data: loggedUser } = useGetUserQuery();
    const userInitials = getUserInitials(loggedUser?.displayName);
    const { data: loggedUserPicture } = useGetUserProfilePictureQuery();
    const theme = useTheme();

    return (
      <Avatar
        data-testid={`${userInitials}Avatar`}
        alt={userInitials}
        src={loggedUserPicture || userInitials}
        ref={ref}
        sx={{
          ...theme.typography.body1,
          backgroundColor: 'primary.200',
          cursor: 'pointer',
          ...sx,
        }}
        color="inherit"
        onClick={onClick}
        onMouseEnter={onMouseEnter}
      >
        {userInitials}
      </Avatar>
    );
  }
);
