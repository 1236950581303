import { BiogassFactoryIcon2 } from '@energy-stacks/fleet/feature-business-accounts';
import { JobModel } from '@energy-stacks/fleet/feature-jobs-data';
import {
  ContainerIcon,
  ProductIcon,
  RoutePoint,
} from '@energy-stacks/fleet/shared';

type JobLocationIconProps = {
  location: RoutePoint;
  jobType?: JobModel['jobType'];
  locationType?: 'origin' | 'destination';
};

export const JobLocationIcon: React.FC<JobLocationIconProps> = ({
  location,
  jobType,
  locationType,
}) => {
  const isPlant =
    (jobType === 'OUTBOUND' && locationType === 'origin') ||
    (jobType === 'INBOUND' && locationType === 'destination');

  return isPlant ? (
    <ContainerIcon>
      <BiogassFactoryIcon2 />
    </ContainerIcon>
  ) : (
    <ProductIcon type={location.type} risk={location.risk} />
  );
};
