import React from 'react';
import { BrokerFeature } from './brokerFeature';
import { useBrokerFeatureGuard } from './useBrokerFeatureGuard';

export const BrokerFeatureRoleGuard: React.FC<{
  children: React.ReactNode;
  feature: BrokerFeature;
}> = ({ children, feature }) => {
  const hasAccess = useBrokerFeatureGuard(feature);

  if (!hasAccess) {
    return null;
  }

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
};
