import { PlantIdSelect } from '@energy-stacks/fleet/feature-business-accounts';
import { LanguageSwitcher } from '@energy-stacks/shared';
import { useAppSelector } from '@energy-stacks/store';
import { Divider, Stack } from '@mui/material';
import { Box } from '@mui/material';
import { Sidebar as CoreSidebar } from '@energy-stacks/core/sidebar';
import { BusinessAccountsSidebarItem } from '@energy-stacks/fleet/feature-business-accounts';
import { ToursSidebarItem } from '@energy-stacks/fleet/feature-tours';
import { VehiclesSidebarItem } from '@energy-stacks/fleet/feature-vehicles';
import { DarkLogo } from '@energy-stacks/theme';
import { useOidc } from '@axa-fr/react-oidc';
import {
  ESProfileSection,
  ESProfileSectionListItem,
  ESRoutes,
} from '@energy-stacks/shared';
import { IconLogout } from '@tabler/icons-react';
import { useTranslation } from 'react-i18next';
import { CreateButtonWithPopup } from '@energy-stacks/fleet-is/feature-intelligent-scheduling';
import { DashboardDrawerItem } from '@energy-stacks/broker/feature-dashboard';
import { ChargingScheduleDrawerItem } from '@energy-stacks/fleet-is/feature-charging-schedule';
import { ChargingStationsDrawerItem } from '@energy-stacks/broker/feature-charging-stations';
import { useGetUserQuery } from '@energy-stacks/broker/feature-users-data';

export const Sidebar = () => {
  return (
    <CoreSidebar
      logo={<DarkLogo />}
      bottom={<SidebarBottom />}
      items={
        <>
          <CreateButtonWithPopup />
          <Box sx={{ marginY: 2 }} />
          <DashboardDrawerItem />
          <ChargingScheduleDrawerItem />
          <ToursSidebarItem />
          <ChargingStationsDrawerItem />
          <VehiclesSidebarItem />
          <BusinessAccountsSidebarItem />
        </>
      }
    />
  );
};

export const SidebarBottom = () => {
  const sidebarOpened = useAppSelector((state) => state.sidebar.sidebarOpened);
  const themeMode = useAppSelector((state) => state.theme.themeState);

  return (
    <>
      <Stack
        direction={sidebarOpened ? 'row' : 'column-reverse'}
        justifyContent="space-between"
        alignItems="center"
        width="100%"
        gap={1}
      >
        <LanguageSwitcher
          themeMode={themeMode}
          collapsed={!sidebarOpened}
          supportedLanguages={['en']}
        />
        <PlantIdSelect />
      </Stack>
      <Divider sx={{ width: '100%', my: 4 }} />
      <ProfileSection />
    </>
  );
};

const ProfileSection = () => {
  const { logout } = useOidc();
  const themeMode = useAppSelector((state) => state.theme.themeState);
  const sidebarOpened = useAppSelector((state) => state.sidebar.sidebarOpened);
  const { data: user } = useGetUserQuery();
  const [tShared] = useTranslation('shared');

  return (
    <ESProfileSection
      userName={user?.displayName || ''}
      emailAddress={user?.emailAddress || ''}
      themeMode={themeMode}
      collapsed={!sidebarOpened}
    >
      <ESProfileSectionListItem
        listItemText={tShared('logout')}
        testId="popupLogoutListItem"
        onClick={() => {
          logout(ESRoutes.Root);
        }}
        Icon={<IconLogout stroke={1.5} size={20} />}
      />
    </ESProfileSection>
  );
};
