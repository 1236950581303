export type Status =
  | 'Accepted'
  | 'Blocked'
  | 'Expired'
  | 'Invalid'
  | 'ConcurrentTx';

export type Reason =
  | 'DeAuthorized'
  | 'EmergencyStop'
  | 'EVDisconnected'
  | 'HardReset'
  | 'Local'
  | 'Other'
  | 'PowerLoss'
  | 'Reboot'
  | 'Remote'
  | 'SoftReset'
  | 'UnlockCommand';

/* export type Context = Interruption.Begin, Interruption.End, Other, Sample.Clock, Sample.Periodic, Transaction.Begin, Transaction.End, Trigger */
export type Format = 'Raw' | 'SignedData';
export type Phase =
  | 'L1'
  | 'L2'
  | 'L3'
  | 'N'
  | 'L1-N'
  | 'L2-N'
  | 'L3-N'
  | 'L1-L2'
  | 'L2-L3'
  | 'L3-L1';

export type Location = 'Body' | 'Cable' | 'EV' | 'Inlet' | 'Outlet';
export type Unit =
  | 'Wh'
  | 'kWh'
  | 'varh'
  | 'kvarh'
  | 'W'
  | 'kW'
  | 'VA'
  | 'kVA'
  | 'var'
  | 'kvar'
  | 'A'
  | 'V'
  | 'Celsius'
  | 'Fahrenheit'
  | 'K'
  | 'Percent';

export type Context =
  | 'Interruption.Begin '
  | 'Interruption.End'
  | 'Other'
  | 'Sample.Clock'
  | 'Sample.Periodic'
  | 'Transaction.Begin '
  | 'Transaction.End'
  | 'Trigger';

export type Measurand =
  | 'Current.Export'
  | 'Current.Import'
  | 'Current.Offered'
  | 'Energy.Active.Export.Register'
  | 'Energy.Active.Import.Register'
  | 'Energy.Reactive.Export.Register'
  | 'Energy.Reactive.Import.Register'
  | 'Energy.Active.Export.Interval'
  | 'Energy.Active.Import.Interval'
  | 'Energy.Reactive.Export.Interval'
  | 'Energy.Reactive.Import.Interval'
  | 'Frequency'
  | 'Power.Active.Export'
  | 'Power.Active.Import'
  | 'Power.Factor'
  | 'Power.Offered'
  | 'Power.Reactive.Export'
  | 'Power.Reactive.Import'
  | 'RPM'
  | 'SoC'
  | 'Temperature'
  | 'Voltage';

interface SampledValueModelDto {
  value: string;
  context: Context;
  format: Format;
  measurand: Measurand;
  phase: Phase;
  location: Location;
  unit: Unit;
}

interface TransactionDataModelDto {
  timestamp: string;
  sampledValue: SampledValueModelDto[];
}

interface MeterValuesModelDto {
  timestamp: string;
  sampledValue: SampledValueModelDto[];
}

export interface BrokerTransactionDto {
  identityKey: string;
  transactionId: number;
  evseId: string;
  connectorId: number;
  reservationId: number | null;
  idTagStart: string;
  idTagInfoStart: {
    expiryDate: string;
    parentIdTag: string;
    status: Status;
  };
  idTagStop: string;
  reason: Reason;
  transactionData: TransactionDataModelDto[] | null;
  meterValues: MeterValuesModelDto[] | null;
  meterStart: number;
  meterStop: number;
  timestampStart: string;
  timestampStop: string;
  duration: string;
  consumption: string;
  tokenUid: string;
  transactionStatus: TransactionStatusEnum;
  transactionFinishReason: TransactionFinishReasonEnum;
}

export enum TransactionStatusEnum {
  successFinish = 'successFinish',
  errorFinish = 'errorFinish',
  charging = 'charging',
}

export enum TransactionFinishReasonEnum {
  deAuthorized = 'deAuthorized',
  emergencyStop = 'emergencyStop',
  eVDisconnected = 'eVDisconnected',
  hardReset = 'hardResetReason',
  local = 'local',
  other = 'other',
  powerLoss = 'powerLoss',
  reboot = 'reboot',
  remote = 'remote',
  softReset = 'softResetReason',
  unlockCommand = 'unlockCommand',
}
