import { useAppSelector } from '@energy-stacks/fleet/store';
import { useCallback } from 'react';

export const useRemovePlantIdPrefix = () => {
  const selectedPlantId = useAppSelector(
    (state) => state.plant.selectedPlantId
  );

  return useCallback(
    (stringToTransform: string): string => {
      const plantIdPrefix = `${selectedPlantId} - `;
      if (!selectedPlantId || !stringToTransform.includes(plantIdPrefix)) {
        return stringToTransform;
      }

      return stringToTransform.replace(plantIdPrefix, '');
    },
    [selectedPlantId]
  );
};
