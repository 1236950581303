import {
  EVSE_ID_SPECIAL_CHARACTERS_REGEX,
  numberValidityTest,
} from '@energy-stacks/shared';
import * as yup from 'yup';

export const editConnectorFormSchema = yup.object().shape({
  type: yup.object({
    label: yup.string(),
    value: yup.string(),
  }),
  evseId: yup
    .string()
    .nullable()
    .max(48, 'evseIdTooLong')
    .matches(EVSE_ID_SPECIAL_CHARACTERS_REGEX, {
      message: 'evseIdPatternFormatError',
      excludeEmptyString: true,
    }),
  connectorFormat: yup.object({
    label: yup.string(),
    value: yup.string(),
  }),
  powerType: yup.object({
    label: yup.string(),
    value: yup.string(),
  }),
  maxVoltage: numberValidityTest.optional(),
  maxAmperage: numberValidityTest.optional(),
  maxPower: numberValidityTest.optional(),
});
