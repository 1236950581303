import { Stack, Typography, Chip } from '@mui/material';
import { IconTruck } from '@tabler/icons-react';
import { useEffect, useState } from 'react';

import { vehicleColors } from './vehicleColors';

interface VehicleChipProps {
  title: string;
  backgroundColor?: string;
  index: number; // Pass index in order to access unique color
}

const useChipWidth = () => {
  const [chipWidth, setChipWidth] = useState<number>(0);
  useEffect(() => {
    const chips = document.querySelectorAll('.MuiChip-root');

    const maxChipWidth = Math.max(
      ...Array.from(chips).map((chip) => chip.getBoundingClientRect().width)
    );

    setChipWidth(maxChipWidth);
  }, []);

  return chipWidth;
};

export const VehicleChip: React.FC<VehicleChipProps> = ({
  title,
  index,
  backgroundColor,
}) => {
  const chipWidth = useChipWidth();
  return (
    <Chip
      sx={{
        borderRadius: 32,
        border: '1.5px solid',
        borderColor: vehicleColors[index % vehicleColors.length],
        backgroundColor: backgroundColor || 'transparent',
        color: 'primary.800',
        minWidth: chipWidth,
        '& .MuiChip-label': {
          paddingX: '16px',
        },
      }}
      label={
        <Stack direction="row" alignItems="center" gap={1}>
          <IconTruck />
          <Typography color="grey.900" fontWeight={700}>
            {title}
          </Typography>
        </Stack>
      }
    />
  );
};
