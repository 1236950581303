import { useTranslation } from 'react-i18next';
import { Chip } from '@mui/material';
import {
  TourStatus,
  tourStatuses,
} from '@energy-stacks/fleet/feature-tours-data';

interface TourStatusChipProps {
  status: TourStatus;
}

interface TourStatusColors {
  bgColor: string;
  color: string;
}

const statusToChipInfoMap: Record<TourStatus, TourStatusColors> = {
  ENROUTE: {
    bgColor: 'primary.light',
    color: 'primary.800',
  },
  PLANNED: {
    bgColor: 'secondary.light',
    color: 'secondary.dark',
  },
  DELAYED: {
    bgColor: 'warning.light',
    color: 'warning.dark',
  },
  FAILED: {
    bgColor: 'orange.light',
    color: 'orange.dark',
  },
  DONE: {
    bgColor: 'success.light',
    color: 'success.dark',
  },
  INCOMPLETE: {
    bgColor: 'grey.100',
    color: 'grey.500',
  },
  UNKNOWN: {
    bgColor: 'grey.200',
    color: 'grey.900',
  },
};

export const TourStatusChip: React.FC<TourStatusChipProps> = ({ status }) => {
  const { bgColor, color } = statusToChipInfoMap[status];
  const [t] = useTranslation('tours');

  return (
    <Chip
      size="medium"
      label={
        tourStatuses.includes(status)
          ? t(`tourStatuses.${status}`)
          : status.toLowerCase()
      }
      sx={{
        backgroundColor: bgColor,
        color,
        fontWeight: 500,
        '&.MuiChip-root': {
          cursor: 'inherit',
          textTransform: 'capitalize',
        },
      }}
    />
  );
};
