import { ESButton } from '@energy-stacks/core/ui';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as ErrorImage } from './error.svg';
import { FallbackProps } from 'react-error-boundary';

interface ErrorFallbackProps extends FallbackProps {
  navigationLink: string;
}

export const ErrorFallback: React.FC<ErrorFallbackProps> = ({
  resetErrorBoundary,
  navigationLink,
}) => {
  const [t] = useTranslation('shared');
  const navigate = useNavigate();

  return (
    <Box textAlign="center" mt={20}>
      <ErrorImage />
      <Typography variant="h4" mb={3} mt={13}>
        {t('errorFallbackTitle')}
      </Typography>
      <Typography variant="subtitle1" color="text.secondary">
        {t('errorFallbackSubtitle1')}
      </Typography>
      <Typography variant="subtitle1" color="text.secondary" mb={11}>
        {t('errorFallbackSubtitle2')}
      </Typography>
      <ESButton
        onClick={() => {
          navigate(navigationLink);
          resetErrorBoundary();
        }}
      >
        {t('errorFallbackAction')}
      </ESButton>
    </Box>
  );
};
