export const jobStatusesDto = [
  'TO_DO',
  'PENDING',
  'PLANNED',
  'ENROUTE',
  'DELIVERED',
  'FAILED',
  'RETURNED',
  'CANCELLED',
] as const;

export type JobStatusDto = (typeof jobStatusesDto)[number];
