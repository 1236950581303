import {
  DEFAULT_PAGINATION_CONFIG,
  ESTable,
  ESTableBodyCell,
  ESTableBodyCompose,
  ESTableBodyRow,
  ESTableHead,
  ESTablePagination,
  ESTableWrapper,
  ESTextButton,
  Sort,
  useFitRows,
} from '@energy-stacks/core/ui';
import {
  ExpandButton,
  formatDateTime,
  NoTableData,
  TableDateTimeFilter,
  CollapsibleRawContentRow,
  useChargingStationIdentityKey,
  RefetchUpdatesButton,
  toPayloadDate,
  TableColumnSelect,
  ExportButton,
  CsvIcon,
  ZipIcon,
} from '@energy-stacks/shared';
import {
  Box,
  Stack,
  MenuItem,
  ListItemText,
  LinearProgress,
} from '@mui/material';
import {
  createColumnHelper,
  ExpandedState,
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  PaginationState,
  Row,
  SortingState,
  useReactTable,
} from '@tanstack/react-table';
import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { DateRange } from '@energy-stacks/core/date-range-picker';
import {
  OcppAction,
  OcppMessage,
  MessageDirectionEnum,
  OcppMessageLogsModel,
  MessageTypeEnum,
  useLazyGetOcppLogsCsvQuery,
  useLazyGetOcppLogsZipQuery,
  mapOcppActionToOcppProcedure,
  messageTypeToEnum,
} from '@energy-stacks/broker/feature-ocpp-message-logs-data';
import { MessageDirection } from '../../logs/MessageDirection';
import { OcppMessageLogsOcppActionFilter } from '../../logs/OcppMessageLogsOcppActionFilter';
import { OcppMessageLogsMessageDirectionFilter } from '../../logs/OcppMessageLogsMessageDirectionFilter';
import { isAfter, parseISO } from 'date-fns';
import { OcppMessageLogsMessageTypeFilter } from '../../logs/OcppMessageLogsMessageTypeFilter';
import { useStompTopic } from '@energy-stacks/shared';
import { MessageTypeOption } from '../../logs/MessageTypeOption';

export type FilterActionType =
  | 'FILTER_DATE'
  | 'FILTER_MESSAGE_DIRECTION'
  | 'FILTER_OCPP_ACTION'
  | 'FILTER_MESSAGE_TYPE'
  | 'RESET';

export interface FiltersState {
  dateRange?: DateRange | undefined;
  messageTypeFilter?: MessageTypeEnum[] | undefined;
  messageDirectionFilter?: MessageDirectionEnum[] | undefined;
  ocppActionFilter?: OcppAction[] | undefined;
}

export interface FilterAction {
  type: FilterActionType;
  payload?: Partial<FiltersState>;
}

type OcppMessageLogsTableProps = {
  tableId?: string;
  ocppMessageLogs: OcppMessageLogsModel | undefined;
  onFiltersChange: (filters: FilterAction) => void;
  filtersState: FiltersState;
  pagination: PaginationState;
  onPaginationChange: Dispatch<SetStateAction<PaginationState>>;
  sorting: SortingState;
  onSortingChange: Dispatch<SetStateAction<SortingState>>;
  sortOrder: Sort;
  onRefetch: () => void;
  isFetching: boolean;
  logsAccessDatetime: Date;
  testId?: string;
};

export const OcppMessageLogsTableV201: React.FC<OcppMessageLogsTableProps> = ({
  tableId = 'ocppMessageLogs',
  ocppMessageLogs,
  onPaginationChange,
  onSortingChange,
  sorting,
  pagination,
  onFiltersChange,
  filtersState,
  onRefetch,
  isFetching,
  logsAccessDatetime,
  testId,
}) => {
  const [expanded, setExpanded] = useState<ExpandedState>({});
  const { t } = useTranslation('chargingStations');
  const [sharedT] = useTranslation('shared');
  const [rowSeen, setRowSeen] = useState<Record<string, boolean>>({});
  const identityKey = useChargingStationIdentityKey();
  const [ocppLogsCsvDownload, { isFetching: isFetchingCsv }] =
    useLazyGetOcppLogsCsvQuery();
  const [ocppLogsZipDownload, { isFetching: isFetchingZip }] =
    useLazyGetOcppLogsZipQuery();

  const columnHelper = createColumnHelper<OcppMessage>();
  const columns = [
    {
      id: 'expand',
      meta: { isActionable: true },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      header: ({ table }: { table: any }) => (
        <Box sx={{ ml: '11px' }}>
          <ExpandButton
            onToggleExpand={table.getToggleAllRowsExpandedHandler()}
            expanded={table.getIsAllRowsExpanded()}
          />
        </Box>
      ),
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      cell: ({ row }: { row: any }) => {
        return (
          <Box sx={{ ml: '11px' }}>
            <ExpandButton
              onToggleExpand={() => row.toggleExpanded()}
              expanded={row.getIsExpanded()}
            />
          </Box>
        );
      },
    },
    columnHelper.accessor('timestamp', {
      sortingFn: 'datetime',
      header: () => t('timestamp'),
      footer: (props) => props.column.id,
      cell: (info) => (
        <Box>
          <span>{formatDateTime(info.getValue()) ?? '-'}</span>
        </Box>
      ),
    }),
    columnHelper.accessor('ocppAction', {
      header: () => t('ocppAction'),
      footer: (props) => props.column.id,
      cell: (info) => (
        <Stack direction="row" alignItems="center" gap={2}>
          <MessageTypeOption
            messageTypeEnum={messageTypeToEnum[info.row.original.messageType]}
            hideText
          />
          {t(info.getValue())}
        </Stack>
      ),
      enableSorting: false,
    }),
    columnHelper.accessor('messageDirection', {
      header: () => t('messageDirection'),
      footer: (props) => props.column.id,
      cell: (info) => <MessageDirection messageDirection={info.getValue()} />,
      enableSorting: false,
    }),
  ];

  const instance = useReactTable({
    data: ocppMessageLogs?.messages ?? [],
    columns,
    state: {
      pagination,
      sorting,
      expanded,
    },
    getRowId: (row) => row.messageUuid,
    getCoreRowModel: getCoreRowModel(),
    onPaginationChange,
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onSortingChange,
    getSortedRowModel: getSortedRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    onExpandedChange: setExpanded,
    enableExpanding: true,
    manualExpanding: true,
    manualPagination: true,
    pageCount: ocppMessageLogs?.totalPages,
    enableColumnResizing: false,
  });

  const { rowsPerPageOptions } = useFitRows(
    instance,
    ocppMessageLogs?.totalElements ?? 0,
    68.5,
    83
  );

  const rows = instance.getRowModel().rows;
  const hasRows = rows.length !== 0;
  const filteredRows = instance.getFilteredRowModel().rows;

  const handleDateRangeFilterChange = useCallback(
    (dateRange: DateRange) => {
      onFiltersChange({ type: 'FILTER_DATE', payload: { dateRange } });
      instance.setPageIndex(DEFAULT_PAGINATION_CONFIG.page);
    },
    [instance, onFiltersChange]
  );

  const handleDateRangeFilterCleared = useCallback(() => {
    onFiltersChange({ type: 'FILTER_DATE', payload: undefined });
    instance.setPageIndex(DEFAULT_PAGINATION_CONFIG.page);
  }, [instance, onFiltersChange]);

  const handleMessageDirectionFilterChange = useCallback(
    (messageDirectionFilter: MessageDirectionEnum[] | undefined) => {
      onFiltersChange({
        type: 'FILTER_MESSAGE_DIRECTION',
        payload: { messageDirectionFilter },
      });
      instance.setPageIndex(DEFAULT_PAGINATION_CONFIG.page);
    },
    [instance, onFiltersChange]
  );
  const handleMessageTypeFilterChange = useCallback(
    (messageTypeFilter: MessageTypeEnum[] | undefined) => {
      onFiltersChange({
        type: 'FILTER_MESSAGE_TYPE',
        payload: { messageTypeFilter },
      });
      instance.setPageIndex(DEFAULT_PAGINATION_CONFIG.page);
    },
    [instance, onFiltersChange]
  );

  const handleOcppActionFilterChange = useCallback(
    (ocppActionFilter: OcppAction[] | undefined) => {
      onFiltersChange({
        type: 'FILTER_OCPP_ACTION',
        payload: { ocppActionFilter },
      });
      instance.setPageIndex(DEFAULT_PAGINATION_CONFIG.page);
    },
    [instance, onFiltersChange]
  );

  const hasFilters = useMemo(
    () => Object.values(filtersState).some((filter) => Boolean(filter)),
    [filtersState]
  );

  const clearAllFilters = useCallback(() => {
    onFiltersChange({
      type: 'RESET',
    });
    instance.setPageIndex(DEFAULT_PAGINATION_CONFIG.page);
  }, [instance, onFiltersChange]);

  const isRowHighlighted = (row: Row<OcppMessage>): boolean => {
    if (rowSeen[row.original.messageUuid]) {
      return false;
    }
    const originalDate = parseISO(row.original.timestamp);
    return isAfter(originalDate, logsAccessDatetime);
  };

  return (
    <>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="flex-start"
      >
        <Stack direction="row" gap={3} alignItems="center" marginBottom={6}>
          <TableDateTimeFilter
            title={t('timeRange')}
            isActive={filtersState.dateRange !== undefined}
            defaultDateRange={filtersState.dateRange}
            onDateRangeApplied={handleDateRangeFilterChange}
            onDateRangeCleared={handleDateRangeFilterCleared}
          />
          <OcppMessageLogsOcppActionFilter
            ocppActionFilter={filtersState.ocppActionFilter}
            onOcppActionFilterChange={handleOcppActionFilterChange}
          />
          <OcppMessageLogsMessageDirectionFilter
            messageDirectionFilter={filtersState.messageDirectionFilter}
            onMessageDirectionFilterChange={handleMessageDirectionFilterChange}
          />
          <OcppMessageLogsMessageTypeFilter
            messageTypeFilter={filtersState.messageTypeFilter}
            onMessageTypeFilterChange={handleMessageTypeFilterChange}
          />
          {hasFilters ? (
            <ESTextButton onClick={clearAllFilters}>
              {sharedT('clearAll')}
            </ESTextButton>
          ) : null}
        </Stack>
        <Stack
          flexDirection="row"
          columnGap={2}
          sx={{ ml: 2 }}
          alignItems="center"
        >
          <RefetchLogsButton
            isFetching={isFetching}
            onRefetch={() => {
              // Invalidate all already fetched rows but not seen
              const afterLogsAccess =
                ocppMessageLogs?.messages.filter((message) =>
                  isAfter(parseISO(message.timestamp), logsAccessDatetime)
                ) ?? [];
              setRowSeen((seen) => ({
                ...seen,
                ...afterLogsAccess.reduce((acc, message) => {
                  acc[message.messageUuid] = true;
                  return acc;
                }, {} as { [key: string]: boolean }),
              }));

              onRefetch();
            }}
          />
          <Box sx={{ marginLeft: 'auto', px: 2 }}>
            <TableColumnSelect instance={instance} />
          </Box>

          <ExportButton
            testId="logsExportButton"
            renderPopover={(closePopover) => {
              return (
                <Box sx={{ position: 'relative' }}>
                  {isFetchingCsv || isFetchingZip ? (
                    <Box
                      sx={{
                        position: 'absolute',
                        top: 0,
                        width: '100%',
                        zIndex: 1,
                      }}
                    >
                      <LinearProgress />
                    </Box>
                  ) : null}
                  <Box>
                    <MenuItem
                      data-testid="csvDownloadOption"
                      disabled={isFetchingCsv}
                      onClick={() => {
                        ocppLogsCsvDownload({
                          identityKey: identityKey,
                          params: {
                            timeFrom: filtersState?.dateRange?.startDate
                              ? toPayloadDate(
                                  filtersState?.dateRange?.startDate
                                )
                              : undefined,
                            timeTo: filtersState?.dateRange?.endDate
                              ? toPayloadDate(filtersState?.dateRange?.endDate)
                              : undefined,
                            messageDirections:
                              filtersState?.messageDirectionFilter,
                            messageTypes: filtersState?.messageTypeFilter,
                            ocppProcedures: filtersState?.ocppActionFilter
                              ? mapOcppActionToOcppProcedure(
                                  filtersState?.ocppActionFilter
                                )
                              : undefined,
                          },
                        }).then(() => closePopover());
                      }}
                    >
                      <CsvIcon />
                      <ListItemText sx={{ ml: 5 }} primary={t('csv')} />
                    </MenuItem>
                    <MenuItem
                      data-testid="zipDownloadOption"
                      disabled={isFetchingZip}
                      onClick={() => {
                        ocppLogsZipDownload({
                          identityKey: identityKey,
                          params: {
                            timeFrom: filtersState?.dateRange?.startDate
                              ? toPayloadDate(
                                  filtersState?.dateRange?.startDate
                                )
                              : undefined,
                            timeTo: filtersState?.dateRange?.endDate
                              ? toPayloadDate(filtersState?.dateRange?.endDate)
                              : undefined,
                            messageDirections:
                              filtersState?.messageDirectionFilter,
                            messageTypes: filtersState?.messageTypeFilter,
                            ocppProcedures: filtersState?.ocppActionFilter
                              ? mapOcppActionToOcppProcedure(
                                  filtersState?.ocppActionFilter
                                )
                              : undefined,
                          },
                        }).then(() => closePopover());
                      }}
                    >
                      <ZipIcon />
                      <ListItemText sx={{ ml: 5 }} primary={t('zip')} />
                    </MenuItem>
                  </Box>
                </Box>
              );
            }}
          />
        </Stack>
      </Box>
      <ESTableWrapper>
        <ESTable>
          <ESTableHead testId={testId} instance={instance} />
          <ESTableBodyCompose>
            {rows.map((row, index) => {
              return (
                <React.Fragment key={row.id}>
                  <ESTableBodyRow data-testid={`${testId}TableRow${index}`}>
                    {row.getVisibleCells().map((cell, i) => {
                      const isExpandColumn =
                        i === 0 && cell.id.includes('expand');

                      return (
                        <ESTableBodyCell
                          sx={{
                            '&': {
                              width: !isExpandColumn
                                ? cell.column.getSize()
                                : '20px',
                            },
                          }}
                          key={cell.id}
                          onClick={() => {
                            if (rowSeen[row.original.messageUuid]) {
                              return;
                            }
                            setRowSeen((seen) => ({
                              ...seen,
                              [row.original.messageUuid]: true,
                            }));
                          }}
                        >
                          <Box
                            fontWeight={
                              isRowHighlighted(row) &&
                              (cell.id.includes('timestamp') ||
                                cell.id.includes('ocppAction'))
                                ? 'bold'
                                : '500'
                            }
                          >
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext()
                            )}
                          </Box>
                        </ESTableBodyCell>
                      );
                    })}
                  </ESTableBodyRow>
                  <CollapsibleRawContentRow
                    expanded={row.getIsExpanded()}
                    message={row?.original?.message ?? ''}
                  />
                </React.Fragment>
              );
            })}
          </ESTableBodyCompose>
        </ESTable>

        {!hasRows ? (
          <NoTableData top={83} message={t('thereAreNoOcppLogs')} />
        ) : null}

        <ESTablePagination
          rowsPerPageOptions={rowsPerPageOptions}
          instance={instance}
          count={ocppMessageLogs?.totalElements || filteredRows.length}
        />
      </ESTableWrapper>
    </>
  );
};

const RefetchLogsButton: React.FC<{
  onRefetch: () => void;
  isFetching: boolean;
}> = ({ onRefetch, isFetching }) => {
  const identityKey = useChargingStationIdentityKey();
  const [count, setCount] = useState(0);
  useStompTopic(
    `/topic/chargingstation-ocpp-message-created/${identityKey}`,
    () => {
      setCount((count) => count + 1);
    }
  );

  useEffect(() => {
    if (!isFetching) {
      setCount(0);
    }
  }, [isFetching]);

  const liveIndicatorCount = count.toString().padStart(2, '0');

  return (isFetching && count > 0) || count > 0 ? (
    <Box sx={{ mr: 2 }}>
      <RefetchUpdatesButton
        testId="refetchLogsButton"
        onClick={onRefetch}
        isFetching={isFetching}
        label={liveIndicatorCount}
      />
    </Box>
  ) : null;
};
