import React from 'react';
import {
  DateRange,
  DateTimePicker,
} from '@energy-stacks/core/date-range-picker';
import { TableDateFilterButton } from './TableDateFilterButton';
import { usePopover } from '@energy-stacks/core/ui';

interface TableDateTimeFilterProps {
  title: string | React.ReactNode;
  isActive?: boolean;
  defaultDateRange: DateRange | undefined;
  onDateRangeApplied: (dateRange: DateRange) => void;
  onDateRangeCleared: () => void;
}

export const TableDateTimeFilter: React.FC<TableDateTimeFilterProps> = ({
  title,
  isActive,
  defaultDateRange,
  onDateRangeApplied,
  onDateRangeCleared,
}) => {
  const { anchorEl, openPopover, closePopover, popoverOpen } = usePopover();

  return (
    <TableDateFilterButton
      title={title}
      popoverOpen={popoverOpen}
      isActive={isActive}
      onClick={openPopover}
      onClose={closePopover}
      anchorEl={anchorEl}
      PickerComponent={
        <DateTimePicker
          defaultDateRange={defaultDateRange}
          onDateRangeApplied={(dateRange) => {
            onDateRangeApplied(dateRange);
            closePopover();
          }}
          onDateRangeCleared={() => {
            onDateRangeCleared();
            closePopover();
          }}
        />
      }
    />
  );
};
