import { AmenityModel } from './amenityModel';
import { Facilities } from './locationDto';

export const convertAmenitiesToFacilities = (amenities: AmenityModel[]) => {
  return amenities.map((amenity) => amenitiesToFacilitiesMap[amenity]);
};

const amenitiesToFacilitiesMap: Record<AmenityModel, Facilities> = {
  busStop: 'BUS_STOP',
  taxiStand: 'TAXI_STAND',
  bikeSharing: 'BIKE_SHARING',
  tramStop: 'TRAM_STOP',
  metroStation: 'METRO_STATION',
  trainStation: 'TRAIN_STATION',
  airport: 'AIRPORT',
  carpoolParing: 'CARPOOL_PARKING',
  fuelStation: 'FUEL_STATION',
  parkingLot: 'PARKING_LOT',
  restaurant: 'RESTAURANT',
  cafe: 'CAFE',
  wifi: 'WIFI',
  sport: 'SPORT',
  recreationArea: 'RECREATION_AREA',
  nature: 'NATURE',
  museum: 'MUSEUM',
  hotel: 'HOTEL',
  mall: 'MALL',
  superMarket: 'SUPERMARKET',
} as const;
