import { Checkbox, ListItemText, MenuItem, Select } from '@mui/material';

interface ConfigurationMemberListValueFieldProps {
  handleValueChange: (val: string) => void;
  controlerValue: string;
  valuesList: string;
}
export const ConfigurationMemberListValueField: React.FC<
  ConfigurationMemberListValueFieldProps
> = ({ handleValueChange, controlerValue, valuesList, ...rest }) => {
  const dropdownValuesList = valuesList.split(',');

  return (
    <Select
      multiple
      value={[controlerValue]}
      onChange={(e) => {
        const newData = e.target.value as string[];
        let joinedData = '';

        if (newData.length !== 0 && newData[0].includes(newData[1])) {
          const substrings = newData[0].split(',');
          const index = substrings.indexOf(newData[1]);
          if (index !== -1) {
            substrings.splice(index, 1);
          }
          joinedData = substrings.join(',');
        } else if (newData[0] === '') {
          joinedData = newData[1];
        } else {
          joinedData = newData.join(',');
        }
        handleValueChange(joinedData);
      }}
      renderValue={(selected) => {
        if (selected[0] === '') {
          return '-';
        }
        return selected;
      }}
      fullWidth
    >
      {dropdownValuesList.map((listItemValue) => (
        <MenuItem key={listItemValue} value={listItemValue}>
          <Checkbox checked={controlerValue.includes(listItemValue)} />
          <ListItemText primary={listItemValue} />
        </MenuItem>
      ))}
    </Select>
  );
};
