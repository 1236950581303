import React, { ChangeEvent, useState } from 'react';
import { MarkerF } from '@react-google-maps/api';
import { useTranslation } from 'react-i18next';
import {
  Autocomplete,
  Box,
  InputAdornment,
  Popper,
  Stack,
  TextField,
  useTheme,
} from '@mui/material';
import Geocode from 'react-geocode';
import { ESMap, ESTextField, showSnackbar } from '@energy-stacks/core/ui';
import { IconSearch } from '@tabler/icons-react';
import { Controller, useFormContext } from 'react-hook-form';
import locationPOIMarker from '../icons/locationPOIMarker.svg';
import { coordsToNumbers } from '@energy-stacks/shared';
import { useGetUiBrandingQuery } from '@energy-stacks/broker/feature-settings-data';

interface BrokerAddLocationGoogleMapProps {
  center: { latitude: string; longitude: string };
  onCoordsChange: (newCoords: { latitude: string; longitude: string }) => void;
}

const MAP_HEIGHT = 370;

// Initial coords are set to P3 HQ in Stuttgart, Germany
export const INITIAL_LATITUDE = '48.7924757';
export const INITIAL_LONGITUDE = '9.1823703';

export const BrokerAddLocationGoogleMap: React.FC<
  BrokerAddLocationGoogleMapProps
> = ({ center, onCoordsChange }) => {
  const [t] = useTranslation('locations');
  const [tShared] = useTranslation('shared');
  const { spacing } = useTheme();
  const { data: uiBranding } = useGetUiBrandingQuery();
  const hasBorderRadius =
    uiBranding?.hasBorderRadius || uiBranding?.hasBorderRadius === undefined;

  const [autocompleteOptions, setAutocompleteOptions] = useState<
    google.maps.places.AutocompletePrediction[]
  >([]);

  const {
    formState: { errors },
    control,
  } = useFormContext();

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const input = event.target.value;
    const autocompleteService = new google.maps.places.AutocompleteService();
    if (input.length > 0) {
      autocompleteService.getPlacePredictions({ input }, (predictions) => {
        predictions
          ? setAutocompleteOptions(predictions)
          : setAutocompleteOptions([]);
      });
    }
  };
  const mapCenter = coordsToNumbers(center);

  const handleGetCoordinatesFromAddress = (address: string) => {
    const getCoordinatesFromAddress = async () => {
      const addressResult = await Geocode.fromAddress(address);
      return addressResult;
    };
    getCoordinatesFromAddress()
      .then((res) => {
        if (res.results[0]?.geometry?.location) {
          const lat = res.results[0]?.geometry?.location.lat as number;
          const lng = res.results[0]?.geometry?.location.lng as number;

          onCoordsChange({
            latitude: lat.toFixed(7).toString(),
            longitude: lng.toFixed(7).toString(),
          });
        } else {
          showSnackbar('error', t('locationDialogSearchCoordinatesError'));
        }
      })
      .catch(() =>
        showSnackbar('error', t('locationDialogSearchCoordinatesError'))
      );
  };

  return (
    <>
      <Box sx={{ height: MAP_HEIGHT }}>
        <ESMap
          hasBorderRadius={hasBorderRadius}
          center={mapCenter}
          zoom={16}
          options={{
            mapTypeControl: true,
            mapTypeControlOptions: {
              style: google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
              position: google.maps.ControlPosition.RIGHT_BOTTOM,
              mapTypeIds: [
                google.maps.MapTypeId.ROADMAP,
                google.maps.MapTypeId.SATELLITE,
              ],
            },
          }}
        >
          <Autocomplete
            sx={{
              position: 'absolute',
              left: spacing(3.5),
              right: spacing(15),
              top: spacing(3),
              '& .MuiOutlinedInput-root': {
                px: 4,
                borderRadius: hasBorderRadius ? 2 : 0,
                '& .MuiAutocomplete-input': {
                  pl: 1,
                  borderRadius: hasBorderRadius ? 2 : 0,
                },
              },
              '& .MuiOutlinedInput-notchedOutline': {
                borderRadius: hasBorderRadius ? 2 : 0,
              },
            }}
            disableClearable
            freeSolo
            options={autocompleteOptions.map((prediction) =>
              prediction.description.toString()
            )}
            // Popper container to open only if there are predictions available.
            // If Popper is empty container, shadow appears becuase of default mui styling.
            PopperComponent={(props) => (
              <Popper
                placeholder={null}
                {...props}
                open={autocompleteOptions.length > 0}
              />
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder={tShared('search')}
                InputProps={{
                  ...params.InputProps,
                  onKeyDown: (e) => {
                    if (e.key === 'Enter') {
                      // When there is no Predictions in Autocomplete and pressing Enter,
                      // default behaviour is to send automatically "save" button click,
                      // which we want to prevent from happening
                      e.preventDefault();
                    }
                  },
                  type: 'search',
                  sx: {
                    '& .MuiOutlinedInput-root': {
                      px: 0,
                    },
                  },
                  startAdornment: (
                    <InputAdornment position="start">
                      <IconSearch stroke={1.5} size={spacing(4.5)} />
                    </InputAdornment>
                  ),
                }}
                onChange={handleInputChange}
              />
            )}
            onChange={(_, newValue: string | null) => {
              newValue && handleGetCoordinatesFromAddress(newValue);
            }}
          />

          <MarkerF
            draggable={true}
            position={mapCenter}
            icon={{
              url: locationPOIMarker,
              scaledSize: new window.google.maps.Size(48, 48),
            }}
            onDragEnd={async (e) => {
              const lat = e.latLng?.lat() || 0;
              const lng = e.latLng?.lng() || 0;

              if (lat && lng) {
                // Fixed 7 decimals becuase of the rule on OCPI
                onCoordsChange({
                  latitude: String(lat.toFixed(7)),
                  longitude: String(lng.toFixed(7)),
                });
              }
            }}
          />
        </ESMap>
      </Box>
      <Stack direction={'row'} spacing={6} mt={6}>
        <Controller
          control={control}
          render={({ field: { onChange, onBlur, value } }) => {
            return (
              <ESTextField
                inputProps={{
                  'data-testid': `longitudeInput`,
                }}
                label={t('longitude')}
                required
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                error={Boolean(errors['lng'])}
                helperText={
                  Boolean(errors['lng']) && t(`${errors['lng']?.message}`)
                }
              />
            );
          }}
          name={'lng'}
        />
        <Controller
          control={control}
          render={({ field: { onChange, onBlur, value } }) => {
            return (
              <ESTextField
                inputProps={{
                  'data-testid': `latitudeInput`,
                }}
                label={t('latitude')}
                required
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                error={Boolean(errors['lat'])}
                helperText={
                  Boolean(errors['lat']) && t(`${errors['lat']?.message}`)
                }
              />
            );
          }}
          name={'lat'}
        />
      </Stack>
    </>
  );
};
