import { JobModel } from '@energy-stacks/fleet/feature-jobs-data';
import { ESPageLoadingIndicator, RefetchOnError } from '@energy-stacks/shared';
import { JobsTable } from './JobsTable';
import { useJobs } from './useJobs';
import { DateRange } from '@energy-stacks/core/date-range-picker';

interface JobsProps {
  onJobsSelected: (jobs: JobModel['jobId'][]) => void;
  selectedJobIds: JobModel['jobId'][] | undefined;
  defaultDateRange?: DateRange | undefined;
  dateRange?: DateRange | undefined;
  preselectedJobStatusFilters?: JobModel['status'][];
  onDateRangeChange?: (dateRange: DateRange | undefined) => void;
}

export const Jobs: React.FC<JobsProps> = ({
  onJobsSelected,
  dateRange,
  preselectedJobStatusFilters,
  defaultDateRange,
  onDateRangeChange,
  selectedJobIds,
}) => {
  const { data, isLoading, isError, refetch } = useJobs();

  return (
    <>
      {isLoading ? <ESPageLoadingIndicator /> : null}
      {isError ? <RefetchOnError onRefetch={refetch} /> : null}
      {data ? (
        <JobsTable
          tableId="create-tour-wizard-jobs-table"
          preselectedJobStatusFilters={preselectedJobStatusFilters}
          jobs={
            data.filter(
              (item) =>
                preselectedJobStatusFilters?.includes(item.status) ?? true
            ) ?? []
          }
          enableColumnSelection
          enableRowSelection
          hiddenColumnsIds={['startDate', 'endDate']}
          defaultRowSelection={(item) => !!selectedJobIds?.includes(item.jobId)}
          onSelectionChange={(jobsRowSelection) => {
            onJobsSelected(Object.keys(jobsRowSelection));
          }}
          defaultDateRange={defaultDateRange}
          dateRange={dateRange}
          onDateRangeChange={onDateRangeChange}
        />
      ) : null}
    </>
  );
};
