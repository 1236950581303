import { MessageDirectionEnum } from '@energy-stacks/broker/feature-ocpp-message-logs-data';
import {
  BrokerEventSource,
  ChargingStationEventSource,
  ServerEventSource,
  TranslatorEventSource,
} from '@energy-stacks/shared';
import { ArrowForwardRounded } from '@mui/icons-material';
import { Stack } from '@mui/material';
import React from 'react';

interface MessageDirectionProps {
  messageDirectionEnum: MessageDirectionEnum;
}

const messageDirectionMap: Record<
  MessageDirectionEnum,
  { from: React.ReactElement; to: React.ReactElement }
> = {
  CSMS_2_CS: {
    from: <ServerEventSource inheritCursor />,
    to: <ChargingStationEventSource inheritCursor />,
  },
  CS_2_CSMS: {
    from: <ChargingStationEventSource inheritCursor />,
    to: <ServerEventSource inheritCursor />,
  },
  BROKER_2_CS: {
    from: <BrokerEventSource inheritCursor />,
    to: <ChargingStationEventSource inheritCursor />,
  },
  CS_2_BROKER: {
    from: <ChargingStationEventSource inheritCursor />,
    to: <BrokerEventSource inheritCursor />,
  },
  CS_2_TRANSLATOR: {
    from: <ChargingStationEventSource inheritCursor />,
    to: <TranslatorEventSource inheritCursor />,
  },
  TRANSLATOR_2_CS: {
    from: <TranslatorEventSource inheritCursor />,
    to: <ChargingStationEventSource inheritCursor />,
  },
  CSMS_2_TRANSLATOR: {
    from: <ServerEventSource inheritCursor />,
    to: <TranslatorEventSource inheritCursor />,
  },
  TRANSLATOR_2_CSMS: {
    from: <TranslatorEventSource inheritCursor />,
    to: <ServerEventSource inheritCursor />,
  },
};

export const MessageDirectionOption = ({
  messageDirectionEnum,
}: MessageDirectionProps) => {
  return (
    <Stack direction={'row'} alignItems="center" spacing={3}>
      {messageDirectionMap[messageDirectionEnum].from}
      <ArrowForwardRounded sx={{ color: 'grey.600' }} />
      {messageDirectionMap[messageDirectionEnum].to}
    </Stack>
  );
};
