import { useAppSelector } from '@energy-stacks/broker/store';
import { environment } from '@energy-stacks/feature-config';

const logoUrl = environment.ocppServiceUrl + '/ui-configuration/logo.svg';

export const BrokerLogo = () => {
  const logoHashDate = useAppSelector((state) => state.branding.logoHashDate);
  const uniqueSource = logoUrl + '?' + logoHashDate;

  return (
    <img
      data-testid="brokerLogoImage"
      src={uniqueSource}
      alt="logo"
      height="auto"
      width="auto"
      style={{
        maxWidth: '100%',
        maxHeight: '100%',
      }}
    />
  );
};
