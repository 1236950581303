import { sortByStatus } from '@energy-stacks/shared';
import { useGetVehiclesQuery } from '@energy-stacks/fleet-is/feature-vehicles-data';
import { useMemo } from 'react';

export const useVehiclesSorted = () => {
  const {
    data: vehicles,
    isError,
    isLoading,
    refetch,
    isSuccess,
  } = useGetVehiclesQuery();

  const sortedVehicles = useMemo(() => {
    if (vehicles) {
      return sortByStatus([...vehicles]);
    }
  }, [vehicles]);

  return { data: sortedVehicles, isError, isLoading, refetch, isSuccess };
};
