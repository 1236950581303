import { Backdrop, Box } from '@mui/material';
import {
  ESLoadingIndicator,
  ESLoadingIndicatorProps,
} from './ESLoadingIndicator';

type ESFullScreenLoadingIndicatorProps = Pick<ESLoadingIndicatorProps, 'text'>;

export const ESFullScreenLoadingIndicator: React.FC<
  ESFullScreenLoadingIndicatorProps
> = ({ text }) => {
  return (
    <Backdrop sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }} open={true}>
      <Box sx={{ mt: -40 }}>
        <ESLoadingIndicator size="large" text={text} color="white" />
      </Box>
    </Backdrop>
  );
};
