import {
  ESPageLoadingIndicator,
  RefetchOnError,
  useDocumentTitle,
  ESPage,
} from '@energy-stacks/shared';
import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';
import { ChargingStations } from './ChargingStations';
import { useChargingStations } from '@energy-stacks/broker/feature-charging-stations-data';

export const ChargingStationsPage = () => {
  const { t } = useTranslation('chargingStations');
  const { refetch, isLoading, isError, isSuccess } = useChargingStations();
  useDocumentTitle(t('title'));

  return (
    <ESPage title={t('title')}>
      {isLoading ? <ESPageLoadingIndicator /> : null}
      {isError ? <RefetchOnError onRefetch={refetch} /> : null}
      {isSuccess ? <ChargingStations /> : null}
      <Outlet />
    </ESPage>
  );
};
