import { useDocumentTitle } from '@energy-stacks/shared';
import { ESPageLoadingIndicator, RefetchOnError } from '@energy-stacks/shared';
import { useTranslation } from 'react-i18next';
import { JobsTable } from './JobsTable';
import { useJobs } from './useJobs';
import { useState } from 'react';
import { DateRange } from '@energy-stacks/core/date-range-picker';
import { FleetPage } from '@energy-stacks/fleet/shared';

const SCROLL_RESTORATION_ID = 'jobsTable';

export const JobsPage = () => {
  const [t] = useTranslation('jobs');
  const pageTitle = t('pageTitle');
  useDocumentTitle(pageTitle);
  const { data, isError, isLoading, refetch, isSuccess } = useJobs();
  const [dateRange, setDateRange] = useState<DateRange | undefined>();

  return (
    <FleetPage title={pageTitle}>
      {isLoading ? <ESPageLoadingIndicator /> : null}
      {isError ? <RefetchOnError onRefetch={refetch} /> : null}
      {isSuccess ? (
        <JobsTable
          jobs={data ?? []}
          enableColumnSelection
          dateRange={dateRange}
          onDateRangeChange={setDateRange}
          scrollRestorationId={SCROLL_RESTORATION_ID}
        />
      ) : null}
    </FleetPage>
  );
};
