import { ShadowChargingStationStatus } from './brokerChargingStationShadow';
import { ChargingStationStatus } from './chargingStationModel';

export function getCSStatus(
  chargingStationStatus?: ShadowChargingStationStatus
): ChargingStationStatus {
  if (chargingStationStatus === 'UNDEFINED') {
    return 'provisioned';
  } else if (chargingStationStatus === 'AVAILABLE') {
    return 'online';
  } else if (chargingStationStatus === 'UNAVAILABLE') {
    return 'offline';
  } else if (chargingStationStatus === 'FAULTED') {
    return 'faulted';
  }

  return 'provisioned';
}
