import {
  useDocumentTitle,
  RefetchOnError,
  ESPageLoadingIndicator,
} from '@energy-stacks/shared';
import { useTranslation } from 'react-i18next';
import { useVehiclesSorted } from './useVehiclesSorted';
import { VehiclesTable } from './VehiclesTable';
import { Outlet } from 'react-router-dom';
import { useLocation, useNavigate } from 'react-router-dom';
import { FleetPage, FleetRoutes } from '@energy-stacks/fleet/shared';
import { IconPlus } from '@tabler/icons-react';
import { ESButton } from '@energy-stacks/core/ui';
import { useState } from 'react';
import { DateRange } from '@energy-stacks/core/date-range-picker';
import { endOfToday, startOfToday } from 'date-fns';

const TABLE_SCROLL_RESTORATION_ID = 'vehiclesTable';

export const VehiclesPage = () => {
  const defaultDateRange = {
    startDate: startOfToday(),
    endDate: endOfToday(),
  };
  const [t] = useTranslation('vehicles');
  const pageTitle = t('pageTitle');
  useDocumentTitle(pageTitle);
  const [dateRange, setDateRange] = useState<DateRange>(defaultDateRange);
  const { data, isError, isLoading, refetch, isSuccess } = useVehiclesSorted({
    dateFrom: dateRange?.startDate,
    dateTo: dateRange?.endDate,
  });
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <FleetPage title={pageTitle}>
      {isLoading ? <ESPageLoadingIndicator /> : null}
      {isError ? <RefetchOnError onRefetch={refetch} /> : null}
      {isSuccess ? (
        <VehiclesTable
          vehicles={data ?? []}
          enableColumnSelection
          onDateRangeChange={(range) => {
            setDateRange(range);
          }}
          defaultDateRange={defaultDateRange}
          dateRange={dateRange}
          right={
            <ESButton
              data-testid="newVehicleButton"
              onClick={() =>
                navigate(FleetRoutes.AddVehicle, {
                  state: { background: location },
                })
              }
              sx={{
                ml: 4,
                px: 7,
              }}
              startIcon={<IconPlus />}
            >
              {t('addVehicleButtonLabel')}
            </ESButton>
          }
          scrollRestorationId={TABLE_SCROLL_RESTORATION_ID}
        />
      ) : null}
      <Outlet />
    </FleetPage>
  );
};
