import { CloseRounded } from '@mui/icons-material';
import { IconButton, InputAdornment, TextField } from '@mui/material';

interface ConfigurationDecimalValueFieldProps {
  handleValueChange: (val: string) => void;
  controlerValue: string;
}
export const ConfigurationDecimalValueField: React.FC<
  ConfigurationDecimalValueFieldProps
> = ({ handleValueChange, controlerValue, ...rest }) => {
  return (
    <TextField
      variant="outlined"
      size="small"
      value={controlerValue}
      placeholder={controlerValue || '-'}
      fullWidth
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              edge="end"
              aria-label="clear"
              sx={{ visibility: controlerValue ? 'visible' : 'hidden' }}
              onClick={() => {
                handleValueChange('');
              }}
            >
              <CloseRounded />
            </IconButton>
          </InputAdornment>
        ),
      }}
      onChange={(e) => {
        const newValue = e.target.value;
        handleValueChange(newValue);
      }}
      {...rest}
    />
  );
};
