import { Stack, Typography } from '@mui/material';

interface StackValuePairProps {
  property: string;
  value?: string;
  customElement?: React.ReactNode;
}

export const StackValuePair: React.FC<StackValuePairProps> = ({
  property,
  value,
  customElement,
}) => {
  return (
    <Stack
      alignItems="center"
      flexDirection="row"
      justifyContent="space-between"
      sx={{
        py: 2,
      }}
    >
      <Typography
        variant="body1"
        fontWeight={400}
        lineHeight="24px"
        color="grey.900"
      >
        {property}
      </Typography>

      {customElement ? (
        customElement
      ) : (
        <Typography
          variant="body1"
          fontWeight={500}
          lineHeight="24px"
          color="grey.900"
          sx={{
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            maxWidth: 'calc(100% - 200px)',
          }}
        >
          {value || ''}
        </Typography>
      )}
    </Stack>
  );
};
