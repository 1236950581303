import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export const ADD_CHARGING_STATION_FEATURE_KEY = 'addChargingStation';

export interface ChargePointModel {
  inputValue?: string;
  model: string;
}

export interface AddChargingStationState {
  chargePointModel: ChargePointModel[];
}

const initialState: AddChargingStationState = {
  chargePointModel: [{ model: 'Model 1' }, { model: 'Model 2' }],
};

export const addChargingStationSlice = createSlice({
  name: ADD_CHARGING_STATION_FEATURE_KEY,
  initialState,
  reducers: {
    addNewChargePointModel: (
      state,
      { payload }: PayloadAction<ChargePointModel>
    ) => {
      state.chargePointModel.push(payload);
    },
  },
});

export const { addNewChargePointModel } = addChargingStationSlice.actions;

export const addChargingStationReducer = addChargingStationSlice.reducer;
