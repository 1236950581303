export function getErrorSeverity(minutes: number) {
  if (minutes <= 1) {
    return {
      error: 'lowAlerts',
      color: 'primary.dark',
      border: 'primary.200',
    };
  } else if (minutes < 30) {
    return {
      error: 'minorAlerts',
      color: 'warning.dark',
      border: 'warning.main',
    };
  } else if (minutes >= 30 && minutes < 120) {
    return {
      error: 'majorAlerts',
      color: 'error.main',
      border: 'error.light',
    };
  } else if (minutes >= 120) {
    return {
      error: 'criticalAlerts',
      color: 'error.dark',
      border: 'error.light',
    };
  }
  return {
    error: 'lowAlerts',
    color: 'primary.dark',
    border: 'primary.200',
  };
}
