import { zeroOutSecondsAndMilliseconds } from '@energy-stacks/shared';
import { intervalToDuration } from 'date-fns';

export const formatIntervalDuration = (
  startDateString: string,
  endDateString: string
) => {
  const { hours, minutes } = intervalToDuration({
    start: zeroOutSecondsAndMilliseconds(new Date(startDateString)),
    end: zeroOutSecondsAndMilliseconds(new Date(endDateString)),
  });

  let duration = '';

  if (hours) {
    duration = `${hours}h`;
  }
  if (hours && minutes) {
    duration += ` ${minutes}m`;
  } else if (minutes) {
    duration = `${minutes}m`;
  }

  return duration;
};
