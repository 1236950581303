import { Card, Typography, useMediaQuery } from '@mui/material';
import { Box } from '@mui/system';
import { useChargingStationDetails } from '../useChargingStationDetails';
import { ConnectorSkeleton } from '../connectors/ConnectorSkeleton';
import { TableEmptyMessage } from '@energy-stacks/shared';
import { useTranslation } from 'react-i18next';
import { ConnectorModel } from '@energy-stacks/broker/feature-charging-stations-data';
import { ConnectorGroupV201 } from './ConnectorGroupV201';

type GroupedConnectorsType = {
  [evseID: string]: ConnectorModel[];
};

const groupConnectorsByEvse = (connectors: ConnectorModel[]) => {
  return connectors.reduce((groups: GroupedConnectorsType, item) => {
    const { evseId } = item;
    if (!groups[evseId]) {
      groups[evseId] = [];
    }
    groups[evseId].push(item);
    return groups;
  }, {});
};

export type ViewMode = 'grid' | 'list';

export const ConnectorGroupsV201 = () => {
  const [t] = useTranslation('chargingStations');

  const { chargingStationDetails, isLoading } = useChargingStationDetails({
    listenToUpdates: true,
  });

  const isFullHD = useMediaQuery('(min-width: 1920px)');
  const viewMode: ViewMode = isFullHD ? 'list' : 'grid';
  const recievedEvses = groupConnectorsByEvse(
    chargingStationDetails?.connectors ?? []
  );

  const numberOfEvses = Object.keys(recievedEvses).length;

  if (isLoading) {
    return <ConnectorSkeleton numberOfConnectors={numberOfEvses || 1} />;
  }

  if (!numberOfEvses) {
    return (
      <Card
        data-testid="emptyDetailsConnectorCardV201"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 4,
          padding: 4,
          border: 'none',
          height: '400px',

          '& > div.MuiBox-root': {
            minHeight: '145px',
          },
        }}
      >
        <Typography
          data-testid="emptyDetailsConnectorCardTitleV201"
          variant="h4"
          fontWeight={700}
          lineHeight={1.5}
          color={'grey.900'}
        >
          {t('connectors')}
        </Typography>
        <TableEmptyMessage
          sx={{ h4: { marginTop: '15px' }, svg: { height: '135px' } }}
        />
      </Card>
    );
  }

  return (
    <Box
      sx={{
        height: isFullHD ? 'unset' : numberOfEvses > 1 ? '100%' : 'auto',
        minHeight:
          numberOfEvses > 1
            ? !isFullHD
              ? '693px'
              : `calc(156px + (min(${numberOfEvses},3) * 108px))`
            : 'unset',
        maxHeight: isFullHD
          ? 'calc(56px + (3 * 108px))'
          : 'calc(100vh - 290px - 72px - 150px)',
      }}
    >
      <Card
        data-testid="detailsConnectorCardV201"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 4,
          padding: 4,
          border: 'none',
          height: '100%',
          overflow: 'auto',
          position: 'relative',
        }}
      >
        <Typography
          data-testid="detailsConnectorCardTitleV201"
          variant="h4"
          fontWeight={700}
          lineHeight={1.5}
          color={'grey.900'}
        >
          {t('connectors')}
        </Typography>
        {Object.keys(recievedEvses).map((evse) => {
          const connectors: ConnectorModel[] = recievedEvses[evse];

          return (
            chargingStationDetails && (
              <ConnectorGroupV201
                key={evse}
                viewMode={viewMode}
                // We will need to introduce a new variable in the shadow later. "Our" EVSE ID is based on OCPI, now there is a new EVSE ID in OCPP 2.0.1
                // Currently EVSE ID = Connector ID
                // EVSE ID 1, Connector ID 1
                evseId={connectors[0].connectorId}
                connectors={connectors}
                chargingStationIdentityKey={
                  chargingStationDetails.identityKey ?? ''
                }
                chargingStationStatus={chargingStationDetails.csStatus}
                chargingStationOnline={
                  chargingStationDetails.csStatus === 'online'
                }
              />
            )
          );
        })}
      </Card>
    </Box>
  );
};
