import {
  DEFAULT_PAGINATION_CONFIG,
  ESButton,
  ESTable,
  ESTableBody,
  ESTableHead,
  ESTablePagination,
  ESTableWrapper,
  ESTextButton,
  Sort,
  useFitRows,
} from '@energy-stacks/core/ui';
import { DateRange } from '@energy-stacks/core/date-range-picker';
import {
  CsvIcon,
  ExportButton,
  formatDateTime,
  NoTableData,
  TableColumnSelect,
  TableDateTimeFilter,
  TableSearchField,
  toPayloadDate,
  ZipIcon,
} from '@energy-stacks/shared';
import {
  Box,
  LinearProgress,
  ListItemText,
  MenuItem,
  Stack,
} from '@mui/material';
import {
  createColumnHelper,
  getCoreRowModel,
  getExpandedRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  PaginationState,
  SortingState,
  useReactTable,
} from '@tanstack/react-table';
import React, { Dispatch, SetStateAction, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import {
  AlertManagementModel,
  Alert,
  AlertStatus,
  useLazyGetAlertsCsvQuery,
  useLazyGetAlertsZipQuery,
} from '@energy-stacks/broker/feature-alert-management-data';
import { AlertManagementAlertStatusFilter } from './AlertManagementAlertStatusFilter';
import { IconChargingPile } from '@tabler/icons-react';
import { BrokerRoutes } from '@energy-stacks/broker/shared';
import { useNavigate } from 'react-router-dom';
import {
  chargingStationsApi,
  useGetChargingStationsQuery,
} from '@energy-stacks/broker/feature-charging-stations-data';
import { RefetchAlertsButton } from './RefetchAlertsButton';
import { AlertReasonCell } from './AlertReasonCell';
import { ErrorSeverityCell } from './ErrorSeverityCell';
import { AlertStatusCell } from './AlertStatusCell';
import { AlertConnectorCell } from './AlertConnectorCell';

type AlertsTableProps = {
  tableId?: string;
  alerts: AlertManagementModel | undefined;
  pagination: PaginationState;
  onPaginationChange: Dispatch<SetStateAction<PaginationState>>;
  sorting: SortingState;
  onSortingChange: Dispatch<SetStateAction<SortingState>>;
  sortOrder: Sort;
  search: string;
  setSearch: Dispatch<SetStateAction<string>>;
  alertStatusFilter: AlertStatus | undefined;
  onAlertStatusFilterChange: (
    alertStatusFilter: AlertStatus | undefined
  ) => void;
  dateRangeFilter: DateRange | undefined;
  onDateRangeFilterChange: (dateRangeFilter: DateRange | undefined) => void;
  isFetching: boolean;
  onRefetch: () => void;
  testId?: string;
};

export const AlertManagementTable: React.FC<AlertsTableProps> = ({
  tableId = 'alerts',
  alerts,
  onPaginationChange,
  onSortingChange,
  sorting,
  search,
  setSearch,
  pagination,
  alertStatusFilter,
  onAlertStatusFilterChange,
  dateRangeFilter,
  onDateRangeFilterChange,
  isFetching,
  onRefetch,
  testId,
}) => {
  const { t } = useTranslation('alertManagement');
  const [sharedT] = useTranslation('shared');
  const columnHelper = createColumnHelper<Alert>();
  const [alertsCsvDownload, { isFetching: isFetchingCsv }] =
    useLazyGetAlertsCsvQuery();
  const [alertsZipDownload, { isFetching: isFetchingZip }] =
    useLazyGetAlertsZipQuery();

  const navigate = useNavigate();
  const { data: chargingStations } = useGetChargingStationsQuery();
  const [getChargingStations] =
    chargingStationsApi.useLazyGetChargingStationsQuery();

  const columns = [
    columnHelper.accessor('id', {
      header: () => t('id'),
      footer: (props) => props.column.id,
      cell: (info) => <div>{info.row.original.id}</div>,
    }),
    columnHelper.accessor('identityKey', {
      header: () => t('stationId'),
      footer: (props) => props.column.id,
      cell: (info) => {
        return (
          (
            <ESButton
              data-testid="chargingStationDashboardButton"
              sx={{
                justifyContent: 'flex-start',
                textDecorationLine: 'underline',
                textUnderlineOffset: '2px',
                '&.MuiButtonBase-root:hover': {
                  bgcolor: 'transparent',
                },
              }}
              variant="text"
              startIcon={<IconChargingPile />}
              onClick={async (row) => {
                let chargingStation =
                  chargingStations?.entities[info.row.original.identityKey];
                try {
                  if (!chargingStation) {
                    // calling getChargingStationsLazy inside this condition since getChargingStationsLazy fetch does not read from cache
                    const stations = await getChargingStations(
                      undefined,
                      true
                    ).unwrap();

                    chargingStation =
                      stations.entities[info.row.original.identityKey];
                  }
                } catch (error) {
                } finally {
                  navigate(
                    `${BrokerRoutes.ChargingStations}/${chargingStation?.name}/${info.row.original.identityKey}`
                  );
                }
              }}
            >
              <span>{info.getValue()}</span>
            </ESButton>
          ) || '-'
        );
      },
      enableSorting: false,
    }),
    columnHelper.accessor('alertReason', {
      header: () => t('alertReason'),
      footer: (props) => props.column.id,
      cell: ({ row }) => (
        <AlertReasonCell alertReason={row.original.alertReason} />
      ),
    }),
    columnHelper.display({
      id: 'severity',
      header: () => t('errorSeverity'),
      cell: ({ row }) => (
        <ErrorSeverityCell
          dateCreated={row.original.dateCreated}
          dateResolved={row.original.dateResolved}
        />
      ),
    }),
    columnHelper.display({
      id: 'alertStatus',
      header: () => t('alertStatus'),
      cell: ({ row }) => (
        <AlertStatusCell dateResolved={row.original.dateResolved} />
      ),
    }),
    columnHelper.accessor('errorCode', {
      header: () => t('errorCode'),
      footer: (props) => props.column.id,
      cell: (info) =>
        info.row.original.errorCode ? t(info.row.original.errorCode) : '-',
      enableSorting: false,
    }),
    columnHelper.accessor('errorInfo', {
      header: () => t('errorInfo'),
      footer: (props) => props.column.id,
      cell: (info) => info.row.original.errorInfo || '-',
      enableSorting: false,
    }),
    columnHelper.accessor('vendorErrorCode', {
      header: () => t('vendorErrorCode'),
      footer: (props) => props.column.id,
      cell: (info) => info.row.original.vendorErrorCode || '-',
      enableSorting: false,
    }),
    columnHelper.accessor('connectorId', {
      header: () => t('connector'),
      footer: (props) => props.column.id,
      cell: ({ row }) => {
        const { connectorId, connectorStandard, evseId } = row.original;
        return (
          <AlertConnectorCell
            connectorId={connectorId}
            connectorStandard={connectorStandard}
            evseId={evseId}
          />
        );
      },
      enableSorting: false,
    }),
    columnHelper.accessor('dateCreated', {
      sortingFn: 'datetime',
      header: () => t('dateCreated'),
      footer: (props) => props.column.id,
      cell: (info) => formatDateTime(info.row.original.dateCreated),
      enableGlobalFilter: false,
    }),
    columnHelper.accessor('dateResolved', {
      id: 'dateResolved',
      header: () => t('dateResolved'),
      footer: (props) => props.column.id,
      cell: (info) => (
        <Box sx={{ pr: 4 }}>
          {info.row.original.dateResolved
            ? formatDateTime(info.row.original.dateResolved)
            : '-'}
        </Box>
      ),
    }),
  ];

  const instance = useReactTable({
    data: alerts?.alerts ?? [],
    columns,
    state: {
      pagination,
      sorting,
    },
    getCoreRowModel: getCoreRowModel(),
    onPaginationChange,
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onSortingChange,
    getSortedRowModel: getSortedRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    manualPagination: true,
    pageCount: alerts?.totalPages,
    manualSorting: true,
    enableColumnResizing: false,
  });

  const { rowsPerPageOptions } = useFitRows(
    instance,
    alerts?.totalElements ?? 0
  );

  const rows = instance.getRowModel().rows;
  const hasRows = rows.length !== 0;
  const filteredRows = instance.getFilteredRowModel().rows;

  const handleDateRangeFilterChange = (dateRange: DateRange) => {
    onDateRangeFilterChange(dateRange);
    instance.setPageIndex(DEFAULT_PAGINATION_CONFIG.page);
  };

  const handleDateRangeFilterCleared = () => {
    onDateRangeFilterChange(undefined);
    instance.setPageIndex(DEFAULT_PAGINATION_CONFIG.page);
  };

  const hasFilters =
    alertStatusFilter !== undefined || Boolean(dateRangeFilter);

  const clearAllFilters = useCallback(() => {
    onAlertStatusFilterChange(undefined);
    onDateRangeFilterChange(undefined);
    instance.setPageIndex(DEFAULT_PAGINATION_CONFIG.page);
  }, [instance, onAlertStatusFilterChange, onDateRangeFilterChange]);

  function handleAlertStatusFilterChange(
    alertStatusFilter: AlertStatus | undefined
  ) {
    onAlertStatusFilterChange(alertStatusFilter);
    instance.setPageIndex(DEFAULT_PAGINATION_CONFIG.page);
  }

  const onSearchChange = useCallback(
    (value: string) => {
      const trimmedNewValue = value.trim();
      setSearch(trimmedNewValue);
    },
    [setSearch]
  );

  return (
    <>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="flex-start"
      >
        <Stack direction="row" gap={3} alignItems="center" marginBottom={6}>
          <TableSearchField
            testId={testId}
            value={search}
            onChange={(val) => {
              onSearchChange(val);
            }}
            tableInstance={instance}
            debounce={500}
          />
          <AlertManagementAlertStatusFilter
            testId={`${testId}Status`}
            alertStatusFilter={alertStatusFilter}
            onAlertStatusFilterChange={handleAlertStatusFilterChange}
          />

          <TableDateTimeFilter
            title={sharedT('timeRange')}
            isActive={dateRangeFilter !== undefined}
            defaultDateRange={dateRangeFilter}
            onDateRangeApplied={handleDateRangeFilterChange}
            onDateRangeCleared={handleDateRangeFilterCleared}
          />

          {hasFilters ? (
            <ESTextButton
              testId={`${testId}ClearAllButton`}
              onClick={clearAllFilters}
            >
              {sharedT('clearAll')}
            </ESTextButton>
          ) : null}
          <RefetchAlertsButton
            testId={testId}
            isFetching={isFetching}
            onRefetch={() => {
              onRefetch();
              instance.setPageIndex(0);
            }}
          />
        </Stack>
        <Stack
          flexDirection="row"
          columnGap={2}
          sx={{ ml: 2 }}
          alignItems="center"
        >
          <Box sx={{ marginLeft: 'auto', pl: 2 }}>
            <TableColumnSelect instance={instance} />
          </Box>
          <ExportButton
            testId="logsExportButton"
            renderPopover={(closePopover) => {
              return (
                <Box sx={{ position: 'relative' }}>
                  {isFetchingCsv || isFetchingZip ? (
                    <Box
                      sx={{
                        position: 'absolute',
                        top: 0,
                        width: '100%',
                        zIndex: 1,
                      }}
                    >
                      <LinearProgress />
                    </Box>
                  ) : null}
                  <Box>
                    <MenuItem
                      data-testid="csvDownloadOption"
                      disabled={isFetchingCsv}
                      onClick={() => {
                        alertsCsvDownload({
                          params: {
                            dateFrom: dateRangeFilter?.startDate
                              ? toPayloadDate(dateRangeFilter?.startDate)
                              : undefined,
                            dateTo: dateRangeFilter?.endDate
                              ? toPayloadDate(dateRangeFilter?.endDate)
                              : undefined,
                            alertStatus: alertStatusFilter
                              ? alertStatusFilter
                              : undefined,
                          },
                        }).then(() => closePopover());
                      }}
                    >
                      <CsvIcon />
                      <ListItemText sx={{ ml: 5 }} primary={t('csv')} />
                    </MenuItem>
                    <MenuItem
                      data-testid="zipDownloadOption"
                      disabled={isFetchingZip}
                      onClick={() => {
                        alertsZipDownload({
                          params: {
                            dateFrom: dateRangeFilter?.startDate
                              ? toPayloadDate(dateRangeFilter?.startDate)
                              : undefined,
                            dateTo: dateRangeFilter?.endDate
                              ? toPayloadDate(dateRangeFilter?.endDate)
                              : undefined,
                            alertStatus: alertStatusFilter
                              ? alertStatusFilter
                              : undefined,
                          },
                        }).then(() => closePopover());
                      }}
                    >
                      <ZipIcon />
                      <ListItemText sx={{ ml: 5 }} primary={t('zip')} />
                    </MenuItem>
                  </Box>
                </Box>
              );
            }}
          />
        </Stack>
      </Box>
      <ESTableWrapper>
        <ESTable>
          <ESTableHead testId={testId} instance={instance} />
          <ESTableBody testId={testId} rows={rows} />
        </ESTable>

        {!hasRows ? <NoTableData message={t('thereAreNoAlerts')} /> : null}

        <ESTablePagination
          rowsPerPageOptions={rowsPerPageOptions}
          instance={instance}
          count={alerts?.totalElements || filteredRows.length}
        />
      </ESTableWrapper>
    </>
  );
};
