import {
  ESDialogActionButton,
  ESDialogActions,
  ESDialogContent,
  ESDialogTitle,
} from '@energy-stacks/core/ui';
import { Dialog, Typography } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

interface ConfirmDeleteDialogProps {
  title: string;
  description?: string;
  onCancel: () => void;
  onConfirm: () => Promise<void> | undefined;
  fullWidth?: boolean;
  disabled?: boolean;
}

export const ConfirmDeleteDialog: React.FC<ConfirmDeleteDialogProps> = ({
  description,
  onCancel,
  onConfirm,
  title,
  fullWidth = false,
  disabled = false,
}) => {
  const [openDialog, setOpenDialog] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);
  const [t] = useTranslation('shared');

  const handleCloseDialog = () => {
    setOpenDialog(false);
    onCancel();
  };

  const handleConfirm = () => {
    setLoading(true);
    onConfirm()?.finally(() => setLoading(false));
  };
  return (
    <Dialog open={openDialog} fullWidth={fullWidth} onClose={handleCloseDialog}>
      <ESDialogTitle data-testid="deleteChargingStationTitle">
        {title}
      </ESDialogTitle>
      <ESDialogContent data-testid="deleteChargingStationContent">
        <Typography variant="body1">{description}</Typography>
      </ESDialogContent>
      <ESDialogActions>
        <ESDialogActionButton
          data-testid="deleteChargingStationCancelButton"
          color="error"
          onClick={handleCloseDialog}
          disabled={loading}
        >
          {t('cancelDeletion')}
        </ESDialogActionButton>
        <ESDialogActionButton
          data-testid="deleteChargingStationDeleteButton"
          variant="contained"
          color="error"
          loading={loading}
          disabled={disabled}
          onClick={handleConfirm}
        >
          {t('deleteActionButton')}
        </ESDialogActionButton>
      </ESDialogActions>
    </Dialog>
  );
};
