import { ChargingStationStatus } from '@energy-stacks/broker/feature-charging-stations-data';
import { Chip, useTheme } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { chargingStationStatusDisplay } from './chargingStationStatusDisplay';

interface ChargingStationShadowStatusChipProps {
  shadowStatus: ChargingStationStatus;
}

export const ChargingStationShadowStatusChip: React.FC<
  ChargingStationShadowStatusChipProps
> = ({ shadowStatus }) => {
  const [t] = useTranslation('chargingStations');
  const theme = useTheme();

  return shadowStatus ? (
    <Chip
      label={t(shadowStatus, { context: 'status' })}
      icon={chargingStationStatusDisplay(theme)[shadowStatus].icon}
      sx={{
        backgroundColor:
          chargingStationStatusDisplay(theme)[shadowStatus].bgColor,
        color: chargingStationStatusDisplay(theme)[shadowStatus].color,
        fontWeight: 500,
      }}
    />
  ) : null;
};
