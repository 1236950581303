import { CommunicationDirectionModel } from '@energy-stacks/broker/feature-ocpp-network-logs-data';
import {
  BrokerEventSource,
  ChargingStationEventSource,
  ServerEventSource,
} from '@energy-stacks/shared';
import { ArrowForwardRounded } from '@mui/icons-material';
import { Stack } from '@mui/material';
import React from 'react';

interface CommunicationDirectionProps {
  communicationDirection: CommunicationDirectionModel;
}

const communicationDirectionMap: Record<
  CommunicationDirectionModel,
  { from: React.ReactElement; to: React.ReactElement }
> = {
  brokerToChargingStation: {
    to: <ChargingStationEventSource inheritCursor />,
    from: <BrokerEventSource inheritCursor />,
  },
  brokerToServer: {
    from: <BrokerEventSource inheritCursor />,
    to: <ServerEventSource inheritCursor />,
  },
  chargingStationToBroker: {
    from: <ChargingStationEventSource inheritCursor />,
    to: <BrokerEventSource inheritCursor />,
  },
};

export const CommunicationDirectionOption = ({
  communicationDirection,
}: CommunicationDirectionProps) => {
  return (
    <Stack direction={'row'} alignItems="center" spacing={3}>
      {communicationDirectionMap[communicationDirection].from}
      <ArrowForwardRounded sx={{ color: 'grey.600' }} />
      {communicationDirectionMap[communicationDirection].to}
    </Stack>
  );
};
