import { useOidcUser } from '@axa-fr/react-oidc';
import { BrokerUserDto } from './brokerUserDto';
import { brokerUserModelNormalizer } from './normalizers/brokerUserModelNormalizer';

export const useBrokerUserInfo = () => {
  const userInfo = useOidcUser();
  const userDto = userInfo.oidcUser as unknown as BrokerUserDto | null;
  const user = userDto ? brokerUserModelNormalizer(userDto) : null;

  return {
    user,
    loadingState: userInfo.oidcUserLoadingState,
  };
};
