import { Box, useTheme } from '@mui/material';
import { BrokerLogo } from './BrokerLogo';

export const BrokerLogoOverlay = () => {
  const { palette } = useTheme();

  return (
    <Box
      sx={{
        backgroundColor: palette.dark.main,
        height: '100%',
        width: '100%',
        position: 'absolute',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: 90,
          width: 300,
        }}
      >
        <BrokerLogo />
      </Box>
    </Box>
  );
};
