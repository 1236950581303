import { Dialog } from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
  ESDialogTitle,
  ESDialogContent,
  ESDialogActions,
  ESButton,
} from '@energy-stacks/core/ui';

interface BrokerDeleteLocationInfoDialogProps {
  isOpen: boolean;
  onClose: () => void;
}

export const BrokerDeleteLocationInfoDialog: React.FC<
  BrokerDeleteLocationInfoDialogProps
> = ({ isOpen, onClose }) => {
  const { t } = useTranslation('locations');

  return (
    <Dialog open={isOpen} onClose={onClose} fullWidth maxWidth="sm">
      <ESDialogTitle data-testid="deleteChargingSiteDialogTitle">
        {t('cannotDeleteLocationTitle')}
      </ESDialogTitle>
      <ESDialogContent data-testid="deleteChargingSiteDialogContent">
        {t('cannotDeleteLocationMessage')}
      </ESDialogContent>
      <ESDialogActions>
        <ESButton
          data-testid="deleteChargingSiteOkayButton"
          variant="contained"
          onClick={onClose}
        >
          {t('okay')}
        </ESButton>
      </ESDialogActions>
    </Dialog>
  );
};
