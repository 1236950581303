import { Typography, Box, Theme, SxProps, Stack } from '@mui/material';

export interface ESPageProps {
  children?: React.ReactNode;
  title: string | React.ReactNode;
  right?: React.ReactNode;
  contentStyle?: SxProps<Theme>;
}

export const ES_PAGE_PADDING = 6;
const ES_PAGE_TITLE_HEIGHT = 72;

export const ESPage: React.FC<ESPageProps> = ({
  children,
  title,
  right,
  contentStyle,
}) => {
  return (
    <Box
      sx={(theme) => ({
        display: 'flex',
        flexDirection: 'column',
        height: '100vh',
        backgroundColor: theme.palette.background.default,
      })}
    >
      <Stack
        direction="row"
        height={ES_PAGE_TITLE_HEIGHT}
        alignItems="center"
        justifyContent="space-between"
        p={6}
        sx={{
          backgroundColor: 'background.paper',
        }}
      >
        <Typography
          variant="h3"
          textTransform="capitalize"
          sx={{ wordBreak: 'break-all' }}
          color="grey.900"
        >
          {title}
        </Typography>
        {right ? right : null}
      </Stack>
      <Box
        sx={{
          overflowY: 'auto',
          p: ES_PAGE_PADDING,
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
          ...contentStyle,
        }}
      >
        {children}
      </Box>
    </Box>
  );
};
