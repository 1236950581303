import { userRoleNormalizer } from './userRoleNormalizer';
import { ExtendedCsmsUserDto } from '../extendedCsmsUserDto';
import { ExtendedCsmsUserModel } from '../extendedCsmsUserModel';

export const extendedCsmsUserModelNormalizer = (
  user: ExtendedCsmsUserDto
): ExtendedCsmsUserModel => {
  return {
    userId: user.userId,
    displayName: user.displayName,
    emailAddress: user.emailAddress,
    isActive: user.isActive,
    userRole: userRoleNormalizer(user.userRole),
    providerId: user.providerId,
  };
};
