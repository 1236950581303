import React from 'react';
import { ESMenuItem, useESMenuContext } from '@energy-stacks/core/ui';
import { useDialog } from '@energy-stacks/shared';
import { useTranslation } from 'react-i18next';
import { BrokerDeleteLocation } from '../delete-location/BrokerDeleteLocation';

interface LocationDetailsDrawerDeleteMenuItemProps {
  locationId: string | undefined;
}

export const LocationDetailsDrawerDeleteMenuItem: React.FC<
  LocationDetailsDrawerDeleteMenuItemProps
> = ({ locationId }) => {
  const { t } = useTranslation('locations');
  const { dialogProps, open } = useDialog();
  const { closeESMenu } = useESMenuContext();

  return (
    <>
      <ESMenuItem onClick={open}>{t('rowActionsDeleteItemLabel')}</ESMenuItem>
      <BrokerDeleteLocation
        isOpen={dialogProps.isOpen}
        onClose={() => {
          closeESMenu?.();
        }}
        locationId={locationId}
      />
    </>
  );
};
