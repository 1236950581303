import { Chip, Stack } from '@mui/material';
import {
  endOfDay,
  startOfDay,
  startOfWeek,
  endOfWeek,
  subWeeks,
} from 'date-fns';
import { useTranslation } from 'react-i18next';
import { DateRange } from './date-range';

interface DateShortcutButtonsProps {
  onDateRangeChange: (newRange: DateRange) => void;
}

export const DateShortcutButtons: React.FC<DateShortcutButtonsProps> = ({
  onDateRangeChange,
}) => {
  const { t } = useTranslation('shared');

  return (
    <Stack
      direction={'row'}
      py={4}
      spacing={2}
      alignItems="center"
      justifyContent="center"
    >
      <DateShortcutButton
        label={t('today')}
        onClick={() => {
          const date = new Date();
          onDateRangeChange({
            startDate: startOfDay(date),
            endDate: endOfDay(date),
          });
        }}
      />
      <DateShortcutButton
        label={t('thisWeek')}
        onClick={() => {
          const date = new Date();
          onDateRangeChange({
            startDate: startOfWeek(date, { weekStartsOn: 1 }),
            endDate: endOfWeek(date, { weekStartsOn: 1 }),
          });
        }}
      />
      <DateShortcutButton
        label={t('nextWeek')}
        onClick={() => {
          const date = subWeeks(new Date(), -1);
          onDateRangeChange({
            startDate: startOfWeek(date, { weekStartsOn: 1 }),
            endDate: endOfWeek(date, { weekStartsOn: 1 }),
          });
        }}
      />
    </Stack>
  );
};

interface DateShortcutButtonProps {
  label: string;
  onClick?: () => void;
}

const DateShortcutButton: React.FC<DateShortcutButtonProps> = ({
  label,
  onClick,
}) => {
  return (
    <Chip
      label={label}
      onClick={onClick}
      sx={{
        backgroundColor: 'transparent',
        border: '1px solid',
        borderColor: 'primary.main',
        borderRadius: '100px',
        color: 'primary.main',
        fontWeight: 500,
        '&:hover': {
          cursor: 'pointer',
          backgroundColor: 'transparent',
        },
      }}
    />
  );
};
