import { TourModel } from '@energy-stacks/fleet/feature-tours-data';
import { Stack, Typography } from '@mui/material';

interface ToursQuantityCellProps {
  quantity: TourModel['quantity'];
}

export const ToursQuantityCell: React.FC<ToursQuantityCellProps> = ({
  quantity,
}) => {
  return (
    <Stack>
      {Object.entries(quantity).map(([product, amount]) => (
        <Stack key={product} direction="row" alignItems="center">
          <Typography variant="inherit" textAlign="right">
            {amount ? `${amount.toFixed(2)}t` : '---'} • {product}
          </Typography>
        </Stack>
      ))}
    </Stack>
  );
};
