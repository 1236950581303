import { useEffect } from 'react';
import { stompClientInstance } from '@energy-stacks/shared';
import { useGetChargingStationsQuery } from './chargingStationsApi';

export const useChargingStations = (options?: { pollingInterval: number }) => {
  const { data, refetch, isLoading, isError, isSuccess } =
    useGetChargingStationsQuery(undefined, options);

  useEffect(() => {
    // Since we have global sub to charging stations, we need to refetch data if not connected to stomp
    if (!stompClientInstance?.connected) {
      refetch();
    }
  }, [refetch]);

  return {
    data,
    refetch,
    isLoading,
    isError,
    isSuccess,
  };
};
