import React, { useMemo, useEffect, useState, useCallback } from 'react';
import {
  BrokerAddChargingStationFormStepName,
  brokerAddChargingStationSteps,
} from './brokerAddChargingStationSteps';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import {
  BrokerAddChargingStationFormData,
  chargingStationApiErrors,
  useAddChargePointMutation,
} from '@energy-stacks/broker/feature-charging-stations-data';
import { yupResolver } from '@hookform/resolvers/yup';
import { ESDialogContent, showSnackbar } from '@energy-stacks/core/ui';
import { brokerAddChargePointFormValidationSchema } from './brokerAddChargePointFormValidationSchema';
import { useDefaultCentralSystem } from '../shared/useDefaultCentralSystem';
import { BrokerAddChargingStationGeneralForm } from './BrokerAddChargingStationGeneralForm';
import { BrokerAddChargingStationConnectorsForm } from './BrokerAddChargingStationConnectorsForm';
import { BrokerAddChargingStationOverview } from './BrokerAddChargingStationOverview';
import { Box, useMediaQuery } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { indexOf } from 'lodash-es';
import {
  FormStepItemModel,
  FormStepper,
  translateFormSteps,
  FormStepperAction,
} from '@energy-stacks/form-stepper';

const BROKER_ADD_CHARGING_STATION_DIALOG_CONTENT_HEIGHT = 500;
export const BROKER_ADD_CHARGING_STATION_FORM_HEIGHT = 412;

interface BrokerAddChargingStationFormProps {
  display: BrokerAddChargingStationFormStepName;
  activeStep: number;
  onFormDirty: (isDirty: boolean) => void;
  onSetIsDialogOpen: (value: boolean) => void;
}

export const BrokerAddChargingStationForm: React.FC<
  BrokerAddChargingStationFormProps
> = ({ display, activeStep, onFormDirty, onSetIsDialogOpen }) => {
  const defaultCentralSystem = useDefaultCentralSystem();
  const [isMapReady, setIsMapReady] = useState(false);
  const [addChargePoint, { isLoading }] = useAddChargePointMutation();
  const navigate = useNavigate();
  const isMobile = useMediaQuery('(max-width: 699px)');

  const defaultValues: BrokerAddChargingStationFormData = {
    identityKey: '',
    chargePointName: '',
    centralSystem: {
      label: defaultCentralSystem?.displayName || '',
      value: defaultCentralSystem?.uuid || '',
    },
    location: {
      label: '',
      value: '',
      description: '',
    },
    coordinates: {
      longitude: '',
      latitude: '',
    },
    connectors: [],
  };

  const methods = useForm<BrokerAddChargingStationFormData>({
    defaultValues: defaultValues,
    mode: 'onTouched',
    resolver: yupResolver(brokerAddChargePointFormValidationSchema[display]),
  });

  const {
    formState: { isDirty: isFormDirty, isValid },
    setValue,
    handleSubmit,
    reset: resetForm,
  } = methods;

  useEffect(() => {
    onFormDirty(isFormDirty);
  }, [onFormDirty, isFormDirty]);

  useEffect(() => {
    // Patch form after central systems are fetched
    if (defaultCentralSystem) {
      setValue('centralSystem', {
        label: defaultCentralSystem?.displayName || '',
        value: defaultCentralSystem?.uuid || '',
      });
    }
  }, [defaultCentralSystem, setValue]);

  const handleClose = useCallback(() => {
    onSetIsDialogOpen(false);
    navigate(-1);
    resetForm();
  }, [navigate, onSetIsDialogOpen, resetForm]);

  const addChargingStationForms: FormStepItemModel<BrokerAddChargingStationFormStepName> =
    useMemo(
      () => ({
        generalDetails: (
          <BrokerAddChargingStationGeneralForm
            onSetIsMapReady={setIsMapReady}
          />
        ),
        addConnectors: <BrokerAddChargingStationConnectorsForm />,
        overview: <BrokerAddChargingStationOverview />,
      }),
      []
    );

  const onSubmit: SubmitHandler<BrokerAddChargingStationFormData> = (data) => {
    addChargePoint({
      identityKey: data.identityKey,
      chargingStationName: data.chargePointName || undefined,
      csmsUuid: data.centralSystem.value,
      coordinates:
        data.coordinates.longitude !== '' && data.coordinates.latitude !== ''
          ? data.coordinates
          : null,
      connectors: data.connectors.reverse().map((connector) => {
        const connectorId = indexOf(data.connectors, connector) + 1;
        return {
          id: connectorId,
          standard: connector.type.value || null,
          evseId: connector.evseId || null,
          format: connector.connectorFormat.value.toLocaleUpperCase() || null,
          power_type: connector.powerType.value || null,
          max_voltage: Number(connector.maxVoltage),
          max_amperage: Number(connector.maxAmperage),
          max_electric_power: Number(connector.maxPower),
          tariff_id: connector.tariffId || null,
        };
      }),
      locationUuid: data.location.value || null,
    })
      .unwrap()
      .then(() => {
        showSnackbar(
          'success',
          'addChargingStationSuccess',
          'chargingStations'
        );
        handleClose();
      })
      .catch((error) => {
        showSnackbar(
          'error',
          chargingStationApiErrors[error.data?.errorCode],
          'chargingStations'
        );
      });
  };

  return (
    <FormProvider {...methods}>
      <form
        id="add-charging-station-form"
        onSubmit={handleSubmit(onSubmit)}
        noValidate
      >
        <ESDialogContent
          sx={{
            flexGrow: 1,
          }}
        >
          <FormStepper
            formSteps={translateFormSteps(
              brokerAddChargingStationSteps,
              'chargingStations'
            )}
            activeStep={activeStep}
            fullWidth
          />
          <Box
            sx={{
              pt: 10,
              height: isMobile
                ? 'auto'
                : `${BROKER_ADD_CHARGING_STATION_DIALOG_CONTENT_HEIGHT}px`,
            }}
          >
            {addChargingStationForms[display]}
          </Box>
          <Box sx={{ mt: 10, pb: 6 }}>
            <FormStepperAction
              activeStep={activeStep}
              formSteps={brokerAddChargingStationSteps}
              disabled={!isValid || !isMapReady || isLoading}
              submitFormId="add-charging-station-form"
            />
          </Box>
        </ESDialogContent>
      </form>
    </FormProvider>
  );
};
