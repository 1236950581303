import { useTranslation } from 'react-i18next';
import { IconDownload } from '@tabler/icons-react';
import { ESButton } from '@energy-stacks/core/ui';

export const ImportToursButton = () => {
  const [t] = useTranslation('tours');
  return (
    <ESButton
      data-testid="importTours"
      onClick={() => {}}
      startIcon={<IconDownload />}
      disabled
    >
      {t('importTours')}
    </ESButton>
  );
};
