import { useNavigate } from 'react-router-dom';
import { ESButton } from '@energy-stacks/core/ui';
import { IconPlus } from '@tabler/icons-react';
import { FleetRoutes } from '@energy-stacks/fleet/shared';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '@energy-stacks/fleet/store';
import { Fab } from '@mui/material';
import { ESTooltip, useAppLocation } from '@energy-stacks/shared';
import { useCallback } from 'react';

export const NewTourButton = () => {
  const sidebarOpened = useAppSelector((state) => state.sidebar.sidebarOpened);
  const navigate = useNavigate();
  const location = useAppLocation();
  const { t } = useTranslation('tours');

  const onClickHandler = useCallback(() => {
    navigate(FleetRoutes.CreateTour, {
      state: { background: location, shouldBackgroundFreeze: true },
    });
  }, [location, navigate]);

  if (!sidebarOpened) {
    return (
      <ESTooltip title={t('newTourButtonLabel')} placement="right">
        <Fab
          data-testid="newTourSidebarButton"
          color="primary"
          size="medium"
          sx={{ boxShadow: 'none' }}
          onClick={onClickHandler}
        >
          <IconPlus />
        </Fab>
      </ESTooltip>
    );
  }

  return (
    <ESButton
      data-testid="newTourSidebarButton"
      onClick={onClickHandler}
      sx={{
        width: '100%',
      }}
      startIcon={<IconPlus />}
    >
      {t('newTourButtonLabel')}
    </ESButton>
  );
};
