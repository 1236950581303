import { Box, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ChargingStationConnectionStatusDot } from './ChargingStationConnectionStatusDot';
import { ChargingStationDetailsHeaderSkeleton } from './ChargingStationDetailsHeaderSkeleton';
import { ChargingStationShadowStatusChip } from './ChargingStationShadowStatusChip';
import { ChargingStationProtocolChip } from './ChargingStationProtocolChip';
import { ChargingStationDetailsActions } from './ChargingStationDetailsActions';
import { ChargingStationDetailsActionsV201 } from './protocol-v201/ChargingStationDetailsActionsV201';
import { useChargingStationDetails } from './useChargingStationDetails';

export const ChargingStationDetailsHeader = () => {
  const { t } = useTranslation('chargingStations');

  const { chargingStationDetails } = useChargingStationDetails({
    listenToUpdates: true,
  });

  if (!chargingStationDetails) {
    return <ChargingStationDetailsHeaderSkeleton />;
  }

  return (
    <Box mb={3}>
      <Box
        display="flex"
        gap={12}
        overflow={'hidden'}
        justifyContent={'space-between'}
      >
        <Stack gap={4} direction="row" alignItems="center" overflow={'hidden'}>
          <ChargingStationConnectionStatusDot
            status={
              chargingStationDetails.csStatus === 'online'
                ? 'online'
                : 'offline'
            }
            type="chargingstation"
          />
          <Typography
            data-testid="chargingStationDetailsHeaderTitle"
            variant="h3"
            sx={{
              textTransform: 'uppercase',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {chargingStationDetails.name ?? ''}
          </Typography>

          <ChargingStationShadowStatusChip
            shadowStatus={chargingStationDetails.csStatus}
          />
          <ChargingStationProtocolChip
            protocol={chargingStationDetails.ocppProtocol}
          />
        </Stack>

        {chargingStationDetails.ocppProtocol === 'V_16' ? (
          <ChargingStationDetailsActions
            chargingStationDetails={chargingStationDetails}
          />
        ) : null}
        {chargingStationDetails.ocppProtocol === 'V_201' ? (
          <ChargingStationDetailsActionsV201
            chargingStationDetails={chargingStationDetails}
          />
        ) : null}
      </Box>
      <Stack direction="row" mt={1} gap={4} alignItems="center">
        <ChargingStationConnectionStatusDot
          status={chargingStationDetails.csmsOnline}
          type="csms"
        />
        <Typography
          data-testid="chargingStationDetailsCentralSystemName"
          color="grey.600"
          fontSize={14}
          overflow={'hidden'}
          textOverflow={'ellipsis'}
        >{`${t('centralSystem')} ${
          chargingStationDetails.csmsDisplayName
        }`}</Typography>
      </Stack>
    </Box>
  );
};
