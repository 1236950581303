import { createSlice } from '@reduxjs/toolkit';

export const USER_ACCESS_FEATURE_KEY = 'userAccess';

export interface UserAccessState {
  isFirstTimeAccess: boolean;
}

const initialState: UserAccessState = {
  isFirstTimeAccess: true,
};

export const userAccess = createSlice({
  initialState,
  name: USER_ACCESS_FEATURE_KEY,
  reducers: {
    markFirstTimeAccess: (state) => {
      state.isFirstTimeAccess = false;
    },
  },
});

export const { markFirstTimeAccess } = userAccess.actions;

export const userAccessReducer = userAccess.reducer;
