import { Box, Grid, useMediaQuery, useTheme } from '@mui/material';
import { DashboardTransactionsTable } from './transactions/DashboardTransactionsTable';
import { SuccessRateWidget } from './widgets/SuccessRateWidget';
import { ActiveSessionsWidget } from './widgets/ActiveSessionsWidget';
import { StationsAndConnectorsCardChart } from './charts/StationsAndConnectorsCardChart';
import { DashboardLocationMap } from './maps/DashboardLocationMap';
import { UptimeWidget } from './widgets/UptimeWidget';
import { useAppSelector } from '@energy-stacks/broker/store';
import { EnergyWidget } from './widgets/EnergyWidget';

export const Dashboard = () => {
  const theme = useTheme();
  const isSmallerThanLXLScreen = useMediaQuery(theme.breakpoints.down('xl'));
  const sidebarOpened = useAppSelector((state) => state.sidebar.sidebarOpened);

  return (
    <>
      <Box
        sx={{
          paddingBottom: 6,
          display: 'flex',
          flexDirection: 'column',
          gap: 6,
          marginTop: -3.5,
        }}
      >
        <Grid container spacing={6}>
          <Grid item xl={6} lg={12} md={12} sm={12} xs={12}>
            <Grid container item spacing={6} sx={{ pt: 3 }}>
              <Grid item md={6} sm={sidebarOpened ? 12 : 6} xs={12}>
                <SuccessRateWidget />
              </Grid>
              <Grid item md={6} sm={sidebarOpened ? 12 : 6} xs={12}>
                <EnergyWidget />
              </Grid>
              <Grid item md={6} sm={sidebarOpened ? 12 : 6} xs={12}>
                <ActiveSessionsWidget />
              </Grid>
              <Grid item md={6} sm={sidebarOpened ? 12 : 6} xs={12}>
                <UptimeWidget />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xl={6} lg={12} md={12} sm={12} xs={12}>
            <Box pt={3}>
              <DashboardLocationMap />
            </Box>
          </Grid>
        </Grid>

        <Grid item container xs={12}>
          <StationsAndConnectorsCardChart />
        </Grid>
      </Box>

      <Grid
        item
        xs={12}
        sx={{
          display: 'flex',
          flex: 1,
          minHeight: isSmallerThanLXLScreen ? '600px' : 'unset',
        }}
      >
        <DashboardTransactionsTable />
      </Grid>
    </>
  );
};
