import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import {
  translationsEn as sharedTranslationsEn,
  translationsDe as sharedTranslationsDe,
  chargingStationsTranslationsEn,
  chargingStationsTranslationsDe,
  dashboardTranslationsDe,
  dashboardTranslationsEn,
} from '@energy-stacks/shared';

import {
  translationsEn as fleetSharedTranslationsEn,
  translationsDe as fleetSharedTranslationsDe,
} from '@energy-stacks/fleet/shared';

import {
  vehiclesTranslationsDe,
  vehiclesTranslationsEn,
} from '@energy-stacks/fleet-is/feature-vehicles';

import {
  translationsEn as userAccessTranslationsEn,
  translationsDe as userAccessTranslationsDe,
} from '@energy-stacks/user-access';

import {
  usersTranslationsEn,
  usersTranslationsDe,
} from '@energy-stacks/fleet/feature-users';

import {
  jobsTranslationsEn,
  jobsTranslationsDe,
} from '@energy-stacks/fleet/feature-jobs';

import {
  businessAccountsTranslationsEn,
  businessAccountsTranslationsDe,
} from '@energy-stacks/fleet/feature-business-accounts';

import {
  intelligentSchedulingTranslationsEn,
  intelligentSchedulingTranslationsDe,
} from '@energy-stacks/fleet-is/feature-intelligent-scheduling';

import {
  chargingScheduleTranslationsEn,
  chargingScheduleTranslationsDe,
} from '@energy-stacks/fleet-is/feature-charging-schedule-create';
import {
  chargingScheduleResultTranslationsEn,
  chargingScheduleResultTranslationsDe,
} from '@energy-stacks/fleet-is/feature-charging-schedule';

import {
  toursTranslationsEn,
  toursTranslationsDe,
} from '@energy-stacks/fleet-is/feature-tours';

enum Language {
  EN = 'en',
  DE = 'de',
}

export const resources = {
  [Language.EN]: {
    tours: toursTranslationsEn,
    userAccess: userAccessTranslationsEn,
    vehicles: vehiclesTranslationsEn,
    fleetShared: fleetSharedTranslationsEn,
    shared: sharedTranslationsEn,
    users: usersTranslationsEn,
    businessAccounts: businessAccountsTranslationsEn,
    intelligentScheduling: intelligentSchedulingTranslationsEn,
    dashboard: dashboardTranslationsEn,
    chargingStations: chargingStationsTranslationsEn,
    chargingSchedule: chargingScheduleTranslationsEn,
    chargingScheduleResult: chargingScheduleResultTranslationsEn,
    jobs: jobsTranslationsEn,
  },
  [Language.DE]: {
    tours: toursTranslationsDe,
    userAccess: userAccessTranslationsDe,
    vehicles: vehiclesTranslationsDe,
    fleetShared: fleetSharedTranslationsDe,
    shared: sharedTranslationsDe,
    users: usersTranslationsDe,
    businessAccounts: businessAccountsTranslationsDe,
    intelligentScheduling: intelligentSchedulingTranslationsDe,
    dashboard: dashboardTranslationsDe,
    chargingStations: chargingStationsTranslationsDe,
    chargingScheduleResult: chargingScheduleResultTranslationsDe,
    chargingSchedule: chargingScheduleTranslationsDe,
    jobs: jobsTranslationsDe,
  },
};

// NOTE: Must be exported and used
// or else the bundler will ignore it.
export const i18nextInitialization = i18next
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    detection: {
      caches: ['localStorage'],
      order: ['localStorage'],
      lookupLocalStorage: 'i18nextLng',
    },
    fallbackLng: Language.EN,
    debug: true,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources,
  });
