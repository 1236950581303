import { ESButton, ESDialogTitle } from '@energy-stacks/core/ui';
import { useDialog } from '@energy-stacks/shared';
import { Alert, Box, Dialog, Divider, IconButton, Stack } from '@mui/material';
import { IconX } from '@tabler/icons-react';
import { useTranslation } from 'react-i18next';
import { SkippedJobsTable } from './SkippedJobsTable';
import { SkippedJob } from '@energy-stacks/fleet/feature-tours-data';
import { usePrefetch } from '@energy-stacks/fleet/feature-jobs-data';

type SkippedJobsAlertProps = {
  skippedJobs: SkippedJob[];
  totalJobsCount: number;
  onDismiss?: () => void;
};

export const SkippedJobsAlert: React.FC<SkippedJobsAlertProps> = ({
  skippedJobs,
  totalJobsCount,
  onDismiss,
}) => {
  const [t] = useTranslation('tours');
  const { open, dialogProps } = useDialog();
  const prefetchJobs = usePrefetch('getJobs');

  if (skippedJobs.length === 0) {
    return null;
  }

  return (
    <>
      <Alert
        variant="outlined"
        severity="info"
        action={
          <Stack direction="row" alignItems="center" gap={1}>
            <ESButton
              data-testid="skippedJobsAlertActionButton"
              variant="text"
              color="inherit"
              onClick={open}
              onMouseEnter={() => prefetchJobs()}
              sx={{
                '&.MuiButton-textInherit:hover': {
                  backgroundColor: 'primary.light',
                },
              }}
            >
              {t('skippedJobsAlertActionLabel').toLocaleUpperCase()}
            </ESButton>
            {onDismiss ? (
              <IconButton
                data-testid="skippedJobsDismissButton"
                onClick={onDismiss}
                color="inherit"
                sx={{
                  '&.MuiIconButton-root': {
                    '&:hover': {
                      backgroundColor: 'primary.light',
                    },
                  },
                }}
              >
                <IconX stroke={1} />
              </IconButton>
            ) : null}
          </Stack>
        }
        sx={{
          borderColor: 'primary.main',
          borderRadius: 1,
          borderStyle: 'solid',
          borderWidth: 1,
          color: 'primary.800',
          py: 0.5,
          width: '100%',
          '.MuiAlert-icon': {
            color: 'primary.800',
          },
          '.MuiAlert-action': {
            p: 0,
          },
        }}
      >
        {t('skippedJobsAlertMessage', {
          plannedJobs: totalJobsCount - skippedJobs.length,
          totalJobs: totalJobsCount,
        })}
      </Alert>
      <Dialog open={dialogProps.isOpen}>
        <Stack
          alignItems="start"
          flexDirection="row"
          justifyContent="space-between"
        >
          <ESDialogTitle>{t('skippedJobsDialogTitle')}</ESDialogTitle>
          <IconButton
            data-testid="skippedJobsCloseDialogButton"
            onClick={dialogProps.onClose}
            sx={{ m: 2 }}
          >
            <IconX stroke={1} />
          </IconButton>
        </Stack>
        <Divider />
        <Box sx={{ p: 6, pt: 'unset' }}>
          <SkippedJobsTable skippedJobs={skippedJobs} />
        </Box>
      </Dialog>
    </>
  );
};
