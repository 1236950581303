import { ESButton, ESButtonProps } from '../esbutton/ESButton';

interface ESDialogActionButtonProps extends ESButtonProps {
  testId?: string;
}

export const ESDialogActionButton: React.FC<ESDialogActionButtonProps> = ({
  children,
  testId,
  sx,
  ...rest
}) => {
  return (
    <ESButton data-testid={`${testId}Button`} variant="text" sx={sx} {...rest}>
      {children}
    </ESButton>
  );
};
