import { useState } from 'react';
import { TourInfo } from '../tour-details/TourDetails';
import { LeftArrow } from './LeftArrow';
import { ToursList } from './ToursList';

type ToursMapSideListProps = {
  onTourFocus: (tour: TourInfo) => void;
  tours: TourInfo[];
  focusedTour?: TourInfo;
};

export const SIDE_LIST_WIDTH = 320;

export const ToursMapSideList: React.FC<ToursMapSideListProps> = ({
  onTourFocus,
  tours,
  focusedTour,
}) => {
  const [sideListOpened, setSideListOpened] = useState(tours.length > 2);

  return (
    <div
      style={{
        position: 'absolute',
        top: 0,
        left: 0,
        height: '100%',
        width: sideListOpened ? SIDE_LIST_WIDTH : 0,
        backgroundColor: 'white',
        transition: 'width 400ms ease-in-out',
        zIndex: 1,
      }}
    >
      <div
        onClick={() => {
          setSideListOpened(!sideListOpened);
        }}
        role="button"
        style={{
          cursor: 'pointer',
          position: 'absolute',
          top: 'calc(50% - 24px)',
          right: -25,
          width: 25,
          height: 48,
          padding: '13.5px 0px 0px 0px',
          borderRadius: '0px 50px 50px 0px',
          backgroundColor: '#EEF2F6',
          zIndex: 1,
        }}
      >
        <div style={{ marginLeft: -2 }}>
          <LeftArrow />
        </div>
      </div>
      <ToursList
        tours={tours}
        focusedTour={focusedTour}
        onTourFocus={onTourFocus}
      />
    </div>
  );
};
