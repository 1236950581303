import { memo, useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import {
  ESTable,
  ESTableBodyCell,
  ESTableBodyCompose,
  ESTableBodyRow,
  ESTableHead,
  ESTablePagination,
  ESTableWrapper,
  useESTableBasic,
} from '@energy-stacks/core/ui';
import { Box, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ConfigurationMenu } from './ConfigurationMenu';
import { SaveConfiguration } from './SaveConfiguration';
import { filter, isEqual } from 'lodash-es';
import { NewConfigurationField } from './NewConfigurationField';
import { useForm } from 'react-hook-form';
import { createColumnHelper, flexRender, Row } from '@tanstack/react-table';
import {
  setConfiguration,
  useAppDispatch,
  useAppSelector,
} from '@energy-stacks/broker/store';
import {
  TableSearchField,
  NoTableData,
  ESTooltip,
  formatDateTime,
  TimeDistance,
} from '@energy-stacks/shared';
import { yupResolver } from '@hookform/resolvers/yup';
import { ConfigurationFormEntry } from './configurationFormEntry';
import { useBrokerFeatureGuard } from '@energy-stacks/broker/feature-user-access';
import { ConfigurationModel } from '@energy-stacks/broker/feature-charging-station-management-data';
import { BrokerRoutes } from '@energy-stacks/broker/shared';

export interface ConfigurationFormData {
  configuration: ConfigurationFormEntry[];
}

interface ConfigurationTableProps {
  configuration: ConfigurationModel[];
}

const editConfigurationSchema = yup.object().shape({
  configuration: yup.array().of(
    yup.object().shape({
      key: yup.string(),
      newValue: yup.string().min(1),
    })
  ),
});

const ConfigurationTableNonMemoized: React.FC<ConfigurationTableProps> = ({
  configuration,
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [t] = useTranslation('chargingStations');

  const { control, getValues, reset, watch } = useForm<ConfigurationFormData>({
    mode: 'onTouched',
    defaultValues: {
      configuration: configuration.map((config) => ({
        key: config.key,
        newValue: '',
        oldValue: config.currentValue,
      })),
    },
    resolver: yupResolver(editConfigurationSchema),
  });

  const columnHelper = createColumnHelper<ConfigurationModel>();
  const updateConfigurationEnabled = useBrokerFeatureGuard('saveConfiguration');

  const columns = [
    columnHelper.accessor('key', {
      header: () => t('configurationKey'),
      footer: (props) => props.column.id,
      cell: (info) => info.getValue() || '-',
    }),
    columnHelper.accessor('currentValue', {
      sortingFn: 'alphanumeric',
      header: () => t('currentValue'),
      footer: (props) => props.column.id,
      cell: (info) => info.getValue() || '-',
    }),
    columnHelper.display({
      id: 'newValue',
      header: () => t('newValue'),
      footer: (props) => props.column.id,
      cell: (info) => {
        const { index } = info.cell.row;
        return (
          <NewConfigurationField
            control={control}
            index={index}
            placeholder={info?.row?.original?.currentValue ?? ''}
            sx={{
              visibility:
                info?.row?.original?.readOnly || !updateConfigurationEnabled
                  ? 'hidden'
                  : 'visible',
            }}
          />
        );
      },
    }),
  ];

  const {
    instance,
    rowsPerPageOptions,
    rows,
    globalFilter,
    onGlobalFilterChange,
  } = useESTableBasic(configuration, columns);
  const hasRows = rows.length !== 0;

  const saveConfiguration = () => {
    navigate(BrokerRoutes.ChargingStationDetailsConfigurationTabEdit);
  };

  const resetForm = useCallback(() => {
    reset({
      configuration: configuration.map((config) => {
        return {
          key: config.key,
          newValue: '',
          oldValue: config.currentValue,
        };
      }),
    });
  }, [configuration, reset]);

  useEffect(() => {
    /**
     * To disable "Send" button until at least one configuration key is changed, we listen to form changes. "Send" button
     * reads this newly submitted "payload" and sets itself to "enabled" if at least one configuration key is changed. This is
     * done to prevent unnecessary renders of the table, which would happen if we called useWatch('configuration') directly.
     */
    const subscription = watch((value, { name }) => {
      if (name === 'configuration' && value.configuration) {
        const newConfiguration = filter(value.configuration, 'newValue')
          .map((config) => ({
            key: config?.key || '',
            newValue: config?.newValue || '',
            oldValue: config?.oldValue || '',
            id: config?.key || '',
          }))
          .filter((entry) => entry.newValue !== entry.oldValue);

        dispatch(setConfiguration(newConfiguration));
      }
    });

    return () => {
      subscription.unsubscribe();
    };
  }, [watch, dispatch, getValues, instance]);

  // rebuild default values each time main "configuration" prop is changed so filtering modified rows is supported
  useEffect(() => {
    resetForm();
  }, [resetForm]);

  const lastUpdated = configuration.reduce((a, b) => {
    return new Date(a.lastUpdate).getTime() > new Date(b.lastUpdate).getTime()
      ? a
      : b;
  });

  return (
    <>
      <Box display="flex" pb={5} sx={{ backgroundColor: 'transparent' }}>
        <Box flex={1}>
          <TableSearchField
            value={globalFilter}
            onChange={onGlobalFilterChange}
            tableInstance={instance}
          />
        </Box>
        <Stack direction="row" gap={4} alignItems="center">
          <ESTooltip title={formatDateTime(lastUpdated.lastUpdate)}>
            <Stack direction="row" gap={1}>
              <Typography fontSize={12}>{t('lastUpdated')}</Typography>
              <TimeDistance date={lastUpdated.lastUpdate} />
            </Stack>
          </ESTooltip>
          <SaveConfiguration onSave={saveConfiguration} />
          <ConfigurationMenu />
        </Stack>
      </Box>
      <ESTableWrapper>
        <ESTable>
          <ESTableHead instance={instance} />
          <ESTableBodyCompose>
            {rows.map((row) => (
              <ConfigurationTableRow row={row} key={row.original.key} />
            ))}
          </ESTableBodyCompose>
        </ESTable>

        {!hasRows ? <NoTableData /> : null}

        <ESTablePagination
          rowsPerPageOptions={rowsPerPageOptions}
          instance={instance}
        />
      </ESTableWrapper>
    </>
  );
};

export const ConfigurationTable = memo(
  ConfigurationTableNonMemoized,
  (prevProps, nextProps) => {
    return isEqual(prevProps.configuration, nextProps.configuration);
  }
);

// listens to form changes to re-render independently and toggle own selected/unselected blueish background
const ConfigurationTableRow: React.FC<{ row: Row<ConfigurationModel> }> = ({
  row,
}) => {
  const configuration = useAppSelector(
    (state) => state.configurationState.configuration
  );
  const isSelected = !!configuration.find(
    (configEntry) => configEntry.key === row.original.key
  );

  return (
    <ESTableBodyRow key={row.id} selected={isSelected}>
      {row.getVisibleCells().map((cell) => {
        return (
          <ESTableBodyCell key={cell.id}>
            {flexRender(cell.column.columnDef.cell, cell.getContext())}
          </ESTableBodyCell>
        );
      })}
    </ESTableBodyRow>
  );
};
