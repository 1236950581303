import { ChargingStationDetailsModel } from '../chargingStationDetailsModel';
import {
  ChargingStationDto,
  CSOperationStatusDto,
} from '../chargingStationDto';
import { evsesRule } from '../evsesRule';
import { chargingStationOperationalStatusNormalizer } from './chargingStationOperationalStatusNormalizer';

export function chargingStationDetailsNormalizer(
  chargingStationDto: ChargingStationDto
): ChargingStationDetailsModel {
  return {
    id: chargingStationDto.chargingStationId.toString(),
    csmsUuid: chargingStationDto.csmsUuid,
    serialNumber: chargingStationDto.chargingStationSerialNumber ?? 'N/A',
    identityKey: chargingStationDto.identityKey ?? 'N/A',
    firmware: chargingStationDto.firmware ?? '',
    model: chargingStationDto.chargingStationModel ?? 'N/A',
    name: chargingStationDto.chargingStationName ?? 'N/A',
    geolocation: chargingStationDto.coordinates
      ? {
          latitude: chargingStationDto.coordinates.latitude,
          longitude: chargingStationDto.coordinates.longitude,
        }
      : {
          latitude: '0',
          longitude: '0',
        },
    online: onlineStatusNormalizer(chargingStationDto.operationalStatus),
    operationalStatus:
      chargingStationOperationalStatusNormalizer(
        chargingStationDto.operationalStatus
      ) ?? 'unknown',
    evses: evsesRule(chargingStationDto.evses),
    location: locationNormalizer(chargingStationDto.locationDetails),
    username: chargingStationDto.userName ?? undefined,
    password: chargingStationDto.password ?? undefined,
    chargingStationGroupDetails: chargingStationGroupDetailsNormalizer(
      chargingStationDto.chargingStationGroupDetails
    ),
  };
}

const chargingStationGroupDetailsNormalizer = (chargingStationGroupDetails: {
  chargingStationGroupName: string;
  chargingStationGroupUuid: string;
}): ChargingStationDetailsModel['chargingStationGroupDetails'] => {
  if (!chargingStationGroupDetails) {
    return {
      chargingStationGroupName: undefined,
      chargingStationGroupUuid: undefined,
    };
  }

  return {
    chargingStationGroupName:
      chargingStationGroupDetails.chargingStationGroupName ?? 'N/A',
    chargingStationGroupUuid:
      chargingStationGroupDetails.chargingStationGroupUuid ?? '',
  };
};

const locationNormalizer = (locationDetails: {
  name: string;
  location_id: string;
}): ChargingStationDetailsModel['location'] => {
  if (!locationDetails) {
    return {
      id: undefined,
      name: undefined,
    };
  }
  return {
    id: locationDetails.location_id ?? '',
    name: locationDetails.name ?? 'N/A',
  };
};

function onlineStatusNormalizer(
  status: CSOperationStatusDto | undefined
): boolean {
  if (!status) return false;

  // TBD: Could be online unless it's undefined
  const statusMap: Record<CSOperationStatusDto, boolean> = {
    AVAILABLE: true,
    BLOCKED: true,
    CHARGING: true,
    OUTOFORDER: false,
  };
  return statusMap[status] ?? false;
}
