import { chargingStationGroupsNormalizer } from '@energy-stacks/csms/feature-charging-station-groups-data';
import {
  ActivityAmenity,
  ActivityAmenityEnum,
  AmenityModel,
  FoodAndDrinkAmenity,
  FoodAndDrinkAmenityEnum,
  ShoppingAmenity,
  ShoppingAmenityEnum,
  TransportationAmenity,
  TransportationAmenityEnum,
} from '../amenityModel';
import { LocationDetailsDto } from '../locationDetailsDto';
import {
  LocationAmenities,
  LocationDetailsModel,
} from '../locationDetailsModel';
import { Facilities, ParkingType } from '../locationDto';
import { LocationParkingType, LocationParkingTypeEnum } from '../locationModel';
import { addressNormalizer } from './addressNormalizer';
import { countryNormalizer } from './countryNormalizer';
import { openingHoursNormalizer } from './openingHoursNormalizer';
import { regularHoursRule } from './regularHoursNormalizer';
import { workingHoursConfigNormalizer } from './workingHoursConfigNormalizer';
import { ownerNormalizer } from './ownerNormalizer';

export const locationDetailsNormalizer = (
  locationDetailsDto: LocationDetailsDto
): LocationDetailsModel => {
  return {
    name:
      locationDetailsDto.locationAndChargingStationGroups
        .chargingStationLocation.name ?? 'N/A',
    locationId:
      locationDetailsDto.locationAndChargingStationGroups
        .chargingStationLocation.location_id ?? '',
    fullAddress: addressNormalizer(
      locationDetailsDto.locationAndChargingStationGroups
        .chargingStationLocation.address,
      locationDetailsDto.locationAndChargingStationGroups
        .chargingStationLocation.postal_code,
      locationDetailsDto.locationAndChargingStationGroups
        .chargingStationLocation.city,
      countryNormalizer(
        locationDetailsDto.locationAndChargingStationGroups
          .chargingStationLocation.country
      )
    ),
    address:
      locationDetailsDto.locationAndChargingStationGroups
        .chargingStationLocation.address,
    postalCode:
      locationDetailsDto.locationAndChargingStationGroups
        .chargingStationLocation.postal_code ?? 'N/A',
    city: locationDetailsDto.locationAndChargingStationGroups
      .chargingStationLocation.city,
    country: countryNormalizer(
      locationDetailsDto.locationAndChargingStationGroups
        .chargingStationLocation.country
    ),

    chargeWhenClosed:
      locationDetailsDto.locationAndChargingStationGroups
        .chargingStationLocation.charging_when_closed ?? false,
    energySupplier:
      locationDetailsDto.locationAndChargingStationGroups
        .chargingStationLocation.energy_mix?.supplier_name ?? 'N/A',
    greenEnergy:
      locationDetailsDto.locationAndChargingStationGroups
        .chargingStationLocation.energy_mix?.is_green_energy ?? false,
    isTwentyFourSeven:
      locationDetailsDto.locationAndChargingStationGroups
        .chargingStationLocation.opening_times?.twentyfourseven ?? false,
    timeZone:
      locationDetailsDto.locationAndChargingStationGroups
        .chargingStationLocation.time_zone ?? '',
    locationType: convertParkingTypeToLocationType(
      locationDetailsDto.locationAndChargingStationGroups
        .chargingStationLocation.parking_type
    ),
    regularHours: regularHoursRule(
      locationDetailsDto.locationAndChargingStationGroups
        .chargingStationLocation.opening_times?.regular_hours
    ),
    ...convertFacilitiesToAmenities(
      locationDetailsDto.locationAndChargingStationGroups
        .chargingStationLocation.facilities ?? []
    ),
    openingHours: openingHoursNormalizer(
      locationDetailsDto.locationAndChargingStationGroups
        .chargingStationLocation.opening_times
    ),
    workingHoursConfig: workingHoursConfigNormalizer(
      regularHoursRule(
        locationDetailsDto.locationAndChargingStationGroups
          .chargingStationLocation.opening_times?.regular_hours
      )
    ),
    chargingStationGroups: chargingStationGroupsNormalizer(
      locationDetailsDto.locationAndChargingStationGroups.chargingStationGroups
    ),
    owner: ownerNormalizer(locationDetailsDto.owner),
  };
};

const convertParkingTypeToLocationType = (
  parkingType: ParkingType | undefined
): LocationParkingType => {
  if (!parkingType) return 'other';

  return parkingLocationTypeToLocationTypeMap[parkingType];
};

const parkingLocationTypeToLocationTypeMap: Record<
  ParkingType,
  LocationParkingType
> = {
  ALONG_MOTORWAY: LocationParkingTypeEnum.alongMotorway,
  ON_DRIVEWAY: LocationParkingTypeEnum.driveway,
  ON_STREET: LocationParkingTypeEnum.onStreet,
  PARKING_GARAGE: LocationParkingTypeEnum.parkingGarage,
  PARKING_LOT: LocationParkingTypeEnum.parkingLot,
  UNDERGROUND_GARAGE: LocationParkingTypeEnum.undergroundGarage,
  OTHER: LocationParkingTypeEnum.other,
} as const;

const transports = new Set<string>(Object.values(TransportationAmenityEnum));
const foodAndDrinks = new Set<string>(Object.values(FoodAndDrinkAmenityEnum));
const activities = new Set<string>(Object.values(ActivityAmenityEnum));
const shopping = new Set<string>(Object.values(ShoppingAmenityEnum));

const convertFacilitiesToAmenities = (
  facilities: Facilities[]
): LocationAmenities => {
  const amenities: LocationAmenities = {
    transportationAmenities: [],
    foodAndDrinkAmenities: [],
    activityAmenities: [],
    shoppingAmenities: [],
  };

  facilities.forEach((facility) => {
    const amenity = facilitiesToAmenitiesMap[facility];
    if (transports.has(amenity)) {
      amenities.transportationAmenities.push(amenity as TransportationAmenity);
    } else if (foodAndDrinks.has(amenity)) {
      amenities.foodAndDrinkAmenities.push(amenity as FoodAndDrinkAmenity);
    } else if (activities.has(amenity)) {
      amenities.activityAmenities.push(amenity as ActivityAmenity);
    } else if (shopping.has(amenity)) {
      amenities.shoppingAmenities.push(amenity as ShoppingAmenity);
    }
  });

  return amenities;
};

const facilitiesToAmenitiesMap: Record<Facilities, AmenityModel> = {
  BUS_STOP: 'busStop',
  TAXI_STAND: 'taxiStand',
  BIKE_SHARING: 'bikeSharing',
  TRAM_STOP: 'tramStop',
  METRO_STATION: 'metroStation',
  TRAIN_STATION: 'trainStation',
  AIRPORT: 'airport',
  CARPOOL_PARKING: 'carpoolParing',
  FUEL_STATION: 'fuelStation',
  PARKING_LOT: 'parkingLot',
  RESTAURANT: 'restaurant',
  CAFE: 'cafe',
  WIFI: 'wifi',
  SPORT: 'sport',
  RECREATION_AREA: 'recreationArea',
  NATURE: 'nature',
  MUSEUM: 'museum',
  HOTEL: 'hotel',
  MALL: 'mall',
  SUPERMARKET: 'superMarket',
} as const;
