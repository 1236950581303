import { Dispatch, FC, Fragment, SetStateAction, useRef } from 'react';
import { MarkerF } from '@react-google-maps/api';
import locationPOIMarker from './icons/locationPOIMarker.svg';
import locationFaultedPOIMarker from './icons/locationFaultedPOIMarker.svg';
import { ESMap, useCustomZoom } from '@energy-stacks/core/ui';
import { LocationPopup } from './LocationPopup';
import { BrokerLocationModel } from '@energy-stacks/broker/feature-locations-data';
import { coordsToNumbers } from '@energy-stacks/shared';
import { useGetUiBrandingQuery } from '@energy-stacks/broker/feature-settings-data';

type LocationsMapProps = {
  data: BrokerLocationModel[];
  focusedLocationId: BrokerLocationModel['uuid'] | null;
  setFocusedLocationId: Dispatch<
    SetStateAction<BrokerLocationModel['uuid'] | null>
  >;
  onNavigate?: (value: string) => void;
};

export const LocationsMap: FC<LocationsMapProps> = ({
  data,
  focusedLocationId,
  setFocusedLocationId,
  onNavigate,
}) => {
  const mapRef = useRef<google.maps.Map | undefined>();
  const { handleZoomChange } = useCustomZoom(mapRef);
  const { data: uiBranding } = useGetUiBrandingQuery();
  const hasBorderRadius =
    uiBranding?.hasBorderRadius || uiBranding?.hasBorderRadius === undefined;

  return (
    <ESMap
      hasBorderRadius={hasBorderRadius}
      onLoad={(map) => {
        mapRef.current = map;
        if (!data.length) {
          map.setCenter({
            lat: 0,
            lng: 0,
          });
          return;
        }
        // Setting Markers into the bounds
        const bounds = new window.google.maps.LatLngBounds();

        data.map((location) => {
          const convertedToLatLng: google.maps.LatLng = coordsToNumbers(
            location.coordinates
          );

          return bounds.extend(convertedToLatLng);
        });

        map.fitBounds(bounds);
      }}
      zoom={data.length ? 8 : 2}
      onZoomChanged={handleZoomChange}
      options={{
        mapTypeControl: true,
        mapTypeControlOptions: {
          style: google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
          position: google.maps.ControlPosition.TOP_LEFT,
          mapTypeIds: [
            google.maps.MapTypeId.ROADMAP,
            google.maps.MapTypeId.SATELLITE,
          ],
        },
      }}
    >
      {data.map((loc) => {
        const markerCenter = coordsToNumbers(loc.coordinates);
        return (
          <Fragment key={loc.uuid}>
            <MarkerF
              key={focusedLocationId === loc.uuid ? 'focused' : loc.uuid}
              draggable={false}
              position={markerCenter}
              icon={{
                url: loc.chargingStations.find((cs) => cs.shadow === 'faulted')
                  ? locationFaultedPOIMarker
                  : locationPOIMarker,
                scaledSize:
                  loc.uuid === focusedLocationId
                    ? new google.maps.Size(60, 60)
                    : new google.maps.Size(50, 50),
              }}
              onClick={() => {
                setFocusedLocationId(loc.uuid);
              }}
            />
            {focusedLocationId === loc.uuid && (
              <LocationPopup
                location={loc}
                onClose={() => setFocusedLocationId(null)}
                onNavigate={
                  onNavigate ? (value) => onNavigate(value) : undefined
                }
              />
            )}
          </Fragment>
        );
      })}
    </ESMap>
  );
};
