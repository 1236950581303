import { envRoot } from './envRoot';

declare global {
  interface Window {
    env: {
      NX_DOMAIN: string;
      NX_TYPE: string;
      NX_AUTH_AUTHORITY: string;
      NX_AUTH_CLIENT_ID: string;
      NX_AUTH_OPEN_ID_SCOPE: string;
      NX_GOOGLE_API_KEY: string;
      NX_STOMP_URL: string;
      NX_AUTH_USER_INFO_ENDPOINT: string;
      NX_OCPP_SERVICE_URL: string;
      NX_MOCKING: string;
      NX_MOCKING_INTEGRATION: string;
      NX_STORAGE_SERVER_URL: string;
      STORYBOOK: string;
    };
  }
  interface ImportMetaEnv {}
  interface ImportMeta {
    readonly env: ImportMetaEnv;
  }
}

export const env = {
  ...Object.entries(envRoot ?? {}).reduce((acc, [key, value]) => {
    const strippedKey = key.replace(
      envRoot['STORYBOOK']
        ? 'STORYBOOK_'
        : envRoot['EXPO']
        ? 'EXPO_PUBLIC_'
        : 'VITE_',
      'NX_'
    );
    acc[strippedKey] = value;
    return acc;
  }, {} as Record<string, string>),
  // Necessary since the env vars on CI are prefixed with REACT_APP_
  ...Object.entries((window && window['env']) ?? {}).reduce(
    (acc, [key, value]) => {
      const strippedKey = key.split('REACT_APP_')[1] as keyof Window['env'];
      acc[strippedKey] = value;
      return acc;
    },
    {} as Window['env']
  ),
};
