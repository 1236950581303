import { useAppSelector } from '@energy-stacks/store';
import { Menu, MenuItem, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { BerryThemeProvider } from '@energy-stacks/core/berry-mui';
import { useAppLocation } from '@energy-stacks/shared';
import { useCallback } from 'react';

const POPOVER_LIST_ITEM_WIDTH = 238;

const createScheduleMenuItems = [
  {
    text: 'chargingSchedule',
    path: '/create-charging-schedule',
  },
  {
    text: 'tour',
    path: '',
    disabled: true,
  },
  {
    text: 'importTours',
    path: '',
    disabled: true,
  },
];

interface CreatePopoverProps {
  anchorEl: HTMLButtonElement | null;
  popoverOpen: boolean;
  closePopover: () => void;
}

export const CreatePopover: React.FC<CreatePopoverProps> = ({
  anchorEl,
  popoverOpen,
  closePopover,
}) => {
  const themeMode = useAppSelector((state) => state.theme.themeState);
  const theme = useTheme();
  const { t } = useTranslation('intelligentScheduling');
  const navigate = useNavigate();
  const location = useAppLocation();

  const onClickHandler = useCallback(
    (itemPath: string) => {
      navigate(itemPath, {
        state: { background: location, shouldBackgroundFreeze: true },
      });
      closePopover();
    },
    [location, navigate, closePopover]
  );

  return (
    <BerryThemeProvider mode={themeMode}>
      <Menu
        id="menu-lang"
        anchorEl={anchorEl}
        disableAutoFocusItem
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        sx={{
          marginTop: 2,
        }}
        keepMounted
        open={popoverOpen}
        onClose={closePopover}
      >
        {createScheduleMenuItems.map((item) => {
          return (
            <MenuItem
              color={theme.palette.grey[600]}
              onClick={() => onClickHandler(item.path)}
              sx={{
                width: POPOVER_LIST_ITEM_WIDTH,
              }}
              disabled={item.disabled}
              key={item.text}
            >
              {t(item.text)}
            </MenuItem>
          );
        })}
      </Menu>
    </BerryThemeProvider>
  );
};
