import {
  RegularHoursDto,
  WeekDay,
  WorkingHoursDto,
} from '@energy-stacks/fleet/shared';
import { toPayloadDate } from '@energy-stacks/shared';
import { EditVehicleOperatingHoursFormData } from '../editVehicleOperatingHoursFormData';

export const regularWorkingHoursToPayload = (
  workingHours: EditVehicleOperatingHoursFormData['workingHours']['regular']
): Pick<WorkingHoursDto, 'regular_hours'> => {
  return {
    regular_hours: Object.entries(workingHours)
      .filter(([_, { isWorkingDay }]) => isWorkingDay)
      .reduce((acc, [day, { startTime, endTime }]) => {
        const periodBegin = startTime
          ? toPayloadDate(startTime, 'HH:mm')
          : null;
        const periodEnd = endTime ? toPayloadDate(endTime, 'HH:mm') : null;
        if (!periodBegin || !periodEnd) {
          return acc;
        }

        return [
          ...acc,
          {
            weekday: day as WeekDay,
            period_begin: periodBegin,
            period_end: periodEnd,
          },
        ];
      }, [] as RegularHoursDto[]),
  };
};
