import {
  AnyAction,
  CombinedState,
  Reducer,
  ThunkAction,
  ThunkDispatch,
} from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { ThemeState } from './theme.slice';
import { UserState } from './user.slice';
import { SidebarState } from './sidebar.slice';
import { StepperState } from './stepper.slice';
import { AppState } from './app.slice';

export type AppDispatch = ThunkDispatch<
  CombinedState<{
    theme: ThemeState;
    user: UserState;
    sidebar: SidebarState;
    stepper: StepperState;
    app: AppState;
  }>,
  undefined,
  AnyAction
>;
export type RootState = ReturnType<
  Reducer<
    CombinedState<{
      theme: ThemeState;
      user: UserState;
      sidebar: SidebarState;
      stepper: StepperState;
      app: AppState;
    }>,
    AnyAction
  >
>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  AnyAction
>;
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
