import { ESButton } from '@energy-stacks/core/ui';
import { useTranslation } from 'react-i18next';
import { ReactComponent as RetrieveConfigurationIcon } from './icons/retrieveConfiguration.svg';

interface RetrieveConfigurationButtonProps {
  onRetrieve: () => void;
}

export const RetrieveConfigurationButton: React.FC<
  RetrieveConfigurationButtonProps
> = ({ onRetrieve }) => {
  const [t] = useTranslation('chargingStations');
  return (
    <ESButton
      startIcon={<RetrieveConfigurationIcon fill="white" fillOpacity="1" />}
      onClick={onRetrieve}
    >
      {t('retrieveConfiguration')}
    </ESButton>
  );
};
