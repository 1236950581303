import { OptimizedTourJob } from '@energy-stacks/fleet/feature-tours-data';
import { Stack, Typography } from '@mui/material';
import { IconCoffee } from '@tabler/icons-react';
import { useTranslation } from 'react-i18next';
import { JobContaminationWarning } from '../shared/JobContaminationWarning';
import { ReactComponent as EmptyRunIcon } from './emptyRun.svg';
import { TourDetail } from './tourDetail';
import { useRemovePlantIdPrefix } from '@energy-stacks/fleet/feature-business-accounts';

const tourJobTypeToIconMap: Partial<
  Record<TourDetail['jobType'], JSX.Element>
> = {
  BREAK: <IconCoffee />,
  EMPTY_RUN: <EmptyRunIcon />,
};

export const TourJobIdCell: React.FC<{
  jobType: TourDetail['jobType'];
  jobId: OptimizedTourJob['jobId'];
  contaminationViolation?: OptimizedTourJob['contaminationViolation'];
}> = ({ jobType, jobId, contaminationViolation }) => {
  const [t] = useTranslation('tours');
  const removePlantIdPrefix = useRemovePlantIdPrefix();

  if (jobType !== 'INBOUND' && jobType !== 'OUTBOUND') {
    return (
      <Stack direction="row" alignItems="center" gap={1}>
        {tourJobTypeToIconMap[jobType]}
        <Typography fontWeight={500} color="grey.900">
          {t(`tourJobTypes.${jobType}`)}
        </Typography>
      </Stack>
    );
  }

  return contaminationViolation ? (
    <Stack alignItems="center" flexDirection="row">
      <JobContaminationWarning
        conflictingJobId={removePlantIdPrefix(
          contaminationViolation.conflictingJobId
        )}
        conflictingJobOrigin={contaminationViolation.conflictingJobOrigin}
        sx={{
          marginRight: 2,
        }}
      />
      <Typography variant="inherit">{jobId}</Typography>
    </Stack>
  ) : (
    <Typography variant="inherit">{jobId}</Typography>
  );
};
