import { useESSnackbar } from '@energy-stacks/core/ui';
import {
  TourModel,
  useDeleteTourMutation,
} from '@energy-stacks/fleet/feature-tours-data';
import { ConfirmDeleteDialog, useAppLocation } from '@energy-stacks/shared';
import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useTours } from './useTours';
import { FleetRoutes, useNavigateBack } from '@energy-stacks/fleet/shared';

export const DeleteTour = () => {
  const { t } = useTranslation('tours');
  const { tourId } = useParams<{ tourId: TourModel['tourId'] }>();
  const { data, isLoading, isSuccess } = useTours();
  const navigate = useNavigate();
  const location = useAppLocation();
  const { showSnackbar } = useESSnackbar();
  const [deleteTour] = useDeleteTourMutation();
  const handleCloseDialog = useNavigateBack(FleetRoutes.Tours);

  const tourToDelete = data?.find((tour) => tour.tourId === tourId);

  const handleDeleteTour = useCallback(() => {
    if (!tourId) {
      return;
    }
    return deleteTour(tourId)
      .unwrap()
      .then(() => {
        showSnackbar('success', 'deleteTourSuccess', 'tours');
        // In case tour is deleted from tour details page, we redirect to the tours overview
        if (
          location.state &&
          location.state.background &&
          location.state.background.pathname !== FleetRoutes.Tours
        ) {
          navigate(FleetRoutes.Tours);
          return;
        }
        handleCloseDialog();
      })
      .catch(() => {
        showSnackbar('error', undefined, 'tours');
      });
  }, [
    deleteTour,
    handleCloseDialog,
    location.state,
    navigate,
    showSnackbar,
    tourId,
  ]);

  useEffect(() => {
    if (!tourToDelete && isSuccess) {
      showSnackbar('error', 'tourNotFound', 'tours');
      handleCloseDialog();
    }
  }, [handleCloseDialog, isSuccess, navigate, showSnackbar, tourToDelete]);

  if (isLoading || !tourToDelete) {
    return null;
  }

  return (
    <ConfirmDeleteDialog
      title={t('deleteTourConfirmTitle')}
      description={
        tourToDelete.status === 'PLANNED'
          ? t('deleteTourConfirmMessage')
          : t('cannotDeleteTour')
      }
      onCancel={handleCloseDialog}
      onConfirm={handleDeleteTour}
      disabled={tourToDelete.status !== 'PLANNED'}
      fullWidth
    />
  );
};
