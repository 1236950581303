import { MessageDirectionEnum } from '@energy-stacks/broker/feature-ocpp-message-logs-data';
import {
  FilterPopupClearButton,
  TableFilterButton,
  stringEnumToArray,
} from '@energy-stacks/shared';
import { Box, Checkbox, Divider, MenuItem } from '@mui/material';
import { xor } from 'lodash-es';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MessageDirectionOption } from './MessageDirectionOption';

interface OcppMessageLogsMessageDirectionFilterProps {
  messageDirectionFilter: MessageDirectionEnum[] | undefined;
  onMessageDirectionFilterChange: (
    messageDirectionFilter: MessageDirectionEnum[] | undefined
  ) => void;
}

export const OcppMessageLogsMessageDirectionFilter: React.FC<
  OcppMessageLogsMessageDirectionFilterProps
> = ({ messageDirectionFilter, onMessageDirectionFilterChange }) => {
  const { t } = useTranslation('chargingStations');

  const [checkedFilters, setCheckedFilters] = useState<MessageDirectionEnum[]>(
    []
  );

  // // Handling filters externally e.g Clear all filters
  useEffect(() => {
    if (!messageDirectionFilter) {
      setCheckedFilters([]);
    }
  }, [messageDirectionFilter]);

  useEffect(() => {
    if (checkedFilters.length > 0) {
      onMessageDirectionFilterChange(checkedFilters);
    } else {
      onMessageDirectionFilterChange(undefined);
    }
  }, [checkedFilters, onMessageDirectionFilterChange]);

  return (
    <TableFilterButton
      testId="messageDirection"
      appliedFiltersCount={checkedFilters.length}
      title={t('messageDirection')}
      renderPopover={(closePopover) => {
        return (
          <>
            <Box>
              {stringEnumToArray(MessageDirectionEnum).map((option) => (
                <MenuItem
                  data-testid={`messageDirctionOption${option}`}
                  key={option}
                  value={option}
                  onClick={() => {
                    setCheckedFilters(
                      xor(checkedFilters, [option as MessageDirectionEnum])
                    );
                  }}
                >
                  <Checkbox
                    sx={{ p: 0, mr: 2.5 }}
                    checked={checkedFilters.includes(
                      option as MessageDirectionEnum
                    )}
                  />
                  <MessageDirectionOption
                    messageDirectionEnum={option as MessageDirectionEnum}
                  />
                </MenuItem>
              ))}
            </Box>

            <Divider />

            <FilterPopupClearButton
              onClick={() => {
                setCheckedFilters([]);
                closePopover();
              }}
              disabled={!messageDirectionFilter}
            />
          </>
        );
      }}
      isActive={Boolean(messageDirectionFilter)}
    />
  );
};
