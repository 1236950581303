import React, { useState } from 'react';
import {
  ESButton,
  ESDialogTitle,
  ESScrollIndicatorDialogContent,
  useESSnackbar,
} from '@energy-stacks/core/ui';
import { Box, Dialog, DialogActions, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useChargingStationIdentityKey } from '@energy-stacks/shared';
import {
  clearHardResetRequired,
  useAppDispatch,
} from '@energy-stacks/broker/store';
import { useHardResetChargingStationMutation } from '@energy-stacks/broker/feature-charging-station-management-data';
import { HardResetRequiredListItem } from '@energy-stacks/feature-charging-station-configuration';

interface HardResetRequiredDialogProps {
  open: boolean;
  hardResetEntries: {
    newValue: string;
    key: string;
  }[];
  onDismiss: () => void;
}

export const HardResetRequiredDialog: React.FC<
  HardResetRequiredDialogProps
> = ({ open, hardResetEntries, onDismiss }) => {
  const [confirmation, setConfirmation] = useState<'pending' | 'confirmed'>(
    'pending'
  );

  return (
    <Dialog open={open}>
      {confirmation === 'pending' ? (
        <ConfirmationPending
          hardResetEntries={hardResetEntries}
          onConfirm={() => {
            setConfirmation('confirmed');
          }}
          onDismiss={onDismiss}
        />
      ) : (
        <HardResetConfirmed
          hardResetEntries={hardResetEntries}
          onComplete={() => {
            setConfirmation('pending'); // in case we reopen the hard reset dialog, ask for confirmation again
            onDismiss();
          }}
        />
      )}
    </Dialog>
  );
};

const ConfirmationPending: React.FC<{
  hardResetEntries: {
    newValue: string;
    key: string;
  }[];
  onConfirm: () => void;
  onDismiss: () => void;
}> = ({ hardResetEntries, onConfirm, onDismiss }) => {
  const [t] = useTranslation('chargingStations');

  return (
    <>
      <ESDialogTitle>{t('rebootRequired')}</ESDialogTitle>
      <ESScrollIndicatorDialogContent sx={{ py: 6 }}>
        <Stack spacing={6}>
          <Box>{t('hardResetRequiredDescription')}</Box>

          <Stack spacing={2}>
            {hardResetEntries.map((entry) => (
              <HardResetRequiredListItem
                configurationKey={entry.key}
                newValue={entry.newValue}
                key={entry.key}
              />
            ))}
          </Stack>
        </Stack>
      </ESScrollIndicatorDialogContent>
      <DialogActions sx={{ p: 10, pt: 6 }}>
        <ESButton variant="text" color="error" onClick={onDismiss}>
          {t('doItLater')}
        </ESButton>
        <ESButton onClick={onConfirm}>{t('resetNow')}</ESButton>
      </DialogActions>
    </>
  );
};

const HardResetConfirmed: React.FC<{
  hardResetEntries: {
    newValue: string;
    key: string;
  }[];
  onComplete: () => void;
}> = ({ hardResetEntries, onComplete }) => {
  const [t] = useTranslation('chargingStations');
  const { showSnackbar } = useESSnackbar();
  const dispatch = useAppDispatch();
  const identityKey = useChargingStationIdentityKey();
  const [hardResetChargingStation, { isLoading }] =
    useHardResetChargingStationMutation();

  const handleHardReset = () => {
    hardResetChargingStation(identityKey)
      .unwrap()
      .then(() => {
        dispatch(clearHardResetRequired(identityKey));
        onComplete();
        showSnackbar('success', 'hardResetAccepted', 'chargingStations');
      })
      .catch((error) => {
        if (error.data.errorCode === 'RPC_TIMEOUT') {
          showSnackbar('error', 'rpcTimeoutError', 'chargingStations');
          return;
        }
        if (error.data.errorCode === 'RPC_ERROR') {
          showSnackbar('error', 'rpcError', 'chargingStations');
          return;
        }
        showSnackbar('error');
      });
  };

  return (
    <>
      <ESDialogTitle>{t('proceedWithHardReset')}</ESDialogTitle>
      <ESScrollIndicatorDialogContent sx={{ py: 6 }}>
        <Stack spacing={6}>
          <Box>{t('hardResetRequiredWarning')}</Box>
          <Stack spacing={2}>
            {hardResetEntries.map((entry) => (
              <HardResetRequiredListItem
                configurationKey={entry.key}
                newValue={entry.newValue}
                key={entry.key}
              />
            ))}
          </Stack>
        </Stack>
      </ESScrollIndicatorDialogContent>

      <DialogActions sx={{ p: 10, pt: 6 }}>
        <ESButton variant="text" onClick={onComplete} disabled={isLoading}>
          {t('doItLater')}
        </ESButton>

        <ESButton
          color="error"
          onClick={handleHardReset}
          loading={isLoading}
          disabled={isLoading}
        >
          {t('hardReset')}
        </ESButton>
      </DialogActions>
    </>
  );
};
