import { useGetToursQuery } from '@energy-stacks/fleet/feature-tours-data';
import { toPayloadDate } from '@energy-stacks/shared';

type GetToursConfig = {
  dateFrom?: Date;
  dateTo?: Date;
};

export const useTours = (config?: GetToursConfig) => {
  return useGetToursQuery(
    config
      ? {
          startDateFrom: config.dateFrom
            ? toPayloadDate(config.dateFrom)
            : undefined,
          endDateTo: config.dateTo ? toPayloadDate(config.dateTo) : undefined,
        }
      : undefined
  );
};
