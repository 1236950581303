import { toPayloadDate } from '@energy-stacks/shared';
import {
  AddTariffFormData,
  Restrictions,
  TariffElement,
} from './addTariffFormData';
import {
  AddTariffDataDto,
  daysOfWeek,
  PriceComponentDto,
  priceTypes,
  RestrictionsDto,
  TariffElementDto,
} from './addTariffDataDto';

export const mapAddTariffDataToPayload = (
  tariff: AddTariffFormData
): AddTariffDataDto => {
  return {
    currency: tariff.currency,
    type: tariff.type || null,
    elements: tariff.tariffElements.map(mapTariffElementToPayload),
    tariff_alt_text: [
      {
        language: 'en',
        text: tariff.name,
      },
    ],
    start_date_time: tariff.startDate ? toPayloadDate(tariff.startDate) : '',
    end_date_time: tariff.expirationDate
      ? toPayloadDate(tariff.expirationDate)
      : '',
  };
};

const mapTariffElementToPayload = (
  element: TariffElement
): TariffElementDto => {
  return {
    restrictions: element.restrictions
      ? mapRestrictionsToPayload(element.restrictions)
      : undefined,
    price_components: [mapPriceComponentsToPayload(element)],
  };
};

const mapRestrictionsToPayload = (
  restrictions: Restrictions
): RestrictionsDto => {
  return {
    day_of_week: restrictions.days.map((d) => daysOfWeek[Number(d)]),
    start_time: restrictions.startTime
      ? restrictions.startTime.toLocaleString('en', {
          hour12: false,
          hour: '2-digit',
          minute: '2-digit',
        })
      : undefined,
    end_time: restrictions.endTime
      ? restrictions.endTime.toLocaleString('en', {
          hour12: false,
          hour: '2-digit',
          minute: '2-digit',
        })
      : undefined,
  };
};

const mapPriceComponentsToPayload = (
  element: TariffElement
): PriceComponentDto => {
  return {
    type: priceTypes[3],
    price: Number(element.price),
    vat: Number(element.vat),
    step_size: 1,
  };
};
