import { WhitelistDto } from '../mobilityTokensDto';
import { MobilityTokenWhitelist } from '../mobilityTokenModel';

export const mobilityTokenWithelistToDtoNormalizer = (
  mobilityTokenWhitelist: MobilityTokenWhitelist
): WhitelistDto => {
  return whitelistDtoMap[mobilityTokenWhitelist];
};

const whitelistDtoMap: Record<MobilityTokenWhitelist, WhitelistDto> = {
  always: 'ALWAYS',
  allowed: 'ALLOWED',
  allowedOffline: 'ALLOWED_OFFLINE',
  never: 'NEVER',
};
