import { Grid, Skeleton } from '@mui/material';

export const BusinessAccountDetailsPageSkeleton = () => {
  return (
    <Grid container height="100%" spacing={6}>
      <Grid item md={4} sm={12} height="50%">
        <Skeleton height="100%" variant="rectangular" />
      </Grid>
      <Grid item md={8} sm={12} height="50%">
        <Skeleton height="100%" variant="rectangular" />
      </Grid>
      <Grid item md={4} sm={12} height="50%">
        <Skeleton height="100%" variant="rectangular" />
      </Grid>
      <Grid item md={4} sm={12} height="50%">
        <Skeleton height="100%" variant="rectangular" />
      </Grid>
      <Grid item md={4} sm={12} height="50%">
        <Skeleton height="100%" variant="rectangular" />
      </Grid>
    </Grid>
  );
};
