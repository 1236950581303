import { TourJobs } from '@energy-stacks/fleet/feature-tours-data';
import { TourDetail } from './tourDetail';
import { differenceInSeconds } from 'date-fns';

export const mapTourJobsToTourDetail = (tourJobs: TourJobs): TourDetail[] => {
  return [
    ...tourJobs.jobs.map((job) => ({
      ...job,
      confirmedStartTime: job.confirmedStartTime,
      confirmedEndTime: job.confirmedEndTime,
      forecastedStartTime: null,
      forecastedEndTime: null,
      dateDropOffFrom: job.dateDropOffFrom,
      datePickUpFrom: job.datePickUpFrom,
      dateDropOffTill: job.dateDropOffTill,
      datePickUpTill: job.datePickUpTill,
    })),
    ...tourJobs.processes.map((process) => {
      return {
        jobId: process.id,
        jobType: process.type,
        supplier: {
          supplierId: '',
          supplierName: '',
        },
        origin: null,
        destination: null,
        startTime: process.startTime,
        endTime: process.endTime,
        confirmedStartTime: null,
        confirmedEndTime: null,
        forecastedStartTime: null,
        forecastedEndTime: null,
        duration: differenceInSeconds(
          new Date(process.endTime),
          new Date(process.startTime)
        ),
        product: null,
        status: undefined,
        dateDropOffFrom: null,
        datePickUpFrom: null,
        dateDropOffTill: null,
        datePickUpTill: null,
      };
    }),
  ];
};
