import { RefObject, useCallback, useEffect, useRef, useState } from 'react';

// By default zoom level is 1, but if you need decimal,
// isFractionalZoomEnabled: true needs to be set under maps options
const ZOOM_LEVEL = 0.2;

const calculateNewZoom = (
  previousZoom: number,
  currentZoom: number,
  customZoomStep: number
): number => {
  if (previousZoom < currentZoom) {
    return previousZoom + customZoomStep;
  }
  return previousZoom - customZoomStep;
};

export const useCustomZoom = (
  mapRef: RefObject<google.maps.Map | undefined>,
  customZoomStep = ZOOM_LEVEL
) => {
  // Re-render needed in order to avoid infinite loop
  const [newMapZoom, setNewMapZoom] = useState<number | undefined>();
  // Used for checking if it is scroll or click
  const previousCenter = useRef<google.maps.LatLng | undefined>();
  // Used for checking if it is + or -
  const previousZoom = useRef<number | undefined>();

  const handleZoomChange = useCallback(() => {
    if (mapRef.current) {
      const currentZoom = mapRef.current.getZoom();
      const currentCenter = mapRef.current.getCenter();

      if (currentZoom && currentCenter) {
        // Initially set previous zoom and center when they don't exist
        previousZoom.current = previousZoom.current ?? currentZoom;

        previousCenter.current = previousCenter.current ?? currentCenter;
        if (previousCenter.current === currentCenter) {
          // Handle Click zoom
          setNewMapZoom(
            calculateNewZoom(previousZoom.current, currentZoom, customZoomStep)
          );
        } else {
          // Handle Scroll zoom
          // Reset zoom and center after default scroll
          // Reason - needed because it causes misbehavior of click after scroll
          previousZoom.current = mapRef.current.getZoom();
          previousCenter.current = currentCenter;
        }
      }
    }
  }, [mapRef, customZoomStep]);

  useEffect(() => {
    if (mapRef.current && newMapZoom) {
      const currentZoom = mapRef.current.getZoom();
      if (
        // Hack - only setZoom() if ZoomLevel is not 1
        // Reason: setZoom() by default causes infinite rerender because it sets the zoom value to 1 first
        currentZoom &&
        Math.abs(newMapZoom - currentZoom) !== 1
      ) {
        mapRef.current.setZoom(newMapZoom);

        previousZoom.current = newMapZoom;
      }
    }
  }, [mapRef, newMapZoom]);

  return { handleZoomChange };
};
