import { useGetUiBrandingQuery } from '@energy-stacks/broker/feature-settings-data';
import { Chip } from '@mui/material';

interface OcppProtocolChipProps {
  label: string;
}

export const OcppProtocolChip: React.FC<OcppProtocolChipProps> = ({
  label,
}) => {
  const { data: uiBranding } = useGetUiBrandingQuery();
  const hasBorderRadius =
    uiBranding?.hasBorderRadius || uiBranding?.hasBorderRadius === undefined;

  return (
    <Chip
      label={label}
      variant="outlined"
      sx={{
        backgroundColor: 'transparent',
        color: 'grey.600',
        borderRadius: hasBorderRadius ? 9 : 0,
        borderColor: 'grey.200',
        borderWidth: '1px',
        borderStyle: 'solid',
      }}
    />
  );
};
