import { Box } from '@mui/material';

type MapWrapperProps = {
  children: React.ReactNode;
};

export const MapWrapper: React.FC<MapWrapperProps> = ({ children }) => {
  return (
    <Box
      sx={{
        position: 'relative',
        height: '100%',
        width: '100%',
        overflow: 'hidden',
        borderRadius: 3,
        border: '2px solid',
        borderColor: 'background.paper',
      }}
    >
      {children}
    </Box>
  );
};
