import { Link, Stack, Typography } from '@mui/material';
import { AccountDetailsRowInfo } from './AccountDetails';
import { useMemo } from 'react';

type AccountDetailsRowProps = {
  type?: 'address' | 'email' | 'phoneNumber';
  label: AccountDetailsRowInfo['label'];
  value: AccountDetailsRowInfo['value'];
  Icon: React.ReactNode;
};

export const AccountDetailsRow: React.FC<AccountDetailsRowProps> = ({
  type,
  label,
  value,
  Icon,
}) => {
  const href: string | undefined = useMemo(() => {
    if (!value || !type) return undefined;
    switch (type) {
      case 'address':
        return `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
          value
        )}`;
      case 'email':
        return `mailto: ${value}`;
      case 'phoneNumber':
        return `tel: ${value}`;
    }
  }, [type, value]);

  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      overflow="hidden"
    >
      <Stack
        direction="row"
        gap={3}
        alignItems="center"
        justifyContent="center"
      >
        {Icon}
        <Typography sx={{ whiteSpace: 'nowrap', marginRight: 10 }}>
          {label}
        </Typography>
      </Stack>
      {href ? (
        <Link
          target={type === 'address' ? '_blank' : '_self'}
          href={href}
          sx={{
            wordBreak: 'break-word',
            textAlign: 'right',
            '&:hover': {
              textDecoration: 'underline',
            },
          }}
        >
          {value}
        </Link>
      ) : (
        <Typography sx={{ wordBreak: 'break-word', textAlign: 'right' }}>
          {value || '---'}
        </Typography>
      )}
    </Stack>
  );
};
