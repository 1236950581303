import { TariffElement } from '@energy-stacks/csms/feature-transactions-data';

/**
 * The response type of the GET method for
 * /chargingstations/{chargingStationId}
 *
 * The Charging station object that is stored in the CSMS.
 *
 * NOTE: Some values are commented out in this file.
 * They are here for the sake of completion to match the
 * BackEnd data.
 */
export interface ChargingStationDto {
  /** Uniquely identifies the Charging Station */
  uid: string;

  /** This contains the UUID of the CSMS. */
  csmsUuid: string;

  /** Identifies the Charging Station. This is input from the user. */
  chargingStationId: number;

  /** Identifies the serial number of the Charging Station. */
  chargingStationSerialNumber: string;

  /** Identifies the name of the Charging Station. */
  chargingStationName: string;

  /** Identifies the list of the evses inside the Charging Station. */
  evses: EvseDto[];

  /** This contains a value that identifies the vendor of the Charging Station. */
  chargingStationVendor: string;

  /** This contains a value that identifies the model of the Charging Station. */
  chargingStationModel: string;

  /** This contains the firmware version of the Charging Station. */
  firmware: string;

  /** This contains the operational status of the Charging Station. */
  operationalStatus: CSOperationStatusDto;

  /** This contains the communication type of the Charging Station.*/
  communicationType: CSCommunicationTypeDto;

  /** This contains the installation Date of the Charging Station. */
  installationDate: string;

  /** This contains the installation contract person of the Charging Station. */
  contactPerson: string;

  /** This contains if the load management is available in the Charging Station. */
  loadManagementAvailable: true;

  /** This contains if the Charging Station is law compliant. */
  calibrationLawCompliant: true;

  /** This contains the authentication methods of the Charging Station. */
  authenticationMethods: CSAuthenticationMethodDto[];

  /** List of functionalities that Charging Station is capable of */
  capabilities: CSCapabilityDto[];

  /** Level on which the Charge Point is located (in garage buildings) in the locally displayed numbering scheme. */
  floorLevel: string;

  coordinates: GeolocationDto;

  /** A number/string printed on the outside of the Charging Station for visual identification. */
  physicalReference: string;

  /** Multi-language human-readable directions when more detailed information on how to reach the Charging Station from the Location is required. */
  directions: DisplayTextDto[];

  /** The restrictions that apply to the parking spot. */
  parkingRestrictions: CSParkingRestrictionsDto[];

  /** Links images related to the Charging Station such as photos or logos. */
  images: ImageDto[];

  /** The Location details object from the Charging Station DTO. */
  locationDetails: {
    name: string;
    location_id: string;
  };

  /** This contains the identity key of the Charging Station. */
  identityKey: string;

  /** The basic auth user name */
  userName: string;

  /** The basic auth password */
  password: string;

  /** Charging Station Group model, filled only with the required information. */
  chargingStationGroupDetails: ChargingStationGroupDetailsDto;
}

interface ChargingStationGroupDetailsDto {
  /** Unique Charging Station Group UUID */
  chargingStationGroupUuid: string;
  /** The Charging Station Group name */
  chargingStationGroupName: string;
}

export type CSOperationStatusDto =
  | 'AVAILABLE'
  | 'BLOCKED'
  | 'CHARGING'
  | 'OUTOFORDER';

type CSCommunicationTypeDto = 'WiFi' | 'LTE' | 'Ethernet';

type CSAuthenticationMethodDto = 'RFID' | 'APP' | 'ISO15118';

export type CSCapabilityDto = (
  | 'CHARGING_PROFILE_CAPABLE'
  | 'CHARGING_PREFERENCES_CAPABLE'
  | 'CHIP_CARD_SUPPORT'
  | 'CONTACTLESS_CARD_SUPPORT'
  | 'CREDIT_CARD_PAYABLE'
  | 'DEBIT_CARD_PAYABLE'
  | 'PED_TERMINAL'
  | 'REMOTE_START_STOP_CAPABLE'
  | 'RESERVABLE'
  | 'RFID_READER'
  | 'TOKEN_GROUP_CAPABLE'
  | 'UNLOCK_CAPABLE'
  | 'START_SESSION_CONNECTOR_REQUIRED'
)[];

export type CSParkingRestrictionsDto =
  | 'EV_ONLY'
  | 'PLUGGED'
  | 'DISABLED'
  | 'CUSTOMERS'
  | 'MOTORCYCLES';

/** This interface defines the geo location of the Charge Point. The geodetic system to be used is WGS 84.*/
export interface GeolocationDto {
  /** Latitude of the point in decimal degree. Decimal separator: '.', Regex: -?[0-9]{1,2}.[0-9]{5,7}*/
  latitude: string;

  /** Longitude of the point in decimal degree. Decimal separator: '.' Regex: -?[0-9]{1,3}.[0-9]{5,7}*/
  longitude: string;
}

/** Multi-language human-readable directions when more detailed information on how to reach the Charging Station from the Location is required. */
export interface DisplayTextDto {
  /** Language Code ISO 639-1. */
  language: string;

  /** Text to be displayed to a end user. No markup, html etc. allowed. */
  text: string;
}

/**
 * The EVSE object describes the part that controls the power supply to a single EV in a single session. It always belongs to a Location object. The object only contains directions to get from the location itself to the EVSE (i.e. floor, physical_reference or directions).
 */
export interface EvseDto {
  /** Uniquely identifies the EVSE within the CPOs platform (and suboperator platforms). For example a database ID or the actual "EVSE ID". This field can never be changed, modified or renamed. This is the 'technical' identification of the EVSE, not to be used as 'human readable' identification, use the field evse_id for that.This field is named uid instead of id, because id could be confused with evse_id which is an eMI3 defined field. */
  uid: string;
  /** Indicates the current status of the EVSE. */
  status: EvseDtoStatus;

  connectors: ConnectorDto[];

  /** Compliant with the following specification for EVSE ID from "eMI3 standard version V1.0" (http://emi3group.com/documents-links/) "Part 2: business objects." Optional because: if an evse_id is to be re-used in the real world, the evse_id can be removed from an EVSE object if the status is set to REMOVED. */
  evse_id: string;

  status_schedule: StatusScheduleDto[];

  /** Timestamp when this EVSE or one of its Connectors was last updated (or created). */
  last_updated: string;
}

export type EvseDtoStatus =
  | 'AVAILABLE'
  | 'BLOCKED'
  | 'CHARGING'
  | 'INOPERATIVE'
  | 'OUTOFORDER'
  | 'PLANNED'
  | 'REMOVED'
  | 'RESERVED'
  | 'UNKNOWN';

/** Indicates a planned status update of the EVSE. */
export interface StatusScheduleDto {
  status: EvseDtoStatus;

  /** Begin of the scheduled period. */
  period_begin: string;

  /** End of the scheduled period, if known. */
  period_end: string;
}

/**
 * A Connector is the socket or cable and plug available for the EV to use. A single EVSE may provide multiple Connectors but only one of them can be in use at the same time. A Connector always belongs to an EVSE object.
 */
export interface ConnectorDto {
  /** Identifier of the Connector within the EVSE. Two Connectors may have the same id as long as they do not belong to the same EVSE object. */
  id: string;

  /** The standard of the installed connector. */
  standard: ConnectorDtoStandard;

  /** The format (socket/cable) of the installed connector. */
  format: ConnectorDtoFormat;

  power_type: ConnectorDtoPowerType;

  /** Maximum voltage of the connector (line to neutral for AC_3_PHASE), in volt [V]. For example: DC Chargers might vary the voltage during charging when battery almost full. */
  max_voltage: number;

  /** Maximum amperage of the connector, in ampere [A]. */
  max_amperage: number;

  /** Maximum electric power that can be delivered by this connector, in Watts (W). When the maximum electric power is lower than the calculated value from voltage and amperage, this value should be set. For example: A DC Charge Point which can delivers up to 920V and up to 400A can be limited to a maximum of 150kW (max_electric_power = 150000). Depending on the car, it may supply max voltage or current, but not both at the same time. For AC Charge Points, the amount of phases used can also have influence on the maximum power. */
  max_electric_power: number;

  /** Identifiers of the currently valid charging tariffs. Multiple tariffs are possible, but only one of each Tariff.type can be active at the same time. Tariffs with the same type are only allowed if they are not active at the same time: start_date_time and end_date_time period not overlapping. When preference-based smart charging is supported, one tariff for every possible ProfileType should be provided. These tell the user about the options they have at this Connector, and what the tariff is for every option. For a "free of charge" tariff, this field should be set and point to a defined "free of charge" tariff. */
  tariff_ids: string[] | null;

  /** URL to the operator’s terms and conditions. */
  terms_and_conditions: string;

  /** Timestamp when this Connector was last updated (or created). */
  last_updated: string;
}

export type ConnectorDtoStandard =
  | `CHADEMO` // CHAdeMO
  // | 'CHAOJI'
  // | 'DOMESTIC_A'
  // | 'DOMESTIC_B'
  // | 'DOMESTIC_C'
  // | 'DOMESTIC_D'
  // | 'DOMESTIC_E'
  // | 'DOMESTIC_F'
  // | 'DOMESTIC_G'
  // | 'DOMESTIC_H'
  // | 'DOMESTIC_I'
  // | 'DOMESTIC_J'
  // | 'DOMESTIC_K'
  // | 'DOMESTIC_L'
  // | 'DOMESTIC_M'
  // | 'DOMESTIC_N'
  // | 'DOMESTIC_O'
  | 'GBT_AC' // Type GB/T
  // | 'GBT_DC'
  // | 'IEC_60309_2_single_16'
  // | 'IEC_60309_2_three_16'
  // | 'IEC_60309_2_three_32'
  // | 'IEC_60309_2_three_64'
  | 'IEC_62196_T1' // Type 1
  // | 'IEC_62196_T1_COMBO'
  | 'IEC_62196_T2' // Type 2
  | 'IEC_62196_T2_COMBO' // CCS
  | 'IEC_62196_T3A' // Type E/F
  // | 'IEC_62196_T3C'
  // | 'NEMA_5_20'
  // | 'NEMA_6_30'
  // | 'NEMA_6_50'
  // | 'NEMA_10_30'
  // | 'NEMA_10_50'
  // | 'NEMA_14_30'
  // | 'NEMA_14_50'
  // | 'PANTOGRAPH_BOTTOM_UP'
  // | 'PANTOGRAPH_TOP_DOWN'
  // | 'TESLA_R'
  // | 'TESLA_S'
  | 'UNKNOWN';

export type ConnectorDtoFormat = 'SOCKET' | 'CABLE';

export type ConnectorDtoPowerType =
  | 'AC_1_PHASE'
  // | 'AC_2_PHASE'
  // | 'AC_2_PHASE_SPLIT'
  | 'AC_3_PHASE'
  | 'DC';

/** Links images related to the Charging Station such as photos or logos. */
interface ImageDto {
  /** URL from where the image data can be fetched through a web browser. */
  url: string;

  /** URL from where a thumbnail of the image can be fetched through a web browser. */
  thumbnail: string;

  /** Describes what the image is used for. */
  category: ImageCategory;

  /** Image type, for example: gif, jpeg, png, svg */
  type: string;

  /** Width of the full scale image. */
  width: number;

  /** Height of the full scale image. */
  height: number;
}

type ImageCategory =
  | 'CHARGER'
  | 'ENTRANCE'
  | 'LOCATION'
  | 'NETWORK'
  | 'OPERATOR'
  | 'OTHER'
  | 'OWNER';

export type OcppProtocol = 'V_15' | 'V_16' | 'V_20' | 'V_201';

// Move to tariffs feature when it gets created
export type TariffDto = {
  id: string;
  currency: Currency;
  type: TypeOfTariff | null;
  elements: TariffElement[];
  country_code: string;
  party_id: string;
  tariff_alt_text: {
    language: string;
    text: string;
  }[];
  tariff_alt_url: string;
  min_price: PriceDto;
  max_price: PriceDto;
  start_date_time: string;
  end_date_time: string;
  energy_mix: EnergyMixDto;
  last_updated: string;
};

const typesOfTariff = [
  'AD_HOC_PAYMENT',
  'PROFILE_CHEAP',
  'PROFILE_FAST',
  'PROFILE_GREEN',
  'REGULAR',
] as const;
export type TypeOfTariff = typeof typesOfTariff[number];

type PriceDto = {
  excl_vat: number;
  incl_vat: number;
};

const currencyOptions = ['EUR'] as const;
export type Currency = typeof currencyOptions[number];

export const typesOfPrice = ['ENERGY'] as const;
export type TypeOfPrice = typeof typesOfPrice[number];

type EnergyMixDto = {
  is_green_energy: boolean;
  energy_sources: EnergySourceDto[];
  environ_impact: EnvironmentImapctDto[];
  supplier_name: string;
  energy_product_name: string;
};

type EnergySourceDto = {
  source: EnergySource;
  percentage: number;
};

const energySources = [
  'NUCLEAR',
  'GENERAL_FOSSIL',
  'COAL',
  'GAS',
  'GENERAL_GREEN',
  'SOLAR',
  'WIND',
  'WATER',
] as const;
type EnergySource = typeof energySources[number];

type EnvironmentImapctDto = {
  category: EnvironmentImapctCategory;
  amount: number;
};

const environmentImapctCategories = [
  'NUCLEAR_WASTE',
  'CARBON_DIOXIDE',
] as const;
type EnvironmentImapctCategory = typeof environmentImapctCategories[number];
