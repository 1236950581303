import { BrokerEventSource } from './BrokerEventSource';
import { ChargingStationEventSource } from './ChargingStationEventSource';
import { ArrowForwardRounded } from '@mui/icons-material';
import { Stack } from '@mui/material';

export const ChargingStationToBroker = () => {
  return (
    <Stack direction={'row'} alignItems="center" spacing={4}>
      <ChargingStationEventSource />
      <ArrowForwardRounded
        sx={{ color: (theme) => theme.palette.action.active }}
      />
      <BrokerEventSource />
    </Stack>
  );
};
