import {
  ESTable,
  ESTableBodyCell,
  ESTableBodyCompose,
  ESTableBodyRow,
  ESTableHead,
  ESTableWrapper,
} from '@energy-stacks/core/ui';
import { formatDateTime, NoTableData } from '@energy-stacks/shared';

import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';

import { SecurityEventModel } from '@energy-stacks/broker/feature-transactions-data';

export const SecurityEventsTableV201: React.FC<{
  securityEvents: SecurityEventModel[];
  testId?: string;
}> = ({ securityEvents, testId }) => {
  const { t } = useTranslation('chargingStations');

  const columnHelper = createColumnHelper<SecurityEventModel>();
  // Currently without filters and pages. After BE adds them, table needs to be changed.
  const columns = [
    columnHelper.accessor('id', {
      sortingFn: 'alphanumeric',
      header: () => t('identityKey'),
      footer: (props) => props.column.id,
      cell: (info) => info.getValue() || '-',
      enableSorting: false,
    }),
    columnHelper.accessor('type', {
      sortingFn: 'alphanumeric',
      header: () => t('type'),
      footer: (props) => props.column.id,
      cell: (info) => info.getValue() || '-',
      enableSorting: false,
    }),
    columnHelper.accessor('timestamp', {
      sortingFn: 'alphanumeric',
      header: () => t('timestamp'),
      footer: (props) => props.column.id,
      cell: (info) =>
        info.row.original.timestamp
          ? formatDateTime(info.row.original.timestamp)
          : '-',
      enableSorting: false,
    }),
  ];

  const instance = useReactTable({
    data: securityEvents ?? [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    enableColumnResizing: false,
  });
  const rows = instance.getRowModel().rows;

  const hasRows = rows.length !== 0;

  return (
    <ESTableWrapper>
      <ESTable>
        <ESTableHead testId={testId} instance={instance} />
        <ESTableBodyCompose>
          {rows.map((row, index) => {
            return (
              <ESTableBodyRow
                data-testid={`${testId}BodyRow${index}`}
                key={row.id}
              >
                {row.getVisibleCells().map((cell) => {
                  return (
                    <ESTableBodyCell key={cell.id} sx={{ wordBreak: 'unset' }}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </ESTableBodyCell>
                  );
                })}
              </ESTableBodyRow>
            );
          })}
        </ESTableBodyCompose>
      </ESTable>

      {!hasRows ? <NoTableData message={t('noData')} top={124} /> : null}
    </ESTableWrapper>
  );
};
