import {
  TariffElement,
  DayOfWeek,
} from '@energy-stacks/broker/feature-tariffs-data';
import { StackValuePair } from '@energy-stacks/core/ui';
import { Box, Divider, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

type TariffElementsViewProps = {
  tariffElements: TariffElement[];
};

type DaysOfWeek = DayOfWeek[];

export const TariffElementsView = ({
  tariffElements,
}: TariffElementsViewProps) => {
  const { t } = useTranslation('tariffs');

  function formatDaysOfWeek(daysOfWeek: DaysOfWeek) {
    const uppercaseDays = daysOfWeek?.map((day) => day.toUpperCase());

    const allDays =
      uppercaseDays?.length === 7 &&
      uppercaseDays?.every((day) =>
        [
          'MONDAY',
          'TUESDAY',
          'WEDNESDAY',
          'THURSDAY',
          'FRIDAY',
          'SATURDAY',
          'SUNDAY',
        ].includes(day)
      );

    if (allDays) {
      return t('allDays');
    } else {
      const dayNames = {
        MONDAY: t('mon'),
        TUESDAY: t('tue'),
        WEDNESDAY: t('wed'),
        THURSDAY: t('thu'),
        FRIDAY: t('fri'),
        SATURDAY: t('sat'),
        SUNDAY: t('sun'),
      };

      const abbreviatedDays = uppercaseDays
        ?.filter((day) => day in dayNames)
        .map((day) => dayNames[day as keyof typeof dayNames]);

      return abbreviatedDays.join(', ');
    }
  }

  return (
    <Box>
      {tariffElements.map((element, index) => {
        return (
          <Box key={element.restrictions.endTime + index.toString()}>
            {index > 0 ? (
              <Divider
                sx={{
                  borderStyle: 'dashed',
                  border: '1px dashed',
                  borderColor: 'grey.200',
                  mt: 2,
                }}
              />
            ) : null}
            <Typography
              variant="h4"
              fontWeight={700}
              lineHeight={1.5}
              color={'grey.900'}
              sx={{ pb: 2, pt: index > 0 ? 4 : 0 }}
            >
              {`${t('tariffElement')} ${index + 1}`}
            </Typography>
            <StackValuePair
              property={t('elementDays')}
              value={formatDaysOfWeek(element.restrictions.dayOfWeek) || '-'}
            />
            <StackValuePair
              property={t('elementStartEnd')}
              value={
                element.restrictions.startTime +
                '-' +
                element.restrictions.endTime
              }
            />

            {element?.priceComponents?.map((component, index) => {
              return (
                <Box key={component.price + index.toString()}>
                  <Divider />
                  <StackValuePair
                    property={t(`priceLabel.${component.type}`)}
                    customElement={
                      <Typography
                        variant="body1"
                        fontWeight={500}
                        lineHeight="24px"
                        color="grey.900"
                        sx={{
                          textOverflow: 'ellipsis',
                          overflow: 'hidden',
                          maxWidth: 'calc(100% - 200px)',
                        }}
                      >
                        {component.price}{' '}
                        <span style={{ fontWeight: 300 }}>
                          ({` Vat ${component.vat}%`})
                        </span>
                      </Typography>
                    }
                  />
                  <StackValuePair
                    property={t('componentType')}
                    value={t(component.type) || '-'}
                  />
                </Box>
              );
            })}
          </Box>
        );
      })}
    </Box>
  );
};
