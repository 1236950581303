import { SidebarGroup } from '@energy-stacks/core/sidebar';
import { IconBuildingCommunity } from '@tabler/icons-react';
import { FleetRoutes } from '@energy-stacks/fleet/shared';
import { useTranslation } from 'react-i18next';

export const BusinessAccountsSidebarItem = () => {
  const [t] = useTranslation('businessAccounts');
  return (
    <SidebarGroup
      groupInitialPath={FleetRoutes.BusinessAccounts}
      groupIcon={<IconBuildingCommunity />}
      groupLabel={t('sidebarItem')}
      testId="businessAccountsSidebarButton"
    ></SidebarGroup>
  );
};
