export const triggerMessageRequestOptions = [
  // Charging station level messages
  'BootNotification',
  'DiagnosticsStatusNotification',
  'FirmwareStatusNotification',
  'Heartbeat',
  // Connector level messages
  'MeterValues',
  'StatusNotification',
] as const;

export type TriggerMessageRequestOption =
  (typeof triggerMessageRequestOptions)[number];

export const triggerMessageRequestOptions201 = [
  'BootNotification',
  'LogStatusNotification',
  'FirmwareStatusNotification',
  'Heartbeat',
  'MeterValues',
  'SignChargingStationCertificate',
  'SignV2GCertificate',
  'StatusNotification',
  'TransactionEvent',
  'SignCombinedCertificate',
  'PublishFirmwareStatusNotification',
] as const;

export type TriggerMessageRequestOption201 =
  (typeof triggerMessageRequestOptions201)[number];
