import { BusinessAccountDetailsModel } from '@energy-stacks/fleet/feature-business-accounts-data';
import { AccountDetails } from './account-details/AccountDetails';
import { TanksCard } from './TanksCard';
import { RecentJobs } from './recent-jobs/RecentJobs';
import { Grid } from '@mui/material';
import { BusinessAccountDetailsCard } from './BusinessAccountDetailsCard';
import { useTranslation } from 'react-i18next';
import { BusinessAccountDetailsCardHeader } from './BusinessAccountDetailsCardHeader';
import { useLayoutEffect, useMemo, useRef, useState } from 'react';
import {
  BusinessAccountDetailsMap,
  BusinessAccountDetailsMapLocation,
} from './map/BusinessAccountDetailsMap';

type BusinessAccountDetailsProps = {
  businessAccount: BusinessAccountDetailsModel;
};

export const BusinessAccountDetails: React.FC<BusinessAccountDetailsProps> = ({
  businessAccount,
}) => {
  const { t } = useTranslation('businessAccounts');
  const gridContainerRef = useRef<HTMLDivElement>(null);

  const [itemHeight, setItemHeight] = useState(0);
  useLayoutEffect(() => {
    if (gridContainerRef.current?.clientHeight) {
      setItemHeight(gridContainerRef.current.clientHeight / 2);
    }
  }, []);

  const commonGridItemProps = useMemo(
    () => ({
      desktop: 4,
      tablet: 6,
      xs: 12,
      height: itemHeight,
    }),
    [itemHeight]
  );

  return (
    <Grid ref={gridContainerRef} container flex={1} spacing={6}>
      <Grid item {...commonGridItemProps} order={{ xs: 1 }}>
        <BusinessAccountDetailsCard
          title={t('accountDetailsCardTitle')}
          ContentComponent={
            <AccountDetails
              accountNumber={businessAccount.id}
              accountName={businessAccount.companyName}
              city={businessAccount.address.city}
              street={businessAccount.address.street}
              email={businessAccount.contact.email}
              phoneNumber={businessAccount.contact.phoneNumber}
            />
          }
        />
      </Grid>
      <Grid
        item
        {...{ desktop: 8, tablet: 12, xs: 12 }}
        height={itemHeight}
        order={{ desktop: 2, tablet: 3, xs: 2 }}
      >
        <BusinessAccountDetailsMap
          locations={[
            ...businessAccount.locations['raw'].map((location) => {
              const locationWithType: BusinessAccountDetailsMapLocation = {
                ...location,
                tankType: 'raw',
              };
              return locationWithType;
            }),
            ...businessAccount.locations['degassed'].map((location) => {
              const locationWithType: BusinessAccountDetailsMapLocation = {
                ...location,
                tankType: 'degassed',
              };
              return locationWithType;
            }),
          ]}
        />
      </Grid>
      <Grid
        item
        {...commonGridItemProps}
        order={{ desktop: 3, tablet: 2, xs: 3 }}
      >
        <BusinessAccountDetailsCard
          title={
            <BusinessAccountDetailsCardHeader
              title={t('recentJobsCardTitle')}
              count={businessAccount?.recentJobs.length}
            />
          }
          ContentComponent={
            <RecentJobs recentJobs={businessAccount.recentJobs} />
          }
        />
      </Grid>
      <Grid item {...commonGridItemProps} order={{ xs: 4 }}>
        <BusinessAccountDetailsCard
          title={
            <BusinessAccountDetailsCardHeader
              title={t('degassedTanksCardTitle')}
              count={businessAccount?.locations.degassed.length}
            />
          }
          ContentComponent={
            <TanksCard
              variant="degassed"
              tanks={businessAccount?.locations.degassed}
            />
          }
        />
      </Grid>
      <Grid item {...commonGridItemProps} order={{ xs: 5 }}>
        <BusinessAccountDetailsCard
          title={
            <BusinessAccountDetailsCardHeader
              title={t('rawTanksCardTitle')}
              count={businessAccount?.locations.raw.length}
            />
          }
          ContentComponent={
            <TanksCard variant="raw" tanks={businessAccount?.locations.raw} />
          }
        />
      </Grid>
    </Grid>
  );
};
