import { PopupTransitions } from '@energy-stacks/core/ui';
import {
  ClickAwayListener,
  IconButton,
  Paper,
  Popper,
  Stack,
} from '@mui/material';
import { IconX } from '@tabler/icons-react';

type ConnectorTariffElementsPopperProps = {
  popperContent: JSX.Element | null;
  anchorEl: HTMLElement | null;
  handleClose: (
    event:
      | React.MouseEvent<HTMLDivElement>
      | MouseEvent
      | TouchEvent
      | React.MouseEvent<HTMLButtonElement>
  ) => void;
};

export const ConnectorTariffElementsPopper = ({
  popperContent,
  anchorEl,
  handleClose,
}: ConnectorTariffElementsPopperProps) => {
  const open = Boolean(anchorEl);

  return (
    <Popper
      placeholder={null}
      placement="left"
      open={open}
      anchorEl={anchorEl}
      role={undefined}
      transition
      sx={{
        zIndex: 1400,
        minWidth: 400,
        pr: 2,
      }}
      disablePortal
    >
      {({ TransitionProps }) => (
        <ClickAwayListener onClickAway={handleClose}>
          <PopupTransitions in={open} {...TransitionProps}>
            <Paper sx={{ overflowY: 'scroll', maxHeight: 400 }}>
              {open && (
                <Stack flexDirection="column">
                  <IconButton
                    onClick={handleClose}
                    sx={{ alignSelf: 'flex-end', mt: 1.5 }}
                  >
                    <IconX stroke={1} />
                  </IconButton>
                  <Stack flexDirection="column" flex={1} sx={{ px: 4, py: 0 }}>
                    {popperContent}
                  </Stack>
                </Stack>
              )}
            </Paper>
          </PopupTransitions>
        </ClickAwayListener>
      )}
    </Popper>
  );
};
