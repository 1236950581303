import { TableContainer, Table, Paper, SxProps, Theme } from '@mui/material';
import { DragEventHandler, forwardRef } from 'react';

export const MIN_TABLE_WIDTH = 650;

interface ESTableProps {
  children: React.ReactNode;
  contentStyle?: SxProps<Theme>;
  id?: string;
  minHeight?: string | number;
  maxHeight?: string | number;
  minWidth?: string | number;
  scrollRestorationId?: string;
  onDragOver?: DragEventHandler<HTMLDivElement>;
  onDragLeave?: DragEventHandler<HTMLDivElement>;
}

export const ESTable = forwardRef<HTMLTableSectionElement, ESTableProps>(
  (
    {
      children,
      contentStyle,
      id = 'tableContainerRef',
      minHeight,
      maxHeight,
      minWidth = MIN_TABLE_WIDTH,
      scrollRestorationId,
      onDragLeave,
      onDragOver,
    },
    ref
  ) => {
    return (
      <TableContainer
        ref={ref}
        sx={{
          flex: 1,
          minHeight,
          maxHeight,
          //TODO: Check behavior on Mac -> Firefox and Safari still have the 'jumping sticky header' issue
          backfaceVisibility: 'hidden',
        }}
        id={id}
        data-scroll-restoration-id={scrollRestorationId}
        onDragOver={onDragOver}
        onDragLeave={onDragLeave}
      >
        <Table stickyHeader sx={{ minWidth, ...contentStyle }}>
          {children}
        </Table>
      </TableContainer>
    );
  }
);

export const ESTableWrapper: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  return (
    <Paper
      sx={{
        width: '100%',
        overflow: 'hidden',
        border: 'none',
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        position: 'relative',
      }}
    >
      {children}
    </Paper>
  );
};
