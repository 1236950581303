import React from 'react';
import { SecurityEventsTableV201 } from './SecurityEventsTableV201';
import {
  ESPageLoadingIndicator,
  NoGeneralDetails,
  RefetchOnError,
  useChargingStationIdentityKey,
} from '@energy-stacks/shared';
import { useGetSecurityEventsQuery } from '@energy-stacks/broker/feature-transactions-data';

export const ChargingStationDetailsSecurityEventsTabV201 = () => {
  const identityKey = useChargingStationIdentityKey();

  const {
    data: securityEvents,
    isSuccess,
    isLoading,
    isFetching,
    isError,
    refetch,
  } = useGetSecurityEventsQuery(identityKey, {
    skip: !identityKey,
    refetchOnMountOrArgChange: true,
  });

  return (
    <>
      {isError ? <RefetchOnError onRefetch={refetch} /> : null}
      {isLoading || isFetching ? <ESPageLoadingIndicator /> : null}
      {isSuccess && securityEvents ? (
        <SecurityEventsTableV201 securityEvents={securityEvents} />
      ) : null}
      {isSuccess && !securityEvents ? (
        <NoGeneralDetails onRefetch={refetch} />
      ) : null}
    </>
  );
};
