// project imports
import { Theme } from '@mui/material/styles';
import { BorderRadius } from './BerryThemeProvider';

export function componentStyleOverrides(
  theme: Theme,
  borderRadius: BorderRadius,
  outlinedFilled: boolean
) {
  const mode = theme.palette.mode;
  const bgColor =
    mode === 'dark' ? theme.palette.dark[800] : theme.palette.grey[50];
  const menuSelectedBack =
    mode === 'dark' ? theme.palette.dark[800] : theme.palette.secondary.light;
  const menuSelected =
    mode === 'dark' ? theme.palette.primary.main : theme.palette.secondary.dark;

  return {
    MuiButton: {
      styleOverrides: {
        root: {
          minWidth: '111px',
          fontWeight: '500',
          borderRadius: borderRadius.xxs,
          boxShadow: 'none',
          '&.MuiButton-sizeSmall': {
            fontSize: '12px',
            lineHeight: 'normal',
            paddingTop: '8px',
            paddingBottom: '8px',
          },
          '&.MuiButton-sizeMedium': {
            fontSize: '14px',
            lineHeight: '20px',
            paddingTop: '12px',
            paddingBottom: '12px',
          },
          '&.MuiButton-sizeLarge': {
            fontSize: '16px',
            lineHeight: '24px',
            paddingTop: '16px',
            paddingBottom: '16px',
          },
          '&.MuiButton-containedPrimary': {
            backgroundColor:
              mode === 'dark'
                ? theme.palette.primary.dark
                : theme.palette.primary.main,
            color: theme.palette.common.white,
          },
          '&.MuiButton-containedError': {
            backgroundColor:
              mode === 'dark'
                ? theme.palette.error.dark
                : theme.palette.error.main,
            color: theme.palette.common.white,
          },
          '&.MuiButton-outlined': {
            backgroundColor:
              mode === 'dark' ? theme.palette.background.paper : 'transparent',
            borderRadius: borderRadius.md,
            borderColor:
              mode === 'dark'
                ? theme.palette.text.primary + 28
                : theme.palette.grey[300],
            '&:hover': {
              backgroundColor:
                mode === 'dark'
                  ? theme.palette.background.paper
                  : 'transparent',
              borderColor:
                mode === 'dark'
                  ? theme.palette.common.white
                  : theme.palette.common.black,
            },
          },
          '&.MuiButton-textPrimary': {
            backgroundColor: 'transparent',
            color: theme.palette.primary.main,
            '&:hover': {
              backgroundColor: theme.palette.primary.light,
            },
          },
          '&.MuiButton-textError': {
            backgroundColor: 'transparent',
            color: theme.palette.error.dark,
            '&:hover': {
              backgroundColor: theme.palette.orange.light,
            },
          },
          '&.MuiButton-textInfo': {
            backgroundColor: 'transparent',
            color: theme.palette.grey[500],
            '&:hover': {
              backgroundColor: theme.palette.grey[50],
            },
          },
          '&:disabled': {
            opacity: 0.5,
          },
        },
      },
    },
    MuiPaper: {
      defaultProps: {
        variant: 'outlined' as const,
      },
      styleOverrides: {
        root: {
          backgroundImage: 'none',
        },
        rounded: {
          borderRadius: borderRadius.sm,
        },
      },
    },
    MuiCardHeader: {
      styleOverrides: {
        root: {
          color: theme.palette.text.dark,
          padding: '24px',
        },
        title: {
          fontSize: '1.125rem',
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          padding: '24px',
        },
      },
    },
    MuiCardActions: {
      styleOverrides: {
        root: {
          padding: '24px',
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          alignItems: 'center',
        },
        outlined: {
          border: '1px dashed',
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          color: theme.palette.text.primary,
          paddingTop: '10px',
          paddingBottom: '10px',
          borderRadius: borderRadius.lg,
          '&.Mui-selected': {
            color: menuSelected,
            backgroundColor: menuSelectedBack,
            '&:hover': {
              backgroundColor: menuSelectedBack,
            },
            '& .MuiListItemIcon-root': {
              color: menuSelected,
            },
          },
          '&:hover': {
            backgroundColor: menuSelectedBack,
            color: menuSelected,
            borderRadius: borderRadius.lg,
            '& .MuiListItemIcon-root': {
              color: menuSelected,
            },
          },
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          color: theme.palette.text.primary,
          minWidth: '36px',
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          backgroundColor: 'transparent',
          '&:hover': {
            backgroundColor:
              mode === 'dark'
                ? theme.palette.action.hoverOpacity
                : theme.palette.grey[50], // TODO: Replace with dark theme color when available
          },
          padding: '12px 16px',
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        primary: {
          color: theme.palette.text.primary,
          fontWeight: '400',
          fontSize: '14px',
          lineHeight: '20px',
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          '&::placeholder': {
            color: theme.palette.text.secondary,
            fontSize: '0.875rem',
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '&.Mui-disabled .MuiOutlinedInput-notchedOutline ': {
            borderColor: theme.palette.grey[200],
          },
          '&.Mui-disabled .MuiInputAdornment-root': {
            color: theme.palette.grey[300],
          },
          background: theme.palette.background.paper,
          borderRadius: borderRadius.md,
          color: theme.palette.grey[900],
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor:
              mode === 'dark'
                ? theme.palette.text.primary + 28
                : theme.palette.grey[300],
          },
          '&:hover $notchedOutline': {
            borderColor: theme.palette.grey[300],
          },
          '&.MuiInputBase-multiline': {
            padding: 1,
          },
        },
        input: {
          background: theme.palette.background.paper,
          fontWeight: '500',
          fontSize: '14px',
          lineHeight: '20px',
          padding: '14px 16px',
          borderRadius: borderRadius.md,
          '&.MuiInputBase-inputSizeSmall': {
            padding: '14px 16px',
            '&.MuiInputBase-inputAdornedStart': {
              paddingLeft: 0,
            },
          },
          '&::placeholder': {
            color: theme.palette.grey[500],
            opacity: 1,
            fontWeight: '400',
            fontSize: '14px',
            lineHeight: '20px',
          },
          '&:-webkit-autofill': {
            WebkitBoxShadow: `0 0 0 100px ${theme.palette.background.paper} inset`,
          },
        },
        inputAdornedStart: {
          paddingLeft: 4,
        },
        notchedOutline: {
          borderRadius: borderRadius.md,
        },
      },
    },
    MuiSlider: {
      styleOverrides: {
        root: {
          '&.Mui-disabled': {
            color:
              mode === 'dark'
                ? theme.palette.text.primary + 50
                : theme.palette.grey[300],
          },
        },
        mark: {
          backgroundColor: theme.palette.background.paper,
          width: '4px',
        },
        valueLabel: {
          color:
            mode === 'dark'
              ? theme.palette.primary.main
              : theme.palette.primary.light,
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          '& .MuiAutocomplete-tag': {
            background:
              mode === 'dark'
                ? theme.palette.text.primary + 20
                : theme.palette.secondary.light,
            borderRadius: borderRadius.sm,
            color: theme.palette.text.dark,
            '.MuiChip-deleteIcon': {
              color:
                mode === 'dark'
                  ? theme.palette.text.primary + 80
                  : theme.palette.secondary[200],
            },
          },
        },
        popper: {
          borderRadius: borderRadius.sm,
          boxShadow:
            '0px 8px 10px -5px rgb(0 0 0 / 20%), 0px 16px 24px 2px rgb(0 0 0 / 14%), 0px 6px 30px 5px rgb(0 0 0 / 12%)',
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: theme.palette.divider,
          opacity: mode === 'dark' ? 0.2 : 1,
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          '&:focus': {
            backgroundColor: 'transparent',
          },
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          color:
            mode === 'dark'
              ? theme.palette.dark.main
              : theme.palette.primary.dark,
          background:
            mode === 'dark'
              ? theme.palette.text.primary
              : theme.palette.primary[200],
          '& .MuiAvatar-img': {
            width: 'calc(100% + 2px)',
            height: 'calc(100% + 2px)',
          },
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          '&.MuiChip-deletable .MuiChip-deleteIcon': {
            color: 'inherit',
          },
          borderRadius: borderRadius.xs,
        },
      },
    },
    MuiTimelineContent: {
      styleOverrides: {
        root: {
          color: theme.palette.text.dark,
          fontSize: '16px',
        },
      },
    },
    MuiTreeItem: {
      styleOverrides: {
        label: {
          marginTop: 14,
          marginBottom: 14,
        },
      },
    },
    MuiTimelineDot: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
        },
      },
    },
    MuiInternalDateTimePickerTabs: {
      styleOverrides: {
        tabs: {
          backgroundColor:
            mode === 'dark'
              ? theme.palette.dark[900]
              : theme.palette.primary.light,
          '& .MuiTabs-flexContainer': {
            borderColor:
              mode === 'dark'
                ? theme.palette.text.primary + 20
                : theme.palette.primary[200],
          },
          '& .MuiTab-root': {
            color:
              mode === 'dark'
                ? theme.palette.text.secondary
                : theme.palette.grey[900],
          },
          '& .MuiTabs-indicator': {
            backgroundColor: theme.palette.primary.dark,
          },
          '& .Mui-selected': {
            color: theme.palette.primary.dark,
          },
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        flexContainer: {
          borderBottom: '1px solid',
          borderColor:
            mode === 'dark'
              ? theme.palette.text.primary + 20
              : theme.palette.grey[200],
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          // padding: '12px 0 12px 0', // TODO: Check if necessary
          border: 'none',
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          '>:not(:first-of-type)': {
            marginLeft: '25px',
          },
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          '&.MuiTableRow-root': {
            '&:hover': {
              backgroundColor:
                mode === 'dark'
                  ? theme.palette.action.hoverOpacity // TODO: Replace with dark theme color when available
                  : theme.palette.grey[50],
            },
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          borderColor:
            mode === 'dark'
              ? theme.palette.text.primary + 15
              : theme.palette.grey[100],
          '&.MuiTableCell-head': {
            fontSize: '14px',
            lineHeight: '21px',
            color: theme.palette.grey[900],
            fontWeight: 700,
            padding: `${theme.spacing(6)} ${theme.spacing(4)}`,
            backgroundColor: theme.palette.background.paper,

            '& > .MuiTableSortLabel-root': {
              '&:focus, &:hover, &.Mui-active': {
                color: theme.palette.grey[900],
              },
            },
          },
          '&.MuiTableCell-body': {
            color: theme.palette.grey[600],
            fontWeight: 500,
            fontSize: '14px',
            lineHeight: '20px',
          },
        },
      },
    },
    MuiTablePagination: {
      styleOverrides: {
        toolbar: {
          padding: 0,
        },
        selectLabel: {
          color: theme.palette.grey[600],
          fontSize: '12px',
          lineHeight: '20px',
        },
        select: {
          color: theme.palette.grey[900],
          fontSize: '12px',
          lineHeight: '20px',
        },
        displayedRows: {
          color: theme.palette.grey[900],
          fontSize: '12px',
          lineHeight: '20px',
        },
        selectIcon: {
          color: theme.palette.grey[500],
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          color: theme.palette.background.paper,
          background: theme.palette.text.primary,
          borderRadius: borderRadius.xxs,
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          fontSize: '1.25rem',
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          '&.MuiDialogContent-root': {
            padding: `${theme.spacing(4)}  ${theme.spacing(10)}`,
          },
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          textDecoration: 'none',
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          textDecoration: 'none',
          borderRadius: borderRadius.md,
          '&:last-of-type': {
            borderBottomLeftRadius: borderRadius.md,
            borderBottomRightRadius: borderRadius.md,
          },
          '&:first-of-type': {
            borderTopLeftRadius: borderRadius.md,
            borderTopRightRadius: borderRadius.md,
          },
          '.MuiAccordionSummary-root': {
            minHeight: '52px',
            '&.Mui-expanded': {
              minHeight: '52px',
            },
          },
          '.MuiAccordionSummary-content.Mui-expanded': {
            margin: '12px 0',
          },
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          borderRadius: borderRadius.xxl,
        },
      },
    },
    MuiSkeleton: {
      styleOverrides: {
        root: {
          borderRadius: borderRadius.md,
        },
      },
    },
  };
}
