import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export const PLANT_FEATURE_KEY = 'plant';

type PlantState = {
  selectedPlantId: string | null;
  plantIds: string[] | null;
};

const initialState: PlantState = {
  selectedPlantId: null,
  plantIds: [],
};

export const plantSlice = createSlice({
  name: PLANT_FEATURE_KEY,
  initialState,
  reducers: {
    setSelectedPlantId: (state, { payload }: PayloadAction<string | null>) => {
      state.selectedPlantId = payload;
    },
    setPlantIds: (state, { payload }: PayloadAction<string[] | null>) => {
      state.plantIds = payload;
    },
  },
});

export const { setSelectedPlantId, setPlantIds } = plantSlice.actions;

export const plantReducer = plantSlice.reducer;
