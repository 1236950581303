import { ConnectorModel } from '@energy-stacks/broker/feature-charging-stations-data';
import { formatCurrentAndMaxPower } from '@energy-stacks/shared';
import { Divider, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface ConnectorGeneralTabCardViewProps {
  connector: ConnectorModel;
  testId: string;
}

export const ConnectorGeneralTabCardView = ({
  connector,
  testId,
}: ConnectorGeneralTabCardViewProps) => {
  const [t] = useTranslation('chargingStations');
  return (
    <>
      <Stack
        data-testid={`connector${testId}Type`}
        paddingY={2}
        direction="row"
        justifyContent="space-between"
      >
        <Typography variant="body2" color="grey.600" fontWeight="400">
          {t('type')}
        </Typography>
        <Typography variant="body2" color="grey.900" fontWeight="500">
          {connector.connectorType
            ? t(`connectorTypes.${connector.connectorType}`)
            : 'N/A'}
        </Typography>
      </Stack>
      <Divider />
      <Stack
        data-testid={`connector${testId}Format`}
        paddingY={2}
        direction="row"
        justifyContent="space-between"
      >
        <Typography variant="body2" color="grey.600" fontWeight="400">
          {t('format')}
        </Typography>
        <Typography variant="body2" color="grey.900" fontWeight="500">
          {connector.connectorFormat ? t(connector.connectorFormat) : 'N/A'}
        </Typography>
      </Stack>

      <Divider />
      <Stack
        data-testid={`connector${testId}Power`}
        paddingY={2}
        direction="row"
        justifyContent="space-between"
      >
        <Typography variant="body2" color="grey.600" fontWeight="400">
          {t('powerType')}
        </Typography>
        <Typography variant="body2" color="grey.900" fontWeight="500">
          {connector.powerType ? t(`powerTypes.${connector.powerType}`) : 'N/A'}
        </Typography>
      </Stack>
      <Divider />
      <Stack
        data-testid={`connector${testId}ConnectorVoltage`}
        paddingY={2}
        direction="row"
        justifyContent="space-between"
      >
        <Typography variant="body2" color="grey.600" fontWeight="400">
          {t('connectorVoltage')}
        </Typography>
        <Typography variant="body2" color="grey.900" fontWeight="500">
          {`${connector.currentVoltage}/${connector.maxVoltage} V`}
        </Typography>
      </Stack>
      <Divider />
      <Stack
        data-testid={`connector${testId}ConnectorAmperage`}
        paddingY={2}
        direction="row"
        justifyContent="space-between"
      >
        <Typography variant="body2" color="grey.600" fontWeight="400">
          {t('connectorAmperage')}
        </Typography>
        <Typography variant="body2" color="grey.900" fontWeight="500">
          {`${connector.currentAmperage}/${connector.maxAmperage} A`}
        </Typography>
      </Stack>
      <Divider />
      <Stack
        data-testid={`connector${testId}ConnectorPower`}
        paddingTop={2}
        direction="row"
        justifyContent="space-between"
      >
        <Typography variant="body2" color="grey.600" fontWeight="400">
          {t('connectorPower')}
        </Typography>
        <Typography variant="body2" color="grey.900" fontWeight="500">
          {formatCurrentAndMaxPower(connector.currentPower, connector.maxPower)}
        </Typography>
      </Stack>
    </>
  );
};
