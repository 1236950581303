import { BrokerLocationModel } from '@energy-stacks/broker/feature-locations-data';
import { TFunction } from 'i18next';

export const getLocationFullAddress = (
  location: BrokerLocationModel,
  t: TFunction
) => {
  let fullAddress = '';
  if (location.address) fullAddress += location.address;
  if (location.city) fullAddress += `, ${location.city}`;
  if (location.postalCode) fullAddress += `, ${location.postalCode}`;
  if (location.country.label) fullAddress += `, ${t(location.country.label)}`;
  return fullAddress;
};
