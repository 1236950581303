import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export const USER_FEATURE_KEY = 'user';

export type LoginState =
  | 'unauthenticated'
  | 'loading'
  | 'error'
  | 'loggedIn'
  | 'accessDenied';

export type CommonUser = {
  isActive?: boolean;
  userRole?: string;
  emailAddress: string;
  displayName: string;
};

export interface UserState {
  user: CommonUser | null;
  loginState: LoginState;
  requestedAccess: boolean;
}

const initialState: UserState = {
  user: null,
  loginState: 'loading',
  requestedAccess: false,
};

export const userSlice = createSlice({
  initialState,
  name: USER_FEATURE_KEY,
  reducers: {
    loginStart: (state) => {
      state.loginState = 'loading';
    },
    loginSuccess: <T extends CommonUser>(
      state: UserState,
      { payload: user }: PayloadAction<T | null>
    ) => {
      state.user = user;
      state.loginState = 'loggedIn';
    },
    loginError: (state) => {
      state.user = null;
      state.loginState = 'error';
    },
    setAccessDeniedForUser: <T extends CommonUser>(
      state: UserState,
      { payload: user }: PayloadAction<T | null>
    ) => {
      if (user) {
        state.loginState = 'accessDenied';
        state.user = user;
      } else {
        state.loginState = 'unauthenticated';
        state.user = null;
      }
    },
    setHasRequestedAccess: (state, { payload }: PayloadAction<boolean>) => {
      state.requestedAccess = payload;
    },
  },
});

export const {
  loginSuccess,
  loginStart,
  loginError,
  setAccessDeniedForUser,
  setHasRequestedAccess,
} = userSlice.actions;

export const userReducer = userSlice.reducer;
