import { useGetAlerts } from './alerts/useGetAlerts';
import { usePagination, useSort } from '@energy-stacks/core/ui';
import { useState } from 'react';
import { AlertManagementTable } from './AlertManagementTable';
import { useTranslation } from 'react-i18next';
import { AlertStatus } from '@energy-stacks/broker/feature-alert-management-data';
import { DateRange } from '@energy-stacks/core/date-range-picker';
import {
  ESPageLoadingIndicator,
  RefetchOnError,
  useDocumentTitle,
  ESPage,
} from '@energy-stacks/shared';

const PRIMARY_SORT = 'dateCreated';

export const AlertManagementPage: React.FC = () => {
  const { t } = useTranslation('alertManagement');
  useDocumentTitle(t('documentTitle'));
  const { sorting, setSorting, sortOrder } = useSort();
  const [search, setSearch] = useState('');
  const { pagination, setPagination } = usePagination();
  const [alertStatus, setAlertStatus] = useState<AlertStatus>();
  const [dateRange, setDateRange] = useState<DateRange | undefined>(undefined);
  const { alerts, isLoading, isError, isFetching, refetch } = useGetAlerts({
    identityKey: search,
    alertStatus: alertStatus,
    filterDate: { dateFrom: dateRange?.startDate, dateTo: dateRange?.endDate },
    pagination: {
      page: pagination.pageIndex,
      size: pagination.pageSize,
    },
    sort: { id: sorting[0] ? sorting[0].id : PRIMARY_SORT, order: sortOrder },
  });

  return (
    <ESPage title={t('title')}>
      {isLoading ? <ESPageLoadingIndicator /> : null}
      {isError ? <RefetchOnError onRefetch={refetch} /> : null}
      {!isLoading && !isError && (
        <AlertManagementTable
          testId="alertManagement"
          alerts={alerts}
          onPaginationChange={setPagination}
          sorting={sorting}
          pagination={pagination}
          onSortingChange={setSorting}
          sortOrder={sortOrder}
          search={search}
          setSearch={setSearch}
          alertStatusFilter={alertStatus}
          onAlertStatusFilterChange={setAlertStatus}
          dateRangeFilter={dateRange}
          onDateRangeFilterChange={setDateRange}
          isFetching={isFetching}
          onRefetch={refetch}
        />
      )}
    </ESPage>
  );
};
