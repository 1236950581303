import { Box } from '@mui/material';

type ContainerIconProps = {
  children: React.ReactNode;
};

export const ContainerIcon = ({ children }: ContainerIconProps) => {
  return (
    <Box
      sx={{
        mr: 2,
        border: '1px solid',
        borderColor: 'dark.light',
        borderRadius: 1.5,
        display: 'flex',
        alignItems: 'center',
        p: 1.25,
        width: 30,
        height: 30,
      }}
    >
      {children}
    </Box>
  );
};
