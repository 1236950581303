import {
  ESTooltip,
  formatDateTime,
  timeAgoFromDate,
} from '@energy-stacks/shared';
import { useEffect, useRef, useState } from 'react';

interface PingTimerProps {
  date: string;
}
export const PingTimer = ({ date }: PingTimerProps) => {
  const intervalId = useRef<number>();
  // Note: No need to display counter, setCounter will cause rerender which will calculate new value.
  const [, setCounter] = useState<number>(0);

  useEffect(() => {
    if (date) {
      setCounter(0);
      window.clearInterval(intervalId.current);
      const int = window.setInterval(() => {
        setCounter((x) => x + 1);
      }, 1000);
      intervalId.current = int;
    }
  }, [date]);

  return (
    <ESTooltip title={formatDateTime(date)} placement="left">
      {timeAgoFromDate(date)}
    </ESTooltip>
  );
};
