import { useCallback, useLayoutEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { useEffectOnce } from 'usehooks-ts';

export type NonNullableUpdater<TPrevious, TResult = TPrevious> =
  | TResult
  | ((prev: TPrevious) => TResult);

export type Updater<TPrevious, TResult = TPrevious> =
  | TResult
  | ((prev?: TPrevious) => TResult);

// eslint-disable-next-line @typescript-eslint/ban-types
function isFunction(d: unknown): d is Function {
  return typeof d === 'function';
}

export function functionalUpdate<TResult>(
  updater: Updater<TResult> | NonNullableUpdater<TResult>,
  previous: TResult
): TResult {
  if (isFunction(updater)) {
    return updater(previous as TResult);
  }

  return updater;
}

const DELIMITER = '___';
const sessionsStorage = typeof window !== 'undefined' && window.sessionStorage;

type ScrollOffset = { scrollX: number; scrollY: number };
type CacheValue = Record<string, ScrollOffset>;
type CacheState = {
  cached: CacheValue;
};

type Cache = {
  state: CacheState;
  set: (updater: NonNullableUpdater<CacheState>) => void;
};

const cache: Cache = sessionsStorage
  ? (() => {
      const storageKey = 'es-scroll-restoration';
      const state: CacheState = JSON.parse(
        window.sessionStorage.getItem(storageKey) || 'null'
      ) || { cached: {} };

      return {
        state,
        set: (updater) => {
          cache.state = functionalUpdate(updater, cache.state);
          window.sessionStorage.setItem(
            storageKey,
            JSON.stringify(cache.state)
          );
        },
      };
    })()
  : (undefined as any);

type ScrollRestorationOption =
  | {
      id: string;
      getElement?: () => Element | undefined | null;
    }
  | {
      id?: string;
      getElement: () => Element | undefined | null;
    };

export const useElementScrollRestoration = (
  options: ScrollRestorationOption | undefined
): ScrollOffset => {
  const location = useLocation();
  let elementSelector = '';

  if (options) {
    if (options.id) {
      elementSelector = `[data-scroll-restoration-id="${options.id}"]`;
    } else {
      const element = options.getElement?.();
      elementSelector = getCssSelector(element);
    }
  }

  const restoreKey = location.pathname;
  const cacheKey = [restoreKey, elementSelector].join(DELIMITER);
  const elementRef = useRef<Element | null>(null);

  useEffectOnce(() => {
    const cacheEntry = cache.state.cached[cacheKey];
    if (cacheEntry) {
      elementRef.current?.scrollTo({
        left: cacheEntry.scrollX,
        top: cacheEntry.scrollY,
      });
    }
  });

  const handleScroll = useCallback(
    (event: Event) => {
      const element = event.target as HTMLElement;
      if (cache.state.cached[cacheKey]) {
        cache.set((c) => {
          return {
            ...c,
            cached: {
              ...c.cached,
              [cacheKey]: {
                scrollX: element?.scrollLeft || 0,
                scrollY: element?.scrollTop || 0,
              },
            },
          };
        });
      } else {
        cache.set((c) => ({
          ...c,
          cached: {
            ...c.cached,
            [cacheKey]: {
              scrollX: 0,
              scrollY: 0,
            },
          },
        }));
      }
    },
    [cacheKey]
  );

  useLayoutEffect(() => {
    elementRef.current = elementSelector
      ? document.querySelector(elementSelector)
      : null;
    if (elementRef.current) {
      elementRef.current.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (elementRef.current) {
        elementRef.current.removeEventListener('scroll', handleScroll);
      }
    };
  }, [cacheKey, elementSelector, location, handleScroll, options]);

  return (
    cache.state.cached[cacheKey] || {
      scrollX: 0,
      scrollY: 0,
    }
  );
};

function getCssSelector(el: any): string {
  const path = [];
  let parent: ParentNode | null = null;
  while ((parent = el.parentNode)) {
    path.unshift(
      `${el.tagName}:nth-child(${
        ([].indexOf as any).call(parent.children, el) + 1
      })`
    );
    el = parent;
  }
  return `${path.join(' > ')}`.toLowerCase();
}
