import { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRoutingBlock } from './useRoutingBlock';

export const useConfirmCloseDialog = (
  isFormDirty: boolean,
  leaveNavigation?: () => void
) => {
  const [isConfirmCloseDialogOpen, setIsConfirmCloseDialogOpen] =
    useState(false);

  const navigate = useNavigate();

  const navigationRef = useRef<(() => void) | undefined>(leaveNavigation);

  const handleOpenConfirmDialog = () => {
    if (isFormDirty) {
      setIsConfirmCloseDialogOpen(true);
    } else {
      if (navigationRef.current) {
        navigationRef.current();
      } else {
        navigate(-1);
      }
    }
  };

  const { liftBlock: handleLeaveWithoutSaving } = useRoutingBlock({
    blockAction: () => {
      handleOpenConfirmDialog();
    },
    when: isFormDirty,
    onBlockLifted: () => {
      setIsConfirmCloseDialogOpen(false);
      if (navigationRef.current) {
        navigationRef.current();
      } else {
        navigate(-1);
      }
    },
  });

  const handleStay = () => {
    setIsConfirmCloseDialogOpen(false);
  };

  return {
    isConfirmCloseDialogOpen,
    handleOpenConfirmDialog,
    handleStay,
    handleLeaveWithoutSaving,
  };
};
