import {
  Alert,
  useGetAlertsPerChargingStationQuery,
} from '@energy-stacks/broker/feature-alert-management-data';
import { GetAlertsConfig } from './useGetAlerts';
import { toPayloadDateBroker } from '@energy-stacks/shared';
import {
  DEFAULT_PAGINATION_CONFIG,
  DEFAULT_SORT_CONFIG,
} from '@energy-stacks/core/ui';

interface GetAlertsPerChargingStationConfig extends GetAlertsConfig {
  identityKey: Alert['identityKey'];
}

export const useGetAlertsPerChargingStation = (
  config: GetAlertsPerChargingStationConfig
) => {
  const {
    data: alerts,
    isSuccess,
    isLoading,
    isError,
    isFetching,
    refetch,
  } = useGetAlertsPerChargingStationQuery(
    {
      identityKey: config.identityKey,
      alertStatus: config.alertStatus || undefined,
      dateFrom: config.filterDate?.dateFrom
        ? toPayloadDateBroker(config.filterDate.dateFrom)
        : undefined,
      dateTo: config.filterDate?.dateTo
        ? toPayloadDateBroker(config.filterDate.dateTo)
        : undefined,
      page: config.pagination?.page ?? DEFAULT_PAGINATION_CONFIG.page,
      size: config.pagination?.size ?? DEFAULT_PAGINATION_CONFIG.size,
      sort: {
        id: config.sort.id ?? '',
        order: config.sort.order ?? DEFAULT_SORT_CONFIG.order,
      },
    },
    { refetchOnMountOrArgChange: true }
  );
  return { alerts, isSuccess, isError, isLoading, isFetching, refetch };
};
