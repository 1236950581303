import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export const ADD_CHARGING_LOCATION_FEATURE_KEY = 'addChargingLocation';

type AddChargingLocationSubmitStatus = 'idle' | 'loading' | 'error' | 'success';

export interface AddChargingLocationState {
  isAddChargingLocationFormValid: boolean;
  isAddChargingLocationFormDirty: boolean;
  addChargingLocationSubmitStatus: AddChargingLocationSubmitStatus;
}

const initialState: AddChargingLocationState = {
  isAddChargingLocationFormValid: false,
  isAddChargingLocationFormDirty: false,
  addChargingLocationSubmitStatus: 'idle',
};

export const addChargingLocationSlice = createSlice({
  name: ADD_CHARGING_LOCATION_FEATURE_KEY,
  initialState,
  reducers: {
    setIsAddChargingLocationFormValid: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isAddChargingLocationFormValid = payload;
    },
    setIsAddChargingLocationFormDirty: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isAddChargingLocationFormDirty = payload;
    },
    setAddChargingLocationSubmitStatus: (
      state,
      { payload }: PayloadAction<AddChargingLocationSubmitStatus>
    ) => {
      state.addChargingLocationSubmitStatus = payload;
    },
  },
});

export const {
  setIsAddChargingLocationFormValid,
  setIsAddChargingLocationFormDirty,
  setAddChargingLocationSubmitStatus,
} = addChargingLocationSlice.actions;

export const addChargingLocationReducer = addChargingLocationSlice.reducer;
