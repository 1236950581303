import {
  ESButton,
  ESDialogActions,
  ESDialogContent,
  ESDialogTitle,
} from '@energy-stacks/core/ui';
import { Dialog, Typography } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

type ContaminationToursConfirmDialogProps = {
  isOpen: boolean;
  onCancel: () => void;
  onConfirm: () => void;
  isLoading: boolean;
};

export const ContaminationToursConfirmDialog: FC<
  ContaminationToursConfirmDialogProps
> = ({ isOpen, onCancel, onConfirm, isLoading }) => {
  const [t] = useTranslation('tours');

  return (
    <Dialog open={isOpen} fullWidth maxWidth="sm">
      <ESDialogTitle>
        {t('contaminationToursConfirmDialog.title')}
      </ESDialogTitle>
      <ESDialogContent>
        <Typography>
          {t('contaminationToursConfirmDialog.description')}
        </Typography>
      </ESDialogContent>
      <ESDialogActions>
        <ESButton
          data-testid="contaminationConfirmationDialogCancelButton"
          variant="text"
          color="error"
          onClick={onCancel}
        >
          {t('contaminationToursConfirmDialog.closeButtonLabel')}
        </ESButton>
        <ESButton variant="contained" onClick={onConfirm} loading={isLoading}>
          {t('contaminationToursConfirmDialog.confirmButtonLabel')}
        </ESButton>
      </ESDialogActions>
    </Dialog>
  );
};
