import { useTranslation } from 'react-i18next';
import { usePromptDisplay } from './usePromptDisplay';

type Params = {
  shouldPrompt: boolean;
  promptMessage?: string;
  onClose: () => void;
};

export const useCloseDialogPrompt = ({
  shouldPrompt,
  promptMessage,
  onClose,
}: Params) => {
  const [t] = useTranslation('shared');

  const showCloseConfirmation = usePromptDisplay({
    onAction: onClose,
    promptMessage: promptMessage || t('closeConfirmContent'),
    shouldPrompt,
  });

  return showCloseConfirmation;
};
