export const formatDurationInSeconds = (duration: number) => {
  const hours = Math.floor(duration / 3600);
  const minutes = Math.ceil((duration % 3600) / 60);

  if (hours > 0) {
    return `${hours}h ${minutes}m`;
  } else {
    return `${minutes}m`;
  }
};
