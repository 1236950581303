import { MessageTypeEnum } from '@energy-stacks/broker/feature-ocpp-message-logs-data';
import { Stack, Typography, useTheme } from '@mui/material';
import {
  IconAccessPoint,
  IconExclamationCircle,
  IconReport,
} from '@tabler/icons-react';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface MessageTypeProps {
  messageTypeEnum: MessageTypeEnum;
  hideText?: boolean;
}

export const MessageTypeOption = ({
  messageTypeEnum,
  hideText,
}: MessageTypeProps) => {
  const { t } = useTranslation('chargingStations');
  const theme = useTheme();
  const messageTypeMap: Record<
    MessageTypeEnum,
    { icon: React.ReactElement; label: string }
  > = {
    CALL: {
      icon: <IconAccessPoint size={26} color={theme.palette.grey[500]} />,
      label: 'call',
    },
    CALL_RESULT: {
      icon: <IconReport size={26} color={theme.palette.grey[500]} />,
      label: 'callResult',
    },
    CALL_ERROR: {
      icon: <IconExclamationCircle size={26} color={theme.palette.grey[500]} />,
      label: 'callError',
    },
  };
  return (
    <Stack direction={'row'} alignItems="center" spacing={3}>
      {messageTypeMap[messageTypeEnum].icon}
      {hideText ? null : (
        <Typography>{t(messageTypeMap[messageTypeEnum].label)}</Typography>
      )}
    </Stack>
  );
};
