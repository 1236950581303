import { LocationWithState, useAppLocation } from '@energy-stacks/shared';
import { createContext, useEffect, useState } from 'react';

export const FleetLocationContext = createContext<{
  locationWithBackground: LocationWithState<unknown> | null;
  shouldBackgroundFreeze: boolean;
} | null>(null);

export const FleetLocationProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const location = useAppLocation();
  const background =
    location.state &&
    (location.state.background as LocationWithState<unknown> | null);
  const shouldBackgroundFreeze =
    location.state && Boolean(location.state.shouldBackgroundFreeze);
  const [locationWithBackground, setLocationWithBackground] =
    useState<LocationWithState<unknown> | null>(null);

  useEffect(() => {
    if (background) {
      setLocationWithBackground(location);
    } else {
      setLocationWithBackground(null);
    }
  }, [background, location]);

  return (
    <FleetLocationContext.Provider
      value={{ locationWithBackground, shouldBackgroundFreeze }}
    >
      {children}
    </FleetLocationContext.Provider>
  );
};
