import i18next from 'i18next';
import { OptionsObject, useSnackbar } from 'notistack';
import { useCallback } from 'react';
import { getI18n } from 'react-i18next';

// Use alert hook?
export const useESSnackbar = () => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  return {
    closeSnackbar,
    showSnackbar: useCallback(
      (
        alertType: OptionsObject['variant'],
        messageKey = 'genericErrorMessage',
        namespace = 'shared',
        interpolationValue?: { [key: string]: string } | undefined
      ) => {
        const isGenericError = messageKey === 'genericErrorMessage';
        const messageWithTranslation = translate(
          !isGenericError ? namespace : 'shared',
          messageKey ? messageKey : 'genericErrorMessage',
          interpolationValue
        );
        return enqueueSnackbar(messageWithTranslation, { variant: alertType });
      },
      [enqueueSnackbar]
    ),
  };
};

// TODO: Export from translations service. i18n is in the app, so it can't be exported from there
export const translate = (
  ns: string,
  key: string,
  interpolationValue?: { [key: string]: string }
) => {
  const i18nInstance = getI18n();
  const lang = i18nInstance.language;
  const t = i18next.getFixedT(lang, ns);

  return t(key, interpolationValue);
};
