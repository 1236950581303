import { VehicleChip } from '../VehicleChip';
import { Coords } from '@energy-stacks/fleet/shared';

type TourLabelProps = Coords & {
  title: string;
  colorIndex: number;
  style?: React.CSSProperties;
};

export const TourLabel = ({ title, colorIndex, style }: TourLabelProps) => {
  return (
    <div style={style}>
      <VehicleChip title={title} backgroundColor="white" index={colorIndex} />;
    </div>
  );
};
