import { brokerManagementApi } from '@energy-stacks/broker/feature-charging-station-management-data';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from './store';

export const CONFIGURATION_FEATURE_KEY = 'configurationState';

export interface ConfigurationState {
  resetChargingStationInProgress: boolean;
  configuration: { newValue: string; oldValue: string; key: string }[]; // importing ConfigurationFormEntry causes circular dependency
  stationIdentityKeysRequiringHardReset: string[];
}

const initialState: ConfigurationState = {
  resetChargingStationInProgress: false,
  configuration: [],
  stationIdentityKeysRequiringHardReset: [],
};

export const configurationSlice = createSlice({
  name: CONFIGURATION_FEATURE_KEY,
  initialState,
  reducers: {
    setConfiguration: (
      state,
      {
        payload,
      }: PayloadAction<{ newValue: string; oldValue: string; key: string }[]>
    ) => {
      state.configuration = payload.map((payload) => payload);
    },
    setHardResetRequiredForStation: (
      state,
      { payload: id }: PayloadAction<string>
    ) => {
      state.stationIdentityKeysRequiringHardReset.push(id);
    },
    setHardResetNotRequiredForStation: (
      state,
      { payload }: PayloadAction<string>
    ) => {
      state.stationIdentityKeysRequiringHardReset =
        state.stationIdentityKeysRequiringHardReset.filter(
          (id) => id !== payload
        );
    },
  },
});

export const clearHardResetRequired =
  (identityKey: string): AppThunk =>
  (dispatch) => {
    dispatch(
      configurationSlice.actions.setHardResetNotRequiredForStation(identityKey)
    );

    /**
     * After calling hard reset endpoint and getting a success response, we mark the station as not requiring
     * a hard reset anymore. We also want to clear the configuration table and refetch to force the loading while
     * station is offline to let user know that once fetch is successful, this means hard reset was completed and
     * new keys are applied.
     *
     * Since rtk-query is designed so all query hooks keep some form of latest data shown, to force clear the table,
     * we must call resetApiState.
     */
    dispatch(brokerManagementApi.util.resetApiState());
  };

export const { setConfiguration, setHardResetRequiredForStation } =
  configurationSlice.actions;

export const configurationReducer = configurationSlice.reducer;
