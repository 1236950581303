import { Checkbox, ListItemText, MenuItem, Select } from '@mui/material';

interface ConfigurationOptionListValueFieldProps {
  handleValueChange: (val: string) => void;
  controlerValue: string;
  valuesList: string;
}
export const ConfigurationOptionListValueField: React.FC<
  ConfigurationOptionListValueFieldProps
> = ({ handleValueChange, controlerValue, valuesList, ...rest }) => {
  const dropdownValuesList = valuesList.split(',');

  return (
    <Select
      multiple
      value={[controlerValue]}
      onChange={(e) => {
        const newData = e.target.value as string[];

        if (newData.length === 0) {
          handleValueChange('');
        } else {
          handleValueChange(newData[1]);
        }
      }}
      renderValue={(selected) => {
        if (selected[0] === '') {
          return '-';
        }
        return selected;
      }}
      fullWidth
    >
      {dropdownValuesList.map((listItemValue) => (
        <MenuItem key={listItemValue} value={listItemValue}>
          <Checkbox checked={controlerValue.includes(listItemValue)} />
          <ListItemText primary={listItemValue} />
        </MenuItem>
      ))}
    </Select>
  );
};
