import { VehicleModel } from '@energy-stacks/fleet/feature-vehicles-data';
import { MoreOptionsMenu } from '@energy-stacks/shared';
import { useLocation, useNavigate } from 'react-router-dom';
import { ESMenuItem } from '@energy-stacks/core/ui';
import { useTranslation } from 'react-i18next';

type VehiclesTableMoreOptionsMenuProps = {
  vehicleId: VehicleModel['vehicleId'];
};

export const VehiclesTableMoreOptionsMenu: React.FC<
  VehiclesTableMoreOptionsMenuProps
> = ({ vehicleId }) => {
  const [t] = useTranslation('vehicles');
  const [tFleetShared] = useTranslation('fleetShared');
  const navigate = useNavigate();
  const location = useLocation();
  return (
    <MoreOptionsMenu testId={vehicleId}>
      <ESMenuItem
        testId={`${vehicleId}EditGeneralInfoMenuItem`}
        onClick={() =>
          navigate(`${vehicleId}/edit-general-info`, {
            state: { background: location },
          })
        }
      >
        {t('editVehicleGeneralInfo')}
      </ESMenuItem>
      <ESMenuItem
        testId={`${vehicleId}EditOperatingHoursMenuItem`}
        onClick={() =>
          navigate(`${vehicleId}/edit-operating-hours`, {
            state: { background: location },
          })
        }
      >
        {t('editVehicleOperatingHours')}
      </ESMenuItem>
      <ESMenuItem
        testId={`${vehicleId}DeleteMenuItem`}
        onClick={() =>
          navigate(`${vehicleId}/delete`, {
            state: { background: location },
          })
        }
      >
        {tFleetShared('deleteMoreOptionsMenuItem')}
      </ESMenuItem>
    </MoreOptionsMenu>
  );
};
