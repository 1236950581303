import { createBaseQuery } from '@energy-stacks/shared';
import { createApi, FetchBaseQueryError } from '@reduxjs/toolkit/query/react';
import { environment } from '@energy-stacks/feature-config';
import { ChargingStationModel } from './chargingStationModel';
import { chargingStationDetailsNormalizer } from './normalizers/chargingStationDetailsNormalizer';
import { ChargingStationDto, EvseDto, TariffDto } from './chargingStationDto';
import { AddChargingStationFormData } from './addChargingStationFormData';
import { mapAddChargingStationFormDataToPayload } from './mapAddChargingStationFormDataToPayload';
import {
  ChargingStationDetailsModel,
  GeoLocation,
} from './chargingStationDetailsModel';
import { chargingStationsNormalizer } from './normalizers/chargingStationsNormalizer';
import { EvseModel } from './evseModel';
import { tariffNormalizer } from './normalizers/tariffsNormalizer';
import { TariffModel } from './TariffModel';
import { ConnectorModel } from './connectorModel';
import { AddTariffFormData } from './addTariffFormData';
import { mapAddTariffDataToPayload } from './mapAddTariffFormDataToPayload';

export interface CsmsEditChargingStationForm {
  chargingStationName: string;
  locationId?: string;
  chargingStationGroupUuid?: string;
}

export type UpdatePricingData = {
  chargingStationIdentityKey: ChargingStationDetailsModel['identityKey'];
  evseId: EvseModel['evseId'];
  connectorId: ConnectorModel['id'];
  tariffsToUnassign: TariffModel['id'][];
  tariffsToAssign: TariffModel['id'][];
};

export interface ChangeCoordinatesRequestBody {
  csmsUuid: string;
  coordinates: GeoLocation;
}

export interface ChangeCoordinatesConfirmation {
  status: 'Accepted' | 'Rejected';
}

export const chargingStationsApi = createApi({
  reducerPath: 'csmsChargingStations',
  tagTypes: ['ChargingStations', 'ChargingStationDetails', 'Tariffs'],
  baseQuery: createBaseQuery(`${environment.ocppServiceUrl}`),
  endpoints: (builder) => ({
    getChargingStations: builder.query<ChargingStationModel[], void>({
      query: () => '/chargingstations',
      providesTags: ['ChargingStations'],
      transformResponse: chargingStationsNormalizer,
    }),
    getChargingStationDetails: builder.query<
      ChargingStationDetailsModel,
      string
    >({
      query: (identityKey) => `/chargingstations/${identityKey}`,
      providesTags: (result, _error, _arg) => [
        {
          type: 'ChargingStationDetails',
          id: result?.identityKey,
        },
      ],
      transformResponse: chargingStationDetailsNormalizer,
    }),
    editChargingStation: builder.mutation<
      ChargingStationDto,
      CsmsEditChargingStationForm &
        Pick<ChargingStationDto, 'identityKey' | 'csmsUuid'>
    >({
      query: (body) => ({
        url: `/chargingstations/${body.identityKey}`,
        method: 'PUT',
        body: {
          csmsUuid: body.csmsUuid,
          chargingStationName: body.chargingStationName,
          locationId: body.locationId ? body.locationId : undefined,
          chargingStationGroupUuid: body.chargingStationGroupUuid
            ? body.chargingStationGroupUuid
            : undefined,
        },
      }),
      invalidatesTags: (result, _error, arg) =>
        result
          ? [
              { type: 'ChargingStationDetails', id: arg.identityKey },
              'ChargingStations',
            ]
          : [],
    }),

    editCoordinates: builder.mutation<
      ChangeCoordinatesConfirmation,
      {
        identityKey: string;
        csmsUuid: string;
        coordinates: GeoLocation;
      }
    >({
      query: (args) => {
        const body: ChangeCoordinatesRequestBody = {
          csmsUuid: args.csmsUuid,
          coordinates: args.coordinates,
        };

        return {
          url: `chargingstations/${args.identityKey}`,
          method: 'PUT',
          body,
        };
      },
    }),

    addChargingStation: builder.mutation<
      ChargingStationDetailsModel,
      AddChargingStationFormData
    >({
      query: (body) => ({
        url: '/chargingstations',
        method: 'POST',
        body: mapAddChargingStationFormDataToPayload(body),
      }),
      invalidatesTags: ['ChargingStations'],
      transformResponse: (chargingStation: ChargingStationDto) =>
        chargingStationDetailsNormalizer(chargingStation) ?? {
          id: '',
          csmsUuid: '',
          evses: [],
          firmware: '',
          geolocation: { latitude: '', longitude: '' },
          model: '',
          name: '',
          online: false,
          serialNumber: '',
          identityKey: '',
          operationalStatus: 'unknown',
          location: {
            name: chargingStation.locationDetails.name,
            id: chargingStation.locationDetails.location_id,
          },
          username: chargingStation.userName,
          password: chargingStation.password,
        },
    }),
    deleteChargingStation: builder.mutation<ChargingStationDto, string>({
      query: (identityKey) => ({
        url: `/chargingstations/${identityKey}`,
        method: 'DELETE',
      }),
      async onQueryStarted(identityKey, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(
            chargingStationsApi.util.updateQueryData(
              'getChargingStations',
              undefined,
              (draft) => {
                const updatedList = draft.filter(
                  (chargingStation) =>
                    chargingStation.identityKey !== identityKey
                );
                return updatedList;
              }
            )
          );
        } catch {}
      },
    }),
    getTariffs: builder.query<TariffModel[], void>({
      query: () => `/tariffs`,
      providesTags: ['Tariffs'],
      transformResponse: (tariffs: TariffDto[]) =>
        tariffs.map(tariffNormalizer),
    }),
    addTariff: builder.mutation<TariffDto, AddTariffFormData>({
      query: (tariffFormData) => ({
        url: `/tariffs`,
        method: 'POST',
        body: mapAddTariffDataToPayload(tariffFormData),
      }),
      invalidatesTags: ['Tariffs'],
    }),
    updatePricing: builder.mutation<EvseDto | undefined, UpdatePricingData>({
      async queryFn(arg, _queryApi, _extraOptions, updateWithBQ) {
        if (arg.tariffsToUnassign.length > 0) {
          const unassignResult = await updateWithBQ({
            url: `/evses/${arg.evseId}/connector/${arg.connectorId}/unassign`,
            method: 'PUT',
            params: {
              ids: arg.tariffsToUnassign,
            },
          });
          if (unassignResult.error)
            return { error: unassignResult.error as FetchBaseQueryError };
          if (arg.tariffsToAssign.length === 0)
            return { data: unassignResult.data as EvseDto };
        }
        if (arg.tariffsToAssign.length > 0) {
          const assignResult = await updateWithBQ({
            url: `/evses/${arg.evseId}/connector/${arg.connectorId}/assign`,
            method: 'PUT',
            params: {
              ids: arg.tariffsToAssign,
            },
          });
          if (assignResult.error)
            return { error: assignResult.error as FetchBaseQueryError };
          return {
            data: assignResult.data as EvseDto,
          };
        }
        return { data: undefined };
      },
      // Invalidates tags unconditionally because assigning might throw error after successful unassiging
      invalidatesTags: (_result, _error, arg) => [
        {
          type: 'ChargingStationDetails',
          id: arg.chargingStationIdentityKey,
        },
        'ChargingStations',
      ],
    }),
  }),
});

export const {
  useGetChargingStationsQuery,
  useGetChargingStationDetailsQuery,
  useEditChargingStationMutation,
  useEditCoordinatesMutation,
  useAddChargingStationMutation,
  useDeleteChargingStationMutation,
  useGetTariffsQuery,
  useAddTariffMutation,
  useUpdatePricingMutation,
} = chargingStationsApi;
