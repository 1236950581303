import { ESTooltip, formatDate } from '@energy-stacks/shared';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

type ForecastedTimeProps = { forecastedTime: string };

export const ForecastedTime: React.FC<ForecastedTimeProps> = ({
  forecastedTime,
}) => {
  const [t] = useTranslation('jobs');
  return (
    <ESTooltip title={t('estimated')}>
      <Typography color="warning.dark">
        {formatDate(forecastedTime, 'HH:mm').toLowerCase()}
      </Typography>
    </ESTooltip>
  );
};
