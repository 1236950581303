import { environment } from '@energy-stacks/feature-config';
import { createBaseQuery } from '@energy-stacks/shared';
import { createApi } from '@reduxjs/toolkit/query/react';
import type { JobModel } from './jobModel';
import { jobNormalizer, jobsNormalizer } from './normalizers/jobsNormalizer';
import { JobDto } from './jobDto';
import { GetJobsPoolRequestBody } from './getJobsPoolRequestBody';
import { getDataByPlantId } from '@energy-stacks/fleet/shared';

const serializeGetJobPoolParameters = (
  searchParams: GetJobsPoolRequestBody
): string => {
  const params = new URLSearchParams();

  Object.entries(searchParams).forEach(([key, value]) => {
    if (!Array.isArray(value)) {
      params.append(key, value);
    } else {
      value.forEach((item) => params.append(key, item));
    }
  });

  return params.toString();
};

export const jobsApi = createApi({
  reducerPath: 'jobs',
  tagTypes: ['Jobs', 'JobsPool'],
  keepUnusedDataFor: 0,
  baseQuery: createBaseQuery(`${environment.ocppServiceUrl}/jobs`),
  endpoints: (builder) => ({
    getJobs: builder.query<JobModel[], void>({
      queryFn: async (_, api, _extraArgs, baseQuery) => {
        return getDataByPlantId({
          api,
          baseQuery,
          url: '',
          transformResponse: jobsNormalizer,
        });
      },
      providesTags: ['Jobs'],
    }),
    getJobPool: builder.query<JobModel[], GetJobsPoolRequestBody>({
      query: (searchParams) => ({
        url: `/excluding?${serializeGetJobPoolParameters(searchParams)}`,
        method: 'GET',
      }),
      providesTags: ['JobsPool'],
      transformResponse: (jobsDto: JobDto[]) => {
        return jobsDto.map(jobNormalizer);
      },
    }),
  }),
});

export const { useGetJobsQuery, useGetJobPoolQuery, usePrefetch } = jobsApi;
