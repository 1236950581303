import { EventType } from '../NetworkLogEntry';
import { EventTypeModel } from '../ocppNetworkLogsModel';

export const eventTypeNormalizer = (messageType: EventType): EventTypeModel => {
  return eventTypeMap[messageType];
};

const eventTypeMap: Record<EventType, EventTypeModel> = {
  AFTER_CONNECTION_CLOSED: 'afterConnectionClosed',
  AFTER_CONNECTION_ESTABLISHED: 'afterConnectionEstablished',
  HANDLE_CONNECTION_ERROR: 'handleConnectionError',
};

export const eventTypeToEnum: Record<EventTypeModel, EventType> = {
  afterConnectionClosed: 'AFTER_CONNECTION_CLOSED',
  afterConnectionEstablished: 'AFTER_CONNECTION_ESTABLISHED',
  handleConnectionError: 'HANDLE_CONNECTION_ERROR',
};
