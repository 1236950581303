import { TableBody, TableBodyProps } from '@mui/material';
import { flexRender, Row } from '@tanstack/react-table';
import { ESTableBodyCell } from './ESTableBodyCell';
import { DEFAULT_ROW_HEIGHT, ESTableBodyRow } from './ESTableBodyRow';
import { useTableIntersectionObserver } from './useTableIntersectionObsever';

interface ESTableBodyProps<T> extends TableBodyProps {
  rows: Row<T>[];
  onRowClick?: (row: Row<T>) => void;
  rowHeight?: number;
  observeRowsEnabled?: boolean;
  observeRowsSelectId?: (row: Row<T>) => string;
  observeRowsOnViewportEnter?: (rowIds: string[]) => void;
  observeRowsOnViewportExit?: (rowIds: string[]) => void;
  testId?: string;
}

export const ESTableBody = <T,>({
  rows,
  onRowClick,
  rowHeight = DEFAULT_ROW_HEIGHT,
  observeRowsEnabled = false,
  observeRowsSelectId,
  observeRowsOnViewportEnter,
  observeRowsOnViewportExit,
  testId,
  ...other
}: ESTableBodyProps<T>) => {
  useTableIntersectionObserver(
    '#tableContainerRef',
    '[data-row-id]',
    (rows) => {
      observeRowsOnViewportEnter?.(
        rows.map((row) => row.getAttribute('data-row-id') ?? '')
      );
    },
    (rows) => {
      observeRowsOnViewportExit?.(
        rows.map((row) => row.getAttribute('data-row-id') ?? '')
      );
    },
    rows,
    observeRowsEnabled
  );
  return (
    <TableBody id="tableBodyRef" data-testid={`${testId}TableBody`} {...other}>
      {rows.map((row, index) => {
        return (
          <ESTableBodyRow
            data-testid={`${testId}TableRow${index}`}
            rowHeight={rowHeight}
            key={row.id}
            data-row-id={observeRowsSelectId?.(row)}
            onRowClick={() => onRowClick?.(row)}
          >
            {row.getVisibleCells().map((cell, i, cells) => {
              const isActionsColumn =
                cells.length - 1 === i && cell.id.includes('actions');

              return (
                <ESTableBodyCell
                  key={cell.id}
                  align={isActionsColumn ? 'right' : 'left'}
                  isSticky={isActionsColumn}
                  onClick={(e) => {
                    if (isActionsColumn) {
                      e.stopPropagation();
                    }
                  }}
                  sx={{
                    '&': {
                      width: !isActionsColumn ? cell.column.getSize() : '20px',
                    },
                    whiteSpace: 'nowrap',
                    backgroundColor: cell.row.getIsSelected()
                      ? 'primary.light'
                      : cell.column.columnDef.meta?.backgroundColor,
                    padding: cell.column.columnDef.meta?.cellPadding,
                    paddingRight: isActionsColumn ? 8 : undefined,
                  }}
                >
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </ESTableBodyCell>
              );
            })}
          </ESTableBodyRow>
        );
      })}
    </TableBody>
  );
};
