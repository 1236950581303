import { FunctionComponent } from 'react';
import { LoadingButton, LoadingButtonProps } from '@mui/lab';

export interface ESButtonProps extends LoadingButtonProps {
  rounded?: boolean;
  testId?: string;
}

export const ESButton: FunctionComponent<ESButtonProps> = ({
  rounded,
  sx,
  testId,
  ...rest
}) => {
  return (
    <LoadingButton
      data-testid={testId}
      variant="contained"
      size="medium"
      sx={{ borderRadius: rounded ? 100 : 'auto', ...sx }}
      {...rest}
    />
  );
};
