import { ChargingStationDto } from '../chargingStationDto';
import { ChargingStationModel } from '../chargingStationModel';
import { evsesRule } from '../evsesRule';
import { chargingStationOperationalStatusNormalizer } from './chargingStationOperationalStatusNormalizer';

export function chargingStationsNormalizer(
  chargingStationDtos: ChargingStationDto[]
): ChargingStationModel[] {
  return chargingStationDtos.map((chargingStationDto) => {
    return {
      id: chargingStationDto.chargingStationId.toString(),
      identityKey: chargingStationDto.identityKey ?? 'N/A',
      model: chargingStationDto.chargingStationModel ?? 'N/A',
      name: chargingStationDto.chargingStationName ?? 'N/A',
      evses: evsesRule(chargingStationDto.evses),
      operationalStatus:
        chargingStationOperationalStatusNormalizer(
          chargingStationDto.operationalStatus
        ) ?? 'unknown',
      location: locationNormalizer(chargingStationDto.locationDetails),
    };
  });
}

const locationNormalizer = (locationDetails: {
  name: string;
  location_id: string;
}): ChargingStationModel['location'] => {
  if (!locationDetails) {
    return {
      id: '',
      name: 'unassigned',
    };
  }
  return {
    id: locationDetails.location_id ?? '',
    name: locationDetails.name ?? 'N/A',
  };
};
