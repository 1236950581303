import { formatIntervalDuration } from '@energy-stacks/fleet/shared';
import { Typography } from '@mui/material';
import { differenceInMinutes } from 'date-fns';

type ConfirmedDurationProps = {
  confirmedStartTime: string;
  confirmedEndTime: string;
  plannedDuration: number;
};

export const ConfirmedDuration: React.FC<ConfirmedDurationProps> = ({
  confirmedEndTime,
  confirmedStartTime,
  plannedDuration,
}) => {
  const confirmedDuration = Math.abs(
    differenceInMinutes(
      new Date(confirmedStartTime),
      new Date(confirmedEndTime)
    )
  );

  const plannedDurationInMinutes = plannedDuration / 60;

  return (
    <Typography
      color={
        Math.abs(confirmedDuration - plannedDurationInMinutes) < 15
          ? 'success.dark'
          : 'error.dark'
      }
    >
      {formatIntervalDuration(confirmedStartTime, confirmedEndTime)}
    </Typography>
  );
};
