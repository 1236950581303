import * as yup from 'yup';
import { MAX_NUMBER_INPUT_VALUE } from './maxNumberInputValue';

export const numberValidityTest = yup
  .string()
  .test('isNumber', 'mustBeNumber', (value) => {
    if (!value) {
      return true;
    }
    return !isNaN(Number(value));
  })
  .test('maxNumber', 'numberTooBig', (value) => {
    if (!value) {
      return true;
    }

    return Number(value) <= MAX_NUMBER_INPUT_VALUE;
  })
  .test('isPositive', 'mustBePositiveValue', (value) => {
    if (!value) {
      return true;
    }

    return Number(value) >= 0;
  });
