import { ConnectorType } from '@energy-stacks/csms/feature-charging-stations-data';
import { SvgIconProps } from '@mui/material';
import React from 'react';
import { CcsFemaleIcon } from './icons/CcsFemaleIcon';
import { CcsIcon } from './icons/CcsIcon';
import { CcsMaleIcon } from './icons/CcsMaleIcon';
import { CcsType1FemaleIcon } from './icons/CcsType1FemaleIcon';
import { CcsType1MaleIcon } from './icons/CcsType1MaleIcon';
import { CHAdeMO_FemaleIcon } from './icons/CHAdeMO_FemaleIcon';
import { CHAdeMO_MaleIcon } from './icons/CHAdeMO_MaleIcon';
import { GB_T_FemaleIcon } from './icons/GB_T_FemaleIcon';
import { GB_T_MaleIcon } from './icons/GB_T_MaleIcon';
import { Type2FemaleIcon } from './icons/Type2FemaleIcon';
import { Type2Icon } from './icons/Type2Icon';
import { Type2MaleIcon } from './icons/Type2MaleIcon';
import { TypeEF } from './icons/TypeEF';
import { UnkownConnectorIcon } from './icons/UnkownConnectorIcon';

type ConnectorIconProps = SvgIconProps & {
  connectorType: ConnectorType;
};

export const ConnectorIcon: React.FC<ConnectorIconProps> = ({
  connectorType,
  ...props
}) => {
  const Icon = connectorTypeToIconMap[connectorType];
  return <Icon {...props} />;
};

const connectorTypeToIconMap: {
  [status in ConnectorType]: React.FC<
    SvgIconProps<'svg', Record<string, unknown>>
  >;
} = {
  'CHAdeMO Female': CHAdeMO_FemaleIcon,
  CHAdeMO: CHAdeMO_MaleIcon,
  'CHAdeMO Male': CHAdeMO_MaleIcon,
  CCS: CcsIcon,
  'CCS Male': CcsMaleIcon,
  'CCS Female': CcsFemaleIcon,
  'CCS Type 1 Male': CcsType1MaleIcon,
  'CCS Type 1 Female': CcsType1FemaleIcon,
  'GB T Female': GB_T_FemaleIcon,
  'GB T Male': GB_T_MaleIcon,
  'Type 2 Female': Type2FemaleIcon,
  'Type 2': Type2Icon,
  'Type 2 Male': Type2MaleIcon,
  'Type E/F': TypeEF,
  Unknown: UnkownConnectorIcon,
};
