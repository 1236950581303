import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const CcsIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props}>
      <path d="M11.9806 13.6856C15.3486 13.6856 18.0788 10.955 18.0788 7.58738C18.0788 6.31718 17.7046 5.19983 17.0594 4.2112C16.3415 3.11096 12.0117 3.19963 12.0117 3.19963C12.0117 3.19963 7.72504 3.00011 6.90598 4.20342C6.25105 5.16638 5.88197 6.33546 5.88197 7.58738C5.88197 10.955 8.61177 13.6856 11.9806 13.6856ZM10.1402 12.5624C9.21576 12.5624 8.46593 11.813 8.46593 10.8881C8.46593 9.96328 9.21537 9.21383 10.1402 9.21383C11.0647 9.21383 11.8145 9.96328 11.8145 10.8881C11.8145 11.813 11.0647 12.5624 10.1402 12.5624ZM13.6813 12.5624C12.7572 12.5624 12.0078 11.813 12.0078 10.8881C12.0078 9.96328 12.7572 9.21383 13.6813 9.21383C14.6057 9.21383 15.3556 9.96328 15.3556 10.8881C15.3556 11.813 14.6057 12.5624 13.6813 12.5624ZM17.2749 7.66867C17.2749 8.59312 16.5254 9.34295 15.6006 9.34295C14.6761 9.34295 13.9263 8.59351 13.9263 7.66867C13.9263 6.7446 14.6758 5.99516 15.6006 5.99516C16.5251 5.99516 17.2749 6.74499 17.2749 7.66867ZM13.7194 4.0148C14.3199 4.0148 14.806 4.50055 14.806 5.10104C14.806 5.70075 14.3199 6.18729 13.7194 6.18729C13.1197 6.18729 12.6332 5.70114 12.6332 5.10104C12.6336 4.50055 13.1197 4.0148 13.7194 4.0148ZM12.0226 5.99516C12.9466 5.99516 13.6961 6.7446 13.6961 7.66867C13.6961 8.59312 12.9466 9.34295 12.0226 9.34295C11.0981 9.34295 10.3483 8.59351 10.3483 7.66867C10.3483 6.74499 11.0981 5.99516 12.0226 5.99516ZM10.1402 4.0148C10.7399 4.0148 11.2265 4.50055 11.2265 5.10104C11.2265 5.70075 10.7403 6.18729 10.1402 6.18729C9.5405 6.18729 9.05397 5.70114 9.05397 5.10104C9.05397 4.50055 9.5405 4.0148 10.1402 4.0148ZM6.68547 7.66867C6.68547 6.7446 7.43491 5.99516 8.35975 5.99516C9.28459 5.99516 10.034 6.74499 10.034 7.66867C10.034 8.59312 9.28459 9.34295 8.35975 9.34295C7.43491 9.34295 6.68547 8.59351 6.68547 7.66867Z" />
      <path d="M11.9802 14.7936C16.0245 14.7936 19.3144 11.5033 19.3144 7.45941C19.3144 6.01147 18.9025 4.64599 18.0901 3.40146C17.1901 2.02237 12.5169 2.07682 11.9841 2.07682C11.4505 2.0671 6.89664 1.89403 5.88001 3.38746C5.07262 4.57288 4.64676 5.98114 4.64676 7.45941C4.64637 11.5033 7.93583 14.7936 11.9802 14.7936ZM6.42372 3.75771C6.92542 3.02033 9.16053 2.7687 10.955 2.7687C11.3357 2.7687 11.6885 2.75003 12.0121 2.7687C13.9477 2.78737 17.012 2.95343 17.5393 3.7616C18.2806 4.89684 18.6567 6.14137 18.6567 7.45941C18.6567 11.1405 15.6617 14.1355 11.9806 14.1355C8.29946 14.1355 5.30481 11.1405 5.30481 7.45941C5.30481 6.11337 5.691 4.83306 6.42372 3.75771Z" />
      <path d="M15.7818 15.0339H8.27535C6.61157 15.0339 5.25775 16.3878 5.25775 18.0515V18.953C5.25775 20.6168 6.61157 21.9706 8.27535 21.9706H15.7818C17.4456 21.9706 18.7994 20.6168 18.7994 18.953V18.0515C18.7998 16.3878 17.446 15.0339 15.7818 15.0339ZM18.1332 18.9534C18.1332 20.2497 17.0785 21.3048 15.7822 21.3048H8.27535C6.97909 21.3048 5.92435 20.2501 5.92435 18.9534V18.0519C5.92435 16.7557 6.97909 15.7005 8.27535 15.7005H15.7818C17.0781 15.7005 18.1328 16.7553 18.1328 18.0519V18.9534H18.1332Z" />
      <path d="M9.72523 18.1041C9.51793 18.1041 9.34914 18.2728 9.34914 18.4801C9.34914 18.6874 9.51793 18.8562 9.72523 18.8562C9.93252 18.8562 10.1013 18.6874 10.1013 18.4801C10.1013 18.2728 9.93252 18.1041 9.72523 18.1041Z" />
      <path d="M14.372 18.1041C14.1647 18.1041 13.9959 18.2728 13.9959 18.4801C13.9959 18.6874 14.1647 18.8562 14.372 18.8562C14.5793 18.8562 14.7481 18.6874 14.7481 18.4801C14.7481 18.2728 14.5793 18.1041 14.372 18.1041Z" />
      <path d="M15.7931 16.1548H8.23103C7.35053 16.1548 6.40468 16.9105 6.40468 17.791C6.40468 17.791 6.39107 18.136 6.37862 18.4801C6.36618 18.8243 6.4039 19.2393 6.4039 19.2393C6.4039 20.1198 7.35014 20.8051 8.23064 20.8051H15.7927C16.6732 20.8051 17.6047 20.1525 17.6047 19.272C17.6047 19.272 17.6809 18.8469 17.6809 18.5023C17.6809 18.1577 17.6156 17.7109 17.6156 17.7109C17.6156 16.8308 16.6736 16.1548 15.7931 16.1548ZM9.72525 20.6468C8.53089 20.6468 7.55898 19.6749 7.55898 18.4805C7.55898 17.2862 8.53089 16.3143 9.72525 16.3143C10.9196 16.3143 11.8915 17.2862 11.8915 18.4805C11.8915 19.6749 10.9196 20.6468 9.72525 20.6468ZM14.372 20.6468C13.1777 20.6468 12.2058 19.6749 12.2058 18.4805C12.2058 17.2862 13.1777 16.3143 14.372 16.3143C15.5664 16.3143 16.5383 17.2862 16.5383 18.4805C16.5387 19.6749 15.5668 20.6468 14.372 20.6468Z" />
    </SvgIcon>
  );
};
