import { useState } from 'react';

export interface CommonDialogActions {
  onClose: () => void;
  onCallToAction?: () => void;
}

export interface CommonDialogProps extends CommonDialogActions {
  isOpen: boolean;
}

export function useDialog(onCallToAction?: () => void): {
  open: () => void;
  dialogProps: CommonDialogProps;
} {
  const [isOpen, setIsOpen] = useState(false);

  return {
    open: () => setIsOpen(true),
    dialogProps: {
      isOpen,
      onClose: () => {
        setIsOpen(false);
      },
      onCallToAction,
    },
  };
}
