import { locationsApi } from '@energy-stacks/csms/feature-locations-data';
import { useAppDispatch } from '@energy-stacks/csms/store';
import { useCallback } from 'react';

export const useInvalidateLocationDetails = () => {
  const dispatch = useAppDispatch();

  return useCallback(
    (locationId?: string) => {
      dispatch(
        locationsApi.util.invalidateTags([
          { type: 'LocationDetails', id: locationId },
        ])
      );
    },
    [dispatch]
  );
};
