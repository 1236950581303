import { useLocation, Location } from 'react-router-dom';

type DefaultLocationState = {
  background?: Location;
  shouldBackgroundFreeze?: boolean;
  from?: Location;
};

export interface LocationWithState<TState> extends Location {
  state: TState & DefaultLocationState;
}

export const useAppLocation = <TState>(): LocationWithState<TState> =>
  useLocation() as LocationWithState<TState>;
