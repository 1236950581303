import { MobilityTokenType } from '../mobilityTokenModel';
import { MobilityTokenTypeDto } from '../mobilityTokensDto';

export const mobilityTokenTypeToDtoNormalizer = (
  mobilityTokenType: MobilityTokenType
): MobilityTokenTypeDto => {
  return mobilityTokenTypeDtoMap[mobilityTokenType];
};

const mobilityTokenTypeDtoMap: Record<MobilityTokenType, MobilityTokenTypeDto> =
  {
    adHocUser: 'AD_HOC_USER',
    appUser: 'APP_USER',
    other: 'OTHER',
    rfidCard: 'RFID',
  };
