import { WorkingHoursModel } from './addChargingLocationFormData';
import { convertAmenitiesToFacilities } from './convertAmenitiesToFacilities';
import { convertLocationTypeToParkingType } from './convertLocationTypeToParkingType';
import { EditChargingStationLocationRequest } from './editChargingStationLocationRequest';
import { LocationDetailsFormData } from './locationDetailsFormData';
import { OpeningHours } from './locationDetailsModel';
import { LocationParkingTypeEnum } from './locationModel';
import { mapWorkingDateToWorkingTime } from './mapWorkingDateToWorkingTime';
import { RegularHoursDto } from './regularHoursDto';

export const mapEditLocationFormDataToPayload = (
  locationDetailsFormData: LocationDetailsFormData
): EditChargingStationLocationRequest => {
  return {
    ownerId: locationDetailsFormData.entity.value || undefined,
    locationUpdateRequest: {
      name: mapOptionalInputValue(locationDetailsFormData.name),
      address: locationDetailsFormData.address,
      postal_code: mapOptionalInputValue(locationDetailsFormData.zipCode),
      country: locationDetailsFormData.country.value,
      city: locationDetailsFormData.city,
      parking_type: convertLocationTypeToParkingType(
        locationDetailsFormData.locationType.value ??
          LocationParkingTypeEnum.other
      ),
      energy_mix: {
        is_green_energy: false,
        supplier_name: mapOptionalInputValue(
          locationDetailsFormData.energySupplier
        ),
      },
      facilities: [
        ...convertAmenitiesToFacilities(
          locationDetailsFormData.activityAmenities
        ),
        ...convertAmenitiesToFacilities(
          locationDetailsFormData.foodAndDrinkAmenities
        ),
        ...convertAmenitiesToFacilities(
          locationDetailsFormData.shoppingAmenities
        ),
        ...convertAmenitiesToFacilities(
          locationDetailsFormData.transportationAmenities
        ),
      ],
      charging_when_closed: locationDetailsFormData.chargingWhenClosed,
      opening_times: {
        twentyfourseven:
          locationDetailsFormData.openingHours === OpeningHours.nonStop,
        regular_hours:
          locationDetailsFormData.openingHours !== OpeningHours.nonStop
            ? mapWorkingHoursToRegularHours(
                locationDetailsFormData.hours,
                locationDetailsFormData.openingHours
              )
            : undefined,
      },
    },
  };
};

const mapWorkingHoursToRegularHours = (
  workingHours: WorkingHoursModel[],
  openingHours: OpeningHours
): RegularHoursDto[] => {
  let selectedWorkingHours;

  if (openingHours === OpeningHours.workdaysOnly) {
    selectedWorkingHours = workingHours.slice(0, 5);
  }

  if (openingHours === OpeningHours.customDays) {
    selectedWorkingHours = workingHours;
  }

  return (
    selectedWorkingHours
      ?.filter((item) => Boolean(item.start) && Boolean(item.end))
      .map((item) => ({
        period_begin: mapWorkingDateToWorkingTime(item.start),
        period_end: mapWorkingDateToWorkingTime(item.end),
        weekday: item.weekday,
      })) ?? []
  );
};

const mapOptionalInputValue = (inputValue: string) => {
  if (!inputValue || inputValue === 'N/A') {
    return undefined;
  }
  return inputValue;
};
