import { BrokerLocationChargingStation } from '@energy-stacks/broker/feature-locations-data';
import { Box, Typography, colors, useTheme } from '@mui/material';
import { ReactComponent as CSChipIcon } from './icons/locationCSChip.svg';
import { ESTooltip } from '@energy-stacks/shared';
import { useTranslation } from 'react-i18next';

interface LocationChargingStationStatusIconsProps {
  chargingStations: BrokerLocationChargingStation[];
}

export const LocationChargingStatusStationIcons: React.FC<
  LocationChargingStationStatusIconsProps
> = ({ chargingStations }) => {
  const { palette, spacing } = useTheme();
  const { t } = useTranslation('locations');
  const initialCSCountPerStatus: Record<string, number> = {
    online: 0,
    faulted: 0,
    offline: 0,
    provisioned: 0,
  };
  const csCountPerStatus = chargingStations.reduce((acc, next) => {
    acc[next.shadow]++;
    return acc;
  }, initialCSCountPerStatus);

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        mb: 1,
        mr: 2,
        minWidth: spacing(25),
        padding: 0.8,
      }}
    >
      {Object.entries(csCountPerStatus)
        .filter(([key]) => key !== 'undefined')
        .map(([status, count]) => {
          let color: string;
          if (status === 'online') color = palette.success.dark;
          else if (status === 'faulted') color = palette.error.dark;
          else if (status === 'offline') color = palette.grey[900];
          else if (status === 'provisioned') color = colors.orange[900];
          else color = palette.text.secondary;

          return (
            <ESTooltip key={color} title={t(status, { context: 'status' })}>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  mr: 2,
                }}
              >
                <Typography variant="caption" color={color} sx={{ mr: 1 }}>
                  {count}
                </Typography>
                <CSChipIcon
                  stroke={color}
                  height={spacing(5)}
                  width={spacing(5)}
                />
              </Box>
            </ESTooltip>
          );
        })}
    </Box>
  );
};
