import { VanillaOidc as Oidc } from '@axa-fr/oidc-client';
import { environment } from '@energy-stacks/feature-config';
import {
  Client,
  IMessage,
  messageCallbackType,
  StompHeaders,
  StompSubscription,
} from '@stomp/stompjs';
import log from 'loglevel';

const stompLogger = log.getLogger('stomp');

if (environment.type === 'prd') {
  stompLogger.setLevel('error');
}

// Debug only
export const receivedMessages: {
  timestamp: Date;
  destination: string;
  data: string;
}[] = [];

// Used for detecting if we are reconnecting or connecting for the first time
let isStompInitialized = false;
const defaultReconnectDelayMs = 5000;
// Make sure to double backoff delay every time we reconnect, up to max 4 times
const maxDelay = defaultReconnectDelayMs * Math.pow(2, 4);

// If accessed from React component, you can be sure that the stompClientInstance is defined and correctly initialized since we are blocking render tree until this happens. Since initialization is asynchronous, if you use this instance outside of component you will need to make sure that it is either already initialized(connected) or you need to wait for it.
export let stompClientInstance: Client | undefined;
let onConnect: (isStompAlreadyInitialized: boolean) => void | undefined,
  onDisconnect: (isStompAlreadyInitialized: boolean) => void | undefined;

export const stompConnect = (
  idToken: string,
  onConnectCallback: (isStompAlreadyInitialized: boolean) => void,
  onDisconnectCallback: (isStompAlreadyInitialized: boolean) => void
) => {
  onConnect = onConnectCallback;
  onDisconnect = onDisconnectCallback;
  if (!environment.stompUrl) {
    stompLogger.warn('No stomp url configured');
    return;
  }

  if (stompClientInstance) {
    stompLogger.info('Stomp already initialized');
    if (!stompClientInstance.connected) {
      stompLogger.info('Stomp not connected, connecting');
      stompClientInstance.reconnectDelay = defaultReconnectDelayMs;
      stompClientInstance.activate();
    }
    return;
  }
  // runs only once
  const instance = new Client({
    brokerURL: environment.stompUrl,
    beforeConnect: async () => {
      stompLogger.info('Stomp connecting');
      const idToken = Oidc.get('default').tokens?.idToken;
      if (stompClientInstance) {
        if (idToken) {
          stompClientInstance.connectHeaders['Authorization'] = idToken;
        }

        if (
          stompClientInstance.reconnectDelay >= maxDelay &&
          !stompClientInstance.connected
        ) {
          stompLogger.info('Stopping automatic reconnect after 4 attempts');
          stompClientInstance.reconnectDelay = 0;
          stompClientInstance?.deactivate();
        }
      }
    },
    connectHeaders: {
      Authorization: idToken,
    },
    debug: (str) => {
      stompLogger.debug(str);
    },
    onDisconnect: () => {
      stompLogger.info('Stomp disconnected');
    },
    onStompError: (frame) => {
      stompLogger.error('Broker reported error: ' + frame.headers['message']);
      stompLogger.error('Additional details: ' + frame.body);
      stompLogger.info(
        'Disconnecting Stomp client due to stomp initialization error'
      );
    },
    onWebSocketClose: () => {
      stompLogger.info('WebSocket closed');
      onDisconnect?.(isStompInitialized);
      if (!isStompInitialized) {
        // Stomp was initialized with an error
        isStompInitialized = true;
      }
      if (stompClientInstance) {
        stompClientInstance.reconnectDelay = Math.min(
          maxDelay,
          stompClientInstance.reconnectDelay * 2
        );
      }
    },
    onWebSocketError: (event) => {
      stompLogger.error('WebSocket error: ' + event);
    },
    onUnhandledMessage: (message) => {
      stompLogger.warn('Unhandled message: ' + message);
    },
    onUnhandledReceipt(frame) {
      stompLogger.warn('Unhandled receipt: ' + frame);
    },
    onUnhandledFrame(frame) {
      stompLogger.warn('Unhandled frame: ' + frame);
    },
    onConnect: () => {
      stompLogger.info('Stomp connected');
      onConnect?.(isStompInitialized);
      // Stomp initialized successfully
      isStompInitialized = true;
    },
  });
  if (environment.type !== 'prd') {
    const subscribe = instance.subscribe;
    instance.subscribe = (
      destination: string,
      callback: messageCallbackType,
      headers?: StompHeaders
    ): StompSubscription => {
      return subscribe.apply(instance, [
        destination,
        (event: IMessage) => {
          receivedMessages.unshift({
            destination,
            timestamp: new Date(),
            data: JSON.parse(event.body),
          });
          callback(event);
        },
        headers,
      ]);
    };
  }

  stompClientInstance = instance;

  stompClientInstance.activate();
};
