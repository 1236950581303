import {
  NoTableData,
  TableSearchField,
  ClearFiltersButton,
  stringEnumToArray,
  TableColumnFilter,
  MoreOptionsMenu,
  ESTooltip,
  TableColumnSelect,
} from '@energy-stacks/shared';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  ESTable,
  ESTableBody,
  ESTablePagination,
  ESTableWrapper,
  ESMenuItem,
  ESTableHead,
  useESTableBasic,
  usePrevious,
  ESTableBasicConfig,
} from '@energy-stacks/core/ui';
import {
  ChargingStationModel,
  ChargingStationOnlineStatus,
  ConnectorStatusEnum,
  chargingStationStatuses,
  resetListeningToStationChanges,
  startListeningToStationChanges,
  stopListeningToStationChanges,
} from '@energy-stacks/broker/feature-charging-stations-data';
import { createColumnHelper } from '@tanstack/react-table';
import { useContext, useEffect, useMemo } from 'react';
import { Box, ListItemText, Stack, colors, useTheme } from '@mui/material';
import { ChargingStationsActionsContext } from '../ChargingStationsActionsContext';
import { BrokerAddChargingStationButton } from '../add-charging-station/BrokerAddChargingStationButton';
import { ReactComponent as CentralSystemIcon } from './icons/centralSystem.svg';
import { ReactComponent as ChargingStationIcon } from './icons/chargingStation.svg';
import { uniq } from 'lodash-es';
import { BrokerRoutes, ConnectorIcon } from '@energy-stacks/broker/shared';
import { useAppDispatch } from '@energy-stacks/broker/store';
import { ChargingStationShadowStatusChip } from '../details/ChargingStationShadowStatusChip';
import { CentralSystemStatus } from '@energy-stacks/broker/feature-system-preferences-data';
import { TableWebsocketUrl } from './TableWebsocketUrl';

interface ChargingStationsTableProps
  extends ESTableBasicConfig<ChargingStationModel> {
  chargingStations: ChargingStationModel[];
  testId?: string;
}

interface ConnectivityStatuses {
  iconColor: string;
  tooltipText: string;
}

export const ChargingStationsTable: React.FC<ChargingStationsTableProps> = ({
  tableId = 'chargingStations',
  chargingStations,
  testId,
}) => {
  const chargingStationsActions = useContext(ChargingStationsActionsContext);
  const location = useLocation();

  const navigate = useNavigate();
  const [t] = useTranslation('chargingStations');

  const columnHelper = createColumnHelper<ChargingStationModel>();
  const addChargingStationEnabled = chargingStationsActions.includes('add');
  const dispatch = useAppDispatch();

  const { palette } = useTheme();

  const chargingSiteNames: string[] = useMemo(() => {
    return chargingStations.map((cs) => cs.location.name);
  }, [chargingStations]);

  const connectivityStatuses: {
    [status in ChargingStationOnlineStatus]: ConnectivityStatuses;
  } = useMemo(() => {
    return {
      online: {
        iconColor: palette.success.dark,
        tooltipText: 'connected',
      },
      offline: {
        iconColor: palette.error.dark,
        tooltipText: 'disconnected',
      },
    };
  }, [palette.error.dark, palette.success.dark]);

  const connectorStatuses: {
    [status in ConnectorStatusEnum]: string;
  } = useMemo(() => {
    return {
      undefined: palette.grey[300],
      available: palette.success.dark,
      charging: palette.primary.dark,
      reserved: palette.warning.dark,
      faulted: palette.error.dark,
      unavailable: palette.grey[500],
      occupied: palette.primary.dark,
      finishing: palette.cyan[300],
      preparing: colors.indigo[300],
      suspendedEV: palette.pink[300],
      suspendedEVSE: palette.secondary.main,
    };
  }, [
    palette.grey,
    palette.success.dark,
    palette.primary.dark,
    palette.warning.dark,
    palette.error.dark,
    palette.cyan,
    palette.pink,
    palette.secondary.main,
  ]);

  const columns = [
    columnHelper.accessor('identityKey', {
      sortingFn: 'alphanumeric',
      header: () => t('identityKey'),
      footer: (props) => props.column.id,
      cell: (info) => info.getValue() || '-',
    }),
    columnHelper.accessor('name', {
      sortingFn: 'alphanumeric',
      header: () => t('name'),
      footer: (props) => props.column.id,
      cell: (info) => info.getValue() || '-',
    }),
    columnHelper.accessor('model', {
      sortingFn: 'alphanumeric',
      header: () => t('model'),
      footer: (props) => props.column.id,
      cell: (info) => info.getValue() || '-',
    }),
    columnHelper.accessor((row) => row.location.name, {
      id: 'location.name',
      sortingFn: 'alphanumeric',
      header: () => t('chargingSite'),
      footer: (props) => props.column.id,
      cell: (info) =>
        (info.getValue() === 'N/A' ? t('unassigned') : info.getValue()) || '-',
      enableGlobalFilter: false,
      filterFn: 'arrIncludesSome',
      enableSorting: false,
    }),
    columnHelper.accessor('csStatus', {
      header: () => t('status'),
      footer: (props) => props.column.id,
      cell: (info) => {
        return (
          <ChargingStationShadowStatusChip
            shadowStatus={info.row.original.csStatus}
          />
        );
      },
      filterFn: 'arrIncludesSome',
      enableGlobalFilter: false,
      enableSorting: false,
    }),
    columnHelper.accessor('connectors', {
      header: () => t('connectors'),
      footer: (props) => props.column.id,
      cell: (info) => {
        return (
          <Stack flexDirection="row" gap={2}>
            {info.row.original.connectors.map((connector) => {
              return (
                <ESTooltip
                  title={
                    <div style={{ textAlign: 'left' }}>
                      <span>{`${t('connectorId')}: ${
                        connector.connectorId
                      }`}</span>
                      <br></br>
                      <span>{`${t('status')}: ${t([connector.connectorStatus], {
                        context: 'status',
                      })}`}</span>
                      <br></br>
                      <span>{`${t('type')}: ${
                        connector.connectorType
                          ? t(`connectorTypes.${connector.connectorType}`)
                          : 'N/A'
                      }`}</span>
                    </div>
                  }
                  key={connector.connectorId}
                  placement="bottom"
                >
                  <Box
                    borderBottom={`4px solid ${
                      info.row.original.csStatus === 'online'
                        ? connectorStatuses[connector.connectorStatus]
                        : palette.grey[300]
                    }`}
                    borderRadius={1}
                  >
                    <ConnectorIcon connectorType={connector.connectorType} />
                  </Box>
                </ESTooltip>
              );
            })}
          </Stack>
        );
      },
      filterFn: 'arrIncludesSome',
      enableGlobalFilter: false,
    }),
    columnHelper.accessor((row) => row.csStatus, {
      id: 'online',
      header: () => t('connectivity'),
      footer: (props) => props.column.id,
      cell: (info) => {
        const stationStatus =
          info.getValue() === 'online' ? 'online' : 'offline';
        return (
          <Stack flexDirection="row" gap={4}>
            <ESTooltip title={t(stationStatus)} placement="bottom">
              <ChargingStationIcon
                stroke={connectivityStatuses[stationStatus].iconColor}
                height={30}
                width={30}
              />
            </ESTooltip>
          </Stack>
        );
      },
      enableGlobalFilter: false,
      filterFn: 'arrIncludesSome',
      enableSorting: false,
    }),
    columnHelper.accessor('centralSystemStatus', {
      header: () => t('centralSystem'),
      footer: (props) => props.column.id,
      cell: (info) => {
        const centralSystemStatus = info.row.original.centralSystemStatus;

        return (
          <Stack flexDirection="row" gap={4}>
            <ESTooltip
              title={t(connectivityStatuses[centralSystemStatus].tooltipText, {
                context: 'status',
              })}
              placement="bottom"
            >
              <CentralSystemIcon
                stroke={connectivityStatuses[centralSystemStatus].iconColor}
                height={30}
                width={30}
              />
            </ESTooltip>
          </Stack>
        );
      },
      enableGlobalFilter: false,
      filterFn: 'arrIncludesSome',
      enableSorting: false,
    }),
    columnHelper.display({
      id: 'actions',
      meta: { isActionable: true },
      header: () => t('actions'),
      footer: (props) => props.column.id,
      cell: (info) => (
        <MoreOptionsMenu testId={info.row.original.identityKey + testId}>
          <ESMenuItem
            testId={`${info.row.original.identityKey}MenuItemEditButton`}
            onClick={() =>
              navigate(
                `${info.row.original.name}/${info.row.original.identityKey}/edit`
              )
            }
          >
            {t('edit')}
          </ESMenuItem>
          <ESMenuItem
            testId={`${info.row.original.identityKey}MenuItemDeleteButton`}
            onClick={() => navigate(`${info.row.original.identityKey}/delete`)}
          >
            {t('deleteMenuItem')}
          </ESMenuItem>
        </MoreOptionsMenu>
      ),
    }),
  ];

  const hiddenColumnsIds = !chargingStationsActions.includes('delete')
    ? ['actions' as const]
    : undefined;

  const {
    globalFilter,
    instance,
    onGlobalFilterChange,
    rows,
    rowsPerPageOptions,
  } = useESTableBasic(chargingStations, columns, { tableId, hiddenColumnsIds });
  const hasRows = rows.length !== 0;

  const previousRows = usePrevious(rows);

  useEffect(() => {
    dispatch(resetListeningToStationChanges());
  }, [location, dispatch]);

  useEffect(() => {
    (previousRows ?? [])
      .map((row) => row.original.identityKey)
      .forEach((rowId) => {
        dispatch(stopListeningToStationChanges(rowId));
      });
  }, [previousRows, dispatch]);

  return (
    <>
      <Box display="flex" mb={6} pr={3} alignItems="center">
        <Box flex={1}>
          <Stack direction="row" gap={3} alignItems="center">
            <TableSearchField
              testId={testId}
              value={globalFilter}
              onChange={onGlobalFilterChange}
              tableInstance={instance}
            />
            <TableColumnFilter
              testId={`${testId}AggregatedStatus`}
              column={instance.getColumn('csStatus')}
              columnLabel={t('status')}
              options={chargingStationStatuses.map((v) => ({
                value: v,
                label: t(v, { context: 'status' }),
              }))}
            />
            <TableColumnFilter
              testId={`${testId}Connectivity`}
              column={instance.getColumn('online')}
              columnLabel={t('connectivity')}
              options={stringEnumToArray(ChargingStationOnlineStatus).map(
                (v) => ({
                  value: v,
                  label: t(v),
                })
              )}
            />
            <TableColumnFilter
              testId={`${testId}CentralSystem`}
              column={instance.getColumn('centralSystemStatus')}
              columnLabel={t('centralSystem')}
              options={stringEnumToArray(CentralSystemStatus).map((v) => ({
                value: v,
                label:
                  v === 'online'
                    ? t('connected', { context: 'status' })
                    : t('disconnected', { context: 'status' }),
              }))}
            />
            {chargingSiteNames ? (
              <TableColumnFilter
                testId={`${testId}ChargingSite`}
                column={instance.getColumn('location.name')}
                columnLabel={t('chargingSite')}
                options={uniq(chargingSiteNames).map((v) => ({
                  value: v,
                  label: v === 'N/A' ? t('unassigned') : v,
                }))}
                isSearchable
                isClearable
                hasTooltip
              />
            ) : (
              <Box p={3}>
                <ListItemText primary={t('noSearchOptions')} />
              </Box>
            )}
            <ClearFiltersButton tableInstance={instance} />
            <Box sx={{ marginLeft: 'auto', px: 2 }} display="flex">
              <TableWebsocketUrl />
              <TableColumnSelect
                instance={instance}
                hiddenColumnsIds={hiddenColumnsIds}
              />
            </Box>
          </Stack>
        </Box>
        {addChargingStationEnabled ? (
          <BrokerAddChargingStationButton testId={testId} />
        ) : null}
      </Box>
      <ESTableWrapper>
        <ESTable>
          <ESTableHead testId={testId} instance={instance} />
          <ESTableBody
            testId={testId}
            observeRowsEnabled={true}
            observeRowsSelectId={(row) => row.original.identityKey}
            observeRowsOnViewportEnter={(rowIds) => {
              rowIds.forEach((rowId) => {
                dispatch(startListeningToStationChanges(rowId));
              });
            }}
            observeRowsOnViewportExit={(rowIds) => {
              rowIds.forEach((rowId) => {
                dispatch(stopListeningToStationChanges(rowId));
              });
            }}
            onRowClick={(row) => {
              navigate(
                `${row.original.name}/${row.original.identityKey}/${BrokerRoutes.ChargingStationDetailsGeneralTab}`
              );
            }}
            rows={rows}
          />
        </ESTable>

        {!hasRows ? (
          <NoTableData message={t('thereAreNoChargingStations')} />
        ) : null}

        <ESTablePagination
          rowsPerPageOptions={rowsPerPageOptions}
          instance={instance}
        />
      </ESTableWrapper>
    </>
  );
};
