import { useEffect } from 'react';

export const useDocumentTitle = (title: string) => {
  useEffect(() => {
    const defaultTitle = document.title;
    document.title = `${defaultTitle} - ${title}`;

    return () => {
      document.title = defaultTitle;
    };
  }, [title]);
};
