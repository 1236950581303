import { TariffElement } from './tariffElement';

export type TariffModel = {
  id: string;
  currency: TariffCurrency;
  type: TariffType | null;
  name: string;
  startDateTime: string | null;
  endDateTime: string | null;
  elements: TariffElement[];
};

export const tariffTypes = [
  'AD_HOC_PAYMENT',
  'PROFILE_CHEAP',
  'PROFILE_FAST',
  'PROFILE_GREEN',
  'REGULAR',
] as const;
export type TariffType = (typeof tariffTypes)[number];

export const tariffCurrencies = ['EUR'] as const;
export type TariffCurrency = (typeof tariffCurrencies)[number];
