import React from 'react';
import { Coords } from '@energy-stacks/fleet/shared';
import { IconButton, Stack, Typography } from '@mui/material';
import { IconX, IconMapPin } from '@tabler/icons-react';
import { ProductIcon } from '@energy-stacks/fleet/shared';
import { BusinessAccountDetailsMapLocation } from './BusinessAccountDetailsMap';
import { TankTypeChip } from '../TankTypeChip';
import { useTranslation } from 'react-i18next';
import { useRemovePlantIdPrefix } from '../../useRemovePlantIdPrefix';

type TankPopupCardProps = Coords & {
  location: BusinessAccountDetailsMapLocation;
  onClose: () => void;
};

export const TankPopupCard: React.FC<TankPopupCardProps> = ({
  location,
  onClose,
}) => {
  const { t } = useTranslation('businessAccounts');
  const removePlantIdPrefix = useRemovePlantIdPrefix();

  return (
    <Stack
      zIndex={1}
      minWidth={203}
      bgcolor="background.paper"
      padding={2}
      borderRadius={(theme) => theme.spacing(1.5)}
      color="grey.600"
    >
      <Stack direction="row" justifyContent="space-between" marginBottom={3}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          marginRight={2}
        >
          <ProductIcon type={location.type} risk={location.risk} />
          <TankTypeChip
            label={removePlantIdPrefix(location.id)}
            variant={location.tankType}
          />
        </Stack>
        <IconButton size="small" onClick={onClose} sx={{ minWidth: '32px' }}>
          <IconX size={14} stroke={1.5} />
        </IconButton>
      </Stack>
      <Typography lineHeight={1} marginBottom={1.5} fontSize="12px">
        {t(`tankCardTypes.${location.tankType}`)}
      </Typography>
      <Stack direction="row" gap={2} alignItems="center" textAlign="center">
        <IconMapPin />
        <Typography lineHeight={1} fontSize="12px">
          {location.address.street}
        </Typography>
      </Stack>
    </Stack>
  );
};
