import { stompClientInstance } from '@energy-stacks/shared';
import {
  AnyAction,
  createAction,
  Dispatch,
  ForkedTask,
  isAnyOf,
  ListenerEffect,
  PayloadAction,
} from '@reduxjs/toolkit';
import log from 'loglevel';
import { chargingStationDetailsSubscriptionState } from './chargingStationDetailsSubscriptionState';
import { ChargingStationModel } from './chargingStationModel';
import { chargingStationsApi } from './chargingStationsApi';
import {
  BrokerChargingStationDto,
  chargingStationBrokerShadowNormalizer,
} from './normalizers/chargingStationDetailsNormalizer';
import { chargingStationsBrokerNormalizer } from './normalizers/chargingStationNormalizer';
import { connectorShadowNormalizer } from './normalizers/connectorShadowNormalizer';

function withPayloadType<T>() {
  return (t: T) => ({ payload: t });
}

export const startListeningToStationChanges = createAction(
  'startListeningToStationChanges',
  withPayloadType<string>()
);

export const stopListeningToStationChanges = createAction(
  'stopListeningToStationChanges',
  withPayloadType<string>()
);

//Reset action to cancel all tasks and unsubscribe from all topics immediately. Fired on location change for example
export const resetListeningToStationChanges = createAction(
  'resetListeningToStationChanges'
);

export const matcher = isAnyOf(
  startListeningToStationChanges,
  stopListeningToStationChanges,
  resetListeningToStationChanges
);

const childrenTasks = new Map<string, ForkedTask<void>>();

const CHILD_TASK_DELAY_MS = 150;

const {
  hasSubscribedToChargingStationDetails,
  subscribeToChargingStationDetails,
  unsubscribeChargingStationDetails,
} = chargingStationDetailsSubscriptionState();

// TODO: Check if we need to subscribe to all these topics. Currently its 3 per id

// Listen to startListeningToStationChanges and stopListeningToStationChanges actions

// For each action, fork a task that will:
// - wait for CHILD_TASK_DELAY_MS
// - if the stompClientInstance is connected, subscribe or unsubscribe to the charging station details
// If we get the same action again within  CHILD_TASK_DELAY_MS, cancel the previous task and start a new one - which is a way to deal with frequent start/stop actions
export const effect: ListenerEffect<
  AnyAction | PayloadAction<string>,
  unknown,
  Dispatch<AnyAction | PayloadAction<string>>
> = async (action, listenerApi) => {
  if (action.type === resetListeningToStationChanges.type) {
    childrenTasks.forEach((task, identityKey) => {
      unsubscribeChargingStationDetails(identityKey);
      task.cancel();
    });
    childrenTasks.clear();
    return;
  }
  if (childrenTasks.has(action.payload)) {
    childrenTasks.get(action.payload)?.cancel();
  }
  const task = listenerApi.fork(async (forkApi) => {
    await forkApi.delay(CHILD_TASK_DELAY_MS);

    if (stompClientInstance && stompClientInstance.connected) {
      if (action.type === startListeningToStationChanges.type) {
        subscribeChargingStationsToDetails(
          action.payload,
          listenerApi.dispatch
        );
      } else if (action.type === stopListeningToStationChanges.type) {
        unsubscribeChargingStationDetails(action.payload);
      }
    } else {
      log.error(
        'Stomp lost connection. Could not subscribe to charging station details topics.'
      );
    }
    // Complete the child by returning a value
    return;
  });
  childrenTasks.set(action.payload, task);
  const result = await task.result;
  if (result.status === 'ok') {
    childrenTasks.delete(action.payload);
  }
};

export const subscribeChargingStationsToDetails = (
  arg: string,
  // Not clear how to type this
  dispatch: Dispatch<any>
) => {
  if (hasSubscribedToChargingStationDetails(arg)) return;

  const topics = [
    `/topic/chargingstation-updated/${arg}`,
    `/topic/chargingstation-shadow-updated/${arg}`,
    `/topic/chargingstation-connector-updated/${arg}`,
  ];
  topics.forEach((topic) => {
    const sub = stompClientInstance?.subscribe(topic, (message) => {
      if (message.body === undefined) return;
      if (topic === `/topic/chargingstation-connector-updated/${arg}`) {
        const connector = connectorShadowNormalizer(
          JSON.parse(message.body).shadow
        );
        dispatch(
          chargingStationsApi.util.updateQueryData(
            'getChargingStations',
            undefined,
            (draft) => {
              const entity = draft.entities[arg] as ChargingStationModel;
              const index = entity.connectors.findIndex(
                (currentConnector) =>
                  currentConnector.connectorId === connector.connectorId
              );
              if (index !== -1) {
                entity.connectors[index] = connector;
              }
            }
          )
        );
        return;
      }
      if (topic === `/topic/chargingstation-shadow-updated/${arg}`) {
        const shadow = chargingStationBrokerShadowNormalizer(
          JSON.parse(message.body)
        );
        dispatch(
          chargingStationsApi.util.updateQueryData(
            'getChargingStations',
            undefined,
            (draft) => {
              const entity = draft.entities[arg] as ChargingStationModel;
              entity.serialNumber = shadow.serialNumber;
              entity.firmware = shadow.firmwareVersion;
              entity.connectors = shadow.connectors;
              entity.ocppProtocol = shadow.ocppProtocol;
              entity.csStatus = shadow.csStatus;
            }
          )
        );
      } else {
        const chargingStation = chargingStationsBrokerNormalizer(
          JSON.parse(message.body) as BrokerChargingStationDto
        );

        dispatch(
          chargingStationsApi.util.updateQueryData(
            'getChargingStations',
            undefined,
            (draft) => {
              const entity = draft.entities[arg] as ChargingStationModel;

              entity.identityKey = chargingStation.identityKey;
              entity.model = chargingStation.model;
              entity.name = chargingStation.name;
              entity.connectors = chargingStation.connectors;
              entity.location = chargingStation.location;
              entity.geolocation = chargingStation.geolocation;
              entity.csmsUuid = chargingStation.csmsUuid;
              entity.csStatus = chargingStation.csStatus;
              entity.centralSystemStatus = chargingStation.centralSystemStatus;
              entity.dateCreated = chargingStation.dateCreated;
            }
          )
        );
      }
    });
    subscribeToChargingStationDetails(arg, sub);
  });
};
