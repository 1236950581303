import { DialogActions, DialogActionsProps } from '@mui/material';

export const ESDialogActions: React.FC<DialogActionsProps> = ({
  sx,
  ...rest
}) => {
  return (
    <DialogActions sx={{ pt: 2, pb: 10, pl: 10, pr: 10, ...sx }} {...rest} />
  );
};
