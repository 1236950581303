import { FleetUserDto } from '../fleetUserDto';
import { FleetUserModel } from '../fleetUserModel';

export const fleetUserModelNormalizer = (
  fleetUserDto: FleetUserDto
): FleetUserModel => ({
  displayName: fleetUserDto.name,
  emailAddress: fleetUserDto.email,
  isActive: true,
  userRole: 'admin',
});
