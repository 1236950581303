import { Vehicles } from '@energy-stacks/fleet/feature-vehicles';
import { DateRange } from '@energy-stacks/core/date-range-picker';
import { VehicleModel } from '@energy-stacks/fleet/feature-vehicles-data';

interface VehiclesStepProps {
  onVehiclesSelected: (vehicleIds: VehicleModel['vehicleId'][]) => void;
  selectedVehicleIds: VehicleModel['vehicleId'][] | undefined;
  onDateRangeChange?: (dateRange: DateRange) => void;
  defaultDateRange: DateRange;
  dateRange: DateRange;
}

export const VehiclesStep: React.FC<VehiclesStepProps> = ({
  onVehiclesSelected,
  dateRange,
  onDateRangeChange,
  selectedVehicleIds,
}) => {
  return (
    <Vehicles
      selectedVehicleIds={selectedVehicleIds}
      onVehiclesSelected={onVehiclesSelected}
      defaultDateRange={dateRange}
      dateRange={dateRange}
      onDateRangeChange={onDateRangeChange}
    />
  );
};
