import GoogleMap from 'google-maps-react-markers';

import React, { useState } from 'react';
import customMapStyle from './customMapStyle.json';

import './map-styles.css';

//NOTE: Not type safe. Lib necessary for compatibility with React 18

type MapControls = {
  panControl?: boolean;
  mapTypeControl?: boolean;
  scaleControl?: boolean;
  streetViewControl?: boolean;
  overviewMapControl?: boolean;
  rotateControl?: boolean;
  fullscreenControl?: boolean;
  zoomControl?: boolean;
};

export interface MapProps {
  children?: React.ReactNode;
  apiKey?: string;
  onChange?: (change: { zoom: number; bounds: any }) => void;
  onMapLoaded?: (map: { map: any }) => void;
  defaultCenter?: {
    lat: number;
    lng: number;
  };
  maxZoom?: number;
  resetBoundsOnResize?: boolean;
  defaultZoom?: number;
  mapOptions?: MapControls;
}

export const Map: React.FC<MapProps> = ({
  children,
  apiKey,
  defaultCenter = {
    lat: 44.0165,
    lng: 21.0059,
  },
  defaultZoom = 11,
  onChange = () => {},
  onMapLoaded = () => {},
  mapOptions = {},
  ...props
}) => {
  const [controls, setControls] = useState<any>(() => {});

  return (
    <GoogleMap
      options={{
        minZoom: 3,
        disableDefaultUI: true,
        zoomControl: false,
        mapTypeControl: true,
        fullscreenControl: true,
        backgroundColor: '#c3d1e4',
        mapTypeId: 'terrain',
        styles: [...customMapStyle],
        restriction: {
          latLngBounds: {
            // This value (85.05115) restricts user from 'panning out' from map into the 'gray area' vertically
            // https://stackoverflow.com/questions/11849636/maximum-lat-and-long-bounds-for-the-world-google-maps-api-latlngbounds
            north: 85.05115,
            south: -85.05115,
            east: 180,
            west: -180,
          },
        },
        ...controls,
        ...mapOptions,
      }}
      onChange={onChange}
      apiKey={apiKey}
      onGoogleApiLoaded={(ev: any) => {
        // Needs to set controls after map is loaded
        setControls({
          mapTypeControlOptions: {
            style: google.maps.MapTypeControlStyle.DEFAULT,
            position: google.maps.ControlPosition.RIGHT_TOP,
          },
          fullscreenControlOptions: {
            position: google.maps.ControlPosition.RIGHT_BOTTOM,
          },
        });
        onMapLoaded?.(ev);
      }}
      defaultCenter={defaultCenter}
      resetBoundsOnResize
      defaultZoom={defaultZoom}
      {...props}
    >
      {children}
    </GoogleMap>
  );
};
