import {
  FleetProduct,
  JobStatus,
  RoutePoint,
  Supplier,
} from '@energy-stacks/fleet/shared';

export const jobStatusFilterOptions = ['TO_DO', 'PLANNED'];

export const jobTypes = ['OUTBOUND', 'INBOUND'] as const;
export type JobType = (typeof jobTypes)[number];

export type JobModel = {
  jobId: string;
  plantId: string;
  supplier: Supplier;
  origin: RoutePoint | null;
  destination: RoutePoint | null;
  jobType: JobType | null;
  product: FleetProduct;
  estimatedDuration: {
    timeMinutes: number;
    distanceKilometres: number;
  } | null;
  dueDate: string;
  earliestStartDate: string;
  datePickUpTill?: string | null;
  dateDropOffTill?: string | null;
  datePickUpFrom?: string | null;
  dateDropOffFrom?: string | null;
  status: JobStatus;
  startDate: string;
  endDate: string;
};
