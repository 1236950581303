import { Dialog } from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
  ESDialogTitle,
  ESDialogContent,
  ESDialogActions,
  ESButton,
} from '@energy-stacks/core/ui';
import { CommonDialogProps } from '../../hooks/useDialog';

type SoftResetDialogProps = CommonDialogProps;

export const SoftResetDialog: React.FC<SoftResetDialogProps> = ({
  isOpen,
  onClose,
  onCallToAction,
}) => {
  const { t } = useTranslation('chargingStations');

  return (
    <Dialog open={isOpen} onClose={onClose} fullWidth maxWidth="sm">
      <ESDialogTitle data-testid="softResetTitle">
        {t('proceedWithSoftResetOfChargingStation')}
      </ESDialogTitle>
      <ESDialogContent data-testid="softResetContent">
        {t('softResetConfirmationDialogDescription')}
      </ESDialogContent>
      <ESDialogActions>
        <ESButton
          data-testid="softResetCancelButton"
          variant="text"
          color="error"
          onClick={onClose}
        >
          {t('cancel')}
        </ESButton>
        <ESButton
          data-testid="softResetResetButton"
          variant="contained"
          onClick={onCallToAction}
        >
          {t('softReset')}
        </ESButton>
      </ESDialogActions>
    </Dialog>
  );
};
