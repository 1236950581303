import { Box, BoxProps, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ReactComponent as NoDataImage } from '../assets/noData.svg';

type TableEmptyMessageProps = Pick<BoxProps, 'sx'>;

export const TableEmptyMessage: React.FC<TableEmptyMessageProps> = ({ sx }) => {
  const [t] = useTranslation('shared');

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      height="100%"
      minHeight={400}
      maxHeight={450}
    >
      <Box textAlign="center" sx={sx}>
        <NoDataImage />

        <Typography variant="h4" mt={25}>
          {t('tableEmptyMessage')}
        </Typography>
      </Box>
    </Box>
  );
};
