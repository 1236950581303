import { useEffect } from 'react';
import { useMatch, useNavigate } from 'react-router-dom';
import {
  setAccessDeniedForUser,
  useAppDispatch,
  useAppSelector,
} from '@energy-stacks/store';
import { useOidc } from '@axa-fr/react-oidc';
import { ESRoutes } from '@energy-stacks/shared';

export const UserAccessGuard = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { isAuthenticated } = useOidc();
  const isAccessDeniedPage = useMatch(ESRoutes.AccessDenied);

  const user = useAppSelector((state) => state.user.user);
  const loginState = useAppSelector((state) => state.user.loginState);

  /**
   * This effect handles user access when AccessDenied page is displayed. On entering this page,
   * if user had logged in in another tab, new authentication state should be picked up and user
   * should be navigated to the home page.
   */
  useEffect(() => {
    if (!isAuthenticated || loginState !== 'loggedIn') {
      return;
    }

    /**
     * There is a possibility that react-oidc tried to fetch user info but failed - this happens on
     * first ever login before user is added to the users table in the database OR when user service
     * is down.
     */
    if (!user) {
      dispatch(setAccessDeniedForUser(null));
      return;
    }

    if (user.userRole === 'noRole' || !user.isActive) {
      dispatch(setAccessDeniedForUser(user));
      return;
    }

    // If we do have a role and the user is active and is accessDenied page, we can navigate to the home page.
    if (isAccessDeniedPage) {
      navigate(ESRoutes.Root);
    }
  }, [
    navigate,
    dispatch,
    user,
    loginState,
    isAuthenticated,
    isAccessDeniedPage,
  ]);

  useEffect(() => {
    if (loginState === 'accessDenied' || loginState === 'unauthenticated') {
      navigate(ESRoutes.AccessDenied);
    }
  }, [navigate, loginState]);

  return null;
};
