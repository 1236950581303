import { ConnectorOcppErrorCode } from '../brokerConnectorShadow';
import { ConnectorModel, OcppErrorCodeEnum } from '../connectorModel';

export const convertShadowOcppErrorCode = (
  shadowOcppErrorCode: ConnectorOcppErrorCode | undefined
): ConnectorModel['ocppErrorCode'] => {
  if (!shadowOcppErrorCode) return OcppErrorCodeEnum.otherError;

  return shadowOcppErrorCodeToOcppErrorCodeMap[shadowOcppErrorCode];
};

const shadowOcppErrorCodeToOcppErrorCodeMap: Record<
  ConnectorOcppErrorCode,
  ConnectorModel['ocppErrorCode']
> = {
  ConnectorLockFailure: OcppErrorCodeEnum.connectorLockFailure,
  EVCommunicationError: OcppErrorCodeEnum.evCommunicationError,
  GroundFailure: OcppErrorCodeEnum.groundFailure,
  HighTemperature: OcppErrorCodeEnum.highTemperature,
  InternalError: OcppErrorCodeEnum.internalError,
  LocalListConflict: OcppErrorCodeEnum.localListConflict,
  NoError: OcppErrorCodeEnum.noError,
  OtherError: OcppErrorCodeEnum.otherError,
  OverCurrentFailure: OcppErrorCodeEnum.overCurrentFailure,
  OverVoltage: OcppErrorCodeEnum.overVoltage,
  PowerMeterFailure: OcppErrorCodeEnum.powerMeterFailure,
  PowerSwitchFailure: OcppErrorCodeEnum.powerSwitchFailure,
  ReaderFailure: OcppErrorCodeEnum.readerFailure,
  ResetFailure: OcppErrorCodeEnum.resetFailure,
  UnderVoltage: OcppErrorCodeEnum.underVoltage,
  WeakSignal: OcppErrorCodeEnum.weakSignal,
} as const;
