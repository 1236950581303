import { throttle } from 'lodash-es';
import { useLayoutEffect, useState } from 'react';

/**
 * Actively checks if element is scrollable by listening to global resize event and comparing
 * element's scrollHeight and clientHeight.
 *
 * @param selector id, class or tag name of targeted element
 * @returns boolean value indicating if element is scrollable
 */
export const useIsElementScrollable = (selector: string, offset = 0) => {
  const [isScrollable, setIsScrollable] = useState(false);

  useLayoutEffect(() => {
    const handleWindowResize = throttle(
      () => {
        const element = document.querySelector(selector);

        if (element) {
          setIsScrollable(element.scrollHeight > element.clientHeight + offset);
        }
      },
      100,
      { trailing: true }
    );

    window.addEventListener('resize', handleWindowResize);
    handleWindowResize();

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, [selector, offset]);

  return isScrollable;
};
