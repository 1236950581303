import { WorkingHoursDto } from '@energy-stacks/fleet/shared';
import { formatDateLong, toPayloadDate } from '@energy-stacks/shared';
import { EditVehicleOperatingHoursFormData } from '../editVehicleOperatingHoursFormData';
import { endOfDay, startOfDay } from 'date-fns';

export const exceptionalWorkingHoursToPayload = (
  workingHours: EditVehicleOperatingHoursFormData['workingHours']['exceptional']
): Pick<WorkingHoursDto, 'exceptional_openings' | 'exceptional_closings'> => {
  const openings = workingHours
    .filter((e) => e.isOperating)
    .map((e) => {
      // Inputting time via keyboard in TimePicker component doesn't work properly if the date is not set to today
      // Should be removed after @mui/x-date-pickers (v.5.0.6) gets updated to next major version or higher
      const startDate = new Date(e.date);
      startDate.setHours(e.startTime.getHours());
      startDate.setMinutes(e.startTime.getMinutes());

      const endDate = new Date(e.date);
      endDate.setHours(e.endTime.getHours());
      endDate.setMinutes(e.endTime.getMinutes());

      return {
        period_begin: toPayloadDate(startDate),
        period_end: toPayloadDate(endDate),
      };
    });
  const closings = workingHours
    .filter((e) => !e.isOperating)
    .map((e) => {
      return {
        // Temporary, until we resolve the time zone issue
        // https://dev.azure.com/p3ds/P3-EnergyStacks/_sprints/taskboard/ElectricRoutes%20Fleet%20and%20Delivery/P3-EnergyStacks/PI3/PI3S18%20-%20Fleet%20and%20Delivery?text=%5Bfe%5D&workitem=97569
        period_begin: formatDateLong(startOfDay(e.date)),
        period_end: toPayloadDate(endOfDay(e.date)),
      };
    });

  return {
    exceptional_openings: openings,
    exceptional_closings: closings,
  };
};
