import { MessageDirection } from '../ocppMessageLogEntry';
import { MessageDirectionModel } from '../ocppMessageLogsModel';

export const messageDirectionNormalizer = (
  messageDirection: MessageDirection
): MessageDirectionModel => {
  return messageDirectionMap[messageDirection];
};

const messageDirectionMap: Record<MessageDirection, MessageDirectionModel> = {
  CS_2_CSMS: 'chargingStationToServer',
  CSMS_2_CS: 'serverToChargingStation',
  CS_2_BROKER: 'chargingStationToBroker',
  BROKER_2_CS: 'brokerToChargingStation',
  CS_2_TRANSLATOR: 'chargingStationToTranslator',
  TRANSLATOR_2_CS: 'translatorToChargingStation',
  CSMS_2_TRANSLATOR: 'ServerToTranslator',
  TRANSLATOR_2_CSMS: 'translatorToServer',
};
