import { TableRow, TableCell, Collapse, Box, Typography } from '@mui/material';

interface CollapsibleRawContentRowProps {
  message: string;
  expanded: boolean;
}

export const CollapsibleRawContentRow: React.FC<
  CollapsibleRawContentRowProps
> = ({ expanded, message }) => {
  return (
    <TableRow>
      <TableCell
        style={{ padding: 0 }}
        colSpan={4}
        sx={{ borderBottom: expanded ? 'auto' : 'unset' }}
      >
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          {message ? (
            <Box
              sx={{
                backgroundColor: 'grey.50',
              }}
            >
              <Typography component={'div'} sx={{ wordBreak: 'break-all' }}>
                <pre
                  style={{
                    whiteSpace: 'pre-wrap',
                    margin: '0px',
                    paddingLeft: '20px',
                    paddingTop: '20px',
                    paddingBottom: '20px',
                  }}
                >
                  {JSON.stringify(JSON.parse(message), null, 2)}
                </pre>
              </Typography>
            </Box>
          ) : null}
        </Collapse>
      </TableCell>
    </TableRow>
  );
};
