import { ESCircularProgress } from '@energy-stacks/core/ui';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';

type ESLoadingIndicatorSizes = 'medium' | 'large';

export interface ESLoadingIndicatorProps {
  text?: string;
  size?: ESLoadingIndicatorSizes;
  color?: string;
}

export const ESLoadingIndicator: React.FC<ESLoadingIndicatorProps> = ({
  text,
  size = 'medium',
  color,
}) => {
  const [t] = useTranslation('shared');

  const typographySizeMap: Record<ESLoadingIndicatorSizes, 'body1' | 'h3'> =
    useMemo(
      () => ({
        medium: 'body1',
        large: 'h3',
      }),
      []
    );

  return (
    <Box textAlign="center">
      <ESCircularProgress size={size} color={color} />
      <Typography variant={typographySizeMap[size]} color={color} mt={4}>
        {text ?? t('loading')}
      </Typography>
    </Box>
  );
};
