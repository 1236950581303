export const brokerAddChargingStationDefaultConnector = {
  evseId: '',
  type: {
    label: '',
    value: '',
  },
  connectorFormat: {
    label: '',
    value: '',
  },
  powerType: {
    label: '',
    value: '',
  },
  maxVoltage: '',
  maxAmperage: '',
  maxPower: '',
};
