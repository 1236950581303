import { useBlocker } from './useBlocker';
import { useState } from 'react';

export interface useRoutingBlockParams {
  blockAction: () => void;
  when: boolean;
  onBlockLifted?: () => void;
}

/**
 * Custom hook that blocks rerouting.
 * Uses react-router's useBlocker.
 * It accepts a function to run once the block has lifted,
 * to handle cases like rerouting after the form is submited.
 *
 * @param params Is an object with the following fields:
 * @field blockAction A function that runs when the routing is blocked. Usually to make a warning popup appear.
 * @field when A boolean that indicated if the routing should be blocked. It only works if the block is NOT manually lifted.
 * @field (Optional) onBlockLifted A function that runs when the block is lifted manually through the liftBlock function.
 *
 * @returns An object containing the function liftBlock which is used to manually lift the routing block
 * and run the onBlockLifted function (if provided).
 */
export function useRoutingBlock(params: useRoutingBlockParams) {
  const { blockAction, when, onBlockLifted } = params;

  const [isLifted, setIsLifted] = useState(false);
  useBlocker(blockAction, !isLifted && when);

  const liftBlock = () => {
    setIsLifted(true);
    onBlockLifted?.();
  };

  return { liftBlock };
}
