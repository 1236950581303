import { countries, Country } from '../country';

export const countryNormalizer = (countryCode: string): Country => {
  const countryName =
    countries.find((country) => country.value === countryCode)?.label ?? '';
  return {
    label: countryName,
    value: countryCode,
  };
};
