import { intervalToDuration } from 'date-fns';

const acceptedDurationUnits = ['dd', 'MMM', 'yyyy', 'hh', 'mm', 'ss'] as const;
type AcceptedDurationUnits = (typeof acceptedDurationUnits)[number];

type DurationFormat = {
  years?: boolean;
  months?: boolean;
  weeks?: boolean;
  days?: boolean;
  hours?: boolean;
  minutes?: boolean;
  seconds?: boolean;
};

const durationFormatMap: Record<AcceptedDurationUnits, keyof DurationFormat> = {
  dd: 'days',
  MMM: 'months',
  yyyy: 'years',
  hh: 'hours',
  mm: 'minutes',
  ss: 'seconds',
};

export const formatDuration = (
  dateStart: Date,
  dateEnd: Date,
  formatString = 'hh:mm:ss'
) => {
  const durationUnits = formatString.split(':');
  if (durationUnits.length > acceptedDurationUnits.length) {
    throw new Error('Invalid duration format');
  }

  const config = durationUnits.reduce((acc, curr) => {
    acc[durationFormatMap[curr as AcceptedDurationUnits]] = true;
    return acc;
  }, {} as DurationFormat);

  const duration = intervalToDuration({
    start: dateStart,
    end: dateEnd,
  });

  const durationArray = Object.entries(duration).filter(
    ([key, value]) => config[key as keyof DurationFormat] && value > 0
  );

  return durationArray.map(([key, value]) => `${value}${key[0]}`).join(' ');
};
