import {
  ESButton,
  ESDialogActions,
  ESDialogContent,
  ESDialogTitle,
  ESSelect,
  useESSnackbar,
} from '@energy-stacks/core/ui';
import { Dialog } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import {
  ChargingStationDetailsModel,
  TriggerMessageRequestOption201,
  triggerMessageRequestOptions201,
  useTriggerMessage201Mutation,
} from '@energy-stacks/broker/feature-charging-stations-data';
import { yupResolver } from '@hookform/resolvers/yup';
import { useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';

interface TriggerConnectorMessageDialogProps {
  isOpen: boolean;
  onClose: () => void;
  identityKey: ChargingStationDetailsModel['identityKey'];
  connectorId: number;
  testId?: string;
}

export const TriggerConnectorMessageDialog201: React.FC<
  TriggerConnectorMessageDialogProps
> = ({ identityKey, connectorId, isOpen, onClose, testId }) => {
  const { t } = useTranslation('chargingStations');
  const { showSnackbar } = useESSnackbar();
  const allowedMessages = [...triggerMessageRequestOptions201];

  const {
    control,
    handleSubmit,
    formState: { isValid },
    getValues,
  } = useForm<{ requestedMessage: TriggerMessageRequestOption201 }>({
    resolver: yupResolver(
      yup.object().shape({
        requestedMessage: yup.string().required().oneOf(allowedMessages),
      })
    ),
    mode: 'onChange',
    defaultValues: {
      requestedMessage: undefined,
    },
  });
  const [triggerMessage, { isLoading }] = useTriggerMessage201Mutation();
  const requestRef = useRef<ReturnType<typeof triggerMessage>>();

  const onSubmit = useCallback(() => {
    const triggerConnectorMessage = triggerMessage({
      identityKey: identityKey,
      requestedMessage: getValues().requestedMessage,
      evse: {
        id: connectorId,
        connectorId: connectorId,
      },
    });

    requestRef.current = triggerConnectorMessage;

    triggerConnectorMessage
      .unwrap()
      .then((result) => {
        if (result.status === 'Rejected') {
          showSnackbar('error', t('triggerMessageRejectedErrorMessage'));
          return;
        }

        if (result.status === 'NotImplemented') {
          showSnackbar('error', t('triggerMessageNotImplementedMessage'));
          return;
        }

        if (result.status === 'Accepted') {
          showSnackbar('success', t('triggerMessageSuccessMessage'));
        }

        onClose();
      })
      .catch((error) => {
        if (error.data?.errorCode === 'RPC_TIMEOUT') {
          showSnackbar('error', t('rpcTimeoutError'));
          return;
        }
        if (error.data?.errorCode === 'RPC_ERROR') {
          showSnackbar('error', t('rpcError'));
          return;
        }

        if (error.name === 'AbortError') {
          return;
        }

        showSnackbar('error');
      });
  }, [
    triggerMessage,
    identityKey,
    getValues,
    connectorId,
    onClose,
    showSnackbar,
    t,
  ]);

  const handleCancel = useCallback(() => {
    requestRef.current?.abort();
    onClose();
  }, [onClose]);

  return (
    <Dialog open={isOpen} onClose={handleCancel} fullWidth maxWidth="sm">
      <ESDialogTitle>{t('remoteTriggerMessage')}</ESDialogTitle>

      <ESDialogContent>
        <Controller
          control={control}
          name="requestedMessage"
          render={({ field: { value, onChange, onBlur } }) => (
            <ESSelect
              testId={`triggerMessageType${testId}`}
              label={t('messageType')}
              options={allowedMessages.map((option) => ({
                value: option,
                label: t(`triggerMessageRequestOption201.${option}`),
              }))}
              onChange={onChange}
              onBlur={onBlur}
              value={value || ''}
              required
              disabled={isLoading}
              sx={{ mt: 2 }}
            />
          )}
        />
      </ESDialogContent>

      <ESDialogActions>
        <ESButton
          data-testid={`triggerMessage${testId}CancelButton`}
          variant="text"
          color="error"
          onClick={handleCancel}
          disabled={isLoading}
        >
          {t('cancel')}
        </ESButton>

        <ESButton
          data-testid={`triggerMessage${testId}TriggerButton`}
          variant="contained"
          onClick={handleSubmit(onSubmit)}
          disabled={!isValid || isLoading}
          loading={isLoading}
        >
          {t('remoteTriggerMessage')}
        </ESButton>
      </ESDialogActions>
    </Dialog>
  );
};
