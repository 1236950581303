import {
  Collapse,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material';
import { IconChevronDown, IconChevronRight } from '@tabler/icons-react';
import { useLocation, useNavigate } from 'react-router-dom';
import React, { useCallback, useMemo, useState } from 'react';
import { ESTooltip } from '@energy-stacks/shared';
import { useAppSelector } from '@energy-stacks/store';
import { useEffectOnce } from 'usehooks-ts';

interface SidebarGroupProps {
  children?: React.ReactNode;
  groupInitialPath: string;
  groupIcon: React.ReactElement;
  groupLabel: string;
  testId?: string;
}

export const SidebarGroup: React.FC<SidebarGroupProps> = ({
  children,
  groupInitialPath,
  groupIcon,
  groupLabel,
  testId,
}) => {
  const [open, setOpen] = useState(false);
  const sidebarOpened = useAppSelector((state) => state.sidebar.sidebarOpened);
  const openGroup = useCallback(() => {
    setOpen(true);
  }, []);

  const navigate = useNavigate();
  const location = useLocation();

  const toggleGroup = useCallback(() => {
    // Not using itemSelected here because it doesn't check for the full pathname
    if (groupInitialPath === location.pathname) {
      return;
    }
    setOpen(!open);
    navigate(groupInitialPath);
  }, [groupInitialPath, location.pathname, navigate, open]);

  const groupRootPath = useMemo(
    () => groupInitialPath.split('/').filter((path) => Boolean(path))[0],
    [groupInitialPath]
  );

  const itemSelected = useMemo(() => {
    const selected = location.pathname.match(groupRootPath);
    return (selected?.index && selected.index === 1) || undefined;
  }, [location.pathname, groupRootPath]);

  useEffectOnce(() => {
    if (itemSelected) {
      openGroup();
    }
  });

  // mini sidebar item: icon + tooltip
  if (!sidebarOpened) {
    return (
      <ESTooltip title={groupLabel} placement="right">
        <ListItemButton
          data-testid={testId}
          selected={itemSelected}
          sx={() => ({
            mt: 2,
            width: 52,
            height: 52,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          })}
          onClick={toggleGroup}
        >
          <ListItemIcon
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            {groupIcon}
          </ListItemIcon>
        </ListItemButton>
      </ESTooltip>
    );
  }

  return (
    <>
      <ListItemButton
        data-testid={testId}
        selected={itemSelected}
        sx={() => ({
          width: '100%',
          mt: 2,
          height: 52,
          display: 'flex',
          alignItems: 'center',
        })}
        onClick={toggleGroup}
      >
        {groupIcon && <ListItemIcon>{groupIcon}</ListItemIcon>}
        <ListItemText
          sx={{ ml: 2 }}
          primary={
            <Typography variant={itemSelected ? 'h5' : 'body1'} color="inherit">
              {groupLabel}
            </Typography>
          }
        />

        {React.Children.count(children) > 0 ? (
          open ? (
            <IconChevronDown stroke={1.5} size="16px" />
          ) : (
            <IconChevronRight stroke={1.5} size="16px" />
          )
        ) : null}
      </ListItemButton>

      <Collapse in={open} timeout="auto" unmountOnExit>
        {children}
      </Collapse>
    </>
  );
};
