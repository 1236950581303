import { DateRange } from './date-range';
import { getDefaultDateRange } from './getDefaultDateRange';
import { DateTimeRangeInputs } from './DateTimeRangeInputs';
import { DateRangePickerCalendar } from './DateRangePickerCalendar';
import { useDateRange } from './useDateRange';
import { DatePickerWrapper } from './DatePickerWrapper';
import { Divider } from '@mui/material';

interface CoreDateRangePickerProps {
  defaultDateRange: DateRange | undefined;
  onDateRangeApplied: (dateRange: DateRange) => void;
  onDateRangeCleared: () => void;
}

export const DateTimePicker: React.FC<CoreDateRangePickerProps> = ({
  defaultDateRange,
  onDateRangeApplied,
  onDateRangeCleared,
}) => {
  const { dateRange, setDateRange, isInvalid } = useDateRange(defaultDateRange);

  return (
    <DatePickerWrapper
      onApplyFilter={() => {
        onDateRangeApplied(dateRange);
      }}
      onClearFilter={() => {
        onDateRangeCleared();
        setDateRange(getDefaultDateRange());
      }}
      isDateInvalid={isInvalid}
    >
      <DateRangePickerCalendar
        dateRange={dateRange}
        onDateRangeChange={setDateRange}
      />
      <Divider />
      <DateTimeRangeInputs
        dateRange={dateRange}
        onDateRangeChange={setDateRange}
      />
      <Divider />
    </DatePickerWrapper>
  );
};
