import {
  ESButton,
  ESTable,
  ESTableBodyCell,
  ESTableBodyCompose,
  ESTableBodyRow,
  ESTableHead,
  ESTableWrapper,
  TableSection,
  useESTableBasic,
} from '@energy-stacks/core/ui';
import { formatDateTime, NoTableData } from '@energy-stacks/shared';
import CreditCardRoundedIcon from '@mui/icons-material/CreditCardRounded';
import { Stack, Typography } from '@mui/material';
import { createColumnHelper, flexRender } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';
import { TransactionFinishReason } from './TransactionFinishReason';
import { TransactionStatusEnum } from '@energy-stacks/broker/feature-dashboard-data';
import {
  BrokerRoutes,
  TransactionDurationCell,
  TransactionStatus,
} from '@energy-stacks/broker/shared';
import { TransactionModel } from '@energy-stacks/broker/feature-transactions-data';
import { IconChargingPile } from '@tabler/icons-react';
import { useNavigate } from 'react-router-dom';

export const LastSessionsTable: React.FC<{
  transactions: TransactionModel[];
  testId?: string;
}> = ({ transactions, testId }) => {
  const { t } = useTranslation('dashboard');

  const navigate = useNavigate();

  const isTransactionActive = transactions?.some(
    (item) => item.transactionStatus === 'charging'
  );

  const columnHelper = createColumnHelper<TransactionModel>();

  const columns = [
    columnHelper.accessor('chargingStationName', {
      header: () => t('chargingStationName'),
      footer: (props) => props.column.id,
      cell: (info) => info.getValue(),
      enableGlobalFilter: false,
      enableSorting: false,
    }),
    columnHelper.accessor('identityKey', {
      sortingFn: 'alphanumeric',
      header: () => t('identityKey'),
      footer: (props) => props.column.id,
      cell: (info) => {
        return (
          (
            <ESButton
              data-testid="chargingStationDashboardButton"
              sx={{
                justifyContent: 'flex-start',
                textDecorationLine: 'underline',
                textUnderlineOffset: '2px',
                '&.MuiButtonBase-root:hover': {
                  bgcolor: 'transparent',
                },
              }}
              variant="text"
              startIcon={<IconChargingPile />}
              onClick={() =>
                navigate(
                  `${BrokerRoutes.ChargingStations}/${info.row.original.chargingStationName}/${info.row.original.identityKey}`
                )
              }
            >
              <span>{info.getValue()}</span>
            </ESButton>
          ) || '-'
        );
      },
      enableSorting: false,
    }),
    columnHelper.accessor((transaction) => String(transaction.transactionId), {
      id: 'transactionId',
      header: () => t('transactionId'),
      footer: (props) => props.column.id,
      cell: (info) => info.getValue() || '-',
      enableSorting: false,
    }),
    columnHelper.accessor('sessionStart', {
      id: 'timestampStart',
      header: () => t('sessionStart'),
      footer: (props) => props.column.id,
      cell: (info) => (info.getValue() ? formatDateTime(info.getValue()) : '-'),
      enableGlobalFilter: false,
      enableSorting: false,
    }),
    columnHelper.accessor('sessionStop', {
      id: 'timestampStop',
      header: () => t('sessionStop'),
      footer: (props) => props.column.id,
      cell: (info) => (info.getValue() ? formatDateTime(info.getValue()) : '-'),
      enableGlobalFilter: false,
      enableSorting: false,
    }),
    columnHelper.accessor('duration', {
      header: () => t('duration'),
      footer: (props) => props.column.id,
      cell: (info) => {
        if (info.row.original.duration === '-') {
          return (
            <TransactionDurationCell
              sessionStartTime={info.row.original.sessionStart}
              isTransactionActive={isTransactionActive || false}
              transactionStatus={info.row.original.transactionStatus}
              value={info.getValue()}
            />
          );
        } else {
          return info.getValue();
        }
      },
      enableGlobalFilter: false,
      enableSorting: false,
    }),
    columnHelper.accessor('consumption', {
      header: () => t('consumption'),
      footer: (props) => props.column.id,
      cell: (info) =>
        info.row.original.transactionStatus !== TransactionStatusEnum.charging
          ? info.getValue()
          : '-',
      enableGlobalFilter: false,
      enableSorting: false,
    }),
    columnHelper.accessor('evseId', {
      header: () => t('evseId'),
      footer: (props) => props.column.id,
      cell: (info) => info.getValue(),
      enableGlobalFilter: false,
      enableSorting: false,
    }),
    columnHelper.accessor('tokenUid', {
      sortingFn: 'alphanumeric',
      id: 'token',
      header: () => t('tokenUid'),
      footer: (props) => props.column.id,
      cell: (info) => (
        <Stack direction={'row'} alignItems={'center'}>
          <CreditCardRoundedIcon sx={{ mr: 2.5 }} />
          {info.getValue()}
        </Stack>
      ),
      enableGlobalFilter: false,
      enableSorting: false,
    }),
    columnHelper.display({
      id: 'transactionStatus',
      header: () => t('transactionStatus'),
      footer: (props) => props.column.id,
      cell: (info) => (
        <Stack direction="row" alignItems="center" gap={2}>
          <TransactionStatus
            transactionStatus={info.row.original.transactionStatus}
          />
          {info.row.original.transactionStatus ===
          TransactionStatusEnum.errorFinish ? (
            <TransactionFinishReason
              transactionFinishReason={
                info.row.original.transactionFinishReason
              }
            />
          ) : null}
        </Stack>
      ),
    }),
  ];

  const { instance, rows } = useESTableBasic(transactions, columns);
  const hasRows = rows.length !== 0;

  return (
    <ESTableWrapper>
      <TableSection>
        <Typography
          data-testid={`${testId}TableTitle`}
          variant="h4"
          color={'grey.900'}
        >
          {t('chargingSessions')}
        </Typography>
      </TableSection>
      <ESTable>
        <ESTableHead testId={testId} instance={instance} />
        <ESTableBodyCompose>
          {rows.map((row, index) => {
            return (
              <ESTableBodyRow
                data-testid={`${testId}BodyRow${index}`}
                key={row.id}
              >
                {row.getVisibleCells().map((cell) => {
                  return (
                    <ESTableBodyCell key={cell.id} sx={{ wordBreak: 'unset' }}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </ESTableBodyCell>
                  );
                })}
              </ESTableBodyRow>
            );
          })}
        </ESTableBodyCompose>
      </ESTable>

      {!hasRows ? (
        <NoTableData message={t('thereAreNoLastSessions')} top={124} />
      ) : null}
    </ESTableWrapper>
  );
};
