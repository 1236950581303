import { usePopover, ESButton } from '@energy-stacks/core/ui';
import { Popover } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as DownloadIcon } from './icons/downloadIcon.svg';

interface ExportButtonProps {
  renderPopover: (closePopover: () => void) => React.ReactNode;
  testId?: string;
}

export const ExportButton: React.FC<ExportButtonProps> = ({
  renderPopover,
  testId,
}) => {
  const { t } = useTranslation('chargingStations');
  const { anchorEl, openPopover, closePopover, popoverOpen } = usePopover();

  return (
    <>
      <ESButton
        data-testid={testId}
        startIcon={<DownloadIcon />}
        variant="contained"
        onClick={openPopover}
      >
        {t('exportButton')}
      </ESButton>
      {popoverOpen ? (
        <Popover
          open={popoverOpen}
          anchorEl={anchorEl}
          onClose={closePopover}
          anchorPosition={{
            top: anchorEl ? anchorEl.getBoundingClientRect().bottom + 8 : 0,
            left: anchorEl ? anchorEl.getBoundingClientRect().left : 0,
          }}
          PaperProps={{
            sx: {
              boxShadow: '0px 2px 1px -1px rgba(56, 67, 107, 0.2)',
              width: anchorEl ? anchorEl?.offsetWidth : 0,
            },
          }}
          anchorReference="anchorPosition"
        >
          {renderPopover(closePopover)}
        </Popover>
      ) : null}
    </>
  );
};
