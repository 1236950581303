import { createSlice } from '@reduxjs/toolkit';

export const APP_FEATURE_KEY = 'app';

export interface AppState {}

const initialState: AppState = {};

export const appSlice = createSlice({
  name: APP_FEATURE_KEY,
  initialState,
  reducers: {},
});

export const appReducer = appSlice.reducer;
