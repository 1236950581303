import React, { useEffect } from 'react';
import { OidcProvider } from '@axa-fr/react-oidc';
import { OidcConfiguration } from '@axa-fr/oidc-client';
import { LogoOverlay, useWindowFocus } from '@energy-stacks/shared';

export const ESOidcProvider: React.FC<{
  configuration: OidcConfiguration;
  LoadingComponent?: React.FC;
  children: React.ReactNode;
}> = ({ configuration, LoadingComponent = LogoOverlay, children }) => {
  return (
    <OidcProvider
      configuration={configuration}
      authenticatingComponent={LoadingComponent}
      callbackSuccessComponent={LoadingComponent}
      loadingComponent={LoadingComponent}
      authenticatingErrorComponent={() => (
        <AuthErrorFallback LoadingComponent={LoadingComponent} />
      )}
      sessionLostComponent={() => (
        <AuthErrorFallback LoadingComponent={LoadingComponent} />
      )}
      //@ts-expect-error Might be a bug in the library
      callbackErrorComponent={() => (
        <AuthErrorFallback LoadingComponent={LoadingComponent} />
      )}
      serviceWorkerNotSupportedComponent={LoadingComponent}
    >
      {children}
    </OidcProvider>
  );
};

const AuthErrorFallback = ({
  LoadingComponent,
}: {
  LoadingComponent: React.FC;
}) => {
  const isFocused = useWindowFocus();

  useEffect(() => {
    if (isFocused) {
      window.location.reload();
    }
  }, [isFocused]);

  return <LoadingComponent />;
};
