import { EditVehicleFormData } from './editVehicleFormData';
import { EditVehiclePayload } from './editVehiclePayload';
import {
  VehicleDetailsModel,
  workingHoursToPayload,
} from '@energy-stacks/fleet/feature-vehicles-data';

export const editVehicleToPayload = (
  formData: EditVehicleFormData,
  coreVehicleId: string,
  vehicleStatus: VehicleDetailsModel['status']
): EditVehiclePayload => {
  return {
    vehicleCoreUuid: coreVehicleId,
    name: formData.name,
    depotId: formData.plantId.value,
    licensePlateNumber: formData.licencePlate,
    status: vehicleStatus,
    workingHours: {
      ...workingHoursToPayload(formData.workingHours),
      // Hardcoded as we don't support it for now, but required on BE
      twentyfourseven: false,
    },
  };
};
