import React, { useCallback, useEffect, useState } from 'react';
import { Typography } from '@mui/material';
import { useChargingStationIdentityKey } from '@energy-stacks/shared';
import {
  ConfigurationKeyStatus,
  ListItemContainer,
  ListItemCell,
  EditConfigurationListItemStatus,
} from '@energy-stacks/feature-charging-station-configuration';
import { ConfigurationFormEntry } from '../../configurationFormEntry';
import {
  ChangeConfigurationStatus,
  useUpdateConfigurationKeyMutation,
} from '@energy-stacks/broker/feature-charging-station-management-data';

const changeConfigurationStatusToKeyListItemStatus: Record<
  ChangeConfigurationStatus,
  ConfigurationKeyStatus
> = {
  [ChangeConfigurationStatus.Accepted]: ConfigurationKeyStatus.Accepted,
  [ChangeConfigurationStatus.Rejected]: ConfigurationKeyStatus.Rejected,
  [ChangeConfigurationStatus.NotSupported]: ConfigurationKeyStatus.NotSupported,
  [ChangeConfigurationStatus.RebootRequired]:
    ConfigurationKeyStatus.RebootRequired,
};

export const EditConfigurationListItem: React.FC<{
  configurationEntry: ConfigurationFormEntry;
  onComplete: (val: {
    key: string;
    value: string;
    status: ConfigurationKeyStatus;
  }) => void;
  onRetry?: () => void;
}> = ({ configurationEntry, onComplete, onRetry }) => {
  const [keyStatus, setKeyStatus] = useState<ConfigurationKeyStatus>(
    ConfigurationKeyStatus.Loading
  );
  const [updateConfigurationKey, { isUninitialized }] =
    useUpdateConfigurationKeyMutation();
  const identityKey = useChargingStationIdentityKey();

  const submitConfiguration = useCallback(() => {
    updateConfigurationKey({
      identityKey,
      body: {
        key: configurationEntry.key,
        value: configurationEntry.newValue,
      },
    })
      .unwrap()
      .then((result) => {
        const listItemStatus =
          changeConfigurationStatusToKeyListItemStatus[result.status];
        setKeyStatus(listItemStatus);

        onComplete({
          key: configurationEntry.key,
          value: configurationEntry.newValue,
          status: listItemStatus,
        });
      })
      .catch((e) => {
        /**
         * Two types of error can happen at this point: RPC_TIMEOUT and generic 500 error.
         * As they are both handled the same way - be pressing on the RETRY button, we can
         * just catch them both and visualize using
         * request timeout status chip and retry button - which will retry the request.
         *
         * For added granularity, use condition "if (e?.data?.errorCode === 'RPC_TIMEOUT') { ..."
         */
        setKeyStatus(ConfigurationKeyStatus.RequestTimeout);

        onComplete({
          key: configurationEntry.key,
          value: configurationEntry.newValue,
          status: ConfigurationKeyStatus.RequestTimeout,
        });
        return;
      });
  }, [
    configurationEntry.key,
    configurationEntry.newValue,
    identityKey,
    updateConfigurationKey,
    onComplete,
  ]);

  const retry = () => {
    setKeyStatus(ConfigurationKeyStatus.Loading);
    submitConfiguration();
    onRetry?.();
  };

  useEffect(() => {
    if (isUninitialized) {
      submitConfiguration();
    }
  }, [isUninitialized, submitConfiguration]);

  return (
    <ListItemContainer>
      {/* Layout expected to behave like a table - but consists of separate items which cannot relayout across rows. Fixed widths required so each "cell" is of
        same width regardless of length of the key name or value.
        */}
      <ListItemCell>
        <Typography variant="body2">{configurationEntry.key}</Typography>
      </ListItemCell>

      <ListItemCell textAlign="center">
        <Typography variant="body2">{configurationEntry.newValue}</Typography>
      </ListItemCell>

      <ListItemCell textAlign="end">
        <EditConfigurationListItemStatus status={keyStatus} onRetry={retry} />
      </ListItemCell>
    </ListItemContainer>
  );
};
