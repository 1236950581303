import { MessageDirectionModel } from '@energy-stacks/broker/feature-ocpp-message-logs-data';
import {
  ChargingStationToBroker,
  ChargingStationToServer,
  ServerToChargingStation,
  BrokerToChargingStation,
  ChargingStationToTranslator,
  TranslatorToChargingStation,
  ServerToTranslator,
  TranslatorToServer,
} from '@energy-stacks/shared';
import React, { memo } from 'react';

interface MessageDirectionProps {
  messageDirection: MessageDirectionModel;
}

const messageDirectionMap: Record<MessageDirectionModel, React.ReactElement> = {
  serverToChargingStation: <ServerToChargingStation />,
  chargingStationToServer: <ChargingStationToServer />,
  chargingStationToBroker: <ChargingStationToBroker />,
  brokerToChargingStation: <BrokerToChargingStation />,
  chargingStationToTranslator: <ChargingStationToTranslator />,
  translatorToChargingStation: <TranslatorToChargingStation />,
  ServerToTranslator: <ServerToTranslator />,
  translatorToServer: <TranslatorToServer />,
};

export const MessageDirection: React.FC<MessageDirectionProps> = memo(
  ({ messageDirection }) => {
    return messageDirectionMap[messageDirection];
  }
);
