export const formatkWhEnergy = (energy: number) => {
  if (energy < 1000) {
    return `${energy}kWh`;
  }
  if (energy < 1000000) {
    return `${(energy / 1000).toFixed(1)}MWh`;
  }

  return `${(energy / 1000000).toFixed(1)}GWh`;
};
