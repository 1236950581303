import { Avatar, Stack, useTheme } from '@mui/material';
import { getUserInitials } from '@energy-stacks/shared';
import {
  useGetProfilePictureQuery,
  useGetUserProfilePictureQuery,
  useGetUserQuery,
} from '@energy-stacks/broker/feature-users-data';

export const UserCell: React.FC<{
  username: string;
  userId: string;
  userEmail: string;
}> = ({ username, userId, userEmail }) => {
  const { data: userPicture } = useGetProfilePictureQuery(userId);
  const { data: loggedUserPicture } = useGetUserProfilePictureQuery();
  const { data: loggedUserData } = useGetUserQuery();
  const userInitials = getUserInitials(username);
  const theme = useTheme();

  const picture =
    userEmail === loggedUserData?.emailAddress
      ? loggedUserPicture
      : userPicture;

  return (
    <Stack direction={'row'} alignItems={'center'}>
      <Avatar
        alt={userInitials}
        src={picture || userInitials}
        sx={{
          ...theme.typography.body1,
          marginRight: 2,
        }}
        color="inherit"
      >
        {userInitials}
      </Avatar>
      {username}
    </Stack>
  );
};
