import { NoTableData, ESTooltip, TableRowsCount } from '@energy-stacks/shared';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import {
  ESTable,
  ESTableBody,
  ESTablePagination,
  ESTableWrapper,
  ESTableHead,
  useESTableBasic,
  usePrevious,
  ESTableBasicConfig,
} from '@energy-stacks/core/ui';
import {
  ChargingStationModel,
  resetListeningToStationChanges,
  startListeningToStationChanges,
  stopListeningToStationChanges,
} from '@energy-stacks/broker/feature-charging-stations-data';
import { createColumnHelper } from '@tanstack/react-table';
import { useEffect } from 'react';
import { Box, Stack } from '@mui/material';
import { ConnectorIcon } from '@energy-stacks/broker/shared';
import { useAppDispatch } from '@energy-stacks/broker/store';

interface SelectableChargingStationsTableProps
  extends ESTableBasicConfig<ChargingStationModel> {
  chargingStations: ChargingStationModel[];
}

export const SelectableChargingStationsTable: React.FC<
  SelectableChargingStationsTableProps
> = ({ chargingStations, onSelectionChange, defaultRowSelection }) => {
  const location = useLocation();
  const [t] = useTranslation('chargingStations');

  const columnHelper = createColumnHelper<ChargingStationModel>();
  const dispatch = useAppDispatch();

  const columns = [
    columnHelper.accessor('identityKey', {
      sortingFn: 'alphanumeric',
      header: () => t('identityKey'),
      footer: (props) => props.column.id,
      cell: (info) => info.getValue() || '-',
    }),
    columnHelper.accessor('name', {
      sortingFn: 'alphanumeric',
      header: () => t('name'),
      footer: (props) => props.column.id,
      cell: (info) => info.getValue() || '-',
    }),
    columnHelper.accessor('model', {
      sortingFn: 'alphanumeric',
      header: () => t('model'),
      footer: (props) => props.column.id,
      cell: (info) => info.getValue() || '-',
    }),
    columnHelper.accessor('connectors', {
      header: () => t('connectors'),
      footer: (props) => props.column.id,
      cell: (info) => {
        return (
          <Stack flexDirection="row" gap={2}>
            {info.row.original.connectors.map((connector) => {
              return (
                <ESTooltip
                  title={
                    <Stack direction="column">
                      <Box component="span">{`${t('connectorId')}: ${
                        connector.connectorId
                      }`}</Box>
                      <Box component="span">{`${t('status')}: ${t(
                        [connector.connectorStatus],
                        {
                          context: 'status',
                        }
                      )}`}</Box>
                      <Box component="span">{`${t('type')}: ${
                        connector.connectorType
                          ? t(`connectorTypes.${connector.connectorType}`)
                          : 'N/A'
                      }`}</Box>
                    </Stack>
                  }
                  key={connector.connectorId}
                  placement="bottom"
                >
                  <ConnectorIcon connectorType={connector.connectorType} />
                </ESTooltip>
              );
            })}
          </Stack>
        );
      },
    }),
  ];

  const { instance, rows, rowsPerPageOptions, handleSelectRow } =
    useESTableBasic(chargingStations, columns, {
      enableRowSelection: true,
      onSelectionChange,
      defaultRowSelection,
      getRowId: (row) => row.identityKey,
    });
  const hasRows = rows.length !== 0;

  const previousRows = usePrevious(rows);

  useEffect(() => {
    dispatch(resetListeningToStationChanges());
  }, [location, dispatch]);

  useEffect(() => {
    (previousRows ?? [])
      .map((row) => row.original.identityKey)
      .forEach((rowId) => {
        dispatch(stopListeningToStationChanges(rowId));
      });
  }, [previousRows, dispatch]);

  return (
    <ESTableWrapper>
      <ESTable>
        <ESTableHead instance={instance} />
        <ESTableBody
          observeRowsEnabled={true}
          observeRowsSelectId={(row) => row.original.identityKey}
          observeRowsOnViewportEnter={(rowIds) => {
            rowIds.forEach((rowId) => {
              dispatch(startListeningToStationChanges(rowId));
            });
          }}
          observeRowsOnViewportExit={(rowIds) => {
            rowIds.forEach((rowId) => {
              dispatch(stopListeningToStationChanges(rowId));
            });
          }}
          onRowClick={handleSelectRow}
          rows={rows}
        />
      </ESTable>

      {!hasRows ? (
        <NoTableData message={t('thereAreNoChargingStations')} />
      ) : null}
      <TableRowsCount instance={instance} />
      <ESTablePagination
        rowsPerPageOptions={rowsPerPageOptions}
        instance={instance}
      />
    </ESTableWrapper>
  );
};
