export interface CdrModel {
  cdrs: Cdr[];
  totalElements: number;
  totalPages: number;
}

export interface Cdr {
  cdrId: string;
  sessionStart: string;
  sessionEnd: string;
  duration: number;
  consumption: number;
  cdrToken: CdrToken;
  totalCost: number;
}

export interface CdrToken {
  uid: string;
  countryCode: string;
  partyId: string;
  type: CdrTokenTypeEnum;
  contractId: string;
}

export enum CdrTokenTypeEnum {
  adHocUser = 'adHocUser',
  appUser = 'appUser',
  other = 'other',
  rfidCard = 'rfidCard',
}
