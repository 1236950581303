import AddIcon from '@mui/icons-material/Add';
import { ESIconButton, ESIconButtonProps } from './ESIconButton';

type ESAddItemButtonProps = ESIconButtonProps;

export const ESAddItemButton: React.FC<ESAddItemButtonProps> = (props) => {
  return (
    <ESIconButton {...props}>
      <AddIcon />
    </ESIconButton>
  );
};
