import React, { useEffect, useMemo } from 'react';
import { EditGeneralDetails } from './EditGeneralDetails';
import { useChargingStationDetails } from '../details/useChargingStationDetails';
import { getLocationFullAddress } from '../getLocationFullAddress';
import { BrokerEditChargingStationFormData } from '@energy-stacks/broker/feature-charging-stations-data';
import { useGetAllLocationsBrokerQuery } from '@energy-stacks/broker/feature-locations-data';
import { useTranslation } from 'react-i18next';
import { Box, CircularProgress, Dialog } from '@mui/material';
import { ESDialogTitle, showSnackbar } from '@energy-stacks/core/ui';
import { useNavigate } from 'react-router-dom';

export const EditChargingStations = () => {
  const [t] = useTranslation('chargingStations');
  const navigate = useNavigate();
  const { chargingStationDetails, isSuccess, isError } =
    useChargingStationDetails();
  const { data: locations, isSuccess: isLocationSuccess } =
    useGetAllLocationsBrokerQuery();
  const [tLocations] = useTranslation('locations');

  const existingLocation = locations?.find((location) =>
    location.chargingStations.some(
      (item) => item.identityKey === chargingStationDetails?.identityKey
    )
  );

  const defaultValues: BrokerEditChargingStationFormData | undefined =
    useMemo(() => {
      return chargingStationDetails && isSuccess && isLocationSuccess
        ? {
            identityKey: chargingStationDetails.identityKey ?? '',
            chargePointName: chargingStationDetails.name ?? '',
            centralSystem: {
              label: chargingStationDetails.csmsDisplayName || '',
              value: chargingStationDetails.csmsUuid || '',
            },
            location: {
              label: existingLocation?.name || '',
              value: existingLocation?.uuid || '',
              description: existingLocation
                ? getLocationFullAddress(existingLocation, tLocations)
                : '',
            },

            coordinates: chargingStationDetails.geolocation || {
              longitude: '',
              latitude: '',
            },
          }
        : undefined;
    }, [
      chargingStationDetails,
      existingLocation,
      isLocationSuccess,
      isSuccess,
      tLocations,
    ]);

  useEffect(() => {
    if (isError) {
      showSnackbar(
        'error',
        'chargingStationDetailsFetchingError',
        'chargingStations'
      );
      navigate(-1);
    }
  }, [isError, navigate]);

  return (
    <Dialog
      onClose={() => navigate(-1)}
      open={true}
      maxWidth="lg"
      fullWidth={true}
    >
      <ESDialogTitle data-testid="editChargingStationDetailsTitle">
        {t('editChargingStationDetailsDialogTitle')}
      </ESDialogTitle>
      {defaultValues && isSuccess ? (
        <EditGeneralDetails defaultValues={defaultValues} />
      ) : (
        <Box
          height={565}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <CircularProgress size={50} />
        </Box>
      )}
    </Dialog>
  );
};
