import { IconButton, SxProps, Theme } from '@mui/material';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';

interface ESDialogCloseButtonProps {
  onClick: () => void;
  sx?: SxProps<Theme> | undefined;
}

export const ESDialogCloseButton: React.FC<ESDialogCloseButtonProps> = ({
  onClick,
  sx,
}) => {
  return (
    <IconButton
      onClick={onClick}
      edge="start"
      color="inherit"
      aria-label="close"
      sx={sx}
    >
      <CloseRoundedIcon />
    </IconButton>
  );
};
