// TODO: This whole file should go to libs/csms/store once csms store is extracted as well

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export const ADD_CHARGING_STATION_FEATURE_KEY = 'addChargingStation';

export interface ChargePointModel {
  inputValue?: string;
  model: string;
}

type AddChargingStationSubmitStatus = 'idle' | 'loading' | 'error' | 'success';

export interface AddChargingStationState {
  chargePointModel: ChargePointModel[];
  isAddChargingStationFormValid: boolean;
  addChargingStationSubmitStatus: AddChargingStationSubmitStatus;
}

const initialState: AddChargingStationState = {
  chargePointModel: [{ model: 'Model 1' }, { model: 'Model 2' }],
  isAddChargingStationFormValid: false,
  addChargingStationSubmitStatus: 'idle',
};

export const addChargingStationSlice = createSlice({
  name: ADD_CHARGING_STATION_FEATURE_KEY,
  initialState,
  reducers: {
    setIsAddChargingStationFormValid: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isAddChargingStationFormValid = payload;
    },
    setAddChargingStationSubmitStatus: (
      state,
      { payload }: PayloadAction<AddChargingStationSubmitStatus>
    ) => {
      state.addChargingStationSubmitStatus = payload;
    },
  },
});

export const {
  setIsAddChargingStationFormValid,
  setAddChargingStationSubmitStatus,
} = addChargingStationSlice.actions;

export const addChargingStationReducer = addChargingStationSlice.reducer;
