import { ExceptionalWorkingHours } from '@energy-stacks/fleet/feature-vehicles-data';
import { isAfter, isValid, setHours, setMinutes } from 'date-fns';
import * as yup from 'yup';

export const exceptionalHoursValidationSchema = yup
  .object()
  .shape({
    isOperating: yup.boolean(),
    date: yup.date().typeError('invalidDate').required('exceptionDateRequired'),
    startTime: yup.mixed().when('isOperating', {
      is: true,
      then: yup
        .date()
        .nullable()
        .typeError('invalidTime')
        .required('startTimeRequired'),
      otherwise: yup.mixed().notRequired(),
    }),
    endTime: yup.mixed().when('isOperating', {
      is: true,
      then: yup
        .date()
        .nullable()
        .typeError('invalidTime')
        .when('startTime', {
          is: (startTime: Date | null) => isValid(startTime),
          then: yup
            .date()
            .nullable()
            .typeError('invalidTime')
            .test(
              'range',
              'endTimeBeforeStartTime',
              (endTime, { parent: { startTime } }) => {
                if (!endTime || !isValid(endTime)) return true;

                // NOTE: Fix applied to TimePicker MUI component:
                // Needed for keyboard input to work properly in TimePicker
                // https://github.com/mui/mui-x/issues/6587
                // Fixed in next major version of @mui/x-date-pickers (v.6)
                // Which will set the date to the current one.
                // Times should be set to same date in order for validation to work
                const start = setMinutes(
                  setHours(new Date(), startTime.getHours()),
                  startTime.getMinutes()
                );
                const end = setMinutes(
                  setHours(new Date(), endTime.getHours()),
                  endTime.getMinutes()
                );
                return isAfter(end, start);
              }
            ),
        })
        .required('endTimeRequired'),
      otherwise: yup.mixed().notRequired(),
    }),
  })
  .test('uniqueDates', (value, { createError, parent, path }) => {
    const occurrenceCount = parent.filter(
      (e: ExceptionalWorkingHours) =>
        e.date?.toDateString() === value.date?.toDateString()
    ).length;

    if (occurrenceCount > 1) {
      throw createError({
        message: 'multipleExceptionsWithSameDate',
        path: `${path}.date`,
      });
    }
    return true;
  });
