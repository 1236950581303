import { ParkingType } from '../locationDto';
import { LocationParkingType, LocationParkingTypeEnum } from '../locationModel';

export const locationParkingTypeNormalizer = (
  parkingType: ParkingType
): LocationParkingType => {
  return locationParkingTypeMap[parkingType];
};

const locationParkingTypeMap: Record<ParkingType, LocationParkingType> = {
  ALONG_MOTORWAY: LocationParkingTypeEnum.alongMotorway,
  ON_DRIVEWAY: LocationParkingTypeEnum.driveway,
  ON_STREET: LocationParkingTypeEnum.onStreet,
  PARKING_GARAGE: LocationParkingTypeEnum.parkingGarage,
  PARKING_LOT: LocationParkingTypeEnum.parkingLot,
  UNDERGROUND_GARAGE: LocationParkingTypeEnum.undergroundGarage,
  OTHER: LocationParkingTypeEnum.other,
};
