import { ProcedureName } from './ocppMessageLogEntry';
import { OcppAction } from './ocppMessageLogsModel';

export const mapOcppActionToOcppProcedure = (
  ocppAction: OcppAction
): ProcedureName | undefined => {
  if (ocppAction === 'unknown') {
    return undefined;
  }
  return ocppProcedureMap[ocppAction];
};

const ocppProcedureMap: Record<
  Exclude<OcppAction, 'unknown'>,
  ProcedureName
> = {
  authorize: 'AUTHORIZE',
  bootNotification: 'BOOT_NOTIFICATION',
  cancelReservation: 'CANCEL_RESERVATION',
  changeAvailability: 'CHANGE_AVAILABILITY',
  changeConfiguration: 'CHANGE_CONFIGURATION',
  clearCache: 'CLEAR_CACHE',
  clearChargingProfile: 'CLEAR_CHARGING_PROFILE',
  dataTransfer: 'DATA_TRANSFER',
  diagnosticsStatusNotification: 'DIAGNOSTICS_STATUS_NOTIFICATION',
  firmwareStatusNotification: 'FIRMWARE_STATUS_NOTIFICATION',
  getConfiguration: 'GET_CONFIGURATION',
  getDiagnostics: 'GET_DIAGNOSTICS',
  getLocalListVersion: 'GET_LOCAL_LIST_VERSION',
  heartbeat: 'HEARTBEAT',
  meterValues: 'METER_VALUES',
  remoteStartTransaction: 'REMOTE_START_TRANSACTION',
  remoteStopTransaction: 'REMOTE_STOP_TRANSACTION',
  reserveNow: 'RESERVE_NOW',
  reset: 'RESET',
  sendLocalList: 'SEND_LOCAL_LIST',
  setChargingProfile: 'SET_CHARGING_PROFILE',
  startTransaction: 'START_TRANSACTION',
  stopTransaction: 'STOP_TRANSACTION',
  statusNotification: 'STATUS_NOTIFICATION',
  unlockConnector: 'UNLOCK_CONNECTOR',
  updateFirmware: 'UPDATE_FIRMWARE',
  triggerMessage: 'TRIGGER_MESSAGE',
  getCompositeSchedule: 'GET_COMPOSITE_SCHEDULE',
};
