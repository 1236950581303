import { TourModel } from '@energy-stacks/fleet/feature-tours-data';
import { useVehicles } from '@energy-stacks/fleet/feature-vehicles';
import { TableColumnFilter } from '@energy-stacks/shared';
import { Table } from '@tanstack/react-table';
import { uniqBy } from 'lodash-es';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

type ToursTableVehicleFilterProps = {
  instance: Table<TourModel>;
};

export const ToursTableVehicleFilter: React.FC<
  ToursTableVehicleFilterProps
> = ({ instance }) => {
  const { t } = useTranslation('tours');
  const { data } = useVehicles();

  const options = useMemo(() => {
    const optionsArray = (data ?? []).map((v) => ({
      label: v.name,
      value: v.name,
    }));
    return uniqBy(optionsArray, (el) => el.value);
  }, [data]);

  return (
    <TableColumnFilter
      column={instance.getColumn('vehicle')}
      columnLabel={t('vehicleColumnHeader')}
      options={options}
      isClearable
      initialFilters={
        (instance.getColumn('vehicle')?.getFilterValue() ?? []) as string[]
      }
    />
  );
};
