import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export const STEPPER_FEATURE_KEY = 'stepper';

export interface StepperState {
  stepperActiveStep: number;
  isLastStep: boolean;
}

const initialState: StepperState = {
  stepperActiveStep: 0,
  isLastStep: false,
};

export const stepperSlice = createSlice({
  name: STEPPER_FEATURE_KEY,
  initialState,
  reducers: {
    handleNextStep: (
      state,
      { payload: totalNumberOfSteps }: PayloadAction<number>
    ) => {
      if (state.stepperActiveStep === totalNumberOfSteps - 1) {
        return;
      }
      state.stepperActiveStep = state.stepperActiveStep + 1;
    },
    handlePreviousStep: (state) => {
      if (state.stepperActiveStep === 0) {
        return;
      }
      state.stepperActiveStep--;
    },
    resetStepper: (state) => {
      state.stepperActiveStep = 0;
      state.isLastStep = false;
    },
    setIsLastStep: (state, { payload }: PayloadAction<boolean>) => {
      state.isLastStep = payload;
    },
  },
});

export const {
  handleNextStep,
  handlePreviousStep,
  resetStepper,
  setIsLastStep,
} = stepperSlice.actions;

export const stepperReducer = stepperSlice.reducer;
