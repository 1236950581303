import { MessageTypeEnum } from '@energy-stacks/broker/feature-ocpp-message-logs-data';
import {
  FilterPopupClearButton,
  TableFilterButton,
  stringEnumToArray,
} from '@energy-stacks/shared';

import { Box, Checkbox, Divider, MenuItem } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MessageTypeOption } from './MessageTypeOption';
import { xor } from 'lodash-es';

interface OcppMessageLogsMessageTypeFilterProps {
  messageTypeFilter: MessageTypeEnum[] | undefined;
  onMessageTypeFilterChange: (
    messageTypeFilter: MessageTypeEnum[] | undefined
  ) => void;
}

export const OcppMessageLogsMessageTypeFilter: React.FC<
  OcppMessageLogsMessageTypeFilterProps
> = ({ messageTypeFilter, onMessageTypeFilterChange }) => {
  const { t } = useTranslation('chargingStations');

  const [checkedFilters, setCheckedFilters] = useState<MessageTypeEnum[]>([]);

  // Handling filters externally e.g Clear all filters
  useEffect(() => {
    if (!messageTypeFilter) {
      setCheckedFilters([]);
    }
  }, [messageTypeFilter]);

  useEffect(() => {
    if (checkedFilters.length > 0) {
      onMessageTypeFilterChange(checkedFilters);
    } else {
      onMessageTypeFilterChange(undefined);
    }
  }, [checkedFilters, onMessageTypeFilterChange]);

  return (
    <TableFilterButton
      testId="messageType"
      appliedFiltersCount={checkedFilters.length}
      title={t('messageType')}
      renderPopover={(closePopover) => {
        return (
          <>
            <Box>
              {stringEnumToArray(MessageTypeEnum).map((option) => (
                <MenuItem
                  data-testid={`option${option}`}
                  key={option}
                  value={option}
                  onClick={() => {
                    setCheckedFilters(
                      xor(checkedFilters, [option as MessageTypeEnum])
                    );
                  }}
                >
                  <Checkbox
                    sx={{ p: 0, mr: 2.5 }}
                    checked={checkedFilters.includes(option as MessageTypeEnum)}
                  />
                  <MessageTypeOption
                    messageTypeEnum={option as MessageTypeEnum}
                  />
                </MenuItem>
              ))}
            </Box>

            <Divider />

            <FilterPopupClearButton
              onClick={() => {
                setCheckedFilters([]);
                closePopover();
              }}
              disabled={!messageTypeFilter}
            />
          </>
        );
      }}
      isActive={Boolean(messageTypeFilter)}
    />
  );
};
