export interface Country {
  value: string;
  label: string;
}

export const countries: Country[] = [
  {
    label: 'albania',
    value: 'ALB',
  },
  {
    label: 'algeria',
    value: 'DZA',
  },
  {
    label: 'andorra',
    value: 'AND',
  },
  {
    label: 'austria',
    value: 'AUT',
  },
  {
    label: 'belarus',
    value: 'BLR',
  },
  {
    label: 'belgium',
    value: 'BEL',
  },
  {
    label: 'bosniaAndHerzegovina',
    value: 'BIH',
  },
  {
    label: 'bulgaria',
    value: 'BGR',
  },
  {
    label: 'canada',
    value: 'CAN',
  },
  {
    label: 'china',
    value: 'CHN',
  },
  {
    label: 'croatia',
    value: 'HRV',
  },
  {
    label: 'czechRepublic',
    value: 'CZE',
  },
  {
    label: 'denmark',
    value: 'DNK',
  },
  {
    label: 'egypt',
    value: 'EGY',
  },
  {
    label: 'estonia',
    value: 'EST',
  },
  {
    label: 'faroeIslands',
    value: 'FRO',
  },
  {
    label: 'finland',
    value: 'FIN',
  },
  {
    label: 'france',
    value: 'FRA',
  },
  {
    label: 'germany',
    value: 'DEU',
  },
  {
    label: 'gibraltar',
    value: 'GIB',
  },
  {
    label: 'greece',
    value: 'GRC',
  },
  {
    label: 'hungary',
    value: 'HUN',
  },
  {
    label: 'iceland',
    value: 'ISL',
  },
  {
    label: 'india',
    value: 'IND',
  },
  {
    label: 'ireland',
    value: 'IRL',
  },
  {
    label: 'italy',
    value: 'ITA',
  },
  {
    label: 'japan',
    value: 'JPN',
  },
  {
    label: 'koreaRepublic',
    value: 'KOR',
  },
  {
    label: 'latvia',
    value: 'LVA',
  },
  {
    label: 'liechtenstein',
    value: 'LIE',
  },
  {
    label: 'lithuania',
    value: 'LTU',
  },
  {
    label: 'luxembourg',
    value: 'LUX',
  },
  {
    label: 'northMacedonia',
    value: 'MKD',
  },
  {
    label: 'malta',
    value: 'MLT',
  },
  {
    label: 'mexico',
    value: 'MEX',
  },
  {
    label: 'moldovaRepublic',
    value: 'MDA',
  },
  {
    label: 'monaco',
    value: 'MCO',
  },
  {
    label: 'montenegro',
    value: 'MNE',
  },
  {
    label: 'morocco',
    value: 'MAR',
  },
  {
    label: 'netherlands',
    value: 'NLD',
  },
  {
    label: 'norway',
    value: 'NOR',
  },
  {
    label: 'poland',
    value: 'POL',
  },
  {
    label: 'portugal',
    value: 'PRT',
  },
  {
    label: 'romania',
    value: 'ROU',
  },
  {
    label: 'russianFederation',
    value: 'RUS',
  },
  {
    label: 'sanMarino',
    value: 'SMR',
  },
  {
    label: 'serbia',
    value: 'SRB',
  },
  {
    label: 'slovakia',
    value: 'SVK',
  },
  {
    label: 'slovenia',
    value: 'SVN',
  },
  {
    label: 'southAfrica',
    value: 'ZAF',
  },
  {
    label: 'spain',
    value: 'ESP',
  },
  {
    label: 'sweden',
    value: 'SWE',
  },
  {
    label: 'switzerland',
    value: 'CHE',
  },
  {
    label: 'tunisia',
    value: 'TUN',
  },
  {
    label: 'turkey',
    value: 'TUR',
  },
  {
    label: 'ukraine',
    value: 'UKR',
  },
  {
    label: 'unitedKingdom',
    value: 'GBR',
  },
  {
    label: 'unitedStates',
    value: 'USA',
  },
];
