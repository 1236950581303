import { createSlice } from '@reduxjs/toolkit';

export const THEME_FEATURE_KEY = 'theme';

export type ThemeMode = 'light' | 'dark';

export interface ThemeState {
  themeState: ThemeMode;
}

const initialState: ThemeState = {
  themeState: 'light',
};

export const themeSlice = createSlice({
  name: THEME_FEATURE_KEY,
  initialState,
  reducers: {
    setDarkTheme: (state) => {
      state.themeState = 'dark';
    },
    setLightTheme: (state) => {
      state.themeState = 'light';
    },
  },
});

export const { setDarkTheme, setLightTheme } = themeSlice.actions;

export const themeReducer = themeSlice.reducer;
