import { IconButton, useTheme } from '@mui/material';
import { IconMenu2 } from '@tabler/icons-react';
import { toggleSidebar, useAppDispatch } from '@energy-stacks/store';

export const SidebarToggler = () => {
  const dispatch = useAppDispatch();
  const theme = useTheme();

  return (
    <IconButton
      size="large"
      aria-label="menu"
      sx={{
        color: 'dark.light',
        height: 48,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        ':hover': {
          backgroundColor: theme.palette.background.paper,
        },
      }}
      onClick={() => {
        dispatch(toggleSidebar());
      }}
      data-testid="sidebarToggler"
    >
      <IconMenu2 />
    </IconButton>
  );
};
