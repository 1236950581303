import { ParkingType } from './locationDto';
import { LocationParkingType, LocationParkingTypeEnum } from './locationModel';

export const convertLocationTypeToParkingType = (
  locationType: LocationParkingType
) => {
  if (locationType === LocationParkingTypeEnum.other) {
    return undefined;
  }
  return locationTypeToParkingLocationTypeMap[locationType];
};

const locationTypeToParkingLocationTypeMap: Record<
  Exclude<LocationParkingType, 'other'>,
  ParkingType
> = {
  [LocationParkingTypeEnum.alongMotorway]: 'ALONG_MOTORWAY',
  [LocationParkingTypeEnum.driveway]: 'ON_DRIVEWAY',
  [LocationParkingTypeEnum.onStreet]: 'ON_STREET',
  [LocationParkingTypeEnum.parkingGarage]: 'PARKING_GARAGE',
  [LocationParkingTypeEnum.parkingLot]: 'PARKING_LOT',
  [LocationParkingTypeEnum.undergroundGarage]: 'UNDERGROUND_GARAGE',
} as const;
