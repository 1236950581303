import React, { CSSProperties, memo } from 'react';
import { ESTableBodyRow } from './ESTableBodyRow';
import { ESTableBodyCell } from './ESTableBodyCell';
import { Row, flexRender } from '@tanstack/react-table';
import { DroppableItem } from './DndRowsOptions';

type ESTableRowDragPreviewProps<T> = {
  item: DroppableItem<T>;
};

export const ESTableRowDragPreview = memo(function ESTableRowDragPreview<T>({
  item,
}: ESTableRowDragPreviewProps<T>) {
  const selectedRowIds = item.selectedRowIds;

  return (
    <table>
      <tbody>
        {selectedRowIds.length > 1 ? (
          selectedRowIds.slice(0, 3).map((itemId, i) => {
            return (
              <RowPreview
                key={itemId}
                // Render the same row data three times since information is visible only for the top most row,
                // and other rows are just creating illusion of multiple rows being dragged.
                row={item.row}
                styles={{
                  zIndex: selectedRowIds.length - i,
                  transform: `rotateZ(${-i * 0.8}deg)`,
                }}
              />
            );
          })
        ) : (
          <RowPreview row={item.row} />
        )}
      </tbody>
    </table>
  );
});

function RowPreview<T>({
  styles,
  row,
}: {
  styles?: CSSProperties;
  row: Row<T>;
}) {
  return (
    <ESTableBodyRow
      sx={{
        display: 'block',
        borderCollapse: 'collapse',
        transformOrigin: 'bottom left',
        border: '1px solid',
        borderColor: 'grey.100',
      }}
      style={{
        position: 'absolute',
        ...(styles ?? {}),
      }}
    >
      {row
        .getVisibleCells()
        .slice(0, 5)
        .map((cell, i, cells) => {
          const isActionsColumn =
            cells.length - 1 === i && cell.id.includes('actions');

          return (
            <ESTableBodyCell
              key={cell.id}
              align={'left'}
              sx={{
                '&': {
                  width: !isActionsColumn ? cell.column.getSize() : '20px',
                },
                whiteSpace: 'nowrap',
                backgroundColor:
                  cell.column.columnDef.meta?.backgroundColor || 'white',
                padding: cell.column.columnDef.meta?.cellPadding,
                paddingRight: isActionsColumn ? 8 : undefined,
              }}
            >
              {flexRender(cell.column.columnDef.cell, cell.getContext())}
            </ESTableBodyCell>
          );
        })}
    </ESTableBodyRow>
  );
}
