import {
  Box,
  Step,
  StepLabel,
  Stepper,
  Typography,
  useTheme,
} from '@mui/material';
import { createRef, FC } from 'react';
import { CSSTransition } from 'react-transition-group';

import './es-stepper.css';

export interface ESStep {
  stepName: string;
  stepLabel?: string;
}

type ESStepperProps = {
  steps: ESStep[];
  activeStep: number;
};

export const ESStepper: FC<ESStepperProps> = ({ steps, activeStep }) => {
  const stepsWithRefs = steps.map((step) => ({
    stepName: step.stepName,
    stepLabel: step.stepLabel,
    nodeRef: createRef<HTMLDivElement>(),
  }));
  const { palette } = useTheme();

  return (
    <Box sx={{ width: '70%', mx: 'auto' }}>
      <Stepper activeStep={activeStep}>
        {stepsWithRefs.map(({ stepName, stepLabel, nodeRef }, i) => {
          const isActive = i + 1 === activeStep;
          const isCompleted = i + 1 < activeStep;
          const isDisabled = i + 1 > activeStep;

          return (
            <Step
              key={stepName}
              active={isActive}
              completed={isCompleted}
              disabled={isDisabled}
            >
              {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
              {/* @ts-ignore */}
              <CSSTransition
                nodeRef={nodeRef}
                in={!isCompleted}
                classNames="step"
                timeout={0}
              >
                {() => (
                  <StepLabel
                    optional={
                      <Typography fontSize={12} color="grey.200">
                        {stepLabel}
                      </Typography>
                    }
                    StepIconProps={{
                      ref: nodeRef,
                      sx: {
                        color: isDisabled
                          ? // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                            // @ts-ignore
                            palette.primary[200]
                          : palette.primary.main,
                        fontSize: 3.5,
                        fontWeight: 800,
                        width: 26,
                        height: 26,
                        '& .MuiStepIcon-text': {
                          fill: isDisabled
                            ? // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                              // @ts-ignore
                              palette.primary[800]
                            : palette.background.paper,
                        },
                        '&.MuiStepIcon-root.Mui-completed': {
                          fill: palette.success.dark,
                        },
                      },
                    }}
                    sx={{
                      '& .MuiStepLabel-label': {
                        color: palette.grey[200],
                        fontWeight: 400,
                      },
                      '& .MuiStepLabel-label.Mui-active': {
                        color: palette.grey[50],
                        fontWeight: 800,
                      },
                      '& .MuiStepLabel-label.Mui-completed': {
                        color: palette.grey[200],
                      },
                    }}
                  >
                    {stepName}
                  </StepLabel>
                )}
              </CSSTransition>
            </Step>
          );
        })}
      </Stepper>
    </Box>
  );
};
