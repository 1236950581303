import { Grid, useMediaQuery } from '@mui/material';
import { useChargingStationDetails } from '../useChargingStationDetails';
import { MapDetails } from '../MapDetails';
import { ChargingStationDetailsConnectorsTabV201 } from './ChargingStationDetailsConnectorsTabV201';
import { BrokerChargingStationGeneralDetails201 } from '../BrokerChargingStationGeneralDetails201';

export const ChargingStationGeneralDetailsV201 = () => {
  const { chargingStationDetails } = useChargingStationDetails();
  const isFullHD = useMediaQuery('(min-width: 1920px)');
  const numberOfConnectors = chargingStationDetails?.connectors.length || 0;

  return (
    <Grid container spacing={6} sx={{ flex: 1 }}>
      <Grid item sm={12} md={12} lg={5} xl={4}>
        <BrokerChargingStationGeneralDetails201 />
      </Grid>
      <Grid
        item
        sm={12}
        md={12}
        lg={7}
        xl={8}
        sx={{ display: 'flex', flexDirection: 'column', gap: 4 }}
      >
        <ChargingStationDetailsConnectorsTabV201 />
        {isFullHD || numberOfConnectors < 2 ? <MapDetails /> : null}
      </Grid>
      {!isFullHD && numberOfConnectors > 1 ? (
        <Grid item sm={12} md={12} lg={12} xl={12}>
          <MapDetails />
        </Grid>
      ) : null}
    </Grid>
  );
};
