import {
  ChargingStationDetailsModel,
  ConnectorModel,
} from '@energy-stacks/broker/feature-charging-stations-data';
import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { ConnectorCard201 } from '../connectors/ConnectorCard201';
import { useTranslation } from 'react-i18next';
import { useGetUiBrandingQuery } from '@energy-stacks/broker/feature-settings-data';
import { ESMenu, ESMenuItem, usePopover } from '@energy-stacks/core/ui';
import { useEffect, useState } from 'react';
import { ConnectorButtonV201 } from './ConnectorButtonV201';
import { ConnectorIcon } from '@energy-stacks/broker/shared';
import { ViewMode } from './ConnectorGroupsV201';
import { ChangeEvseAvailabilityStatusDialog201 } from '../change-availability/ChangeEvseAvailabilityStatusDialog201';
import { ResetEvseDialogV201 } from './ResetEvseDialogV201';

type ConnectorGroupV201Dialog = 'availability201' | 'reset201';

export const ConnectorGroupV201 = ({
  viewMode,
  chargingStationIdentityKey,
  chargingStationStatus,
  evseId,
  connectors,
  chargingStationOnline,
}: {
  viewMode: ViewMode;
  chargingStationIdentityKey: ChargingStationDetailsModel['identityKey'];
  chargingStationStatus: ChargingStationDetailsModel['csStatus'];
  evseId: number;
  connectors: ConnectorModel[];
  chargingStationOnline: boolean;
}) => {
  const [t] = useTranslation('chargingStations');
  const { data: uiBranding } = useGetUiBrandingQuery();
  const { palette } = useTheme();
  const [openedDialog, setOpenedDialog] = useState<ConnectorGroupV201Dialog>();
  const hasBorderRadius =
    uiBranding?.hasBorderRadius || uiBranding?.hasBorderRadius === undefined;

  const [selectedConnector, setSelectedConnector] = useState<ConnectorModel>();
  const { anchorEl, openPopover, closePopover, popoverOpen } = usePopover();

  useEffect(() => {
    setSelectedConnector(connectors[0]);
  }, [connectors]);

  return (
    <Grid
      key={evseId}
      item
      xs={12}
      lg={12}
      xl={12}
      sx={{ flexBasis: 'auto !important' }}
    >
      {selectedConnector ? (
        <Card
          data-testid={`connectorCard${evseId}V201`}
          sx={{
            borderRadius: hasBorderRadius ? (theme) => theme.spacing(3) : 0,
          }}
        >
          <CardHeader
            sx={{
              backgroundColor: (theme) => theme.palette.dark.main,
              p: 4,
            }}
            title={
              <Stack
                alignItems="center"
                flexDirection="row"
                justifyContent="space-between"
              >
                <Stack direction="row" alignItems="center" gap={3}>
                  <Typography
                    sx={{
                      color: (theme) => theme.palette.dark.light,
                    }}
                  >
                    {`${t('evseId')} - ${evseId}`}
                  </Typography>
                </Stack>
                <Stack direction="row" alignItems="center" gap={3}>
                  <ConnectorButtonV201
                    connector={selectedConnector}
                    popoverOpen={popoverOpen}
                    onClick={openPopover}
                    onClose={closePopover}
                    anchorEl={anchorEl}
                    PickerComponent={
                      <>
                        {connectors.map((connector) => (
                          <ESMenuItem
                            key={connector.connectorId}
                            data-testid={`menuItem${connector.connectorId}V201`}
                            onClick={() => {
                              setSelectedConnector(connector);
                              closePopover();
                            }}
                          >
                            <Stack direction="row">
                              <ConnectorIcon
                                connectorType={connector.connectorType}
                                size={20}
                              />
                              <Typography sx={{ pl: 2 }}>
                                {`${t('connector')} ${connector.connectorId}`}
                              </Typography>
                            </Stack>
                          </ESMenuItem>
                        ))}
                      </>
                    }
                  />
                  <ESMenu
                    testId={`evseCard${evseId}Menu`}
                    iconColor={palette.dark.light}
                    disableMenu={!!openedDialog}
                  >
                    <ESMenuItem
                      testId={`${evseId}changeEvseAvailability`}
                      onClick={() => setOpenedDialog('availability201')}
                    >
                      {t('changeEvseAvailability')}
                    </ESMenuItem>
                    <ESMenuItem
                      testId={`${evseId}resetEvse`}
                      onClick={() => setOpenedDialog('reset201')}
                    >
                      {`${t('reset')} ${evseId}`}
                    </ESMenuItem>
                  </ESMenu>
                </Stack>
              </Stack>
            }
          ></CardHeader>
          <CardContent
            sx={{
              p: 4,
            }}
          >
            <ConnectorCard201
              viewMode={viewMode}
              testId={`ConnectorCar${selectedConnector}`}
              connector={selectedConnector}
              chargingStationIdentityKey={chargingStationIdentityKey}
              chargingStationOnline={chargingStationOnline}
            />
          </CardContent>
        </Card>
      ) : null}
      <ResetEvseDialogV201
        identityKey={chargingStationIdentityKey}
        isOpen={openedDialog === 'reset201'}
        onClose={() => setOpenedDialog(undefined)}
        evseId={evseId}
      />
      <ChangeEvseAvailabilityStatusDialog201
        identityKey={chargingStationIdentityKey}
        currentStatus={chargingStationStatus}
        isOpen={openedDialog === 'availability201'}
        onClose={() => setOpenedDialog(undefined)}
        evseId={evseId}
      />
    </Grid>
  );
};
