import { Box, Grid } from '@mui/material';
import { isBefore } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { DateRange } from './date-range';
import { TimeInput } from './TimeInput';

interface DateTimeRangeInputsProps {
  dateRange: DateRange;
  onDateRangeChange: (newRange: DateRange) => void;
}

export const DateTimeRangeInputs: React.FC<DateTimeRangeInputsProps> = ({
  dateRange,
  onDateRangeChange,
}) => {
  const { t } = useTranslation('shared');

  const { startDate, endDate } = dateRange;

  const setStartDate = (date: Date | string | null) => {
    if (typeof date === 'string' || !date) {
      return;
    } else onDateRangeChange({ startDate: date, endDate });
  };

  const setEndDate = (date: Date | string | null) => {
    if (typeof date === 'string' || !date) {
      return;
    } else onDateRangeChange({ startDate, endDate: date });
  };

  const isError = !isBefore(startDate, endDate);

  return (
    <Box>
      <Grid
        container
        justifyContent="center"
        columnSpacing={1.5}
        rowSpacing={1.5}
        px={3}
        py={4}
      >
        <Grid item xs={6}>
          <TimeInput
            date={startDate}
            onDateChange={setStartDate}
            error={isError}
            placeholder={t('startTime')}
          />
        </Grid>
        <Grid item xs={6}>
          <TimeInput
            date={endDate}
            onDateChange={setEndDate}
            error={isError}
            placeholder={t('endTime')}
          />
        </Grid>
      </Grid>
    </Box>
  );
};
