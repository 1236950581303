import { OptimizedTourJob } from '@energy-stacks/fleet/feature-tours-data';
import { useDialog } from '@energy-stacks/shared';
import { useCallback } from 'react';

export const useConfirmViolatedToursDialog = (
  handleConfirm: () => void,
  tourJobs: OptimizedTourJob[]
) => {
  const { open: openConfirmationDialog, dialogProps: confirmationDialogProps } =
    useDialog();
  const handleTourConfirmation = useCallback(() => {
    const hasViolation = tourJobs.some((j) => j.contaminationViolation);
    if (hasViolation) {
      openConfirmationDialog();
    } else {
      handleConfirm();
    }
  }, [handleConfirm, openConfirmationDialog, tourJobs]);

  return {
    handleTourConfirmation,
    isConfirmViolatedToursDialogOpen: confirmationDialogProps.isOpen,
    closeConfirmViolatedToursDialog: confirmationDialogProps.onClose,
  };
};
