import { ConfigurationModel } from '@energy-stacks/broker/feature-charging-station-management-data';
import { TableEmptyMessage } from '@energy-stacks/shared';
import { Box } from '@mui/material';
import { ConfigurationTable } from './ConfigurationTable';
import { RetrieveConfigurationButton } from './RetrieveConfigurationButton';
import { useRefreshConfiguration } from './useRefreshConfiguration';

interface ConfigurationProps {
  configuration: ConfigurationModel[];
}

export const Configuration: React.FC<ConfigurationProps> = ({
  configuration,
}) => {
  const retrieveConfiguration = useRefreshConfiguration();

  return configuration?.length === 0 ? (
    <Box height="100%" textAlign="center">
      <TableEmptyMessage />
      <RetrieveConfigurationButton onRetrieve={retrieveConfiguration} />
    </Box>
  ) : (
    <ConfigurationTable configuration={configuration ?? []} />
  );
};
