import {
  ExceptionalHours,
  RegularWorkingHours,
  VehicleDetailsModel,
} from '@energy-stacks/fleet/feature-vehicles-data';
import { weekdays } from '@energy-stacks/fleet/shared';
import { parse } from 'date-fns';

export const getEditVehicleDefaultOperatingHours = (
  vehicle: VehicleDetailsModel | undefined
) => {
  return {
    workingHours: {
      regular: getDefaultRegularHours(vehicle?.workingHours.regular ?? []),
      exceptional: getDefaultExceptionalHours(
        vehicle?.workingHours.exceptional ?? []
      ),
    },
  };
};

const getDefaultRegularHours = (
  regularHours: VehicleDetailsModel['workingHours']['regular']
) => {
  const workingHours: RegularWorkingHours = weekdays.reduce((acc, day) => {
    return {
      ...acc,
      [day]: {
        startTime: null,
        endTime: null,
        isWorkingDay: false,
      },
    };
  }, {} as RegularWorkingHours);

  regularHours.forEach((day) => {
    const workingHoursStart = day.startTime
      ? utcTimeStringToLocalDate(day.startTime)
      : null;
    const workingHoursEnd = day.endTime
      ? utcTimeStringToLocalDate(day.endTime)
      : null;

    workingHours[day.weekday].startTime = workingHoursStart;
    workingHours[day.weekday].endTime = workingHoursEnd;
    workingHours[day.weekday].isWorkingDay =
      workingHoursStart !== null && workingHoursEnd !== null;
  });

  return workingHours;
};

const getDefaultExceptionalHours = (exceptionalHours: ExceptionalHours[]) => {
  return exceptionalHours
    .map((c) => ({
      isOperating: c.isOperating,
      date: new Date(c.startDate),
      startTime: new Date(c.startDate),
      endTime: new Date(c.endDate),
    }))
    .sort((a, b) => b.startTime.getTime() - a.startTime.getTime());
};

const utcTimeStringToLocalDate = (time: string) => {
  const parsedDate = parse(time, 'HH:mm', new Date());
  parsedDate.setUTCHours(parsedDate.getHours());
  parsedDate.setUTCMinutes(parsedDate.getMinutes());
  return parsedDate;
};
