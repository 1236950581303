import {
  ESButton,
  ESDialogActions,
  ESDialogContent,
  ESDialogTitle,
  ESSelect,
  useESSnackbar,
} from '@energy-stacks/core/ui';
import { Dialog, Stack, Typography } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import {
  ChargingStationDetailsModel,
  changeAvailabilityRequestOptions,
  ChangeAvailabilityRequestOption,
  useChangeChargingStationAvailability201Mutation,
} from '@energy-stacks/broker/feature-charging-stations-data';
import { yupResolver } from '@hookform/resolvers/yup';
import { useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';

interface ChangeChargingStationAvailabilityStatusDialog201Props {
  isOpen: boolean;
  onClose: () => void;
  identityKey: ChargingStationDetailsModel['identityKey'];
  currentStatus: ChargingStationDetailsModel['csStatus'];
  evseId?: number;
  connectorId?: number;
}

export const ChangeChargingStationAvailabilityStatusDialog201: React.FC<
  ChangeChargingStationAvailabilityStatusDialog201Props
> = ({ identityKey, currentStatus, evseId, connectorId, isOpen, onClose }) => {
  const { t } = useTranslation('chargingStations');
  const { showSnackbar } = useESSnackbar();
  const {
    control,
    handleSubmit,
    reset,
    formState: { isValid },
    getValues,
  } = useForm<{ availability: ChangeAvailabilityRequestOption }>({
    resolver: yupResolver(
      yup.object().shape({
        availability: yup
          .string()
          .required()
          .oneOf([...changeAvailabilityRequestOptions]),
      })
    ),
    mode: 'onChange',
    defaultValues: {
      availability: undefined,
    },
  });
  const [triggerChangeChargingStationAvailability, { isLoading }] =
    useChangeChargingStationAvailability201Mutation();
  const requestRef =
    useRef<ReturnType<typeof triggerChangeChargingStationAvailability>>();

  const onSubmit = useCallback(() => {
    const changeAvailability = triggerChangeChargingStationAvailability({
      identityKey: identityKey,
      operationalStatus: getValues().availability,
      evse: {
        id: evseId ? evseId : 0,
        connectorId,
      },
    });

    requestRef.current = changeAvailability;

    changeAvailability
      .unwrap()
      .then((result) => {
        if (result.status === 'Rejected') {
          showSnackbar(
            'error',
            t('changeChargingStationStatusRejectedMessage')
          );
          return;
        }

        if (result.status === 'Accepted') {
          showSnackbar(
            'success',
            t('changeChargingStationStatusSuccessMessage')
          );
        }

        if (result.status === 'Faulted') {
          showSnackbar(
            'info',
            t('changeChargingStationStatusScheduledMessage')
          );
        }
        if (result.status === 'Occupied') {
          showSnackbar(
            'info',
            t('changeChargingStationStatusScheduledMessage')
          );
        }
        if (result.status === 'Unavailable') {
          showSnackbar(
            'info',
            t('changeChargingStationStatusScheduledMessage')
          );
        }

        onClose();
      })
      .catch((error) => {
        if (error.data?.errorCode === 'RPC_TIMEOUT') {
          showSnackbar('error', t('rpcTimeoutError'));
          return;
        }
        if (error.data?.errorCode === 'RPC_ERROR') {
          showSnackbar('error', t('rpcError'));
          return;
        }

        if (error.name === 'AbortError') {
          return;
        }

        showSnackbar('error');
      });
  }, [
    identityKey,
    triggerChangeChargingStationAvailability,
    getValues,
    onClose,
    t,
    showSnackbar,
    connectorId,
    evseId,
  ]);

  const handleCancel = useCallback(() => {
    requestRef.current?.abort();
    reset();
    onClose();
  }, [onClose, reset]);

  return (
    <Dialog open={isOpen} onClose={handleCancel} fullWidth maxWidth="sm">
      <ESDialogTitle data-testid="changeStatusTitle">
        {t('changeStatus')}
      </ESDialogTitle>

      <ESDialogContent>
        <Stack direction="column" data-testid="changeStatusContent">
          <Typography variant="caption" color="text.secondary">
            {t('currentStatus')}
          </Typography>
          <Typography>
            {currentStatus
              ? t(currentStatus, { context: 'status' })
              : t('unknown')}
          </Typography>
        </Stack>

        <Controller
          control={control}
          name="availability"
          render={({ field: { value, onChange, onBlur } }) => (
            <ESSelect
              testId="stationAvailability"
              label={t('newStatus')}
              options={changeAvailabilityRequestOptions.map((option) => ({
                value: option,
                label: t(`changeAvailabilityRequestStatusOption.${option}`),
              }))}
              onChange={onChange}
              onBlur={onBlur}
              value={value || ''}
              required
              disabled={isLoading}
              sx={{ mt: 8 }}
            />
          )}
        />
      </ESDialogContent>

      <ESDialogActions>
        <ESButton
          data-testid="changeStatusCancelButton"
          variant="text"
          color="error"
          onClick={handleCancel}
          disabled={isLoading}
        >
          {t('cancel')}
        </ESButton>

        <ESButton
          data-testid="changeStatusChangeButton"
          variant="contained"
          onClick={handleSubmit(onSubmit)}
          disabled={!isValid || isLoading}
          loading={isLoading}
        >
          {t('change')}
        </ESButton>
      </ESDialogActions>
    </Dialog>
  );
};
