import { DialogTitle, DialogTitleProps } from '@mui/material';

export const ESDialogTitle: React.FC<DialogTitleProps> = ({ sx, ...rest }) => {
  return (
    <DialogTitle
      sx={{
        pt: 10,
        pb: 6,
        pl: 10,
        pr: 10,
        ...sx,
      }}
      {...rest}
    />
  );
};
