import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { RefetchButton } from '..';
import { ReactComponent as NotFoundImage } from '../assets/notFound_NO_TEXT.svg';

interface RefetchOnErrorProps {
  onRefetch: () => void;
}

export const RefetchOnErrorPage: React.FC<RefetchOnErrorProps> = ({
  onRefetch,
}) => {
  const [t] = useTranslation('shared');
  return (
    <Box textAlign="center" sx={{ mt: 8 }}>
      <NotFoundImage />
      <Typography variant="h6" sx={{ mt: 6 }}>
        {t('pageCantLoad')}
      </Typography>
      <Typography
        variant="subtitle1"
        sx={{ mb: 10, mt: 1 }}
        color="text.secondary"
      >
        {t('pleaseTryAgain')}
      </Typography>
      <RefetchButton onRefetch={onRefetch} />
    </Box>
  );
};
