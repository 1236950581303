import {
  ESTable,
  ESTableBody,
  ESTableHead,
  useESTableBasic,
} from '@energy-stacks/core/ui';
import { BusinessAccountRecentJob } from '@energy-stacks/fleet/feature-business-accounts-data';
import { ProductIcon } from '@energy-stacks/fleet/shared';
import { NoTableData, formatDate } from '@energy-stacks/shared';
import { Box, Stack, useTheme } from '@mui/material';
import { createColumnHelper } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';
import { TankTypeChip } from '../TankTypeChip';
import { useRemovePlantIdPrefix } from '../../useRemovePlantIdPrefix';

type RecentJobsProps = {
  recentJobs: BusinessAccountRecentJob[];
};

export const RecentJobs: React.FC<RecentJobsProps> = ({ recentJobs }) => {
  const [t] = useTranslation('businessAccounts');
  const columnHelper = createColumnHelper<BusinessAccountRecentJob>();
  const theme = useTheme();
  const cellPadding = `0px ${theme.spacing(6)} 0px 0px`;
  const removePlantIdPrefix = useRemovePlantIdPrefix();

  const columns = [
    columnHelper.accessor('date', {
      sortingFn: 'datetime',
      header: () => t('recentJobsDateColumnHeader'),
      footer: (props) => props.column.id,
      cell: (info) => formatDate(info.getValue(), 'dd.MM.yyyy'),
      meta: {
        cellPadding,
      },
    }),
    columnHelper.accessor('id', {
      sortingFn: 'alphanumeric',
      header: () => t('recentJobsIdColumnHeader'),
      footer: (props) => props.column.id,
      cell: (info) => removePlantIdPrefix(info.getValue()),
      meta: {
        cellPadding,
      },
    }),
    columnHelper.accessor('location.id', {
      sortingFn: 'alphanumeric',
      header: () => t('recentJobsTankCodeColumnHeader'),
      footer: (props) => props.column.id,
      cell: ({ row }) => (
        <Stack
          direction="row"
          alignItems="center"
          gap={2}
          justifyContent="space-between"
        >
          <ProductIcon
            type={row.original.location.type}
            risk={row.original.location.risk}
          />
          <TankTypeChip
            label={removePlantIdPrefix(row.original.location.id)}
            variant={row.original.location.productType}
          />
        </Stack>
      ),
      meta: {
        cellPadding,
      },
    }),
    columnHelper.accessor('productName', {
      sortingFn: 'alphanumeric',
      header: () => t('recentJobsProductNameColumnHeader'),
      footer: (props) => props.column.id,
      cell: (info) => info.getValue(),
      meta: {
        cellPadding,
      },
    }),
  ];

  const { instance, rows } = useESTableBasic(recentJobs, columns, {
    initialSorting: [{ id: 'date', desc: true }],
    manualPagination: true,
  });

  return (
    <Box
      sx={{
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        position: 'relative',
      }}
    >
      <ESTable minWidth={0}>
        <ESTableHead instance={instance} fontWeight={500} height={52} />
        <ESTableBody rows={rows} />
      </ESTable>
      {rows.length === 0 ? (
        <NoTableData message={t('recentJobsEmptyTableMessage')} />
      ) : null}
    </Box>
  );
};
