import { IconButton, Stack } from '@mui/material';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { BrokerRoutes } from '@energy-stacks/broker/shared';
import { IconPencil } from '@tabler/icons-react';
import { ESMenu, ESMenuItem } from '@energy-stacks/core/ui';
import { ChargingStationDetailsModel } from '@energy-stacks/broker/feature-charging-stations-data';
import { ChargingStationsActionsContext } from '../../ChargingStationsActionsContext';
import { ClearAuthorizationCacheDialog201 } from '../clear-authorization-cache/ClearAuthorizationCacheDialog201';
import { ChangeChargingStationAvailabilityStatusDialog201 } from '../change-availability/ChangeChargingStationavailabilityStatusDialog201';
import { TriggerChargingStationMessageDialog201 } from '../trigger-message/TriggerChargingStationMessageDialog201';
import { ResetDialogV201 } from './ResetDialogV201';

type ChargingStationV201Dialog =
  | 'authorization201'
  | 'triggerMessage201'
  | 'availability201'
  | 'reset201';

interface ChargingStationDetailsActionsV201Props {
  chargingStationDetails?: ChargingStationDetailsModel;
}

export const ChargingStationDetailsActionsV201 = ({
  chargingStationDetails,
}: ChargingStationDetailsActionsV201Props) => {
  const [openedDialog, setOpenedDialog] = useState<ChargingStationV201Dialog>();
  const chargingSationsActions = useContext(ChargingStationsActionsContext);
  const { t } = useTranslation('chargingStations');
  const navigate = useNavigate();

  return (
    chargingStationDetails && (
      <Stack gap={4} direction="row" alignItems="center" ml={4}>
        {chargingSationsActions.includes('edit') ? (
          <IconButton
            data-testid="editChargingStationPencilButton"
            onClick={() =>
              navigate(
                `${BrokerRoutes.ChargingStationDetailsGeneralTabV201}/${BrokerRoutes.EditChargingStation}`
              )
            }
          >
            <IconPencil />
          </IconButton>
        ) : null}
        <ESMenu testId={'chargingStationDetails'} disableMenu={!!openedDialog}>
          <ESMenuItem
            testId={'clearAuthorizationCacheMenuItem'}
            onClick={() => setOpenedDialog('authorization201')}
          >
            {t('clearAuthorizationCache')}
          </ESMenuItem>

          <ESMenuItem
            testId={'stationResetMenuItem'}
            onClick={() => setOpenedDialog('reset201')}
          >
            {t('resetStation')}
          </ESMenuItem>
          <ESMenuItem
            testId={'triggerMessageMenuItem'}
            onClick={() => setOpenedDialog('triggerMessage201')}
          >
            {t('remoteTriggerMessage')}
          </ESMenuItem>
          <ESMenuItem
            testId={'stationAvailabilityMenuItem'}
            onClick={() => setOpenedDialog('availability201')}
          >
            {t('stationAvailability')}
          </ESMenuItem>
        </ESMenu>

        <ClearAuthorizationCacheDialog201
          identityKey={chargingStationDetails.identityKey}
          isOpen={openedDialog === 'authorization201'}
          onClose={() => setOpenedDialog(undefined)}
        />

        <ChangeChargingStationAvailabilityStatusDialog201
          identityKey={chargingStationDetails.identityKey}
          currentStatus={chargingStationDetails.csStatus}
          isOpen={openedDialog === 'availability201'}
          onClose={() => setOpenedDialog(undefined)}
        />
        <TriggerChargingStationMessageDialog201
          identityKey={chargingStationDetails.identityKey}
          isOpen={openedDialog === 'triggerMessage201'}
          onClose={() => setOpenedDialog(undefined)}
        />
        <ResetDialogV201
          identityKey={chargingStationDetails.identityKey}
          isOpen={openedDialog === 'reset201'}
          onClose={() => setOpenedDialog(undefined)}
        />
      </Stack>
    )
  );
};
