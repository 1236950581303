import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const GB_T_FemaleIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props}>
      <path d="M19.3956 5.91349C18.3063 4.24387 12.6495 4.30998 12.0043 4.30998C11.3583 4.29832 5.84618 4.08869 4.61527 5.89637C3.63792 7.33109 3.12222 9.0361 3.12222 10.8255C3.12222 15.7208 7.10394 19.7033 11.9992 19.7033C16.8945 19.7033 20.877 15.7208 20.877 10.8255C20.8774 9.07265 20.3792 7.41976 19.3956 5.91349ZM11.9996 18.9068C7.5438 18.9068 3.91911 15.2813 3.91911 10.8255C3.91911 9.19594 4.38697 7.6465 5.2737 6.34441C5.88119 5.45184 8.58687 5.14732 10.7586 5.14732C11.2194 5.14732 11.6465 5.12438 12.0381 5.14732C14.3809 5.17027 18.0904 5.37095 18.7286 6.34907C19.6259 7.7235 20.0809 9.22978 20.0809 10.8255C20.0809 15.2813 16.4554 18.9068 11.9996 18.9068Z" />
      <path d="M10.3463 11.9642C10.3463 10.7874 9.38843 9.82986 8.21118 9.82986C7.03393 9.82986 6.07603 10.7874 6.07603 11.9642C6.07603 13.1415 7.03393 14.0994 8.21118 14.0994C9.38843 14.099 10.3463 13.1415 10.3463 11.9642ZM8.21118 13.4048C7.41663 13.4048 6.77064 12.7584 6.77064 11.9642C6.77064 11.1705 7.41702 10.5245 8.21118 10.5245C9.00574 10.5245 9.65173 11.1705 9.65173 11.9642C9.65212 12.7584 9.00574 13.4048 8.21118 13.4048Z" />
      <path d="M17.924 11.9642C17.924 10.7874 16.9661 9.82986 15.7888 9.82986C14.6116 9.82986 13.6537 10.7874 13.6537 11.9642C13.6537 13.1415 14.6116 14.0994 15.7888 14.0994C16.9661 14.099 17.924 13.1415 17.924 11.9642ZM15.7888 13.4048C14.9943 13.4048 14.3483 12.7584 14.3483 11.9642C14.3483 11.1705 14.9947 10.5245 15.7888 10.5245C16.5834 10.5245 17.2294 11.1705 17.2294 11.9642C17.2294 12.7584 16.5834 13.4048 15.7888 13.4048Z" />
      <path d="M14.7497 7.88957C15.1973 7.88957 15.5613 7.52555 15.5613 7.0783C15.5613 6.63105 15.1973 6.26703 14.7497 6.26703C14.3024 6.26703 13.9384 6.63105 13.9384 7.0783C13.9384 7.52555 14.3024 7.88957 14.7497 7.88957ZM14.7497 6.86206C14.8691 6.86206 14.9663 6.95929 14.9663 7.07869C14.9663 7.19808 14.8691 7.29531 14.7497 7.29531C14.6303 7.29531 14.533 7.19808 14.533 7.07869C14.5334 6.95929 14.6303 6.86206 14.7497 6.86206Z" />
      <path d="M12.0113 7.88957C12.4589 7.88957 12.8229 7.52555 12.8229 7.0783C12.8229 6.63105 12.4589 6.26703 12.0113 6.26703C11.564 6.26703 11.2 6.63105 11.2 7.0783C11.2 7.52555 11.564 7.88957 12.0113 7.88957ZM12.0113 6.86206C12.1307 6.86206 12.2279 6.95929 12.2279 7.07869C12.2279 7.19808 12.1307 7.29531 12.0113 7.29531C11.8919 7.29531 11.7947 7.19808 11.7947 7.07869C11.795 6.95929 11.8919 6.86206 12.0113 6.86206Z" />
      <path d="M12.0113 10.5015C12.4589 10.5015 12.8229 10.1375 12.8229 9.69025C12.8229 9.24299 12.4589 8.87897 12.0113 8.87897C11.564 8.87897 11.2 9.24299 11.2 9.69025C11.2 10.1379 11.564 10.5015 12.0113 10.5015ZM12.0113 9.47401C12.1307 9.47401 12.2279 9.57124 12.2279 9.69063C12.2279 9.81003 12.1307 9.90726 12.0113 9.90726C11.8919 9.90726 11.7947 9.81003 11.7947 9.69063C11.795 9.57124 11.8919 9.47401 12.0113 9.47401Z" />
      <path d="M9.29937 7.88957C9.74701 7.88957 10.111 7.52555 10.111 7.0783C10.111 6.63105 9.74701 6.26703 9.29937 6.26703C8.85212 6.26703 8.48809 6.63105 8.48809 7.0783C8.48848 7.52555 8.85212 7.88957 9.29937 7.88957ZM9.29937 6.86206C9.41877 6.86206 9.516 6.95929 9.516 7.07869C9.516 7.19808 9.41877 7.29531 9.29937 7.29531C9.17997 7.29531 9.08274 7.19808 9.08274 7.07869C9.08313 6.95929 9.18036 6.86206 9.29937 6.86206Z" />
      <path d="M15.298 16.8883C15.7457 16.8883 16.1097 16.5243 16.1097 16.077C16.1097 15.6298 15.7457 15.2657 15.298 15.2657C14.8508 15.2657 14.4867 15.6298 14.4867 16.077C14.4871 16.5247 14.8508 16.8883 15.298 16.8883ZM15.2984 15.8608C15.4178 15.8608 15.515 15.958 15.515 16.0774C15.515 16.1968 15.4178 16.294 15.2984 16.294C15.179 16.294 15.0818 16.1968 15.0818 16.0774C15.0818 15.958 15.179 15.8608 15.2984 15.8608Z" />
      <path d="M8.72299 16.8883C9.17063 16.8883 9.53465 16.5243 9.53465 16.077C9.53465 15.6298 9.17063 15.2657 8.72299 15.2657C8.27574 15.2657 7.91172 15.6298 7.91172 16.077C7.91211 16.5247 8.27574 16.8883 8.72299 16.8883ZM8.72338 15.8608C8.84277 15.8608 8.94 15.958 8.94 16.0774C8.94 16.1968 8.84277 16.294 8.72338 16.294C8.60398 16.294 8.50675 16.1968 8.50675 16.0774C8.50675 15.958 8.60398 15.8608 8.72338 15.8608Z" />
      <path d="M12.0078 17.2982C12.6814 17.2982 13.2294 16.7506 13.2294 16.0774C13.2294 15.4042 12.6814 14.8566 12.0078 14.8566C11.3346 14.8566 10.787 15.4042 10.787 16.0774C10.787 16.7506 11.3346 17.2982 12.0078 17.2982ZM12.0078 15.7515C12.1878 15.7515 12.3341 15.8977 12.3341 16.0774C12.3341 16.2571 12.1878 16.4033 12.0078 16.4033C11.8281 16.4033 11.6819 16.2571 11.6819 16.0774C11.6819 15.8977 11.8281 15.7515 12.0078 15.7515Z" />
    </SvgIcon>
  );
};
