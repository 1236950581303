import React, { useEffect, useRef, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import {
  ClickAwayListener,
  Divider,
  List,
  ListItemButton,
  Paper,
  Popper,
  Typography,
} from '@mui/material';

import { BerryThemeProvider } from '@energy-stacks/core/berry-mui';
import { PopupTransitions } from '@energy-stacks/core/ui';
import { ESUserAvatar } from './ESUserAvatar';
import { getUserInitials } from '../getUserInitials';

type ESProfileSectionProps = {
  collapsed: boolean;
  userName: string;
  emailAddress: string;
  themeMode: 'light' | 'dark';
  children?: React.ReactNode;
  avatarUrl?: string;
  hasBorderRadius?: boolean;
};

export const ESProfileSection: React.FC<ESProfileSectionProps> = ({
  collapsed,
  userName,
  emailAddress,
  themeMode,
  children,
  avatarUrl,
  hasBorderRadius = true,
}) => {
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLDivElement>(null);
  const userInitials = getUserInitials(userName);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (
    event: React.MouseEvent<HTMLDivElement> | MouseEvent | TouchEvent
  ) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as Node)) {
      return;
    }

    setOpen(false);
  };

  const prevOpen = useRef(open);

  useEffect(() => {
    if (anchorRef.current && prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <>
      {!collapsed ? (
        <ListItemButton
          data-testid="profileSectionSidebarButton"
          onClick={handleToggle}
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 4,
            paddingRight: 4.5,
            width: '100%',
            color: 'text.primary',
            paddingY: 4.5,
            ':hover': {
              backgroundColor: theme.palette.background.paper,
              cursor: 'pointer',
            },
          }}
        >
          <ESUserAvatar
            userInitials={userInitials}
            testId={`${userInitials}Avatar`}
            ref={anchorRef}
            aria-controls={open ? 'menu-list-grow' : undefined}
            aria-haspopup="true"
            avatarUrl={avatarUrl}
          />
          <Typography maxWidth={150} textOverflow="ellipsis" overflow="hidden">
            {userName}
          </Typography>
        </ListItemButton>
      ) : (
        <ESUserAvatar
          userInitials={userInitials}
          testId={`${userInitials}Avatar`}
          ref={anchorRef}
          aria-controls={open ? 'menu-list-grow' : undefined}
          aria-haspopup="true"
          avatarUrl={avatarUrl}
          onClick={handleToggle}
        />
      )}
      <BerryThemeProvider mode={themeMode} hasBorderRadius={hasBorderRadius}>
        <Popper
          placeholder={null}
          placement="top"
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
          sx={{ zIndex: 1400 }}
          modifiers={[
            {
              name: 'offset',
              options: {
                offset: [!collapsed ? 123 : 140, 30],
              },
            },
          ]}
        >
          {({ TransitionProps }) => (
            <ClickAwayListener onClickAway={handleClose}>
              <PopupTransitions
                in={open}
                position="bottom-left"
                {...TransitionProps}
              >
                <Paper
                  sx={{
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                    p: 5,
                  }}
                >
                  <ESUserAvatar
                    userInitials={userInitials}
                    size="large"
                    avatarUrl={avatarUrl}
                  />
                  <Typography
                    sx={{ color: 'dark.main' }}
                    marginTop={5}
                    marginBottom={2}
                    variant="h4"
                    fontWeight={500}
                  >
                    {userName}
                  </Typography>
                  <Typography fontWeight={500} variant="caption">
                    {emailAddress}
                  </Typography>
                  {React.Children.count(children) > 0 ? (
                    <Divider sx={{ marginY: 3, width: '100%' }} />
                  ) : null}
                  <List
                    component="nav"
                    sx={{
                      width: '100%',
                      maxWidth: 350,
                      minWidth: 280,
                      [theme.breakpoints.down('md')]: {
                        minWidth: '100%',
                      },
                      paddingY: 0,
                    }}
                  >
                    {React.Children.map(children, (child) => {
                      if (React.isValidElement(child) && child.props.onClick) {
                        return React.cloneElement(child as React.ReactElement, {
                          ...child.props,
                          onClick: () => {
                            child.props.onClick();
                            setOpen(false);
                          },
                        });
                      }
                      return child;
                    })}
                  </List>
                </Paper>
              </PopupTransitions>
            </ClickAwayListener>
          )}
        </Popper>
      </BerryThemeProvider>
    </>
  );
};
