import {
  Table as MuiTable,
  SxProps,
  TableBody,
  TableContainer,
  TableContainerProps,
  TableHead,
  TableHeadProps,
  Theme,
} from '@mui/material';
import {
  ItemProps,
  TableBodyProps,
  TableComponents,
  TableVirtuoso,
  TableVirtuosoProps,
} from 'react-virtuoso';
import { ESTableHeadContentRenderer, TABLE_HEADER_HEIGHT } from './ESTableHead';
import { Row, Table, flexRender } from '@tanstack/react-table';
import { DEFAULT_ROW_HEIGHT, ESTableBodyRow } from './ESTableBodyRow';
import { ESTableBodyCell } from './ESTableBodyCell';
import React, { useMemo } from 'react';

const ELLIPSIS_TEXT_SX: SxProps<Theme> = {
  // NOTE: for now only supporting p tags. If this should be applied to, for example,
  // span or some other element, selector should be added here
  '& p': {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
};

export interface ESVirtualizedTableProps<T> {
  instance: Table<T>;
  rows: Row<T>[];
  rowHeight?: number;
  minWidth?: string | number;
  onRowClick?: (row: Row<T>) => void;
  scrollRestorationId?: string;
  initialScrollTop?: number;
  testId?: string;
  components?: TableComponents<T>;
  scrollerRef?: TableVirtuosoProps<T, any>['scrollerRef'];
  renderRow?: (props: ItemProps<T>) => React.ReactNode;
}

type TableVirtuosoContext = Omit<
  ESVirtualizedTableProps<any>,
  'instance' | 'initialScrollTop'
>;

const virtuosoTableComponents: TableComponents<any> = {
  Scroller: React.forwardRef<HTMLDivElement>((props, ref) => {
    const { context, ...rest } = props as TableContainerProps & {
      context: unknown;
    };
    const { scrollRestorationId } = context as TableVirtuosoContext;
    return (
      <TableContainer
        ref={ref}
        sx={{
          flex: 1,
        }}
        data-scroll-restoration-id={scrollRestorationId}
        {...rest}
      />
    );
  }),
  TableHead: React.forwardRef<HTMLTableSectionElement>((props, ref) => {
    const { children, ...rest } = props as TableHeadProps;
    return (
      <TableHead
        style={{ height: TABLE_HEADER_HEIGHT, overflowAnchor: 'none' }}
        ref={ref}
        {...rest}
      >
        {children}
      </TableHead>
    );
  }),
  TableBody: React.forwardRef<HTMLTableSectionElement>((props, ref) => {
    const { context, ...rest } = props as TableBodyProps & {
      context: unknown;
    };
    const { minWidth, testId } = context as TableVirtuosoContext;
    return (
      <TableBody
        id="tableBodyRef"
        data-testid={`${testId}TableBody`}
        sx={{ minWidth }}
        {...rest}
        ref={ref}
      />
    );
  }),
  Table: ({ children }) => {
    return (
      <MuiTable style={{ overflowAnchor: 'none', borderCollapse: 'separate' }}>
        {children}
      </MuiTable>
    );
  },
  TableRow: (props) => {
    const { context, children, ...rest } = props;
    const virtualTableContext = context as TableVirtuosoContext;
    const { rows, onRowClick, rowHeight, testId, renderRow } =
      virtualTableContext;

    if (renderRow) {
      return renderRow(props);
    }

    const index = rest['data-index'];
    const row = rows[index];

    return (
      <ESTableBodyRow
        data-testid={`${testId}TableRow${index}`}
        rowHeight={rowHeight}
        key={row.id}
        onRowClick={() => onRowClick?.(row)}
        {...rest}
      >
        {children}
      </ESTableBodyRow>
    );
  },
};

export const ESVirtualizedTable = <T,>({
  instance,
  rows,
  rowHeight = DEFAULT_ROW_HEIGHT,
  minWidth,
  onRowClick,
  scrollRestorationId,
  initialScrollTop = 0,
  testId = '',
  scrollerRef,
  renderRow,
}: ESVirtualizedTableProps<T>) => {
  const tableContext: TableVirtuosoContext = useMemo(
    () => ({
      rows,
      rowHeight,
      minWidth,
      onRowClick,
      scrollRestorationId,
      testId,
      renderRow,
    }),
    [
      rows,
      rowHeight,
      minWidth,
      onRowClick,
      scrollRestorationId,
      testId,
      renderRow,
    ]
  );

  return (
    <TableVirtuoso
      data={rows}
      context={tableContext}
      totalCount={rows.length}
      overscan={50}
      scrollerRef={scrollerRef}
      initialScrollTop={initialScrollTop}
      components={virtuosoTableComponents}
      fixedHeaderContent={() => ESTableHeadContentRenderer(instance)}
      itemContent={renderRowContent}
    />
  );
};

function renderRowContent<T>(_index: number, row: Row<T>) {
  return row.getVisibleCells().map((cell, i, cells) => {
    const isActionsColumn =
      cells.length - 1 === i && cell.id.includes('actions');

    return (
      <ESTableBodyCell
        key={cell.id}
        align={isActionsColumn ? 'right' : 'left'}
        isSticky={isActionsColumn}
        onClick={(e) => {
          if (isActionsColumn) {
            e.stopPropagation();
          }
        }}
        sx={{
          '&': {
            width: !isActionsColumn ? cell.column.getSize() : undefined,
            minWidth: !isActionsColumn ? cell.column.getSize() : undefined,
            maxWidth: !isActionsColumn ? cell.column.getSize() : undefined,
            overflow: 'hidden',
          },
          whiteSpace: 'nowrap',
          backgroundColor: cell.row.getIsSelected()
            ? 'primary.light'
            : cell.column.columnDef.meta?.backgroundColor,
          padding: cell.column.columnDef.meta?.cellPadding,
          paddingRight: isActionsColumn ? 8 : undefined,
          ...(cell.column.getCanResize() ? ELLIPSIS_TEXT_SX : {}),
        }}
      >
        {flexRender(cell.column.columnDef.cell, cell.getContext())}
      </ESTableBodyCell>
    );
  });
}
