import { environment } from '@energy-stacks/feature-config';
import { createBaseQuery } from '@energy-stacks/shared';
import { createApi } from '@reduxjs/toolkit/query/react';
import {
  ChangeConfigurationConfirmation,
  ChangeConfigurationConfirmationDto,
  ChangeConfigurationRequestFormData,
} from './changeConfigurationRequestFormData';
import { ConfigurationModel } from './configurationModel';
import { GetConfigurationConfirmation } from './getConfigurationConfirmation';
import { ConfigurationConfirmation } from './configurationConfirmation';
import { configurationKeyStatusNormalizer } from './normalizers/changeConfigurationStatusNormalizer';

export const csmsManagementApi = createApi({
  reducerPath: 'configuration',
  tagTypes: ['Configuration'],
  baseQuery: createBaseQuery(
    `${environment.ocppServiceUrl}/management/chargingstations/`
  ),
  endpoints: (builder) => ({
    getConfiguration: builder.query<ConfigurationModel[], string>({
      query: (identityKey) => `/${identityKey}/configuration`,
      providesTags: ['Configuration'],
      transformResponse: (configuration: GetConfigurationConfirmation) => {
        return (
          configuration.configurationKey?.map((config) => ({
            currentValue: config.value ?? '',
            key: config.key ?? '-',
            readOnly: config.readonly ?? true,
          })) ?? []
        );
      },
    }),
    softResetChargingStation: builder.mutation<
      ConfigurationConfirmation,
      string
    >({
      query: (identityKey) => ({
        url: `/${identityKey}/reset`,
        method: 'POST',
        body: {
          type: 'Soft',
        },
      }),
    }),
    hardResetChargingStation: builder.mutation<
      ConfigurationConfirmation,
      string
    >({
      queryFn: async (identityKey, { dispatch }, _, baseQuery) => {
        const { data, error } = await baseQuery({
          url: `/${identityKey}/reset`,
          method: 'POST',
          body: {
            type: 'Hard',
          },
        });

        /**
         * After hard reset endpoint is called and returns success, the station does not go offline immediately. We introduce artificial
         * delay to give station time to go offline. A loading indicator will be shown during this time. After the delay, we call an action
         * to mark the station as not requiring a hard reset anymore and force a refetch.
         */
        await new Promise((r) => setTimeout(r, 15000)); //
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return { data, error } as any;
      },
    }),
    updateConfigurationKey: builder.mutation<
      ChangeConfigurationConfirmation,
      ChangeConfigurationRequestFormData
    >({
      query: ({ identityKey, body }) => {
        return {
          url: `/${identityKey}/configuration`,
          method: 'POST',
          body,
        };
      },
      transformResponse: (response: ChangeConfigurationConfirmationDto) => {
        return {
          status: configurationKeyStatusNormalizer(response.status),
        };
      },
    }),
    startTransaction: builder.mutation<
      ConfigurationConfirmation,
      { connectorId?: string; idTag: string; identityKey: string }
    >({
      query: ({ connectorId, idTag, identityKey }) => {
        return {
          url: `/${identityKey}/remoteStartTransaction`,
          method: 'POST',
          body: {
            connectorId,
            idTag,
          },
        };
      },
    }),
    stopTransaction: builder.mutation<
      ConfigurationConfirmation,
      { transactionId: number; identityKey: string }
    >({
      query: ({ transactionId, identityKey }) => {
        return {
          url: `/${identityKey}/remoteStopTransaction`,
          method: 'POST',
          body: {
            transactionId,
          },
        };
      },
    }),
  }),
});

export const {
  useGetConfigurationQuery,
  useSoftResetChargingStationMutation,
  useHardResetChargingStationMutation,
  useUpdateConfigurationKeyMutation,
  useStartTransactionMutation,
  useStopTransactionMutation,
} = csmsManagementApi;
