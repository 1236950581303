import { DateRange } from '@energy-stacks/core/date-range-picker';
import { useCallback, useMemo, useReducer } from 'react';
import { endOfWeek, startOfWeek, addDays, getDay } from 'date-fns';
import { TourModel } from '@energy-stacks/fleet/feature-tours-data';
import { ChargingStationModel } from '@energy-stacks/broker/feature-charging-stations-data';

type CreateChargingScheduleAction =
  | { type: 'FILTER_DATE'; payload: { dateRange: DateRange } }
  | {
      type: 'SET_TOUR_IDS';
      payload: {
        selectedTourIds: CreateChargingScheduleState['selectedTourIds'];
      };
    }
  | {
      type: 'SET_CHARGING_STATION_IDENTITY_KEYS';
      payload: {
        selectedChargingStationIdentityKeys: CreateChargingScheduleState['selectedChargingStationIdentityKeys'];
      };
    }
  | {
      type: 'SET_IS_MOSOC_INDIVIDUAL';
      payload: { isMoSocIndividual: boolean };
    }
  | {
      type: 'SET_FLEET_MOSOC';
      payload: { fleetMoSoc: string };
    }
  | {
      type: 'SET_IS_PRICE_OPTIMIZED';
      payload: { isPriceOptimized: boolean };
    }
  | {
      type: 'SET_IS_SOLAR_OPTIMIZED';
      payload: { isSolarOptimized: boolean };
    };

export interface CreateChargingScheduleState {
  dateRange: DateRange;
  selectedTourIds: TourModel['tourId'][] | undefined;
  selectedChargingStationIdentityKeys:
    | ChargingStationModel['identityKey'][]
    | undefined;
  isMoSocIndividual: boolean;
  fleetMoSoc: string;
  isPriceOptimized: boolean;
  isSolarOptimized: boolean;
}

export const DEFAULT_FLEET_MOSOC = '80';

const createChargingScheduleReducer = (
  state: CreateChargingScheduleState,
  action: CreateChargingScheduleAction
): CreateChargingScheduleState => {
  const { type, payload } = action;
  switch (type) {
    case 'FILTER_DATE':
      return {
        ...state,
        dateRange: { ...state.dateRange, ...payload?.dateRange },
      };
    case 'SET_TOUR_IDS':
      return {
        ...state,
        selectedTourIds: payload?.selectedTourIds,
      };
    case 'SET_CHARGING_STATION_IDENTITY_KEYS':
      return {
        ...state,
        selectedChargingStationIdentityKeys:
          payload?.selectedChargingStationIdentityKeys,
      };
    case 'SET_IS_MOSOC_INDIVIDUAL':
      return {
        ...state,
        isMoSocIndividual: payload.isMoSocIndividual,
      };
    case 'SET_FLEET_MOSOC':
      return {
        ...state,
        fleetMoSoc: payload.fleetMoSoc,
      };
    case 'SET_IS_PRICE_OPTIMIZED':
      return {
        ...state,
        isPriceOptimized: payload.isPriceOptimized,
      };
    case 'SET_IS_SOLAR_OPTIMIZED':
      return {
        ...state,
        isSolarOptimized: payload.isSolarOptimized,
      };
    default:
      return state;
  }
};

export const useCreateChargingScheduleState = () => {
  const defaultDateRange: DateRange = useMemo(
    () => ({
      minDate: new Date(),
      disabledDay: (date) => getDay(date) === 0,
      maxStartDateOffset: 6,
      startDate: addDays(startOfWeek(new Date(), { weekStartsOn: 1 }), 7),
      endDate: addDays(endOfWeek(new Date(), { weekStartsOn: 1 }), 6),
      showShortcuts: false,
    }),
    []
  );

  const [state, dispatch] = useReducer(createChargingScheduleReducer, {
    dateRange: defaultDateRange,
    selectedTourIds: undefined,
    selectedChargingStationIdentityKeys: undefined,
    isMoSocIndividual: true,
    fleetMoSoc: DEFAULT_FLEET_MOSOC,
    isPriceOptimized: true,
    isSolarOptimized: false,
  });

  const setDateRange = useCallback((dateRange: DateRange) => {
    dispatch({ type: 'FILTER_DATE', payload: { dateRange } });
  }, []);

  const setSelectedTourIds = useCallback(
    (tourIds: CreateChargingScheduleState['selectedTourIds']) => {
      dispatch({ type: 'SET_TOUR_IDS', payload: { selectedTourIds: tourIds } });
    },
    []
  );

  const setSelectedChargingStationIdentityKeys = useCallback(
    (
      chargingStationIdentityKeys: CreateChargingScheduleState['selectedChargingStationIdentityKeys']
    ) => {
      dispatch({
        type: 'SET_CHARGING_STATION_IDENTITY_KEYS',
        payload: {
          selectedChargingStationIdentityKeys: chargingStationIdentityKeys,
        },
      });
    },
    []
  );

  const setIsMoSocIndividual = useCallback((isMoSocIndividual: boolean) => {
    dispatch({
      type: 'SET_IS_MOSOC_INDIVIDUAL',
      payload: { isMoSocIndividual },
    });
  }, []);

  const setFleetMoSoc = useCallback((fleetMoSoc: string) => {
    dispatch({ type: 'SET_FLEET_MOSOC', payload: { fleetMoSoc } });
  }, []);

  const setIsPriceOptimized = useCallback((isPriceOptimized: boolean) => {
    dispatch({ type: 'SET_IS_PRICE_OPTIMIZED', payload: { isPriceOptimized } });
  }, []);

  const setIsSolarOptimized = useCallback((isSolarOptimized: boolean) => {
    dispatch({ type: 'SET_IS_SOLAR_OPTIMIZED', payload: { isSolarOptimized } });
  }, []);

  return {
    state,
    setSelectedTourIds,
    setDateRange,
    setIsMoSocIndividual,
    setFleetMoSoc,
    setIsPriceOptimized,
    setIsSolarOptimized,
    defaultDateRange,
    setSelectedChargingStationIdentityKeys,
  };
};
