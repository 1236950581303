import { FleetPage, FleetRoutes } from '@energy-stacks/fleet/shared';
import {
  RefetchOnError,
  useAppLocation,
  useDocumentTitle,
} from '@energy-stacks/shared';
import { IconButton, Skeleton, Stack } from '@mui/material';
import { IconArrowLeft } from '@tabler/icons-react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { BusinessAccountDetails } from './BusinessAccountDetails';
import { useBusinessAccountDetails } from './useBusinessAccountDetails';
import { BusinessAccountDetailsPageSkeleton } from './BusinessAccountDetailsPageSkeleton';

export const BusinessAccountDetailsPage = () => {
  const location = useAppLocation();
  const navigate = useNavigate();
  const [t] = useTranslation('businessAccounts');
  useDocumentTitle(t('pageTitle'));
  const { businessAccount, isError, isLoading, refetch } =
    useBusinessAccountDetails();

  return (
    <FleetPage
      title={
        <Stack gap={2} direction="row" alignItems="center">
          <IconButton
            onClick={() => {
              if (location.state?.from) {
                navigate(-1);
              } else {
                navigate(FleetRoutes.BusinessAccounts);
              }
            }}
            sx={{ color: 'grey.500' }}
          >
            <IconArrowLeft />
          </IconButton>
          {isLoading ? <Skeleton width={200} variant="text" /> : null}
          {businessAccount
            ? `${businessAccount.contact.firstName} ${businessAccount.contact.lastName}`
            : null}
        </Stack>
      }
    >
      {isError ? <RefetchOnError onRefetch={refetch} /> : null}
      {isLoading ? <BusinessAccountDetailsPageSkeleton /> : null}
      {businessAccount ? (
        <BusinessAccountDetails businessAccount={businessAccount} />
      ) : null}
    </FleetPage>
  );
};
