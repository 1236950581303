import { createBaseQuery } from '@energy-stacks/shared';
import { createApi } from '@reduxjs/toolkit/query/react';
import { environment } from '@energy-stacks/feature-config';
import { ApiKeysModel } from './ApiKeysModel';
import { ApiKeysDto } from './ApiKeysDto';

export const apiKeysApi = createApi({
  reducerPath: 'brokerApiKeysApi',
  tagTypes: ['ApiKeys'],
  baseQuery: createBaseQuery(`${environment.ocppServiceUrl}/api-keys`),
  endpoints: (builder) => ({
    getApiKeys: builder.query<ApiKeysModel[], void>({
      query: () => '/',
      providesTags: ['ApiKeys'],
    }),
    deleteApiKey: builder.mutation<void, string>({
      query: (apiId) => {
        return {
          url: `/${apiId}`,
          method: 'DELETE',
        };
      },
      invalidatesTags: ['ApiKeys'],
    }),
    addApiKey: builder.mutation<ApiKeysDto, { apiKeyName: string }>({
      query: (body) => ({
        url: '/',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['ApiKeys'],
    }),
  }),
});

export const {
  useGetApiKeysQuery,
  useDeleteApiKeyMutation,
  useAddApiKeyMutation,
} = apiKeysApi;
