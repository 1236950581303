import { ESTableFooter, PortalTarget } from '@energy-stacks/core/ui';
import { Stack, Typography } from '@mui/material';
import { Table } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';

interface TableRowsCountProps<T> {
  instance: Table<T>;
  totalCountLabel?: string;
}

const getIsRowSelectionEnabled = <T,>(instance: Table<T>) => {
  return !!instance.options.enableRowSelection;
};

const getIsTablePaginated = <T,>(instance: Table<T>) => {
  return !instance.options.manualPagination;
};

export const TableRowsCount = <T,>({
  instance,
  totalCountLabel,
}: TableRowsCountProps<T>) => {
  const { t } = useTranslation('shared');

  const isRowSelectionEnabled = getIsRowSelectionEnabled(instance);
  const isTablePaginated = getIsTablePaginated(instance);

  if (isTablePaginated && isRowSelectionEnabled) {
    return (
      <PortalTarget targetId="paginatorRef">
        <Typography
          variant="h6"
          color="textSecondary"
          sx={{ position: 'absolute', top: 16, left: 16 }}
        >
          {t('rowsSelected', {
            count: instance.getSelectedRowModel().flatRows.length,
          })}
        </Typography>
      </PortalTarget>
    );
  }

  return (
    <ESTableFooter>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        {isRowSelectionEnabled ? (
          <Typography variant="h6" color="textSecondary">
            {t('rowsSelected', {
              count: instance.getSelectedRowModel().flatRows.length,
            })}
          </Typography>
        ) : null}
        <Stack
          direction="row"
          alignItems="center"
          gap={2}
          marginLeft={!isRowSelectionEnabled ? 'auto' : 0}
        >
          <Typography variant="h6" fontWeight="400" color="grey.600">
            {totalCountLabel ? totalCountLabel : t('totalRows')}
          </Typography>
          <Typography variant="h6" fontWeight="400" color="grey.900">
            {instance.getFilteredRowModel().rows.length}
          </Typography>
        </Stack>
      </Stack>
    </ESTableFooter>
  );
};
