import {
  ChangeConfigurationStatus,
  ChangeConfigurationStatusDto,
} from '../changeConfigurationRequestFormData';

const statusDtoToStatus: Record<
  ChangeConfigurationStatusDto,
  ChangeConfigurationStatus
> = {
  Accepted: ChangeConfigurationStatus.Accepted,
  Rejected: ChangeConfigurationStatus.Rejected,
  NotSupported: ChangeConfigurationStatus.NotSupported,
  RebootRequired: ChangeConfigurationStatus.RebootRequired,
};

export const configurationKeyStatusNormalizer = (
  statusDto: ChangeConfigurationStatusDto
): ChangeConfigurationStatus => {
  return statusDtoToStatus[statusDto];
};
