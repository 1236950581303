import { memo, useCallback, useEffect } from 'react';
import {
  ESTable,
  ESTableBodyCell,
  ESTableBodyCompose,
  ESTableBodyRow,
  ESTableHead,
  ESTableWrapper,
  useESSnackbar,
  useESTableBasic,
} from '@energy-stacks/core/ui';
import { Box, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { isEqual } from 'lodash-es';
import { createColumnHelper, flexRender, Row } from '@tanstack/react-table';
import {
  TableSearchField,
  NoTableData,
  ESTooltip,
  formatDateTime,
  TimeDistance,
} from '@energy-stacks/shared';
import {
  ComponentAttribute,
  ComponentVariable,
  ConfigurationTableModel201,
  VariableAttributeType,
  useSetConfigurationVariables201Mutation,
} from '@energy-stacks/broker/feature-charging-station-management-data';
import { SaveConfiguration201 } from './SaveConfiguration201';
import { ConfigurationValueField } from './ConfigurationValueField';
import { useForm } from 'react-hook-form';
import {
  setConfigurationCounter,
  useAppDispatch,
} from '@energy-stacks/broker/store';
import { ConfigurationMenu201 } from './ConfigurationMenu201';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

interface SetVariableDataType {
  attributeType: VariableAttributeType;
  attributeValue: string;
  component: ComponentAttribute;
  variable: ComponentVariable;
  attributeValueNew: string;
}

export interface Configuration201FormData {
  setVariableData: SetVariableDataType[];
}

interface ConfigurationTableProps {
  configuration: ConfigurationTableModel201[];
  identityKey: string;
}
const editConfigurationSchema = yup.object().shape({
  setVariableData: yup.array().of(
    yup.object().shape({
      attributeValueNew: yup.string(),
    })
  ),
});

const ConfigurationTableNonMemoized: React.FC<ConfigurationTableProps> = ({
  configuration,
  identityKey,
}) => {
  const [t] = useTranslation('chargingStations');
  const { showSnackbar } = useESSnackbar();
  const dispatch = useAppDispatch();

  const { control, handleSubmit, watch, getValues, reset } =
    useForm<Configuration201FormData>({
      mode: 'onTouched',
      defaultValues: {
        setVariableData: configuration.map((config) => {
          return {
            attributeType: config.variableAttribute.type,
            attributeValue: config.variableAttribute.value,
            attributeValueNew: config.variableAttribute.value,
            component: {
              name: config.component,
            },
            variable: {
              name: config.variable,
            },
          };
        }),
      },

      resolver: yupResolver(editConfigurationSchema),
    });

  const resetForm = useCallback(() => {
    reset({
      setVariableData: configuration.map((config) => {
        return {
          attributeType: config.variableAttribute.type,
          attributeValue: config.variableAttribute.value,
          attributeValueNew: config.variableAttribute.value,
          component: {
            name: config.component,
          },
          variable: {
            name: config.variable,
          },
        };
      }),
    });
  }, [configuration, reset]);

  // rebuild default values each time main "configuration" prop is changed
  useEffect(() => {
    resetForm();
  }, [resetForm]);

  const [triggerSetVariables, { isLoading: isSettingVariables }] =
    useSetConfigurationVariables201Mutation();

  const onSubmit = (data: Configuration201FormData) => {
    const variableData = data.setVariableData
      .map((item) => {
        return {
          attributeType: item.attributeType,
          attributeValue: item.attributeValue,
          attributeValueNew: item.attributeValueNew,
          component: {
            name: item.component.name,
          },
          variable: {
            name: item.variable.name,
          },
        };
      })
      .filter((item) => {
        if (item.attributeValue === null && item.attributeValueNew === '') {
          return false;
        }
        if (
          typeof item.attributeValueNew === 'string' ||
          item.attributeValueNew === ''
        ) {
          if (item.attributeValue !== item.attributeValueNew) {
            return true;
          }
        }
        return false;
      });
    triggerSetVariables({
      body: {
        setVariableData: variableData.map((item) => {
          return {
            attributeType: item.attributeType,
            attributeValue: item.attributeValueNew,
            component: {
              name: item.component.name,
            },
            variable: {
              name: item.variable.name,
            },
          };
        }),
      },
      identityKey: identityKey,
    })
      .unwrap()
      .then(() => {
        showSnackbar(
          'success',
          'configurationChangedSuccess',
          'chargingStations'
        );
      })
      .catch((error) => {
        if (error.data.errorCode === 'RPC_TIMEOUT') {
          showSnackbar('error', 'rpcTimeoutError', 'chargingStations');
          return;
        }
        if (error.data.errorCode === 'RPC_ERROR') {
          showSnackbar('error', 'rpcError', 'chargingStations');
          return;
        }
        showSnackbar('error');
      });
  };

  const columnHelper = createColumnHelper<ConfigurationTableModel201>();

  const columns = [
    columnHelper.accessor('component', {
      header: () => t('configurationKey'),
      footer: (props) => props.column.id,
      cell: (info) => info.getValue() || '-',
    }),
    columnHelper.accessor('variable', {
      sortingFn: 'alphanumeric',
      header: () => t('configurationKeyName'),
      footer: (props) => props.column.id,
      cell: (info) => info.getValue() || '-',
    }),
    columnHelper.accessor('variableAttribute.type', {
      sortingFn: 'alphanumeric',
      header: () => t('type'),
      footer: (props) => props.column.id,
      cell: (info) => info.getValue() || '-',
    }),
    columnHelper.display({
      id: 'newValue',
      header: () => t('configurationValue'),
      footer: (props) => props.column.id,
      cell: (info) => {
        const { index } = info.cell.row;
        return (
          <ConfigurationValueField
            control={control}
            row={info.row}
            index={index}
          />
        );
      },
    }),
    columnHelper.accessor('variableCharacteristics.unit', {
      sortingFn: 'alphanumeric',
      header: () => t('configurationUnit'),
      footer: (props) => props.column.id,
      cell: (info) => info.getValue() || '-',
    }),
  ];

  const { instance, globalFilter, onGlobalFilterChange, rows } =
    useESTableBasic(configuration, columns, { manualPagination: true });

  const lastUpdated =
    configuration.length > 0
      ? configuration.reduce((a, b) => {
          return new Date(a.dateLastUpdate).getTime() >
            new Date(b.dateLastUpdate).getTime()
            ? a
            : b;
        })
      : null;

  useEffect(() => {
    /**
     * To disable "Send" button until at least one configuration key is changed, we listen to form changes. "Send" button
     * reads this newly submitted "payload" and sets itself to "enabled" if at least one configuration key is changed. This is
     * done to prevent unnecessary renders of the table, which would happen if we called useWatch('configuration') directly.
     */
    const subscription = watch((value, { name }) => {
      const newConfiguration = value?.setVariableData
        ?.map((config) => ({
          attributeType: config?.attributeType || '',
          attributeValue: config?.attributeValue || '',
          attributeValueNew: config?.attributeValueNew || '',
          componentName: config?.component?.name || '',
          vvaribaleName: config?.variable?.name,
        }))
        .filter((item) => {
          if (item.attributeValue !== item.attributeValueNew) {
            return true;
          }

          return false;
        });
      dispatch(setConfigurationCounter(newConfiguration?.length || 0));
    });

    return () => {
      dispatch(setConfigurationCounter(0));
      subscription.unsubscribe();
    };
  }, [watch, instance, getValues, dispatch]);

  return (
    <>
      <Box display="flex" pb={5} sx={{ backgroundColor: 'transparent' }}>
        <Box flex={1}>
          <TableSearchField
            value={globalFilter}
            onChange={onGlobalFilterChange}
            tableInstance={instance}
          />
        </Box>
        <Stack direction="row" gap={4} alignItems="center">
          <ESTooltip
            title={
              lastUpdated?.dateLastUpdate
                ? formatDateTime(lastUpdated?.dateLastUpdate)
                : ''
            }
          >
            <Stack direction="row" gap={1}>
              <Typography fontSize={12}>{t('lastUpdated')}</Typography>
              <TimeDistance date={lastUpdated?.dateLastUpdate || ''} />
            </Stack>
          </ESTooltip>
          <SaveConfiguration201
            onSave={handleSubmit(onSubmit)}
            disabled={isSettingVariables}
          />
          <ConfigurationMenu201 />
        </Stack>
      </Box>
      <ESTableWrapper>
        <ESTable>
          <ESTableHead instance={instance} />
          <ESTableBodyCompose>
            {rows.map((row) => (
              <ConfigurationTableRow
                row={row}
                key={
                  row.original.component +
                  row.original.variable +
                  row.original.variableAttribute.type
                }
              />
            ))}
          </ESTableBodyCompose>
        </ESTable>

        {configuration.length === 0 ? <NoTableData /> : null}
      </ESTableWrapper>
    </>
  );
};

export const ConfigurationTable201 = memo(
  ConfigurationTableNonMemoized,
  (prevProps, nextProps) => {
    return isEqual(prevProps.configuration, nextProps.configuration);
  }
);

const ConfigurationTableRow: React.FC<{
  row: Row<ConfigurationTableModel201>;
}> = ({ row }) => {
  return (
    <ESTableBodyRow key={row.id}>
      {row.getVisibleCells().map((cell) => {
        return (
          <ESTableBodyCell key={cell.id}>
            {flexRender(cell.column.columnDef.cell, cell.getContext())}
          </ESTableBodyCell>
        );
      })}
    </ESTableBodyRow>
  );
};
