import { toPayloadDate } from '@energy-stacks/shared';
import { EditTourPayload } from './editTourPayload';
import { OptimizedTourModel } from './optimizedTourModel';
import { transitionsToPayload } from './transitionsToPayload';

export const editTourDataToPayload = (
  tour: OptimizedTourModel
): EditTourPayload => {
  return {
    startDate: toPayloadDate(new Date(tour.startDate)),
    endDate: toPayloadDate(new Date(tour.endDate)),
    jobs: tour.tourJobs.jobs.map((job) => ({
      jobUid: job.jobId,
      startDate: toPayloadDate(new Date(job.startTime)),
      endDate: toPayloadDate(new Date(job.endTime)),
    })),
    vehicleBreak: tour.tourJobs.processes
      .filter((process) => process.type === 'BREAK')
      .map((process) => ({
        jobUid: process.id,
        startDateTime: toPayloadDate(new Date(process.startTime)),
        endDateTime: toPayloadDate(new Date(process.endTime)),
      }))[0],
    totalTime: tour.duration,
    totalDistance: tour.distance,
    polyline: tour.polyline,
    transitions: transitionsToPayload(tour.transitions),
  };
};
