import {
  TransactionModel,
  TransactionStatusEnum,
} from '@energy-stacks/broker/feature-transactions-data';
import { DateRange } from '@energy-stacks/core/date-range-picker';
import {
  DEFAULT_PAGINATION_CONFIG,
  ESTable,
  ESTableBody,
  ESTableHead,
  ESTablePagination,
  ESTableWrapper,
  ESTextButton,
  useESTableBasic,
} from '@energy-stacks/core/ui';
import {
  NoTableData,
  TableSearchField,
  formatDateTime,
  TableDateTimeFilter,
  TableColumnSelect,
} from '@energy-stacks/shared';
import CreditCardRoundedIcon from '@mui/icons-material/CreditCardRounded';
import { Box, Stack } from '@mui/material';
import { createColumnHelper } from '@tanstack/react-table';
import {
  TransactionStatus,
  TransactionFinishReason,
  TransactionDurationCell,
} from '@energy-stacks/broker/shared';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useTransactions } from './useTransactions';

export const TransactionsTable: React.FC<{
  tableId?: string;
  testId?: string;
}> = ({ tableId = 'charging-stations-transactions-v2.0.1', testId }) => {
  const [dateRange, setDateRange] = useState<DateRange | undefined>(undefined);

  const { transactions } = useTransactions({
    filterDate: { timeFrom: dateRange?.startDate, timeTo: dateRange?.endDate },
  });
  const { t } = useTranslation('chargingStations');
  const isTransactionActive = transactions?.some(
    (item) => item.transactionStatus === 'charging'
  );

  const columnHelper = createColumnHelper<TransactionModel>();

  const columns = [
    columnHelper.accessor((transaction) => String(transaction.transactionId), {
      id: 'transactionId',
      header: () => t('transactionId'),
      footer: (props) => props.column.id,
      cell: (info) => info.getValue() || '-',
    }),
    columnHelper.accessor('sessionStart', {
      sortingFn: 'datetime',
      header: () => t('sessionStart'),
      footer: (props) => props.column.id,
      cell: (info) => (info.getValue() ? formatDateTime(info.getValue()) : '-'),
      enableGlobalFilter: false,
    }),
    columnHelper.accessor('sessionStop', {
      sortingFn: 'datetime',
      header: () => t('sessionStop'),
      footer: (props) => props.column.id,
      cell: (info) => (info.getValue() ? formatDateTime(info.getValue()) : '-'),
      enableGlobalFilter: false,
    }),
    columnHelper.accessor('duration', {
      header: () => t('duration'),
      footer: (props) => props.column.id,
      cell: (info) => {
        if (info.row.original.duration === '-') {
          return (
            <TransactionDurationCell
              sessionStartTime={info.row.original.sessionStart}
              isTransactionActive={isTransactionActive || false}
              transactionStatus={info.row.original.transactionStatus}
              value={info.getValue()}
            />
          );
        } else {
          return info.getValue();
        }
      },
      enableGlobalFilter: false,
    }),
    columnHelper.accessor('consumption', {
      header: () => t('consumption'),
      footer: (props) => props.column.id,
      cell: (info) =>
        info.row.original.transactionStatus !== TransactionStatusEnum.charging
          ? info.getValue()
          : '-',
      enableGlobalFilter: false,
    }),
    columnHelper.accessor('evseId', {
      header: () => t('evseId'),
      footer: (props) => props.column.id,
      cell: (info) => info.getValue(),
      enableGlobalFilter: false,
    }),
    columnHelper.accessor('tokenUid', {
      sortingFn: 'alphanumeric',
      id: 'token',
      header: () => t('tokenUid'),
      footer: (props) => props.column.id,
      cell: (info) => (
        <Stack direction={'row'} alignItems={'center'}>
          <CreditCardRoundedIcon sx={{ mr: 2.5 }} />
          {info.getValue()}
        </Stack>
      ),
      enableGlobalFilter: false,
    }),
    columnHelper.display({
      id: 'transactionStatus',
      header: () => t('transactionStatus'),
      footer: (props) => props.column.id,
      cell: (info) => (
        <Stack direction="row" alignItems="center" gap={2} paddingRight={8}>
          <TransactionStatus
            transactionStatus={info.row.original.transactionStatus}
          />
          {info.row.original.transactionStatus ===
          TransactionStatusEnum.errorFinish ? (
            <TransactionFinishReason
              transactionFinishReason={
                info.row.original.transactionFinishReason
              }
            />
          ) : null}
        </Stack>
      ),
    }),
  ];

  const {
    globalFilter,
    instance,
    onGlobalFilterChange,
    rows,
    rowsPerPageOptions,
  } = useESTableBasic(transactions, columns, { tableId });
  const hasRows = rows.length !== 0;

  const handleDateRangeFilterChange = (dateRange: DateRange) => {
    setDateRange(dateRange);
    instance.setPageIndex(DEFAULT_PAGINATION_CONFIG.page);
  };

  const handleDateRangeFilterCleared = () => {
    setDateRange(undefined);
    instance.setPageIndex(DEFAULT_PAGINATION_CONFIG.page);
  };

  const hasFilters = Boolean(dateRange);

  const clearAllFilters = () => {
    setDateRange(undefined);
    instance.setPageIndex(DEFAULT_PAGINATION_CONFIG.page);
  };

  return (
    <>
      <Box display="flex" mb={6}>
        <Stack direction="row" gap={3} alignItems="center">
          <TableSearchField
            testId={testId}
            value={globalFilter}
            onChange={onGlobalFilterChange}
            placeholder={t('transactionsSearchPlaceholder')}
            tableInstance={instance}
          />
          <TableDateTimeFilter
            title={t('timeRange')}
            isActive={dateRange !== undefined}
            defaultDateRange={dateRange}
            onDateRangeApplied={handleDateRangeFilterChange}
            onDateRangeCleared={handleDateRangeFilterCleared}
          />
          {hasFilters ? (
            <ESTextButton
              testId={`${testId}ClearAllButton`}
              onClick={clearAllFilters}
            >
              {t('clearAll')}
            </ESTextButton>
          ) : null}
        </Stack>
        <Box sx={{ marginLeft: 'auto', pl: 2 }}>
          <TableColumnSelect instance={instance} />
        </Box>
      </Box>
      <ESTableWrapper>
        <ESTable>
          <ESTableHead testId={testId} instance={instance} />
          <ESTableBody testId={testId} rows={rows} />
        </ESTable>
        {!hasRows ? (
          <NoTableData message={t('thereAreNoTransactions')} />
        ) : null}

        <ESTablePagination
          rowsPerPageOptions={rowsPerPageOptions}
          instance={instance}
        />
      </ESTableWrapper>
    </>
  );
};
