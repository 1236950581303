import { Box, Typography } from '@mui/material';
import { FC } from 'react';

type BusinessAccountDetailsCardHeaderProps = {
  title: string;
  count?: number;
};
export const BusinessAccountDetailsCardHeader: FC<
  BusinessAccountDetailsCardHeaderProps
> = ({ title, count }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      <Typography variant="subtitle1">{title}</Typography>
      {count ? (
        <Typography variant="body1" color="grey.600">
          {count}
        </Typography>
      ) : null}
    </Box>
  );
};
