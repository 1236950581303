import {
  ToggleButton,
  ToggleButtonGroup,
  ToggleButtonGroupProps,
  Tooltip,
} from '@mui/material';
import { ReactElement, useCallback } from 'react';

type ToggleOptions<T> =
  | {
      value: T;
      id: string;
      icon: ReactElement;
      tooltipText?: string;
    }
  | {
      value: string;
      icon: ReactElement;
      tooltipText?: string;
    };

type AdditionalProps<T> =
  | {
      value: T;
      onChange: (value: T) => void;
      toggleOptions: ToggleOptions<T>[];
      enforceValueSet: true;
      testId?: string;
    }
  | {
      value: T;
      onChange: (value: T | null) => void;
      toggleOptions: ToggleOptions<T>[];
      enforceValueSet?: false;
      testId?: string;
    };

type ESToggleButtonGroupProps<T> = Pick<
  ToggleButtonGroupProps,
  'size' | 'exclusive'
> &
  AdditionalProps<T>;

export const ESToggleButtonGroup = <T,>({
  size = 'small',
  exclusive = true,
  onChange,
  value,
  enforceValueSet = false,
  toggleOptions,
  testId,
}: ESToggleButtonGroupProps<T>) => {
  const handleChange = useCallback(
    (_: unknown, value: T) => {
      if (enforceValueSet && value === null) {
        return;
      }
      onChange(value);
    },
    [enforceValueSet, onChange]
  );
  return (
    <ToggleButtonGroup
      value={value}
      exclusive={exclusive}
      size={size}
      onChange={handleChange}
      data-testid={testId ? `${testId}-toggleButtonGroup` : undefined}
    >
      {toggleOptions.map((option, index) => {
        return (
          <ToggleButton
            key={'id' in option ? option.id : option.value}
            data-testid={testId ? `${testId}-toggleButton-${index}` : undefined}
            sx={(theme) => ({
              '&.Mui-selected': {
                backgroundColor: theme.palette.background.paper,
                color: theme.palette.grey['500'],
              },
              borderRadius: 1.5,
              borderWidth: 1,
              borderColor: theme.palette.grey['300'],
              color: theme.palette.grey['500'],
            })}
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            value={option.value as any}
          >
            {/*
              Wrapping the tooltip around options.icon instead of ToggleButton because
              the ToggleButtonGroup is using React.cloneElement to pass props to ToggleButton
              which would result in passing props to Tooltip component instead, threfore
              breaking the ToggleButton behavior.
              This issue is fixed in https://github.com/mui/material-ui/releases/tag/v5.15.4 release
              and should be working fine after upgrading MUI version.
              */}
            <Tooltip title={option.tooltipText ?? ''}>{option.icon}</Tooltip>
          </ToggleButton>
        );
      })}
    </ToggleButtonGroup>
  );
};
