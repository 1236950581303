import { appendZToDateString } from '@energy-stacks/shared';
import { EmptyRunsMetrics } from '../emptyRunsMetrics';
import { TransitionDto, TransitionMetricsDto } from '../transitionMetricsDto';
import { Process } from '../optimizedTourModel';

export const emptyRunsMetricsNormalizer = (
  metrics: TransitionMetricsDto | undefined
): EmptyRunsMetrics => ({
  emptyRunsTotalNumber: metrics?.emptyRunsTotalNumber ?? 0,
  emptyRunsTotalDurationSeconds: metrics?.emptyRunsTotalDurationSeconds ?? 0,
  emptyRunsTotalDistanceMeters: metrics?.emptyRunsTotalDistanceMeters ?? 0,
});

export const emptyRunsNormalizer = (emptyRuns: TransitionDto[]): Process[] => {
  return emptyRuns.map((t, i) => ({
    id: `emptyRun${i}`,
    type: 'EMPTY_RUN',
    startTime: appendZToDateString(t.startDate),
    endTime: appendZToDateString(t.endDate),
  }));
};
