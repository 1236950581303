import { usePagination, useSort } from '@energy-stacks/core/ui';
import { ESPageLoadingIndicator, RefetchOnError } from '@energy-stacks/shared';
import { useReducer, useRef } from 'react';
import {
  NetworkLogsTable,
  FilterAction,
  FiltersState,
} from './NetworkLogsTable';
import { useOcppNetworkLogs } from './useOcppNetworkLogs';

const initialFilters: FiltersState = {
  dateRange: undefined,
  communicationDirectionFilter: undefined,
  eventTypeFilter: undefined,
};

const filtersReducer = (state: FiltersState, action: FilterAction) => {
  const { type, payload } = action;
  switch (type) {
    case 'FILTER_DATE':
      return {
        ...state,
        dateRange: payload?.dateRange,
      };
    case 'FILTER_COMMUNICATION_DIRECTION':
      return {
        ...state,
        communicationDirectionFilter: payload?.communicationDirectionFilter,
      };
    case 'FILTER_EVENT_TYPE':
      return {
        ...state,
        eventTypeFilter: payload?.eventTypeFilter,
      };
    case 'RESET':
      return {
        ...initialFilters,
      };
    default:
      return state;
  }
};

export const ChargingStationDetailsNetworkLogsTab = () => {
  const { sorting, setSorting, sortOrder } = useSort('timestamp');
  const { pagination, setPagination } = usePagination();
  const [filtersState, dispatchFilter] = useReducer(
    filtersReducer,
    initialFilters
  );
  const { ocppNetworksLogs, isError, isLoading, refetch, isFetching } =
    useOcppNetworkLogs({
      filterDate: {
        timeFrom: filtersState.dateRange?.startDate,
        timeTo: filtersState.dateRange?.endDate,
      },
      eventType: filtersState.eventTypeFilter,
      communicationDirection: filtersState.communicationDirectionFilter,
      pagination: {
        page: pagination.pageIndex,
        size: pagination.pageSize,
      },
      sort: { order: sortOrder },
    });
  const logsAccessDateTime = useRef(new Date());

  if (isLoading) {
    return <ESPageLoadingIndicator />;
  }
  if (isError) {
    <RefetchOnError onRefetch={refetch} />;
  }

  return (
    <NetworkLogsTable
      onRefetch={refetch}
      networkLogs={ocppNetworksLogs}
      pagination={pagination}
      sorting={sorting}
      isFetching={isFetching}
      onSortingChange={setSorting}
      sortOrder={sortOrder}
      onPaginationChange={setPagination}
      filtersState={filtersState}
      onFiltersChange={dispatchFilter}
      logsAccessDatetime={logsAccessDateTime.current}
      testId="networkLogs"
    />
  );
};
