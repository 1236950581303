import { StompSubscription } from '@stomp/stompjs';
import log from 'loglevel';

export const chargingStationDetailsSubscriptionState = () => {
  const chargingStationIdentitySubs: Record<string, StompSubscription[]> = {};

  const unsubscribeChargingStationDetails = (key: string) => {
    if (chargingStationIdentitySubs[key]?.length > 0) {
      chargingStationIdentitySubs[key]?.forEach((sub) => {
        try {
          sub.unsubscribe();
        } catch (e) {
          log.error(e);
        }
      });
      chargingStationIdentitySubs[key] = [];
      delete chargingStationIdentitySubs[key];
    }
    log.debug(`chargingStationIdentitySubs`, chargingStationIdentitySubs);
  };

  const hasSubscribedToChargingStationDetails = (id: string) => {
    return chargingStationIdentitySubs[id]?.length > 0;
  };

  const subscribeToChargingStationDetails = (
    id: string,
    sub: StompSubscription | undefined
  ) => {
    if (sub) {
      if (chargingStationIdentitySubs[id]) {
        chargingStationIdentitySubs[id].push(sub);
      } else {
        chargingStationIdentitySubs[id] = [sub];
      }
    }
    log.debug(`chargingStationIdentitySubs`, chargingStationIdentitySubs);
  };
  return {
    unsubscribeChargingStationDetails,
    hasSubscribedToChargingStationDetails,
    subscribeToChargingStationDetails,
  };
};
