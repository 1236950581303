import { Box, SxProps, Tooltip } from '@mui/material';

interface ESToolTipProps {
  title: NonNullable<React.ReactNode>;
  children: React.ReactNode;
  placement?:
    | 'bottom-start'
    | 'bottom'
    | 'bottom-end'
    | 'top-start'
    | 'top'
    | 'top-end'
    | 'right-start'
    | 'right'
    | 'right-end'
    | 'left-start'
    | 'left'
    | 'left-end';
  enterDelay?: number;
  enterNextDelay?: number;
  leaveDelay?: number;
  arrow?: boolean;
  maxWidth?: number;
  disablePortal?: boolean;
  contentContainerStyle?: SxProps;
}

export const ESTooltip: React.FC<ESToolTipProps> = ({
  title,
  children,
  placement = 'bottom-start',
  enterDelay = 100,
  enterNextDelay = 400,
  leaveDelay = 200,
  arrow = false,
  maxWidth,
  disablePortal = false,
  contentContainerStyle,
}) => {
  return (
    <Tooltip
      title={title}
      placement={placement}
      enterDelay={enterDelay}
      enterNextDelay={enterNextDelay}
      leaveDelay={leaveDelay}
      arrow={arrow}
      PopperProps={{
        // When disablePortal is set to false, the tooltip will remain in the same place when scrolling.
        // If set to true, tooltip will be removed when scrolling.
        // Reference: https://github.com/mui/material-ui/issues/14366#issuecomment-459664775
        disablePortal,
        sx: {
          '& .MuiTooltip-tooltip': {
            maxWidth,
            fontWeight: 400,
          },
        },
      }}
      disableInteractive
      componentsProps={{
        tooltip: {
          onClick: (e) => e.stopPropagation(),
        },
      }}
    >
      <Box sx={contentContainerStyle}>{children}</Box>
    </Tooltip>
  );
};
