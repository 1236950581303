import { ConnectorStatusEnum } from '@energy-stacks/broker/feature-charging-stations-data';
import { Chip } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { connectorStatusToChipInfoMap } from './connectorStatusToChipInfoMap';

export interface ConnectorStatusInfo {
  bgColor: string;
  color: string;
}

interface ConnectorStatusChipProps {
  connectorStatus: ConnectorStatusEnum;
  testId?: string;
  colorDisabled?: boolean;
}

export const ConnectorStatus = React.memo<ConnectorStatusChipProps>(
  ({ connectorStatus, testId, colorDisabled }) => {
    const { bgColor, color } = connectorStatusToChipInfoMap[connectorStatus];

    const [t] = useTranslation('chargingStations');

    return (
      <Chip
        data-testid={`connector${testId}StatusChip`}
        size="small"
        label={t(connectorStatus, { context: 'status' })}
        sx={{
          backgroundColor: colorDisabled ? 'grey.200' : bgColor,
          color: colorDisabled ? 'grey.700' : color,
          fontWeight: 500,
        }}
      />
    );
  }
);
