import { SortingOrderModel } from '@energy-stacks/core/ui';
import { environment } from '@energy-stacks/feature-config';
import { createBaseQuery } from '@energy-stacks/shared';
import { createApi } from '@reduxjs/toolkit/query/react';
import { MessageDirectionEnum } from './messageDirectionEnum';
import { messageDirectionNormalizer } from './normalizers/messageDirectionNormalizer';
import { ocppActionNormalizer } from './normalizers/ocppActionNormalizer';
import { ProcedureName } from './ocppMessageLogEntry';
import { OcppMessageLogsModel } from './ocppMessageLogsModel';
import { PageOcppMessageLogEntry } from './pageOcppMessageLogEntry';
import { MessageTypeEnum } from '@energy-stacks/broker/feature-ocpp-message-logs-data';

export interface GetOcppMessageLogsBody {
  identityKey: string;
  timeFrom?: string;
  timeTo?: string;
  messageDirection?: MessageDirectionEnum;
  messageType?: MessageTypeEnum;
  page: number;
  size: number;
  sort: SortingOrderModel;
  ocppProcedure?: ProcedureName;
}

type SortColumn = 'timestamp';

// NOTE: Ocpp message logs can be sorted only by timestamp BE field
const SORT_COLUMN: SortColumn = 'timestamp';

export const ocppMessageLogsApi = createApi({
  reducerPath: 'csmsOcppMessageLogsApi',
  tagTypes: ['OcppMessageLogs'],
  baseQuery: createBaseQuery(`${environment.ocppServiceUrl}/ocpp-messages/`),
  endpoints: (builder) => ({
    getOcppMessageLogs: builder.query<
      OcppMessageLogsModel,
      GetOcppMessageLogsBody
    >({
      query: (searchParams) => ({
        url: `search`,
        method: 'GET',
        params: {
          ...searchParams,
          sort: [`${SORT_COLUMN},${searchParams.sort.order}`],
        },
      }),
      providesTags: ['OcppMessageLogs'],
      transformResponse: (
        ocppMessageLogs: PageOcppMessageLogEntry
      ): OcppMessageLogsModel => {
        return {
          totalElements: ocppMessageLogs.totalElements ?? 0,
          totalPages: ocppMessageLogs.totalPages ?? -1,
          messages:
            ocppMessageLogs?.content?.map((log) => ({
              messageDirection: messageDirectionNormalizer(
                log.messageDirection
              ),
              timestamp: log.timestamp ? log.timestamp : null, // "2023-01-04T16:48:07.859+00:00" DTO date should not contain time zone. If they change it add 'Z' at the end.
              ocppAction: ocppActionNormalizer(log.procedureName),
              messageUuid: log.messageUuid ?? '-',
              message: log.rawMessage ?? '-',
            })) ?? [],
        };
      },
    }),
  }),
});

export const { useGetOcppMessageLogsQuery } = ocppMessageLogsApi;
