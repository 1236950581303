import i18next from 'i18next';
import { mapValues } from 'lodash-es';
import { getI18n } from 'react-i18next';
import { FormStepperModel } from './formStep';

export const translateFormSteps = (
  formSteps: FormStepperModel<string>,
  ns: string
) => {
  const translatedSteps = mapValues(formSteps, (step) => {
    return { ...step, name: translate(ns, step.name) };
  });
  return translatedSteps;
};

// TODO: Export from translations service. i18n is in the app, so it can't be exported from there
const translate = (ns: string, key: string) => {
  const i18nInstance = getI18n();
  const lang = i18nInstance.language;
  const t = i18next.getFixedT(lang, ns);
  return t(key);
};
