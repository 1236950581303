/**
 * These hooks re-implement the now removed useBlocker and usePrompt hooks in 'react-router-dom'.
 * Thanks for the idea @piecyk https://github.com/remix-run/react-router/issues/8139#issuecomment-953816315
 * Source: https://github.com/remix-run/react-router/commit/256cad70d3fd4500b1abcfea66f3ee622fb90874#diff-b60f1a2d4276b2a605c05e19816634111de2e8a4186fe9dd7de8e344b65ed4d3L344-L381
 */
import { useCallback, useContext, useEffect } from 'react';
import { UNSAFE_NavigationContext as NavigationContext } from 'react-router-dom';
import { History, Transition } from 'history';

type ExtendedNavigator = Navigator & Pick<History, 'block'>;

type AutoUnblockingTransition = Omit<Transition, 'retry'> & {
  retry: () => void;
};

// Although we are not using createBrowserRouter from react router dom, so we can't use new versions of useBlocker and usePrompt
// imported directly from the library, we are still able to use re-implemented versions from github issue mentioned above using the HistoryRouter.
// For more context and information see: https://github.com/remix-run/react-router/blob/dev/decisions/0001-use-blocker.md#context
export function useBlocker(
  blocker: (arg: AutoUnblockingTransition) => void,
  when = true
) {
  const { navigator } = useContext(NavigationContext);

  useEffect(() => {
    if (!when) {
      return;
    }

    const unblock = (navigator as unknown as ExtendedNavigator).block?.(
      (tx) => {
        const autoUnblockingTx: AutoUnblockingTransition = {
          ...tx,
          retry() {
            // Automatically unblock the transition so it can play all the way
            // through before retrying it. TODO: Figure out how to re-enable
            // this block if the transition is cancelled for some reason.
            unblock();
            tx.retry();
          },
        };

        blocker(autoUnblockingTx);
      }
    );

    return unblock;
  }, [blocker, navigator, when]);
}

type NavigationPromptParams = {
  message: string;
  when?: boolean;
  onProceed?: (tx: AutoUnblockingTransition) => void;
};

export function useNavigationPrompt({
  message,
  onProceed,
  when = true,
}: NavigationPromptParams) {
  const blocker = useCallback(
    (tx: AutoUnblockingTransition) => {
      if (window.confirm(message)) {
        onProceed?.(tx);
        tx.retry();
      }
    },
    [message, onProceed]
  );

  useBlocker(blocker, when);
}
