import React from 'react';
import { Fab, Tooltip, FabProps, Box } from '@mui/material';
import { Instance } from '@popperjs/core';

export interface ESIconButtonProps extends FabProps {
  title: string;
  tooltipOffsetX?: number;
  tooltipOffsetY?: number;
}

export const ESIconButton: React.FC<ESIconButtonProps> = ({
  title,
  size = 'small',
  children,
  tooltipOffsetX = 0,
  tooltipOffsetY = 35,
  ...rest
}) => {
  const popperRef = React.useRef<Instance>(null);
  const areaRef = React.useRef<HTMLDivElement>(null);

  return (
    <Tooltip
      title={title}
      // Without this the tooltip can exceed the body width therefore cousing the horizontal scrollbar to appear
      // for reference see: https://github.com/mui/material-ui/issues/14366
      PopperProps={{
        disablePortal: true,
        popperRef,
        anchorEl: {
          getBoundingClientRect: () => {
            return new DOMRect(
              areaRef.current!.getBoundingClientRect().x + tooltipOffsetX,
              areaRef.current!.getBoundingClientRect().y + tooltipOffsetY,
              0,
              0
            );
          },
        },
      }}
    >
      <Box
        ref={areaRef}
        // Box wrapper is needed in case when button is disabled.
        // Tooltip needs to listen to the child element's events to display the title.
        // Disabled element does not fire events.
      >
        <Fab color="primary" size={size} sx={{ boxShadow: 'none' }} {...rest}>
          {children}
        </Fab>
      </Box>
    </Tooltip>
  );
};
