import { AddVehicleFormData } from '@energy-stacks/fleet/feature-vehicles-data';
import { Box, Switch, Typography } from '@mui/material';
import { useState } from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { DayWorkingHours } from '../DayWorkingHours';
import { useFormContext } from 'react-hook-form';
import { useMondayToFridayChange } from '../useMondayToFridayChange';

export const AddVehicleWorkingHours = () => {
  const [t] = useTranslation('vehicles');
  const [isPerDay, setIsPerDay] = useState(false);
  const { control, getValues } = useFormContext();
  const mondayValues = getValues('workingHours.regular.MONDAY');
  const { handleMondayToFridayChange } = useMondayToFridayChange();

  return (
    <Box sx={{ m: 6, mr: 'unset' }}>
      <Box
        sx={{
          alignItems: 'center',
          backgroundColor: 'grey.100',
          borderRadius: 2,
          display: 'flex',
          flexDirection: 'row',
          mb: 6,
          p: 2,
          width: '100%',
        }}
      >
        <Switch
          checked={isPerDay}
          onChange={() => {
            handleMondayToFridayChange(mondayValues.startTime, 'startTime');
            handleMondayToFridayChange(mondayValues.endTime, 'endTime');
            setIsPerDay((prev) => !prev);
          }}
          sx={{
            '.MuiSwitch-track': {
              opacity: isPerDay ? 0.5 : 0.6,
            },
          }}
        />
        <Typography variant="inherit">
          {t('workingHoursSwitchLabel')}
        </Typography>
      </Box>
      <Controller
        control={control}
        render={({ field: { value } }) => {
          const days = Object.keys(value) as Array<
            keyof AddVehicleFormData['workingHours']['regular']
          >;
          return !isPerDay ? (
            <>
              <DayWorkingHours day="mondayToFriday" />
              <DayWorkingHours day="SATURDAY" />
              <DayWorkingHours day="SUNDAY" />
            </>
          ) : (
            <>
              {days.map((day) => {
                return <DayWorkingHours key={day} day={day} />;
              })}
            </>
          );
        }}
        name="workingHours.regular"
      />
    </Box>
  );
};
