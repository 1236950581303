import { environment } from '@energy-stacks/feature-config';
import { createBaseQuery, stompClientInstance } from '@energy-stacks/shared';
import { createApi } from '@reduxjs/toolkit/query/react';
import { BrokerTransactionDto } from './brokerTransactionDto';
import { transactionNormalizer } from './normalizers/transactionNormalizer';
import { TransactionModel, TransactionSearchModel } from './transactionModel';
import { StompSubscription } from '@stomp/stompjs';
import { createEntityAdapter, EntityState } from '@reduxjs/toolkit';
import { isWithinInterval, parseISO } from 'date-fns';
import log from 'loglevel';
import { appendZToDateString } from '@energy-stacks/shared';
import { TransactionsPageEntry } from './transactionsPageEntry';
import { BackendSortingOrderModel } from '@energy-stacks/core/ui';
import { SecurityEventModel } from './securityEventsModel';
import { SecurityEventDto } from './securityEventsDto';
import { securityEventNormalizer } from './normalizers/securityEventNormalizer';

export interface GetTransactionsBody {
  identityKey: string;
  timeFrom?: string;
  timeTo?: string;
  includeMeterValues?: boolean;
  includeTransactionData?: boolean;
}

export interface GetSearchTransactionsBody {
  timeFrom?: string;
  timeTo?: string;
  term?: string;
  finished?: boolean;
  includeMeterValues?: boolean;
  includeTransactionData?: boolean;
  page: number;
  size: number;
  sort: BackendSortingOrderModel;
}

const transactionAdapter = createEntityAdapter<TransactionModel>({
  selectId: (transaction) =>
    // Unique identifier made as there can be duplicate transactions - with same id
    transaction.transactionId + transaction.identityKey,
  sortComparer: (item1, item2) =>
    // Here we compare by session start time so the new transactions
    // would always be at the top of the table
    item2.sessionStart.localeCompare(item1.sessionStart),
});

const transactionDashboardAdapter = createEntityAdapter<TransactionModel>({
  selectId: (transaction) =>
    // Unique identifier made as there can be duplicate transactions - with same id
    transaction.transactionId + transaction.identityKey,
  sortComparer: (item1, item2) =>
    // Here we compare by session start time so the new transactions
    // would always be at the top of the table
    item2.sessionStart.localeCompare(item1.sessionStart),
});

const transactionIdentityAdapter = createEntityAdapter<TransactionModel>({
  selectId: (transaction) =>
    // Unique identifier made as there can be duplicate transactions - with same id
    transaction.transactionId + transaction.identityKey,
  sortComparer: (item1, item2) =>
    // Here we compare by session start time so the new transactions
    // would always be at the top of the table
    item2.sessionStart.localeCompare(item1.sessionStart),
});

let transactionDashboardSubs: StompSubscription[] = [];
const transactionIdentitySubs: Record<string, StompSubscription[]> = {};

const unsubscribeTransactionDetails = (key: string) => {
  if (transactionIdentitySubs[key]?.length > 0) {
    transactionIdentitySubs[key]?.forEach((sub) => {
      try {
        sub.unsubscribe();
      } catch (e) {
        log.error(e);
      }
    });
    transactionIdentitySubs[key] = [];
  }
};

const unsubscribeDashboardTransactions = () => {
  if (transactionDashboardSubs.length > 0) {
    transactionDashboardSubs.forEach((sub) => {
      try {
        sub.unsubscribe();
      } catch (e) {
        log.error(e);
      }
    });
    transactionDashboardSubs = [];
  }
};

export const brokerTransactionsApi = createApi({
  reducerPath: 'brokerTransactionsApi',
  baseQuery: createBaseQuery(`${environment.ocppServiceUrl}/shadow`),
  tagTypes: ['SecurityEvent'],
  keepUnusedDataFor: 0,
  endpoints: (builder) => ({
    getTransactionsByIdentityKey: builder.query<
      EntityState<TransactionModel>,
      GetTransactionsBody
    >({
      query: (searchParams) => ({
        url: `/transactions/${searchParams.identityKey}`,
        method: 'GET',
        params: {
          from: searchParams.timeFrom,
          to: searchParams.timeTo,
          includeMeterValues: false,
          includeTransactionData: false,
        },
      }),
      transformResponse: (locations: BrokerTransactionDto[]) => {
        return transactionIdentityAdapter.addMany(
          transactionAdapter.getInitialState(),
          locations.map(transactionNormalizer)
        );
      },
      async onCacheEntryAdded(
        arg,
        { updateCachedData, cacheDataLoaded, cacheEntryRemoved }
      ) {
        try {
          // wait for the initial query to resolve before proceeding
          await cacheDataLoaded;

          if (stompClientInstance && stompClientInstance.connected) {
            unsubscribeTransactionDetails(arg.identityKey);
            const topics = [
              `/topic/transaction-created/${arg.identityKey}`,
              `/topic/transaction-updated/${arg.identityKey}`,
            ];
            topics.forEach((topic) => {
              const sub = stompClientInstance?.subscribe(topic, (message) => {
                if (message.body === undefined) return;

                const transaction = JSON.parse(
                  message.body
                ) as BrokerTransactionDto;
                if (
                  !arg.timeFrom ||
                  isWithinInterval(
                    parseISO(appendZToDateString(transaction.timestampStart)),
                    {
                      start: parseISO(appendZToDateString(arg.timeFrom)),
                      end: parseISO(appendZToDateString(arg.timeTo)),
                    }
                  )
                ) {
                  updateCachedData((draft) => {
                    transactionAdapter.upsertOne(
                      draft,
                      transactionNormalizer(transaction)
                    );
                  });
                }
                return;
              });
              if (sub) {
                if (transactionIdentitySubs[arg.identityKey]) {
                  transactionIdentitySubs[arg.identityKey].push(sub);
                } else {
                  transactionIdentitySubs[arg.identityKey] = [sub];
                }
              }
            });
          } else {
            log.error(
              'Stomp lost connection. Could not subscribe to transaction station details topics.'
            );
          }
        } catch {
          // no-op in case `cacheEntryRemoved` resolves before `cacheDataLoaded`,
          // in which case `cacheDataLoaded` will throw
        }
        // A Promise that allows you to wait for the point in time when the cache entry has been removed from the cache, by not being used/subscribed to any more in the application for too long or by dispatching api.util.resetApiState
        await cacheEntryRemoved;
        // perform cleanup steps once the `cacheEntryRemoved` promise resolves
        unsubscribeTransactionDetails(arg.identityKey);
      },
    }),
    getSearchTransactions: builder.query<
      TransactionSearchModel,
      GetSearchTransactionsBody
    >({
      query: (searchParams) => ({
        url: `/transactions/search`,
        method: 'GET',
        params: {
          ...searchParams,
          from: searchParams.timeFrom,
          to: searchParams.timeTo,
          term: searchParams.term,
          includeMeterValues: false,
          includeTransactionData: false,
          sort: [`${searchParams.sort.id},${searchParams.sort.order}`],
        },
      }),
      transformResponse: (transactions: TransactionsPageEntry) => {
        return {
          totalElements: transactions.totalElements ?? 0,
          totalPages: transactions.totalPages ?? 0,
          transactions: transactions.content?.map((item) => {
            return transactionNormalizer(item);
          }),
        };
      },
    }),
    getDashboardTransactions: builder.query<
      EntityState<TransactionModel>,
      GetSearchTransactionsBody
    >({
      query: (searchParams) => ({
        url: `/transactions/search`,
        method: 'GET',
        params: {
          ...searchParams,
          includeMeterValues: false,
          includeTransactionData: false,
          sort: [`${searchParams.sort.id},${searchParams.sort.order}`],
        },
      }),
      transformResponse: (transactions: TransactionsPageEntry) => {
        return transactionDashboardAdapter.addMany(
          transactionDashboardAdapter.getInitialState(),
          transactions.content
            ? transactions.content.map((item) => transactionNormalizer(item))
            : []
        );
      },
      async onCacheEntryAdded(
        arg,
        { updateCachedData, cacheDataLoaded, cacheEntryRemoved }
      ) {
        try {
          // wait for the initial query to resolve before proceeding
          await cacheDataLoaded;
          if (stompClientInstance && stompClientInstance.connected) {
            unsubscribeDashboardTransactions();
            const topics = [
              '/topic/transaction-created',
              '/topic/transaction-updated',
            ];
            topics.forEach((topic) => {
              const sub = stompClientInstance?.subscribe(topic, (message) => {
                if (message.body === undefined) return;

                const transaction = JSON.parse(
                  message.body
                ) as BrokerTransactionDto;

                updateCachedData((draft) => {
                  transactionDashboardAdapter.upsertOne(
                    draft,
                    transactionNormalizer(transaction)
                  );
                });

                return;
              });
              if (sub) {
                transactionDashboardSubs.push(sub);
              }
            });
          } else {
            log.error(
              'Stomp lost connection. Could not subscribe to transaction topics.'
            );
          }
        } catch {
          // no-op in case `cacheEntryRemoved` resolves before `cacheDataLoaded`,
          // in which case `cacheDataLoaded` will throw
        }
        await cacheEntryRemoved;
        // perform cleanup steps once the `cacheEntryRemoved` promise resolves
        unsubscribeDashboardTransactions();
      },
    }),
    getSecurityEvents: builder.query<SecurityEventModel[], string>({
      query: (identityKey) => `/securityevents/${identityKey}`,
      transformResponse: (securityEvents: SecurityEventDto[]) =>
        securityEvents.map((securityEvent) =>
          securityEventNormalizer(securityEvent)
        ),
      providesTags: ['SecurityEvent'],
    }),
  }),
});

export const {
  useGetTransactionsByIdentityKeyQuery,
  useGetSearchTransactionsQuery,
  useGetDashboardTransactionsQuery,
  useGetSecurityEventsQuery,
} = brokerTransactionsApi;
