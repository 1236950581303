import { format, setHours } from 'date-fns';
import { Text, Rect, Polyline } from 'react-svg-path';
import { hours } from './chargingScheduleGantHours';
import { forwardRef, useEffect, useRef, useState } from 'react';
import { IconButton, Stack, Typography, Zoom } from '@mui/material';
import { IconX } from '@tabler/icons-react';
import ShortUniqueId from 'short-unique-id';
import { useTranslation } from 'react-i18next';
import { ReactComponent as BatteryIcon } from './icons/battery.svg';
import { ReactComponent as TimeIcon } from './icons/time.svg';
import { ReactComponent as ChargeIcon } from './icons/charge.svg';
import { ReactComponent as PriceIcon } from './icons/price.svg';

const { randomUUID: uuid } = new ShortUniqueId({ length: 10 });

export type Data = {
  index: string;
  power: string;
  powerType: string;
  schedule: {
    startSlot: number;
    endSlot: number;
    startDate: Date;
    endDate: Date;
    socStart: number;
    socEnd: number;
    vehicleId: string;
    vehicleName: string;
  }[];
}[];

export const ChargingScheduleGantChart = ({
  data = [],
  loading,
  width,
  height,
  dataError,
  onDataErrorRefetch,
}: {
  data: Data | undefined;
  dataError: boolean;
  loading: boolean;
  height: number;
  onDataErrorRefetch: () => void;
  width: number;
}) => {
  const gap = 8;
  const hourWidth = Math.max(((width - 95 - gap) / hours.length, 200));
  const [widthState, setWidthState] = useState(hourWidth * hours.length);
  const [heightState, setHeightState] = useState(data.length * 102 + 50);
  const laneRef = useRef<HTMLDivElement | null>(null);
  const [mouse, setMouse] = useState<{ x: number; y: number } | null>(null);
  const [t] = useTranslation('shared');
  const [tSchedule] = useTranslation('chargingScheduleResult');
  const [vehiclePopup, setShowPopup] = useState<{
    x: number;
    y: number;
    rowIndex: number;
    columnIndex: number;
    details: {
      startDate: string;
      endDate: string;
      socStart: number;
      socEnd: number;
      chargedEnergy: number;
      totalPrice: number;
      vehicleName: string;
    };
  } | null>(null);

  useEffect(() => {
    setShowPopup(null);
  }, [data]);

  //enlarge the chart if the popup is outside the chart
  useEffect(() => {
    if (vehiclePopup === null) {
      setWidthState(hourWidth * hours.length);
    } else {
      setWidthState((width) =>
        width - (vehiclePopup.x + 95 + gap - 20) < 350 ? width + 350 : width
      );
    }
  }, [vehiclePopup, width, hourWidth]);

  //enlarge the chart if the popup is outside the chart
  // TODO: Smart popup placement
  useEffect(() => {
    if (vehiclePopup === null) {
      setHeightState(data.length * 102 + 60);
    } else {
      setHeightState((height) =>
        height - (vehiclePopup.y + 25) < 200 ? height + 200 : height
      );
    }
  }, [vehiclePopup, data]);

  return (
    <div style={{ display: 'flex' }}>
      <div>
        <svg id="stations" width={95} height={heightState}>
          <g transform="translate(0, 32)">
            {data.map(({ index: id, power, powerType }, rowIndex) => (
              // Station
              <g key={uuid()} transform={`translate(0, ${100 * rowIndex})`}>
                <foreignObject x="0" y="0" width="95" height="110">
                  <div
                    style={{
                      padding: 6.5,
                      textAlign: 'center',
                      backgroundColor: '#EEF2F6',
                      borderRadius: 4,
                      fontSize: 12,
                      borderLeft: '2px solid #2196F3',
                    }}
                  >
                    <span style={{ fontWeight: 400, color: '#697586' }}>
                      #{id}
                    </span>
                    <br></br>
                    <span style={{ fontWeight: 700, color: '#212946' }}>
                      {powerType} {power}
                    </span>
                  </div>
                </foreignObject>
              </g>
            ))}
          </g>
        </svg>
      </div>
      {/*Data error */}
      {/* {dataError && (
        <g transform={`translate(0, 0)`}>
          <foreignObject
            x={widthState / 2 - 150}
            y={80}
            width={300}
            height={200}
          >
            <Box textAlign="center">
              <Typography variant="body1" mb={3}>
                {t('getDataError')}
              </Typography>
              <RefetchButton onRefetch={onDataErrorRefetch} />
            </Box>
          </foreignObject>
        </g>
      )} */}
      {/* {!loading && !data.filter((d) => d.schedule.length).length && (
        <g transform={`translate(0, 0)`}>
          <foreignObject x={width / 2 - 150} y={80} width={300} height={200}>
            <Box textAlign="center">
              <Typography variant="body1" mb={3}>
                {tSchedule('nothingScheduledYet')}
              </Typography>
            </Box>
          </foreignObject>
        </g>
      )} */}
      <div
        style={{
          marginLeft: gap,
          overflowX: 'scroll',
        }}
      >
        <svg width={widthState} height={heightState}>
          {/* Timeline */}
          <svg id="timeline">
            {hours.map((hour, index) => (
              <Text
                key={uuid()}
                fontSize="0.625em"
                x={(hourWidth + 5.5) * index}
                y={16}
              >
                {format(setHours(new Date(), hour), 'HH:00')}
              </Text>
            ))}
          </svg>
          <g transform={`translate(0, 32)`}>
            {data.map(({ index: id, power, powerType, schedule }, rowIndex) => (
              // Lane
              <g
                key={uuid()}
                transform={`translate(0, ${100 * rowIndex})`}
                onMouseMove={(e) => {
                  if (!(e.target instanceof Element) || !laneRef.current) {
                    return;
                  }
                  const laneRect = laneRef.current.getBoundingClientRect();
                  const x = e.clientX - laneRect.left;
                  const y = e.clientY - laneRect.top;
                  if (x > 0) {
                    setMouse({ x, y });
                  }
                }}
              >
                {/* Vehicles */}
                <Background
                  ref={laneRef}
                  width={hourWidth * hours.length - 16}
                  height={50}
                />
                {schedule.map((schedule, columnIndex) => (
                  <VehicleSchedule
                    key={uuid()}
                    isActive={
                      vehiclePopup?.rowIndex === rowIndex &&
                      vehiclePopup?.columnIndex === columnIndex
                    }
                    onShowDetails={(position) => {
                      if (vehiclePopup) {
                        setShowPopup(null);
                      }
                      setTimeout(() => {
                        setShowPopup({
                          ...position,
                          details: {
                            startDate: format(schedule.startDate, 'HH:mmaaa'),
                            endDate: format(schedule.endDate, 'HH:mmaaa'),
                            socStart: schedule.socStart,
                            socEnd: schedule.socEnd,
                            chargedEnergy: 10,
                            totalPrice: 10,
                            vehicleName: schedule.vehicleName,
                          },
                        });
                      }, 0);
                    }}
                    schedule={schedule}
                    columnIndex={columnIndex}
                    rowIndex={rowIndex}
                    hourWidth={hourWidth + 5.5}
                  />
                ))}
                <g transform="translate(0,52)">
                  <Background
                    width={hourWidth * hours.length - 16}
                    height={50}
                    backgroundColor="transparent"
                  />
                </g>
              </g>
            ))}
            {/* Vehicle Popup */}
            {vehiclePopup && (
              <Zoom
                style={{
                  transformOrigin: `${vehiclePopup.x + 95 + gap - 20}px ${
                    vehiclePopup.y + 25
                  }px 0px`,
                }}
                in={!!vehiclePopup}
              >
                <foreignObject
                  x={vehiclePopup.x + 95 + gap - 20}
                  y={vehiclePopup.y + 25}
                  width={250}
                  height={200}
                  overflow="scroll"
                  style={{
                    padding: 16,
                    borderRadius: 8,
                    backgroundColor: 'white',
                    boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.12)',
                  }}
                >
                  <Stack
                    style={{ position: 'relative' }}
                    direction="column"
                    gap={2}
                  >
                    <Stack direction="row" justifyContent="space-between">
                      <Typography
                        fontWeight={500}
                        width="90%"
                        style={{ wordBreak: 'break-word' }}
                        color="#121926"
                        variant="h4"
                      >
                        {vehiclePopup.details.vehicleName}
                      </Typography>
                      <IconButton
                        style={{ position: 'absolute', right: -7, top: -7 }}
                        onClick={(e) => {
                          setShowPopup(null);
                        }}
                      >
                        <IconX width={14} height={14} stroke={1} />
                      </IconButton>
                    </Stack>
                    <Stack direction="row" justifyContent="space-between">
                      <Stack direction="row" gap={2}>
                        <TimeIcon />
                        <Typography fontSize={12}>Start time:</Typography>
                      </Stack>
                      <Stack>
                        <Typography textAlign="right" fontSize={12} mb={1}>
                          {vehiclePopup.details.startDate}
                        </Typography>
                        <Stack
                          bgcolor="primary.light"
                          color="primary.light.800"
                          direction="row"
                          alignItems={'center'}
                          gap={2}
                          px={1}
                        >
                          <Typography pt={0.65} fontSize={12}>
                            <BatteryIcon />
                          </Typography>
                          <Typography fontSize={12}>
                            SOC: {vehiclePopup.details.socStart}%
                          </Typography>
                        </Stack>
                      </Stack>
                    </Stack>
                    <Stack
                      direction="row"
                      textAlign="right"
                      justifyContent="space-between"
                    >
                      <Stack direction="row" gap={2}>
                        <TimeIcon />
                        <Typography fontSize={12} mb={1}>
                          End time:
                        </Typography>
                      </Stack>
                      <Stack>
                        <Typography textAlign="right" fontSize={12} mb={1}>
                          {vehiclePopup.details.endDate}
                        </Typography>
                        <Stack
                          bgcolor="primary.light"
                          color="primary.light.800"
                          direction="row"
                          alignItems={'center'}
                          gap={2}
                          px={1}
                        >
                          <Typography pt={0.65} fontSize={12}>
                            <BatteryIcon />
                          </Typography>
                          <Typography fontSize={12}>
                            SOC: {vehiclePopup.details.socEnd}%
                          </Typography>
                        </Stack>
                      </Stack>
                    </Stack>
                    <Stack direction="row" justifyContent="space-between">
                      <Stack direction="row" gap={2}>
                        <ChargeIcon />
                        <Typography fontSize={12}>Charged Energy:</Typography>
                      </Stack>
                      <Typography fontSize={12}>
                        {vehiclePopup.details.chargedEnergy} KWh
                      </Typography>
                    </Stack>
                    <Stack direction="row" justifyContent="space-between">
                      <Stack direction="row" gap={2}>
                        <PriceIcon />
                        <Typography fontSize={12}>
                          Estimated Price(total):
                        </Typography>
                      </Stack>
                      <Typography fontSize={12}>
                        {vehiclePopup.details.totalPrice}€
                      </Typography>
                    </Stack>
                  </Stack>
                </foreignObject>
              </Zoom>
            )}
            {mouse && !vehiclePopup && (
              <GuidelineX x={mouse.x + 1} height={data.length * 102 + 50} />
            )}
          </g>
        </svg>
      </div>
    </div>
  );
};

// TODO:
//  Extract magic constants, hardcoded colors and strings
//  Toast error
//  Extract components
//  Add tests for more stations
//  Cleanup IS some more fixing earlier sheningans

const VehicleSchedule = ({
  isActive,
  schedule: { startSlot, endSlot, socStart, socEnd, vehicleId, vehicleName },
  columnIndex,
  rowIndex,
  hourWidth,
  onShowDetails,
}: {
  isActive: boolean;
  schedule: {
    startSlot: number;
    endSlot: number;
    socStart: number;
    socEnd: number;
    vehicleId: string;
    vehicleName: string;
  };
  columnIndex: number;
  rowIndex: number;
  hourWidth: number;
  onShowDetails?: (params: {
    x: number;
    y: number;
    rowIndex: number;
    columnIndex: number;
  }) => void;
}) => {
  const elementRef = useRef<HTMLDivElement | null>(null);

  return (
    <>
      <foreignObject
        key={uuid()}
        x={(hourWidth / 4) * startSlot}
        y={0}
        width={(hourWidth / 4) * (endSlot - startSlot)}
        height="52"
      >
        <div
          ref={elementRef}
          onClick={() => {
            onShowDetails?.({
              // -95 - gap
              x: (hourWidth / 4) * endSlot - 103,
              y: rowIndex * 102,
              rowIndex,
              columnIndex,
            });
          }}
          style={{
            padding: 5,
            cursor: 'pointer',
            textAlign: 'center',
            backgroundColor: isActive ? 'white' : '#8993A2',
            border: isActive ? '1px solid #2196F3' : 'initial',
            borderRadius: 4,
            fontSize: 12,
            color: isActive ? '#000' : '#FFF',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          <span
            style={{
              fontSize: 10,
              fontWeight: 400,
            }}
          >
            {vehicleName}
          </span>
          <br></br>
          <span
            style={{
              fontSize: 16,
              fontWeight: 700,
            }}
          >
            {vehicleId}
          </span>
        </div>
      </foreignObject>
      <Text
        key={`text-${uuid()}`}
        fontSize="0.625em"
        x={(hourWidth / 4) * startSlot + 3}
        y={50 + 12}
        fill="#4B5565"
      >
        {socStart}%
      </Text>
      <Text
        key={`text-${uuid()}`}
        fontSize="0.625em"
        x={
          (hourWidth / 4) * startSlot +
          (hourWidth / 4) * (endSlot - startSlot) -
          19.5
        }
        y={50 + 12}
        fill="#4B5565"
      >
        {socEnd}%
      </Text>
    </>
  );
};

const Background = forwardRef(
  (
    {
      width,
      height,
      backgroundColor,
    }: {
      width: number;
      height: number;
      backgroundColor?: string;
    },
    ref: React.ForwardedRef<HTMLDivElement | null>
  ) => {
    return (
      <foreignObject x={0} y={0} width={width} height={height}>
        <div
          ref={ref}
          style={{
            backgroundColor: backgroundColor || '#F8FAFC',
            width: '100%',
            height: '100%',
          }}
        />
      </foreignObject>
    );
  }
);

const GuidelineX = ({ height, x }: { x: number; height: number }) => {
  const squareSize = 4;
  return (
    <g transform={`translate(${x}, 0)`}>
      <Rect
        centerEnd={false}
        cx={1 / 2}
        cy={(height - 100) / 2}
        width={1}
        height={height - 80}
        fill="#7B7C89"
      >
        <Polyline
          points={[
            [0.5, height - 90],
            [squareSize, squareSize],
            [-squareSize, squareSize],
            [-squareSize, -squareSize],
            [squareSize, -squareSize],
          ]}
          relative
          stroke="#7B7C89"
          fill="#7B7C89"
        />
      </Rect>
    </g>
  );
};
