import { appendZToDateString } from '@energy-stacks/shared';
import {
  AlertDto,
  PageAlertManagementEntry,
  AlertErrorCodeDto,
} from '../pageAlertManagementEntry';
import { Alert, AlertErrorCode, AlertManagementModel } from '../alertsModel';
import {
  ConnectorStandard,
  connectorStandardDtoToTypeMap,
} from '@energy-stacks/data';

export const alertsManagementNormalizer = (
  alertsManagament: PageAlertManagementEntry
): AlertManagementModel => {
  return {
    alerts:
      alertsManagament?.content?.map((alert) => alertsNormalizer(alert)) ?? [],
    totalElements: alertsManagament?.totalElements ?? 0,
    totalPages: alertsManagament?.totalElements ?? 0,
  };
};

const alertsNormalizer = (alert: AlertDto): Alert => {
  return {
    id: alert.id,
    alertReason: alert.alertReason,
    dateCreated: appendZToDateString(alert.dateCreated),
    dateResolved: appendZToDateString(alert.dateResolved),
    identityKey: alert.identityKey,
    connectorId: alert.connectorId,
    vendorErrorCode: alert.vendorErrorCode,
    errorCode: alert.errorCode ? alertErrorCodeMap[alert.errorCode] : null,
    errorInfo: alert.errorInfo,
    evseId: alert.evseId,
    connectorStandard:
      connectorStandardDtoToTypeMap[alert.standard] ??
      ConnectorStandard.UNKNOWN,
  };
};

const alertErrorCodeMap: Record<AlertErrorCodeDto, AlertErrorCode> = {
  ConnectorLockFailure: 'connectorLockFailure',
  EVCommunicationError: 'eVCommunicationError',
  GroundFailure: 'groundFailure',
  HighTemperature: 'highTemperature',
  InternalError: 'internalError',
  LocalListConflict: 'localListConflict',
  NoError: 'noError',
  OtherError: 'otherError',
  OverCurrentFailure: 'overCurrentFailure',
  OverVoltage: 'overVoltage',
  PowerMeterFailure: 'powerMeterFailure',
  PowerSwitchFailure: 'powerSwitchFailure',
  ReaderFailure: 'readerFailure',
  ResetFailure: 'resetFailure',
  UnderVoltage: 'underVoltage',
  WeakSignal: 'weakSignal',
};
