import { ESPopover } from '@energy-stacks/core/ui';
import { ContainerIcon } from '@energy-stacks/fleet/shared';
import { Stack, Typography, useTheme } from '@mui/material';
import { IconClockExclamation, IconClockHour5 } from '@tabler/icons-react';
import React from 'react';
import { useTranslation } from 'react-i18next';

type JobTimeIconProps = {
  showWarning?: boolean;
};

export const JobTimeIcon: React.FC<JobTimeIconProps> = ({ showWarning }) => {
  const theme = useTheme();
  const [t] = useTranslation('jobs');

  if (!showWarning) {
    return (
      <ContainerIcon>
        <IconClockHour5 size={20} />
      </ContainerIcon>
    );
  }

  return (
    <ESPopover
      content={
        <Stack padding={1.5} gap={1}>
          <Typography fontWeight={500} color="grey.900">
            {t('jobTimeWindowWarning.title')}
          </Typography>
          <Typography color="grey.500">
            {t('jobTimeWindowWarning.content')}
          </Typography>
        </Stack>
      }
      breakWord
    >
      <ContainerIcon
        backgroundColor={theme.palette.error.dark}
        borderColor={theme.palette.error.dark}
      >
        <IconClockExclamation
          size={20}
          color={theme.palette.background.paper}
        />
      </ContainerIcon>
    </ESPopover>
  );
};
