import { ESTextField, ESVirtualizedAutocomplete } from '@energy-stacks/core/ui';
import { Box, Grid } from '@mui/material';
import { useMemo } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { CentralSystemField } from '../shared/CentralSystemField';
import { useDefaultCentralSystem } from '../shared/useDefaultCentralSystem';
import { useTranslation } from 'react-i18next';
import { useGetAllLocationsBrokerQuery } from '@energy-stacks/broker/feature-locations-data';
import { EditableMap } from '@energy-stacks/shared';
import { BrokerAddChargingStationFormData } from '@energy-stacks/broker/feature-charging-stations-data';
import { getLocationFullAddress } from '../getLocationFullAddress';
import { useGetUiBrandingQuery } from '@energy-stacks/broker/feature-settings-data';

interface BrokerAddChargingStationGeneralFormProps {
  onSetIsMapReady: (value: boolean) => void;
}

type LocationOption = {
  label: string;
  value: string;
  description: string;
};

export const BrokerAddChargingStationGeneralForm: React.FC<
  BrokerAddChargingStationGeneralFormProps
> = ({ onSetIsMapReady }) => {
  const [t] = useTranslation('chargingStations');
  const [tLocations] = useTranslation('locations');
  const { data: uiBranding } = useGetUiBrandingQuery();
  const hasBorderRadius =
    uiBranding?.hasBorderRadius || uiBranding?.hasBorderRadius === undefined;

  const {
    formState: { errors },
    control,
    setValue,
    trigger,
  } = useFormContext();

  const defaultCentralSystem = useDefaultCentralSystem();

  const defaultValues: BrokerAddChargingStationFormData = {
    identityKey: '',
    chargePointName: '',
    centralSystem: {
      label: defaultCentralSystem?.displayName || '',
      value: defaultCentralSystem?.uuid || '',
    },
    location: {
      label: '',
      value: '',
      description: '',
    },
    coordinates: {
      longitude: '',
      latitude: '',
    },
    connectors: [],
  };

  const {
    data: locations,
    isLoading: isFetchingLocations,
    isError: isLocationError,
  } = useGetAllLocationsBrokerQuery();

  const watchLongitude = useWatch({
    name: 'coordinates.longitude',
    control,
  });
  const watchLatitude = useWatch({
    name: 'coordinates.latitude',
    control,
  });

  const locationOptions = useMemo(
    () =>
      locations?.map((loc) => ({
        label: loc.name,
        value: loc.uuid,
        description: getLocationFullAddress(loc, tLocations),
      })) || [],
    [locations, tLocations]
  );

  const handleCoordsChange = (
    newCoords: BrokerAddChargingStationFormData['coordinates']
  ) => {
    setValue('coordinates.latitude', newCoords.latitude.slice(0, 10), {
      shouldValidate: true,
    });
    setValue('coordinates.longitude', newCoords.longitude.slice(0, 11), {
      shouldValidate: true,
    });
  };

  return (
    <Grid container spacing={6}>
      <Grid item xs={12} sm={6}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Controller
              control={control}
              rules={{ required: true }}
              render={({ field: { onChange, onBlur, value } }) => {
                return (
                  <ESTextField
                    label={t('identityKey')}
                    type="text"
                    required
                    onChange={onChange}
                    onBlur={onBlur}
                    error={Boolean(errors['identityKey'])}
                    helperText={
                      Boolean(errors['identityKey']) &&
                      t(`${errors['identityKey']?.message}`)
                    }
                    value={value}
                    inputProps={{
                      'data-testid': 'chargingStationIdTextField',
                    }}
                  />
                );
              }}
              name="identityKey"
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              control={control}
              rules={{ required: true }}
              render={({ field: { onChange, onBlur, value } }) => {
                return (
                  <ESTextField
                    label={t('chargingStationName')}
                    type="text"
                    onChange={onChange}
                    onBlur={onBlur}
                    error={Boolean(errors['chargePointName'])}
                    helperText={
                      Boolean(errors['chargePointName']) &&
                      t(`${errors['chargePointName']?.message}`)
                    }
                    value={value}
                    inputProps={{
                      'data-testid': 'chargingStationNameTextField',
                    }}
                  />
                );
              }}
              name="chargePointName"
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              control={control}
              rules={{ required: true }}
              render={({ field: { value, onChange, onBlur } }) => (
                <CentralSystemField
                  disabled={!defaultCentralSystem}
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  error={Boolean(errors['centralSystem'])}
                  helperText={
                    Boolean(errors['centralSystem']) &&
                    t(`${errors['centralSystem']?.value?.message}`)
                  }
                  testId="centralSystemTextField"
                />
              )}
              name="centralSystem"
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              control={control}
              render={({ field: { value, onChange, onBlur } }) => (
                <ESVirtualizedAutocomplete<LocationOption>
                  data-testid="chargingSiteTextField"
                  testId="chargingSiteTextField"
                  label={t('locationFieldLabel')}
                  loading={isFetchingLocations || isLocationError}
                  value={{
                    label: value?.label || '',
                    value: value?.value || '',
                    description: value?.description || '',
                  }}
                  onBlur={onBlur}
                  options={locationOptions}
                  onChange={(_, value) => {
                    if (Array.isArray(value)) {
                      return;
                    }
                    const newValue = value
                      ? {
                          label: value.label,
                          value: value.value,
                        }
                      : { label: '', value: '' };
                    onChange(newValue);
                    if (value?.value) {
                      const chosenLocation = locations?.find(
                        (loc) => loc.uuid === value.value
                      );
                      handleCoordsChange(
                        chosenLocation?.coordinates ?? {
                          latitude: '',
                          longitude: '',
                        }
                      );
                    }
                  }}
                />
              )}
              name="location"
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Box height={370}>
              <EditableMap
                hasBorderRadius={hasBorderRadius}
                center={{
                  longitude: watchLongitude || '15',
                  latitude: watchLatitude || '52',
                }}
                zoom={
                  watchLatitude !== defaultValues.coordinates.latitude ||
                  watchLongitude !== defaultValues.coordinates.longitude
                    ? 13
                    : 3
                }
                onMapReady={() => onSetIsMapReady(true)}
                onCoordsChange={handleCoordsChange}
              />
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Controller
              control={control}
              render={({ field: { value, onChange, onBlur } }) => {
                return (
                  <ESTextField
                    value={value}
                    label={t('locationDialogLongitudeLabel')}
                    type="text"
                    onChange={(event) => {
                      const isValid =
                        !isNaN(Number(event?.target.value)) ||
                        event?.target.value === '-';
                      if (event === undefined || isValid) {
                        trigger('coordinates.latitude');
                        onChange(event);
                      }
                    }}
                    onBlur={onBlur}
                    error={Boolean(errors['coordinates']?.longitude)}
                    helperText={
                      Boolean(errors['coordinates']?.longitude) &&
                      t(`${errors['coordinates']?.longitude?.message}`)
                    }
                    inputProps={{
                      'data-testid': 'addStationLongitudeTextField',
                    }}
                  />
                );
              }}
              name="coordinates.longitude"
            />
          </Grid>
          <Grid item xs={6}>
            <Controller
              control={control}
              render={({ field: { value, onChange, onBlur } }) => {
                return (
                  <ESTextField
                    value={value}
                    label={t('locationDialogLatitudeLabel')}
                    type="text"
                    onChange={(event) => {
                      const isValid =
                        !isNaN(Number(event?.target.value)) ||
                        event?.target.value === '-';
                      if (event === undefined || isValid) {
                        trigger('coordinates.longitude');
                        onChange(event);
                      }
                    }}
                    onBlur={onBlur}
                    error={Boolean(errors['coordinates']?.latitude)}
                    helperText={
                      Boolean(errors['coordinates']?.latitude) &&
                      t(`${errors['coordinates']?.latitude?.message}`)
                    }
                    inputProps={{
                      'data-testid': 'addStationLatitudeTextField',
                    }}
                  />
                );
              }}
              name="coordinates.latitude"
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
