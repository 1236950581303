import { SidebarGroup } from '@energy-stacks/core/sidebar';
import { IconTruck } from '@tabler/icons-react';
import { FleetRoutes } from '@energy-stacks/fleet/shared';
import { useTranslation } from 'react-i18next';

export const VehiclesSidebarItem = () => {
  const [t] = useTranslation('vehicles');

  return (
    <SidebarGroup
      groupInitialPath={FleetRoutes.Vehicles}
      groupIcon={<IconTruck />}
      groupLabel={t('sidebarItem')}
      testId="vehiclesSidebarButton"
    ></SidebarGroup>
  );
};
