import {
  ESDialogActionButton,
  ESDialogActions,
  ESDialogContent,
  ESDialogTitle,
} from '@energy-stacks/core/ui';
import { Dialog, Typography } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

interface BrokerDeleteLocationConfirmDialogProps {
  isOpen: boolean;
  onCancel: () => void;
  onConfirm: () => Promise<void> | undefined;
}

export const BrokerDeleteLocationConfirmDialog: React.FC<
  BrokerDeleteLocationConfirmDialogProps
> = ({ isOpen, onCancel, onConfirm }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const { t } = useTranslation('locations');

  const handleConfirm = () => {
    setLoading(true);
    onConfirm()?.finally(() => setLoading(false));
  };
  return (
    <Dialog open={isOpen} onClose={onCancel}>
      <ESDialogTitle data-testid="deleteChargingSiteDialogTitle">
        {t('confirmDeleteLocationTitle')}
      </ESDialogTitle>
      <ESDialogContent data-testid="deleteChargingSiteDialogContent">
        <Typography variant="body1">
          {t('confirmDeleteLocationMessage')}
        </Typography>
      </ESDialogContent>
      <ESDialogActions>
        <ESDialogActionButton
          testId="deleteChargingSiteCancel"
          color="error"
          onClick={onCancel}
          disabled={loading}
        >
          {t('cancel')}
        </ESDialogActionButton>
        <ESDialogActionButton
          testId="deleteChargingSiteDelete"
          variant="contained"
          loading={loading}
          onClick={handleConfirm}
        >
          {t('deleteConfirm')}
        </ESDialogActionButton>
      </ESDialogActions>
    </Dialog>
  );
};
