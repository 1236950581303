import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';
import {
  ESPageLoadingIndicator,
  RefetchOnError,
  useDocumentTitle,
  ESPage,
} from '@energy-stacks/shared';
import { Locations } from './Locations';
import { useGetAllLocationsBrokerQuery } from '@energy-stacks/broker/feature-locations-data';

export const LocationsPage = () => {
  const { t } = useTranslation('locations');
  useDocumentTitle(t('documentTitle'));

  const { refetch, isLoading, isError, isSuccess } =
    useGetAllLocationsBrokerQuery();

  return (
    <ESPage title={t('screenTitle')}>
      {isLoading ? <ESPageLoadingIndicator /> : null}
      {isError ? <RefetchOnError onRefetch={refetch} /> : null}
      {isSuccess ? <Locations /> : null}
      <Outlet />
    </ESPage>
  );
};
