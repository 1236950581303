import { JobStatus } from '../types/jobStatuses';
import { JobStatusDto } from '../types/jobStatusesDto';

export const jobStatusMap: Record<JobStatusDto, JobStatus> = {
  TO_DO: 'TO_DO',
  PENDING: 'PENDING',
  PLANNED: 'PLANNED',
  ENROUTE: 'ENROUTE',
  DELIVERED: 'DONE',
  FAILED: 'FAILED',
  RETURNED: 'FAILED',
  CANCELLED: 'CANCELLED',
};
