import {
  TransactionModel,
  useGetDashboardTransactionsQuery,
} from '@energy-stacks/broker/feature-transactions-data';
import { DEFAULT_SORT_CONFIG } from '@energy-stacks/core/ui';
import { useMemo } from 'react';

export const useDashboardTransactions = () => {
  const { data, isLoading, isFetching, error, isSuccess, refetch, isError } =
    useGetDashboardTransactionsQuery({
      page: 0,
      size: 10,
      sort: {
        id: 'transactionId' ?? '',
        order: DEFAULT_SORT_CONFIG.order,
      },
    });

  const allTransactions =
    useMemo(
      () => data?.ids?.map((id) => data.entities[id] as TransactionModel),
      [data]
    ) || [];

  const transactions = allTransactions.slice(0, 10);

  return {
    transactions: transactions,
    isLoading,
    error,
    isSuccess,
    refetch,
    isError,
    isFetching,
  };
};
