import { useRef, useEffect } from 'react';
import { stompClientInstance } from './stomp';

export const useStompTopic = <E>(topic: string, fn: (event: E) => void) => {
  const callback = useRef(fn);

  useEffect(() => {
    const sub = stompClientInstance?.subscribe(topic, (event) => {
      callback.current(JSON.parse(event.body) as E);
    });
    return () => sub?.unsubscribe();
  }, [topic, callback]);
};
