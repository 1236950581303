import { useTranslation } from 'react-i18next';
import { ConnectorModel } from '@energy-stacks/broker/feature-charging-stations-data';
import { Button, Popover, Typography, useTheme } from '@mui/material';
import { IconChevronDown, IconChevronUp } from '@tabler/icons-react';
import { ConnectorIcon } from '@energy-stacks/broker/shared';

interface ConnectorButtonV201Props {
  connector: ConnectorModel;
  PickerComponent: React.ReactNode;
  anchorEl: HTMLButtonElement | null;
  popoverOpen: boolean;
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  onClose: () => void;
  testId?: string;
}

export const ConnectorButtonV201: React.FC<ConnectorButtonV201Props> = ({
  connector,
  PickerComponent,
  onClick,
  anchorEl,
  popoverOpen,
  onClose,
  testId,
}) => {
  const [t] = useTranslation('chargingStations');
  const { palette } = useTheme();
  return (
    <>
      <Button
        onClick={onClick}
        startIcon={
          <ConnectorIcon
            connectorType={connector.connectorType}
            size={20}
            color={palette.dark.light}
          />
        }
        endIcon={popoverOpen ? <IconChevronUp /> : <IconChevronDown />}
        variant="outlined"
        disableRipple
        data-testid={testId ? `${testId}ConnectorButton` : undefined}
        sx={{
          border: 'none !important',
          p: '4px 8px !important',
          color: palette.dark.light,
          backgroundColor: `${palette.dark.dark} !important`,
        }}
      >
        <Typography sx={{ pl: 2, fontWeight: 500 }}>
          {`${t('connector')} ${connector.connectorId}`}
        </Typography>
      </Button>

      <Popover
        open={popoverOpen}
        anchorEl={anchorEl}
        onClose={onClose}
        anchorPosition={{
          top: anchorEl ? anchorEl.getBoundingClientRect().bottom + 13 : 0,
          left: anchorEl ? anchorEl.getBoundingClientRect().left : 0,
        }}
        PaperProps={{
          sx: {
            boxShadow: '0px 2px 1px -1px rgba(56, 67, 107, 0.2)',
          },
        }}
        anchorReference="anchorPosition"
      >
        {PickerComponent}
      </Popover>
    </>
  );
};
