import { OptimizedTourJob } from '../optimizedTourModel';
import { ConflictingJobOrigin } from '../contaminationViolation';
import { ContaminationRuleJobResponse } from '../contaminationRuleJobResponse';

export const contaminationViolationNormalizer = (
  violations: ContaminationRuleJobResponse[],
  job: OptimizedTourJob,
  jobs: OptimizedTourJob[]
) => {
  const violation = violations.find(
    (violation) => violation.offendingJobUid === job.jobId
  );
  let conflictingJobOrigin: ConflictingJobOrigin | undefined;
  if (violation) {
    if (violation.uidOfJobInDatabaseTour) {
      conflictingJobOrigin = 'previousTour';
    } else if (jobs.find((job) => job.jobId === violation.firstJobUid)) {
      conflictingJobOrigin = 'sameTour';
    } else {
      conflictingJobOrigin = 'reviewTour';
    }
  }

  return violation && conflictingJobOrigin
    ? {
        conflictingJobId: violation.firstJobUid,
        conflictingJobOrigin,
      }
    : null;
};
