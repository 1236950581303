import { useGetCentralSystemsQuery } from '@energy-stacks/broker/feature-system-preferences-data';
import { useMemo } from 'react';

export const useDefaultCentralSystem = () => {
  const { data: centralSystems } = useGetCentralSystemsQuery();
  return useMemo(
    () => centralSystems?.find((cs) => cs.isDefault),
    [centralSystems]
  );
};
