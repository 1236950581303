import { useGetRegisteredServicesQuery } from '@energy-stacks/broker/feature-registered-services-data';
import React from 'react';
import { BrokerService } from './brokerService';
import { Navigate } from 'react-router-dom';
import { BrokerRoutes } from '@energy-stacks/broker/shared';

export const ServiceAccessGuard: React.FC<{
  children: React.ReactNode;
  service: BrokerService;
  redirect: boolean;
}> = ({ children, service, redirect }) => {
  const { data: availableServices } = useGetRegisteredServicesQuery();
  const isServiceAvailable = availableServices?.includes(service);
  if (!isServiceAvailable && !redirect) {
    return null;
  }

  if (!isServiceAvailable && redirect) {
    return <Navigate to={BrokerRoutes.Dashboard} replace />;
  }

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
};
