export const LeftArrow = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={23}
      height={22}
      viewBox="0 0 23 22"
      fill="none"
    >
      <g clipPath="url(#clip0_2895_1506)">
        <path
          d="M13.4895 5.48877L8.00085 10.9774L13.4895 16.466L14.7793 15.1762L10.5896 10.9774L14.7793 6.7786L13.4895 5.48877Z"
          fill="#29314F"
        />
      </g>
      <defs>
        <clipPath id="clip0_2895_1506">
          <rect
            width={21.9544}
            height={21.9546}
            fill="white"
            transform="translate(0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
