import { Stack, Typography, Chip } from '@mui/material';
import { IconTruck } from '@tabler/icons-react';
import { useRef } from 'react';
import { useEffectOnce } from 'usehooks-ts';
import { vehicleColors } from '../vehicleColors';

interface VehicleChipProps {
  title: string;
  index: number; // Pass index in order to access unique color
}

const useChipWidth = () => {
  const chipWidthRef = useRef<number>(0);
  const chipsRef = useRef<NodeListOf<Element> | null>(null);

  useEffectOnce(() => {
    // TODO: Evaluate if this is necessary
    chipsRef.current = document.querySelectorAll('.MuiChip-root');

    chipWidthRef.current = Math.max(
      ...Array.from(chipsRef.current).map(
        (chip) => chip.getBoundingClientRect().width
      )
    );
  });

  return chipWidthRef.current;
};

export const VehicleChip: React.FC<VehicleChipProps> = ({ title, index }) => {
  const chipWidth = useChipWidth();
  return (
    <Chip
      sx={{
        borderRadius: 32,
        border: '1.5px solid',
        borderColor: vehicleColors[index % vehicleColors.length],
        backgroundColor: 'transparent',
        color: 'primary.800',
        minWidth: chipWidth,
        '& .MuiChip-label': {
          paddingX: '16px',
        },
        '&.MuiChip-root': {
          cursor: 'inherit',
        },
      }}
      label={
        <Stack direction="row" alignItems="center" gap={1}>
          <IconTruck />
          <Typography color="grey.900" fontWeight={700}>
            {title}
          </Typography>
        </Stack>
      }
    />
  );
};
