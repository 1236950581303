import { ConnectorStandard } from '@energy-stacks/data';

export interface ConnectorModel {
  connectorId: number;
  evseId: string;
  connectorStatus: ConnectorStatusEnum;
  ocppErrorCode: OcppErrorCodeEnum;
  dateLastUpdate: string | null;
  ocppErrorInfo?: string;
  maxPower: number;
  connectorFormat: ConnectorFormat;
  connectorType: ConnectorStandard | undefined;
  maxVoltage: number;
  maxAmperage: number;
  powerType: ConnectorPowerType | undefined;
  currentVoltage: number;
  currentAmperage: number;
  currentPower: number;
  tariffId: string;
}

export enum ConnectorStatusEnum {
  undefined = 'undefined',
  available = 'available',
  preparing = 'preparing',
  charging = 'charging',
  occupied = 'occupied',
  suspendedEVSE = 'suspendedEVSE',
  suspendedEV = 'suspendedEV',
  finishing = 'finishing',
  reserved = 'reserved',
  unavailable = 'unavailable',
  faulted = 'faulted',
}

export enum OcppErrorCodeEnum {
  connectorLockFailure = 'connectorLockFailure',
  evCommunicationError = 'evCommunicationError',
  groundFailure = 'groundFailure',
  highTemperature = 'highTemperature',
  internalError = 'internalError',
  localListConflict = 'localListConflict',
  noError = 'noError',
  otherError = 'otherError',
  overCurrentFailure = 'overCurrentFailure',
  overVoltage = 'overVoltage',
  powerMeterFailure = 'powerMeterFailure',
  powerSwitchFailure = 'powerSwitchFailure',
  readerFailure = 'readerFailure',
  resetFailure = 'resetFailure',
  underVoltage = 'underVoltage',
  weakSignal = 'weakSignal',
}

type ConnectorFormat = 'socket' | 'cable';

export const connectorPowerTypes = [
  'AC_1_PHASE',
  'AC_2_PHASE',
  'AC_2_PHASE_SPLIT',
  'AC_3_PHASE',
  'DC',
] as const;

export type ConnectorPowerType = (typeof connectorPowerTypes)[number];
