import {
  ESButton,
  ESDialogActions,
  ESDialogContent,
  ESDialogTitle,
  ESSelect,
  useESSnackbar,
} from '@energy-stacks/core/ui';
import { Dialog } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import {
  ResetV201RequestOption,
  resetV201RequestOptions,
  useResetV201Mutation,
  ChargingStationDetailsModel,
} from '@energy-stacks/broker/feature-charging-stations-data';
import { yupResolver } from '@hookform/resolvers/yup';
import { useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';

interface ResetDialogV201Props {
  isOpen: boolean;
  onClose: () => void;
  identityKey: ChargingStationDetailsModel['identityKey'];
  evseId?: number;
}

export const ResetDialogV201: React.FC<ResetDialogV201Props> = ({
  isOpen,
  onClose,
  identityKey,
  evseId,
}) => {
  const { t } = useTranslation('chargingStations');
  const { showSnackbar } = useESSnackbar();
  const {
    control,
    handleSubmit,
    reset,
    formState: { isValid },
    getValues,
  } = useForm<{ resetOption: ResetV201RequestOption }>({
    resolver: yupResolver(
      yup.object().shape({
        resetOption: yup
          .string()
          .required()
          .oneOf([...resetV201RequestOptions]),
      })
    ),
    mode: 'onChange',
    defaultValues: {
      resetOption: undefined,
    },
  });
  const [resetV201, { isLoading }] = useResetV201Mutation();
  const requestRef = useRef<ReturnType<typeof resetV201>>();

  const onSubmit = useCallback(() => {
    const reset = resetV201({
      identityKey: identityKey,
      type: getValues().resetOption,
      evseId: evseId,
    });

    requestRef.current = reset;

    reset
      .unwrap()
      .then((result) => {
        if (result.status === 'Rejected') {
          showSnackbar(
            'error',
            t('changeChargingStationStatusRejectedMessage')
          );
          return;
        }

        if (result.status === 'Accepted') {
          showSnackbar(
            'success',
            t('changeChargingStationStatusSuccessMessage')
          );
        }

        if (result.status === 'Scheduled') {
          showSnackbar(
            'info',
            t('changeChargingStationStatusScheduledMessage')
          );
        }

        onClose();
      })
      .catch((error) => {
        if (error.data?.errorCode === 'RPC_TIMEOUT') {
          showSnackbar('error', t('rpcTimeoutError'));
          return;
        }
        if (error.data?.errorCode === 'RPC_ERROR') {
          showSnackbar('error', t('rpcError'));
          return;
        }

        if (error.name === 'AbortError') {
          return;
        }

        showSnackbar('error');
      });
  }, [identityKey, evseId, resetV201, getValues, onClose, t, showSnackbar]);

  const handleCancel = useCallback(() => {
    requestRef.current?.abort();
    reset();
    onClose();
  }, [onClose, reset]);

  return (
    <Dialog open={isOpen} onClose={handleCancel} fullWidth maxWidth="sm">
      <ESDialogTitle data-testid="resetTitle">{`${t(
        'resetStation'
      )}`}</ESDialogTitle>
      <ESDialogContent>
        <Controller
          control={control}
          name="resetOption"
          render={({ field: { value, onChange, onBlur } }) => (
            <ESSelect
              testId="resetV201"
              label={`${t('resetStation')}`}
              options={resetV201RequestOptions.map((option) => ({
                value: option,
                label: t(`resetV201Option.${option}`),
              }))}
              onChange={onChange}
              onBlur={onBlur}
              value={value || ''}
              required
              disabled={isLoading}
              sx={{ mt: 8 }}
            />
          )}
        />
      </ESDialogContent>

      <ESDialogActions>
        <ESButton
          data-testid="resetCancelButton"
          variant="text"
          color="error"
          onClick={handleCancel}
          disabled={isLoading}
        >
          {t('cancel')}
        </ESButton>

        <ESButton
          data-testid="resetButton"
          variant="contained"
          onClick={handleSubmit(onSubmit)}
          disabled={!isValid || isLoading}
          loading={isLoading}
        >
          {t('reset')}
        </ESButton>
      </ESDialogActions>
    </Dialog>
  );
};
