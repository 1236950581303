import { useTheme } from '@mui/material';
import React from 'react';
import { ConnectorStandard } from '@energy-stacks/data';
import { ReactComponent as UnknownIcon } from './icons/unknown.svg';
import { connectorStandardToIconMap } from './connectorStandardToIconMap';

interface ConnectorIconProps {
  connectorType?: ConnectorStandard;
  color?: string;
  size?: number;
}

export const ConnectorIcon: React.FC<ConnectorIconProps> = (props) => {
  const { palette } = useTheme();
  const { connectorType, color = palette.dark.main, size = 26 } = props;

  if (!connectorType) {
    return <UnknownIcon height={size} width={size} color={color} />;
  }

  const Icon = connectorStandardToIconMap[connectorType];
  return <Icon height={size} width={size} color={color} />;
};
