import { useCallback, useState } from 'react';
import {
  ESDialogActionButton,
  ESDialogActions,
  ESDialogContent,
  ESDialogTitle,
  ESTextField,
  ESVirtualizedAutocomplete,
  useESSnackbar,
} from '@energy-stacks/core/ui';
import { Dialog, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, FormProvider, useForm, useWatch } from 'react-hook-form';
import {
  ConfirmCloseDialog,
  useConfirmCloseDialog,
} from '@energy-stacks/shared';
import {
  BrokerAddLocationGoogleMap,
  INITIAL_LATITUDE,
  INITIAL_LONGITUDE,
} from '../shared/BrokerAddLocationGoogleMap';
import { brokerLocationFormValidationSchema } from '../shared/brokerLocationFormValidationSchema';
import {
  BrokerLocationFormData,
  GeoLocation,
  useAddBrokerLocationsMutation,
} from '@energy-stacks/broker/feature-locations-data';
import {
  Country,
  countries,
} from '@energy-stacks/broker/feature-locations-data';

export const BrokerAddLocation = () => {
  const [isDialogOpen, setIsDialogOpen] = useState(true);
  const { t } = useTranslation('locations');
  const [tShared] = useTranslation('shared');

  const brokerLocationFormInitialValues: BrokerLocationFormData = {
    locationName: '',
    streetNameAndNumber: '',
    city: '',
    zipCode: '',
    state: '',
    country: {
      label: '',
      value: '',
    },
    lat: INITIAL_LATITUDE,
    lng: INITIAL_LONGITUDE,
  };

  const methods = useForm<BrokerLocationFormData>({
    mode: 'onTouched',
    defaultValues: brokerLocationFormInitialValues,
    resolver: yupResolver(brokerLocationFormValidationSchema),
  });
  const {
    handleSubmit,
    control,
    formState: { isValid, isDirty: isFormDirty, errors },
    setValue,
    reset: resetForm,
  } = methods;
  const lat = useWatch({ control, name: 'lat' });
  const lng = useWatch({ control, name: 'lng' });

  const {
    isConfirmCloseDialogOpen,
    handleOpenConfirmDialog,
    handleStay,
    handleLeaveWithoutSaving,
  } = useConfirmCloseDialog(isFormDirty);

  const navigate = useNavigate();
  const { showSnackbar } = useESSnackbar();

  const handleClose = useCallback(() => {
    setIsDialogOpen(false);
    navigate(-1);
    resetForm();
  }, [resetForm, navigate]);

  const handleCoordsChange = (newCoords: GeoLocation) => {
    setValue('lat', newCoords.latitude.toString().slice(0, 10), {
      shouldValidate: true,
    });
    setValue('lng', newCoords.longitude.toString().slice(0, 11), {
      shouldValidate: true,
    });
  };

  const [addBrokerLocation, { isLoading }] = useAddBrokerLocationsMutation();

  const onSubmit = (data: BrokerLocationFormData) => {
    addBrokerLocation(data)
      .unwrap()
      .then(() => {
        showSnackbar('success', 'addBrokerLocation', 'locations');
        handleClose();
      })
      .catch((error) => {
        showSnackbar('error');
      });
  };

  return (
    <FormProvider {...methods}>
      <Dialog
        open={isDialogOpen}
        onClose={handleOpenConfirmDialog}
        maxWidth="lg"
        fullWidth
      >
        <ESDialogTitle>{t('createLocationTitle')}</ESDialogTitle>
        <form>
          <ESDialogContent sx={{ flexGrow: 1 }}>
            <Grid container spacing={6}>
              <Grid item xs={6}>
                <Grid container spacing={6}>
                  <Grid item xs={12}>
                    <Controller
                      control={control}
                      rules={{ required: true }}
                      render={({ field: { onChange, onBlur, value } }) => {
                        return (
                          <ESTextField
                            inputProps={{
                              'data-testid': `chargingSiteNameInput`,
                            }}
                            label={t('locationName')}
                            required
                            value={value}
                            onChange={onChange}
                            onBlur={onBlur}
                            error={Boolean(errors.locationName)}
                            helperText={
                              Boolean(errors.locationName) &&
                              t(`${errors.locationName?.message}`)
                            }
                          />
                        );
                      }}
                      name={'locationName'}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Controller
                      control={control}
                      render={({ field: { onChange, onBlur, value } }) => {
                        return (
                          <ESTextField
                            inputProps={{
                              'data-testid': `streetNameAndNumberInput`,
                            }}
                            label={t('streetNameAndNumber')}
                            value={value}
                            onChange={onChange}
                            onBlur={onBlur}
                            error={Boolean(errors.streetNameAndNumber)}
                            helperText={
                              Boolean(errors.streetNameAndNumber) &&
                              t(`${errors.streetNameAndNumber?.message}`)
                            }
                          />
                        );
                      }}
                      name={'streetNameAndNumber'}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Controller
                      control={control}
                      render={({ field: { onChange, onBlur, value } }) => {
                        return (
                          <ESTextField
                            inputProps={{
                              'data-testid': `zipCodeInput`,
                            }}
                            label={t('zipCode')}
                            value={value}
                            onChange={onChange}
                            onBlur={onBlur}
                            error={Boolean(errors.zipCode)}
                            helperText={
                              Boolean(errors.zipCode) &&
                              t(`${errors.zipCode?.message}`)
                            }
                          />
                        );
                      }}
                      name={'zipCode'}
                    />
                  </Grid>
                  <Grid item xs={8}>
                    <Controller
                      control={control}
                      render={({ field: { onChange, onBlur, value } }) => {
                        return (
                          <ESTextField
                            inputProps={{
                              'data-testid': `cityInput`,
                            }}
                            label={t('city')}
                            value={value}
                            onChange={onChange}
                            onBlur={onBlur}
                            error={Boolean(errors.city)}
                            helperText={
                              Boolean(errors.city) &&
                              t(`${errors.city?.message}`)
                            }
                          />
                        );
                      }}
                      name={'city'}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Controller
                      control={control}
                      render={({ field: { onChange, onBlur, value } }) => {
                        return (
                          <ESTextField
                            inputProps={{
                              'data-testid': `stateInput`,
                            }}
                            label={t('state')}
                            value={value}
                            onChange={onChange}
                            onBlur={onBlur}
                            error={Boolean(errors.state)}
                            helperText={
                              Boolean(errors.state) &&
                              t(`${errors.state?.message}`)
                            }
                          />
                        );
                      }}
                      name={'state'}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Controller
                      control={control}
                      render={({ field: { onChange, onBlur, value } }) => {
                        return (
                          <ESVirtualizedAutocomplete<Country>
                            testId="countryInput"
                            label={t('country')}
                            value={{
                              value: value.value,
                              label: value.label,
                            }}
                            options={countries.map((country) => ({
                              label: t(country.label),
                              value: country.value,
                            }))}
                            noOptionsText={t('noCountries')}
                            onChange={(_, value) => {
                              if (Array.isArray(value)) {
                                return;
                              }
                              if (value) {
                                onChange({
                                  label: value.label,
                                  value: value.value ?? '',
                                });
                              }
                            }}
                            onBlur={onBlur}
                          />
                        );
                      }}
                      name={'country'}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={6}>
                <BrokerAddLocationGoogleMap
                  center={{
                    latitude: lat,
                    longitude: lng,
                  }}
                  onCoordsChange={handleCoordsChange}
                />
              </Grid>
            </Grid>
          </ESDialogContent>
          <ESDialogActions>
            <ESDialogActionButton
              testId="addSiteCancel"
              color="error"
              disabled={isLoading}
              onClick={handleOpenConfirmDialog}
            >
              {tShared('cancel')}
            </ESDialogActionButton>
            <ESDialogActionButton
              testId="addsiteCreate"
              disabled={!isValid}
              loading={isLoading}
              type="submit"
              variant="contained"
              onClick={handleSubmit(onSubmit)}
            >
              {t('create')}
            </ESDialogActionButton>
          </ESDialogActions>
        </form>
      </Dialog>
      <ConfirmCloseDialog
        open={isConfirmCloseDialogOpen}
        onStay={handleStay}
        onLeaveWithoutSaving={handleLeaveWithoutSaving}
      />
    </FormProvider>
  );
};
