import { Box, Divider } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { IconListNumbers } from '@tabler/icons-react';
import { IconFileDescription } from '@tabler/icons-react';
import { IconMapPin } from '@tabler/icons-react';
import { IconPhone } from '@tabler/icons-react';
import { IconMail } from '@tabler/icons-react';
import React, { useMemo } from 'react';
import { AccountDetailsRow } from './AccountDetailsRow';
import { AccountDetailsIconWrapper } from './AccountDetailsIconWrapper';
import { BusinessAccountDetailsModel } from '@energy-stacks/fleet/feature-business-accounts-data';
import { useRemovePlantIdPrefix } from '../../useRemovePlantIdPrefix';

type AccountDetailsProps = {
  accountNumber?: BusinessAccountDetailsModel['id'];
  accountName?: BusinessAccountDetailsModel['companyName'];
  city?: BusinessAccountDetailsModel['address']['city'];
  street?: BusinessAccountDetailsModel['address']['street'];
  email?: BusinessAccountDetailsModel['contact']['email'];
  phoneNumber?: BusinessAccountDetailsModel['contact']['phoneNumber'];
};

export type AccountDetailsRowInfo = {
  label: string;
  value: string | undefined;
  icon: React.ReactNode;
};

export const AccountDetails: React.FC<AccountDetailsProps> = ({
  accountNumber,
  accountName,
  city,
  street,
  email,
  phoneNumber,
}) => {
  const { t } = useTranslation('businessAccounts');
  const removePlantIdPrefix = useRemovePlantIdPrefix();

  const accountDetails: Record<string, AccountDetailsRowInfo> = useMemo(
    () => ({
      accountNumber: {
        label: t('accountDetailsAccountNumber'),
        value: accountNumber ? removePlantIdPrefix(accountNumber) : '',
        icon: <IconListNumbers />,
      },
      accountName: {
        label: t('accountDetailsAccountName'),
        value: accountName,
        icon: <IconFileDescription />,
      },
      address: {
        label: t('accountDetailsAddress'),
        value:
          street && city
            ? `${street}, ${city}`
            : !street && !city
            ? undefined
            : street || city,
        icon: <IconMapPin />,
      },
      email: {
        label: t('accountDetailsEmail'),
        value: email,
        icon: <IconMail />,
      },
      phoneNumber: {
        label: t('accountDetailsPhoneNumber'),
        value: phoneNumber,
        icon: <IconPhone />,
      },
    }),
    [
      accountName,
      accountNumber,
      city,
      email,
      phoneNumber,
      removePlantIdPrefix,
      street,
      t,
    ]
  );

  const accountDetailsEntries = Object.entries(accountDetails);

  return (
    <Box height="100%" overflow="auto">
      {accountDetailsEntries.map(([key, rowInfo], index) => {
        const isHyperlink =
          key === 'address' || key === 'email' || key === 'phoneNumber';
        return (
          <React.Fragment key={key}>
            {index !== 0 ? <Divider sx={{ borderColor: 'grey.100' }} /> : null}
            <Box
              marginY={3.57}
              marginBottom={
                index === accountDetailsEntries.length - 1 ? 0 : undefined
              }
              marginRight={2}
            >
              <AccountDetailsRow
                type={isHyperlink ? key : undefined}
                label={rowInfo.label}
                value={rowInfo.value}
                Icon={
                  <AccountDetailsIconWrapper>
                    {rowInfo.icon}
                  </AccountDetailsIconWrapper>
                }
              />
            </Box>
          </React.Fragment>
        );
      })}
    </Box>
  );
};
