import { BrokerFeature } from './brokerFeature';
import { UserRole as BrokerUserRole } from '@energy-stacks/broker/feature-users-data';

export const brokerFeatureAccess: Record<BrokerFeature, BrokerUserRole[]> = {
  userManagement: ['admin'],
  userEditing: ['admin'],
  userDeleting: ['admin'],
  accessRequestManagement: ['admin'],
  editImprint: ['admin'],
  saveConfiguration: ['admin', 'operator'],
  settingsSystemConfiguration: ['admin'],
};
