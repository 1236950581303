import { ESTableBodyCell, ESTableBodyRow } from '@energy-stacks/core/ui';
import { formatDate } from '@energy-stacks/shared';
import { Box, Collapse, Typography, Stack, Divider } from '@mui/material';
import {
  IconMapPin,
  IconArrowMoveRight,
  IconArrowUpRhombus,
  IconCar,
  IconArrowDownRhombus,
  IconFlag3,
} from '@tabler/icons-react';
import { secondsToMinutes, subMinutes } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { TourDetail } from './tourDetail';
import { ConfirmedTime } from '../shared/ConfirmedTime';
import { ForecastedTime } from '../shared/ForecastedTime';

interface TourDetailsJobsTableProps {
  expanded: boolean;
  colSpan: number;
  backgroundColor?: string;
  job: TourDetail;
}

export const JobDetailsRow: React.FC<TourDetailsJobsTableProps> = ({
  expanded,
  colSpan,
  backgroundColor,
  job,
}) => {
  const { t } = useTranslation('tours');

  return (
    <ESTableBodyRow rowHeight="unset">
      <ESTableBodyCell
        colSpan={colSpan}
        sx={{
          borderBottom: expanded ? 'auto' : 'unset',
          backgroundColor,
          padding: 0,
        }}
      >
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <Box
            sx={{
              p: 4,
              marginX: 6,
              marginTop: 2,
              marginBottom: 6,
              borderRadius: 2,
              color: 'grey.900',
              backgroundColor: 'primary.light',
            }}
          >
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <Stack direction="row" alignItems="center" gap={2}>
                <IconArrowMoveRight />
                <Stack direction="column" gap={1} alignItems="center">
                  <Typography fontWeight={500}>
                    {formatDate(job.startTime, 'HH:mm').toLowerCase()}
                  </Typography>
                  {job.confirmedStartTime && (
                    <ConfirmedTime
                      confirmedTime={job.confirmedStartTime}
                      plannedTime={job.startTime}
                    />
                  )}
                  {job.forecastedStartTime && !job.confirmedStartTime && (
                    <ForecastedTime forecastedTime={job.forecastedStartTime} />
                  )}
                </Stack>
              </Stack>
              <Stack direction="row" width="40%" position="relative">
                <Box
                  sx={{
                    width: '7px',
                    height: '7px',
                    borderRadius: 100,
                    position: 'absolute',
                    top: '55%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    backgroundColor: 'primary.200',
                  }}
                />
                <Stack
                  alignItems="center"
                  width="50%"
                  justifyContent="center"
                  gap={2}
                >
                  <Stack direction="row" alignItems="center">
                    <IconArrowUpRhombus />
                    <Typography>
                      {t('tourJobTypes.LOAD')} (
                      {job.product ? job.product.quantity + 't' : '---'})
                    </Typography>
                  </Stack>
                  <Divider
                    sx={{
                      borderWidth: '1px',
                      width: '100%',
                      borderColor: 'primary.200',
                    }}
                  />
                  <Typography>
                    {job.origin?.processTimeInMinutes ?? 0}min
                  </Typography>
                </Stack>
                <Stack
                  alignItems="center"
                  width="50%"
                  justifyContent="center"
                  gap={2}
                >
                  <Stack direction="row" gap={1} alignItems="center">
                    <IconCar />
                    <Typography>{t('driving')}</Typography>
                  </Stack>
                  <Divider
                    sx={{
                      borderWidth: '1px',
                      width: '100%',
                      borderColor: 'primary.200',
                    }}
                  />
                  <Typography>
                    {secondsToMinutes(job.duration) -
                      (job?.origin?.processTimeInMinutes ?? 0) -
                      (job?.destination?.processTimeInMinutes ?? 0)}
                    min
                  </Typography>
                </Stack>
              </Stack>
              <Stack direction="row" alignItems="center" gap={2}>
                <IconMapPin />
                <Typography fontWeight={500}>
                  {formatDate(
                    subMinutes(
                      new Date(job.endTime),
                      job?.destination?.processTimeInMinutes ?? 0
                    ).toISOString(),
                    'h:mm a'
                  ).toLowerCase()}
                </Typography>
              </Stack>
              <Stack
                alignItems="center"
                width="20%"
                justifyContent="center"
                gap={2}
              >
                <Stack direction="row" alignItems="center">
                  <IconArrowDownRhombus />
                  <Typography>
                    {t('tourJobTypes.UNLOAD')} (
                    {job.product ? job.product.quantity + 't' : '---'})
                  </Typography>
                </Stack>
                <Divider
                  sx={{
                    borderWidth: '1px',
                    width: '100%',
                    borderColor: 'primary.200',
                  }}
                />
                <Typography>
                  {job.destination?.processTimeInMinutes ?? 0}min
                </Typography>
              </Stack>
              <Stack direction="row" alignItems="center" gap={2}>
                <IconFlag3 />
                <Stack direction="column" gap={1} alignItems="center">
                  <Typography fontWeight={500}>
                    {formatDate(job.endTime, 'HH:mm').toLowerCase()}
                  </Typography>
                  {job.confirmedEndTime && (
                    <ConfirmedTime
                      confirmedTime={job.confirmedEndTime}
                      plannedTime={job.endTime}
                    />
                  )}
                  {job.forecastedEndTime && !job.confirmedEndTime && (
                    <ForecastedTime forecastedTime={job.forecastedEndTime} />
                  )}
                </Stack>
              </Stack>
            </Stack>
          </Box>
        </Collapse>
      </ESTableBodyCell>
    </ESTableBodyRow>
  );
};
