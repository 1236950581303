import i18next from 'i18next';
import { getI18n } from 'react-i18next';
import { Country } from '../country';

export const addressNormalizer = (
  address: string,
  postalCode: string | undefined,
  city: string,
  country: Country
) => {
  // TODO: Use from translations service. i18n is in the app, so it can't be exported from there
  const i18nInstance = getI18n();
  const lang = i18nInstance.language;
  const t = i18next.getFixedT(lang, 'locations');

  const postalAndCity = postalCode ? `${postalCode} ${city}` : city;

  return `${address}, ${postalAndCity}, ${t(country.label)}`;
};
