export enum MessageDirectionEnum {
  Server_To_ChargingStation = 'CSMS_2_CS',
  ChargingStation_To_Server = 'CS_2_CSMS',
  Broker_To_ChargingStation = 'BROKER_2_CS',
  ChargingStation_To_Broker = 'CS_2_BROKER',
  ChargingStation_To_Translator = 'CS_2_TRANSLATOR',
  Translator_To_ChargingStation = 'TRANSLATOR_2_CS',
  Server_To_Translator = 'CSMS_2_TRANSLATOR',
  Translator_To_Server = 'TRANSLATOR_2_CSMS',
}
