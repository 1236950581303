import { DateRange } from '@energy-stacks/core/date-range-picker';
import {
  DndRowsOptions,
  ESDnDVirtualizedTable,
  ESTableWrapper,
  useESTableBasic,
} from '@energy-stacks/core/ui';
import { useRemovePlantIdPrefix } from '@energy-stacks/fleet/feature-business-accounts';
import {
  JobDueDateCell,
  JobLocationCell,
} from '@energy-stacks/fleet/feature-jobs';
import { JobModel } from '@energy-stacks/fleet/feature-jobs-data';
import {
  ClearFiltersButton,
  ESTooltip,
  NoTableData,
  TableColumnFilter,
  TableRowsCount,
  TableSearchField,
} from '@energy-stacks/shared';
import { Stack, Typography } from '@mui/material';
import { createColumnHelper } from '@tanstack/react-table';
import { areIntervalsOverlapping, isAfter, parseISO } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { JobDurationCell } from '../tour-details/JobDurationCell';
import { TourDndTableHeader } from '../tour-details/TourDndTableHeader';

interface JobsPoolDndTableProps {
  jobPool: JobModel[];
  dndRowsOptions: DndRowsOptions<JobModel>;
}

const ROW_HEIGHT = 90;

export const JobsPoolDndTable: React.FC<JobsPoolDndTableProps> = ({
  jobPool,
  dndRowsOptions,
}) => {
  const [t] = useTranslation('jobs');
  const columnHelper = createColumnHelper<JobModel>();
  const removePlantIdPrefix = useRemovePlantIdPrefix();

  const columns = [
    columnHelper.accessor((row) => removePlantIdPrefix(row.jobId), {
      id: 'jobId',
      sortingFn: 'alphanumeric',
      header: () => t('jobId'),
      footer: (props) => props.column.id,
      cell: (info) => removePlantIdPrefix(info.getValue()),
      size: 100,
      enableResizing: false,
    }),
    columnHelper.accessor(
      (row) =>
        `${row.origin?.id} ${row.supplier.supplierId} ${row.supplier.supplierName} ${row.origin?.street}, ${row.origin?.city}`,
      {
        id: 'origin',
        header: () => t('origin'),
        footer: (props) => props.column.id,
        size: 250,
        cell: ({ row }) => (
          <JobLocationCell
            jobType={row.original.jobType}
            locationType="origin"
            supplierName={row.original.supplier.supplierName}
            location={row.original.origin}
          />
        ),
        enableGlobalFilter: true,
      }
    ),
    columnHelper.accessor(
      (row) =>
        `${row.destination?.id} ${row.supplier.supplierId} ${row.supplier.supplierName} ${row.destination?.street}, ${row.destination?.city}`,
      {
        id: 'destination',
        header: () => t('destination'),
        footer: (props) => props.column.id,
        size: 250,
        cell: ({ row }) => (
          <JobLocationCell
            location={row.original.destination}
            jobType={row.original.jobType}
            locationType="destination"
            supplierName={row.original.supplier.supplierName}
          />
        ),
        enableGlobalFilter: true,
      }
    ),
    columnHelper.accessor((row) => row.product?.name, {
      id: 'product',
      header: () => t('productType'),
      footer: (props) => props.column.id,
      size: 180,
      cell: ({ row }) => (
        <Stack direction="column" gap={1} alignItems="start">
          <Typography variant="inherit">{row.original.product.name}</Typography>
        </Stack>
      ),
      enableSorting: false,
      enableResizing: false,
      filterFn: 'arrIncludesSome',
    }),
    columnHelper.accessor('estimatedDuration.timeMinutes', {
      header: () => t('duration'),
      footer: (props) => props.column.id,
      cell: ({ row }) => (
        <JobDurationCell duration={row.original.estimatedDuration} />
      ),
      enableSorting: true,
    }),
    columnHelper.accessor('dueDate', {
      header: () => t('dueDate'),
      footer: (props) => props.column.id,
      cell: (info) => <JobDueDateCell dueDate={info.getValue()} />,
      size: 150,
      filterFn: (row, _, filterValue: DateRange | undefined) => {
        if (!filterValue) {
          return true;
        }

        if (
          isAfter(
            parseISO(row.original.earliestStartDate),
            parseISO(row.original.dueDate)
          )
        ) {
          return false;
        }

        return areIntervalsOverlapping(
          {
            start: filterValue.startDate,
            end: filterValue.endDate,
          },
          {
            start: parseISO(row.original.earliestStartDate),
            end: parseISO(row.original.dueDate),
          }
        );
      },
      enableResizing: false,
    }),
  ];

  const { instance, rows, globalFilter, onGlobalFilterChange } =
    useESTableBasic(jobPool, columns, {
      fitRowHeight: ROW_HEIGHT,
      getRowId: (row) => row.jobId,
      manualPagination: true,
      enableColumnResizing: true,
    });

  return (
    <>
      <TourDndTableHeader>
        <ESTooltip title={t('searchJobsPoolPlaceholder')}>
          <TableSearchField
            placeholder={t('searchJobsPoolPlaceholder')}
            value={globalFilter}
            onChange={onGlobalFilterChange}
            tableInstance={instance}
          />
        </ESTooltip>
        <TableColumnFilter
          column={instance.getColumn('product')}
          columnLabel={t('productType')}
          options={['Raw Manure', 'Degassed Manure'].map((v) => ({
            label: v,
            value: v,
          }))}
        />
        <ClearFiltersButton tableInstance={instance} />
      </TourDndTableHeader>
      <ESTableWrapper>
        <ESDnDVirtualizedTable
          instance={instance}
          rows={rows}
          rowHeight={ROW_HEIGHT}
          dndRowsOptions={dndRowsOptions}
        />
        {rows.length === 0 ? (
          <NoTableData message={t('thereAreNoJobs')} />
        ) : null}
        <TableRowsCount
          instance={instance}
          totalCountLabel={t('totalRowsCount')}
        />
      </ESTableWrapper>
    </>
  );
};
