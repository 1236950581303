import { isEmpty } from 'lodash-es';
import * as themeConfig from './theme.json';
import './assets/scss/_scrollbar.scss';

const config = themeConfig as Record<string, string>;

export const theme = !isEmpty(config['primaryMainColor'])
  ? {
      primaryMainColor: config['primaryMainColor'],
      primaryDarkColor: config['primaryDarkColor'],
      secondaryMainColor: config['secondaryMainColor'],
    }
  : undefined;

export { ReactComponent as DarkLogo } from './darkLogo.svg';
export { ReactComponent as LightLogo } from './lightLogo.svg';
