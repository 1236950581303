import { ChargingStationLocationMapView } from '@energy-stacks/shared';
import { Box, Skeleton, useMediaQuery } from '@mui/material';
import { useChargingStationDetails } from './useChargingStationDetails';
import { BrokerRoutes } from '@energy-stacks/broker/shared';
import { useGetUiBrandingQuery } from '@energy-stacks/broker/feature-settings-data';
import { useMemo } from 'react';

export const MapDetails = () => {
  const { chargingStationDetails, isLoading: isLoadingChargingStationDetails } =
    useChargingStationDetails();
  const isFullHD = useMediaQuery('(min-width: 1920px)');
  const { data: uiBranding } = useGetUiBrandingQuery();
  const hasBorderRadius =
    uiBranding?.hasBorderRadius || uiBranding?.hasBorderRadius === undefined;

  const coords = useMemo(() => {
    if (chargingStationDetails?.geolocation) {
      return {
        latitude: chargingStationDetails.geolocation.latitude,
        longitude: chargingStationDetails.geolocation.longitude,
      };
    }
    return null;
  }, [chargingStationDetails?.geolocation]);

  return (
    <Box
      sx={{
        minHeight: '290px',
        height: '100%',
        aspectRatio: isFullHD ? 'unset' : '2/1',
      }}
    >
      {isLoadingChargingStationDetails ||
      chargingStationDetails === undefined ? (
        <Skeleton
          variant="rectangular"
          animation="wave"
          height="100%"
          width="100%"
        />
      ) : (
        <ChargingStationLocationMapView
          hasBorderRadius={hasBorderRadius}
          coords={coords}
          editRoute={BrokerRoutes.EditChargingStation}
        />
      )}
    </Box>
  );
};
