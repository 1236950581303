import { ESButton } from '@energy-stacks/core/ui';
import ReplayIcon from '@mui/icons-material/Replay';
import { useTranslation } from 'react-i18next';

interface RefetchButtonProps {
  onRefetch: () => void;
}

export const RefetchButton: React.FC<RefetchButtonProps> = ({ onRefetch }) => {
  const [t] = useTranslation('shared');
  return (
    <ESButton startIcon={<ReplayIcon />} onClick={onRefetch}>
      {t('retry')}
    </ESButton>
  );
};
