import { ESAddItemButton } from '@energy-stacks/core/ui';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { CSMSRoutes } from './CSMSRoutes';

export const CsmsAddChargingStation = () => {
  const { t } = useTranslation('chargingStations');
  const navigate = useNavigate();

  return (
    <ESAddItemButton
      onClick={() =>
        navigate(
          `${CSMSRoutes.ChargingStations}/${CSMSRoutes.AddChargingStation}`
        )
      }
      title={t('addChargingStationCallToAction')}
    />
  );
};
