import { ChargingStationStatus } from '@energy-stacks/broker/feature-charging-stations-data';
import React from 'react';
import { Theme } from '@mui/material/styles';
import {
  IconCheck,
  IconRefresh,
  IconBan,
  IconAlertTriangleFilled,
} from '@tabler/icons-react';

interface ChargingStationShadowStatus {
  bgColor: string;
  color: string;
  icon: React.ReactElement;
}

interface ChargingStationShadowStatus {
  bgColor: string;
  color: string;
  icon: React.ReactElement;
}

export const chargingStationStatusDisplay: (theme: Theme) => {
  [status in ChargingStationStatus]: ChargingStationShadowStatus;
} = (theme: Theme) => ({
  provisioned: {
    bgColor: 'orange.100',
    color: 'orange.900',
    icon: <IconRefresh size={18} color={theme.palette.orange[900]} />,
  },
  online: {
    bgColor: 'success.light',
    color: 'success.900',
    icon: <IconCheck size={18} color={theme.palette.success[900]} />,
  },
  offline: {
    bgColor: 'grey.300',
    color: 'grey.900',
    icon: <IconBan size={18} color={theme.palette.grey[900]} />,
  },
  faulted: {
    bgColor: 'orange.light',
    color: 'orange.dark',
    icon: (
      <IconAlertTriangleFilled
        size={18}
        style={{ color: theme.palette.orange.dark }}
      />
    ),
  },
});
