import {
  DndRowsOptions,
  ESDnDVirtualizedTable,
  ESTableWrapper,
  useESTableBasic,
} from '@energy-stacks/core/ui';
import { useRemovePlantIdPrefix } from '@energy-stacks/fleet/feature-business-accounts';
import {
  JobDueDateCell,
  JobLocationCell,
} from '@energy-stacks/fleet/feature-jobs';
import { NoTableData, TableRowsCount } from '@energy-stacks/shared';
import { Stack, Typography, useTheme } from '@mui/material';
import { createColumnHelper } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';
import { TourJobIdCell } from '../tour-details/TourJobIdCell';
import { EditingTourJob } from './editingTourJobModel';
import { JobStatusChip } from '@energy-stacks/fleet/shared';

type TourJobsDndTableProps = {
  tourJobs: EditingTourJob[];
  dndRowsOptions: DndRowsOptions<EditingTourJob>;
};

const ROW_HEIGHT = 65;

const jobSideActivities = ['BREAK'];

const JobTypeGuard: React.FC<{
  jobType: EditingTourJob['jobType'] | 'BREAK';
  children: React.ReactNode;
}> = ({ jobType, children }) => {
  if (jobSideActivities.includes(jobType)) {
    return null;
  }

  return <>{children}</>;
};

export const TourJobsDndTable: React.FC<TourJobsDndTableProps> = ({
  tourJobs,
  dndRowsOptions,
}) => {
  const [t] = useTranslation('jobs');
  const columnHelper = createColumnHelper<EditingTourJob>();
  const removePlantIdPrefix = useRemovePlantIdPrefix();
  const theme = useTheme();

  const columns = [
    columnHelper.accessor((row) => removePlantIdPrefix(row.jobId), {
      id: 'jobId',
      header: () => t('jobId'),
      footer: (props) => props.column.id,
      cell: (info) => (
        <TourJobIdCell
          jobType={info.row.original.jobType}
          jobId={removePlantIdPrefix(info.getValue())}
          contaminationViolation={info.row.original.contaminationViolation}
        />
      ),
      size: 100,
      enableResizing: false,
      enableSorting: false,
    }),
    columnHelper.accessor((row) => row.origin?.id, {
      id: 'origin',
      header: () => t('origin'),
      footer: (props) => props.column.id,
      size: 250,
      cell: ({ row, column }) => (
        <JobLocationCell
          supplierName={row.original.supplier.supplierName}
          jobType={row.original.jobType}
          locationType="origin"
          location={row.original.origin}
        />
      ),
    }),
    columnHelper.accessor((row) => row.destination?.id, {
      id: 'destination',
      header: () => t('destination'),
      footer: (props) => props.column.id,
      size: 250,
      cell: ({ row, column }) => (
        <JobLocationCell
          location={row.original.destination}
          supplierName={row.original.supplier.supplierName}
          jobType={row.original.jobType}
          locationType="destination"
        />
      ),
    }),
    columnHelper.accessor('status', {
      id: 'status',
      header: () => t('status'),
      footer: (props) => props.column.id,
      size: 130,
      enableSorting: false,
      cell: (info) => <JobStatusChip status={info.getValue()} />,
      enableResizing: false,
      enableGlobalFilter: false,
    }),
    columnHelper.accessor('product.name', {
      id: 'product',
      header: () => t('productType'),
      footer: (props) => props.column.id,
      size: 180,
      enableSorting: false,
      cell: ({ row }) => (
        <JobTypeGuard jobType={row.original.jobType}>
          <Stack direction="column" alignItems="start">
            <Typography variant="inherit">
              {row.original.product ? row.original.product.name : '---'}
            </Typography>
          </Stack>
        </JobTypeGuard>
      ),
      enableResizing: false,
      enableGlobalFilter: false,
    }),
    columnHelper.accessor('endTime', {
      header: () => t('dueDate'),
      footer: (props) => props.column.id,
      cell: (info) => <JobDueDateCell dueDate={info.getValue()} />,
      size: 150,
      enableResizing: false,
    }),
  ];

  const { instance, rows } = useESTableBasic(tourJobs, columns, {
    fitRowHeight: ROW_HEIGHT,
    manualPagination: true,
    enableColumnResizing: true,
    getRowId: (row) => row.jobId,
  });

  return (
    <ESTableWrapper>
      <ESDnDVirtualizedTable
        instance={instance}
        rows={rows}
        rowHeight={ROW_HEIGHT}
        dndRowsOptions={dndRowsOptions}
        applyRowStyles={(row) => {
          if (row.original.status === 'CANCELLED') {
            return {
              backgroundColor: theme.palette.warning.light,
              '&:hover': {
                backgroundColor: theme.palette.warning.light,
              },
            };
          }
        }}
      />
      {rows.length === 0 ? <NoTableData message={t('thereAreNoJobs')} /> : null}
      <TableRowsCount
        instance={instance}
        totalCountLabel={t('totalRowsCount')}
      />
    </ESTableWrapper>
  );
};
