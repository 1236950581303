import {
  ESTooltip,
  formatDateTime,
  timeAgoFromDate,
} from '@energy-stacks/shared';
import { Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { PingTimer } from './PingTimer';

const NO_VALUE = 'N/A';

interface ChargingStationDetailsValueAndTooltipProps {
  chargingStationDetailsValue: string;
  chargingStationDetailsLabel: string;
  testId?: number | string;
}

export const ChargingStationDetailsValueAndTooltip = ({
  chargingStationDetailsValue,
  chargingStationDetailsLabel,
  testId,
}: ChargingStationDetailsValueAndTooltipProps) => {
  const [t] = useTranslation('chargingStations');

  const getFieldValueAndTooltip = (label: string, value: string) => {
    if (label === 'dateLastBoot' && value !== NO_VALUE) {
      return (
        <ESTooltip title={formatDateTime(value)} placement="left">
          {timeAgoFromDate(value)}
        </ESTooltip>
      );
    }

    if (label === 'dateLastHeartBeat' && value !== NO_VALUE) {
      return <PingTimer date={value} />;
    }

    if (label === 'dateLastWebsocketPing' && value !== NO_VALUE) {
      return <PingTimer date={value} />;
    }

    if (label === 'ocppProtocol' && value !== NO_VALUE) {
      return t(value);
    }

    return value;
  };

  return (
    <Stack
      data-testid={`chargingStationDetailsCardField${testId}`}
      alignItems="center"
      flexDirection="row"
      justifyContent="space-between"
      sx={{
        borderTop: (theme) => `1px solid ${theme.palette.grey[100]}`,
        py: 2,
      }}
    >
      <Typography
        variant="body1"
        fontWeight={400}
        lineHeight={1.5}
        color="grey.900"
      >
        {t(chargingStationDetailsLabel)}
      </Typography>
      <Typography
        variant="body1"
        component={'span'}
        fontWeight={500}
        lineHeight={1.5}
        color="grey.900"
        sx={{
          textOverflow: 'ellipsis',
          overflow: 'hidden',
          maxWidth: 'calc(100% - 200px)',
        }}
      >
        {getFieldValueAndTooltip(
          chargingStationDetailsLabel,
          chargingStationDetailsValue
        )}
      </Typography>
    </Stack>
  );
};
