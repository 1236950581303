import { TariffModel } from '@energy-stacks/broker/feature-tariffs-data';
import { ESButton } from '@energy-stacks/core/ui';
import { formatDateTime } from '@energy-stacks/shared';
import { Box, Link, Stack, Typography } from '@mui/material';
import { IconCoinOff } from '@tabler/icons-react';
import { useTranslation } from 'react-i18next';
import { MouseEventHandler } from 'react';

interface ConnectorTariffsTabListViewProps {
  testId: string;
  onAssignTariff: () => void;
  onViewDetails: MouseEventHandler<HTMLAnchorElement>;
  tariff: TariffModel | undefined;
}

export const ConnectorTariffsTabListView = ({
  testId,
  onAssignTariff,
  onViewDetails,
  tariff,
}: ConnectorTariffsTabListViewProps) => {
  const { t } = useTranslation('tariffs');

  return (
    <>
      {tariff ? (
        <Box display="flex" justifyContent="space-between" pr={4}>
          <Stack data-testid={`tariff${testId}Name`}>
            <Typography
              variant="body2"
              fontWeight={400}
              marginBottom={1.5}
              color="grey.600"
            >
              {t('tariffName')}
            </Typography>
            <Typography variant="body2" color="grey.900" fontWeight={500}>
              {tariff.name ? tariff.name : 'N/A'}
            </Typography>
          </Stack>
          <Stack data-testid={`tariff${testId}Currency`}>
            <Typography
              variant="body2"
              fontWeight={400}
              marginBottom={1.5}
              color="grey.600"
            >
              {t('tariffCurrency')}
            </Typography>
            <Typography variant="body2" color="grey.900" fontWeight={500}>
              {tariff.currency ? tariff.currency : 'N/A'}
            </Typography>
          </Stack>

          <Stack data-testid={`tariff${testId}Type`}>
            <Typography
              variant="body2"
              fontWeight={400}
              marginBottom={1.5}
              color="grey.600"
            >
              {t('tariffType')}
            </Typography>
            <Typography variant="body2" color="grey.900" fontWeight={500}>
              {tariff.type ? tariff.type : 'N/A'}
            </Typography>
          </Stack>

          <Stack data-testid={`tariff${testId}Expires`}>
            <Typography
              variant="body2"
              fontWeight={400}
              marginBottom={1.5}
              color="grey.600"
            >
              {t('tariffExpires')}
            </Typography>
            <Typography variant="body2" color="grey.900" fontWeight={500}>
              {tariff.endDateTime ? formatDateTime(tariff.endDateTime) : '-'}
            </Typography>
          </Stack>

          <Stack data-testid={`tariff${testId}Elements`}>
            <Typography
              variant="body2"
              fontWeight={400}
              marginBottom={1.5}
              color="grey.600"
            >
              {t('tariffElements')}
            </Typography>
            <Link
              sx={{ cursor: 'pointer' }}
              underline="hover"
              onClick={onViewDetails}
            >
              {t('viewDetails')}
            </Link>
          </Stack>
        </Box>
      ) : (
        <Box
          display="flex"
          sx={{
            flex: 1,
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Stack gap={6} alignItems="center" direction="row">
            <Stack direction="row" alignItems="center">
              <IconCoinOff style={{ marginRight: 10 }} />
              <Typography>{t('noTariffsMessage')}</Typography>
            </Stack>

            <ESButton
              sx={{ display: 'flex' }}
              onClick={onAssignTariff}
              size="small"
            >
              {t('assignTariff')}
            </ESButton>
          </Stack>
        </Box>
      )}
    </>
  );
};
