import { createBaseQuery } from '@energy-stacks/shared';
import { createApi } from '@reduxjs/toolkit/query/react';
import { environment } from '@energy-stacks/feature-config';
import { AddAndEditLocationDto } from './addAndEditLocationDto';
import { BrokerLocationFormData } from './brokerLocationFormData';
import { mapAddAndEditFormDataToPayload } from './mapAddAndEditFormDataToPayload';
import { BrokerLocationDto } from './brokerLocationDto';
import { brokerLocationNormalizer } from './normalizers/brokerLocationNormalizer';
import { BrokerLocationModel } from './brokerLocationModel';
import { chargingStationsApi } from '@energy-stacks/broker/feature-charging-stations-data';

export const brokerLocationsApi = createApi({
  reducerPath: 'brokerLocationsApi',
  tagTypes: ['BrokerLocations'],
  baseQuery: createBaseQuery(`${environment.ocppServiceUrl}/locations`),
  endpoints: (builder) => ({
    getAllLocationsBroker: builder.query<BrokerLocationModel[], void>({
      query: () => '/',
      transformResponse: (
        response: BrokerLocationDto[]
      ): BrokerLocationModel[] => response.map(brokerLocationNormalizer),
      providesTags: ['BrokerLocations'],
    }),
    getLocationDetails: builder.query({
      query: (locationId) => `/${locationId}`,
      providesTags: (result) => [
        {
          type: 'BrokerLocations',
          id: result?.uuid,
        },
      ],
      transformResponse: (response: BrokerLocationDto): BrokerLocationModel =>
        brokerLocationNormalizer(response),
    }),
    addBrokerLocations: builder.mutation<
      AddAndEditLocationDto,
      BrokerLocationFormData
    >({
      query: (body) => ({
        url: '/',
        method: 'POST',
        body: mapAddAndEditFormDataToPayload(body),
      }),
      invalidatesTags: ['BrokerLocations'],
    }),
    editBrokerLocations: builder.mutation<
      AddAndEditLocationDto,
      BrokerLocationFormData
    >({
      query: (body) => ({
        url: `/${body.uuid}`,
        method: 'PUT',
        body: mapAddAndEditFormDataToPayload(body),
      }),
      invalidatesTags: ['BrokerLocations'],
    }),
    deleteBrokerLocation: builder.mutation<{ success: boolean }, string>({
      query: (uuid) => ({
        url: `/${uuid}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['BrokerLocations'],
    }),
    unassignChargingStation: builder.mutation<
      { success: boolean },
      { locationUuid: string; chargingStations: string[] }
    >({
      query: ({ locationUuid, chargingStations }) => ({
        url: `/${locationUuid}/chargingstations?identityKeys=${chargingStations.toString()}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['BrokerLocations'],

      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(
            chargingStationsApi.util.invalidateTags(['ChargingStations'])
          );
        } catch {}
      },
    }),
    assignChargingStation: builder.mutation<
      { success: boolean },
      { locationUuid: string; chargingStation: string }
    >({
      query: ({ locationUuid, chargingStation }) => ({
        url: `/${locationUuid}/chargingstations?identityKeys=${chargingStation}`,
        method: 'POST',
      }),
      invalidatesTags: ['BrokerLocations'],

      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(
            chargingStationsApi.util.invalidateTags(['ChargingStations'])
          );
        } catch {}
      },
    }),
  }),
});

export const {
  useGetAllLocationsBrokerQuery,
  useGetLocationDetailsQuery,
  useAddBrokerLocationsMutation,
  useEditBrokerLocationsMutation,
  useDeleteBrokerLocationMutation,
  useUnassignChargingStationMutation,
  useAssignChargingStationMutation,
} = brokerLocationsApi;
