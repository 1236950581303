import { TableFilterButton, stringEnumToArray } from '@energy-stacks/shared';

import {
  EventTypeModel,
  NetworkLogsEventTypeEnum,
} from '@energy-stacks/broker/feature-ocpp-network-logs-data';
import { Box, Divider, MenuItem, Radio, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface EventTypeFilterProps {
  eventTypeFilter: EventTypeModel | undefined;
  onEventTypeFilterChange: (
    eventTypeFilter: EventTypeModel | undefined
  ) => void;
}

export const EventTypeFilter: React.FC<EventTypeFilterProps> = ({
  eventTypeFilter,
  onEventTypeFilterChange,
}) => {
  const { t } = useTranslation('chargingStations');

  const options = stringEnumToArray(NetworkLogsEventTypeEnum).map((option) => {
    return {
      label: t(option),
      value: option as EventTypeModel,
    };
  });

  const handleFilterChange = (filter: EventTypeModel) => {
    onEventTypeFilterChange(filter);
  };

  return (
    <TableFilterButton
      testId="eventType"
      title={t('eventType')}
      renderPopover={(closePopover) => {
        return (
          <>
            <Box>
              {options.map((option) => (
                <MenuItem
                  data-testid={`eventTypeOption${option}`}
                  key={option.label}
                  value={option.value}
                  onClick={() => {
                    handleFilterChange(option.value);
                  }}
                >
                  <Radio
                    checked={option.value === eventTypeFilter}
                    value={option}
                  />
                  <Typography>{option.label}</Typography>
                </MenuItem>
              ))}
            </Box>

            <Divider />
          </>
        );
      }}
      isActive={Boolean(eventTypeFilter)}
    />
  );
};
