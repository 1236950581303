import { useCallback, useEffect, useRef, useState } from 'react';
import debounce from 'lodash-es/debounce';
import { Table } from '@tanstack/react-table';
import { DEFAULT_OPTIONS } from './usePagination';
import { DEFAULT_ROW_HEIGHT } from './ESTableBodyRow';
import { TABLE_HEADER_HEIGHT } from './ESTableHead';

const MAX_ALLOWED_ROWS = DEFAULT_OPTIONS[DEFAULT_OPTIONS.length - 1];

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function useFitRows(
  instance: Table<any>,
  totalDataCount: number,
  // Default height of material-ui table row
  // Always apply fixed height to row and pass it here to not have the fragmentation at the bottom of the table
  rowHeight = DEFAULT_ROW_HEIGHT,
  tableHeaderHeight = TABLE_HEADER_HEIGHT
) {
  const tableBodyRef = useRef<HTMLElement | null>(null);

  const pageSizePreselected = useRef(false);
  const [rowsPerPageOptions, setRowsPerPageOptions] = useState(DEFAULT_OPTIONS);

  const fitTableToWindowHeight = useCallback(() => {
    if (!tableBodyRef.current) {
      return;
    }

    const usableTableBodyHeight =
      tableBodyRef.current.getBoundingClientRect().height - tableHeaderHeight;

    if (usableTableBodyHeight <= 0) {
      return;
    }

    const maxRowsNonBounded = Math.ceil(usableTableBodyHeight / rowHeight); // might be a large value, limit it to prevent render issues on huge vertical screens
    const maxRows = Math.min(Math.max(maxRowsNonBounded, 2), MAX_ALLOWED_ROWS);

    let options = DEFAULT_OPTIONS;

    if (totalDataCount > 0) {
      /**
       * Calculate custom pagination only if there are more rows in data than the theoretical maximum rows. This
       * prevents showing arbitrary pagination values such as 13 when there are only 6 rows available in the data.
       */
      if (maxRows > DEFAULT_OPTIONS[0] && totalDataCount > maxRows) {
        options = [maxRows].concat(
          DEFAULT_OPTIONS.filter((option) => option > maxRows)
        );
      }

      if (
        instance.getPaginationRowModel().flatRows.length ===
        rowsPerPageOptions[0]
      ) {
        setRowsPerPageOptions(options); // required to update the paginator, but does not introduce an
        instance.setPageSize(options[0]); // side effect
      }
    }
  }, [
    instance,
    rowHeight,
    rowsPerPageOptions,
    tableHeaderHeight,
    totalDataCount,
  ]);

  /**
   * Did not wrap function in useCallback because useCallback can't figure out the
   * dependencies when callback is wrapped with 'debounce'. Not a perf issue since
   * there are no heavy computations, just simple function reference passing.
   */
  const handleWindowResize = debounce(fitTableToWindowHeight, 200);

  // re-fit table each time window is resized
  useEffect(() => {
    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, [handleWindowResize]);

  useEffect(() => {
    const tableBody = document.getElementById('tableContainerRef');

    if (tableBody) {
      tableBodyRef.current = tableBody;
    }
  }, [fitTableToWindowHeight]);

  useEffect(() => {
    if (pageSizePreselected.current) {
      return;
    }
    fitTableToWindowHeight();
    pageSizePreselected.current = true;
  }, [fitTableToWindowHeight]);

  return {
    rowsPerPageOptions,
  };
}
