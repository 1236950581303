import { useESSnackbar } from '@energy-stacks/core/ui';
import {
  chargingStationApiErrors,
  useDeleteChargingStationMutation,
} from '@energy-stacks/broker/feature-charging-stations-data';
import { ConfirmDeleteDialog } from '@energy-stacks/shared';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useNavigate } from 'react-router-dom';

export const DeleteChargingStation = () => {
  const { identityKey } = useParams<{ identityKey: string }>();
  const [t] = useTranslation('chargingStations');
  const { showSnackbar } = useESSnackbar();
  const navigate = useNavigate();
  const [deleteChargingStation] = useDeleteChargingStationMutation();

  const handleCloseDialog = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const handleDeleteChargingStation = useCallback(() => {
    if (!identityKey) {
      return;
    }
    return deleteChargingStation(identityKey)
      .unwrap()
      .then(() => {
        showSnackbar(
          'success',
          'deleteChargingStationSuccess',
          'chargingStations'
        );
        handleCloseDialog();
      })
      .catch((error) => {
        showSnackbar(
          'error',
          chargingStationApiErrors[error.data?.errorCode],
          'chargingStations'
        );
      });
  }, [deleteChargingStation, identityKey, showSnackbar, handleCloseDialog]);

  return (
    <ConfirmDeleteDialog
      title={t('deleteChargingStationConfirmTitle')}
      description={t('deleteChargingStationConfirmMessage')}
      onCancel={handleCloseDialog}
      onConfirm={handleDeleteChargingStation}
    />
  );
};
