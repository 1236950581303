import { getHours, getMinutes } from 'date-fns';

export const mapWorkingDateToWorkingTime = (workingDate: Date | null) => {
  if (!workingDate) {
    return '00:00';
  }
  return `${formatTime(getHours(workingDate))}:${formatTime(
    getMinutes(workingDate)
  )}`;
};

const formatTime = (time: number) => {
  return time < 10 ? `0${time}` : `${time}`;
};
