import { createBaseQuery } from '@energy-stacks/shared';
import { createApi } from '@reduxjs/toolkit/query/react';
import { environment } from '@energy-stacks/feature-config';
import { TariffModel } from './TariffModel';
import { TariffDto } from './TariffDto';
import { tariffNormalizer } from './tariffNormalizer';
import { AddTariffFormData } from './addTariffFormData';
import { mapTariffDataToPayload } from './mapTariffFormDataToPayload';
import { EditTariffFormData } from './editTariffFormData';

type EditTariffData = {
  formData: EditTariffFormData;
  tariffId: string;
};

export const tariffsApi = createApi({
  reducerPath: 'brokerTariffsApi',
  tagTypes: ['Tariffs'],
  baseQuery: createBaseQuery(`${environment.ocppServiceUrl}/tariffs`),
  endpoints: (builder) => ({
    getTariffs: builder.query<TariffModel[], void>({
      query: () => `/`,
      providesTags: ['Tariffs'],
      transformResponse: (tariffs: TariffDto[]) =>
        tariffs.map(tariffNormalizer),
    }),
    getTariff: builder.query<TariffModel, string>({
      query: (tariffId) => `/${tariffId}`,
      providesTags: (result) => [
        {
          type: 'Tariffs',
          id: result?.id,
        },
      ],
      transformResponse: (response: TariffDto) => tariffNormalizer(response),
    }),
    addTariff: builder.mutation<TariffDto, AddTariffFormData>({
      query: (tariffFormData) => ({
        url: `/`,
        method: 'POST',
        body: mapTariffDataToPayload(tariffFormData),
      }),
      invalidatesTags: ['Tariffs'],
    }),
    editTariff: builder.mutation<void, EditTariffData>({
      query: (body) => ({
        // We don't have a way to dynamically send a "providerId" which is the mix
        // of country_code and party_id so it's hardcoded for now
        url: `/${body.tariffId}/DE*ERG`,
        method: 'PUT',
        body: mapTariffDataToPayload(body.formData),
      }),
      invalidatesTags: ['Tariffs'],
    }),
  }),
});

export const {
  useGetTariffsQuery,
  useGetTariffQuery,
  useAddTariffMutation,
  useEditTariffMutation,
} = tariffsApi;
