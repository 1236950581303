import { environment } from '@energy-stacks/feature-config';
import { createApi } from '@reduxjs/toolkit/query/react';
import { appendZToDateString, createBaseQuery } from '@energy-stacks/shared';
import {
  MobilityTokenDto,
  MobilityTokenTypeDto,
  WhitelistDto,
} from './mobilityTokensDto';
import { MobilityTokenModel } from './mobilityTokenModel';
import { mobilityTokenTypeNormalizer } from './normalizers/mobilityTokenTypeNormalizer';
import { mobilityTokenWithelistNormalizer } from './normalizers/mobilityTokenWhitelistNormalizer';

export interface MobilityTokenFormData {
  type: MobilityTokenTypeDto;
  issuer: string;
  whitelist: WhitelistDto;
  uid: string;
  country_code: string;
  party_id: string;
  visual_number: string;
  valid: boolean;
  expiry_date: string | undefined;
}

export const mobilityTokensApi = createApi({
  reducerPath: 'mobilityTokensApi',
  tagTypes: ['MobilityTokens'],
  baseQuery: createBaseQuery(`${environment.ocppServiceUrl}/tokens`),
  endpoints: (builder) => ({
    getMobilityTokens: builder.query<MobilityTokenModel[], void>({
      query: () => '/',
      providesTags: ['MobilityTokens'],
      transformResponse: (mobilityTokens: MobilityTokenDto[]) => {
        return mobilityTokens.map((mobilityToken) => ({
          type: mobilityTokenTypeNormalizer(mobilityToken.type),
          issuer: mobilityToken.issuer ?? '',
          whitelist: mobilityTokenWithelistNormalizer(mobilityToken.whitelist),
          uid: mobilityToken.uid ?? '',
          countryCode: mobilityToken.country_code ?? '',
          partyId: mobilityToken.party_id ?? '',
          contractId: mobilityToken.contract_id ?? '',
          visualNumber: mobilityToken.visual_number ?? '',
          groupId: mobilityToken.group_id ?? '',
          valid: mobilityToken.valid ?? '',
          lastUpdated: appendZToDateString(mobilityToken.last_updated),
          expiryDate: appendZToDateString(mobilityToken.expiry_date) ?? '',
        }));
      },
    }),
    addMobilityToken: builder.mutation<
      MobilityTokenModel,
      MobilityTokenFormData
    >({
      query: (body) => ({
        url: '/',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['MobilityTokens'],
    }),
    editMobilityToken: builder.mutation<
      MobilityTokenModel,
      Omit<MobilityTokenFormData, 'type'>
    >({
      query: (body) => ({
        url: `/${body.uid}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['MobilityTokens'],
    }),
  }),
});

export const {
  useGetMobilityTokensQuery,
  useAddMobilityTokenMutation,
  useEditMobilityTokenMutation,
} = mobilityTokensApi;
