import {
  TourModel,
  useGetTourDetailsQuery,
} from '@energy-stacks/fleet/feature-tours-data';
import { useParams } from 'react-router-dom';

export const useTourDetails = () => {
  const { tourId } = useParams<{
    tourId: TourModel['tourId'];
  }>();
  const { data, ...other } = useGetTourDetailsQuery(tourId ?? '');

  return {
    tourDetails: data,
    ...other,
  };
};
