import { useGetWebsocketUrlQuery } from '@energy-stacks/broker/feature-settings-data';
import { showSnackbar, usePopover } from '@energy-stacks/core/ui';
import { Box, IconButton, Popover, Typography } from '@mui/material';
import { IconHomeLink } from '@tabler/icons-react';
import React, { useState } from 'react';
import ContentCopyRoundedIcon from '@mui/icons-material/ContentCopyRounded';
import CheckIcon from '@mui/icons-material/Check';
import { ESTooltip } from '@energy-stacks/shared';
import { useTranslation } from 'react-i18next';

export const TableWebsocketUrl = () => {
  const { anchorEl, openPopover, closePopover, popoverOpen } = usePopover();
  const { data: websocketUrl } = useGetWebsocketUrlQuery();
  const [isTextCopied, setIsTextCopied] = useState(false);
  const [t] = useTranslation('chargingStations');

  return (
    <>
      <ESTooltip placement="top" title={t('websocketUrl')}>
        <IconButton onClick={openPopover}>
          <IconHomeLink />
        </IconButton>
      </ESTooltip>
      <Popover
        open={popoverOpen}
        anchorEl={anchorEl}
        onClose={() => {
          closePopover();
          setIsTextCopied(false);
        }}
        anchorPosition={{
          top: anchorEl ? anchorEl.getBoundingClientRect().bottom + 5 : 0,
          left: anchorEl ? anchorEl.getBoundingClientRect().left : 0,
        }}
        PaperProps={{
          sx: {
            boxShadow: '0px 2px 1px -1px rgba(56, 67, 107, 0.2)',
          },
        }}
        anchorReference="anchorPosition"
      >
        <Box paddingX={4} paddingY={2} display="flex" alignItems="center">
          <Typography>{websocketUrl}</Typography>
          <IconButton
            sx={{ ml: 2 }}
            disabled={isTextCopied}
            onClick={() => {
              navigator.clipboard.writeText(websocketUrl || '');
              setIsTextCopied(true);
              showSnackbar(
                'success',
                'copyToClipboardSuccess',
                'chargingStations'
              );
            }}
          >
            {isTextCopied ? (
              <CheckIcon color="success" />
            ) : (
              <ContentCopyRoundedIcon />
            )}
          </IconButton>
        </Box>
      </Popover>
    </>
  );
};
