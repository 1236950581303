import { SkippedJobDto } from '../optimizeToursWebResponse';
import { SkippedJob } from '../optimizedTourModel';

export const skippedJobsNormalizer = (
  skippedJobsDto: SkippedJobDto[]
): SkippedJob[] => {
  return skippedJobsDto.map((skippedJob) => ({
    jobId: skippedJob.jobUid,
    reasons: skippedJob.reasons,
  }));
};
