import React from 'react';
import { Box, Paper } from '@mui/material';

export const ListItemContainer: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  return (
    <Paper
      variant="elevation"
      elevation={0}
      sx={{ border: 1, borderColor: 'divider' }}
    >
      <Box
        px={4}
        py={6}
        display="table"
        width="100%"
        sx={{
          tableLayout: 'fixed',
          wordBreak: 'break-word',
        }}
      >
        {children}
      </Box>
    </Paper>
  );
};

export const ListItemCell: React.FC<{
  children: React.ReactNode;
  textAlign?: 'start' | 'center' | 'end';
}> = ({ children, textAlign }) => {
  return (
    <Box
      display="table-cell"
      width="33.33%"
      sx={{ verticalAlign: 'middle' }}
      textAlign={textAlign}
    >
      {children}
    </Box>
  );
};
