import {
  DEFAULT_PAGINATION_CONFIG,
  DEFAULT_SORT_CONFIG,
  SortingOrderModel,
} from '@energy-stacks/core/ui';
import {
  useGetOcppMessageLogsQuery,
  mapOcppActionToOcppProcedure,
  OcppAction,
  MessageDirectionEnum,
  MessageTypeEnum,
} from '@energy-stacks/broker/feature-ocpp-message-logs-data';
import {
  toPayloadDateBroker,
  useChargingStationIdentityKey,
} from '@energy-stacks/shared';

interface GetOcppMessageLogsConfig {
  filterDate: {
    timeFrom?: Date;
    timeTo?: Date;
  };
  pagination: {
    page: number;
    size: number;
  };
  messageDirection?: MessageDirectionEnum[];
  messageType?: MessageTypeEnum[];
  sort: SortingOrderModel;
  ocppAction?: OcppAction[];
}

export const useOcppMessageLogs = (config?: GetOcppMessageLogsConfig) => {
  const identityKey = useChargingStationIdentityKey();

  const {
    data: ocppMessageLogs,
    isSuccess,
    isLoading,
    isError,
    refetch,
    isFetching,
  } = useGetOcppMessageLogsQuery(
    {
      identityKey: identityKey,
      timeFrom: config?.filterDate.timeFrom
        ? toPayloadDateBroker(config?.filterDate.timeFrom)
        : undefined,
      timeTo: config?.filterDate.timeTo
        ? toPayloadDateBroker(config?.filterDate.timeTo)
        : undefined,
      messageDirections: config?.messageDirection,
      messageTypes: config?.messageType ?? undefined,
      page: config?.pagination?.page ?? DEFAULT_PAGINATION_CONFIG.page,
      size: config?.pagination?.size ?? DEFAULT_PAGINATION_CONFIG.size,
      sort: { order: config?.sort.order ?? DEFAULT_SORT_CONFIG.order },
      procedureNames: config?.ocppAction
        ? mapOcppActionToOcppProcedure(config.ocppAction)
        : undefined,
    },
    {
      skip: !identityKey,
      refetchOnMountOrArgChange: true,
    }
  );

  return {
    ocppMessageLogs,
    isSuccess,
    isError,
    isLoading,
    refetch,
    isFetching,
  };
};
