import {
  effect as chargingStationsListenerEffect,
  matcher as chargingStationsListenerMatcher,
} from '@energy-stacks/broker/feature-charging-stations-data';
import { createListenerMiddleware } from '@reduxjs/toolkit';

export const listenerMiddleware = createListenerMiddleware();

listenerMiddleware.startListening({
  matcher: chargingStationsListenerMatcher,
  effect: chargingStationsListenerEffect,
});
