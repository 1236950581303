import {
  ESButton,
  ESDialogActions,
  ESDialogContent,
  ESDialogTitle,
} from '@energy-stacks/core/ui';
import { Dialog, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface ConfirmCloseDialogProps {
  open: boolean;
  onStay: () => void;
  onLeaveWithoutSaving: () => void;
  description?: string;
  leaveButtonText?: string;
  cancelButtonText?: string;
  testId?: string;
  confirmCloseDialogTitle?: string;
}

export const ConfirmCloseDialog: React.FC<ConfirmCloseDialogProps> = ({
  open,
  onStay,
  onLeaveWithoutSaving,
  testId,
  description,
  leaveButtonText,
  cancelButtonText,
  confirmCloseDialogTitle,
}) => {
  const { t } = useTranslation('shared');

  return (
    <Dialog open={open} fullWidth maxWidth={'sm'}>
      <ESDialogTitle data-testid={`${testId}ConfirmCloseDialogTitle`}>
        {confirmCloseDialogTitle || t('closeConfirmTitle')}
      </ESDialogTitle>
      <ESDialogContent data-testid={`${testId}ConfirmCloseDialogContent`}>
        <Typography variant="body1">
          {description || t('closeConfirmContent')}
        </Typography>
      </ESDialogContent>
      <ESDialogActions>
        <ESButton
          variant="text"
          color="error"
          onClick={onStay}
          data-testid={`${testId}ConfirmCloseCancelButton`}
        >
          {cancelButtonText || t('stay')}
        </ESButton>
        <ESButton
          color="error"
          onClick={onLeaveWithoutSaving}
          data-testid={`${testId}ConfirmCloseLeaveButton`}
        >
          {leaveButtonText || t('leaveWithoughtSaving')}
        </ESButton>
      </ESDialogActions>
    </Dialog>
  );
};
