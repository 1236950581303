import {
  MoreOptionsMenu,
  useAppLocation,
  useDocumentTitle,
} from '@energy-stacks/shared';
import { useTranslation } from 'react-i18next';
import { TourDetails, TourInfo } from './TourDetails';
import { Stack } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { ESMenuItem } from '@energy-stacks/core/ui';
import { TourInfoBanner } from './TourInfoBanner';
import {
  TourDetailsViewMode,
  TourDetailsViewModeButton,
} from './TourDetailsViewModeButton';
import { SkippedJob } from '@energy-stacks/fleet/feature-tours-data';
import { EditTourMenuItem } from '../edit-tour/EditTourMenuItem';
import {
  TourDetailsJobsTableViewMode,
  TourDetailsJobsTableViewModeToggle,
} from './TourDetailsJobsTableViewModeToggle';

export const TourDetailsPage = ({
  onEnterEditMode,
  tourInfo,
}: {
  onEnterEditMode: () => void;
  tourInfo: TourInfo;
}) => {
  const navigate = useNavigate();
  const location = useAppLocation<{ skippedJobs: SkippedJob[] }>();
  const [t] = useTranslation('tours');
  useDocumentTitle(t('pageTitle'));
  const [viewMode, setViewMode] = useState<TourDetailsViewMode>('table');
  const [tableViewMode, setTableViewMode] =
    useState<TourDetailsJobsTableViewMode>('standard');

  return (
    <Stack sx={{ minHeight: '100%' }} gap={4}>
      <Stack direction="column" gap={6}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent={'flex-end'}
          gap={4}
        >
          {viewMode === 'table' ? (
            <TourDetailsJobsTableViewModeToggle
              onTableViewModeChange={setTableViewMode}
            />
          ) : null}
          <TourDetailsViewModeButton onViewModeChange={setViewMode} />
        </Stack>
        <TourInfoBanner
          tourInfo={tourInfo}
          Actions={() => (
            <MoreOptionsMenu>
              <EditTourMenuItem
                onEnterEditMode={onEnterEditMode}
                status={tourInfo.tourStatus || 'PLANNED'}
              />
              <ESMenuItem
                onClick={() =>
                  navigate('delete', { state: { background: location } })
                }
              >
                {t('deleteTourCTA')}
              </ESMenuItem>
            </MoreOptionsMenu>
          )}
        />
      </Stack>
      <TourDetails
        viewMode={viewMode}
        tableViewMode={tableViewMode}
        tourInfo={tourInfo}
      />
    </Stack>
  );
};
