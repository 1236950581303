import { TableBody, TableBodyProps } from '@mui/material';

interface ESTableBodyProps extends TableBodyProps {
  children: React.ReactNode;
}

export const ESTableBodyCompose: React.FC<ESTableBodyProps> = ({
  children,
  ...other
}) => {
  return (
    <TableBody id="tableBodyRef" {...other}>
      {children}
    </TableBody>
  );
};
