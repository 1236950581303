import { useState } from 'react';
import { TourInfo } from '../tour-details/TourDetails';
import { ToursListItem } from './ToursListItem';

type ToursListProps = {
  onTourFocus: (tour: TourInfo) => void;
  tours: TourInfo[];
  focusedTour?: TourInfo;
};

export const ToursList = ({
  onTourFocus,
  tours,
  focusedTour,
}: ToursListProps) => {
  const [hoveredItemIndex, setHoveredItemIndex] = useState<
    number | undefined
  >();
  return (
    <div
      onMouseLeave={() => setHoveredItemIndex(undefined)}
      style={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100%',
        paddingTop: 2,
        paddingBottom: 6,
        overflowY: 'auto',
        overflowX: 'hidden',
      }}
    >
      {tours.map((tour, index) => (
        <ToursListItem
          key={tour.tourIndex}
          onMouseEnter={() => setHoveredItemIndex(index)}
          onClick={() => {
            onTourFocus(tour);
          }}
          isHovered={hoveredItemIndex === index}
          isFocused={tour.tourIndex === focusedTour?.tourIndex}
          index={index}
          tour={tour}
        />
      ))}
    </div>
  );
};
