import { ConfigurationTableModel201 } from '@energy-stacks/broker/feature-charging-station-management-data';
import { ConfigurationBooleanValueField } from './ConfigurationBooleanValueField';
import { Row } from '@tanstack/react-table';
import { Configuration201FormData } from './ConfigurationTable201';
import { Control, Controller } from 'react-hook-form';
import { ConfigurationIntegerValueField } from './ConfigurationIntegerValueField';
import { ConfigurationDecimalValueField } from './ConfigurationDecimalValueField';
import { ConfigurationStringValueField } from './ConfigurationStringValueField';
import { ConfigurationSequenceListValueField } from './ConfigurationSequenceListValueField';
import { ConfigurationMemberListValueField } from './ConfigurationMemberListValueField';
import { ConfigurationOptionListValueField } from './ConfigurationOptionListValueField';
import { ConfigurationDateTimeValueField } from './ConfigurationDateTimeValueField';
import { upperFirst } from 'lodash-es';

interface ConfigurationValueFieldProps {
  row: Row<ConfigurationTableModel201>;
  control: Control<Configuration201FormData, object>;
  index: number;
}

export const ConfigurationValueField: React.FC<
  ConfigurationValueFieldProps
> = ({ row, control, index, ...rest }) => {
  if (row.original.variableAttribute.mutability !== 'ReadOnly') {
    return (
      <Controller
        control={control}
        render={({ field: { onChange, value } }) => {
          const handleValueChange = (newValue: string) => {
            onChange(newValue);
          };
          if (row.original.variableCharacteristics.dataType === 'boolean') {
            return (
              <ConfigurationBooleanValueField
                handleValueChange={handleValueChange}
                formValue={row?.original.variableAttribute.value}
                controlerValue={value || ''}
              />
            );
          }
          if (row.original.variableCharacteristics.dataType === 'integer') {
            return (
              <ConfigurationIntegerValueField
                handleValueChange={handleValueChange}
                controlerValue={value}
                minLimit={row.original.variableCharacteristics.minLimit}
                maxLimit={row.original.variableCharacteristics.maxLimit}
              />
            );
          }
          if (row.original.variableCharacteristics.dataType === 'decimal') {
            return (
              <ConfigurationDecimalValueField
                handleValueChange={handleValueChange}
                controlerValue={value}
              />
            );
          }
          if (row.original.variableCharacteristics.dataType === 'string') {
            return (
              <ConfigurationStringValueField
                handleValueChange={handleValueChange}
                controlerValue={value}
                maxLimit={row.original.variableCharacteristics.maxLimit}
              />
            );
          }
          if (
            row.original.variableCharacteristics.dataType === 'SequenceList'
          ) {
            return (
              <ConfigurationSequenceListValueField
                valuesList={row.original.variableCharacteristics.valuesList}
                controlerValue={value}
                handleValueChange={handleValueChange}
              />
            );
          }
          if (row.original.variableCharacteristics.dataType === 'MemberList') {
            return (
              <ConfigurationMemberListValueField
                valuesList={row.original.variableCharacteristics.valuesList}
                controlerValue={value}
                handleValueChange={handleValueChange}
              />
            );
          }
          if (row.original.variableCharacteristics.dataType === 'OptionList') {
            return (
              <ConfigurationOptionListValueField
                valuesList={row.original.variableCharacteristics.valuesList}
                controlerValue={value}
                handleValueChange={handleValueChange}
              />
            );
          }
          if (row.original.variableCharacteristics.dataType === 'dateTime') {
            return (
              <ConfigurationDateTimeValueField
                handleValueChange={handleValueChange}
                controlerValue={value || ''}
              />
            );
          }
          return <div>{row.original.variableAttribute.value || '-'}</div>;
        }}
        name={`setVariableData.${index}.attributeValueNew`}
      />
    );
  }

  return (
    <div>
      {capitalizeBooleanValue(row.original.variableAttribute.value) || '-'}
    </div>
  );
};

function capitalizeBooleanValue(value: string) {
  if (value === 'true' || value === 'false') {
    return upperFirst(value);
  }
  return value;
}
