import { JobType } from '@energy-stacks/fleet/feature-jobs-data';
import { useTheme } from '@mui/material';
import { IconArrowLeftRhombus } from '@tabler/icons-react';
import { IconArrowRightRhombus } from '@tabler/icons-react';

interface JobTypeIconProps {
  jobType: JobType;
}

export const JobTypeIcon: React.FC<JobTypeIconProps> = ({ jobType }) => {
  const { palette } = useTheme();

  return jobType === 'OUTBOUND' ? (
    <IconArrowLeftRhombus style={{ color: palette.grey[500] }} />
  ) : (
    <IconArrowRightRhombus style={{ color: palette.grey[500] }} />
  );
};
