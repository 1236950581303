import { AddVehicleFormData } from '@energy-stacks/fleet/feature-vehicles-data';
import {
  Box,
  Divider,
  InputAdornment,
  Switch,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { DayWorkingHours } from '@energy-stacks/fleet/feature-vehicles';
import { useFormContext } from 'react-hook-form';
import { ESTextField } from '@energy-stacks/core/ui';
import { useMondayToFridayChange } from '@energy-stacks/fleet/feature-vehicles';

export const AddVehicleOperationalInfo = () => {
  const [t] = useTranslation('vehicles');
  const [isPerDay, setIsPerDay] = useState(false);
  const {
    control,
    getValues,
    formState: { errors },
    trigger,
  } = useFormContext();
  const mondayValues = getValues('workingHours.regular.MONDAY');
  const { handleMondayToFridayChange } = useMondayToFridayChange();

  return (
    <Box sx={{ m: 6, mr: 'unset' }}>
      <Typography
        variant="h5"
        sx={{
          color: 'text.dark',
          fontWeight: 500,
          mb: 6,
        }}
      >
        {t('addVehicleSectionTitles.workingHours')}
      </Typography>
      <Box
        sx={{
          alignItems: 'center',
          backgroundColor: 'grey.100',
          borderRadius: 2,
          display: 'flex',
          flexDirection: 'row',
          mb: 6,
          p: 2,
          width: '100%',
        }}
      >
        <Switch
          checked={isPerDay}
          onChange={() => {
            handleMondayToFridayChange(mondayValues.startTime, 'startTime');
            handleMondayToFridayChange(mondayValues.endTime, 'endTime');
            setIsPerDay((prev) => !prev);
          }}
          sx={{
            '.MuiSwitch-track': {
              opacity: isPerDay ? 0.5 : 0.6,
            },
          }}
        />
        <Typography variant="inherit">
          {t('workingHoursSwitchLabel')}
        </Typography>
      </Box>
      <Controller
        control={control}
        render={({ field: { value } }) => {
          const days = Object.keys(value) as Array<
            keyof AddVehicleFormData['workingHours']['regular']
          >;
          return !isPerDay ? (
            <>
              <DayWorkingHours day="mondayToFriday" />
              <DayWorkingHours day="SATURDAY" />
              <DayWorkingHours day="SUNDAY" />
            </>
          ) : (
            <>
              {days.map((day) => {
                return <DayWorkingHours key={day} day={day} />;
              })}
            </>
          );
        }}
        name="workingHours.regular"
      />
      <Divider />
      <Typography
        variant="h5"
        sx={{
          color: 'text.dark',
          fontWeight: 500,
          my: 6,
        }}
      >
        {t('addVehicleSectionTitles.moSoc')}
      </Typography>
      <Box width={373}>
        <Controller
          control={control}
          render={({ field: { onBlur, onChange, value } }) => {
            return (
              <ESTextField
                testId="addVehicleMoSoc"
                required
                label={t('addVehicleMoSocLabel')}
                InputProps={{
                  endAdornment: (
                    <InputAdornment
                      position="end"
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        height: 30,
                      }}
                    >
                      <Divider
                        orientation="vertical"
                        variant="middle"
                        flexItem
                      />
                      <Typography variant="h5" sx={{ mx: 2 }}>
                        %
                      </Typography>
                    </InputAdornment>
                  ),
                }}
                value={value}
                onBlur={onBlur}
                onChange={(event) => {
                  const isValid =
                    !isNaN(Number(event?.target.value)) &&
                    !event?.target.value.includes('.');
                  if (event === undefined || isValid) {
                    onChange(event);
                    trigger('moSoc');
                  }
                }}
                error={Boolean(errors.moSoc)}
                helperText={
                  Boolean(errors.moSoc) && t(`${errors.moSoc?.message}`)
                }
              />
            );
          }}
          name="moSoc"
        />
      </Box>
    </Box>
  );
};
