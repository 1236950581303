import * as React from 'react';
import { useTranslation } from 'react-i18next';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import i18next from 'i18next';
import { IconLanguage } from '@tabler/icons-react';
import { IconButton, Typography, useTheme } from '@mui/material';
import { BerryThemeProvider } from '@energy-stacks/core/berry-mui';
import { usePopover } from '@energy-stacks/core/ui';

const languages = ['de', 'en'] as const;
type Language = (typeof languages)[number];
type ThemeMode = 'light' | 'dark';

const handleChangeLanguage = (lng: Language) => {
  i18next.changeLanguage(lng);
};
interface LanguageSwitcherProps {
  collapsed: boolean;
  themeMode: ThemeMode;
  supportedLanguages: Language[];
}

export const LanguageSwitcher: React.FC<LanguageSwitcherProps> = ({
  collapsed,
  themeMode,
  supportedLanguages,
}) => {
  const [t] = useTranslation('shared');
  const theme = useTheme();
  const { anchorEl, openPopover, closePopover, popoverOpen } = usePopover();
  const [selectedLanguage, setSelectedLanguage] = React.useState<Language>(
    i18next.language as Language
  );

  return (
    <>
      <BerryThemeProvider mode={themeMode}>
        <Menu
          id="menu-lang"
          anchorEl={anchorEl}
          disableAutoFocusItem
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          sx={{
            marginTop: -2,
          }}
          keepMounted
          open={popoverOpen}
          onClose={closePopover}
        >
          {supportedLanguages.map((lang) => {
            return (
              <MenuItem
                key={lang}
                color={theme.palette.grey[600]}
                onClick={() => {
                  handleChangeLanguage(lang);
                  setSelectedLanguage(lang);
                  closePopover();
                }}
                sx={
                  lang === selectedLanguage
                    ? {
                        textDecoration: 'underline',
                        textUnderlineOffset: 3,
                        color: theme.palette.primary.main,
                        pointerEvents: 'none',
                      }
                    : {}
                }
                data-testid={`${lang}LanguageSwitcherOption`}
              >
                {t(lang)}
              </MenuItem>
            );
          })}
        </Menu>
      </BerryThemeProvider>
      <IconButton
        size="large"
        aria-label="menu"
        sx={(theme) => ({
          color: 'dark.light',
          borderRadius: '8px',
          padding: `${collapsed ? '16px 12px' : '6px 12px'}`,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          ':hover': {
            backgroundColor: theme.palette.background.paper,
          },
          gap: '6px',
        })}
        onClick={openPopover}
        data-testid="languageSwitcherButton"
      >
        {collapsed ? (
          <IconLanguage />
        ) : (
          <>
            <IconLanguage />
            <Typography>{selectedLanguage.toUpperCase()}</Typography>
          </>
        )}
      </IconButton>
    </>
  );
};
