import { ProcedureName } from '../ocppMessageLogEntry';
import { OcppAction } from '../ocppMessageLogsModel';

export const ocppActionNormalizer = (
  procedureName: ProcedureName
): OcppAction => {
  return ocppActionMap[procedureName];
};

const ocppActionMap: Record<ProcedureName, OcppAction> = {
  AUTHORIZE: 'authorize',
  BOOT_NOTIFICATION: 'bootNotification',
  CANCEL_RESERVATION: 'cancelReservation',
  CHANGE_AVAILABILITY: 'changeAvailability',
  CHANGE_CONFIGURATION: 'changeConfiguration',
  CLEAR_CACHE: 'clearCache',
  CLEAR_CHARGING_PROFILE: 'clearChargingProfile',
  DATA_TRANSFER: 'dataTransfer',
  DIAGNOSTICS_STATUS_NOTIFICATION: 'diagnosticsStatusNotification',
  FIRMWARE_STATUS_NOTIFICATION: 'firmwareStatusNotification',
  GET_CONFIGURATION: 'getConfiguration',
  GET_DIAGNOSTICS: 'getDiagnostics',
  GET_LOCAL_LIST_VERSION: 'getLocalListVersion',
  HEARTBEAT: 'heartbeat',
  METER_VALUES: 'meterValues',
  REMOTE_START_TRANSACTION: 'remoteStartTransaction',
  REMOTE_STOP_TRANSACTION: 'remoteStopTransaction',
  RESERVE_NOW: 'reserveNow',
  RESET: 'reset',
  SEND_LOCAL_LIST: 'sendLocalList',
  SET_CHARGING_PROFILE: 'setChargingProfile',
  START_TRANSACTION: 'startTransaction',
  STOP_TRANSACTION: 'stopTransaction',
  STATUS_NOTIFICATION: 'statusNotification',
  UNLOCK_CONNECTOR: 'unlockConnector',
  UPDATE_FIRMWARE: 'updateFirmware',
  TRIGGER_MESSAGE: 'triggerMessage',
  GET_COMPOSITE_SCHEDULE: 'getCompositeSchedule',
  GET_15118_EV_CERTIFICATE: 'get15118EVCertificate',
  GET_CERTIFICATE_STATUS: 'getCertificateStatus',
  GET_BASE_REPORT: 'getBaseReport',
  GET_LOG: 'getLog',
  LOG_STATUS_NOTIFICATION: 'logStatusNotification',
  NOTIFY_CHARGING_LIMIT: 'notifyChargingLimit',
  NOTIFY_CUSTOMER_INFORMATION: 'notifyCustomerInformation',
  NOTIFY_DISPLAY_MESSAGES: 'notifyDisplayMessages',
  NOTIFY_EV_CHARGING_NEEDS: 'notifyEVChargingNeeds',
  NOTIFY_EV_CHARGING_SCHEDULE: 'notifyEVChargingSchedule',
  NOTIFY_EVENT: 'notifyEvent',
  NOTIFY_MONITORING_REPORT: 'notifyMonitoringReport',
  NOTIFY_REPORT: 'notifyReport',
  REPORT_CHARGING_PROFILES: 'reportChargingProfiles',
  REQUEST_START_TRANSACTION: 'requestStartTransaction',
  REQUEST_STOP_TRANSACTION: 'requestStopTransaction',
  RESERVATION_STATUS_UPDATE: 'reservationStatusUpdate',
  SECURITY_EVENT_NOTIFICATION: 'securityEventNotification',
  SET_VARIABLES: 'setVariables',
  SIGN_CERTIFICATE: 'signCertificate',
  TRANSACTION_EVENT: 'transactionEvent',
};
