import { createApi } from '@reduxjs/toolkit/query/react';
import { createBaseQuery } from '@energy-stacks/shared';
import { environment } from '@energy-stacks/feature-config';
import { TourModel } from './tourModel';
import { tourNormalizer, toursNormalizer } from './normalizers/toursNormalizer';
import { OptimizedTours, OptimizedTourModel } from './optimizedTourModel';
import { OptimizeToursPayload } from './optimizeToursPayload';
import { CreateTourPayload } from './createTourPayload';
import {
  RouteInfoDto,
  optimizedTourJobsNormalizer,
} from './normalizers/optimizedTourJobsNormalizer';
import { optimizedToursNormalizer } from './normalizers/optimizedToursNormalizer';
import { ContaminationRuleJobResponse } from './contaminationRuleJobResponse';
import { ContaminationRulesValidationRequest } from './contaminationRulesValidationRequest';
import { RecalculateTourDetailsPayload } from './recalculateTourDetailsPayload';
import { RecalculateTourDetailsResponse } from './recalculateTourDetailsResponse';
import { recalculateTourDetailsResponseNormalizer } from './normalizers/recalculateTourDetailsResponseNormalizer';
import { RecalculateTourDetailsResponseDto } from './recalculateTourDetailsResponseDto';
import { editTourDataToPayload } from './editTourDataToPayload';
import { EditVirtualTourDetailsPayload } from './editVirtualTourDetailsPayload';
import { TourDto } from './tourDto';
import {
  extractPlantIdFromBaseQueryApi,
  getDataByPlantId,
} from '@energy-stacks/fleet/shared';
import { EditTourNoteRequestParam } from './editTourNoteDataToPayload';

export type GetToursRequestParams =
  | {
      startDateFrom?: string;
      endDateTo?: string;
    }
  | undefined;

export const toursApi = createApi({
  reducerPath: 'tours',
  tagTypes: ['Tours', 'TourDetails'],
  keepUnusedDataFor: 0,
  baseQuery: createBaseQuery(environment.ocppServiceUrl),
  endpoints: (builder) => ({
    getTours: builder.query<TourModel[], GetToursRequestParams>({
      queryFn: async (params, api, _extraArgs, baseQuery) => {
        return getDataByPlantId({
          api,
          baseQuery,
          params,
          url: '/tours',
          transformResponse: toursNormalizer,
        });
      },
      providesTags: ['Tours'],
    }),
    getTourDetails: builder.query<OptimizedTourModel, TourModel['tourId']>({
      queryFn: async (tourId, api, _extraArgs, baseQuery) => {
        const getToursPromise = baseQuery({
          url: `/tours`,
          method: 'GET',
          params: {
            depotId: extractPlantIdFromBaseQueryApi(api),
          },
        });

        const getTourJobsPromise = baseQuery({
          url: `/tours/${tourId}/details`,
          method: 'GET',
        });

        const [getTourJobsResponse, getToursResponse] = await Promise.all([
          getTourJobsPromise,
          getToursPromise,
        ]);

        const { data: toursDto, error: toursError } = getToursResponse;
        const { data: tourJobsDto, error: tourJobsError } = getTourJobsResponse;

        if (toursError) {
          return { error: toursError };
        }

        if (tourJobsError) {
          return { error: tourJobsError };
        }

        const tourDto = ((toursDto as TourDto[]) ?? []).find((tourDto) => {
          return tourDto.tourUid === tourId;
        });

        const tour = tourNormalizer(tourDto as TourDto);
        const tourJobs = optimizedTourJobsNormalizer(
          tourJobsDto as RouteInfoDto
        );

        const tourDetails = {
          id: tour.tourId,
          vehicleName: tour.vehicle.name,
          vehicleId: tour.vehicle.id,
          plantId: tour.plantId,
          date: tour.date,
          startDate: tour.startDate,
          endDate: tour.endDate,
          duration: tour.duration.time,
          distance: tour.duration.distance,
          tourJobs: {
            jobs: tourJobs.jobs,
            processes: [...tourJobs.processes, ...tour.emptyRuns],
          },
          totalJobs: tourJobs.jobs.length,
          quantity: tour.quantity,
          polyline: {
            points: tour.polyline,
          },
          status: tour.status,
          emptyRunsMetrics: tour.emptyRunsMetrics,
          transitions: tour.transitions,
        } as OptimizedTourModel;

        return { data: tourDetails };
      },
      providesTags: (_result, _error, tourId) => [
        {
          type: 'TourDetails',
          id: tourId,
        },
      ],
    }),
    optimizeTours: builder.mutation<OptimizedTours, OptimizeToursPayload>({
      query: (body) => ({
        url: 'routes/optimize',
        method: 'POST',
        body,
      }),
      transformResponse: optimizedToursNormalizer,
    }),
    createTours: builder.mutation<void, CreateTourPayload[]>({
      query: (body) => ({
        url: '/tours',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Tours'],
    }),
    deleteTour: builder.mutation<void, string>({
      query: (tourId) => ({
        url: `/tours/${tourId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Tours'],
    }),
    validateContaminationRules: builder.mutation<
      {
        contaminationRuleViolations: Record<
          string,
          ContaminationRuleJobResponse[]
        >;
      },
      ContaminationRulesValidationRequest
    >({
      query: (body) => ({
        url: `/contamination-rules/validation`,
        method: 'POST',
        body,
      }),
    }),
    editVirtualTourDetails: builder.mutation<
      RecalculateTourDetailsResponse,
      EditVirtualTourDetailsPayload
    >({
      query: (body) => ({
        url: `/routes/optimize/job-precedence`,
        method: 'POST',
        body,
      }),
      transformResponse: recalculateTourDetailsResponseNormalizer,
    }),
    editTourDetails: builder.mutation<
      RecalculateTourDetailsResponse,
      { body: RecalculateTourDetailsPayload; tourId: string }
    >({
      queryFn: async (args, _api, _extraArgs, baseQuery) => {
        const requestBody = args.body;
        const tourId = args.tourId;

        // Tour gets re-calculated but not saved in the database yet
        const {
          data: recalculateTourResponseData,
          error: recalculateTourResponseError,
        } = await baseQuery({
          url: `/routes/optimize/${tourId}/job-precedence`,
          method: 'PUT',
          body: requestBody,
        });

        if (recalculateTourResponseError) {
          return { error: recalculateTourResponseError };
        }

        const recalculatedTour = recalculateTourDetailsResponseNormalizer(
          recalculateTourResponseData as RecalculateTourDetailsResponseDto
        );

        // Request to acutally save the re-calculated tour in the database
        const { error: RecalculateTourDetailsResponseError } = await baseQuery({
          url: `/tours/${tourId}`,
          method: 'PUT',
          body: editTourDataToPayload(recalculatedTour.optimizedTour),
        });

        if (RecalculateTourDetailsResponseError) {
          return { error: RecalculateTourDetailsResponseError };
        }

        return {
          data: recalculatedTour,
        };
      },
      invalidatesTags: (_, error, arg) =>
        !error ? [{ type: 'TourDetails', id: arg.tourId }, 'Tours'] : [],
    }),
    editTourNote: builder.mutation<
      void,
      { tourId: TourDto['tourUid']; notes: EditTourNoteRequestParam }
    >({
      query: ({ tourId, notes }) => ({
        url: `/tours/${tourId}`,
        method: 'PATCH',
        body: {
          notes,
        },
      }),
      invalidatesTags: ['Tours'],
    }),
  }),
});

export const {
  useGetToursQuery,
  useOptimizeToursMutation,
  useCreateToursMutation,
  useGetTourDetailsQuery,
  useDeleteTourMutation,
  useValidateContaminationRulesMutation,
  useEditTourDetailsMutation,
  useEditVirtualTourDetailsMutation,
  useEditTourNoteMutation,
} = toursApi;
