import { useTranslation } from 'react-i18next';
import { ESIconButton } from '@energy-stacks/core/ui';
import { Box, Chip, CircularProgress } from '@mui/material';
import { ReactComponent as RefreshIcon } from '../assets/refresh.svg';

export const RefetchUpdatesButton: React.FC<{
  onClick: () => void;
  isFetching: boolean;
  label: string;
  testId?: string;
}> = ({ onClick, isFetching, label, testId }) => {
  const [t] = useTranslation('shared');
  // We currently use this button to show "!" or "counter" as a label
  // that's why the logic for fontSize was done that way

  return (
    <ESIconButton
      data-testid={testId}
      tooltipOffsetX={20}
      onClick={() => {
        onClick();
      }}
      title={t('loadUpdates')}
    >
      <Box mt={2} sx={{ position: 'relative' }}>
        {!isFetching && (
          <Chip
            size="small"
            label={label}
            sx={{
              '> .MuiChip-label.MuiChip-labelSmall': {
                paddingLeft: 0,
                paddingRight: 0,
                textOverflow: 'clip',
              },
              position: 'absolute',
              top: -16,
              right: -18,
              maxWidth: 24,
              width: 24,
              height: 24,
              fontSize:
                label === '!'
                  ? 16
                  : `calc(0.67275rem * 2.425/${
                      label.length > 2 ? label.length : 2
                    })`,
              borderRadius: '50%',
              bgcolor: (theme) => theme.palette.error.dark,
              color: (theme) =>
                theme.palette.getContrastText(theme.palette.error.dark),
            }}
          />
        )}
        {!isFetching ? <RefreshIcon /> : null}
        {isFetching ? (
          <CircularProgress style={{ color: 'white' }} size={20} />
        ) : null}
      </Box>
    </ESIconButton>
  );
};
