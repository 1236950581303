import { CsmsProviderDto } from '../csmsProviderDto';
import { CsmsProviderModel } from '../csmsProviderModel';

export const csmsProviderNormalizer = (
  providerDto: CsmsProviderDto
): CsmsProviderModel => {
  return {
    providerId: providerDto.providerId,
    providerName: providerDto.providerName,
    countryCode: providerDto.countryCode,
  };
};
