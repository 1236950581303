import { createSlice } from '@reduxjs/toolkit';

export const SIDEBAR_FEATURE_KEY = 'sidebar';

export interface SidebarState {
  sidebarOpened: boolean;
  imprintOpen: boolean;
  logoHashDate: string;
}

const initialState: SidebarState = {
  sidebarOpened: true,
  imprintOpen: false,
  logoHashDate: '',
};

export const sidebarSlice = createSlice({
  name: SIDEBAR_FEATURE_KEY,
  initialState,
  reducers: {
    toggleSidebar: (state) => {
      state.sidebarOpened = !state.sidebarOpened;
    },
    openSidebar: (state) => {
      state.sidebarOpened = true;
    },
    collapseSidebar: (state) => {
      state.sidebarOpened = false;
    },
    toggleImprint: (state) => {
      state.imprintOpen = !state.imprintOpen;
    },
    setLogoHashDate: (state) => {
      const hashDate = Date.now().toPrecision();
      state.logoHashDate = hashDate;
    },
  },
});

export const {
  toggleSidebar,
  toggleImprint,
  setLogoHashDate,
  openSidebar,
  collapseSidebar,
} = sidebarSlice.actions;

export const sidebarReducer = sidebarSlice.reducer;
