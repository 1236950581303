import { environment } from '@energy-stacks/feature-config';
import { createApi } from '@reduxjs/toolkit/query/react';
import { appendZToDateString, createBaseQuery } from '@energy-stacks/shared';
import { ImprintModel } from './imprintModel';
import { ImprintDto } from './imprintDto';
import { VersionInfoDto } from './versionInfoDto';
import { VersionInfoModel } from './versionInfoModel';

export interface ImprintFormData {
  content: string;
}

export const brokerImprintApi = createApi({
  reducerPath: 'brokerImprintApi',
  tagTypes: ['BrokerImprint'],
  baseQuery: createBaseQuery(`${environment.ocppServiceUrl}`),
  refetchOnMountOrArgChange: true,
  endpoints: (builder) => ({
    getImprint: builder.query<ImprintModel, void>({
      query: () => '/imprint',
      providesTags: ['BrokerImprint'],
      transformResponse: (imprint: ImprintDto) => ({
        ...imprint,
        dateCreated: appendZToDateString(imprint.dateCreated),
        dateLastUpdated: appendZToDateString(imprint.dateLastUpdated),
      }),
    }),
    updateImprint: builder.mutation<ImprintModel, ImprintFormData>({
      query: (body) => ({
        url: '/imprint',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['BrokerImprint'],
    }),
    getVersionInfo: builder.query<VersionInfoModel, void>({
      query: () => '/version',
      transformResponse: (versionInfo: VersionInfoDto) => ({
        ...versionInfo,
      }),
    }),
  }),
});

export const {
  useGetImprintQuery,
  useUpdateImprintMutation,
  useGetVersionInfoQuery,
} = brokerImprintApi;
