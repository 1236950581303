import { ConnectorStatus } from '../brokerConnectorShadow';
import { ConnectorModel, ConnectorStatusEnum } from '../connectorModel';

export const convertShadowConnectorStatus = (
  shadowConnectorStatus: ConnectorStatus | undefined
): ConnectorModel['connectorStatus'] => {
  if (!shadowConnectorStatus) return ConnectorStatusEnum.undefined;

  return shadowConnectorStatusToConnectorStatusMap[shadowConnectorStatus];
};

const shadowConnectorStatusToConnectorStatusMap: Record<
  ConnectorStatus,
  ConnectorModel['connectorStatus']
> = {
  AVAILABLE: ConnectorStatusEnum.available,
  CHARGING: ConnectorStatusEnum.charging,
  FAULTED: ConnectorStatusEnum.faulted,
  FINISHING: ConnectorStatusEnum.finishing,
  OCCUPIED: ConnectorStatusEnum.occupied,
  PREPARING: ConnectorStatusEnum.preparing,
  RESERVED: ConnectorStatusEnum.reserved,
  SUSPENDED_EV: ConnectorStatusEnum.suspendedEV,
  SUSPENDED_EVSE: ConnectorStatusEnum.suspendedEVSE,
  UNAVAILABLE: ConnectorStatusEnum.unavailable,
  UNDEFINED: ConnectorStatusEnum.undefined,
} as const;
