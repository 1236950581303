import { OptimizedTourJob } from '@energy-stacks/fleet/feature-tours-data';
import { useMemo } from 'react';

export const useDifferentLocationsCount = (jobs: OptimizedTourJob[]) => {
  const differentLocations = useMemo(() => {
    return jobs.reduce((acc, curr) => {
      const clientLocationId =
        curr.jobType === 'INBOUND' ? curr.origin?.id : curr.destination?.id;
      if (!clientLocationId || acc.includes(clientLocationId)) {
        return acc;
      }
      return [...acc, clientLocationId];
    }, [] as string[]);
  }, [jobs]);

  return differentLocations.length;
};
