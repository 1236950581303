import { Box, Stack, Typography } from '@mui/material';
import { TourInfoHeaderItemLayout } from '../shared/TourInfoHeaderItemLayout';
import { formatDurationInSeconds } from '@energy-stacks/fleet/shared';
import { useTranslation } from 'react-i18next';
import { ReactComponent as EmptyRunIcon } from './emptyRun.svg';
import { FC } from 'react';
import { EmptyRunsMetrics } from '@energy-stacks/fleet/feature-tours-data';

type EmptyRunsTourInfoItemProps = {
  emptyRunsMetrics: EmptyRunsMetrics;
};

export const EmptyRunsTourInfoItem: FC<EmptyRunsTourInfoItemProps> = ({
  emptyRunsMetrics,
}) => {
  const [t] = useTranslation('tours');

  return (
    <TourInfoHeaderItemLayout Icon={EmptyRunIcon}>
      <Typography variant="body1" color="grey.500">
        <Stack component="span" direction="row" gap={1}>
          {t('emptyRunsCount', {
            count: emptyRunsMetrics.emptyRunsTotalNumber,
          })}
          <Box component="span">•</Box>
          {formatDurationInSeconds(
            emptyRunsMetrics.emptyRunsTotalDurationSeconds
          )}
          <Box component="span">•</Box>
          {`${(emptyRunsMetrics.emptyRunsTotalDistanceMeters / 1000).toFixed(
            1
          )}km`}
        </Stack>
      </Typography>
    </TourInfoHeaderItemLayout>
  );
};
