import { Card, CardContent, CardHeader, Divider } from '@mui/material';
import { useRef } from 'react';

type BusinessAccountDetailsCardProps = {
  title: React.ReactNode;
  ContentComponent: React.ReactNode;
  hasPadding?: boolean;
};

export const BusinessAccountDetailsCard: React.FC<
  BusinessAccountDetailsCardProps
> = ({ title, ContentComponent, hasPadding = true }) => {
  const cardHeaderRef = useRef<HTMLElement | null>(null);
  const headerHeight = cardHeaderRef.current?.clientHeight || 0;
  const contentHeight = `calc(100% - ${headerHeight}px)`;
  return (
    <Card sx={{ border: 'none', height: '100%' }}>
      <CardHeader
        ref={cardHeaderRef}
        title={title}
        titleTypographyProps={{
          fontSize: '16px',
          fontWeight: 700,
          color: 'grey.900',
        }}
        sx={{
          paddingTop: 5,
          paddingBottom: 0,
        }}
      />
      <CardContent
        sx={{
          height: contentHeight,
          overflowY: 'auto',
          padding: !hasPadding ? 0 : undefined,
          paddingTop: 0,
          display: 'flex',
          flexDirection: 'column',
          '&.MuiCardContent-root:last-child': {
            paddingBottom: !hasPadding ? 0 : undefined,
          },
        }}
      >
        <Divider sx={{ mt: 5, borderColor: 'grey.100' }} />
        {ContentComponent}
      </CardContent>
    </Card>
  );
};
