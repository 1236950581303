import { ReactComponent as Cow } from './icons/cow.svg';
import { ReactComponent as Pig } from './icons/pig.svg';
import { ReactComponent as Industrial } from './icons/industrial.svg';
import { ReactComponent as Chicken } from './icons/chicken.svg';
import { ReactComponent as CowPig } from './icons/cowPig.svg';
import { ReactComponent as Mink } from './icons/mink.svg';
import { Box, alpha, useTheme } from '@mui/material';
import { FC, useMemo } from 'react';
import { ESTooltip } from '@energy-stacks/shared';
import { RoutePoint } from './types/routePoint';
import { useTranslation } from 'react-i18next';

type ProductIconProps = {
  type: RoutePoint['type'];
  risk: RoutePoint['risk'];
};
const productIconMap: {
  [key: string]: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
} = {
  Pig: Pig,
  Cow: Cow,
  Industrial: Industrial,
  Chicken: Chicken,
  CowPig: CowPig,
  Mink: Mink,
};

export const ProductIcon: FC<ProductIconProps> = ({ type, risk }) => {
  const [t] = useTranslation('fleetShared');
  const { palette } = useTheme();

  const Icon = productIconMap[type];

  const iconColors = useMemo(() => {
    switch (risk) {
      case 'Low':
        return {
          backgroundColor: palette.success.light,
          stroke: palette.success.dark,
        };
      case 'High':
        return {
          backgroundColor: palette.orange.light,
          stroke: palette.error.dark,
        };
      default:
        return {
          backgroundColor: palette.grey[300],
          stroke: palette.grey[700],
        };
    }
  }, [risk, palette]);

  if (!Icon) {
    return null;
  }

  return (
    <ESTooltip
      title={
        t(`locationTypeTooltip.${type}`) + ' - ' + t(`riskLevelTooltip.${risk}`)
      }
      disablePortal={true}
    >
      <Box
        sx={{
          backgroundColor: alpha(iconColors.backgroundColor, 0.5),
          borderRadius: '6px',
          display: 'flex',
          alignItems: 'center',
          mr: 2,
          p: 1.25,
          width: 30,
        }}
      >
        <Icon
          style={{
            stroke: iconColors.stroke,
            strokeWidth: 1.5,
          }}
        />
      </Box>
    </ESTooltip>
  );
};
