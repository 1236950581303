import {
  BrokerConnectorShadow,
  ShadowConnectorDtoFormat,
} from '../brokerConnectorShadow';
import { ConnectorModel } from '../connectorModel';
import { convertShadowConnectorStatus } from './convertShadowConnectorStatus';
import { convertShadowOcppErrorCode } from './convertShadowOcppErrorCode';
import {
  ConnectorStandard,
  connectorStandardDtoToTypeMap,
} from '@energy-stacks/data';

export const connectorShadowNormalizer = (
  connectorShadowDto: BrokerConnectorShadow
): ConnectorModel => {
  return {
    connectorId: connectorShadowDto.connectorId,
    evseId: connectorShadowDto.evseId ?? '',
    connectorStatus: convertShadowConnectorStatus(
      connectorShadowDto.connectorStatus
    ),
    ocppErrorCode: convertShadowOcppErrorCode(connectorShadowDto.ocppErrorCode),
    dateLastUpdate: connectorShadowDto.dateLastUpdate || '',
    ocppErrorInfo: connectorShadowDto.ocppErrorInfo,
    connectorFormat: convertShadowConnectorFormat(connectorShadowDto.format),
    maxPower: connectorShadowDto.max_electric_power ?? 0,
    connectorType:
      connectorStandardDtoToTypeMap[connectorShadowDto.standard] ??
      ConnectorStandard.UNKNOWN,
    maxVoltage: connectorShadowDto.max_voltage ?? 0,
    maxAmperage: connectorShadowDto.max_amperage ?? 0,
    powerType: connectorShadowDto.power_type ?? undefined,
    currentVoltage: connectorShadowDto.current_voltage ?? 0,
    currentAmperage: connectorShadowDto.current_amperage ?? 0,
    currentPower: connectorShadowDto.current_power ?? 0,
    tariffId: connectorShadowDto.tariff_id ?? '',
  };
};

const convertShadowConnectorFormat = (
  shadowConnectorDtoFormat: ShadowConnectorDtoFormat
): ConnectorModel['connectorFormat'] => {
  return shadowConnectorFormatMap[shadowConnectorDtoFormat];
};

const shadowConnectorFormatMap: Record<
  ShadowConnectorDtoFormat,
  ConnectorModel['connectorFormat']
> = {
  SOCKET: 'socket',
  CABLE: 'cable',
} as const;
