import { useRef } from 'react';
import { Libraries, useLoadScript } from '@react-google-maps/api';
import { environment } from '@energy-stacks/feature-config';
import Geocode from 'react-geocode';

type GoogleMapsLibrary =
  | 'places'
  | 'drawing'
  | 'geometry'
  | 'localContext'
  | 'visualization';

export const useLoadGoogleMapsApiScript = (
  apiKey = environment.googleApiKey
) => {
  Geocode.setApiKey(environment.googleApiKey);
  // Typescript issue with libraries and environemnt on multiple render. Workaround is useRef hook.
  const googleMapsLibraryRef = useRef<GoogleMapsLibrary[]>(['places']);
  const googleApiKeyRef = useRef<string>(apiKey);

  googleApiKeyRef.current = apiKey;

  return useLoadScript({
    googleMapsApiKey: googleApiKeyRef.current,
    libraries: googleMapsLibraryRef.current as Libraries,
  });
};
