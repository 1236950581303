export const deleteCentralSystemErrors: Record<string, string> = {
  UNABLE_TO_DELETE_CSMS: 'unableToDeleteCentralSystem',
  CANNOT_DELETE_DEFAULT_CSMS: 'cannotDeleteDefaultCentralSystem',
};

export const addCentralSystemErrors: Record<string, string> = {
  ALREADY_EXISTS: 'centralSystemExists',
  CSMS_URL_ALREADY_EXISTS: 'csmsUrlAlreadyExists',
  NOT_UNIQUE_NAME: 'centralSystemNameNotUnique',
};

export const editCentralSystemErrors = {
  NOT_UNIQUE_NAME: 'centralSystemNameNotUnique',
  DEFAULT_CSMS_CANNOT_CHANGE_TO_NOT_DEFAULT:
    'cannotChangeDefaultCentralSystemToNotDefault',
  CSMS_URL_ALREADY_EXISTS: 'csmsUrlAlreadyExists',
  UNABLE_TO_UPDATE_CSMS_CREDENTIALS: 'unableToUpdateCentralSystemCredentials',
} as const;

/**
 * TODO: Centralize error codes for the whole api in a single record/enum. For keys NOT_UNIQUE_NAME which are present in two
 * different flows: "add" and "edit", merge them into single error code per swagger documentation and show snackbars based on
 * the context where this error was thrown, e.g. inside AddCentralSystemForm or EditCentralSystemForm.
 *
 * EditCentralSystemApiErrorMessage can then become CentralSystemApiErrorMessageCode which is a union of all error codes.
 *
 * Do this for all api files to improve typechecking of error codes.
 */
type EditCentralSystemApiErrorCode = keyof typeof editCentralSystemErrors;

export type EditCentralSystemApiError = {
  data: {
    errorCode: EditCentralSystemApiErrorCode;
    errorMessage: string;
  };
};
