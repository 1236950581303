import { Optimize, OptimizeProps } from '../Optimize';

export const OptimizeStep: React.FC<OptimizeProps> = ({
  onOptimizeSelected,
  selectedOption,
}) => {
  return (
    <Optimize
      onOptimizeSelected={onOptimizeSelected}
      selectedOption={selectedOption}
    />
  );
};
