import React from 'react';
import { testMapProps } from './testMapProps';
import { TestMapContainer, TestMapSize } from './TestMapContainer';
import { Map, MapProps } from '../Map';
import { environment } from '@energy-stacks/feature-config';

type Props = {
  children?: React.ReactNode;
  size?: TestMapSize;
  mapProps?: MapProps;
};

export const TestMap = ({
  children,
  size = 'large',
  mapProps = testMapProps,
}: Props) => {
  return (
    <TestMapContainer size={size}>
      <Map apiKey={environment.googleApiKey} {...mapProps}>
        {children}
      </Map>
    </TestMapContainer>
  );
};
