// TODO: Move to shared/charging-stations?

import { OperationalStatus } from './operationalStatusModel';

export type AddChargingStationFormData = {
  identityKey: string;
  name?: string;
  location?: { label: string; value: string | undefined };
  chargingStationGroup?: { label: string; value: string };
  activateHttpBasicAuthentication: boolean;
  username?: string;
  password?: string;
  operationalStatus?: OperationalStatus;
  communicationType?: string;
  installationDate: Date | null;
  contactPerson?: string;
  authorizationMethods: AuthorizationMethods[];
  loadManagementSupported: boolean;
  calibrationLawCompliant: boolean;
  evses: Evse[];
  defaultCentralSystem: { value: string; label: string };
  secondaryCentralSystems: string[];
};

export type AuthorizationMethods = 'RFID' | 'APP' | 'ISO15118';

export const supportedAuthorizationFormMethods: AuthorizationMethods[] = [
  'RFID',
  'APP',
  'ISO15118',
];

export interface Evse {
  evseId?: string;
  connectors: Connector[];
}

export interface Connector {
  connectorId: string;
  type: { label: string; value: string }; // String for default value
  connectorFormat: { label: string; value: string };
  powerType: { label: string; value: string };
  voltage: string;
  maxAmperage: string;
  maxPower?: string;
}

export const defaultConnector: Connector = {
  connectorId: '',
  connectorFormat: { label: '', value: '' },
  type: { label: '', value: '' },
  maxAmperage: '',
  maxPower: '',
  powerType: { label: '', value: '' },
  voltage: '',
};

export const defaultEvse: Evse = {
  evseId: undefined,
  connectors: [defaultConnector],
};
