import { useGetChargingStationDetailsQuery } from '@energy-stacks/broker/feature-charging-stations-data';
import { useGetCentralSystemsDetailsQuery } from '@energy-stacks/broker/feature-system-preferences-data';
import { useChargingStationIdentityKey } from '@energy-stacks/shared';

export const useChargingStationDetails = ({
  listenToUpdates = false,
}: { listenToUpdates?: boolean } = {}) => {
  const identityKey = useChargingStationIdentityKey();

  const {
    data: chargingStationDetails,
    isLoading,
    error,
    isSuccess,
    refetch,
    isError,
  } = useGetChargingStationDetailsQuery({
    identityKey,
    listenToUpdates,
  });

  const { data: csms, isSuccess: isSuccessCsms } =
    useGetCentralSystemsDetailsQuery(
      chargingStationDetails?.csmsUuid as string,
      {
        skip: !chargingStationDetails?.csmsUuid,
      }
    );

  return {
    chargingStationDetails: chargingStationDetails && {
      ...chargingStationDetails,
      csmsDisplayName: csms ? csms.displayName ?? 'N/A' : '',
    },
    isLoading,
    error,
    isSuccess: isSuccess && isSuccessCsms,
    refetch,
    isError,
  };
};
