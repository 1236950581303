import { Checkbox, ListItemText, MenuItem, Select } from '@mui/material';

interface ConfigurationSequenceListValueFieldProps {
  handleValueChange: (val: string) => void;
  controlerValue: string;
  valuesList: string;
}
export const ConfigurationSequenceListValueField: React.FC<
  ConfigurationSequenceListValueFieldProps
> = ({ handleValueChange, controlerValue, valuesList, ...rest }) => {
  const dropdownValuesList = valuesList.split(',');

  return (
    <Select
      multiple
      value={[controlerValue]}
      onChange={(e) => {
        const newData = e.target.value as string[];
        let joinedData = '';
        const substrings =
          newData.length !== 0 ? newData[0].split(',') : newData;

        if (newData.length !== 0 && substrings.includes(newData[1])) {
          const index = substrings.indexOf(newData[1]);
          if (index !== -1) {
            substrings.splice(index, 1);
          }
          joinedData = substrings.join(',');
        } else if (newData[0] === '') {
          joinedData = newData[1];
        } else {
          joinedData = newData.join(',');
        }
        handleValueChange(joinedData);
      }}
      renderValue={(selected) => {
        if (selected[0] === '') {
          return '-';
        }
        return selected;
      }}
      fullWidth
    >
      {dropdownValuesList.map((listItemValue) => (
        <MenuItem key={listItemValue} value={listItemValue}>
          <Checkbox
            checked={controlerValue.split(',').includes(listItemValue)}
          />
          <ListItemText primary={listItemValue} />
        </MenuItem>
      ))}
    </Select>
  );
};
