import { AddChargingStationGroupFormData } from './addChargingStationGroupFromData';
import { ChargingStationGroupCreateRequestForExistingLocation } from './chargingStationGroupCreateRequest';
import { ChargingStationGroupModel } from './chargingStationGroupModel';

export const mapAddChargingStationGroupFormDataToPayload = (data: {
  chargingStationGroup: AddChargingStationGroupFormData;
  locationId: ChargingStationGroupModel['locationId'];
}): ChargingStationGroupCreateRequestForExistingLocation => {
  return {
    // BE supports only array of charging group names, even if only one name is always provided
    chargingStationGroupCreateRequests: [
      {
        chargingStationGroupName:
          data.chargingStationGroup.chargingStationGroupName,
      },
    ],
    locationId: data.locationId,
  };
};
