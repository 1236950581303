import { ESPopover } from '@energy-stacks/core/ui';
import { Box, SxProps } from '@mui/material';
import { IconAlertTriangle } from '@tabler/icons-react';
import { useTranslation } from 'react-i18next';

export const TourContaminationWarning = ({ sx }: { sx?: SxProps }) => {
  const [t] = useTranslation('tours');

  return (
    <ESPopover content={t('contaminationWarningPopover.tour')}>
      <Box
        data-testid="tourContaminationWarning"
        sx={{
          alignItems: 'center',
          display: 'flex',
          color: 'error.dark',
          ...sx,
        }}
      >
        <IconAlertTriangle />
      </Box>
    </ESPopover>
  );
};
