import { Box, TableCell, TableCellProps, TableSortLabel } from '@mui/material';
import { flexRender, Header } from '@tanstack/react-table';
import { useMemo } from 'react';
import { STICKY_COLUMN_SX } from './sticky-column-styles';

interface ESTableHeaderCellProps<T> extends TableCellProps {
  header: Header<T, unknown>;
  isSticky?: boolean;
  testId?: string;
}

export const ESTableHeaderCell = <T,>(props: ESTableHeaderCellProps<T>) => {
  const { header, isSticky, testId, sx, onMouseEnter, onMouseLeave, ...rest } =
    props;
  const disableSorting = useMemo(
    () => header.id === 'select' || header.id === 'expand',
    [header.id]
  );

  return (
    <TableCell
      colSpan={header.colSpan}
      sortDirection={header.column.getIsSorted() ?? false}
      padding={disableSorting ? 'checkbox' : 'normal'}
      sx={(theme) => ({
        ...sx,
        ...(isSticky ? STICKY_COLUMN_SX(theme) : {}),
      })}
      {...rest}
    >
      {header.column.getCanResize() ? <ColumnResizer header={header} /> : null}
      <TableSortLabel
        data-testid={`${testId}SortLabel`}
        disabled={!header.column.getCanSort() && !disableSorting}
        active={!!header.column.getIsSorted() && !disableSorting}
        direction={header.column.getIsSorted() || undefined}
        onClick={header.column.getToggleSortingHandler()}
        hideSortIcon={disableSorting}
        sx={{ flexDirection: 'row' }}
      >
        {flexRender(header.column.columnDef.header, header.getContext())}
      </TableSortLabel>
    </TableCell>
  );
};

const ColumnResizer = <T,>({
  header,
}: {
  header: ESTableHeaderCellProps<T>['header'];
}) => {
  return (
    <Box
      onDoubleClick={() => header.column.resetSize()}
      onMouseDown={header.getResizeHandler()}
      onTouchStart={header.getResizeHandler()}
      className="resizer"
      sx={{
        position: 'absolute',
        width: '3px',
        backgroundColor: header.column.getIsResizing()
          ? 'grey.500'
          : 'grey.200',
        cursor: 'col-resize',
        userSelect: 'none',
        touchAction: 'none',
        display: 'flex',
        justifyContent: 'center',
        top: '50%',
        right: 0,
        height: '50%',
        transform: 'translateY(-50%)',
        '&:hover': {
          backgroundColor: 'grey.500',
          transition: 'all .5 ease-in-out',
        },
      }}
    />
  );
};
