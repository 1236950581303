import React from 'react';
import { Navigate } from 'react-router-dom';
import { BrokerFeature } from './brokerFeature';
import { BrokerRoutes } from '@energy-stacks/broker/shared';
import { useBrokerFeatureGuard } from './useBrokerFeatureGuard';

interface BrokerRoleProtectedRouteProps {
  children: React.ReactNode;
  feature: BrokerFeature;
}

export const BrokerRoleProtectedRoute: React.FC<
  BrokerRoleProtectedRouteProps
> = ({ children, feature }) => {
  const hasAccess = useBrokerFeatureGuard(feature);

  if (!hasAccess) {
    return <Navigate to={BrokerRoutes.Dashboard} replace />;
  }

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
};
