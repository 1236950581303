import { usePopover } from '@energy-stacks/core/ui';
import {
  Grid,
  IconButton,
  Popover,
  Typography,
  Checkbox,
  FormControlLabel,
} from '@mui/material';
import { IconColumns } from '@tabler/icons-react';
import { Column, Table } from '@tanstack/react-table';
import { useRef } from 'react';

interface TableColumnSelectProps<T> {
  instance: Table<T>;
  staticColumnsIds?: string[];
  hiddenColumnsIds?: string[];
}

const ROWS_PER_GRID_COLUMN = 5;

export const TableColumnSelect = <T,>({
  instance,
  staticColumnsIds = [],
  hiddenColumnsIds = [],
}: TableColumnSelectProps<T>) => {
  const { anchorEl, openPopover, closePopover, popoverOpen } = usePopover();
  const columnsRef = useRef<Column<T>[]>(instance.getAllColumns());
  const numberOfGridColumns = Math.ceil(
    columnsRef.current.length / ROWS_PER_GRID_COLUMN
  );
  const minColumnsCount =
    columnsRef.current.filter((c) => c.columnDef.meta?.isActionable).length +
    (staticColumnsIds.length || 1);
  const columnOptionsToShow = columnsRef.current.filter(
    (c) =>
      !staticColumnsIds.includes(c.id) &&
      !hiddenColumnsIds.includes(c.id) &&
      !c.columnDef.meta?.isActionable &&
      c.getCanHide()
  );

  return (
    <>
      <IconButton onClick={openPopover}>
        <IconColumns />
      </IconButton>
      <Popover
        open={popoverOpen}
        anchorEl={anchorEl}
        onClose={closePopover}
        anchorPosition={{
          top: anchorEl ? anchorEl.getBoundingClientRect().bottom + 5 : 0,
          left: anchorEl ? anchorEl.getBoundingClientRect().left : 0,
        }}
        PaperProps={{
          sx: {
            boxShadow: '0px 2px 1px -1px rgba(56, 67, 107, 0.2)',
          },
        }}
        anchorReference="anchorPosition"
      >
        <Grid
          display="grid"
          container
          gridAutoFlow="column"
          gridTemplateColumns={`repeat(${numberOfGridColumns}), 1fr`}
          gridTemplateRows={`repeat(${ROWS_PER_GRID_COLUMN}, auto)`}
          spacing={4}
          padding={6}
        >
          {columnOptionsToShow.map((column) => (
            <Grid sx={{ wordBreak: 'no-wrap' }} item key={column.id}>
              <FormControlLabel
                control={
                  <Checkbox
                    sx={{ p: 0, mr: 2 }}
                    disabled={
                      instance.getVisibleLeafColumns().length ===
                        minColumnsCount &&
                      instance.getVisibleLeafColumns().some((visibleCol) => {
                        return visibleCol.id === column.id;
                      })
                    }
                    checked={column.getIsVisible()}
                    onChange={(e) => {
                      column.getToggleVisibilityHandler()(e);
                    }}
                  />
                }
                label={
                  <Typography>
                    {
                      // @ts-expect-error columnDef.header returns a function, but typed as string
                      column.columnDef.header?.()
                    }
                  </Typography>
                }
              />
            </Grid>
          ))}
        </Grid>
      </Popover>
    </>
  );
};
