import { ESButton } from '@energy-stacks/core/ui';
import { Box, Divider, Link, Stack, Typography } from '@mui/material';
import { IconCoinOff } from '@tabler/icons-react';
import { useTranslation } from 'react-i18next';
import { MouseEventHandler } from 'react';
import { formatDateTime } from '@energy-stacks/shared';
import { TariffModel } from '@energy-stacks/broker/feature-tariffs-data';

interface ConnectorTariffsTabCardViewProps {
  testId: string;
  onAssignTariff: () => void;
  onViewDetails: MouseEventHandler<HTMLAnchorElement>;
  tariff: TariffModel | undefined;
}

export const ConnectorTariffsTabCardView = ({
  testId,
  onAssignTariff,
  onViewDetails,
  tariff,
}: ConnectorTariffsTabCardViewProps) => {
  const { t } = useTranslation('tariffs');

  return (
    <>
      {tariff ? (
        <>
          <Stack
            data-testid={`tariff${testId}Name`}
            paddingY={2}
            direction="row"
            justifyContent="space-between"
          >
            <Typography variant="body2" color="grey.600" fontWeight="400">
              {t('tariffName')}
            </Typography>
            <Typography variant="body2" color="grey.900" fontWeight="500">
              {tariff.name ? tariff.name : 'N/A'}
            </Typography>
          </Stack>
          <Divider />
          <Stack
            data-testid={`tariff${testId}Currency`}
            paddingY={2}
            direction="row"
            justifyContent="space-between"
          >
            <Typography variant="body2" color="grey.600" fontWeight="400">
              {t('tariffCurrency')}
            </Typography>
            <Typography variant="body2" color="grey.900" fontWeight="500">
              {tariff.currency ? tariff.currency : 'N/A'}
            </Typography>
          </Stack>
          <Divider />
          <Stack
            data-testid={`tariff${testId}Type`}
            paddingY={2}
            direction="row"
            justifyContent="space-between"
          >
            <Typography variant="body2" color="grey.600" fontWeight="400">
              {t('tariffType')}
            </Typography>
            <Typography variant="body2" color="grey.900" fontWeight="500">
              {tariff.type ? tariff.type : 'N/A'}
            </Typography>
          </Stack>
          <Divider />
          <Stack
            data-testid={`tariff${testId}Expires`}
            paddingY={2}
            direction="row"
            justifyContent="space-between"
          >
            <Typography variant="body2" color="grey.600" fontWeight="400">
              {t('tariffExpires')}
            </Typography>
            <Typography variant="body2" color="grey.900" fontWeight="500">
              {tariff.endDateTime ? formatDateTime(tariff.endDateTime) : '-'}
            </Typography>
          </Stack>
          <Divider />
          <Stack
            data-testid={`tariff${testId}Elements`}
            paddingY={2}
            direction="row"
            justifyContent="space-between"
          >
            <Typography variant="body2" color="grey.600" fontWeight="400">
              {t('tariffElements')}
            </Typography>
            <Link
              sx={{ cursor: 'pointer' }}
              underline="hover"
              onClick={onViewDetails}
            >
              {t('viewDetails')}
            </Link>
          </Stack>
          <Divider />
        </>
      ) : (
        <Box
          display="flex"
          sx={{
            flex: 1,
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Stack gap={4.5} alignItems="center" direction="column">
            <Stack direction="row" alignItems="center">
              <IconCoinOff style={{ marginRight: 10 }} />
              <Typography>{t('noTariffsMessage')}</Typography>
            </Stack>

            <ESButton sx={{ display: 'flex' }} onClick={onAssignTariff}>
              {t('assignTariff')}
            </ESButton>
          </Stack>
        </Box>
      )}
    </>
  );
};
