import { Box } from '@mui/material';
import { IconChevronRight } from '@tabler/icons-react';

interface CloseDrawerButtonProps {
  top?: number;
  onClick: () => void;
}

export const CloseDrawerButton: React.FC<CloseDrawerButtonProps> = ({
  top = 20,
  onClick,
}) => {
  return (
    <Box
      sx={{
        cursor: 'pointer',
      }}
      bgcolor="primary.main"
      width="30px"
      height="30px"
      borderRadius={100}
      display="flex"
      justifyContent="center"
      position="absolute"
      alignItems="center"
      top={top}
      left="-15px"
      onClick={onClick}
    >
      <IconChevronRight color="white" />
    </Box>
  );
};
