import { MessageTypeEnum } from '../messageTypeEnum';
import { MessageType } from '../ocppMessageLogEntry';
import { MessageTypeModel } from '../ocppMessageLogsModel';

export const messageTypeNormalizer = (
  messageType: MessageType
): MessageTypeModel => {
  return messageTypeMap[messageType];
};

const messageTypeMap: Record<MessageType, MessageTypeModel> = {
  CALL: 'call',
  CALL_RESULT: 'callResult',
  CALL_ERROR: 'callError',
};

export const messageTypeToEnum: Record<MessageTypeModel, MessageTypeEnum> = {
  call: MessageTypeEnum.Call,
  callResult: MessageTypeEnum.Call_Result,
  callError: MessageTypeEnum.Call_Error,
};
