import { formatDuration } from '@energy-stacks/core/ui';
import { formatDate } from '@energy-stacks/shared';
import { TourStatusChip } from '../TourStatusChip';
import { VehicleChip } from '../VehicleChip';
import { useTranslation } from 'react-i18next';
import { TourInfo } from '../tour-details/TourDetails';
import { useDifferentLocationsCount } from '../shared/useDifferentLocationsCount';

type ToursListItemProps = {
  onMouseEnter: () => void;
  isHovered: boolean;
  onClick: () => void;
  isFocused: boolean;
  tour: TourInfo;
  index: number;
};

export const ToursListItem: React.FC<ToursListItemProps> = ({
  onMouseEnter,
  isHovered,
  onClick,
  isFocused,
  tour,
  index,
}) => {
  const [t] = useTranslation('tours');
  const differentLocationsCount = useDifferentLocationsCount(
    tour.tourJobs.jobs
  );
  return (
    <div
      onMouseEnter={onMouseEnter}
      onClick={onClick}
      style={{
        boxShadow: isHovered ? `0px 3px 8px rgba(0, 0, 0, 0.24)` : 'none',
        zIndex: isHovered ? 1 : 0,
        backgroundColor: isFocused
          ? '#2199f40e'
          : isHovered
          ? '#afd2ef13'
          : 'white',
        cursor: 'pointer',
        display: 'flex',
        padding: '24px 16px',
        flexDirection: 'column',
        justifyContent: 'flex-end',
        alignItems: 'flex-start',
        borderBottom: '1px solid #EEF2F6',
        gap: '16px',
        alignSelf: 'stretch',
      }}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          alignSelf: 'stretch',
        }}
      >
        <div style={{ marginRight: 10 }}>
          <VehicleChip title={tour.vehicleName} index={index} />
        </div>
        {tour.tourStatus && <TourStatusChip status={tour.tourStatus} />}
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          gap: '8px',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <div style={{ paddingRight: 8 }}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width={20}
              height={20}
              viewBox="0 0 20 20"
              fill="none"
            >
              <g clipPath="url(#clip0_1817_34236)">
                <path
                  d="M3.33337 5.83317C3.33337 5.39114 3.50897 4.96722 3.82153 4.65466C4.13409 4.3421 4.55801 4.1665 5.00004 4.1665H15C15.4421 4.1665 15.866 4.3421 16.1786 4.65466C16.4911 4.96722 16.6667 5.39114 16.6667 5.83317V15.8332C16.6667 16.2752 16.4911 16.6991 16.1786 17.0117C15.866 17.3242 15.4421 17.4998 15 17.4998H5.00004C4.55801 17.4998 4.13409 17.3242 3.82153 17.0117C3.50897 16.6991 3.33337 16.2752 3.33337 15.8332V5.83317Z"
                  stroke="#212946"
                  strokeWidth={1.5}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M13.3334 2.5V5.83333"
                  stroke="#212946"
                  strokeWidth={1.5}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M6.66663 2.5V5.83333"
                  stroke="#212946"
                  strokeWidth={1.5}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M3.33337 9.1665H16.6667"
                  stroke="#212946"
                  strokeWidth={1.5}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M9.16663 13.3333C9.16663 13.5543 9.25442 13.7663 9.4107 13.9226C9.56698 14.0789 9.77895 14.1667 9.99996 14.1667C10.221 14.1667 10.4329 14.0789 10.5892 13.9226C10.7455 13.7663 10.8333 13.5543 10.8333 13.3333C10.8333 13.1123 10.7455 12.9004 10.5892 12.7441C10.4329 12.5878 10.221 12.5 9.99996 12.5C9.77895 12.5 9.56698 12.5878 9.4107 12.7441C9.25442 12.9004 9.16663 13.1123 9.16663 13.3333Z"
                  stroke="#212946"
                  strokeWidth={1.5}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </g>
              <defs>
                <clipPath id="clip0_1817_34236">
                  <rect width={20} height={20} fill="white" />
                </clipPath>
              </defs>
            </svg>
          </div>
          <span style={{ whiteSpace: 'nowrap' }}>
            {formatDate(tour.startDate).toLowerCase()}
          </span>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <div style={{ paddingRight: 8 }}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width={20}
              height={20}
              viewBox="0 0 20 20"
              fill="none"
            >
              <g clipPath="url(#clip0_1817_34285)">
                <path
                  d="M2.5 10C2.5 10.9849 2.69399 11.9602 3.0709 12.8701C3.44781 13.7801 4.00026 14.6069 4.6967 15.3033C5.39314 15.9997 6.21993 16.5522 7.12987 16.9291C8.03982 17.306 9.01509 17.5 10 17.5C10.9849 17.5 11.9602 17.306 12.8701 16.9291C13.7801 16.5522 14.6069 15.9997 15.3033 15.3033C15.9997 14.6069 16.5522 13.7801 16.9291 12.8701C17.306 11.9602 17.5 10.9849 17.5 10C17.5 9.01509 17.306 8.03982 16.9291 7.12987C16.5522 6.21993 15.9997 5.39314 15.3033 4.6967C14.6069 4.00026 13.7801 3.44781 12.8701 3.0709C11.9602 2.69399 10.9849 2.5 10 2.5C9.01509 2.5 8.03982 2.69399 7.12987 3.0709C6.21993 3.44781 5.39314 4.00026 4.6967 4.6967C4.00026 5.39314 3.44781 6.21993 3.0709 7.12987C2.69399 8.03982 2.5 9.01509 2.5 10Z"
                  stroke="#212946"
                  strokeWidth={1.5}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M10 10L11.6667 12.5"
                  stroke="#212946"
                  strokeWidth={1.5}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M10 5.8335V10.0002"
                  stroke="#212946"
                  strokeWidth={1.5}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </g>
              <defs>
                <clipPath id="clip0_1817_34285">
                  <rect width={20} height={20} fill="white" />
                </clipPath>
              </defs>
            </svg>
          </div>
          <span style={{ whiteSpace: 'nowrap' }}>
            {formatDate(tour.startDate, 'HH:mm').toLowerCase()} -{' '}
            {formatDate(tour.endDate, 'HH:mm').toLowerCase()} <br />
            {formatDuration(
              new Date(tour.startDate),
              new Date(tour.endDate)
            )} • {tour.distance} km
          </span>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <div style={{ paddingRight: 8 }}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
            >
              <g clipPath="url(#clip0_3567_18592)">
                <path
                  d="M8.01185 16.6663H5.83268C5.39065 16.6663 4.96673 16.4907 4.65417 16.1782C4.34161 15.8656 4.16602 15.4417 4.16602 14.9997V4.99967C4.16602 4.55765 4.34161 4.13372 4.65417 3.82116C4.96673 3.5086 5.39065 3.33301 5.83268 3.33301H12.4993C12.9414 3.33301 13.3653 3.5086 13.6779 3.82116C13.9904 4.13372 14.166 4.55765 14.166 4.99967V11.6663"
                  stroke="#212946"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M11.666 15.8337L13.3327 17.5003L16.666 14.167"
                  stroke="#212946"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M7.5 6.66699H10.8333"
                  stroke="#212946"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M7.5 10H9.16667"
                  stroke="#212946"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </g>
              <defs>
                <clipPath id="clip0_3567_18592">
                  <rect width="20" height="20" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </div>
          <span style={{ whiteSpace: 'nowrap' }}>
            {t('jobsCount', { count: tour.tourJobs.jobs.length })} •{' '}
            {t('differentLocationsCount', {
              count: differentLocationsCount,
            })}
          </span>
        </div>
      </div>
    </div>
  );
};
