import { LogoOverlay } from '@energy-stacks/shared';
import { DarkLogo } from '@energy-stacks/theme';
import { useTheme } from '@mui/material';

export const FleetLogoOverlay = () => {
  const { palette } = useTheme();
  return (
    <LogoOverlay logo={<DarkLogo />} backgroundColor={palette.dark.main} />
  );
};
