import { useSoftResetChargingStationMutation } from '@energy-stacks/broker/feature-charging-station-management-data';
import { useESSnackbar } from '@energy-stacks/core/ui';
import {
  SoftResetDialog,
  useChargingStationIdentityKey,
} from '@energy-stacks/shared';
import {
  endChargingStationCoreManagementAction,
  startChargingStationCoreManagementAction,
  useAppDispatch,
} from '@energy-stacks/broker/store';
import { useCallback } from 'react';

interface BrokerSoftReset {
  isOpen: boolean;
  onClose: () => void;
}

export const BrokerSoftReset: React.FC<BrokerSoftReset> = ({
  isOpen,
  onClose,
}) => {
  const [softResetChargingStation] = useSoftResetChargingStationMutation();
  const identityKey = useChargingStationIdentityKey();
  const { showSnackbar } = useESSnackbar();
  const dispatch = useAppDispatch();

  const handleSoftReset = useCallback(() => {
    dispatch(startChargingStationCoreManagementAction());
    softResetChargingStation(identityKey)
      .unwrap()
      .then((response) => {
        if (response.status === 'Accepted') {
          showSnackbar('success', 'softResetAccepted', 'chargingStations');
          return;
        }
        if (response.status === 'Rejected') {
          showSnackbar('error', 'softResetRejected', 'chargingStations');
          return;
        }
      })
      .catch((error) => {
        if (error.data.errorCode === 'RPC_TIMEOUT') {
          showSnackbar('error', 'rpcTimeoutError', 'chargingStations');
          return;
        }
        if (error.data.errorCode === 'RPC_ERROR') {
          showSnackbar('error', 'rpcError', 'chargingStations');
          return;
        }
        showSnackbar('error');
      })
      .finally(() => {
        dispatch(endChargingStationCoreManagementAction());
      });
  }, [dispatch, identityKey, showSnackbar, softResetChargingStation]);

  return (
    <SoftResetDialog
      isOpen={isOpen}
      onClose={onClose}
      onCallToAction={() => {
        handleSoftReset();
        onClose();
      }}
    />
  );
};
