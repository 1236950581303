import { BerryThemeProvider } from '@energy-stacks/core/berry-mui';
import { usePopover } from '@energy-stacks/core/ui';
import { IconChevronUp } from '@tabler/icons-react';
import {
  IconButton,
  Typography,
  Box,
  MenuItem,
  Radio,
  Menu,
} from '@mui/material';
import {
  useAppDispatch,
  useAppSelector,
  setSelectedPlantId,
} from '@energy-stacks/fleet/store';
import { ReactComponent as BiogassFactoryIcon } from './icons/biogassFactoryIcon.svg';

export const PlantIdSelect = () => {
  const { anchorEl, closePopover, openPopover, popoverOpen } = usePopover();
  const dispatch = useAppDispatch();
  const plantIds = useAppSelector((state) => state.plant.plantIds);
  const selectedPlantId = useAppSelector(
    (state) => state.plant.selectedPlantId
  );
  const sidebarOpened = useAppSelector((state) => state.sidebar.sidebarOpened);

  if (!plantIds) {
    return null;
  }

  return (
    <>
      <IconButton
        size="large"
        aria-label="menu"
        sx={(theme) => ({
          color: 'dark.light',
          borderRadius: '8px',
          padding: `${!sidebarOpened ? '16px 12px' : '6px 12px'}`,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: !sidebarOpened ? undefined : 'dark.600',
          ':hover': {
            backgroundColor: theme.palette.background.paper,
          },
          gap: '6px',
        })}
        onClick={openPopover}
        data-testid="plantIdSelectButton"
      >
        <BiogassFactoryIcon />
        {sidebarOpened ? (
          <>
            <Typography>{selectedPlantId}</Typography>
            <IconChevronUp />
          </>
        ) : null}
      </IconButton>
      <BerryThemeProvider mode="light">
        <Menu
          id="menu-lang"
          anchorEl={anchorEl}
          disableAutoFocusItem
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          sx={{
            marginTop: -2,
            '& .MuiMenu-list': {
              paddingY: 0,
              minWidth: 165,
              maxHeight: 240,
            },
          }}
          open={popoverOpen}
          onClose={closePopover}
        >
          <Box>
            {plantIds.map((plantId) => {
              return (
                <MenuItem
                  dense
                  selected={plantId === selectedPlantId}
                  key={plantId}
                  onClick={() => {
                    dispatch(setSelectedPlantId(plantId));
                    closePopover();
                  }}
                  data-testid={`${plantId}PlantIdSelectOption`}
                >
                  <Radio
                    checked={plantId === selectedPlantId}
                    value={plantId}
                    sx={{ p: 0, mr: 2.5 }}
                  />
                  <Typography>{plantId}</Typography>
                </MenuItem>
              );
            })}
          </Box>
        </Menu>
      </BerryThemeProvider>
    </>
  );
};
