import { ChargingStationStatsModel } from '../chargingStationModel';
import {
  ChargingStationStatsDto,
  OcppStatusDto,
  OcppStatus,
} from '../chargingStationsModelDto';

export const chargingStationStatsNormalizer = (
  response: ChargingStationStatsDto
): ChargingStationStatsModel => {
  return {
    chargingStationsTotal: response.chargingStationsTotal,
    chargingStationsOnline: response.chargingStationsTotal,
    chargingStationsCsmsConnected: response.chargingStationsCsmsConnected,
    modelStats: response.modelStats.map((model) => {
      return {
        modelName: model.modelName,
        count: model.count,
      };
    }),
    ocppProtocolStats: response.ocppProtocolStats.map((protocol) => {
      return {
        ocppProtocol: protocol.ocppProtocol,
        count: protocol.count,
      };
    }),
    ocppStatusStats: response.ocppStatusStats.map((status) => {
      return {
        ocppStatus: ocppStatusNormalizer(status.ocppStatus),
        count: status.count,
      };
    }),
    connectorStats: {
      connectorsTotal: response.connectorStats.connectorsTotal,
      ocppStatusStats: response.connectorStats.ocppStatusStats.map((status) => {
        return {
          ocppStatus: ocppStatusNormalizer(status.ocppStatus),
          count: status.count,
        };
      }),
    },
  };
};

const ocppStatusNormalizer = (
  occpStatus: OcppStatusDto | undefined
): OcppStatus => {
  if (!occpStatus) {
    return 'undefined';
  }
  return ocppStatusMap[occpStatus];
};

const ocppStatusMap: Record<OcppStatusDto, OcppStatus> = {
  AVAILABLE: 'available',
  CHARGING: 'charging',
  FAULTED: 'faulted',
  FINISHING: 'finishing',
  OCCUPIED: 'occupied',
  PREPARING: 'preparing',
  RESERVED: 'reserved',
  SUSPENDED_EV: 'suspendedEV',
  SUSPENDED_EVSE: 'suspendedEVSE',
  UNAVAILABLE: 'unavailable',
  UNDEFINED: 'undefined',
};
