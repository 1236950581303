import { Card, Grid, Typography, useMediaQuery } from '@mui/material';
import { Box } from '@mui/system';
import { useChargingStationDetails } from '../useChargingStationDetails';
import { ConnectorCard } from '../connectors/ConnectorCard';
import { ConnectorSkeleton } from '../connectors/ConnectorSkeleton';
import { TableEmptyMessage } from '@energy-stacks/shared';
import { useTranslation } from 'react-i18next';

export type ViewMode = 'grid' | 'list';

export const Connectors = () => {
  const [t] = useTranslation('chargingStations');
  const { chargingStationDetails, isLoading } = useChargingStationDetails({
    listenToUpdates: true,
  });
  const numberOfConnectors = chargingStationDetails?.connectors.length;
  const isFullHD = useMediaQuery('(min-width: 1920px)');
  const viewMode: ViewMode = isFullHD ? 'list' : 'grid';

  if (isLoading) {
    return <ConnectorSkeleton numberOfConnectors={numberOfConnectors || 1} />;
  }

  if (!numberOfConnectors) {
    return (
      <Card
        data-testid="emptyDetailsConnectorCard"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 4,
          padding: 4,
          border: 'none',
          height: '400px',

          '& > div.MuiBox-root': {
            minHeight: '145px',
          },
        }}
      >
        <Typography
          data-testid="emptyDetailsConnectorCardTitle"
          variant="h4"
          fontWeight={700}
          lineHeight={1.5}
          color={'grey.900'}
        >
          {t('connectors')}
        </Typography>
        <TableEmptyMessage
          sx={{ h4: { marginTop: '15px' }, svg: { height: '135px' } }}
        />
      </Card>
    );
  }

  return (
    <Box
      sx={{
        height: isFullHD ? 'unset' : numberOfConnectors > 1 ? '100%' : 'auto',
        minHeight:
          numberOfConnectors > 1
            ? !isFullHD
              ? '693px'
              : `calc(156px + (min(${numberOfConnectors},3) * 108px))`
            : 'unset',
        maxHeight: isFullHD
          ? 'calc(56px + (3 * 108px))'
          : 'calc(100vh - 290px - 72px - 150px)',
      }}
    >
      <Card
        data-testid="detailsConnectorCard"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 4,
          padding: 4,
          border: 'none',
          height: '100%',
          overflow: 'auto',
          position: 'relative',
        }}
      >
        <Typography
          data-testid="detailsConnectorCardTitle"
          variant="h4"
          fontWeight={700}
          lineHeight={1.5}
          color={'grey.900'}
        >
          {t('connectors')}
        </Typography>
        {chargingStationDetails?.connectors.map((connector, index) => (
          <Grid
            key={connector.connectorId}
            item
            xs={12}
            lg={12}
            xl={12}
            sx={{ flexBasis: 'auto !important' }}
          >
            <ConnectorCard
              viewMode={viewMode}
              testId={`${index}`}
              connector={connector}
              chargingStationIdentityKey={chargingStationDetails.identityKey}
              chargingStationOnline={
                chargingStationDetails.csStatus === 'online'
              }
            />
          </Grid>
        ))}
      </Card>
    </Box>
  );
};
