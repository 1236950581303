import { Typography } from '@mui/material';

interface FormStepContentHeaderTitleProps {
  children: React.ReactNode;
}

export const FormStepContentHeaderTitle: React.FC<
  FormStepContentHeaderTitleProps
> = ({ children }) => {
  return <Typography variant="h4">{children}</Typography>;
};
