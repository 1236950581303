import { SidebarGroup } from '@energy-stacks/core/sidebar';
import { IconRoute } from '@tabler/icons-react';
import { FleetRoutes } from '@energy-stacks/fleet/shared';
import { useTranslation } from 'react-i18next';

export const ToursSidebarItem = () => {
  const [t] = useTranslation('tours');

  return (
    <SidebarGroup
      groupInitialPath={FleetRoutes.Tours}
      groupIcon={<IconRoute />}
      groupLabel={t('sidebarItem')}
      testId="toursSidebarButton"
    ></SidebarGroup>
  );
};
