import { IconButton, InputAdornment, TextField } from '@mui/material';
import React, { useMemo, useState } from 'react';
import { IconSearch, IconX } from '@tabler/icons-react';
import { useTranslation } from 'react-i18next';
import { Table } from '@tanstack/react-table';
import { debounce as lodashDebounce } from 'lodash-es';

export const TableSearchField: React.FC<{
  value: string;
  placeholder?: string;
  onChange: (value: string) => void;
  debounce?: number;
  autoFocus?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  tableInstance: Table<any>;
  testId?: string;
}> = React.memo(
  ({
    value: initialValue,
    placeholder,
    onChange,
    debounce = 0,
    autoFocus,
    tableInstance,
    testId,
  }) => {
    const [value, setValue] = useState(initialValue);
    const [tShared] = useTranslation('shared');

    const clearInput = () => {
      setValue('');
      onChange('');
    };

    const debouncedOnChange = useMemo(
      () =>
        lodashDebounce((e) => {
          onChange(e.target.value);
          tableInstance.setPageIndex(0);
        }, debounce),
      [onChange, debounce, tableInstance]
    );

    return (
      <TextField
        id="outlined-adornment-amount"
        size="small"
        variant="outlined"
        sx={{
          width: 295,
        }}
        inputProps={{
          'data-testid': `${testId}SearchField`,
          size: placeholder?.length,
          sx: {
            textOverflow: 'ellipsis',
          },
        }}
        value={value}
        placeholder={placeholder || tShared('search')}
        onChange={(e) => {
          setValue(e.target.value);
          debouncedOnChange(e);
        }}
        onKeyDown={(e) => e.stopPropagation()} // Prevents selecting list item instead of entering text in the search field
        InputProps={{
          autoFocus,
          autoComplete: 'off',
          startAdornment: (
            <InputAdornment
              position="start"
              sx={{ '& > svg': { pointerEvents: 'none' } }}
            >
              <IconSearch width="16px" />
            </InputAdornment>
          ),
          endAdornment: value ? (
            <InputAdornment position="end">
              <IconButton
                onClick={clearInput}
                edge="end"
                color="inherit"
                size="small"
                aria-label="clear"
                sx={{
                  marginRight: '2px',
                  padding: 1,
                }}
              >
                <IconX fontSize="small" />
              </IconButton>
            </InputAdornment>
          ) : null,
        }}
      />
    );
  }
);
