import { range, unionBy } from 'lodash-es';
import { RegularHoursDto } from '../regularHoursDto';
import { RegularHoursModel, Weekday } from '../regularHoursModel';

export const regularHoursRule = (
  weeklyRegularHours: RegularHoursDto[] | undefined
): RegularHoursModel[] => {
  if (!weeklyRegularHours)
    return defaultWeekWorkingHours.map((day) => ({
      ...day,
      weekday: weekdayMap[day.weekday],
    }));
  return regularHoursNormalizer(weeklyRegularHours);
};

export const regularHoursNormalizer = (
  regularHours: RegularHoursDto[]
): RegularHoursModel[] => {
  const workingHours = regularHours.map((day) => ({
    periodBegin: day.period_begin,
    periodEnd: day.period_end,
    weekday: day.weekday,
  }));

  return unionBy(workingHours, defaultWeekWorkingHours, 'weekday')
    .sort((a, b) => a.weekday - b.weekday)
    .map((day) => ({
      weekday: weekdayMap[day.weekday],
      periodBegin: day.periodBegin,
      periodEnd: day.periodEnd,
    }));
};

const weekdayMap: Record<number, Weekday> = {
  1: 'monday',
  2: 'tuesday',
  3: 'wednesday',
  4: 'thursday',
  5: 'friday',
  6: 'saturday',
  7: 'sunday',
};

const defaultWeekWorkingHours = range(1, 8).map((i) => ({
  weekday: i,
  periodBegin: '',
  periodEnd: '',
}));
