import {
  OptimizedTourModel,
  TourModel,
} from '@energy-stacks/fleet/feature-tours-data';
import { Row } from '@tanstack/react-table';

export const sortByQuantity = <T extends TourModel | OptimizedTourModel>(
  rowA: Row<T>,
  rowB: Row<T>
) => {
  const sumA = Object.values(rowA.original.quantity).reduce(
    (sum, value) => (sum += value),
    0
  );

  const sumB = Object.values(rowB.original.quantity).reduce(
    (sum, value) => (sum += value),
    0
  );

  return sumA - sumB;
};
