import { VehicleDetailsModel } from '@energy-stacks/fleet/feature-vehicles-data';

export const getEditVehicleDefaultGeneralInfo = (
  vehicle: VehicleDetailsModel | undefined
) => {
  return {
    plantId: { label: vehicle?.plantId ?? '', value: vehicle?.plantId ?? '' },
    name: vehicle?.name ?? '',
    brand: { label: vehicle?.brand ?? '', value: vehicle?.brand ?? '' },
    model: { label: vehicle?.model ?? '', value: vehicle?.model ?? '' },
    variant: { label: vehicle?.variant ?? '', value: vehicle?.variant ?? '' },
    vehicleId: vehicle?.vehicleId ?? '',
    licencePlate: vehicle?.licencePlate ?? '',
  };
};
