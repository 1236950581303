import { environment } from '@energy-stacks/feature-config';
import { createApi } from '@reduxjs/toolkit/query/react';
import { appendZToDateString, createBaseQuery } from '@energy-stacks/shared';
import { ImprintDto } from './imprintDto';

export interface ImprintFormData {
  content: string;
}

export const csmsImprintApi = createApi({
  reducerPath: 'csmsImprintApi',
  tagTypes: ['CsmsImprint'],
  baseQuery: createBaseQuery(`${environment.ocppServiceUrl}/imprint`),
  endpoints: (builder) => ({
    getImprint: builder.query<ImprintDto, void>({
      query: () => '/',
      providesTags: ['CsmsImprint'],
      transformResponse: (imprint: ImprintDto) => ({
        ...imprint,
        dateCreated: appendZToDateString(imprint.dateCreated),
        dateLastUpdate: appendZToDateString(imprint.dateLastUpdated),
      }),
    }),
    updateImprint: builder.mutation<ImprintDto, ImprintFormData>({
      query: (body) => ({
        url: '/',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['CsmsImprint'],
    }),
  }),
});

export const { useGetImprintQuery, useUpdateImprintMutation } = csmsImprintApi;
