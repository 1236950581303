import {
  BusinessAccountDetailsModel,
  useGetBusinessAccountDetailsQuery,
} from '@energy-stacks/fleet/feature-business-accounts-data';
import { useBusinessAccounts } from '../useBusinessAccounts';
import { useParams } from 'react-router-dom';

export const useBusinessAccountDetails = () => {
  const { businessAccountId } = useParams<{ businessAccountId: string }>();
  // Get businessAccounts for the currently selected plant
  const {
    data: businessAccounts,
    isError: isErrorBusinessAccounts,
    isSuccess: isSuccessBusinessAccounts,
    isLoading: isLoadingBusinessAccounts,
  } = useBusinessAccounts();
  const isBusinessAccountFromCurrentPlant = !!(businessAccounts ?? []).find(
    (account) => account.id === businessAccountId
  );
  const { data, isLoading, isError, isSuccess, refetch } =
    useGetBusinessAccountDetailsQuery(businessAccountId || '', {
      skip: !businessAccountId,
    });

  let businessAccount: BusinessAccountDetailsModel | undefined = undefined;
  // If businessAccount is not found in the list of businessAccounts it means that it doesn't belong
  // to the currently selected plant, so we don't want to return it
  if (isBusinessAccountFromCurrentPlant) {
    businessAccount = data;
  }

  return {
    businessAccount,
    isError: isError || isErrorBusinessAccounts,
    isSuccess: isSuccess && isSuccessBusinessAccounts,
    isLoading: isLoading || isLoadingBusinessAccounts,
    refetch,
  };
};
