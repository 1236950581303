import { Box, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
  OptimizeOptionCard,
  OptimizeOptionVariant,
} from './OptimizeOptionCard';

export interface OptimizeProps {
  onOptimizeSelected: (option: OptimizeOptionVariant) => void;
  selectedOption: OptimizeOptionVariant;
}

export const Optimize: React.FC<OptimizeProps> = ({
  onOptimizeSelected,
  selectedOption,
}) => {
  const [t] = useTranslation('tours');

  return (
    <Box
      sx={{
        overflow: 'auto',
        p: 4,
      }}
    >
      <Typography
        variant="h3"
        align="center"
        sx={{
          color: 'grey.900',
          fontWeight: 700,
          mx: 'auto',
        }}
      >
        {t('optimizeStepTitle')}
      </Typography>
      <Grid
        container
        spacing={6}
        sx={{
          mt: 6.5,
        }}
      >
        <Grid item sm={12} md={6} lg={4} xl={4}>
          <OptimizeOptionCard
            variant="time"
            selected={selectedOption === 'time'}
            onSelect={() => onOptimizeSelected('time')}
          />
        </Grid>
        <Grid item sm={12} md={6} lg={4} xl={4}>
          <OptimizeOptionCard
            variant="costs"
            selected={selectedOption === 'costs'}
            disabled
            onSelect={() => onOptimizeSelected('costs')}
          />
        </Grid>
        <Grid item sm={12} md={6} lg={4} xl={4}>
          <OptimizeOptionCard
            variant="drivers"
            selected={selectedOption === 'drivers'}
            disabled
            onSelect={() => onOptimizeSelected('drivers')}
          />
        </Grid>
      </Grid>
    </Box>
  );
};
