import { Box, Paper, Stack, Typography, useTheme } from '@mui/material';
import { useChargingStationDetails } from './useChargingStationDetails';
import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import { ChargingStationDetailsSkeleton } from './ChargingStationDetailsSkeleton';
import { ChargingStationDetailsValueAndTooltip } from './ChargingStationDetailsValueAndTooltip';

type BrokerChargingStationGeneralDetailsModel = {
  chargingStationDetails: {
    chargingStationName: string;
    chargingSite: string;
    vendor: string;
    vendorModel: string;
    identityKey: string;
    chargingStationModel: string;
    meterType: string;
    meterSerialNumber: string;
    firmware: string;
    centralSystem: string;
    iccid: string;
    imsi: string;
    ocppProtocol: string;
    dateLastBoot: string;
    dateLastHeartBeat: string;
    dateLastWebsocketPing: string;
  };
};

const NO_VALUE = 'N/A';

export const BrokerChargingStationGeneralDetails = () => {
  const { chargingStationDetails, isLoading } = useChargingStationDetails({
    listenToUpdates: true,
  });
  const [t] = useTranslation('chargingStations');
  const { palette } = useTheme();

  const generalDetailsValues: BrokerChargingStationGeneralDetailsModel =
    useMemo(
      () => ({
        chargingStationDetails: {
          chargingStationName: chargingStationDetails?.name || NO_VALUE,
          chargingSite: chargingStationDetails?.location.name || NO_VALUE,
          vendor: chargingStationDetails?.vendor || NO_VALUE,
          vendorModel: chargingStationDetails?.vendorModel || NO_VALUE,
          identityKey: chargingStationDetails?.identityKey || NO_VALUE,
          chargingStationModel: chargingStationDetails?.model || NO_VALUE,
          meterType: chargingStationDetails?.meterType || NO_VALUE,
          meterSerialNumber:
            chargingStationDetails?.meterSerialNumber || NO_VALUE,
          firmware: chargingStationDetails?.firmware || NO_VALUE,
          centralSystem: chargingStationDetails?.csmsDisplayName || NO_VALUE,
          iccid: chargingStationDetails?.iccid || NO_VALUE,
          imsi: chargingStationDetails?.imsi || NO_VALUE,
          ocppProtocol: chargingStationDetails?.ocppProtocol || NO_VALUE,
          dateLastBoot: chargingStationDetails?.dateLastBoot || NO_VALUE,
          dateLastHeartBeat:
            chargingStationDetails?.dateLastHeartBeat || NO_VALUE,
          dateLastWebsocketPing:
            chargingStationDetails?.dateLastWebsocketPing || NO_VALUE,
        },
      }),
      [chargingStationDetails]
    );

  if (isLoading) {
    return <ChargingStationDetailsSkeleton />;
  }

  return (
    <Paper
      sx={{
        border: 'none',
        height: '100%',
        p: 4,
      }}
    >
      {Object.entries(generalDetailsValues).map((section, index) => (
        <Box
          data-testid={`chargingStationDetailsCardHeader${index}`}
          key={section[0]}
          sx={{
            borderBottom:
              index === Object.entries(generalDetailsValues).length - 1
                ? 'none'
                : `1px solid ${palette.grey[100]}`,
          }}
        >
          <Typography
            variant="h4"
            fontWeight={700}
            lineHeight={1.5}
            color={'grey.900'}
            sx={{ paddingBottom: 4 }}
          >
            {t(section[0])}
          </Typography>

          <Stack>
            {Object.entries(section[1]).map((field, counter) => (
              <ChargingStationDetailsValueAndTooltip
                chargingStationDetailsValue={field[1]}
                chargingStationDetailsLabel={field[0]}
                testId={counter}
                key={field[0]}
              />
            ))}
          </Stack>
        </Box>
      ))}
    </Paper>
  );
};
