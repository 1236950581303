import TranslateIcon from '@mui/icons-material/Translate';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { EventSourceChip } from './EventSourceChip';

interface TranslatorEventProps {
  inheritCursor?: boolean;
}

export const TranslatorEventSource: React.FC<TranslatorEventProps> = ({
  inheritCursor,
}) => {
  const { t } = useTranslation('chargingStations');
  return (
    <EventSourceChip
      icon={<TranslateIcon />}
      label={t('logsTranslator')}
      inheritCursor={inheritCursor}
    />
  );
};
