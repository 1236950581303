import { useTranslation } from 'react-i18next';
import { ESButton } from '@energy-stacks/core/ui';
import { ReactComponent as RefreshIcon } from '@energy-stacks/fleet-is/shared';

interface UpdateToursProps {
  onUpdate: () => void;
  disabled?: boolean;
}

export const UpdateTours: React.FC<UpdateToursProps> = ({
  onUpdate,
  disabled,
}) => {
  const { t } = useTranslation('chargingSchedule');

  return (
    <ESButton
      startIcon={<RefreshIcon />}
      onClick={onUpdate}
      disabled={disabled}
    >
      {t('updateTours')}
    </ESButton>
  );
};
