export type TestMapSize = 'small' | 'large';

type Props = { children: React.ReactNode; size?: TestMapSize };

const sizes: Record<TestMapSize, { width: number; height: number }> = {
  small: {
    width: 600,
    height: 450,
  },
  large: {
    width: 1200,
    height: 900,
  },
};

export const TestMapContainer: React.FC<Props> = ({
  children,
  size = 'small',
}) => {
  return <div style={sizes[size]}>{children}</div>;
};
