import { Typography } from '@mui/material';

interface FormStepContentHeaderSubtitleProps {
  children: React.ReactNode;
}

export const FormStepContentHeaderSubtitle: React.FC<
  FormStepContentHeaderSubtitleProps
> = ({ children }) => {
  return (
    <Typography variant="subtitle1" color="text.secondary">
      {children}
    </Typography>
  );
};
