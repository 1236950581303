import {
  BusinessAccountDetailsDto,
  BusinessAccountLocationDto,
} from '../businessAccountDetailsDto';
import {
  BusinessAccountDetailsModel,
  BusinessAccountLocation,
} from '../businessAccountDetailsModel';

export const businessAccountLocationsNormalizer = (
  businessAccountLocations: BusinessAccountDetailsDto['locationAddressTypeGroups']
): BusinessAccountDetailsModel['locations'] => {
  if (!businessAccountLocations) {
    return {
      raw: [],
      degassed: [],
    };
  }

  return {
    raw: (businessAccountLocations['Raw Tank'] ?? []).map(
      businessAccountLocationNormalizer
    ),
    degassed: (businessAccountLocations['Degassed Tank'] ?? []).map(
      businessAccountLocationNormalizer
    ),
  };
};

const businessAccountLocationNormalizer = (
  businessAccountLocation: BusinessAccountLocationDto
): BusinessAccountLocation => {
  return {
    id: businessAccountLocation.uid,
    type: businessAccountLocation.type,
    risk: businessAccountLocation.risk,
    address: {
      street: businessAccountLocation.address.street,
      description: businessAccountLocation.address.description,
      geoLocation: {
        latitude: businessAccountLocation.address.geoLocation.latitude,
        longitude: businessAccountLocation.address.geoLocation.longitude,
      },
    },
  };
};
