import { BrokerUserDto } from '../brokerUserDto';
import { BrokerUserModel } from '../brokerUserModel';
import { userRoleNormalizer } from './userRoleNormalizer';

export const brokerUserModelNormalizer = (
  userDto: BrokerUserDto
): BrokerUserModel => {
  return {
    userRole: userRoleNormalizer(userDto.userRole),
    isActive: userDto.isActive,
    emailAddress: userDto.emailAddress,
    displayName: userDto.displayName,
  };
};
