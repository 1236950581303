import {
  AnyAction,
  combineReducers,
  configureStore,
  ThunkAction,
} from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { persistStore, persistReducer } from 'redux-persist';
import { chargingStationsApi as csmsChargingStationsApi } from '@energy-stacks/csms/feature-charging-stations-data';
import { USER_FEATURE_KEY, userReducer } from '@energy-stacks/store';
import { sidebarReducer, SIDEBAR_FEATURE_KEY } from '@energy-stacks/store';
import {
  addChargingStationReducer,
  ADD_CHARGING_STATION_FEATURE_KEY,
} from './add-charging-station.slice';
import {
  configurationReducer,
  CONFIGURATION_FEATURE_KEY,
} from './configuration.slice';
import { setupListeners } from '@reduxjs/toolkit/dist/query';
import { locationsApi } from '@energy-stacks/csms/feature-locations-data';
import { usersCsmsApi } from '@energy-stacks/csms/feature-users-data';
import { mobilityTokensApi } from '@energy-stacks/csms/feature-mobility-tokens-data';
import { csmsManagementApi } from '@energy-stacks/csms/feature-charging-station-management-data';
import { ocppMessageLogsApi as csmsOcppMessageLogsApi } from '@energy-stacks/csms/feature-ocpp-message-logs-data';
import storage from 'redux-persist/lib/storage';
import { centralSystemsApi as csmsCentralSystemsApi } from '@energy-stacks/csms/feature-central-systems-data';
import { providersApi } from '@energy-stacks/csms/feature-providers-data';
import { stepperReducer, STEPPER_FEATURE_KEY } from '@energy-stacks/store';
import {
  addChargingLocationReducer,
  ADD_CHARGING_LOCATION_FEATURE_KEY,
} from './add-charging-location.slice';
import {
  locationDetailsReducer,
  LOCATION_DETAILS_FEATURE_KEY,
} from './location-details.slice';
import { chargingStationGroupsApi } from '@energy-stacks/csms/feature-charging-station-groups-data';
import { csmsImprintApi } from '@energy-stacks/csms/feature-imprint-data';
import { csmsTransactionsApi } from '@energy-stacks/csms/feature-transactions-data';
import { ownerEntityApi } from '@energy-stacks/csms/feature-entity-management-data';
import { entityDetailsReducer } from './entity-details.slice';
import { THEME_FEATURE_KEY, themeReducer } from '@energy-stacks/store';
import { APP_FEATURE_KEY, appReducer } from '@energy-stacks/store';
import { apiSpecsApi } from '@energy-stacks/api-portal/feature-api-overview-data';

const persistConfig = {
  key: 'ES',
  storage,
  // Must keep a whitelist value here or
  // the whole store will be persisted
  whitelist: [THEME_FEATURE_KEY],
};

export const rootReducer = combineReducers({
  [THEME_FEATURE_KEY]: themeReducer,
  [USER_FEATURE_KEY]: userReducer,
  [SIDEBAR_FEATURE_KEY]: sidebarReducer,
  [ADD_CHARGING_STATION_FEATURE_KEY]: addChargingStationReducer,
  [ADD_CHARGING_LOCATION_FEATURE_KEY]: addChargingLocationReducer,
  [CONFIGURATION_FEATURE_KEY]: configurationReducer,
  [LOCATION_DETAILS_FEATURE_KEY]: locationDetailsReducer,
  [STEPPER_FEATURE_KEY]: stepperReducer,
  [APP_FEATURE_KEY]: appReducer,
  [csmsCentralSystemsApi.reducerPath]: csmsCentralSystemsApi.reducer,
  [locationsApi.reducerPath]: locationsApi.reducer,
  [usersCsmsApi.reducerPath]: usersCsmsApi.reducer,
  [mobilityTokensApi.reducerPath]: mobilityTokensApi.reducer,
  [csmsChargingStationsApi.reducerPath]: csmsChargingStationsApi.reducer,
  [csmsOcppMessageLogsApi.reducerPath]: csmsOcppMessageLogsApi.reducer,
  [csmsManagementApi.reducerPath]: csmsManagementApi.reducer,
  [providersApi.reducerPath]: providersApi.reducer,
  [chargingStationGroupsApi.reducerPath]: chargingStationGroupsApi.reducer,
  [csmsImprintApi.reducerPath]: csmsImprintApi.reducer,
  [csmsTransactionsApi.reducerPath]: csmsTransactionsApi.reducer,
  [ownerEntityApi.reducerPath]: ownerEntityApi.reducer,
  entityDetailsSlice: entityDetailsReducer,
  [apiSpecsApi.reducerPath]: apiSpecsApi.reducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const initStore = (preloadedState = {}) =>
  configureStore({
    preloadedState,
    reducer: persistedReducer,
    // Additional middleware can be passed to this array
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: {
          ignoredActions: ['persist/PERSIST'],
        },
      }).concat(
        locationsApi.middleware,
        usersCsmsApi.middleware,
        csmsChargingStationsApi.middleware,
        mobilityTokensApi.middleware,
        csmsOcppMessageLogsApi.middleware,
        csmsManagementApi.middleware,
        csmsCentralSystemsApi.middleware,
        providersApi.middleware,
        chargingStationGroupsApi.middleware,
        csmsImprintApi.middleware,
        csmsTransactionsApi.middleware,
        ownerEntityApi.middleware,
        apiSpecsApi.middleware
      ),
    devTools:
      (typeof process !== 'undefined' &&
        process.env['NODE_ENV'] !== 'production') ||
      !!import.meta,

    // Optional Redux store enhancers
    enhancers: [],
  });

export const store = initStore();
export const persistor = persistStore(store);

setupListeners(store.dispatch);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof rootReducer>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  AnyAction
>;
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
