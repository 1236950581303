import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const CcsType1MaleIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props}>
      <path d="M15.2563 18.5768C15.6028 18.2863 15.6482 17.77 15.3577 17.4235C15.0672 17.077 14.5508 17.0317 14.2043 17.3221C13.8578 17.6126 13.8125 18.129 14.103 18.4755C14.3935 18.822 14.9098 18.8673 15.2563 18.5768Z" />
      <path d="M9.68985 18.692C10.0993 18.5001 10.2756 18.0127 10.0837 17.6033C9.89182 17.1939 9.40438 17.0176 8.99497 17.2094C8.58556 17.4013 8.40922 17.8888 8.6011 18.2982C8.79299 18.7076 9.28044 18.8839 9.68985 18.692Z" />
      <path d="M17.7116 15.0903C17.1461 14.5038 16.3535 14.1935 15.4193 14.1935H8.63082C7.69664 14.1935 6.90403 14.5034 6.33855 15.0903C5.71006 15.7421 5.39154 16.6892 5.39154 17.9049C5.39154 19.1207 5.71006 20.0677 6.33855 20.7195C6.90403 21.306 7.69664 21.6163 8.63082 21.6163H15.4193C16.3535 21.6163 17.1461 21.3064 17.7116 20.7195C18.3401 20.0677 18.6586 19.1207 18.6586 17.9049C18.6586 16.6892 18.3401 15.7421 17.7116 15.0903ZM17.271 20.3205C16.7809 20.8128 16.0891 21.073 15.2692 21.073H8.78678C7.96694 21.073 7.27467 20.8128 6.78503 20.3205C6.23315 19.7659 5.95352 18.9484 5.95352 17.8913C5.95352 16.8338 6.23315 16.0167 6.78503 15.4621C7.27506 14.9698 7.96733 14.7096 8.78678 14.7096H15.2692C16.0891 14.7096 16.7813 14.9698 17.271 15.4621C17.8228 16.0167 18.1025 16.8338 18.1025 17.8913C18.1025 18.9484 17.8228 19.7659 17.271 20.3205Z" />
      <path d="M15.2692 15.2541H8.78678C7.31084 15.2541 6.49762 16.1906 6.49762 17.8913C6.49762 19.592 7.31045 20.5285 8.78678 20.5285H15.2692C16.7452 20.5285 17.5584 19.592 17.5584 17.8913C17.5584 16.1906 16.7452 15.2541 15.2692 15.2541ZM9.34137 19.9969C8.21313 19.9969 7.2949 19.0787 7.2949 17.9504C7.2949 16.8222 8.21313 15.9039 9.34137 15.9039C10.4696 15.9039 11.3878 16.8222 11.3878 17.9504C11.3878 19.0787 10.47 19.9969 9.34137 19.9969ZM14.7294 19.9969C13.6012 19.9969 12.6829 19.0787 12.6829 17.9504C12.6829 16.8222 13.6012 15.9039 14.7294 15.9039C15.8577 15.9039 16.7759 16.8222 16.7759 17.9504C16.7759 19.0787 15.858 19.9969 14.7294 19.9969Z" />
      <path d="M13.8454 6.9721C14.0659 6.9721 14.2452 6.79281 14.2452 6.57229C14.2452 6.35178 14.0659 6.17249 13.8454 6.17249C13.6249 6.17249 13.4456 6.35178 13.4456 6.57229C13.4456 6.79281 13.6249 6.9721 13.8454 6.9721Z" />
      <path d="M10.2662 6.9721C10.4867 6.9721 10.666 6.79281 10.666 6.57229C10.666 6.35178 10.4867 6.17249 10.2662 6.17249C10.0457 6.17249 9.86639 6.35178 9.86639 6.57229C9.86639 6.79281 10.0457 6.9721 10.2662 6.9721Z" />
      <path d="M12.1066 11.1603C12.3271 11.1603 12.5064 10.981 12.5064 10.7605C12.5064 10.54 12.3271 10.3607 12.1066 10.3607C11.886 10.3607 11.7068 10.54 11.7068 10.7605C11.7068 10.981 11.886 11.1603 12.1066 11.1603Z" />
      <path d="M9.45767 9.66962C9.57046 9.66962 9.66224 9.57784 9.66224 9.46544C9.66224 9.35305 9.57046 9.26126 9.45767 9.26126C9.34488 9.26126 9.25348 9.35305 9.25348 9.46544C9.25348 9.57784 9.34488 9.66962 9.45767 9.66962Z" />
      <path d="M12.0614 12.835C14.6431 12.835 16.7432 10.7349 16.7432 8.15324C16.7432 5.57161 14.6431 3.47147 12.0614 3.47147C9.47982 3.47147 7.37967 5.57161 7.37967 8.15324C7.37967 10.7349 9.47982 12.835 12.0614 12.835ZM15.3672 9.46544C15.3672 9.8528 15.0518 10.1678 14.6645 10.1678C14.2771 10.1678 13.9621 9.8528 13.9621 9.46544C13.9621 9.07808 14.2771 8.76306 14.6645 8.76306C15.0522 8.76306 15.3672 9.07808 15.3672 9.46544ZM12.6899 6.57229C12.7074 5.93564 13.2084 5.41721 13.8454 5.41721C14.4824 5.41721 15.0009 5.93525 15.0009 6.57229C15.0009 7.20934 14.4824 7.74565 13.8454 7.72776C13.1893 7.70948 12.6728 7.193 12.6899 6.57229ZM12.1066 9.60506C12.7436 9.60506 13.262 10.1231 13.262 10.7601C13.262 11.3972 12.7436 11.9156 12.1066 11.9156C11.4695 11.9156 10.9511 11.3972 10.9511 10.7601C10.9511 10.1235 11.4695 9.60506 12.1066 9.60506ZM10.2662 5.41721C10.9032 5.41721 11.4217 5.93525 11.4217 6.57229C11.4217 7.20934 10.9032 7.72776 10.2662 7.72776C9.62916 7.72776 9.11073 7.20934 9.11073 6.57229C9.11073 5.93525 9.62916 5.41721 10.2662 5.41721ZM9.45765 8.76306C9.84501 8.76306 10.1604 9.07808 10.1604 9.46544C10.1604 9.8528 9.84501 10.1678 9.45765 10.1678C9.07029 10.1678 8.75526 9.8528 8.75526 9.46544C8.75526 9.07808 9.07029 8.76306 9.45765 8.76306Z" />
      <path d="M14.4603 9.46544C14.4603 9.57823 14.5521 9.66962 14.6644 9.66962C14.7772 9.66962 14.869 9.57784 14.869 9.46544C14.869 9.35305 14.7772 9.26126 14.6644 9.26126C14.5521 9.26126 14.4603 9.35305 14.4603 9.46544Z" />
      <path d="M12.0614 14.0698C15.3241 14.0698 17.978 11.4155 17.978 8.15325C17.978 4.89101 15.3237 2.23627 12.0614 2.23627C8.79883 2.23627 6.14487 4.89063 6.14487 8.15286C6.14487 11.4151 8.79883 14.0698 12.0614 14.0698ZM12.0614 2.86709C14.976 2.86709 17.3472 5.23832 17.3472 8.15286C17.3472 11.0674 14.976 13.4386 12.0614 13.4386C9.14691 13.4386 6.77569 11.0674 6.77569 8.15286C6.77569 5.23832 9.14652 2.86709 12.0614 2.86709Z" />
    </SvgIcon>
  );
};
