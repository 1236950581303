import { useTranslation } from 'react-i18next';
import { Chip } from '@mui/material';
import { JobStatus } from './types/jobStatuses';

interface JobStatusChipProps {
  status: JobStatus | undefined;
}

interface JobStatusInfo {
  bgColor: string;
  color: string;
}

const jobStatusToChipInfoMap: Record<JobStatus, JobStatusInfo> = {
  DONE: {
    bgColor: 'success.light',
    color: 'success.dark',
  },
  ENROUTE: {
    bgColor: 'primary.light',
    color: 'primary.dark',
  },
  FAILED: {
    bgColor: 'orange.light',
    color: 'orange.dark',
  },
  CANCELLED: {
    bgColor: 'orange.light',
    color: 'orange.dark',
  },
  PLANNED: {
    bgColor: 'secondary.light',
    color: 'secondary.main',
  },
  TO_DO: {
    bgColor: 'cyan.50',
    color: 'cyan.500',
  },
  PENDING: {
    bgColor: 'gray.200',
    color: 'black',
  },
};

export const JobStatusChip: React.FC<JobStatusChipProps> = ({ status }) => {
  const [t] = useTranslation('fleetShared');

  if (!status) {
    return null;
  }
  const { bgColor, color } = jobStatusToChipInfoMap[status];

  return (
    <Chip
      size="medium"
      label={t(`jobStatuses.${status}`)}
      sx={{
        backgroundColor: bgColor,
        color,
        fontWeight: 500,
        cursor: 'inherit',
      }}
    />
  );
};
