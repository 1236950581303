import React from 'react';
import { formatDateTime, toPayloadDate } from '@energy-stacks/shared';
import CardWidget from '../CardWidget';
import { useGetTransactionTimeSlicesQuery } from '@energy-stacks/broker/feature-dashboard-data';
import { Props } from 'react-apexcharts';
import { IconRecharging } from '@tabler/icons-react';
import { useTranslation } from 'react-i18next';

export const ActiveSessionsWidget = () => {
  const { t } = useTranslation('dashboard');
  const last24hr = new Date(new Date().getTime() - 24 * 60 * 60 * 1000);
  const [chosenDate, setChosenDate] = React.useState<Date>(last24hr);
  const { data, isLoading } = useGetTransactionTimeSlicesQuery(
    {
      dateStart: toPayloadDate(chosenDate),
    },
    { pollingInterval: 1000 * 30 }
  );

  const sessions =
    data?.map((transaction) => {
      const activeTransactions = transaction.activeTransactions;
      const dateSlice = transaction.dateSlice;
      return {
        activeSessions: Math.round(activeTransactions),
        timestamp: formatDateTime(dateSlice),
      };
    }) || [];
  const maxNumberofSessions = Math.max(
    ...sessions.map((session) => session.activeSessions)
  );

  const lastTimeSlice = data?.slice(-1)[0];
  const currentActiveSessions = lastTimeSlice?.activeTransactions || 0;

  const chartData: Props = {
    type: 'line',
    height: 90,
    options: {
      chart: {
        animations: {
          enabled: false,
        },
        sparkline: {
          enabled: true,
        },
      },
      dataLabels: {
        enabled: false,
      },
      grid: {
        padding: {
          top: 7,
          bottom: 7,
          left: 7,
          right: 7,
        },
      },
      colors: ['#fff'],
      fill: {
        type: 'solid',
        opacity: 1,
      },
      stroke: {
        curve: 'smooth',
        width: 3,
      },
      yaxis: {
        min: 0,
        max: maxNumberofSessions || 100,
      },
      tooltip: {
        theme: 'dark',
        fixed: {
          enabled: false,
        },
        x: {
          show: true,
        },
        y: {
          title: {
            formatter: (seriesName: string) => seriesName,
          },
          formatter: (val: number) => Math.floor(val) + '',
        },
        marker: {
          show: false,
        },
      },
    },
    series: [
      {
        name: t('activeSessions'),
        data: sessions.map((session) => {
          return {
            x: session.timestamp,
            y: session.activeSessions,
          };
        }),
      },
    ],
  };

  return (
    <CardWidget
      chartData={chartData}
      color="purple"
      isLoading={isLoading}
      value={currentActiveSessions}
      name={t('activeSessions')}
      icon={<IconRecharging />}
      onDateChange={setChosenDate}
    />
  );
};
