import { Chip } from '@mui/material';

interface EventSourceChipProps {
  label: string;
  icon: React.ReactElement;
  inheritCursor?: boolean;
}

export const EventSourceChip: React.FC<EventSourceChipProps> = ({
  icon,
  label,
  inheritCursor,
}) => {
  return (
    <Chip
      icon={icon}
      label={label}
      sx={{
        backgroundColor: 'grey.100',
        color: 'grey.900',
        cursor: inheritCursor ? 'inherit' : undefined,
      }}
    />
  );
};
