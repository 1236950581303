export const editChargingStationErrors: Record<string, string> = {
  NOT_FOUND: 'chargingStationNotFound',
  ALREADY_EXISTS: 'chargingStationExists',
};

export const addChargingStationErrors: Record<string, string> = {
  NOT_FOUND: 'chargingStationNotFound',
  CHARGING_STATION_NAME_ALREADY_EXISTS: 'chargingStationNameExists',
  CHARGING_STATION_IDENTITY_KEY_ALREADY_EXISTS:
    'chargingStationIdentityKeyExists',
  EVSE_ID_ALREADY_EXISTS: 'evseIdAlreadyExists',
  LIST_CONTAINS_DUPLICATE_EVSE_ID: 'listContainsDuplicateEvseIds',
  LOCATION_ID_NOT_FOUND: 'locationIdNotFound',
};

export const deleteChargingStationErrors: Record<string, string> = {
  CHARGING_STATION_IDENTITY_KEY_NOT_FOUND: 'chargingStationIdentityKeyNotFound',
};
