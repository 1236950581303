import { formatDateTime } from './formatDate';

const FILE_NAME_DATE_FORMAT = 'yyyy-MM-dd-HH-mm-ss';

type FileFormat = 'csv' | 'zip';

export const handleFileDownload = async (
  response: Response,
  fileExtention: FileFormat
) => {
  const blob = await response.blob();
  const url = window.URL.createObjectURL(blob);

  const filename =
    response.headers.get('content-disposition')?.split('=')[1] ??
    `${formatDateTime(
      new Date().toUTCString(),
      FILE_NAME_DATE_FORMAT
    )}.${fileExtention}`;

  const link = document.createElement('a');

  link.style.visibility = 'hidden';
  link.href = url;
  link.setAttribute('download', filename);

  document.body.appendChild(link);

  link.click();

  document.body.removeChild(link);
};
