import { PlantDto } from '../plantDto';
import { PlantModel } from '../plantModel';

export const plantsNormalizer = (plantDtos: PlantDto[]): PlantModel[] => {
  if (Array.isArray(plantDtos)) {
    return plantDtos
      .reduce((acc, next) => {
        if (!next.geoLocation) {
          return acc;
        } else {
          return [
            ...acc,
            {
              id: next.depotId,
              geoLocation: {
                latitude: next.geoLocation.latitude,
                longitude: next.geoLocation.longitude,
              },
            },
          ];
        }
      }, [] as PlantModel[])
      .sort((p1, p2) => (p1.id.toLowerCase() < p2.id.toLowerCase() ? -1 : 1));
  }
  return [];
};
