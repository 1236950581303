import { ESButton } from '@energy-stacks/core/ui';
import { IconBrandTelegram } from '@tabler/icons-react';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '@energy-stacks/broker/store';
import { CsmsFeatureRoleGuard } from '@energy-stacks/csms/feature-user-access';
import { useChargingStationDetails } from '../../useChargingStationDetails';

interface SaveConfigurationProps {
  onSave: () => void;
  disabled?: boolean;
}

export const SaveConfiguration: React.FC<SaveConfigurationProps> = ({
  onSave,
  disabled,
}) => {
  const { t } = useTranslation('chargingStations');
  const configuration = useAppSelector(
    (state) => state.configurationState.configuration
  );
  const valid = configuration.length > 0;
  const { chargingStationDetails } = useChargingStationDetails({
    listenToUpdates: true,
  });

  const isStationOnline =
    chargingStationDetails?.csStatus === 'online' ? true : false;

  return (
    <CsmsFeatureRoleGuard feature="saveConfiguration">
      <ESButton
        startIcon={<IconBrandTelegram fontSize="medium" />}
        onClick={onSave}
        disabled={disabled || !valid || !isStationOnline}
      >
        {t('send')}
      </ESButton>
    </CsmsFeatureRoleGuard>
  );
};
