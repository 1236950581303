import {
  ESPage,
  RefetchOnError,
  toPayloadDate,
  useDocumentTitle,
} from '@energy-stacks/shared';
import { useTranslation } from 'react-i18next';
import { Dashboard } from './Dashboard';
import { DashboardSkeleton } from './DashboardSkeleton';
import { useGetTransactionTimeSlicesQuery } from '@energy-stacks/broker/feature-dashboard-data';

export const DashboardPage = () => {
  const { t } = useTranslation('dashboard');
  useDocumentTitle(t('title'));

  const { isLoading, isSuccess, isError, refetch } =
    useGetTransactionTimeSlicesQuery({
      dateStart: toPayloadDate(new Date()),
    });

  return (
    <ESPage title={t('title')}>
      {isLoading ? <DashboardSkeleton /> : null}
      {isError ? <RefetchOnError onRefetch={refetch} /> : null}
      {isSuccess ? <Dashboard /> : null}
    </ESPage>
  );
};
