import { Box, useTheme } from '@mui/material';
import { GoogleMap, GoogleMapProps } from '@react-google-maps/api';
import { FC, useRef } from 'react';
import mapStyles from './mapStyles.json';
import { useCustomZoom } from './useCustomMapZoom';

// Typescript issue (not JSX element) - workaround for GoogleMap component
const GoogleMapFC = GoogleMap as unknown as React.FC<GoogleMapProps>;
type ESMapProps = GoogleMapProps & {
  onMapReady?: () => void;
  options: google.maps.MapOptions;
  hasBorderRadius?: boolean;
};

export const ESMap: FC<ESMapProps> = ({
  options,
  onMapReady,
  children,
  hasBorderRadius = true,
  ...rest
}) => {
  const { palette, spacing } = useTheme();
  const currentMapRef = useRef<google.maps.Map | undefined>();
  const { handleZoomChange } = useCustomZoom(currentMapRef);

  return (
    <Box
      sx={{
        // this class selector stands for "google maps style info window" opened on marker click
        '.gm-style-iw': {
          borderRadius: hasBorderRadius ? 1 : 0,
        },
        height: '100%',
        width: '100%',
      }}
    >
      <GoogleMapFC
        onLoad={(map) => {
          currentMapRef.current = map;
        }}
        mapContainerStyle={{
          width: '100%',
          height: '100%',
          position: 'relative',
          borderRadius: hasBorderRadius ? spacing(3) : 0,
          borderWidth: spacing(0.5),
          borderStyle: 'solid',
          borderColor: palette.common.white,
        }}
        onZoomChanged={handleZoomChange}
        options={{
          ...options,
          minZoom: 1,
          gestureHandling: 'cooperative',
          isFractionalZoomEnabled: true,
          disableDefaultUI: true,
          zoomControl: true,
          zoomControlOptions: {
            position: google.maps.ControlPosition.RIGHT_TOP,
          },
          controlSize: 31,
          styles: mapStyles,
        }}
        onTilesLoaded={onMapReady}
        {...rest}
      >
        {children}
      </GoogleMapFC>
    </Box>
  );
};
