import { TransactionFinishReasonEnum } from '@energy-stacks/broker/feature-dashboard-data';
import { ESTooltip } from '@energy-stacks/shared';
import InfoRoundedIcon from '@mui/icons-material/InfoRounded';
import { IconButton } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface TransactionFinishReasonProps {
  transactionFinishReason: TransactionFinishReasonEnum;
}

export const TransactionFinishReason: React.FC<
  TransactionFinishReasonProps
> = ({ transactionFinishReason }) => {
  const { t } = useTranslation('chargingStations');
  return (
    <ESTooltip title={t(transactionFinishReason)}>
      <IconButton size="small">
        <InfoRoundedIcon fontSize="small" />
      </IconButton>
    </ESTooltip>
  );
};
