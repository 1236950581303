import StorageRoundedIcon from '@mui/icons-material/StorageRounded';
import { useTranslation } from 'react-i18next';
import { EventSourceChip } from './EventSourceChip';

interface ServerEventSourceProps {
  inheritCursor?: boolean;
}

export const ServerEventSource: React.FC<ServerEventSourceProps> = ({
  inheritCursor,
}) => {
  const { t } = useTranslation('chargingStations');
  return (
    <EventSourceChip
      icon={<StorageRoundedIcon />}
      label={t('server')}
      inheritCursor={inheritCursor}
    />
  );
};
