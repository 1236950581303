import { Dialog, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
  ESDialogActionButton,
  ESDialogActions,
  ESDialogContent,
  ESDialogTitle,
} from '@energy-stacks/core/ui';

type EditAssignedVehicleDialogProps = {
  onClose: () => void;
};

export const EditAssignedVehicleDialog: React.FC<
  EditAssignedVehicleDialogProps
> = ({ onClose }) => {
  const [t] = useTranslation('vehicles');

  return (
    <Dialog
      open
      fullWidth
      onClose={(_event, reason) => {
        if (reason === 'backdropClick') {
          onClose();
        }
      }}
    >
      <ESDialogTitle>{t('editAssignedVehicleDialogTitle')}</ESDialogTitle>
      <ESDialogContent>
        <Typography variant="body1">
          {t('editAssignedVehicleDialogDescription')}
        </Typography>
      </ESDialogContent>
      <ESDialogActions>
        <ESDialogActionButton variant="contained" onClick={onClose}>
          {t('editAssignedVehicleDialogButtonLabel')}
        </ESDialogActionButton>
      </ESDialogActions>
    </Dialog>
  );
};
