import { Paper, PaperProps } from '@mui/material';

interface ESPaperProps extends PaperProps {
  children: React.ReactNode;
}

export const ESPaper: React.FC<ESPaperProps> = (props) => {
  const { children } = props;
  return (
    <Paper variant="elevation" elevation={0} {...props}>
      {children}
    </Paper>
  );
};
