import { TablePagination, TablePaginationProps, Box } from '@mui/material';
import { OverrideProps } from '@mui/material/OverridableComponent';
import { Table } from '@tanstack/react-table';
import React from 'react';

interface ESTablePaginationProps<T> {
  rowsPerPageOptions: number[];
  instance: Table<T>;
  count?: number;
  onPageChange?: TablePaginationProps['onPageChange'];
}

export const TABLE_PAGINATION_HEIGHT = '52px';

export const ESTablePagination = <T,>({
  rowsPerPageOptions,
  instance,
  count,
  onPageChange = (_event, page) => {
    if (instance.getIsSomeRowsExpanded()) {
      instance.resetExpanded();
    }
    instance.setPageIndex(page);
  },
}: ESTablePaginationProps<T>) => {
  return (
    <TablePagination
      component={PaginatorContainer}
      sx={{
        '&': {
          // Slight indication where pagination starts in order to visualize table height for rows
          borderTop: '0.3px solid rgba(224, 224, 224, 0.3)',
          marginLeft: 'auto',
        },
      }}
      rowsPerPageOptions={rowsPerPageOptions}
      count={count ?? instance.getFilteredRowModel().rows.length}
      rowsPerPage={instance.getState().pagination.pageSize}
      page={Math.max(instance.getState().pagination.pageIndex, 0)}
      // Remove Math.max after this react-table PR is released: https://github.com/TanStack/table/pull/4370
      onPageChange={onPageChange}
      onRowsPerPageChange={(event) => {
        const numberOfRows = Number(event.target.value);
        instance.setPageSize(numberOfRows);
      }}
      SelectProps={{
        MenuProps: {
          PaperProps: {
            sx: {
              marginTop: -16,
            },
          },
        },
      }}
    />
  );
};

const PaginatorContainer: React.FC<OverrideProps<any, any>> = ({
  children,
}) => {
  return (
    <Box id="paginatorRef" sx={{ position: 'relative' }}>
      {children}
    </Box>
  );
};
