import { Alert } from '@energy-stacks/broker/feature-alert-management-data';
import { ConnectorIcon } from '@energy-stacks/broker/shared';
import { ESTooltip } from '@energy-stacks/shared';
import { Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

type AlertConnectorCellProps = {
  connectorId: Alert['connectorId'];
  connectorStandard: Alert['connectorStandard'];
  evseId: Alert['evseId'];
};

export const AlertConnectorCell: React.FC<AlertConnectorCellProps> = ({
  connectorId,
  connectorStandard,
  evseId,
}) => {
  const [t] = useTranslation('chargingStations');
  return (
    <ESTooltip
      title={
        <Stack textAlign="left">
          <Typography variant="inherit">{`${t('connectorId')}: ${
            connectorId ?? 'N/A'
          }`}</Typography>
          <Typography variant="inherit">{`${t('evseId')}: ${
            evseId ?? 'N/A'
          }`}</Typography>
          <Typography variant="inherit">{`${t('connectorType')}: ${
            connectorStandard ? t(`connectorTypes.${connectorStandard}`) : 'N/A'
          }`}</Typography>
        </Stack>
      }
      key={connectorId}
      placement="bottom"
    >
      <ConnectorIcon connectorType={connectorStandard} />
    </ESTooltip>
  );
};
