import { CentralSystemDto } from '../centralSystemDto';
import { CentralSystemModel } from '../CentralSystemModel';

export const centralSystemNormalizer = (
  dto: CentralSystemDto
): CentralSystemModel => ({
  uuid: dto.uuid,
  displayName: dto.displayName,
  csmsUrl: dto.csmsUrl,
  isDefault: dto.isDefault,
  userName: dto.username || '',
  password: dto.password || '',
  ocppProtocol: dto.ocppProtocol,
  csmsCredentialsUsed: dto.isCsmsCredentialsUsed,
});
