import { useGetGoogleMapsKeyQuery } from '@energy-stacks/broker/feature-settings-data';
import { useLoadGoogleMapsApiScript } from '@energy-stacks/shared';
import { environment } from '@energy-stacks/feature-config';

type Params = {
  skipFetchingGoogleApiKey: boolean;
};

export const useLoadBrokerGoogleMapsApiScript = (params?: Params) => {
  const { data: apiKey } = useGetGoogleMapsKeyQuery(undefined, {
    skip: params?.skipFetchingGoogleApiKey || false,
  });
  return useLoadGoogleMapsApiScript(apiKey || environment.googleApiKey);
};
