import { ESPageLoadingIndicator, RefetchOnError } from '@energy-stacks/shared';
import { Transactions } from './Transactions';
import { useTransactions } from './useTransactions';

export const ChargingStationDetailsTransactionsTab = () => {
  const { isSuccess, isError, isLoading, refetch } = useTransactions();

  return (
    <>
      {isLoading ? <ESPageLoadingIndicator /> : null}
      {isError ? <RefetchOnError onRefetch={refetch} /> : null}
      {isSuccess ? <Transactions /> : null}
    </>
  );
};
