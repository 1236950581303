import { TransactionStatusEnum } from '@energy-stacks/broker/feature-transactions-data';
import { formatDuration } from '@energy-stacks/core/ui';
import { Box } from '@mui/material';
import { useEffect, useState } from 'react';

interface TransactionDurationCellProps {
  sessionStartTime: string;
  transactionStatus: TransactionStatusEnum;
  isTransactionActive: boolean;
  value: string;
}

export const TransactionDurationCell: React.FC<
  TransactionDurationCellProps
> = ({ sessionStartTime, transactionStatus, isTransactionActive, value }) => {
  const [newSessionEndDate, setNewSessionEndDate] = useState(new Date());

  useEffect(() => {
    let durationInterval: number | undefined;

    if (isTransactionActive) {
      durationInterval = window.setInterval(() => {
        setNewSessionEndDate(new Date());
      }, 10000);
    }

    return () => {
      clearInterval(durationInterval);
    };
  }, [isTransactionActive]);

  const startDate = new Date(sessionStartTime);
  const newDuration = formatDuration(startDate, newSessionEndDate);
  if (transactionStatus !== TransactionStatusEnum.charging) {
    return <Box>{value}</Box>;
  } else {
    return <Box>{newDuration}</Box>;
  }
};
