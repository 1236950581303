import { TimePicker } from '@mui/x-date-pickers';
import { TextField } from '@mui/material';
import { isValid } from 'date-fns';
import { getHours, getMinutes, setHours, setMinutes } from 'date-fns/esm';

interface TimeInputProps {
  label?: string;
  date: Date | null;
  minTime?: Date;
  maxTime?: Date;
  onDateChange: (newDate: Date | string | null) => void;
  error?: boolean;
  helperText?: React.ReactNode;
  placeholder?: string;
  allowInvalidStringInput?: boolean;
}

export const TimeInput: React.FC<TimeInputProps> = ({
  label,
  date,
  minTime,
  maxTime,
  onDateChange,
  error,
  helperText,
  placeholder,
  allowInvalidStringInput,
}) => {
  const handleDateChange = (
    newDate: Date | null,
    keyboardInput: string | undefined
  ) => {
    // re-implement behaviour seen in MUI docs in which a user can't enter an invalid date
    // refactor the component and remove the 'isFormInput' prop after the refactor

    if (allowInvalidStringInput) {
      if (newDate && isValid(newDate)) {
        let updatedDate = date || new Date();
        updatedDate = setHours(updatedDate, getHours(newDate));
        updatedDate = setMinutes(updatedDate, getMinutes(newDate));

        onDateChange(updatedDate);
      } else {
        onDateChange(keyboardInput || null);
      }
    } else {
      if (newDate && isValid(newDate)) {
        let updatedDate = date || new Date();
        updatedDate = setHours(updatedDate, getHours(newDate));
        updatedDate = setMinutes(updatedDate, getMinutes(newDate));

        onDateChange(updatedDate);
      }
    }
  };

  return (
    <TimePicker
      label={label ?? ''}
      ampm={false}
      views={['hours', 'minutes']}
      inputFormat="HH:mm"
      mask="__:__"
      value={date}
      minTime={minTime}
      maxTime={maxTime}
      onChange={handleDateChange}
      renderInput={({ inputProps, ...rest }) => (
        <TextField
          {...rest}
          fullWidth
          variant="outlined"
          size="small"
          error={error}
          helperText={helperText}
          inputProps={{
            ...inputProps,
            placeholder,
          }}
        />
      )}
    />
  );
};
