import { VehicleStatus } from '@energy-stacks/fleet/feature-vehicles-data';

export const vehicleStatusFilterOptions = [
  'AVAILABLE',
  'PLANNED',
  'ENROUTE',
] as const;

export interface VehicleModel {
  plantId: string;
  vehicleId: string;
  brandId: string;
  brandAndModel: string;
  name: string;
  licencePlate: string;
  weight: number | null;
  volume: number | null;
  electricVehicleData: {
    soc?: number;
    moSoc?: number;
  };
  tour: {
    tourId: string;
    startDate?: string;
    status: TourStatus;
  } | null;
  status: VehicleStatus;
}

const tourStatuses = [
  'PLANNED',
  'ENROUTE',
  'DELAYED',
  'FAILED',
  'INCOMPLETE',
  'DONE',
] as const;

type TourStatus = (typeof tourStatuses)[number];
