import { ChargingStationDto } from '@energy-stacks/csms/feature-charging-stations-data';

export interface LocationModel {
  locationId: string;
  locationName: string;
  locationFullAddress: string;
  locationParkingType: LocationParkingType;
  chargingStations: ChargingStationDto[];
}

export type LocationParkingType =
  | 'alongMotorway'
  | 'parkingGarage'
  | 'undergroundGarage'
  | 'parkingLot'
  | 'onStreet'
  | 'driveway'
  | 'other';

export enum LocationParkingTypeEnum {
  alongMotorway = 'alongMotorway',
  parkingGarage = 'parkingGarage',
  undergroundGarage = 'undergroundGarage',
  parkingLot = 'parkingLot',
  onStreet = 'onStreet',
  driveway = 'driveway',
  other = 'other',
}
