import { OcppProtocol, createBaseQuery } from '@energy-stacks/shared';
import { createApi } from '@reduxjs/toolkit/query/react';
import { CentralSystemDto } from './centralSystemDto';
import { centralSystemNormalizer } from './normalizers/centralSystemNormalizer';
import { environment } from '@energy-stacks/feature-config';
import { CentralSystemModel } from './CentralSystemModel';
import { EditCentralSystemApiError } from './centralSystemApiErrors';

export interface CsmsCreateRequestDto {
  displayName: string;
  csmsUrl: string;
  isDefault: boolean;
  username?: string;
  password?: string;
  confirmPassword?: string;
  isCsmsCredentialsUsed: boolean;
  ocppProtocol?: OcppProtocol;
}

interface CsmsUpdateRequestDto {
  displayName: string;
  csmsUrl: string;
  isDefault: boolean;
  username: string | undefined;
  password: string | undefined;
  confirmPassword: string | undefined;
  isCsmsCredentialsUsed: boolean;
  ocppProtocol?: OcppProtocol;
}

export interface CsmsPatchRequestDto {
  usernameToBeRemoved: boolean;
  passwordToBeRemoved: boolean;
}

export const centralSystemsApi = createApi({
  reducerPath: 'brokerCentralSystemsApi',
  tagTypes: ['CentralSystems', 'CentralSystemsDetails'],
  baseQuery: createBaseQuery(`${environment.ocppServiceUrl}/csms`),
  endpoints: (builder) => ({
    getCentralSystems: builder.query<CentralSystemModel[], void>({
      query: () => '/',
      providesTags: ['CentralSystems'],
      transformResponse: (dtos: CentralSystemDto[]) =>
        dtos.map(centralSystemNormalizer),
    }),
    getCentralSystemsDetails: builder.query<CentralSystemModel, string>({
      query: (id: string) => `/${id}`,
      providesTags: ['CentralSystemsDetails'],
    }),
    addCentralSystem: builder.mutation<CentralSystemDto, CsmsCreateRequestDto>({
      query: (body) => ({
        url: '/',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['CentralSystems'],
    }),
    deleteCentralSystem: builder.mutation<{ success: boolean }, string>({
      query: (centralSystemUuid) => ({
        url: `/${centralSystemUuid}`,
        method: 'DELETE',
      }),
      async onQueryStarted(centralSystemUuid, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(
            centralSystemsApi.util.updateQueryData(
              'getCentralSystems',
              undefined,
              (draft) => {
                const updatedList = draft.filter(
                  (centralSystem) => centralSystem.uuid !== centralSystemUuid
                );
                return updatedList;
              }
            )
          );
        } catch {}
      },
    }),
    editCentralSystem: builder.mutation<
      CentralSystemModel,
      CsmsCreateRequestDto &
        Pick<CentralSystemDto, 'uuid'> & { credentialsToBeRemoved: boolean }
    >({
      queryFn: async (args, _api, _extraArgs, baseQuery) => {
        /**
         * To update existing CSMS username or password or to create new username and password when they are initialy empty,
         * we simply call a single PUT endpoint. This is the common flow.
         *
         * However, to remove existing CSMS username or password, we need to call an additional PATCH endpoint to trigger removal
         * manually.
         *
         * For this reason, the flow is split into two parts.
         */
        const usernameToBeRemoved = !args.username;
        const passwordToBeRemoved =
          !args.password && args.credentialsToBeRemoved; // in case password is blank, it can either mean it was unchanged or "Remove credentials" was explicitly requested from UI and we should call the second endpoint to remove it
        const credentialRemovalEndpointToBeCalled =
          usernameToBeRemoved || passwordToBeRemoved;

        // Regular flow which needs to be called in any case
        const csmsUpdateRequestBody: CsmsUpdateRequestDto = {
          displayName: args.displayName,
          csmsUrl: args.csmsUrl,
          isDefault: args.isDefault,
          username: args.username || undefined,
          password: args.password || undefined,
          confirmPassword: args.confirmPassword || undefined,
          isCsmsCredentialsUsed: args.isCsmsCredentialsUsed,
          ocppProtocol: args.ocppProtocol || undefined,
        };

        const { data, error } = await baseQuery({
          url: `/${args.uuid}`,
          method: 'PUT',
          body: csmsUpdateRequestBody,
        });

        if (!credentialRemovalEndpointToBeCalled) {
          if (error) {
            return { error };
          }

          return {
            // in case no credentials are to be removed, this is where the query function ends
            data: centralSystemNormalizer(data as CentralSystemDto),
          };
        }

        if (error) {
          // in case displayName is changed to something that's not unique, we will throw an error here, before the credentials removal is started
          return { error };
        }

        // In case credentials are to be removed, we need to call the PATCH endpoint right after the PUT endpoint
        const { error: credentialRemovalError } = await baseQuery({
          url: `/${args.uuid}`,
          method: 'PATCH',
          body: (() => {
            const credentialRemovalBody: CsmsPatchRequestDto = {
              usernameToBeRemoved,
              passwordToBeRemoved,
            };

            return credentialRemovalBody;
          })(),
        });

        if (credentialRemovalError) {
          if (
            (credentialRemovalError as EditCentralSystemApiError).data
              .errorCode === 'UNABLE_TO_UPDATE_CSMS_CREDENTIALS'
          ) {
            /**
             * This error is returned when the PATCH request to remove credentials is triggered, but both username and password
             * are already null.
             *
             * This error cannot happen due to FE validation, but in case FE validation logic changes(allow submitting a non-dirty form),
             * this error should be supressed as it can be safely called when credentials are already null.
             */
            return { data: centralSystemNormalizer(data as CentralSystemDto) };
          }

          return { error: credentialRemovalError };
        }

        return {
          data: centralSystemNormalizer(data as CentralSystemDto),
        };
      },
      invalidatesTags: ['CentralSystems', 'CentralSystemsDetails'],
    }),
    updateCentralSystemCredentials: builder.mutation<
      { success: boolean },
      CsmsPatchRequestDto & Pick<CentralSystemDto, 'uuid'>
    >({
      query: (formData) => {
        const body: CsmsPatchRequestDto = {
          usernameToBeRemoved: formData.usernameToBeRemoved,
          passwordToBeRemoved: formData.passwordToBeRemoved,
        };

        return {
          url: `/${formData.uuid}`,
          method: 'PATCH',
          body,
        };
      },
      invalidatesTags: ['CentralSystems'],
    }),
  }),
});

export const {
  useGetCentralSystemsQuery,
  useGetCentralSystemsDetailsQuery,
  useAddCentralSystemMutation,
  useEditCentralSystemMutation,
  useDeleteCentralSystemMutation,
  useUpdateCentralSystemCredentialsMutation,
} = centralSystemsApi;
