import { ESTextField } from '@energy-stacks/core/ui';
import {
  Box,
  Divider,
  InputAdornment,
  Switch,
  Typography,
} from '@mui/material';
import { IconBolt } from '@tabler/icons-react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { SectionTitle } from './SectionTitle';
import { FC, useEffect } from 'react';
import { DEFAULT_FLEET_MOSOC } from '../useCreateChargingScheduleState';

type MoSocSectionProps = {
  onIsMoSocIndividualChange: (isMoSocIndividual: boolean) => void;
  onFleetMoSocChange: (fleetMoSoc: string) => void;
};

export const MoSocSection: FC<MoSocSectionProps> = ({
  onIsMoSocIndividualChange,
  onFleetMoSocChange,
}) => {
  const [t] = useTranslation('chargingSchedule');
  const {
    control,
    formState: { errors },
    setValue,
    trigger,
    watch,
  } = useFormContext();

  useEffect(() => {
    // Keeps the field in error state when user navigates back and forth between steps
    trigger('fleetMoSoc');
  }, [trigger]);

  const isMoSocIndividual = watch('isMoSocIndividual');

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        mr: 5,
      }}
    >
      <SectionTitle title={t('chargingOptionLabels.moSoc')} Icon={IconBolt} />
      <Controller
        control={control}
        render={({ field: { onChange, value } }) => (
          <Box sx={{ alignItems: 'center', display: 'flex', mb: 5, mt: 4 }}>
            <Switch
              checked={value}
              onChange={(e) => {
                onChange(e);
                onIsMoSocIndividualChange(e.target.checked);
                if (e.target.checked) {
                  onFleetMoSocChange(DEFAULT_FLEET_MOSOC);
                  setValue('fleetMoSoc', DEFAULT_FLEET_MOSOC);
                  trigger('fleetMoSoc');
                }
              }}
              sx={{
                ml: -2,
                '.MuiSwitch-track': {
                  opacity: 0.7,
                },
              }}
            />
            <Typography variant="h5">{t('moSocSwitchLabel')}</Typography>
          </Box>
        )}
        name="isMoSocIndividual"
      />
      {!isMoSocIndividual && (
        <Controller
          control={control}
          render={({ field: { onBlur, onChange, value } }) => {
            return (
              <ESTextField
                testId="fleetMoSocInput"
                required
                label={t('fleetMoSocInputLabel')}
                InputProps={{
                  endAdornment: (
                    <InputAdornment
                      position="end"
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        height: 30,
                      }}
                    >
                      <Divider
                        orientation="vertical"
                        variant="middle"
                        flexItem
                      />
                      <Typography variant="h5" sx={{ mx: 2 }}>
                        %
                      </Typography>
                    </InputAdornment>
                  ),
                }}
                value={value}
                onBlur={onBlur}
                onChange={(event) => {
                  const isValid =
                    !isNaN(Number(event?.target.value)) &&
                    !event?.target.value.includes('.');
                  if (event === undefined || isValid) {
                    onChange(event);
                    onFleetMoSocChange(event?.target.value ?? '');
                    trigger('fleetMoSoc');
                  }
                }}
                error={Boolean(errors.fleetMoSoc)}
                helperText={
                  Boolean(errors.fleetMoSoc) &&
                  t(`${errors.fleetMoSoc?.message}`)
                }
              />
            );
          }}
          name="fleetMoSoc"
        />
      )}
    </Box>
  );
};
