import { ESMenuItem, useESMenuContext } from '@energy-stacks/core/ui';
import { TourModel } from '@energy-stacks/fleet/feature-tours-data';
import { useDialog } from '@energy-stacks/shared';
import { EditTourInfoDialog } from './EditTourInfoDialog';
import { useTranslation } from 'react-i18next';

type EditTourMenuItemProps = {
  testId?: string;
  status: TourModel['status'];
  onEnterEditMode: () => void;
};

export const EditTourMenuItem: React.FC<EditTourMenuItemProps> = ({
  testId,
  status,
  onEnterEditMode,
}) => {
  const { closeESMenu } = useESMenuContext();
  const { t } = useTranslation('tours');

  const {
    open: openWarningInfoDialog,
    dialogProps: { onClose, isOpen },
  } = useDialog();

  const handleClick = () => {
    closeESMenu();
    if (status === 'PLANNED' || status === 'ENROUTE') {
      onEnterEditMode();
    } else {
      openWarningInfoDialog();
    }
  };

  return (
    <>
      <ESMenuItem testId={`${testId}EditMenuItem`} onClick={handleClick}>
        {t('editTourCTA')}
      </ESMenuItem>
      <EditTourInfoDialog
        isOpen={isOpen}
        onClose={() => {
          onClose();
          closeESMenu();
        }}
      />
    </>
  );
};
