import {
  AddChargingLocationFormData,
  WorkingHoursModel,
} from './addChargingLocationFormData';
import { convertAmenitiesToFacilities } from './convertAmenitiesToFacilities';
import { convertLocationTypeToParkingType } from './convertLocationTypeToParkingType';
import { LocationAndChargingStationGroupsCreateRequest } from './createChargingStationLocationRequest';
import { OpeningHours } from './locationDetailsModel';
import { LocationParkingTypeEnum } from './locationModel';
import { mapWorkingDateToWorkingTime } from './mapWorkingDateToWorkingTime';
import { RegularHoursDto } from './regularHoursDto';

export const mapAddLocationFormDataToPayload = (
  addChargingLocationFormData: AddChargingLocationFormData
): LocationAndChargingStationGroupsCreateRequest => {
  return {
    ownerId: addChargingLocationFormData.entity.value || undefined,
    locationAndChargingStationGroupsCreateRequest: {
      locationCreateRequest: {
        name: addChargingLocationFormData.name || undefined,
        address: addChargingLocationFormData.address,
        postal_code: addChargingLocationFormData.zipCode || undefined,
        country: addChargingLocationFormData.country.value,
        city: addChargingLocationFormData.city,
        parking_type: convertLocationTypeToParkingType(
          addChargingLocationFormData.locationType?.value ??
            LocationParkingTypeEnum.other
        ),
        energy_mix: {
          is_green_energy: false,
          supplier_name:
            addChargingLocationFormData.energySupplier || undefined,
        },
        facilities: [
          ...convertAmenitiesToFacilities(
            addChargingLocationFormData.activityAmenities
          ),
          ...convertAmenitiesToFacilities(
            addChargingLocationFormData.foodAndDrinkAmenities
          ),
          ...convertAmenitiesToFacilities(
            addChargingLocationFormData.shoppingAmenities
          ),
          ...convertAmenitiesToFacilities(
            addChargingLocationFormData.transportationAmenities
          ),
        ],
        charging_when_closed: addChargingLocationFormData.chargingWhenClosed,
        opening_times: {
          twentyfourseven:
            addChargingLocationFormData.openingHours === OpeningHours.nonStop,
          regular_hours:
            addChargingLocationFormData.openingHours !== OpeningHours.nonStop
              ? mapWorkingHoursToRegularHours(addChargingLocationFormData.hours)
              : undefined,
        },
      },
      chargingStationGroupCreateRequests:
        addChargingLocationFormData.chargingStationGroups,
    },
  };
};

const mapWorkingHoursToRegularHours = (
  workingHours: WorkingHoursModel[]
): RegularHoursDto[] => {
  const selectedWorkingHours = workingHours.filter(
    (item) => Boolean(item.start) && Boolean(item.end)
  );
  return selectedWorkingHours.map((item) => ({
    period_begin: mapWorkingDateToWorkingTime(item.start),
    period_end: mapWorkingDateToWorkingTime(item.end),
    weekday: item.weekday,
  }));
};
