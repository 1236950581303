import { ChargingStationGroupDto } from '../chargingStationGroupDto';
import { ChargingStationGroupModel } from '../chargingStationGroupModel';

export const chargingStationGroupsNormalizer = (
  chargingStationGroupsDto: ChargingStationGroupDto[]
): ChargingStationGroupModel[] => {
  return chargingStationGroupsDto.map((chargingStationGroup) => ({
    chargingStationGroupName:
      chargingStationGroup.chargingStationGroupName ?? 'N/A',
    chargingStationGroupUuid:
      chargingStationGroup.chargingStationGroupUuid ?? '',
    chargingStationIdentityKeys:
      chargingStationGroup.chargingStationIdentityKeys ?? [],
    locationId: chargingStationGroup.locationId ?? '',
    providerId: chargingStationGroup.providerId ?? '',
  }));
};
