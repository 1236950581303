import { sortByStatus } from '@energy-stacks/shared';
import { GetVehiclesConfig, useVehicles } from './useVehicles';
import { useMemo } from 'react';

export const useVehiclesSorted = (config?: GetVehiclesConfig) => {
  const {
    data: vehicles,
    isError,
    isLoading,
    refetch,
    isSuccess,
  } = useVehicles(config);

  const sortedVehicles = useMemo(() => {
    if (vehicles) {
      return sortByStatus([...vehicles]);
    }
  }, [vehicles]);

  return { data: sortedVehicles, isError, isLoading, refetch, isSuccess };
};
