import { Box } from '@mui/material';
import React from 'react';

interface TabbedScreenBoundaryProps {
  children?: React.ReactNode;
}

const PAGE_TOP_CONTENT_HEIGHT = 161;
const TABBED_SCREEN_BOUNDARY_HEIGHT = `calc(100vh - ${PAGE_TOP_CONTENT_HEIGHT}px)`;

export const TabbedScreenBoundary: React.FC<TabbedScreenBoundaryProps> = ({
  children,
}) => {
  return (
    <Box
      sx={{
        height: TABBED_SCREEN_BOUNDARY_HEIGHT,
        overflow: 'auto',
        m: 5,
        p: 1,
      }}
    >
      {children}
    </Box>
  );
};

export const TabbedScreenBoundaryWithNavigation: React.FC<
  TabbedScreenBoundaryProps
> = ({ children }) => {
  return (
    <Box
      sx={{
        overflow: 'auto',
        m: 5,
        mt: 0,
        px: 1,
        py: 5,
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
      }}
    >
      {children}
    </Box>
  );
};
