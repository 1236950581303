import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const Type2FemaleIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props}>
      <path d="M9.77269 9.28537C10.4976 9.28537 11.0872 8.69577 11.0872 7.97084C11.0872 7.2459 10.4976 6.65631 9.77269 6.65631C9.04775 6.65631 8.45816 7.2459 8.45816 7.97084C8.45777 8.69577 9.04775 9.28537 9.77269 9.28537ZM9.77269 7.31513C10.1344 7.31513 10.4284 7.60915 10.4284 7.97084C10.4284 8.33253 10.1344 8.62655 9.77269 8.62655C9.411 8.62655 9.11698 8.33253 9.11698 7.97084C9.11698 7.60915 9.411 7.31513 9.77269 7.31513Z" />
      <path d="M14.1052 9.28537C14.8305 9.28537 15.4205 8.69577 15.4205 7.97084C15.4205 7.2459 14.8305 6.65631 14.1052 6.65631C13.3803 6.65631 12.7907 7.2459 12.7907 7.97084C12.7903 8.69577 13.3803 9.28537 14.1052 9.28537ZM14.1052 7.31513C14.4673 7.31513 14.7617 7.60915 14.7617 7.97084C14.7617 8.33253 14.4673 8.62655 14.1052 8.62655C13.7439 8.62655 13.4495 8.33253 13.4495 7.97084C13.4495 7.60915 13.7435 7.31513 14.1052 7.31513Z" />
      <path d="M16.3823 9.05319C15.265 9.05319 14.3557 9.96208 14.3557 11.079C14.3557 12.1964 15.265 13.1057 16.3823 13.1057C17.4997 13.1057 18.409 12.1964 18.409 11.079C18.4086 9.96169 17.4997 9.05319 16.3823 9.05319ZM16.3823 12.4465C15.6282 12.4465 15.0149 11.8331 15.0149 11.079C15.0149 10.3253 15.6282 9.7124 16.3823 9.7124C17.1364 9.7124 17.7498 10.3253 17.7498 11.079C17.7498 11.8328 17.1361 12.4465 16.3823 12.4465Z" />
      <path d="M9.77267 12.9494C8.65532 12.9494 7.74603 13.8587 7.74603 14.976C7.74603 16.0933 8.65532 17.0026 9.77267 17.0026C10.89 17.0026 11.7993 16.0933 11.7993 14.976C11.7989 13.8583 10.89 12.9494 9.77267 12.9494ZM9.77267 16.3434C9.01857 16.3434 8.40525 15.7301 8.40525 14.976C8.40525 14.2219 9.01857 13.6086 9.77267 13.6086C10.5268 13.6086 11.1401 14.2219 11.1401 14.976C11.1401 15.7301 10.5264 16.3434 9.77267 16.3434Z" />
      <path d="M9.64396 11.0787C9.64396 9.96169 8.73468 9.0528 7.61732 9.0528C6.49996 9.0528 5.59067 9.96169 5.59067 11.0787C5.59067 12.196 6.49996 13.1053 7.61732 13.1053C8.73468 13.1053 9.64396 12.1964 9.64396 11.0787ZM7.61732 12.4465C6.86321 12.4465 6.24988 11.8332 6.24988 11.079C6.24988 10.3253 6.86321 9.7124 7.61732 9.7124C8.37143 9.7124 8.98475 10.3253 8.98475 11.079C8.98475 11.8328 8.37143 12.4465 7.61732 12.4465Z" />
      <path d="M14.0589 12.9494C12.9419 12.9494 12.0331 13.8587 12.0331 14.976C12.0331 16.0933 12.9419 17.0026 14.0589 17.0026C15.1763 17.0026 16.0856 16.0933 16.0856 14.976C16.0856 13.8583 15.1763 12.9494 14.0589 12.9494ZM14.0589 16.3434C13.3052 16.3434 12.6923 15.7301 12.6923 14.976C12.6923 14.2219 13.3052 13.6086 14.0589 13.6086C14.813 13.6086 15.4263 14.2219 15.4263 14.976C15.4263 15.7301 14.813 16.3434 14.0589 16.3434Z" />
      <path d="M14.0768 11.0787C14.0768 9.96169 13.1679 9.0528 12.051 9.0528C10.9336 9.0528 10.0243 9.96169 10.0243 11.0787C10.0243 12.196 10.9336 13.1053 12.051 13.1053C13.1679 13.1053 14.0768 12.1964 14.0768 11.0787ZM10.6835 11.0787C10.6835 10.3249 11.2969 9.71201 12.051 9.71201C12.8047 9.71201 13.4176 10.3249 13.4176 11.0787C13.4176 11.8328 12.8047 12.4461 12.051 12.4461C11.2969 12.4465 10.6835 11.8328 10.6835 11.0787Z" />
      <path d="M19.3956 5.91349C18.3063 4.24387 12.6495 4.30998 12.0043 4.30998C11.3583 4.29832 5.84619 4.08869 4.61527 5.89637C3.63793 7.33109 3.12222 9.0361 3.12222 10.8255C3.12222 15.7208 7.10394 19.7033 11.9992 19.7033C16.8945 19.7033 20.877 15.7208 20.877 10.8255C20.8774 9.07265 20.3792 7.41976 19.3956 5.91349ZM11.9996 18.9068C7.54381 18.9068 3.91911 15.2813 3.91911 10.8255C3.91911 9.19594 4.38698 7.6465 5.27371 6.34441C5.88119 5.45184 8.58688 5.14732 10.7586 5.14732C11.2195 5.14732 11.6465 5.12438 12.0381 5.14732C14.3809 5.17027 18.0904 5.37095 18.7286 6.34907C19.6259 7.7235 20.0809 9.22978 20.0809 10.8255C20.0809 15.2813 16.4554 18.9068 11.9996 18.9068Z" />
    </SvgIcon>
  );
};
