import { ESPageLoadingIndicator, RefetchOnError } from '@energy-stacks/shared';
import { SelectableChargingStationsTable } from './SelectableChargingStationsTable';
import {
  useChargingStations,
  selectAllStations,
} from '@energy-stacks/broker/feature-charging-stations-data';
import { useSelector } from 'react-redux';
import { CreateChargingScheduleState } from '../useCreateChargingScheduleState';

interface ChargingStationsStepProps {
  onChargingStationsSelected: (
    chargingStations: CreateChargingScheduleState['selectedChargingStationIdentityKeys']
  ) => void;
  selectedChargingStationIdentityKeys:
    | CreateChargingScheduleState['selectedChargingStationIdentityKeys']
    | undefined;
}

export const ChargingStationsStep: React.FC<ChargingStationsStepProps> = ({
  onChargingStationsSelected,
  selectedChargingStationIdentityKeys,
}) => {
  const { refetch, isLoading, isError, isSuccess } = useChargingStations();
  const chargingStations = useSelector(selectAllStations);

  return (
    <>
      {isLoading ? <ESPageLoadingIndicator /> : null}
      {isError ? <RefetchOnError onRefetch={refetch} /> : null}
      {isSuccess && chargingStations ? (
        <SelectableChargingStationsTable
          chargingStations={chargingStations}
          defaultRowSelection={(item) =>
            !!selectedChargingStationIdentityKeys?.includes(item.identityKey)
          }
          onSelectionChange={(chargingStationsRowSelection) => {
            onChargingStationsSelected(
              Object.keys(chargingStationsRowSelection)
            );
          }}
        />
      ) : null}
    </>
  );
};
