export interface MapMarkerWrapperProps {
  onClick?: () => void;
  style?: React.CSSProperties;
  children?: React.ReactNode;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
}

export const MapMarkerWrapper: React.FC<MapMarkerWrapperProps> = ({
  style = {
    width: 24,
    height: 28,
    marginTop: -27,
    marginLeft: -12,
  },
  onClick,
  children,
  onMouseEnter,
  onMouseLeave,
}) => {
  return (
    <div
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onClick={onClick}
      style={{
        ...style,
        marginTop: -(style.height || 0),
        marginLeft: -(style.width || 0) / 2,
      }}
    >
      {children}
    </div>
  );
};
