import { Jobs } from '@energy-stacks/fleet/feature-jobs';
import { JobModel } from '@energy-stacks/fleet/feature-jobs-data';
import { DateRange } from '@energy-stacks/core/date-range-picker';

interface JobsStepProps {
  onJobsSelected: (jobIds: JobModel['jobId'][]) => void;
  selectedJobIds: JobModel['jobId'][] | undefined;
  defaultDateRange: DateRange;
  dateRange: DateRange;
  onDateRangeChange: (dateRange: DateRange) => void;
}

export const JobsStep: React.FC<JobsStepProps> = ({
  onJobsSelected,
  dateRange,
  defaultDateRange,
  onDateRangeChange,
  selectedJobIds,
}) => {
  return (
    <Jobs
      selectedJobIds={selectedJobIds}
      onJobsSelected={onJobsSelected}
      preselectedJobStatusFilters={['TO_DO']}
      defaultDateRange={defaultDateRange}
      dateRange={dateRange}
      onDateRangeChange={(range) =>
        onDateRangeChange(range ?? defaultDateRange)
      }
    />
  );
};
