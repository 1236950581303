import { ConnectorStatusEnum } from '@energy-stacks/broker/feature-charging-stations-data';
import { ConnectorStatusInfo } from './ConnectorStatus';
import { colors } from '@mui/material';

export const connectorStatusToChipInfoMap: {
  [status in ConnectorStatusEnum]: ConnectorStatusInfo;
} = {
  undefined: {
    bgColor: 'grey.200',
    color: 'grey.700',
  },
  available: {
    bgColor: 'success.light',
    color: 'success.dark',
  },
  charging: {
    bgColor: 'primary.light',
    color: 'primary.dark',
  },
  reserved: {
    bgColor: 'warning.light',
    color: 'warning.dark',
  },
  faulted: {
    bgColor: 'orange.light',
    color: 'error.dark',
  },
  unavailable: {
    bgColor: 'grey.300',
    color: 'grey.900',
  },
  // Hardcoded values until we add color to palette
  occupied: {
    bgColor: 'cyan.50',
    color: 'cyan.500',
  },
  finishing: {
    bgColor: 'cyan.50',
    color: 'cyan.500',
  },
  preparing: {
    bgColor: colors.indigo[50],
    color: colors.indigo[600],
  },
  // Hardcoded values until we add color to palette
  suspendedEV: {
    bgColor: 'pink.50',
    color: 'pink.600',
  },
  suspendedEVSE: {
    bgColor: 'secondary.light',
    color: 'secondary.main',
  },
};
