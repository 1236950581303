import React from 'react';
import { formatDateTime, toPayloadDate } from '@energy-stacks/shared';
import CardWidget from '../CardWidget';
import { useGetTransactionTimeSlicesQuery } from '@energy-stacks/broker/feature-dashboard-data';
import { Props } from 'react-apexcharts';
import { IconArrowBigUp } from '@tabler/icons-react';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';

export const UptimeWidget = () => {
  const { t } = useTranslation('dashboard');
  const last24hr = new Date(new Date().getTime() - 24 * 60 * 60 * 1000);
  const [chosenDate, setChosenDate] = React.useState<Date>(last24hr);
  const { data, isLoading } = useGetTransactionTimeSlicesQuery(
    {
      dateStart: toPayloadDate(chosenDate),
    },
    { pollingInterval: 1000 * 30 }
  );

  const lastTimeSlice = data?.slice(-1)[0];
  const uptime = lastTimeSlice?.averageConnectorUptime || -0;

  const plottedUptimeRate =
    data?.map((slice) => {
      const uptimeRate = slice?.averageConnectorUptime;
      const dateSlice = slice.dateSlice;

      return {
        rate: Math.round(uptimeRate),
        timestamp: formatDateTime(dateSlice),
      };
    }) || [];

  const chartData: Props = {
    type: 'line',
    height: 90,
    options: {
      chart: {
        animations: {
          enabled: false,
        },
        sparkline: {
          enabled: true,
        },
      },
      dataLabels: {
        enabled: false,
      },
      grid: {
        padding: {
          top: 7,
          bottom: 7,
          left: 7,
          right: 7,
        },
      },
      colors: ['#fff'],
      fill: {
        type: 'solid',
        opacity: 1,
      },
      stroke: {
        curve: 'smooth',
        width: 3,
      },
      yaxis: {
        min: 0,
        max: 100,
      },
      tooltip: {
        theme: 'dark',
        fixed: {
          enabled: false,
        },
        x: {
          show: true,
        },
        y: {
          title: {
            formatter: (seriesName: string) => seriesName,
          },
          formatter: (val: number) => val + '%',
        },
        marker: {
          show: false,
        },
      },
    },
    series: [
      {
        name: t('connectorUptime'),
        data: plottedUptimeRate.map((uptimeRate) => {
          return {
            x: uptimeRate.timestamp,
            y: uptimeRate.rate,
          };
        }),
      },
    ],
  };

  return (
    <Box>
      <CardWidget
        chartData={chartData}
        color="green"
        isLoading={isLoading}
        value={Math.round(uptime) + '%'}
        name={t('connectorUptime')}
        icon={<IconArrowBigUp />}
        onDateChange={setChosenDate}
      />
    </Box>
  );
};
