import {
  jobStatusMap,
  routePointNormalizer,
} from '@energy-stacks/fleet/shared';
import { JobDto } from '../jobDto';
import { JobModel } from '../jobModel';
import { appendZToDateString } from '@energy-stacks/shared';

export const jobsNormalizer = (jobsDto: JobDto[]): JobModel[] => {
  return jobsDto.map(jobNormalizer);
};

export const jobNormalizer = (job: JobDto): JobModel => {
  return {
    jobId: job.jobUid,
    plantId: job.depotId,
    supplier: {
      supplierId: job.businessAccountUid,
      supplierName: job.companyName,
    },
    origin: routePointNormalizer(job.origin),
    destination: routePointNormalizer(job.destination),
    jobType: job.jobType,
    product: {
      id: job.product.uid,
      name: job.product.name,
      risk: job.product.risk,
      quantity: job.product.quantity
        ? Number(job.product.quantity.toFixed(2))
        : 0,
    },
    estimatedDuration: job.metrics
      ? {
          timeMinutes: job.metrics.totalDuration,
          distanceKilometres: job.metrics.drivingDistance,
        }
      : null,
    dueDate:
      job.jobType === 'OUTBOUND'
        ? appendZToDateString(job.dateDropOffTill)
        : appendZToDateString(job.datePickUpTill),
    earliestStartDate:
      job.jobType === 'OUTBOUND'
        ? appendZToDateString(job.dateDropOffFrom)
        : appendZToDateString(job.datePickUpFrom),
    startDate: appendZToDateString(job.startDate),
    endDate: appendZToDateString(job.endDate),
    status: jobStatusMap[job.status],
  };
};
