import { environment } from '@energy-stacks/feature-config';
import { createBaseQuery } from '@energy-stacks/shared';
import { createApi } from '@reduxjs/toolkit/query/react';

export const registeredServicesApi = createApi({
  reducerPath: 'brokerRegisteredServicesApi',
  tagTypes: ['RegisteredServices'],
  keepUnusedDataFor: 0,
  baseQuery: createBaseQuery(
    `${environment.ocppServiceUrl}/registeredservices`
  ),
  endpoints: (builder) => ({
    getRegisteredServices: builder.query<string[], void>({
      query: () => '/',
    }),
  }),
});

export const { useGetRegisteredServicesQuery } = registeredServicesApi;
