import { createSlice } from '@reduxjs/toolkit';

export const BRANDING_FEATURE_KEY = 'branding';

export interface BrandingState {
  logoHashDate: string;
}

const initialState: BrandingState = {
  logoHashDate: '',
};

export const brandingSlice = createSlice({
  name: BRANDING_FEATURE_KEY,
  initialState,
  reducers: {
    setLogoHashDate: (state) => {
      const hashDate = Date.now().toPrecision();
      state.logoHashDate = hashDate;
    },
  },
});

export const { setLogoHashDate } = brandingSlice.actions;

export const brandingReducer = brandingSlice.reducer;
