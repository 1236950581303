import { TransactionStatusEnum } from '@energy-stacks/broker/feature-transactions-data';
import { BoltRounded, WarningRounded, CheckRounded } from '@mui/icons-material';
import { Box, Chip, SvgIconTypeMap, useTheme } from '@mui/material';
import { deepOrange, green, lightBlue } from '@mui/material/colors';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import React from 'react';
import { useTranslation } from 'react-i18next';

type IconType = OverridableComponent<
  SvgIconTypeMap<Record<string, unknown>, 'svg'>
> & {
  muiName: string;
};

interface TransactionStatusInfo {
  Icon: IconType;
  bgColorLight: string;
  colorLight: string;
  bgColorDark: string;
  colorDark: string;
}

const transactionStatusToChipInfoMap: {
  [status in TransactionStatusEnum]: TransactionStatusInfo;
} = {
  charging: {
    Icon: BoltRounded,
    bgColorLight: 'primary.light',
    colorLight: 'primary.dark',
    bgColorDark: lightBlue['700'],
    colorDark: lightBlue['50'],
  },
  errorFinish: {
    Icon: WarningRounded,
    bgColorLight: 'orange.light',
    colorLight: 'error.dark',
    bgColorDark: deepOrange['700'],
    colorDark: deepOrange['50'],
  },
  successFinish: {
    Icon: CheckRounded,
    bgColorLight: 'success.light',
    colorLight: 'success.dark',
    bgColorDark: green['700'],
    colorDark: green['50'],
  },
};

interface TransactionStatusChipProps {
  transactionStatus: TransactionStatusEnum;
  thumbnail?: boolean;
}

export const TransactionStatus = React.memo<TransactionStatusChipProps>(
  ({ transactionStatus, thumbnail = false }) => {
    const { Icon, bgColorLight, colorLight, bgColorDark, colorDark } =
      transactionStatusToChipInfoMap[transactionStatus];

    const [t] = useTranslation('chargingStations');
    const { palette } = useTheme();

    const color = palette.mode === 'dark' ? colorDark : colorLight;
    const bgColor = palette.mode === 'dark' ? bgColorDark : bgColorLight;

    if (thumbnail)
      return (
        <Box
          sx={{
            width: 24,
            height: 24,
            borderRadius: 3,
            bgcolor: bgColor,
            display: 'grid',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Icon style={{ color }} sx={{ fontSize: 18 }} />
        </Box>
      );

    return (
      <Chip
        size="small"
        label={t(transactionStatus, { context: 'status' })}
        sx={{ backgroundColor: bgColor, color, height: 30, paddingX: 1 }}
      />
    );
  }
);
