import React, { useState, useEffect } from 'react';
import { Typography } from '@mui/material';
import { timeAgoFromDate } from './formatDate';

export const TimeDistance: React.FC<{ date: string }> = ({ date }) => {
  const [, setIntervalActivate] = useState<number>(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setIntervalActivate((prev) => prev + 1);
    }, 60000);

    return () => clearInterval(interval);
  }, []);

  return <Typography fontSize={12}>{timeAgoFromDate(date)}</Typography>;
};
