import { Box } from '@mui/material';
import { useGetUiBrandingQuery } from '@energy-stacks/broker/feature-settings-data';
// NOTE: disabling ts due to compatibility issues between react version and library

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import Chart, { Props } from 'react-apexcharts';

interface ESApexChartProps {
  chartData: Props;
}

export function ESApexChart({ chartData }: ESApexChartProps) {
  const { data: uiBranding } = useGetUiBrandingQuery();

  return (
    <Box
      sx={{
        '.apexcharts-tooltip': {
          borderRadius: uiBranding?.hasBorderRadius ? 2 : 0,
        },
      }}
    >
      <Chart {...chartData} />
    </Box>
  );
}
