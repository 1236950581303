import { ESButton } from '@energy-stacks/core/ui';
import { IconBrandTelegram } from '@tabler/icons-react';
import { useTranslation } from 'react-i18next';
import { CsmsFeatureRoleGuard } from '@energy-stacks/csms/feature-user-access';
import { useAppSelector } from '@energy-stacks/broker/store';
import { useChargingStationDetails } from '../../useChargingStationDetails';

interface SaveConfigurationProps {
  onSave: () => void;
  disabled?: boolean;
}

export const SaveConfiguration201: React.FC<SaveConfigurationProps> = ({
  onSave,
  disabled,
}) => {
  const { t } = useTranslation('chargingStations');
  const configurationCounter = useAppSelector(
    (state) => state.configurationState201.configurationCounter
  );
  const { chargingStationDetails } = useChargingStationDetails({
    listenToUpdates: true,
  });

  const isStationOnline =
    chargingStationDetails?.csStatus === 'online' ? true : false;

  return (
    <CsmsFeatureRoleGuard feature="saveConfiguration">
      <ESButton
        startIcon={<IconBrandTelegram fontSize="medium" />}
        onClick={onSave}
        disabled={disabled || configurationCounter === 0 || !isStationOnline}
      >
        {t('send')}
      </ESButton>
    </CsmsFeatureRoleGuard>
  );
};
