import { DateRange } from '@energy-stacks/core/date-range-picker';
import { Tours } from './Tours';
import { CreateChargingScheduleState } from '../useCreateChargingScheduleState';

interface ToursStepProps {
  onToursSelected: (
    tourIds: CreateChargingScheduleState['selectedTourIds']
  ) => void;
  defaultDateRange: DateRange;
  dateRange: DateRange;
  onDateRangeChange: (dateRange: DateRange) => void;
  selectedTourIds: CreateChargingScheduleState['selectedTourIds'] | undefined;
}

export const ToursStep: React.FC<ToursStepProps> = ({
  onToursSelected,
  dateRange,
  defaultDateRange,
  onDateRangeChange,
  selectedTourIds,
}) => {
  return (
    <Tours
      selectedTourIds={selectedTourIds}
      onToursSelected={onToursSelected}
      dateRange={dateRange}
      defaultDateRange={defaultDateRange}
      onDateRangeChange={(range) =>
        onDateRangeChange(range ?? defaultDateRange)
      }
    />
  );
};
