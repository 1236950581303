import { FetchBaseQueryError } from '@reduxjs/toolkit/query';

/**
 * Type predicate to narrow an unknown error to `FetchBaseQueryError`
 * Reference: https://redux-toolkit.js.org/rtk-query/usage-with-typescript#inline-error-handling-example
 */
export function isFetchBaseQueryError(
  error: unknown
): error is FetchBaseQueryError {
  return typeof error === 'object' && error != null && 'data' in error;
}

/**
 * Type predicate to narrow an unknown error to an object with a `data: { errorCode: string }` property as commonly returned by the BE
 */
export function isErrorWithErrorCode(
  error: unknown
): error is { data: { errorCode: string } } {
  if (isFetchBaseQueryError(error)) {
    const data = error.data;

    return (
      typeof data === 'object' &&
      data != null &&
      'errorCode' in data &&
      typeof data.errorCode === 'string'
    );
  }

  return false;
}
