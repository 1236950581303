import { useAppSelector } from '@energy-stacks/fleet-is/store';
import { useSelectPlantIds } from '@energy-stacks/fleet/feature-business-accounts';
import { useCacheVehicleImages } from '@energy-stacks/fleet/feature-vehicles';
import { FleetIsLogoOverlay } from '@energy-stacks/fleet-is/shared';
import { Main } from '@energy-stacks/shared';
import { useLoadBrokerGoogleMapsApiScript } from '@energy-stacks/broker/shared';

import { Sidebar } from './Sidebar';

export const AppAuth = () => {
  const loginState = useAppSelector((state) => state.user.loginState);
  const { isLoadingPlantIds } = useSelectPlantIds();
  const { isLoaded: isGoogleMapsApiScriptLoaded } =
    useLoadBrokerGoogleMapsApiScript({
      skipFetchingGoogleApiKey: loginState !== 'loggedIn',
    });

  useCacheVehicleImages();

  if (
    loginState === 'loading' ||
    loginState === 'unauthenticated' ||
    isLoadingPlantIds ||
    !isGoogleMapsApiScriptLoaded
  ) {
    return <FleetIsLogoOverlay />;
  }

  return (
    <Main>
      {/* <UserWelcomeDialog /> */}
      <Sidebar />
    </Main>
  );
};
