import { LocationsMap } from '@energy-stacks/broker/feature-locations';
import {
  BrokerLocationModel,
  useGetAllLocationsBrokerQuery,
} from '@energy-stacks/broker/feature-locations-data';
import { useState } from 'react';
import { ESLoadingIndicator } from '@energy-stacks/shared';
import { Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { BrokerRoutes } from '@energy-stacks/broker/shared';

export const DashboardLocationMap = () => {
  const [focusedLocationId, setFocusedLocationId] = useState<
    BrokerLocationModel['uuid'] | null
  >(null);
  const { data: locations, isLoading } = useGetAllLocationsBrokerQuery();
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
        height: 358,
      }}
    >
      {isLoading ? (
        <ESLoadingIndicator />
      ) : (
        <LocationsMap
          data={locations || []}
          focusedLocationId={focusedLocationId}
          setFocusedLocationId={setFocusedLocationId}
          onNavigate={(uuid) =>
            navigate(BrokerRoutes.Locations + `/${uuid}/details`)
          }
        />
      )}
    </Box>
  );
};
