import { ESEllipsisTypography } from '@energy-stacks/core/ui';
import { Stack } from '@mui/material';
import { FC } from 'react';
import { TourContaminationWarning } from './shared/TourContaminationWarning';

type TourIdCellProps = {
  tourId: string;
  hasViolation?: boolean;
};

export const TourIdCell: FC<TourIdCellProps> = ({ tourId, hasViolation }) => {
  return hasViolation ? (
    <Stack alignItems="center" flexDirection="row">
      <TourContaminationWarning
        sx={{
          marginRight: 2,
        }}
      />
      <ESEllipsisTypography variant="inherit">
        {tourId.toUpperCase()}
      </ESEllipsisTypography>
    </Stack>
  ) : (
    <ESEllipsisTypography variant="inherit">
      {tourId.toUpperCase()}
    </ESEllipsisTypography>
  );
};
