import { Box, Typography, useTheme } from '@mui/material';
import { FC, SVGProps } from 'react';

type SectionTitleProps = {
  title: string;
  Icon: FC<SVGProps<SVGSVGElement>>;
};

export const SectionTitle: FC<SectionTitleProps> = ({ title, Icon }) => {
  const { palette } = useTheme();

  return (
    <Box
      sx={{
        alignItems: 'center',
        display: 'flex',
      }}
    >
      <Box
        sx={{
          alignItems: 'center',
          backgroundColor: 'grey.100',
          borderRadius: 1.5,
          display: 'flex',
          justifyContent: 'center',
          mr: 2.5,
          p: 0.5,
        }}
      >
        <Icon
          style={{ color: palette.grey[500] }}
          strokeWidth={1.5}
          height={24}
          width={24}
        />
      </Box>
      <Typography
        variant="h4"
        sx={{
          color: 'grey.900',
          fontWeight: 700,
        }}
      >
        {title}
      </Typography>
    </Box>
  );
};
