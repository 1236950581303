import React from 'react';
import { ESButton } from '@energy-stacks/core/ui';
import { Box, CircularProgress, SxProps } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ConfigurationKeyStatus } from './configurationKeyStatus';
import { ConfigurationKeyStatusChip } from './ConfigurationKeyStatusChip';

export const EditConfigurationListItemStatus: React.FC<{
  status: ConfigurationKeyStatus;
  sx?: SxProps;
  onRetry?: () => void;
}> = ({ status, sx, onRetry }) => {
  const statusContent: Record<ConfigurationKeyStatus, React.ReactNode> = {
    [ConfigurationKeyStatus.Idle]: null,
    [ConfigurationKeyStatus.Accepted]: (
      <ConfigurationKeyStatusChip status={ConfigurationKeyStatus.Accepted} />
    ),
    [ConfigurationKeyStatus.Loading]: <CircularProgress size={16} />,
    [ConfigurationKeyStatus.NotSupported]: (
      <ConfigurationKeyStatusChip
        status={ConfigurationKeyStatus.NotSupported}
      />
    ),
    [ConfigurationKeyStatus.RebootRequired]: (
      <ConfigurationKeyStatusChip
        status={ConfigurationKeyStatus.RebootRequired}
      />
    ),
    [ConfigurationKeyStatus.Rejected]: (
      <ConfigurationKeyStatusChip status={ConfigurationKeyStatus.Rejected} />
    ),
    [ConfigurationKeyStatus.RequestTimeout]: (
      <RetryButton
        onClick={() => {
          onRetry?.();
        }}
      />
    ),
  };

  return <Box sx={sx}>{statusContent[status] || null}</Box>;
};

// not used anywhere other than status cells in this file, has some inconsistent styling in order to fit well in the row
const RetryButton: React.FC<{ onClick: () => void }> = ({ onClick }) => {
  const [t] = useTranslation('chargingStations');

  return (
    <ESButton
      variant="text"
      size="small"
      onClick={onClick}
      sx={{
        height: 26, // align with chip height -> default height not exactly per MUI spec in Figma
      }}
    >
      {t('retry')}
    </ESButton>
  );
};
