import styled from '@emotion/styled';
import { TableRow, TableRowProps, useTheme } from '@mui/material';
import { forwardRef, useEffect, useCallback, useState, useMemo } from 'react';
import { STICKY_COLUMN_CLASS_NAME } from './sticky-column-styles';
import React from 'react';

export const DEFAULT_ROW_HEIGHT = 68.5;

export interface ESTableBodyRowProps extends TableRowProps {
  onRowClick?: () => void;
  onPrefetchIntent?: () => void;
  selected?: boolean;
  children: React.ReactNode;
  rowHeight?: number | 'unset';
}

// TODO: See if this function is even needed
function usePrefetchIntent(): [
  boolean,
  { onMouseEnter: () => void; onMouseLeave: () => void }
] {
  const [maybePrefetch, setMaybePrefetch] = useState(false);
  const [shouldPrefetch, setShouldPrefetch] = useState(false);

  const setIntent = useCallback(() => {
    setMaybePrefetch(true);
  }, []);

  const cancelIntent = useCallback(() => {
    setMaybePrefetch(false);
    setShouldPrefetch(false);
  }, []);

  useEffect(() => {
    let id: number | undefined;
    if (maybePrefetch) {
      id = window.setTimeout(() => {
        setShouldPrefetch(true);
      }, 250);
    }
    return () => {
      if (id) {
        clearTimeout(id);
      }
    };
  }, [maybePrefetch]);

  return useMemo(
    () => [
      shouldPrefetch,
      {
        onMouseEnter: setIntent,
        onMouseLeave: cancelIntent,
      },
    ],
    [cancelIntent, setIntent, shouldPrefetch]
  );
}

export const ESTableBodyRow = forwardRef<
  HTMLTableRowElement,
  ESTableBodyRowProps
>(function ESTableBodyRow(
  {
    onRowClick,
    onPrefetchIntent,
    selected,
    children,
    rowHeight = DEFAULT_ROW_HEIGHT,
    sx,
    ...other
  },
  ref
) {
  const theme = useTheme();

  const rowSx = useMemo(() => {
    const sxObject = typeof sx === 'function' ? sx(theme) : sx;
    return {
      '&': {
        height: rowHeight,
        minHeight: rowHeight,
        position: 'relative',
      },
      cursor: onRowClick ? 'pointer' : 'default',
      ...sxObject,
    };
  }, [onRowClick, rowHeight, sx, theme]);

  return (
    <StyledTableRow
      selected={selected}
      hover
      onClick={onRowClick}
      sx={rowSx}
      ref={ref}
      {...other}
    >
      {children}
    </StyledTableRow>
  );
});

const StyledTableRow = styled(TableRow)`
  &:hover > .${STICKY_COLUMN_CLASS_NAME} {
    background-color: inherit;
  }
`;
