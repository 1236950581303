import { JobModel } from '@energy-stacks/fleet/feature-jobs-data';
import { ESTooltip } from '@energy-stacks/shared';
import { Stack, Typography } from '@mui/material';
import { JobTypeIcon } from './JobTypeIcon';
import { useTranslation } from 'react-i18next';

interface JobQuantityCellProps {
  jobType: JobModel['jobType'] | null;
  quantity: number;
}

export const JobQuantityCell: React.FC<JobQuantityCellProps> = ({
  jobType,
  quantity,
}) => {
  const [t] = useTranslation('jobs');

  return (
    <Stack
      direction="row"
      alignItems="center"
      width={75}
      justifyContent="space-between"
    >
      <ESTooltip title={t(`jobTypes.${jobType}`)}>
        {jobType ? <JobTypeIcon jobType={jobType} /> : '---'}
      </ESTooltip>
      <Typography>{quantity ? quantity.toFixed(2) : '---'}</Typography>
    </Stack>
  );
};
