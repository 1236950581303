export interface BrokerChargingStationShadowConfigurationDto {
  configurationKey: string;
  value: string;
  readonly: boolean;
  dateLastUpdate: string;
}

interface OcppKeyValueDto {
  key: string;
  readonly: boolean;
  value: string;
  dateLastUpdate: string;
}

export interface BrokerChargingStationConfigurationDto {
  configurationKey: OcppKeyValueDto[];
  unknownKey: OcppKeyValueDto[];
}

export interface ConfigurationModel {
  key: string;
  currentValue: string;
  readOnly: boolean;
  lastUpdate: string;
}

export const characteristicsDataTypes = [
  'string',
  'decimal',
  'integer',
  'dateTime',
  'boolean',
  'OptionList',
  'SequenceList',
  'MemberList',
] as const;

export type CharacteristicsDataType = (typeof characteristicsDataTypes)[number];

export interface OcppVariableCharacteristicModel {
  unit: string;
  dataType: CharacteristicsDataType;
  minLimit: number;
  maxLimit: number;
  valuesList: string;
  supportsMonitoring: boolean;
}

export const attributeTypes = ['Actual', 'Target', 'MinSet', 'MaxSet'] as const;
export type AttribudeType = (typeof attributeTypes)[number];

export const mutabilityTypes = ['ReadOnly', 'WriteOnly', 'ReadWrite'];
export type MutabilityType = (typeof mutabilityTypes)[number];

interface OcppVariableAttribudeModel {
  type: AttribudeType;
  value: string;
  mutability: MutabilityType;
  persistent: boolean;
  constant: boolean;
}

export interface ConfigurationModel201 {
  component: string;
  variable: string;
  variableCharacteristics: OcppVariableCharacteristicModel;
  variableAttribute: OcppVariableAttribudeModel[];
  dateLastUpdate: string;
}

export interface ConfigurationTableModel201 {
  component: string;
  variable: string;
  variableCharacteristics: OcppVariableCharacteristicModel;
  variableAttribute: OcppVariableAttribudeModel;
  dateLastUpdate: string;
}
