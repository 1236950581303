import {
  PaginationState,
  PaginationDefaultOptions,
} from '@tanstack/react-table';
import { useCallback, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

export const DEFAULT_OPTIONS = [10, 20, 50];
export const DEFAULT_PAGINATION_CONFIG = {
  page: 0,
  size: DEFAULT_OPTIONS[0],
  pageCount: undefined,
};

type Params = { shouldPersist: boolean; totalRows: number };

export const usePagination = (params?: Params) => {
  const { shouldPersist, totalRows } = params || {
    shouldPersist: false,
    totalRows: 0,
  };
  const [searchParams, setSearchParams] = useSearchParams();

  const initialPagination: PaginationState = useMemo(() => {
    if (!shouldPersist) {
      return {
        pageIndex: DEFAULT_PAGINATION_CONFIG.page,
        pageSize: DEFAULT_PAGINATION_CONFIG.size,
        pageCount: DEFAULT_PAGINATION_CONFIG.pageCount,
      };
    }

    let pageToSet = Number(searchParams.get('page'));
    const rowsPerPageParam = Number(searchParams.get('rowsPerPage'));
    const pageSize = DEFAULT_OPTIONS.includes(rowsPerPageParam)
      ? rowsPerPageParam
      : DEFAULT_OPTIONS[0];
    const pageCount = Math.ceil(totalRows / pageSize);

    const lastPage = pageCount || 1;
    if (!pageToSet || pageToSet < 1 || pageToSet > lastPage) {
      pageToSet = 1;
    }

    return {
      pageIndex: pageToSet - 1,
      pageSize,
      pageCount,
    };
  }, [searchParams, shouldPersist, totalRows]);

  const [pagination, setPagination] =
    useState<PaginationState>(initialPagination);

  const handlePaginationChange: PaginationDefaultOptions['onPaginationChange'] =
    useCallback(
      (updaterOrValue) => {
        if (shouldPersist) {
          const newPagination =
            typeof updaterOrValue === 'function'
              ? updaterOrValue(pagination)
              : updaterOrValue;

          setSearchParams(
            (params) => {
              params.set('page', String(newPagination.pageIndex + 1));
              params.set('rowsPerPage', String(newPagination.pageSize));
              return params;
            },
            { replace: true }
          );
        }
        return setPagination(updaterOrValue);
      },
      [pagination, setSearchParams, shouldPersist]
    );

  return {
    pagination,
    setPagination: handlePaginationChange,
  };
};
