import { forwardRef } from 'react';
import { Avatar, useTheme } from '@mui/material';

type AvatarSize = 'small' | 'large';
type AvatarSizeValues = { width: number; height: number; fontSize: number };

interface ESUserAvatarProps {
  testId?: string;
  size?: AvatarSize;
  userInitials: string | undefined;
  avatarUrl?: string;
  onClick?: () => void;
}

const avatarSizeMap: Record<AvatarSize, AvatarSizeValues> = {
  small: {
    width: 40,
    height: 40,
    fontSize: 14,
  },
  large: {
    width: 80,
    height: 80,
    fontSize: 20,
  },
};

export const ESUserAvatar = forwardRef<HTMLDivElement, ESUserAvatarProps>(
  ({ size = 'small', testId, userInitials, avatarUrl, onClick }, ref) => {
    const theme = useTheme();
    const { width, height, fontSize } = avatarSizeMap[size];

    return (
      <Avatar
        data-testid={testId}
        alt={userInitials}
        src={avatarUrl || userInitials}
        ref={ref}
        sx={{
          ...theme.typography.body1,
          backgroundColor: 'primary.200',
          width,
          height,
          fontSize,
          cursor: onClick ? 'pointer' : 'default',
        }}
        color="inherit"
        onClick={onClick}
      >
        {userInitials}
      </Avatar>
    );
  }
);
