import { useContext } from 'react';
import { ESMenuContext } from './ESMenu';

export const useESMenuContext = () => {
  const context = useContext(ESMenuContext);
  if (context === undefined) {
    throw new Error(
      'useESMenuContext must be used within a ESMenuContext.Provider'
    );
  }
  return context;
};
