import { matchPath, useLocation } from 'react-router-dom';

// Useful when handling tab routing. Will match one of the provided tab paths
export const useRouteMatch = (patterns: readonly string[]) => {
  const { pathname } = useLocation();

  for (let i = 0; i < patterns.length; i += 1) {
    const pattern = patterns[i];
    const possibleMatch = matchPath(pattern, pathname);
    if (possibleMatch !== null) {
      return { match: possibleMatch, index: i };
    }
  }

  return null;
};
