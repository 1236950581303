import { toPayloadDate } from '@energy-stacks/shared';
import {
  AddTariffFormData,
  FormDataPriceComponent,
  Restrictions,
  TariffFormDataElement,
} from './addTariffFormData';
import {
  AddTariffDataDto,
  PriceComponentDto,
  RestrictionsDto,
  TariffElementDto,
  daysOfWeek,
} from './addTariffDataDto';
import { format } from 'date-fns';

export const mapTariffDataToPayload = (
  tariff: AddTariffFormData
): AddTariffDataDto => {
  return {
    currency: tariff.currency,
    type: tariff.type || null,
    elements: tariff.tariffElements.map(mapTariffElementToPayload),
    tariff_alt_text: [
      {
        language: 'en',
        text: tariff.name,
      },
    ],
    start_date_time: tariff.startDate ? toPayloadDate(tariff.startDate) : '',
    end_date_time: tariff.expirationDate
      ? toPayloadDate(tariff.expirationDate)
      : '',
    // party_id and contry_code are required on the backend but we have
    // no way of getting them right now, so they are hardcoded
    party_id: 'ERG',
    country_code: 'DE',
  };
};

const mapTariffElementToPayload = (
  element: TariffFormDataElement
): TariffElementDto => {
  return {
    restrictions: element.restrictions
      ? mapRestrictionsToPayload(element.restrictions)
      : undefined,
    price_components: element.priceComponents.map(mapPriceComponentsToPayload),
  };
};

const mapRestrictionsToPayload = (
  restrictions: Restrictions
): RestrictionsDto => {
  return {
    day_of_week: restrictions.days.map((d) => daysOfWeek[Number(d)]),
    start_time: restrictions.startTime
      ? format(restrictions.startTime, 'HH:mm')
      : undefined,
    end_time: restrictions.endTime
      ? format(restrictions.endTime, 'HH:mm')
      : undefined,
  };
};

const mapPriceComponentsToPayload = (
  priceComponent: FormDataPriceComponent
): PriceComponentDto => {
  return {
    type: priceComponent.type,
    price: Number(priceComponent.price),
    vat: Number(priceComponent.vat),
    step_size: 1,
  };
};
