import { IconButton, InputAdornment, TextField } from '@mui/material';
import React, { useState } from 'react';
import { IconSearch, IconX } from '@tabler/icons-react';
import { useTranslation } from 'react-i18next';

export const SearchTextField: React.FC<{
  value: string;
  placeholder?: string;
  onChange: (value: string) => void;
  autoFocus?: boolean;
  testId?: string;
}> = React.memo(
  ({ value: initialValue, placeholder, onChange, autoFocus, testId }) => {
    const [value, setValue] = useState(initialValue);
    const [tShared] = useTranslation('shared');

    const clearInput = () => {
      setValue('');
      onChange('');
    };

    return (
      <TextField
        id="outlined-adornment-amount"
        size="small"
        variant="outlined"
        sx={{
          width: 295,
        }}
        inputProps={{
          'data-testid': `${testId}SearchField`,
          size: placeholder?.length,
          sx: {
            textOverflow: 'ellipsis',
          },
        }}
        value={value}
        placeholder={placeholder || tShared('search')}
        onChange={(e) => {
          onChange(e.target.value);
          setValue(e.target.value);
        }}
        onKeyDown={(e) => e.stopPropagation()} // Prevents selecting list item instead of entering text in the search field
        InputProps={{
          autoFocus,
          autoComplete: 'off',
          startAdornment: (
            <InputAdornment
              position="start"
              sx={{ '& > svg': { pointerEvents: 'none' } }}
            >
              <IconSearch />
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                onClick={clearInput}
                edge="end"
                color="inherit"
                size="small"
                aria-label="clear"
                sx={{
                  visibility: value ? 'visible' : 'hidden',
                  marginRight: '2px',
                  padding: 1,
                }}
              >
                <IconX fontSize="small" />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    );
  }
);
