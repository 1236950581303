import { IconButton } from '@mui/material';
import { IconLayoutList, IconMap } from '@tabler/icons-react';
import { useCallback, useState } from 'react';

export type TourDetailsViewMode = 'table' | 'map';

type TourDetailsViewModeButtonProps = {
  onViewModeChange: (viewMode: TourDetailsViewMode) => void;
};

export const TourDetailsViewModeButton: React.FC<
  TourDetailsViewModeButtonProps
> = ({ onViewModeChange }) => {
  const [viewMode, setViewMode] = useState<TourDetailsViewMode>('table');

  const toggleViewModeHandler = useCallback(() => {
    const nextViewMode = viewMode === 'map' ? 'table' : 'map';
    setViewMode(nextViewMode);
    onViewModeChange(nextViewMode);
  }, [onViewModeChange, viewMode]);

  return (
    <IconButton onClick={toggleViewModeHandler}>
      {viewMode === 'table' ? <IconMap /> : <IconLayoutList />}
    </IconButton>
  );
};
