import { TableCellProps, TableCell } from '@mui/material';
import {
  STICKY_COLUMN_CLASS_NAME,
  STICKY_COLUMN_SX,
} from './sticky-column-styles';

interface ESTableBodyCellProps extends TableCellProps {
  children: React.ReactNode;
  isSticky?: boolean;
}

export const ESTableBodyCell: React.FC<ESTableBodyCellProps> = ({
  isSticky,
  children,
  sx,
  ...rest
}) => {
  return (
    <TableCell
      {...rest}
      className={isSticky ? STICKY_COLUMN_CLASS_NAME : ''}
      sx={(theme) => ({
        ...sx,
        ...(isSticky ? STICKY_COLUMN_SX(theme) : {}),
      })}
    >
      {children}
    </TableCell>
  );
};
