import { ConnectorModel } from '@energy-stacks/broker/feature-charging-stations-data';
import { ViewMode } from '../protocol-v1.6/Connectors';
import { ConnectorGeneralTabCardView } from './ConnectorGeneralTabCardView';
import { ConnectorGeneralTabListView } from './ConnectorGeneralTabListView';

interface ConnectorGeneralTabProps {
  connector: ConnectorModel;
  testId: string;
  viewMode: ViewMode;
}

export const ConnectorGeneralTab = ({
  connector,
  testId,
  viewMode,
}: ConnectorGeneralTabProps) => {
  return (
    <>
      {viewMode === 'grid' ? (
        <ConnectorGeneralTabCardView connector={connector} testId={testId} />
      ) : (
        <ConnectorGeneralTabListView connector={connector} testId={testId} />
      )}
    </>
  );
};
