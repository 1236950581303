import {
  ESButton,
  ESDialogActions,
  ESDialogContent,
  ESDialogTitle,
  useESSnackbar,
} from '@energy-stacks/core/ui';
import { Dialog } from '@mui/material';
import {
  ChargingStationDetailsModel,
  useClearAuthorizationCache201Mutation,
} from '@energy-stacks/broker/feature-charging-stations-data';
import { useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';

interface ClearAuthorizationCacheDialogProps {
  isOpen: boolean;
  onClose: () => void;
  identityKey: ChargingStationDetailsModel['identityKey'];
}

export const ClearAuthorizationCacheDialog201: React.FC<
  ClearAuthorizationCacheDialogProps
> = ({ identityKey, isOpen, onClose }) => {
  const { t } = useTranslation('chargingStations');
  const { showSnackbar } = useESSnackbar();
  const [triggerClearAuthorizationCache, { isLoading }] =
    useClearAuthorizationCache201Mutation();
  const requestRef =
    useRef<ReturnType<typeof triggerClearAuthorizationCache>>();

  const handleClearAuthorizationCache = useCallback(() => {
    const clearAuthorizationCache = triggerClearAuthorizationCache({
      identityKey,
    });

    requestRef.current = clearAuthorizationCache;

    clearAuthorizationCache
      .unwrap()
      .then((result) => {
        if (result.status === 'Rejected') {
          showSnackbar(
            'error',
            t('clearAuthorizationCacheRejectedErrorMessage')
          );
          return;
        }

        if (result.status === 'Accepted') {
          showSnackbar('success', t('clearAuthorizationCacheSuccessMessage'));
        }

        onClose();
      })
      .catch((error) => {
        if (error.data?.errorCode === 'RPC_TIMEOUT') {
          showSnackbar('error', t('rpcTimeoutError'));
          return;
        }
        if (error.data?.errorCode === 'RPC_ERROR') {
          showSnackbar('error', t('rpcError'));
          return;
        }

        if (error.name === 'AbortError') {
          return;
        }

        showSnackbar('error');
      });
  }, [identityKey, triggerClearAuthorizationCache, onClose, t, showSnackbar]);

  const handleCancel = useCallback(() => {
    requestRef.current?.abort();
    onClose();
  }, [onClose]);

  return (
    <Dialog open={isOpen} onClose={handleCancel} fullWidth maxWidth="sm">
      <ESDialogTitle data-testid="clearAuthorizationCacheTitle">
        {t('clearAuthorizationCacheConfirmationDialogTitle')}
      </ESDialogTitle>

      {/* TODO: Add description of clear authorization cache consequences */}
      <ESDialogContent data-testid="clearAuthorizationCacheContent">
        {t('clearAuthorizationCacheConfirmationDialogDescription')}
      </ESDialogContent>

      <ESDialogActions>
        <ESButton
          data-testid="clearAuthorizationCacheCancelButton"
          variant="text"
          color="error"
          onClick={handleCancel}
          disabled={isLoading}
        >
          {t('cancel')}
        </ESButton>

        <ESButton
          data-testid="clearAuthorizationCacheClearButton"
          variant="contained"
          onClick={handleClearAuthorizationCache}
          disabled={isLoading}
          loading={isLoading}
        >
          {t('clearAuthorizationCacheConfirmationDialogButtonText')}
        </ESButton>
      </ESDialogActions>
    </Dialog>
  );
};
