import { Country, countries } from '../countries';

export const countryNormalizer = (countryCode: string | undefined): Country => {
  const countryName =
    countries.find((country) => country.value === countryCode)?.label ?? '';
  return {
    label: countryName,
    value: countryCode || '',
  };
};
