export type TransportationAmenity =
  | 'busStop'
  | 'taxiStand'
  | 'bikeSharing'
  | 'tramStop'
  | 'metroStation'
  | 'trainStation'
  | 'airport'
  | 'carpoolParing'
  | 'fuelStation'
  | 'parkingLot';

export enum TransportationAmenityEnum {
  BusStop = 'busStop',
  TaxiStand = 'taxiStand',
  BikeSharing = 'bikeSharing',
  TramStop = 'tramStop',
  MetroStation = 'metroStation',
  TrainStation = 'trainStation',
  Airport = 'airport',
  CarpoolParing = 'carpoolParing',
  FuelStation = 'fuelStation',
  ParkingLot = 'parkingLot',
}

export type FoodAndDrinkAmenity = 'restaurant' | 'cafe' | 'wifi';

export enum FoodAndDrinkAmenityEnum {
  Restaurant = 'restaurant',
  Cafe = 'cafe',
  Wifi = 'wifi',
}

export type ActivityAmenity =
  | 'sport'
  | 'recreationArea'
  | 'nature'
  | 'museum'
  | 'hotel';

export enum ActivityAmenityEnum {
  Sport = 'sport',
  RecreationArea = 'recreationArea',
  Nature = 'nature',
  Museum = 'museum',
  Hotel = 'hotel',
}

export type ShoppingAmenity = 'mall' | 'superMarket';

export enum ShoppingAmenityEnum {
  Mall = 'mall',
  SuperMarket = 'superMarket',
}

export type AmenityModel =
  | TransportationAmenity
  | FoodAndDrinkAmenity
  | ActivityAmenity
  | ShoppingAmenity;
