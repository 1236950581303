import { FormStepperModel } from '@energy-stacks/form-stepper';

export const brokerAddChargingStationSteps: BrokerAddChargingStationSteps = {
  0: { name: 'generalDetails', optional: false },
  1: { name: 'addConnectors', optional: true },
  2: { name: 'overview', optional: false },
};

export type BrokerAddChargingStationFormStepName =
  | 'generalDetails'
  | 'addConnectors'
  | 'overview';

export type BrokerAddChargingStationSteps =
  FormStepperModel<BrokerAddChargingStationFormStepName>;
