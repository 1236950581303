import { Box } from '@mui/material';
import { FormStepContentHeaderSubtitle } from './FormStepContentHeaderSubtitle';
import { FormStepContentHeaderTitle } from './FormStepContentHeaderTitle';

interface FormStepContentHeaderProps {
  title: string;
  subtitle: string;
}

export const FormStepContentHeader: React.FC<FormStepContentHeaderProps> = ({
  title,
  subtitle,
}) => {
  return (
    <Box mb={12} textAlign="center">
      <FormStepContentHeaderTitle>{title}</FormStepContentHeaderTitle>
      <FormStepContentHeaderSubtitle>{subtitle}</FormStepContentHeaderSubtitle>
    </Box>
  );
};
