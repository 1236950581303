import React from 'react';
import { CsmsFeature } from './csmsFeature';
import { useCsmsFeatureGuard } from './useCsmsFeatureGuard';

export const CsmsFeatureRoleGuard: React.FC<{
  children: React.ReactNode;
  feature: CsmsFeature;
}> = ({ children, feature }) => {
  const hasAccess = useCsmsFeatureGuard(feature);

  if (!hasAccess) {
    return null;
  }

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
};
