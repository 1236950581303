export const vehicleApiErrors: Record<string, string> = {
  END_DATE_IS_EARLIER_THAN_START_DATE: 'badTimeRange',
  VIN_NOT_FOUND: 'vinNotFound',
  VEHICLE_CORE_UUID_NOT_FOUND: 'coreIdNotFound',
  FLEET_VEHICLE_IDENTIFICATION_NUMBER_NOT_FOUND: 'vehicleNotFound',
  VEHICLE_CORE_NOT_FOUND: 'coreVehicleNotFound',
  INVALID_HOURS: 'invalidWorkingHours',
  DUPLICATE_WEEKDAYS: 'duplicateWeekdays',
  INVALID_BEGIN_END_TIME: 'invalidWorkingHoursTimeRange',
  FLEET_VEHICLE_IDENTIFICATION_NUMBER_ALREADY_EXISTS: 'vehicleIdAlreadyExists',
  VEHICLE_IS_ASSIGNED_TO_A_TOUR: 'vehicleAssigned',
  COULD_NOT_EXTRACT_ERROR_FROM_RESPONSE: 'noMessage',
};
