import React from 'react';
import { ESTooltip } from '@energy-stacks/shared';
import { useTranslation } from 'react-i18next';
import { ReactComponent as ChargingStationIcon } from '../charging-stations-table/icons/chargingStation.svg';
import { ReactComponent as CentralSystemIcon } from '../charging-stations-table/icons/centralSystem.svg';
import { useTheme } from '@mui/material';

interface ChargingStationConnectionStatusDotProps {
  status: 'online' | 'offline';
  type: 'chargingstation' | 'csms';
}

interface Status {
  color: string;
  tooltipTitle: string;
}

export const ChargingStationConnectionStatusDot: React.FC<
  ChargingStationConnectionStatusDotProps
> = ({ status, type }) => {
  const [t] = useTranslation('chargingStations');
  const { palette } = useTheme();

  const connectionStatuses: Record<string, Status> = {
    online: {
      color: palette.success.dark,
      tooltipTitle: 'online',
    },
    offline: {
      color: palette.error.dark,
      tooltipTitle: 'offline',
    },
  };

  return (
    <ESTooltip title={t(connectionStatuses[status].tooltipTitle)}>
      {type === 'csms' ? (
        <CentralSystemIcon
          stroke={connectionStatuses[status].color}
          height={30}
          width={30}
        />
      ) : (
        <ChargingStationIcon
          stroke={connectionStatuses[status].color}
          height={30}
          width={30}
        />
      )}
    </ESTooltip>
  );
};
