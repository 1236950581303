import { ESRoutes } from '@energy-stacks/shared';
import { useAppSelector } from '@energy-stacks/fleet/store';
import { useNavigate } from 'react-router-dom';
import { useUpdateEffect } from 'usehooks-ts';

export const PlantIdChangeNavigateToRoot = () => {
  const selectedPlantId = useAppSelector(
    (state) => state.plant.selectedPlantId
  );

  const navigate = useNavigate();

  useUpdateEffect(() => {
    navigate(ESRoutes.Root);
  }, [selectedPlantId]);

  return null;
};
