import { useGetBaseReportMutation } from '@energy-stacks/broker/feature-charging-station-management-data';
import { useESSnackbar } from '@energy-stacks/core/ui';
import { useChargingStationIdentityKey } from '@energy-stacks/shared';
import {
  endConfigurationRefetch,
  startConfigurationRefetch,
  useAppDispatch,
} from '@energy-stacks/broker/store';
import { useCallback } from 'react';

export const useRefreshConfiguration201 = () => {
  const dispatch = useAppDispatch();
  const { showSnackbar } = useESSnackbar();
  const identityKey = useChargingStationIdentityKey();
  const [triggerBaseReport] = useGetBaseReportMutation();

  const refreshConfiguration = useCallback(async () => {
    // Immediately trigger a loading indicator in configuration tab.
    dispatch(startConfigurationRefetch());
    // getBaseReport is a mutation that retrieves updated configuration and it is sending
    // stomp messages one by one. That is why we trigger the loading screen until config
    // is retrieved. The table would rerender for each config key change because we update
    // cache value in RTK query.

    triggerBaseReport({
      identityKey,
      reportBase: 'FullInventory',
      requestId: 0,
    })
      .unwrap()
      .then(() => {
        setTimeout(() => {
          showSnackbar(
            'success',
            'configurationRetrieveSuccess',
            'chargingStations'
          );
          dispatch(endConfigurationRefetch());
        }, 5000);
      })
      .catch((error) => {
        dispatch(endConfigurationRefetch());
        if (error.data.errorCode === 'RPC_TIMEOUT') {
          showSnackbar('error', 'rpcTimeoutError', 'chargingStations');
          return;
        }
        if (error.data.errorCode === 'RPC_ERROR') {
          showSnackbar('error', 'rpcError', 'chargingStations');
          return;
        }
        showSnackbar('error');
      });
  }, [dispatch, triggerBaseReport, identityKey, showSnackbar]);

  return refreshConfiguration;
};
