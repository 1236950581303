import { LastSessionsTable } from './LastSessionsTable';
import { useDashboardTransactions } from '../useDashboardTransactions';

export const DashboardTransactionsTable = () => {
  const { transactions } = useDashboardTransactions();
  return (
    <LastSessionsTable
      testId="dashboardTransactions"
      transactions={transactions}
    />
  );
};
