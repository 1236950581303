import React, { useMemo, useRef } from 'react';
import {
  ESVirtualizedTable,
  ESVirtualizedTableProps,
} from './ESVirtualizedTable';
import { useDrop } from 'react-dnd';
import { DndRowsOptions, DroppableItem } from './DndRowsOptions';
import {
  ESTableDraggableRow,
  ESTableDraggableRowProps,
} from './ESTableDraggableRow';
import { Row } from '@tanstack/react-table';
import { useScrollOnDrag } from '../useScrollOnDrag';

interface ESDnDVirtualizedTableProps<T>
  extends Omit<ESVirtualizedTableProps<T>, 'scrollerRef' | 'renderRow'> {
  dndRowsOptions: DndRowsOptions<T>;
  applyRowStyles?: (row: Row<T>) => ESTableDraggableRowProps<T>['rowStyles'];
}

export const ESDnDVirtualizedTable = <T,>({
  instance,
  rows,
  rowHeight,
  dndRowsOptions,
  testId,
  applyRowStyles,
}: ESDnDVirtualizedTableProps<T>) => {
  const [{ draggedItemId }, dropRef] = useDrop(
    {
      accept: 'row',
      collect: (monitor) => ({
        draggedItemId: monitor.getItem()?.id,
      }),
      drop: (item: DroppableItem<T>, monitor: { didDrop: () => boolean }) => {
        const didDrop = monitor.didDrop();
        if (didDrop) {
          return;
        }

        requestAnimationFrame(() => {
          // Drop handler on the table used when there is no rows in the table that can listen to the drop event
          dndRowsOptions.onDrop?.(
            item.selectedRowIds.length > 0
              ? item.selectedRowIds
              : [dndRowsOptions.dragRowsSelectId?.(item.row) || ''],
            ''
          );
        });
      },
    },
    [dndRowsOptions.onDrop]
  );

  const tableContainerRef = useRef<HTMLElement | null>(null);
  useScrollOnDrag({
    scrollContainer: tableContainerRef.current,
  });

  const isLocalDrag = useMemo(() => {
    return Boolean(rows.find((row) => row.id === draggedItemId));
  }, [draggedItemId, rows]);

  return (
    <ESVirtualizedTable
      instance={instance}
      rows={rows}
      rowHeight={rowHeight}
      scrollerRef={(ref) => {
        const element = ref as HTMLElement;
        dropRef(element);
        tableContainerRef.current = element;
      }}
      renderRow={(props) => {
        const index = props['data-index'];
        const row = rows[index];
        return (
          <ESTableDraggableRow
            key={row.id}
            data-testid={`${testId}TableRow${row.index}`}
            row={row}
            rowStyles={applyRowStyles?.(row)}
            isLocalDrag={isLocalDrag}
            dndRowsOptions={dndRowsOptions}
            {...props}
          >
            {props.children}
          </ESTableDraggableRow>
        );
      }}
    />
  );
};
