import {
  ESPageLoadingIndicator,
  RefetchOnError,
  useDocumentTitle,
} from '@energy-stacks/shared';
import { useTranslation } from 'react-i18next';
import { useBusinessAccounts } from './useBusinessAccounts';
import { BusinessAccountsTable } from './BusinessAccountsTable';
import { FleetPage } from '@energy-stacks/fleet/shared';

export const BusinessAccountsPage = () => {
  const [t] = useTranslation('businessAccounts');
  const pageTitle = t('pageTitle');
  useDocumentTitle(pageTitle);
  const { data, isLoading, isError, refetch } = useBusinessAccounts();

  return (
    <FleetPage title={pageTitle}>
      {isLoading ? <ESPageLoadingIndicator /> : null}
      {isError ? <RefetchOnError onRefetch={refetch} /> : null}
      {data ? <BusinessAccountsTable businessAccounts={data ?? []} /> : null}
    </FleetPage>
  );
};
