import React from 'react';
import { useTheme, styled } from '@mui/material/styles';
import { Avatar, Box, Button, Grid, Skeleton, Typography } from '@mui/material';
import { Props } from 'react-apexcharts';
import { ESApexChart } from './ESApexChart';
import { PopupMainCard } from '@energy-stacks/core/ui';
import { colors } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface TotalOrderLineChartCardProps {
  isLoading: boolean;
  color: 'blue' | 'purple' | 'dark' | 'green';
  chartData: Props;
  value: string | number;
  name: string;
  icon: React.ReactNode;
  onDateChange: (val: Date) => void;
}

const CardWidget = ({
  isLoading,
  color,
  chartData,
  value,
  name,
  icon,
  onDateChange,
}: TotalOrderLineChartCardProps) => {
  const { t } = useTranslation('dashboard');
  const theme = useTheme();
  const last24hr = new Date(new Date().getTime() - 24 * 60 * 60 * 1000);
  const oneWeekAgo = new Date(Date.now() - 7 * 24 * 60 * 60 * 1000);
  const [timeValue, setTimeValue] = React.useState<'day' | 'week'>('day');
  const handleChangeTime = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    newValue: 'day' | 'week'
  ) => {
    setTimeValue(newValue);
  };

  function getBackgroundColor(propColor: string) {
    if (propColor === 'blue') {
      return theme.palette.primary.main;
    }
    if (propColor === 'purple') {
      return theme.palette.secondary.main;
    }
    if (propColor === 'dark') {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      return theme.palette.dark[800];
    }
    if (propColor === 'green') {
      return theme.palette.success.main;
    }
    return theme.palette.primary.main;
  }

  function getCircleBackgroundColor(propColor: string) {
    if (propColor === 'blue') {
      return colors.blue[800];
    }
    if (propColor === 'purple') {
      return colors.deepPurple[800];
    }
    if (propColor === 'dark') {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      return theme.palette.dark.main;
    }
    if (propColor === 'green') {
      return theme.palette.success.dark;
    }
    return colors.blue[800];
  }

  const backgorundColor = getBackgroundColor(color);
  const circleBackgroundColor = getCircleBackgroundColor(color);

  const CardWrapper = styled(PopupMainCard)(({ theme }) => ({
    backgroundColor:
      theme.palette.mode === 'dark'
        ? theme.palette.primary.main
        : backgorundColor,
    color: '#fff',
    overflow: 'hidden',
    position: 'relative',
    height: 166,
    '&>div': {
      position: 'relative',
      zIndex: 5,
    },
    '&:after': {
      content: '""',
      position: 'absolute',
      width: 210,
      height: 210,
      background:
        theme.palette.mode === 'dark'
          ? `linear-gradient(210.04deg, ${theme.palette.primary.dark} -50.94%, rgba(144, 202, 249, 0) 95.49%)`
          : circleBackgroundColor,
      borderRadius: '50%',
      zIndex: 1,
      top: -85,
      right: -95,
      [theme.breakpoints.down('sm')]: {
        top: -105,
        right: -140,
      },
    },
    '&:before': {
      content: '""',
      position: 'absolute',
      zIndex: 1,
      width: 210,
      height: 210,
      background:
        theme.palette.mode === 'dark'
          ? `linear-gradient(140.9deg, ${theme.palette.primary.dark} -14.02%, rgba(144, 202, 249, 0) 82.50%)`
          : circleBackgroundColor,
      borderRadius: '50%',
      top: -125,
      right: -15,
      opacity: 0.5,
      [theme.breakpoints.down('sm')]: {
        top: -155,
        right: -70,
      },
    },
  }));

  return isLoading ? (
    <Skeleton
      variant="rectangular"
      animation="wave"
      width="100%"
      height={166}
    />
  ) : (
    <CardWrapper border={false} content={false}>
      <Box sx={{ p: 2.25 }}>
        <Grid container direction="column">
          <Grid item>
            <Grid container justifyContent="space-between">
              <Grid item marginLeft={2} marginTop={2}>
                <Avatar
                  variant="rounded"
                  sx={{
                    backgroundColor:
                      theme.palette.mode === 'dark'
                        ? theme.palette.primary.dark
                        : circleBackgroundColor,
                    color: '#fff',
                  }}
                >
                  {icon}
                </Avatar>
              </Grid>
              <Grid marginRight={2} marginTop={2}>
                <Button
                  disableElevation
                  variant={timeValue === 'day' ? 'contained' : 'text'}
                  sx={{
                    minHeight: '32px !important',
                    minWidth: '70px !important',
                    height: '32px !important',
                    width: '70px !important',
                    paddingX: 5,
                    color: theme.palette.grey[100] + '!important',
                    backgroundColor:
                      timeValue === 'day'
                        ? backgorundColor + '!important'
                        : 'inherit',
                    '&:hover': {
                      backgroundColor: backgorundColor + '!important',
                    },
                  }}
                  onClick={(e) => {
                    onDateChange(last24hr);
                    handleChangeTime(e, 'day');
                  }}
                >
                  {t('day')}
                </Button>
                <Button
                  disableElevation
                  variant={timeValue === 'week' ? 'contained' : 'text'}
                  sx={{
                    ml: 1,
                    minHeight: '32px !important',
                    minWidth: '70px !important',
                    height: '32px !important',
                    width: '70px !important',
                    color: theme.palette.grey[100] + '!important',
                    backgroundColor:
                      timeValue === 'week'
                        ? backgorundColor + '!important'
                        : 'inherit',
                    '&:hover': {
                      backgroundColor: backgorundColor + '!important',
                    },
                  }}
                  onClick={(e) => {
                    onDateChange(oneWeekAgo);
                    handleChangeTime(e, 'week');
                  }}
                >
                  {t('week')}
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid item sx={{ mb: 0.75 }}>
            <Grid container alignItems="center">
              <Grid item xs={6}>
                <Grid container alignItems="center">
                  <Grid item>
                    <Typography
                      sx={{
                        fontSize: '2.125rem',
                        fontWeight: 500,
                        mr: 1,
                        mt: 1.75,
                        mb: 0.75,
                        ml: 2,
                      }}
                    >
                      {value}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography
                      sx={{
                        fontSize: '1rem',
                        fontWeight: 500,
                        ml: 2,
                        color:
                          theme.palette.mode === 'dark'
                            ? theme.palette.text.secondary
                            : theme.palette.primary.light,
                      }}
                    >
                      {name}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={6}>
                <ESApexChart chartData={chartData} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </CardWrapper>
  );
};

export default CardWidget;
