type CoordsTuple = [string, string];

export type OffsetPoint<T> = {
  properties: T;
  coords: CoordsTuple;
};

export const offsetPoints = <T>(
  points: OffsetPoint<T>[],
  plant: { coords: CoordsTuple }
): OffsetPoint<T>[] => {
  const pointsMap: Map<string, OffsetPoint<T>[]> = new Map();

  for (const point of points) {
    if (
      point.coords[0] === plant.coords[0] &&
      point.coords[1] === plant.coords[1]
    ) {
      continue;
    }
    const key = point.coords.join(',');
    if (!pointsMap.has(key)) {
      pointsMap.set(key, []);
    }
    pointsMap.get(key)?.push(point);
  }

  for (const value of pointsMap.values()) {
    if (value.length > 1) {
      for (let i = 0; i < value.length; i++) {
        value[i] = {
          properties: value[i].properties,
          coords: [
            (parseFloat(value[i].coords[0]) + i * 0.000003).toString(),
            (parseFloat(value[i].coords[1]) + i * 0.0000492).toString(),
          ],
        };
      }
    }
  }
  return Array.from(pointsMap.values()).flat();
};
