import { Alert, AlertColor, IconButton, useTheme } from '@mui/material';
import { CustomContentProps, SnackbarContent, useSnackbar } from 'notistack';
import { forwardRef } from 'react';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';

type ESSnackbarProps = CustomContentProps;

export const ESSnackbar = forwardRef<HTMLDivElement, ESSnackbarProps>(
  (props, ref) => {
    const { id, style, variant, message } = props;
    const { closeSnackbar } = useSnackbar();
    const { palette } = useTheme();
    const textColor =
      variant === 'success' || variant === 'warning'
        ? palette.grey[900]
        : palette.primary.light;
    return (
      <SnackbarContent key={id} ref={ref} style={style}>
        <Alert
          severity={snackbarVariantMap[variant]}
          variant="filled"
          sx={{
            border: 'none',
            color: textColor,
            flex: 1,
          }}
          action={
            <IconButton size="small" onClick={() => closeSnackbar(id)}>
              <CloseRoundedIcon fontSize="small" sx={{ color: textColor }} />
            </IconButton>
          }
        >
          {message}
        </Alert>
      </SnackbarContent>
    );
  }
);

const snackbarVariantMap: Record<ESSnackbarProps['variant'], AlertColor> = {
  success: 'success',
  error: 'error',
  warning: 'warning',
  info: 'info',
  default: 'info',
};
