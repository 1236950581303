import { appendZToDateString } from '@energy-stacks/shared';
import { TariffDto } from './TariffDto';
import { TariffModel } from './TariffModel';

export const tariffNormalizer = (tariff: TariffDto): TariffModel => {
  return {
    id: tariff.id,
    currency: tariff.currency,
    type: tariff.type,
    name: getTariffName(tariff.tariff_alt_text),
    startDateTime:
      tariff.start_date_time && appendZToDateString(tariff.start_date_time),
    endDateTime:
      tariff.end_date_time && appendZToDateString(tariff.end_date_time),
    elements: tariff.elements?.map((element) => {
      return {
        restrictions: {
          reservation: element?.restrictions?.reservation || '',
          startTime: element?.restrictions?.start_time || '',
          endTime: element?.restrictions?.end_time || '',
          startDate: element?.restrictions?.start_date || '',
          endDate: element?.restrictions?.end_date || '',
          minKwh: element?.restrictions?.min_kwh || 0,
          maxKwh: element?.restrictions?.max_kwh || 0,
          minCurrent: element?.restrictions?.min_current || 0,
          maxCurrent: element?.restrictions?.max_current || 0,
          minPower: element?.restrictions?.min_power || 0,
          maxPower: element?.restrictions?.max_power || 0,
          minDuration: element?.restrictions?.min_duration || 0,
          maxDuration: element?.restrictions?.max_duration || 0,
          dayOfWeek: element?.restrictions?.day_of_week || [],
        },
        priceComponents: element?.price_components
          ? element?.price_components?.map((component) => {
              return {
                type: component.type,
                vat: component.vat,
                price: component.price,
                stepSize: component.step_size,
              };
            })
          : [],
      };
    }),
  };
};

const getTariffName = (tariffAltText: TariffDto['tariff_alt_text'] | null) => {
  const englishTextObj = tariffAltText?.find(
    (altTextObj) => altTextObj.language === 'en'
  );
  return englishTextObj?.text ?? 'N/A';
};
