import { SidebarGroup } from '@energy-stacks/core/sidebar';
import { IconBell } from '@tabler/icons-react';
import { useTranslation } from 'react-i18next';
import { BrokerRoutes } from '@energy-stacks/broker/shared';
import { Badge } from '@mui/material';
import { useStompTopic } from '@energy-stacks/shared';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

export const AlertManagementDrawerItem: React.FC = () => {
  const { t } = useTranslation('alertManagement');
  const itemLabel = t('title');
  const location = useLocation();
  const [hasNewAlerts, setHasNewAlerts] = useState(false);

  useStompTopic(`/topic/alert-created`, () => {
    setHasNewAlerts(true);
  });
  useStompTopic(`/topic/alert-updated`, () => {
    setHasNewAlerts(true);
  });

  useEffect(() => {
    if (location.pathname === `/${BrokerRoutes.AlertManagement}`) {
      setHasNewAlerts(false);
    }
    // If alerts come while the component is mounted we need reset the array so
    // we don't see the sidebar indicator when we leave the tab
    return () => {
      if (location.pathname === `/${BrokerRoutes.AlertManagement}`) {
        setHasNewAlerts(false);
      }
    };
  }, [location, location.pathname]);

  return (
    <SidebarGroup
      testId="alertManagementSidebarButton"
      groupInitialPath={BrokerRoutes.AlertManagement}
      groupIcon={
        <ESAlertManagementIcon
          showBadge={
            hasNewAlerts &&
            location.pathname !== `/${BrokerRoutes.AlertManagement}`
          }
        />
      }
      groupLabel={itemLabel}
    ></SidebarGroup>
  );
};

interface ESAlertManagementIconProps {
  showBadge?: boolean;
}

const ESAlertManagementIcon: React.FC<ESAlertManagementIconProps> = ({
  showBadge,
}) => {
  return (
    <Badge color="error" badgeContent="" variant="dot" invisible={!showBadge}>
      <IconBell />
    </Badge>
  );
};
