import { ESMenu, ESMenuItem } from '@energy-stacks/core/ui';
import { useTranslation } from 'react-i18next';
import { useRefreshConfiguration201 } from './useRefreshConfiguration201';

export const ConfigurationMenu201 = () => {
  const { t } = useTranslation('chargingStations');

  const retrieveConfiguration = useRefreshConfiguration201();

  return (
    <>
      <ESMenu testId={'configurationMenuItems'}>
        <ESMenuItem
          testId={'retrieveConfigurationMenuItem'}
          onClick={() => retrieveConfiguration()}
        >
          {t('retrieveConfiguration')}
        </ESMenuItem>
      </ESMenu>
    </>
  );
};
