import { createApi } from '@reduxjs/toolkit/query/react';
import { environment } from '@energy-stacks/feature-config';
import { createBaseQuery } from '@energy-stacks/shared';
import { ColorScheme } from './colorScheme';
import { UiBrandingModel } from './uiBrandingModel';
import { UiBrandingDto } from './uiBrandingDto';

interface GetGoogleMapsKeyResponse {
  key: string | null;
}

interface GetWebsocketUrl {
  ocppEndpoint: string;
}

export interface UiBrandingFormData {
  colorScheme: ColorScheme | undefined;
  hasBorderRadius: boolean | undefined;
  applicationName: string | undefined;
}

export const settingsApi = createApi({
  reducerPath: 'settingsApi',
  baseQuery: createBaseQuery(`${environment.ocppServiceUrl}`),
  tagTypes: ['apiKey', 'logo', 'favIcon', 'uiBranding'],
  endpoints: (builder) => ({
    setGoogleMapsKey: builder.mutation<
      { success: boolean },
      string | undefined
    >({
      query: (formData) => {
        return {
          url: `/settings/google-maps-key`,
          method: 'PUT',
          body: { key: formData },
        };
      },
      invalidatesTags: ['apiKey'],
    }),
    getGoogleMapsKey: builder.query<string, void>({
      query: () => {
        return {
          url: `/ui-configuration/google-maps-key`,
          method: 'GET',
        };
      },
      providesTags: ['apiKey'],
      transformResponse: (response: GetGoogleMapsKeyResponse): string => {
        return response.key || '';
      },
    }),
    getWebsocketUrl: builder.query<string, void>({
      query: () => {
        return {
          url: `/ui-configuration/ocpp-endpoint`,
          method: 'GET',
        };
      },
      transformResponse: (response: GetWebsocketUrl): string => {
        return response.ocppEndpoint;
      },
    }),
    setLogo: builder.mutation<void, File>({
      query: (logoFile) => {
        const formData = new FormData();
        formData.append('logo', logoFile);

        return {
          url: `/settings/logo`,
          method: 'PUT',
          body: formData,
        };
      },
      invalidatesTags: ['logo'],
    }),
    setFavicon: builder.mutation<void, File>({
      query: (faviconFile) => {
        const formData = new FormData();
        formData.append('favIcon', faviconFile);

        return {
          url: `/settings/favicon.ico`,
          method: 'PUT',
          body: formData,
        };
      },
      invalidatesTags: ['favIcon'],
    }),
    getUiBranding: builder.query<UiBrandingModel, void>({
      query: () => {
        return {
          url: `/ui-configuration/ui-branding`,
          method: 'GET',
        };
      },
      providesTags: ['uiBranding'],
      transformResponse: (response: UiBrandingDto) => {
        return {
          colorScheme: response.colorScheme,
          hasBorderRadius: response.borderRadius > 0 ? true : false,
          applicationName: response.applicationName,
        };
      },
    }),
    setUiBranding: builder.mutation<{ success: boolean }, UiBrandingModel>({
      query: (data) => {
        return {
          url: `/settings/ui-branding`,
          method: 'PUT',
          body: {
            borderRadius: data.hasBorderRadius ? 12 : 0,
            ...data,
          },
        };
      },
      invalidatesTags: ['uiBranding'],
      async onQueryStarted(data, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          settingsApi.util.updateQueryData('getUiBranding', undefined, () => {
            return {
              ...data,
              borderRadius: data.hasBorderRadius ? 12 : 0,
            };
          })
        );
        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
    }),
  }),
});

export const {
  useSetGoogleMapsKeyMutation,
  useGetGoogleMapsKeyQuery,
  useGetWebsocketUrlQuery,
  useSetLogoMutation,
  useSetFaviconMutation,
  useGetUiBrandingQuery,
  useSetUiBrandingMutation,
} = settingsApi;
