import React from 'react';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
  TABLE_HEADER_HEIGHT,
  TABLE_PAGINATION_HEIGHT,
} from '@energy-stacks/core/ui';

interface NoDataProps {
  message?: string;
  top?: number;
}

export const NoTableData: React.FC<NoDataProps> = ({
  message,
  top = TABLE_HEADER_HEIGHT,
}) => {
  const { t } = useTranslation('shared');
  const defaultMessage = t('noDataToDisplay');
  const noDataMessage = message || defaultMessage;
  return (
    <Box
      p={4}
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'absolute',
        height: `calc(100% - ${top}px - ${TABLE_PAGINATION_HEIGHT})`,
        width: '100%',
        top: top,
        left: 0,
        pointerEvents: 'none',
      }}
    >
      <Typography variant="body1" textAlign="center">
        {noDataMessage}
      </Typography>
    </Box>
  );
};
