import { CdrTokenDto, CdrTokenTypeDtoType } from '../cdrTokenDto';
import { CdrToken, CdrTokenTypeEnum } from '../cdrModel';

export const cdrTokenNormalizer = (cdrToken: CdrTokenDto): CdrToken => {
  return {
    uid: cdrToken.uid,
    countryCode: cdrToken.country_code,
    partyId: cdrToken.party_id,
    type: cdrTokenTypeNormalizer(cdrToken.type),
    contractId: cdrToken.contract_id,
  };
};

export const cdrTokenTypeNormalizer = (
  type: CdrTokenTypeDtoType
): CdrTokenTypeEnum => {
  return cdrTokenTypeMap[type];
};

const cdrTokenTypeMap: Record<CdrTokenTypeDtoType, CdrTokenTypeEnum> = {
  AD_HOC_USER: CdrTokenTypeEnum.adHocUser,
  APP_USER: CdrTokenTypeEnum.appUser,
  OTHER: CdrTokenTypeEnum.other,
  RFID: CdrTokenTypeEnum.rfidCard,
};
