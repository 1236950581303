export const vehicleStatusFilterOptions = ['AVAILABLE', 'PLANNED'] as const;

export const vehicleStatuses = [
  'AVAILABLE',
  'PLANNED',
  'ENROUTE',
  'OUT_OF_ORDER',
] as const;

export type VehicleStatus = (typeof vehicleStatuses)[number];

export interface VehicleModel {
  plantId: string;
  vehicleId: string;
  brandId: string;
  brandAndModel: string;
  name: string;
  licencePlate: string;
  weight: number | null;
  volume: number | null;
  status: VehicleStatus;
  isPartiallyPlanned: boolean;
  startTour: string | null;
  endTour: string | null;
}
