import { RecalculateTourDetailsResponse } from '../recalculateTourDetailsResponse';
import { RecalculateTourDetailsResponseDto } from '../recalculateTourDetailsResponseDto';
import { optimizedTourNormalizer } from './optimizedTourNormalizer';
import { skippedJobsNormalizer } from './skippedJobsNormalizer';

export const recalculateTourDetailsResponseNormalizer = (
  response: RecalculateTourDetailsResponseDto
): RecalculateTourDetailsResponse => {
  return {
    optimizedTour: optimizedTourNormalizer(response.optimizedTour),
    skippedJobs: skippedJobsNormalizer(response.skippedJobs),
  };
};
