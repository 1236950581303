import { Box, BoxProps } from '@mui/material';

type ESBannerProps = BoxProps;

export const BANNER__MIN_HEIGHT = 48;

export const ESBanner: React.FC<ESBannerProps> = (props) => {
  const { sx, ...boxProps } = props;

  return (
    <Box
      sx={{
        minHeight: `${BANNER__MIN_HEIGHT}px`,
        // TODO: Add to brand theme colors. Uses Snackbar color in figma, but Snackbar color is not provided by palette.
        bgcolor: '#323232',
        ...sx,
      }}
      {...boxProps}
    />
  );
};
