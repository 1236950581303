import { Alert } from '@energy-stacks/broker/feature-alert-management-data';
import { ESTooltip } from '@energy-stacks/shared';
import { Box } from '@mui/material';
import { IconCheck, IconX } from '@tabler/icons-react';
import { useTranslation } from 'react-i18next';

type AlertStatusCellProps = {
  dateResolved: Alert['dateResolved'];
};

export const AlertStatusCell: React.FC<AlertStatusCellProps> = ({
  dateResolved,
}) => {
  const [t] = useTranslation('alertManagement');

  if (!dateResolved) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <ESTooltip title={t('active')} placement="bottom">
          <IconX />
        </ESTooltip>
      </Box>
    );
  }

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
      <ESTooltip title={t('resolved')} placement="bottom">
        <IconCheck />
      </ESTooltip>
    </Box>
  );
};
