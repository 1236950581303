export enum ConnectorType {
  CCS = 'CCS',
  CCS_MALE = 'CCS Male',
  CCS_FEMALE = 'CCS Female',
  CCS_TYPE_1_MALE = 'CCS Type 1 Male',
  CCS_TYPE_1_FEMALE = 'CCS Type 1 Female',
  CHAdeMO = 'CHAdeMO',
  CHAdeMO_MALE = 'CHAdeMO Male',
  CHAdeMO_FEMALE = 'CHAdeMO Female',
  GB_T_MALE = 'GB T Male',
  GB_T_FEMALE = 'GB T Female',
  TYPE_2 = 'Type 2',
  TYPE_2_MALE = 'Type 2 Male',
  TYPE_2_FEMALE = 'Type 2 Female',
  TYPE_EF = 'Type E/F',
  UNKNOWN = 'Unknown',
}
