import { BusinessAccountDto } from '../businessAccountDto';
import { BusinessAccountModel } from '../businessAccountModel';

export const businessAccountsNormalizer = (
  businessAccountsDto: BusinessAccountDto[]
): BusinessAccountModel[] => {
  return businessAccountsDto.map(businessAccountNormalizer);
};

export const businessAccountNormalizer = (
  businessAccountDto: BusinessAccountDto
): BusinessAccountModel => {
  return {
    id: businessAccountDto.uid,
    plantId: businessAccountDto.depotId,
    accountType: businessAccountDto.type,
    companyName: businessAccountDto.companyName,
    phoneNumber: businessAccountDto.phoneNumber,
    address: businessAccountAddressNormalizer(businessAccountDto.address),
    locations: businessAccountLocationsNormalizer(
      businessAccountDto.locationAddressTypes
    ),
  };
};

const businessAccountAddressNormalizer = (
  addressDto: BusinessAccountDto['address']
): BusinessAccountModel['address'] => {
  if (!addressDto) {
    return {
      city: '',
      street: '',
      zipCode: '',
    };
  }

  return {
    city: addressDto.city,
    street: addressDto.street,
    zipCode: addressDto.zipCode,
  };
};

const businessAccountLocationsNormalizer = (
  locationsDto: BusinessAccountDto['locationAddressTypes']
): BusinessAccountModel['locations'] => {
  if (!locationsDto) {
    return {
      degassed: 0,
      raw: 0,
    };
  }

  return {
    degassed: locationsDto['Degassed Tank'] || 0,
    raw: locationsDto['Raw Tank'] || 0,
  };
};
