import { BrokerUserAccessRequestDto } from '../brokerUserAccessRequestDto';
import { BrokerUserAccessRequestModel } from '../brokerUserAccessRequestModel';
import { userRoleNormalizer } from './userRoleNormalizer';

export const brokerUserAccessRequestNormalizer = (
  request: BrokerUserAccessRequestDto
): BrokerUserAccessRequestModel => ({
  dateAccountCreated: request.userAccount.dateCreatedAt, // "2023-01-04T16:48:07.859+00:00" DTO date should not contain time zone. If they change it add 'Z' at the end.
  dateRequestCreated: request.dateCreated, // "2023-01-04T16:48:07.859+00:00" DTO date should not contain time zone. If they change it add 'Z' at the end.
  displayName: request.userAccount.displayName,
  emailAddress: request.userAccount.emailAddress,
  isActive: request.userAccount.isActive,
  userId: request.userAccount.id,
  userTokenId: request.userAccount.userId,
  userRole: userRoleNormalizer(request.userAccount.userRole),
});
