import { Alert } from '@energy-stacks/broker/feature-alert-management-data';
import { differenceInMinutes } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { getErrorSeverity } from './getErrorSeverity';
import { Chip } from '@mui/material';

type ErrorSeverityCellProps = {
  dateCreated: Alert['dateCreated'];
  dateResolved: Alert['dateResolved'];
};

export const ErrorSeverityCell: React.FC<ErrorSeverityCellProps> = ({
  dateCreated,
  dateResolved,
}) => {
  const [t] = useTranslation('alertManagement');
  const startDate = new Date(dateCreated);
  const endDate =
    Boolean(dateResolved) === false ? new Date() : new Date(dateResolved);

  const differenceMins = differenceInMinutes(endDate, startDate);
  const errorSeverity = getErrorSeverity(differenceMins);
  return (
    <Chip
      label={t(`${errorSeverity?.error}`)}
      variant="outlined"
      sx={{
        backgroundColor: 'transparent',
        color: errorSeverity?.color,
        borderColor: errorSeverity?.border,
        width: 100,
        cursor: 'pointer',
      }}
    />
  );
};
