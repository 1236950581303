import {
  AlertStatus,
  alertStatuses,
} from '@energy-stacks/broker/feature-alert-management-data';
import { TableFilterButton } from '@energy-stacks/shared';
import { Box, ListItemText, MenuItem, Radio, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface AlertManagementAlertStatusFilterProps {
  alertStatusFilter: AlertStatus | undefined;
  onAlertStatusFilterChange: (
    alertStatusFilter: AlertStatus | undefined
  ) => void;
  testId?: string;
}

export const AlertManagementAlertStatusFilter: React.FC<
  AlertManagementAlertStatusFilterProps
> = ({ alertStatusFilter, onAlertStatusFilterChange, testId }) => {
  const { t } = useTranslation('alertManagement');

  const options = alertStatuses.map((reason) => {
    return {
      label: t(reason.toLowerCase()),
      value: reason,
    };
  });

  const handleFilterChange = (filter: AlertStatus) => {
    onAlertStatusFilterChange(filter);
  };

  return (
    <TableFilterButton
      testId={testId}
      title={t('alertStatus')}
      renderPopover={(closePopover) => {
        return (
          <Box
            sx={{
              maxHeight: 450,
              minWidth: 176,
            }}
          >
            {options.length < 1 ? (
              <Box p={3}>
                <ListItemText primary={t('noSearchOptions')} />
              </Box>
            ) : (
              <Box>
                {options.map((alertStatus) => {
                  return (
                    <MenuItem
                      dense
                      selected={alertStatus.value === alertStatusFilter}
                      key={alertStatus.value}
                      onClick={() => {
                        handleFilterChange(alertStatus.value as AlertStatus);
                        closePopover();
                      }}
                    >
                      <Radio
                        checked={alertStatus.value === alertStatusFilter}
                        value={alertStatus}
                      />
                      <Typography>{alertStatus.label}</Typography>
                    </MenuItem>
                  );
                })}
              </Box>
            )}
          </Box>
        );
      }}
      isActive={Boolean(alertStatusFilter)}
    />
  );
};
