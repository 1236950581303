import { environment } from '@energy-stacks/feature-config';
import { createBaseQuery } from '@energy-stacks/shared';
import { createApi } from '@reduxjs/toolkit/query/react';
import { toUserRoleDto } from './normalizers/userRoleNormalizer';
import { ExtendedCsmsUserDto } from './extendedCsmsUserDto';
import { ExtendedCsmsUserModel } from './extendedCsmsUserModel';
import { EditUserFormData } from './userFormData';
import { UpdateUserRequest } from './updateUserRequest';
import { extendedCsmsUserModelNormalizer } from './normalizers/extendedUserModelNormalizer';
import { CsmsUserAccessRequestModel } from './csmsUserAccessRequestModel';
import { CsmsUserAccessRequestDto } from './csmsUserAccessRequestDto';
import { csmsUserAccessRequestNormalizer } from './normalizers/csmsUserAccessRequestNormalizer';

export const usersCsmsApi = createApi({
  reducerPath: 'usersCsmsApi',
  tagTypes: ['Users', 'UserAccessRequests'],
  baseQuery: createBaseQuery(`${environment.ocppServiceUrl}/users`),
  endpoints: (builder) => ({
    getUsers: builder.query<ExtendedCsmsUserModel[], void>({
      query: () => '/',
      providesTags: ['Users'],
      transformResponse: (users: ExtendedCsmsUserDto[]) => {
        return users.map(extendedCsmsUserModelNormalizer);
      },
    }),
    editUser: builder.mutation<ExtendedCsmsUserModel, EditUserFormData>({
      query: (data) => {
        const requestBody: UpdateUserRequest = {
          userRole: toUserRoleDto(data.userRole),
          isActive: data.isActive,
          providerId: data.providerId,
        };
        return {
          url: `/${data.userId}`,
          method: 'PUT',
          body: requestBody,
        };
      },
      invalidatesTags: ['Users'],
      transformResponse: extendedCsmsUserModelNormalizer,
    }),
    getUserRequests: builder.query<CsmsUserAccessRequestModel[], void>({
      query: () => '/requests',
      providesTags: ['UserAccessRequests'],
      transformResponse: (userRequests: CsmsUserAccessRequestDto[]) =>
        userRequests.map(csmsUserAccessRequestNormalizer),
    }),
    editUserRequest: builder.mutation<ExtendedCsmsUserModel, EditUserFormData>({
      query: (data) => {
        const requestBody: UpdateUserRequest = {
          userRole: toUserRoleDto(data.userRole),
          isActive: data.isActive,
          providerId: data.providerId,
        };
        return {
          url: `/${data.userId}`,
          method: 'PUT',
          body: requestBody,
        };
      },
      transformResponse: extendedCsmsUserModelNormalizer,
    }),
    deleteUserRequest: builder.mutation<void, { userId: string }>({
      query: (data) => {
        return {
          url: `/requests/${data.userId}`,
          method: 'DELETE',
        };
      },
    }),

    requestAccess: builder.mutation<void, void>({
      query: () => ({
        url: '/me/access',
        method: 'POST',
      }),
    }),
    refetchUsersAndRequests: builder.mutation<null, void>({
      queryFn: () => ({ data: null }),
      invalidatesTags: ['UserAccessRequests', 'Users'],
    }),
  }),
});

export const {
  useGetUsersQuery,
  useEditUserMutation,
  useEditUserRequestMutation,
  useDeleteUserRequestMutation,
  useRequestAccessMutation,
  useGetUserRequestsQuery,
  useRefetchUsersAndRequestsMutation,
} = usersCsmsApi;
