import { Box, IconButton, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { MarkerF } from '@react-google-maps/api';
import { ReactComponent as CsPOI } from '../../../assets/csPOI.svg';
import { IconPencil } from '@tabler/icons-react';
import { FC, useCallback, useEffect, useState } from 'react';
import Geocode from 'react-geocode';
import { ESMap } from '@energy-stacks/core/ui';
import { useNavigate } from 'react-router-dom';
import csMarkerPOI from '../../../assets/csMarkerPOI.svg';
import { coordsToNumbers } from './coordsToNumbers';

export type Coordinates = {
  latitude: string;
  longitude: string;
};

type ChargingStationLocationMapViewProps = {
  coords: Coordinates | null;
  editRoute?: string;
  isNotEditable?: boolean; // Waiting for CSMS BE
  hasBorderRadius?: boolean;
};

export const ChargingStationLocationMapView: FC<
  ChargingStationLocationMapViewProps
> = ({ coords, editRoute, isNotEditable, hasBorderRadius }) => {
  const [t] = useTranslation('chargingStations');
  const { palette, spacing, shadows } = useTheme();
  const [address, setAddress] = useState('');
  const mapCenter = coordsToNumbers(coords);
  const navigate = useNavigate();

  const getAddressFromCoords = useCallback(async () => {
    try {
      const latitudeStr = String(coords?.latitude || 0);
      const longitudeStr = String(coords?.longitude || 0);
      const addressResult = await Geocode.fromLatLng(latitudeStr, longitudeStr);
      return addressResult.results[0].formatted_address;
    } catch (error) {
      return t('locationDialogSearchAddressError');
    }
  }, [coords, t]);

  useEffect(() => {
    let isMounted = true;
    getAddressFromCoords()
      .then((res) => {
        if (isMounted) {
          setAddress(res);
        }
      })
      .catch(() => setAddress(t('locationDialogSearchAddressError')));

    return () => {
      isMounted = false;
    };
  }, [coords, getAddressFromCoords, t]);

  return (
    <ESMap
      hasBorderRadius={hasBorderRadius}
      center={mapCenter}
      zoom={coords ? 16 : 2}
      options={{
        mapTypeControl: true,
        mapTypeControlOptions: {
          style: google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
          position: google.maps.ControlPosition.TOP_LEFT,
          mapTypeIds: [
            google.maps.MapTypeId.ROADMAP,
            google.maps.MapTypeId.SATELLITE,
          ],
        },
      }}
    >
      <MarkerF
        position={mapCenter}
        draggable={false}
        clickable={false}
        icon={{
          url: csMarkerPOI,
          scaledSize: new google.maps.Size(48, 48),
        }}
      />
      <Box
        borderRadius={hasBorderRadius ? 3 : 0}
        // absolute positioning - fixed values
        sx={{
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: 'background.paper',
          boxShadow: shadows[1],
          p: 3,
          position: 'absolute',
          left: spacing(4),
          right: spacing(4),
          bottom: spacing(7),
          maxWidth: '515px',
          minWidth: 'fit-content',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'nowrap',
            justifyContent: 'space-between',
            mb: 3.5,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <CsPOI />
            <Typography
              sx={{
                color: palette.grey[900],
                flexGrow: 1,
                ml: 2,
              }}
            >
              {t('locationDialogSearchAddressLabel')}
            </Typography>
          </Box>
          {!isNotEditable && editRoute && (
            <IconButton
              onClick={() => navigate(editRoute)}
              sx={{
                // requested is 28px, but size property small is by default 34px
                height: spacing(7),
                width: spacing(7),
                p: 0,
              }}
            >
              <IconPencil
                style={{ cursor: 'pointer', color: palette.grey[500] }}
              />
            </IconButton>
          )}
        </Box>
        <Typography
          sx={{
            color: palette.grey[900],
            fontWeight: '500',
          }}
        >
          {address}
        </Typography>
      </Box>
    </ESMap>
  );
};
