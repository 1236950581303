import { persistor, store } from '@energy-stacks/fleet-is/store';
import { StrictMode } from 'react';
import { Provider } from 'react-redux';
import { unstable_HistoryRouter as HistoryRouter } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import { createBrowserHistory } from 'history';

const history = createBrowserHistory({ window });

export const AppShell = ({ children }: { children: React.ReactNode }) => {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <StrictMode>
          {/* Reference: https://github.com/remix-run/react-router/issues/9630#issuecomment-1341643731 */}
          {/* @ts-expect-error Types not compatible */}
          <HistoryRouter history={history}>{children}</HistoryRouter>
        </StrictMode>
      </PersistGate>
    </Provider>
  );
};
