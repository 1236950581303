import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const Type2Icon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props}>
      <path d="M12.1459 20.5721C17.581 20.5721 22.0025 16.1505 22.0025 10.7154C22.0025 8.76965 21.4491 6.93436 20.3574 5.26202C19.1479 3.40845 12.8673 3.48156 12.1509 3.48156C11.4341 3.46834 5.31377 3.23538 3.94751 5.24257C2.86243 6.83557 2.28995 8.72842 2.28995 10.7154C2.28995 16.1501 6.71075 20.5721 12.1459 20.5721ZM4.67867 5.74C5.35305 4.74904 8.35704 4.41107 10.7683 4.41107C11.2797 4.41107 11.7538 4.38579 12.1886 4.41107C14.7897 4.43635 18.9083 4.65959 19.6169 5.74505C20.6129 7.27116 21.1181 8.94349 21.1181 10.715C21.1181 15.662 17.0929 19.6873 12.1459 19.6873C7.19884 19.6873 3.17434 15.662 3.17434 10.715C3.17434 8.90577 3.69393 7.18559 4.67867 5.74Z" />
      <path d="M12.1463 19.0825C16.6725 19.0825 20.3423 15.4127 20.3423 10.8865C20.3423 9.17917 19.8394 7.67796 18.9725 6.34903C18.008 4.87037 12.1883 4.98977 12.1883 4.98977C12.1883 4.98977 6.42725 4.72142 5.32661 6.33892C4.44649 7.63284 3.95062 9.20406 3.95062 10.8869C3.95062 15.4131 7.61928 19.0825 12.1463 19.0825ZM9.67315 17.5731C8.43056 17.5731 7.42326 16.5659 7.42326 15.3233C7.42326 14.0807 8.43056 13.0734 9.67315 13.0734C10.9157 13.0734 11.923 14.0807 11.923 15.3233C11.923 16.5659 10.9161 17.5731 9.67315 17.5731ZM14.4323 17.5731C13.1905 17.5731 12.1832 16.5659 12.1832 15.3233C12.1832 14.0807 13.1905 13.0734 14.4323 13.0734C15.6749 13.0734 16.6822 14.0807 16.6822 15.3233C16.6822 16.5659 15.6749 17.5731 14.4323 17.5731ZM19.2619 10.9966C19.2619 12.2392 18.2546 13.2465 17.012 13.2465C15.7694 13.2465 14.7621 12.2392 14.7621 10.9966C14.7621 9.75477 15.7694 8.74748 17.012 8.74748C18.2546 8.74748 19.2619 9.75477 19.2619 10.9966ZM14.4837 6.08613C15.2907 6.08613 15.944 6.73873 15.944 7.54573C15.944 8.35195 15.2903 9.00533 14.4837 9.00533C13.6774 9.00533 13.0241 8.35156 13.0241 7.54573C13.0241 6.73873 13.6774 6.08613 14.4837 6.08613ZM12.2031 8.74748C13.4449 8.74748 14.4522 9.75477 14.4522 10.9966C14.4522 12.2392 13.4449 13.2465 12.2031 13.2465C10.9605 13.2465 9.95317 12.2392 9.95317 10.9966C9.95317 9.75477 10.9605 8.74748 12.2031 8.74748ZM9.67315 6.08613C10.4794 6.08613 11.1328 6.73873 11.1328 7.54573C11.1328 8.35195 10.479 9.00533 9.67315 9.00533C8.86731 9.00533 8.21354 8.35156 8.21354 7.54573C8.21354 6.73873 8.86731 6.08613 9.67315 6.08613ZM5.03026 10.9966C5.03026 9.75477 6.03755 8.74748 7.28014 8.74748C8.52273 8.74748 9.53003 9.75477 9.53003 10.9966C9.53003 12.2392 8.52273 13.2465 7.28014 13.2465C6.03755 13.2465 5.03026 12.2392 5.03026 10.9966Z" />
    </SvgIcon>
  );
};
