import { RoutePoint } from '@energy-stacks/fleet/shared';
import { ESTooltip } from '@energy-stacks/shared';
import { Stack, Typography } from '@mui/material';
import { FC } from 'react';
import { JobLocationIcon } from './JobLocationIcon';
import { JobModel } from '@energy-stacks/fleet/feature-jobs-data';
import { useRemovePlantIdPrefix } from '@energy-stacks/fleet/feature-business-accounts';

type JobLocationCellProps = {
  location: RoutePoint | null;
  jobType: JobModel['jobType'];
  locationType: 'origin' | 'destination';
  supplierName?: JobModel['supplier']['supplierName'];
  showFullAddress?: boolean;
  columnWidth?: number;
};

export const JobLocationCell: FC<JobLocationCellProps> = ({
  location,
  supplierName,
  jobType,
  locationType,
  showFullAddress = true,
}) => {
  const removePlantIdPrefix = useRemovePlantIdPrefix();
  const isPlant =
    (jobType === 'OUTBOUND' && locationType === 'origin') ||
    (jobType === 'INBOUND' && locationType === 'destination');

  if (!location) {
    return <Typography variant="inherit">---</Typography>;
  }

  return (
    <Stack flexDirection="row" alignItems="center">
      <JobLocationIcon
        jobType={jobType}
        locationType={locationType}
        location={location}
      />
      <ESTooltip
        title={
          <Stack direction="column">
            <Typography variant="inherit">{location.street},</Typography>
            <Typography variant="inherit">{location.city}</Typography>
          </Stack>
        }
        contentContainerStyle={{
          // Needed for ellipsis (applied on ESTableBodyCell) to take an effect
          // on child Typography components
          minWidth: 0,
        }}
      >
        <Typography variant="inherit" color="grey.900" fontWeight={500} noWrap>
          {`${removePlantIdPrefix(location.id) || '---'}${
            !isPlant && supplierName ? ` • ${supplierName}` : ''
          }`}
        </Typography>

        {showFullAddress ? (
          <Typography
            variant="inherit"
            color="grey.600"
            fontWeight={300}
            noWrap
          >
            {`${location.street}, ${location.city}`}
          </Typography>
        ) : null}
      </ESTooltip>
    </Stack>
  );
};
