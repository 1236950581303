import { FleetProduct } from '../types/product';
import { FleetProductDto } from '../types/productDto';

export const productNormalizer = (
  product: FleetProductDto | undefined
): FleetProduct | null => {
  if (!product) {
    return null;
  }

  return {
    id: product.uid,
    name: product.name,
    quantity: product.quantity,
    risk: product.risk,
  };
};
