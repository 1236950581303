import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import { Stack } from '@mui/material';
import {
  ESWizardContent,
  ESWizardV2,
  Step,
  useESStepper,
} from '@energy-stacks/core/ui';
import { useCloseDialogPrompt } from '@energy-stacks/shared';
import { Stepper, useNavigateBack } from '@energy-stacks/fleet/shared';
import { FleetIsRoutes } from '@energy-stacks/fleet-is/shared';
import { useCreateChargingScheduleState } from './useCreateChargingScheduleState';
import { ToursStep } from './tours/ToursStep';
import './charging-schedule-wizard.css';
import { ChargingStationsStep } from './charging-stations/ChargingStationsStep';
import { OptimizeChargingStep } from './optimization/OptimizeChargingStep';
import { useCreateChargingScheduleMutation } from '@energy-stacks/fleet-is/feature-charging-schedule-data';
import { useNavigate } from 'react-router-dom';

type CreateChargingScheduleStepName =
  | 'selectTours'
  | 'selectChargingStations'
  | 'optimizeCharging';

export const CreateChargingScheduleWizard = () => {
  const [isWizardOpen, setIsWizardOpen] = useState(false);

  const [t] = useTranslation('chargingSchedule');

  const {
    state,
    defaultDateRange,
    setDateRange,
    setSelectedTourIds,
    setSelectedChargingStationIdentityKeys,
    setIsMoSocIndividual,
    setFleetMoSoc,
  } = useCreateChargingScheduleState();

  useEffect(() => {
    setIsWizardOpen(true);
  }, []);

  const [createSchedule, { isSuccess: isCreateScheduleSuccess }] =
    useCreateChargingScheduleMutation();

  const navigate = useNavigate();

  const handleCreateTourSchedule = useCallback(
    (finish: () => void) => {
      if (!state.selectedTourIds || state.selectedTourIds.length === 0) {
        return;
      }
      createSchedule({
        tours: state.selectedTourIds,
      })
        .unwrap()
        .then(() => {
          // TODO: Check why this is not closing wizard
          finish();
          navigate(FleetIsRoutes.ChargingSchedule);
        })
        .catch(() => {});
    },
    [state.selectedTourIds, createSchedule, navigate]
  );

  const handleCloseWizard = useNavigateBack(FleetIsRoutes.Tours);
  const showCloseConfirmation = useCloseDialogPrompt({
    shouldPrompt: true, // Show prompt whenever the user tries to close the wizard
    promptMessage: t('exitChargingScheduleWizardConfirmCloseDescription'),
    onClose: () => setIsWizardOpen(false),
  });

  const createChargingScheduleStepsMap: Record<
    CreateChargingScheduleStepName,
    Step
  > = useMemo(
    () => ({
      selectTours: {
        component: (
          <ToursStep
            selectedTourIds={state.selectedTourIds}
            onToursSelected={setSelectedTourIds}
            onDateRangeChange={setDateRange}
            defaultDateRange={defaultDateRange}
            dateRange={state.dateRange}
          />
        ),
      },
      selectChargingStations: {
        component: (
          <ChargingStationsStep
            onChargingStationsSelected={setSelectedChargingStationIdentityKeys}
            selectedChargingStationIdentityKeys={
              state.selectedChargingStationIdentityKeys
            }
          />
        ),
      },
      optimizeCharging: {
        component: (
          <OptimizeChargingStep
            isMoSocIndividual={state.isMoSocIndividual}
            onIsMoSocIndividualChange={setIsMoSocIndividual}
            fleetMoSoc={state.fleetMoSoc}
            onFleetMoSocChange={setFleetMoSoc}
            isPriceOptimized={state.isPriceOptimized}
            isSolarOptimized={state.isSolarOptimized}
          />
        ),
        action: (finish) => {
          handleCreateTourSchedule(finish);
        },
      },
    }),
    [
      state.selectedTourIds,
      state.dateRange,
      state.selectedChargingStationIdentityKeys,
      state.isMoSocIndividual,
      state.fleetMoSoc,
      state.isPriceOptimized,
      state.isSolarOptimized,
      setSelectedTourIds,
      setDateRange,
      defaultDateRange,
      setSelectedChargingStationIdentityKeys,
      setIsMoSocIndividual,
      setFleetMoSoc,
      handleCreateTourSchedule,
    ]
  );

  const {
    canGoToNextStep,
    canGoToPrevStep,
    activeStep,
    activeStepIndex,
    activeStepName,
    handleNextStep,
    handlePreviousStep,
    isLastStep,
  } = useESStepper<CreateChargingScheduleStepName>(
    createChargingScheduleStepsMap
  );

  const goToPreviousStep = useCloseDialogPrompt({
    shouldPrompt: activeStepName === 'selectTours',
    promptMessage: t('goBackConfirmationMessage'),
    onClose: () => {
      handlePreviousStep();
    },
  });

  return (
    <>
      <ESWizardV2
        isOpen={isWizardOpen}
        onClose={showCloseConfirmation}
        onExit={() => {
          if (isCreateScheduleSuccess) {
            navigate(FleetIsRoutes.ChargingSchedule);
            return;
          }
          handleCloseWizard();
        }}
        HeaderComponent={
          <Stepper
            canGoToNextStep={canGoToNextStep}
            canGoToPrevStep={canGoToPrevStep}
            currentStep={activeStepIndex}
            disableNextButton={
              (activeStepName === 'selectTours' &&
                (!state.selectedTourIds ||
                  state.selectedTourIds.length === 0)) ||
              (activeStepName === 'selectChargingStations' &&
                (!state.selectedChargingStationIdentityKeys ||
                  state.selectedChargingStationIdentityKeys.length === 0))
            }
            handleNextStep={handleNextStep}
            handlePreviousStep={goToPreviousStep}
            nextButtonText={t(
              isLastStep ? 'confirmStepButtonLabel' : 'nextStepButtonLabel'
            )}
            steps={Object.keys(createChargingScheduleStepsMap).map((step) => {
              return {
                stepName: t(`${step}StepLabel`),
              };
            })}
          />
        }
      >
        <SwitchTransition>
          <CSSTransition key={activeStepIndex} classNames="fade" timeout={180}>
            {() => (
              <Stack height="100%" gap={6}>
                <ESWizardContent>{activeStep}</ESWizardContent>
              </Stack>
            )}
          </CSSTransition>
        </SwitchTransition>
      </ESWizardV2>
    </>
  );
};
