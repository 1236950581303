import { ESBanner, useESSnackbar } from '@energy-stacks/core/ui';
import { useHardResetChargingStationMutation } from '@energy-stacks/broker/feature-charging-station-management-data';
import {
  HardResetDialog,
  useChargingStationIdentityKey,
} from '@energy-stacks/shared';
import {
  clearHardResetRequired,
  startChargingStationCoreManagementAction,
  endChargingStationCoreManagementAction,
  useAppDispatch,
  useAppSelector,
} from '@energy-stacks/broker/store';
import { Typography, Link } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

export const HardResetRequiredBanner = () => {
  const [t] = useTranslation('chargingStations');
  const dispatch = useAppDispatch();
  const identityKey = useChargingStationIdentityKey();
  const hardResetRequired = useAppSelector(
    (state) => state.configurationState.stationIdentityKeysRequiringHardReset
  ).includes(identityKey);
  const { showSnackbar } = useESSnackbar();
  const [hardResetChargingStation] = useHardResetChargingStationMutation();
  const [dialogOpen, setDialogOpen] = useState(false);

  const handleHardReset = () => {
    setDialogOpen(false);
    dispatch(startChargingStationCoreManagementAction());
    hardResetChargingStation(identityKey)
      .unwrap()
      .then((response) => {
        dispatch(clearHardResetRequired(identityKey));
        if (response.status === 'Accepted') {
          showSnackbar('success', 'hardResetAccepted', 'chargingStations');
          return;
        }
        if (response.status === 'Rejected') {
          showSnackbar('error', 'hardResetRejected', 'chargingStations');
          return;
        }
      })
      .catch((error) => {
        if (error.data.errorCode === 'RPC_TIMEOUT') {
          showSnackbar('error', 'rpcTimeoutError', 'chargingStations');
          return;
        }
        if (error.data.errorCode === 'RPC_ERROR') {
          showSnackbar('error', 'rpcError', 'chargingStations');
          return;
        }
        showSnackbar('error');
      })
      .finally(() => {
        dispatch(endChargingStationCoreManagementAction());
      });
  };

  useEffect(() => {
    // We dispatch the resize event when banner changes so the Table can trigger useFitRow and resize
    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    }, 0);
  }, []);

  if (!hardResetRequired) {
    return null;
  }

  return (
    <>
      <ESBanner
        sx={{
          display: 'flex',
          alignItems: 'center',
          px: 4,
          py: 3.5,
        }}
      >
        <>
          <Typography
            variant="body2"
            color={(theme) => theme.palette.common.white}
          >
            {t('keysRequireHardReset')}
          </Typography>

          <Link
            component="button"
            variant="body2"
            sx={{ pl: 1, color: (theme) => theme.palette.primary.light }}
            onClick={() => {
              setDialogOpen(true);
            }}
          >
            {t('resetNow')}
          </Link>
        </>
      </ESBanner>

      <HardResetDialog
        isOpen={dialogOpen}
        onClose={() => setDialogOpen(false)}
        onCallToAction={handleHardReset}
      />
    </>
  );
};
