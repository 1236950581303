import { ProcedureName } from './ocppMessageLogEntry';
import { OcppAction } from './ocppMessageLogsModel';

export const mapOcppActionToOcppProcedure = (
  ocppActions: OcppAction[]
): ProcedureName[] => {
  const _procedureNames: ProcedureName[] = [];

  ocppActions.map((action) => {
    return _procedureNames.push(ocppProcedureMap[action]);
  });
  return _procedureNames;
};

const ocppProcedureMap: Record<OcppAction, ProcedureName> = {
  authorize: 'AUTHORIZE',
  bootNotification: 'BOOT_NOTIFICATION',
  cancelReservation: 'CANCEL_RESERVATION',
  changeAvailability: 'CHANGE_AVAILABILITY',
  changeConfiguration: 'CHANGE_CONFIGURATION',
  clearCache: 'CLEAR_CACHE',
  clearChargingProfile: 'CLEAR_CHARGING_PROFILE',
  dataTransfer: 'DATA_TRANSFER',
  diagnosticsStatusNotification: 'DIAGNOSTICS_STATUS_NOTIFICATION',
  firmwareStatusNotification: 'FIRMWARE_STATUS_NOTIFICATION',
  getConfiguration: 'GET_CONFIGURATION',
  getDiagnostics: 'GET_DIAGNOSTICS',
  getLocalListVersion: 'GET_LOCAL_LIST_VERSION',
  heartbeat: 'HEARTBEAT',
  meterValues: 'METER_VALUES',
  remoteStartTransaction: 'REMOTE_START_TRANSACTION',
  remoteStopTransaction: 'REMOTE_STOP_TRANSACTION',
  reserveNow: 'RESERVE_NOW',
  reset: 'RESET',
  sendLocalList: 'SEND_LOCAL_LIST',
  setChargingProfile: 'SET_CHARGING_PROFILE',
  startTransaction: 'START_TRANSACTION',
  stopTransaction: 'STOP_TRANSACTION',
  statusNotification: 'STATUS_NOTIFICATION',
  unlockConnector: 'UNLOCK_CONNECTOR',
  updateFirmware: 'UPDATE_FIRMWARE',
  triggerMessage: 'TRIGGER_MESSAGE',
  getCompositeSchedule: 'GET_COMPOSITE_SCHEDULE',
  get15118EVCertificate: 'GET_15118_EV_CERTIFICATE',
  getCertificateStatus: 'GET_CERTIFICATE_STATUS',
  getBaseReport: 'GET_BASE_REPORT',
  getLog: 'GET_LOG',
  logStatusNotification: 'LOG_STATUS_NOTIFICATION',
  notifyChargingLimit: 'NOTIFY_CHARGING_LIMIT',
  notifyCustomerInformation: 'NOTIFY_CUSTOMER_INFORMATION',
  notifyDisplayMessages: 'NOTIFY_DISPLAY_MESSAGES',
  notifyEVChargingNeeds: 'NOTIFY_EV_CHARGING_NEEDS',
  notifyEVChargingSchedule: 'NOTIFY_EV_CHARGING_SCHEDULE',
  notifyEvent: 'NOTIFY_EVENT',
  notifyMonitoringReport: 'NOTIFY_MONITORING_REPORT',
  notifyReport: 'NOTIFY_REPORT',
  reportChargingProfiles: 'REPORT_CHARGING_PROFILES',
  requestStartTransaction: 'REQUEST_START_TRANSACTION',
  requestStopTransaction: 'REQUEST_STOP_TRANSACTION',
  reservationStatusUpdate: 'RESERVATION_STATUS_UPDATE',
  securityEventNotification: 'SECURITY_EVENT_NOTIFICATION',
  setVariables: 'SET_VARIABLES',
  signCertificate: 'SIGN_CERTIFICATE',
  transactionEvent: 'TRANSACTION_EVENT',
};
