import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';
import { ESButton } from '@energy-stacks/core/ui';
import LogoutRoundedIcon from '@mui/icons-material/LogoutRounded';
import { ReactComponent as AccessDeniedImage } from '../icons/accessDenied.svg';
import { useOidc } from '@axa-fr/react-oidc';
import { useAppSelector } from '@energy-stacks/store';

interface AccessDeniedPageProps {
  message: string;
  shouldRequestAccess?: () => void;
  requestAccessIsLoading?: boolean;
}

export const AccessDeniedMessage: React.FC<AccessDeniedPageProps> = ({
  message,
  shouldRequestAccess,
  requestAccessIsLoading,
}) => {
  const [t] = useTranslation('userAccess');
  const { logout } = useOidc();

  const hasRequestedAccess = useAppSelector(
    (store) => store.user.requestedAccess
  );

  return (
    <Box textAlign="center">
      <AccessDeniedImage />
      <Typography variant="h4" mb={3} mt={24}>
        {t('noAccessToPage')}
      </Typography>
      <Typography variant="subtitle1" color="text.secondary">
        {message}
      </Typography>
      {shouldRequestAccess ? (
        <>
          <Typography variant="subtitle1" color="text.secondary" mb={11}>
            {t('contactSystemAdminForInfo')}
          </Typography>
          <Box mb={3.5}>
            <ESButton
              onClick={shouldRequestAccess}
              loading={requestAccessIsLoading}
              disabled={hasRequestedAccess}
            >
              {t('contactAdmin')}
            </ESButton>
          </Box>
        </>
      ) : (
        <Box mb={5} />
      )}
      <Box>
        <ESButton
          onClick={() => {
            logout('/');
          }}
          startIcon={<LogoutRoundedIcon />}
          variant="text"
        >
          {t('switchAccount')}
        </ESButton>
      </Box>
    </Box>
  );
};
