import { ReactElement, useCallback, useRef } from 'react';
import { useStep } from 'usehooks-ts';

export type Step = {
  component: ReactElement;
  action?: (finish: () => void) => void;
};

type StepsMap<T extends string> = Record<T, Step>;

export function useESStepper<T extends string>(stepsMap: StepsMap<T>) {
  const stepNamesRef = useRef(Object.keys(stepsMap));
  const [
    activeStepIndex,
    { goToNextStep, goToPrevStep, canGoToNextStep, canGoToPrevStep },
  ] = useStep(stepNamesRef.current.length);
  const activeStepName = stepNamesRef.current[activeStepIndex - 1] as T;
  const isLastStep = activeStepIndex === stepNamesRef.current.length;

  const handleNextStep = useCallback(() => {
    const stepAction = stepsMap[activeStepName].action;
    if (!stepAction) {
      goToNextStep();
      return;
    }

    if (stepAction.length === 0) {
      stepAction(() => {});
      goToNextStep();
      return;
    }

    stepAction(() => {
      goToNextStep();
    });
  }, [activeStepName, goToNextStep, stepsMap]);

  const handlePreviousStep = useCallback(() => {
    goToPrevStep();
  }, [goToPrevStep]);

  return {
    activeStep: stepsMap[activeStepName].component,
    activeStepIndex,
    activeStepName,
    isLastStep,
    canGoToNextStep,
    canGoToPrevStep,
    handlePreviousStep,
    handleNextStep,
  };
}
