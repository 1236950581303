import { AddAndEditLocationDto } from './addAndEditLocationDto';
import { BrokerLocationFormData } from './brokerLocationFormData';

export const mapAddAndEditFormDataToPayload = (
  addEndEditFormData: BrokerLocationFormData
): AddAndEditLocationDto => {
  return {
    name: addEndEditFormData.locationName,
    address: addEndEditFormData.streetNameAndNumber,
    city: addEndEditFormData.city,
    postalCode: addEndEditFormData.zipCode,
    state: addEndEditFormData.state,
    country: addEndEditFormData.country.value,
    coordinates: {
      latitude: addEndEditFormData.lat,
      longitude: addEndEditFormData.lng,
    },
  };
};
