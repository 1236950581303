import { Stack, Typography } from '@mui/material';
import { TourDetailsEditModeActions } from './TourDetailsEditModeActions';
import { useTranslation } from 'react-i18next';

type TourDetailsEditModeProps = {
  onRecalculateTour: () => void;
  shouldDisableSave: boolean;
};

export const TourDetailsEditModeHeader: React.FC<TourDetailsEditModeProps> = ({
  onRecalculateTour,
  shouldDisableSave,
}) => {
  const { t } = useTranslation('tours');

  return (
    <Stack direction="row" alignItems="center" justifyContent="space-between">
      <Typography color="grey.900" variant="h3">
        {t('tourDetailsEditModeTitle')}
      </Typography>
      <TourDetailsEditModeActions
        onSave={onRecalculateTour}
        shouldDisableSave={shouldDisableSave}
      />
    </Stack>
  );
};
