import { LocationDto } from '../locationDto';
import { LocationModel } from '../locationModel';
import { addressNormalizer } from './addressNormalizer';
import { countryNormalizer } from './countryNormalizer';
import { locationParkingTypeNormalizer } from './parkingTypeNormalizer';

export const locationNormalizer = (location: LocationDto): LocationModel => {
  return {
    locationId: location.location_id ?? '',
    locationName: location.name ?? 'N/A',
    locationFullAddress: addressNormalizer(
      location.address,
      location.postal_code,
      location.city,
      countryNormalizer(location.country)
    ),
    locationParkingType: locationParkingTypeNormalizer(
      location.parking_type ?? 'OTHER'
    ),
    chargingStations: location.chargingStations,
  };
};
