import type { Coordinates } from './ChargingStationLocationMapView';

export const coordsToNumbers = (
  coords: Coordinates | null
): google.maps.LatLng => {
  if (coords) {
    const latitude = parseFloat(coords.latitude);
    const longitude = parseFloat(coords.longitude);
    return {
      lat: !isNaN(latitude) ? latitude : 0,
      lng: !isNaN(longitude) ? longitude : 0,
    } as unknown as google.maps.LatLng;
  } else {
    return {
      lat: 0,
      lng: 0,
    } as unknown as google.maps.LatLng;
  }
};
