export const FleetRoutes = {
  Dashboard: '/dashboard',
  Tours: '/tours',
  TourDetails: '/tours/:tourId',
  DeleteTour: '/tours/:tourId/delete',
  Jobs: '/jobs',
  Vehicles: '/vehicles',
  AddVehicle: '/vehicles/add',
  DeleteVehicle: '/vehicles/:vehicleId/delete',
  EditVehicleGeneralInfo: '/vehicles/:vehicleId/edit-general-info',
  EditVehicleOperatingHours: '/vehicles/:vehicleId/edit-operating-hours',
  Drivers: '/drivers',
  CreateTour: '/create-tour',
  BusinessAccounts: '/business-accounts',
  BusinessAccountDetails: '/business-accounts/:businessAccountId',
};
