import {
  ESDialogActionButton,
  ESDialogActions,
  ESDialogContent,
  ESDialogTitle,
  ESFormDialog,
  useESSnackbar,
} from '@energy-stacks/core/ui';
import { useCloseDialogPrompt } from '@energy-stacks/shared';
import { Grid, IconButton, Stack, Typography } from '@mui/material';
import { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  VehicleModel,
  useGetCoreVehiclesQuery,
  useGetVehicleDetailsQuery,
} from '@energy-stacks/fleet/feature-vehicles-data';
import { IconChevronLeft, IconChevronRight, IconX } from '@tabler/icons-react';
import { useParams } from 'react-router-dom';
import {
  FleetRoutes,
  weekdays,
  useNavigateBack,
} from '@energy-stacks/fleet/shared';
import { getEditVehicleDefaultValues } from './getEditVehicleDefaultValues';
import * as yup from 'yup';
import { useAppSelector } from '@energy-stacks/fleet/store';
import {
  EditVehicleGeneralInfoFields,
  EditVehicleRegularOperatingHours,
  vehicleApiErrors,
  workingHoursValidationSchema,
  EditAssignedVehicleDialog,
} from '@energy-stacks/fleet/feature-vehicles';
import {
  EditVehicleFormData,
  useEditVehicleMutation,
} from '@energy-stacks/fleet-is/feature-vehicles-data';

type EditVehicleStep = {
  stepComponent: ReactNode;
  actionButtonType: 'reset' | 'button' | 'submit' | undefined;
  actionButtonClickHandler?: () => void;
  isActionButtonDisabled: boolean;
};

const stepFieldNames = {
  generalInfo: [
    'plantId',
    'name',
    'brand',
    'model',
    'variant',
    'vehicleId',
    'licencePlate',
  ],
  workingHours: weekdays,
};

export const EditVehicle = () => {
  const [t] = useTranslation('vehicles');
  const { vehicleId } = useParams<{ vehicleId: VehicleModel['vehicleId'] }>();
  const {
    data: vehicle,
    isLoading,
    isError,
  } = useGetVehicleDetailsQuery(vehicleId ?? '');
  const { data: coreVehicles } = useGetCoreVehiclesQuery();
  const [editVehicle, { isLoading: isEditing }] = useEditVehicleMutation();
  const { showSnackbar } = useESSnackbar();
  const plantIds = useAppSelector((state) => state.plant.plantIds);

  const [activeStep, setActiveStep] = useState(0);

  const defaultEditVehicleValues: EditVehicleFormData = useMemo(
    () => getEditVehicleDefaultValues(vehicle),
    [vehicle]
  );

  const editVehicleValidationSchema = useMemo(() => {
    return yup.object().shape({
      plantId: yup.object().shape({
        label: yup
          .string()
          .oneOf(plantIds ?? [])
          .required('vehicleFormErrors.plantIdRequired'),
        values: yup.string(),
      }),
      name: yup
        .string()
        .trim()
        .max(20, 'vehicleFormErrors.nameTooLong')
        .required('vehicleFormErrors.nameRequired'),
      brand: yup.object().shape({
        label: yup.string().required('vehicleFormErrors.brandRequired'),
        value: yup.string(),
      }),
      model: yup.object().shape({
        label: yup.string().required('vehicleFormErrors.modelRequired'),
        value: yup.string(),
      }),
      variant: yup.object().shape({
        label: yup.string().required('vehicleFormErrors.variantRequired'),
        value: yup.string(),
      }),
      vehicleId: yup
        .string()
        .trim()
        .max(30, 'vehicleFormErrors.vehicleIdTooLong')
        .required('vehicleFormErrors.vehicleIdRequired'),
      licencePlate: yup
        .string()
        .trim()
        .max(15, 'vehicleFormErrors.licencePlateTooLong'),
      workingHours: yup.object().shape({
        MONDAY: workingHoursValidationSchema,
        TUESDAY: workingHoursValidationSchema,
        WEDNESDAY: workingHoursValidationSchema,
        THURSDAY: workingHoursValidationSchema,
        FRIDAY: workingHoursValidationSchema,
        SATURDAY: workingHoursValidationSchema,
      }),
    });
  }, [plantIds]);

  const methods = useForm<EditVehicleFormData>({
    defaultValues: defaultEditVehicleValues,
    mode: 'onTouched',
    resolver: yupResolver(editVehicleValidationSchema),
  });

  const {
    formState: { isDirty, isValid, errors },
    control,
    handleSubmit,
    reset: resetForm,
  } = methods;

  const workingHours = useWatch({ name: 'workingHours.regular', control });
  const areAllDaysOff = useMemo(
    () => Object.values(workingHours).every((day) => !day.isWorkingDay),
    [workingHours]
  );

  const steps: EditVehicleStep[] = [
    {
      stepComponent: <EditVehicleGeneralInfoFields />,
      actionButtonType: 'button',
      actionButtonClickHandler: () => {
        setActiveStep((prev) => prev + 1);
      },
      isActionButtonDisabled:
        stepFieldNames.generalInfo.some((field) =>
          Object.keys(errors).includes(field)
        ) || isLoading,
    },
    {
      stepComponent: <EditVehicleRegularOperatingHours />,
      actionButtonType: 'submit',
      isActionButtonDisabled:
        stepFieldNames.workingHours.some(
          (field) =>
            errors.workingHours &&
            Object.keys(errors.workingHours).includes(field)
        ) ||
        !isDirty ||
        isEditing ||
        !isValid,
    },
  ];

  useEffect(() => {
    if (defaultEditVehicleValues) {
      resetForm(defaultEditVehicleValues);
    }
  }, [defaultEditVehicleValues, resetForm]);

  const handleCloseDialog = useNavigateBack(FleetRoutes.Vehicles);
  const showExitConfirmation = useCloseDialogPrompt({
    shouldPrompt: isDirty,
    onClose: () => {
      handleCloseDialog();
      resetForm();
    },
  });

  const onSubmit = useCallback(
    (data: EditVehicleFormData) => {
      const coreVehicleId = coreVehicles?.find(
        (cv) =>
          cv.brandName === data.brand.value &&
          cv.model === data.model.value &&
          cv.variant === data.variant.value
      )?.vehicleCoreUuid;
      if (!coreVehicleId) {
        return;
      }
      editVehicle({
        formData: data,
        coreVehicleId,
        vehicleStatus: vehicle?.status ?? 'AVAILABLE',
        vehicleIdentificationNumber: vehicle?.vehicleId ?? '',
      })
        .unwrap()
        .then(() => {
          showSnackbar(
            'success',
            'responseMessages.successfulUpdate',
            'vehicles'
          );

          handleCloseDialog();
        })
        .catch((error) => {
          const errorCode = vehicleApiErrors[error.data?.errorCode];
          showSnackbar(
            'error',
            errorCode ? `responseMessages.${errorCode}` : undefined,
            'vehicles'
          );
        });
    },
    [
      coreVehicles,
      editVehicle,
      handleCloseDialog,
      showSnackbar,
      vehicle?.status,
      vehicle?.vehicleId,
    ]
  );

  useEffect(() => {
    if (isError) {
      showSnackbar('error', undefined, 'vehicles');
      handleCloseDialog();
    }
  }, [isError, showSnackbar, handleCloseDialog]);

  if (!vehicle) return null;

  if (vehicle.status === 'PLANNED') {
    return <EditAssignedVehicleDialog onClose={showExitConfirmation} />;
  }

  return (
    <ESFormDialog
      open
      fullWidth
      onClose={(_event, reason) => {
        if (reason === 'backdropClick') {
          showExitConfirmation();
        }
      }}
      onSubmit={handleSubmit(onSubmit)}
    >
      <Stack
        flexDirection="row"
        alignItems="start"
        justifyContent="space-between"
      >
        <ESDialogTitle>{t('editVehicleDialogTitle')}</ESDialogTitle>
        <IconButton onClick={showExitConfirmation} sx={{ m: 2 }}>
          <IconX stroke={1} />
        </IconButton>
      </Stack>
      <FormProvider {...methods}>
        <ESDialogContent
          sx={{
            borderTop: '1px solid',
            borderColor: 'grey.100',
            height: 483,
          }}
        >
          <Grid container spacing={6} sx={{ paddingY: 4 }}>
            <Grid item xs={6}>
              <Typography variant="h4" fontWeight={500} color="text.dark">
                {t(`editVehicleStepTitles.${activeStep}`)}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Stack flexDirection="row" justifyContent="end">
                <Typography
                  component="span"
                  variant="inherit"
                  fontWeight={500}
                  color="secondary.main"
                  mr="5px"
                >
                  {`${t('editVehicleStepCounterLabel')}`}
                </Typography>
                <Typography
                  component="span"
                  variant="inherit"
                  fontWeight={500}
                  color="secondary.main"
                  width="20px"
                >
                  {`${activeStep + 1}/${steps.length}`}
                </Typography>
              </Stack>
            </Grid>
            {steps[activeStep].stepComponent}
          </Grid>
        </ESDialogContent>
        <ESDialogActions>
          {activeStep !== 0 && (
            <ESDialogActionButton
              variant="outlined"
              onClick={() => {
                setActiveStep((prev) => prev - 1);
              }}
              sx={{
                mr: 'auto',
                '&.MuiButton-outlined': {
                  borderColor: 'primary.main',
                  '&:hover': {
                    backgroundColor: 'primary.light',
                    borderColor: 'primary.main',
                  },
                },
              }}
            >
              <IconChevronLeft style={{ marginRight: 12, height: 20 }} />
              {t('editVehicleStepBackButtonLabel')}
            </ESDialogActionButton>
          )}
          <ESDialogActionButton
            variant="contained"
            type={steps[activeStep].actionButtonType}
            disabled={steps[activeStep].isActionButtonDisabled || areAllDaysOff}
            loading={isEditing}
            onClick={(e) => {
              if (activeStep + 1 !== steps.length) e.preventDefault();
              steps[activeStep].actionButtonClickHandler?.();
            }}
          >
            {t(`editVehicleStepActionButtonLabels.${activeStep}`)}
            {activeStep + 1 !== steps.length && (
              <IconChevronRight style={{ marginLeft: 12, height: 20 }} />
            )}
          </ESDialogActionButton>
        </ESDialogActions>
      </FormProvider>
    </ESFormDialog>
  );
};
