import { ConnectorStandardDto } from '../dtos/connectorStandardDto';
import { ConnectorStandard } from '../models/connectorStandardModel';

export const connectorStandardDtoToTypeMap: Record<
  ConnectorStandardDto,
  ConnectorStandard
> = {
  CHADEMO: ConnectorStandard.CHADEMO,
  DOMESTIC_F: ConnectorStandard.DOMESTIC_F,
  IEC_62196_T1: ConnectorStandard.IEC_62196_T1,
  IEC_62196_T1_COMBO: ConnectorStandard.IEC_62196_T1_COMBO,
  IEC_62196_T2: ConnectorStandard.IEC_62196_T2,
  IEC_62196_T2_COMBO: ConnectorStandard.IEC_62196_T2_COMBO,
  IEC_62196_T3C: ConnectorStandard.IEC_62196_T3C,
  UNKNOWN: ConnectorStandard.UNKNOWN,
};
