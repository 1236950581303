import {
  InputLabelProps,
  InputProps,
  TextField,
  InputBaseComponentProps,
} from '@mui/material';
import React, { ChangeEvent, FocusEvent } from 'react';

export interface ESTextFieldProps {
  label?: React.ReactNode;
  value?: string;
  error?: boolean;
  type?: string;
  onChange?: (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | undefined
  ) => void;
  onBlur?: (event: FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  autoFocus?: boolean;
  disabled?: boolean;
  InputProps?: InputProps;
  inputProps?: InputBaseComponentProps;
  InputLabelProps?: InputLabelProps;
  helperText?: React.ReactNode;
  id?: string;
  required?: boolean;
  placeholder?: string;
  onFocus?: (event: FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  testId?: string;
}

export const ESTextField: React.FC<ESTextFieldProps> = ({
  type = 'text',
  autoFocus = false,
  label,
  onChange,
  onBlur,
  disabled = false,
  error = false,
  helperText,
  InputProps,
  inputProps,
  InputLabelProps,
  value,
  id,
  required,
  placeholder,
  onFocus,
  testId,
  ...rest
}) => {
  return (
    <TextField
      variant="outlined"
      size="medium"
      autoComplete="off"
      fullWidth
      id={id}
      type={type}
      value={value}
      disabled={disabled}
      error={error}
      label={label}
      autoFocus={autoFocus}
      onChange={onChange}
      onBlur={onBlur}
      helperText={helperText}
      InputProps={InputProps}
      inputProps={{
        'data-testid': `${testId}Input`,
        ...inputProps,
      }}
      InputLabelProps={InputLabelProps}
      required={required}
      placeholder={placeholder}
      onFocus={onFocus}
      {...rest}
    />
  );
};
