import { CircularProgress } from '@mui/material';

type ESCircularProgressSizes = 'medium' | 'large';

interface ESCircularProgressProps {
  size?: ESCircularProgressSizes;
  color?: string;
}

const circularProgressSizeMap: Record<ESCircularProgressSizes, number> = {
  medium: 40,
  large: 64,
};

export const ESCircularProgress: React.FC<ESCircularProgressProps> = ({
  size = 'medium',
  color,
}) => {
  return (
    <CircularProgress
      sx={{
        color,
      }}
      size={circularProgressSizeMap[size]}
    />
  );
};
