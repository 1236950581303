import { Box } from '@mui/material';

interface ESWizardContentProps {
  children: React.ReactNode;
}

export const ESWizardContent: React.FC<ESWizardContentProps> = ({
  children,
}) => {
  return (
    <Box
      sx={{
        backgroundColor: 'grey.100',
        borderRadius: 4,
        height: '100%',
        padding: 6,
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        overflowY: 'auto',
      }}
    >
      {children}
    </Box>
  );
};
