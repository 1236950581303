import { ESLoader } from '@energy-stacks/core/ui';
import { useAppSelector } from '@energy-stacks/broker/store';

export const ChargingStationCoreManagementActionLoading = () => {
  const chargingStationCoreManagementAction = useAppSelector(
    (state) => state.brokerManagementState.chargingStationCoreManagementAction
  );

  return <ESLoader isLoading={chargingStationCoreManagementAction} />;
};
