import { DialogContent, DialogTitle } from '@mui/material';
import { ESDialogCloseButton } from './dialog/ESDialogCloseButton';
import { ESFullScreenDialog } from './dialog/ESFullScreenDialog';

interface ESWizardV2Props {
  isOpen: boolean;
  onClose: () => void;
  onExit: () => void;
  children: React.ReactNode;
  HeaderComponent?: React.ReactNode;
}

export const ESWizardV2: React.FC<ESWizardV2Props> = ({
  children,
  onClose,
  onExit,
  isOpen,
  HeaderComponent,
}) => {
  return (
    <ESFullScreenDialog isOpen={isOpen} onExited={onExit}>
      <ESDialogCloseButton
        sx={{
          color: 'background.paper',
          position: 'absolute',
          right: 5,
        }}
        onClick={onClose}
      />
      <DialogTitle sx={{ backgroundColor: 'dark.main' }}>
        {HeaderComponent ? HeaderComponent : null}
      </DialogTitle>
      <DialogContent
        sx={{
          '&.MuiDialogContent-root': {
            px: 12,
            py: 6,
          },
        }}
      >
        {children}
      </DialogContent>
    </ESFullScreenDialog>
  );
};
