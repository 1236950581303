import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const CHAdeMO_MaleIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props}>
      <g clipPath="url(#clip0_8711_2280)">
        <path d="M9.95079 12.4144C10.1781 11.0138 9.22695 9.69409 7.82633 9.46678C6.42572 9.23947 5.10603 10.1906 4.87872 11.5912C4.6514 12.9918 5.60255 14.3115 7.00317 14.5388C8.40378 14.7662 9.72348 13.815 9.95079 12.4144Z" />
        <path d="M11.9665 8.08009C12.9812 8.08009 13.8065 7.25481 13.8065 6.24013C13.8065 5.22545 12.9812 4.40018 11.9665 4.40018C10.9519 4.40018 10.1266 5.22545 10.1266 6.24013C10.1266 7.25481 10.9523 8.08009 11.9665 8.08009ZM11.3571 5.53581H11.6896L11.972 5.98851L12.2489 5.53581H12.5787L12.1404 6.21991L12.6219 6.94446H12.2788L11.9665 6.45715L11.6531 6.94446H11.312L11.7935 6.20941L11.3571 5.53581Z" />
        <path d="M11.9665 16.2318C10.9519 16.2318 10.1266 17.0571 10.1266 18.0717C10.1266 19.0864 10.9519 19.9117 11.9665 19.9117C12.9812 19.9117 13.8065 19.0864 13.8065 18.0717C13.8065 17.0571 12.9812 16.2318 11.9665 16.2318ZM12.2785 18.7765L11.9662 18.2891L11.6527 18.7765H11.3116L11.7931 18.0414L11.3567 17.3678H11.6893L11.9716 17.8205L12.2485 17.3678H12.5783L12.14 18.0519L12.6215 18.7765H12.2785Z" />
        <path d="M16.4718 14.7831C18.0073 14.7831 19.2521 13.5383 19.2521 12.0027C19.2521 10.4672 18.0073 9.22235 16.4718 9.22235C14.9362 9.22235 13.6914 10.4672 13.6914 12.0027C13.6914 13.5383 14.9362 14.7831 16.4718 14.7831Z" />
        <path d="M20.6367 7.11559L21.5996 6.07952L17.5273 2.2942L16.6654 3.22176C15.2206 2.44471 13.5949 2.03479 11.9494 2.03479C10.3281 2.03479 8.72377 2.43265 7.29568 3.18793L6.46534 2.29459L2.393 6.07952L3.29684 7.05181C2.43539 8.55458 1.98114 10.2615 1.98114 12.0027C1.98114 14.6652 3.01799 17.1687 4.90073 19.0514C6.78347 20.9342 9.28654 21.971 11.9494 21.971C14.6124 21.971 17.1154 20.9342 18.9982 19.0514C20.8809 17.1687 21.9177 14.6656 21.9177 12.0027C21.9177 10.2884 21.4752 8.60319 20.6367 7.11559ZM11.9494 21.4199C6.75664 21.4199 2.53223 17.1955 2.53223 12.0027C2.53223 10.2919 2.99621 8.61602 3.87438 7.15642L3.98133 6.97869L3.172 6.10791L6.43695 3.0732L7.18483 3.87786L7.37346 3.77247C8.76656 2.99619 10.3491 2.58588 11.9494 2.58588C13.5728 2.58588 15.1759 3.00825 16.5849 3.80669L16.7743 3.91403L17.5561 3.0732L20.8214 6.10791L19.9549 7.04014L20.0595 7.21709C20.9147 8.66308 21.3667 10.3179 21.3667 12.0027C21.3667 17.1955 17.1419 21.4199 11.9494 21.4199Z" />
        <path d="M12.0144 3.30307C7.20973 3.30307 3.31435 7.19807 3.31435 12.0031C3.31435 16.8082 7.20934 20.7032 12.0144 20.7032C16.8195 20.7032 20.7144 16.8082 20.7144 12.0031C20.7144 7.19807 16.8191 3.30307 12.0144 3.30307ZM11.9666 3.85572C13.2815 3.85572 14.351 4.92524 14.351 6.24017C14.351 7.55509 13.2815 8.62461 11.9666 8.62461C10.6516 8.62461 9.58212 7.55509 9.58212 6.24017C9.5825 4.92563 10.652 3.85572 11.9666 3.85572ZM3.87322 12.0027C3.87322 10.05 5.46194 8.46127 7.41469 8.46127C9.36743 8.46127 10.9562 10.05 10.9562 12.0027C10.9562 13.9555 9.36743 15.5442 7.41469 15.5442C5.46194 15.5442 3.87322 13.9555 3.87322 12.0027ZM11.9666 20.4562C10.6516 20.4562 9.58212 19.3867 9.58212 18.0718C9.58212 16.7569 10.6516 15.6873 11.9666 15.6873C13.2815 15.6873 14.351 16.7569 14.351 18.0718C14.351 19.3867 13.2815 20.4562 11.9666 20.4562ZM16.4718 15.7554C14.4023 15.7554 12.7191 14.0718 12.7191 12.0027C12.7191 9.9337 14.4027 8.25008 16.4718 8.25008C18.5408 8.25008 20.2244 9.9337 20.2244 12.0027C20.2244 14.0718 18.5408 15.7554 16.4718 15.7554Z" />
      </g>
      <defs>
        <clipPath id="clip0_8711_2280">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};
