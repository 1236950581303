import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  ColumnFiltersState,
  FilterFn,
  VisibilityState,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
  RowData,
  RowSelectionState,
  ColumnDef,
  Row,
  ExpandedState,
  SortingState,
} from '@tanstack/react-table';
import { useFitRows } from './useFitRows';
import { usePagination } from './usePagination';
import { useSort } from './useSort';
import { RankingInfo, rankItem, Ranking } from '@tanstack/match-sorter-utils';
import { Checkbox, Theme } from '@mui/material';
import { SystemStyleObject, ResponsiveStyleValue } from '@mui/system';
import { Property } from 'csstype';
import { useLocalStorage } from 'usehooks-ts';
import { useSyncTableFiltersWithSearchParams } from './useSyncTableFiltersWithSearchParams';
import { usePersistTableColumnWidths } from './usePersistTableColumnWidths';
import { useTableColumnResizeObserver } from './useTableColumnResizeObserver';

const fuzzyFilter: FilterFn<any> = (row, columnId, value, addMeta) => {
  // Rank the item
  const itemRank = rankItem(row.getValue(columnId), value, {
    threshold: 1.5 as Ranking,
  });

  // Store the itemRank info
  addMeta({
    itemRank,
  });

  // Return if the item should be filtered in/out
  return itemRank.passed;
};

declare module '@tanstack/table-core' {
  interface FilterMeta {
    itemRank: RankingInfo;
  }

  interface ColumnMeta<TData extends RowData, TValue> {
    backgroundColor?:
      | SystemStyleObject<Theme>
      | ResponsiveStyleValue<
          Property.BackgroundColor | Property.BackgroundColor[] | undefined
        >;
    cellPadding?:
      | SystemStyleObject<Theme>
      | ResponsiveStyleValue<
          | Property.Padding<string | number>
          | NonNullable<Property.Padding<string | number> | undefined>[]
          | undefined
        >;
    isActionable?: boolean;
  }
}

export type ESTableBasicConfig<TData> = {
  tableId?: string;
  hiddenColumnsIds?: (
    | keyof TData
    | 'actions'
    | 'expand'
    | 'select'
    | 'edit'
    | 'delete'
  )[];
  enableRowSelection?: boolean | ((row: Row<TData>) => boolean);
  onSelectionChange?: (rowSelection: RowSelectionState) => void;
  fitRowHeight?: number;
  defaultRowSelection?: (row: TData) => boolean;
  getRowId?: (originalRow: TData, index: number, parent?: Row<TData>) => string;
  initialColumnFilters?: ColumnFiltersState;
  initialSorting?: SortingState;
  persistPagination?: boolean;
  manualPagination?: boolean;
  enableColumnResizing?: boolean;
  persistSorting?: boolean;
  persistFilters?: boolean;
  initialGlobalFilter?: string;
};

// Covers use case of simple table with FE filtering including global search, column filters, sorting, and pagination
export const useESTableBasic = <T,>(
  data: T[] | undefined,
  columns: ColumnDef<T, any>[],
  config?: ESTableBasicConfig<T>
) => {
  const {
    tableId,
    hiddenColumnsIds = [],
    defaultRowSelection = () => false,
    enableRowSelection = false,
    onSelectionChange,
    fitRowHeight,
    getRowId,
    initialColumnFilters = [],
    initialSorting = [],
    persistPagination = false,
    manualPagination = false,
    enableColumnResizing = false,
    persistSorting = false,
    persistFilters = false,
    initialGlobalFilter = '',
  } = config ?? {};
  const [globalFilter, setGlobalFilter] = useState(initialGlobalFilter);
  const [rowSelection, setRowSelection] = useState<RowSelectionState>(() => {
    if (!getRowId || !data) {
      return {};
    }

    return data
      .filter((row) => defaultRowSelection(row))
      .reduce((acc, item, index) => {
        const id = getRowId(item, index);
        if (id) {
          acc[id] = true;
        }
        return acc;
      }, {} as Record<string, boolean>);
  });
  const { sorting, setSorting } = useSort(
    undefined,
    initialSorting,
    persistSorting
  );
  const { pagination, setPagination } = usePagination({
    totalRows: (data ?? []).length,
    shouldPersist: persistPagination,
  });
  const [columnFilters, setColumnFilters] = useSyncTableFiltersWithSearchParams(
    persistFilters,
    initialColumnFilters
  );
  const [expanded, setExpanded] = useState<ExpandedState>({});
  const onRowSelectionChangeRef = useRef(onSelectionChange);

  if (enableRowSelection) {
    columns.unshift({
      enableHiding: false,
      id: 'select',
      meta: { isActionable: true },
      header: ({ table }) => (
        <Checkbox
          onClick={(e) => e.stopPropagation()}
          sx={{ padding: 0 }}
          checked={table.getIsAllRowsSelected()}
          indeterminate={table.getIsSomeRowsSelected()}
          onChange={table.getToggleAllRowsSelectedHandler()}
        />
      ),
      cell: ({ row }) => {
        return (
          <Checkbox
            onClick={(e) => e.stopPropagation()}
            sx={{ padding: 0 }}
            checked={row.getIsSelected()}
            disabled={!row.getCanSelect()}
            indeterminate={row.getIsSomeSelected()}
            onChange={row.getToggleSelectedHandler()}
          />
        );
      },
      size: 50,
    });
  }

  const [persistedColumnVisibility, setPersistedColumnVisibility] =
    useLocalStorage(`table-column-select-${tableId}`, {});

  const hiddenColumnsVisibility = useMemo(
    () =>
      hiddenColumnsIds.reduce((acc, id) => {
        acc[String(id)] = false;
        return acc;
      }, {} as VisibilityState),
    [hiddenColumnsIds]
  );

  const { persistedColumnWidths, handlePersistTableColumnWidth } =
    usePersistTableColumnWidths(tableId);

  const instance = useReactTable({
    data: data ?? [],
    columns,
    initialState: {
      columnFilters,
      columnSizing: persistedColumnWidths,
    },
    state: {
      pagination,
      globalFilter,
      columnFilters,
      sorting,
      rowSelection,
      columnVisibility: {
        ...persistedColumnVisibility,
        ...hiddenColumnsVisibility,
      },
      expanded,
    },
    defaultColumn: {
      minSize: 100, // Can be overwritten through column definition from Table components
    },
    autoResetPageIndex: false,
    getCoreRowModel: getCoreRowModel(),
    onPaginationChange: setPagination,
    globalFilterFn: fuzzyFilter,
    onGlobalFilterChange: setGlobalFilter,
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onSortingChange: setSorting,
    onRowSelectionChange: setRowSelection,
    getSortedRowModel: getSortedRowModel(),
    onColumnFiltersChange: (updater) => {
      instance.resetPageIndex();
      setColumnFilters(updater);
    },
    onColumnVisibilityChange: setPersistedColumnVisibility,
    enableRowSelection,
    getRowId,
    onExpandedChange: setExpanded,
    manualPagination,
    enableColumnResizing,
    columnResizeMode: 'onChange',
  });

  useTableColumnResizeObserver(instance, handlePersistTableColumnWidth);

  useEffect(() => {
    if (enableRowSelection) {
      onRowSelectionChangeRef.current?.(rowSelection);
    }
  }, [enableRowSelection, rowSelection]);

  const canPreviousPage = instance.getCanPreviousPage();

  const onGlobalFilterChange = useCallback(
    (value: string) => {
      const trimmedNewValue = value.trim();
      instance.resetPageIndex();
      setGlobalFilter(trimmedNewValue);
    },
    [instance]
  );

  const handleSelectRow = useCallback(
    (row: Row<T>) => {
      if (enableRowSelection) {
        const toggleRowSelected = row.getToggleSelectedHandler();
        toggleRowSelected(row);
      }
    },
    [enableRowSelection]
  );

  /// TODO: Make fit rows more dynamic
  const { rowsPerPageOptions } = useFitRows(
    instance,
    data?.length ? data.length : 0,
    fitRowHeight
  );

  const rows = instance.getRowModel().rows;
  const allRows = instance.getCoreRowModel().rows;
  const filteredRows = instance.getFilteredRowModel().rows;

  useEffect(() => {
    if (rows.length === 0 && canPreviousPage) {
      instance.previousPage();
    }
  }, [canPreviousPage, rows.length, instance]);

  return {
    rows,
    filteredRows,
    rowsPerPageOptions,
    instance,
    globalFilter,
    onGlobalFilterChange,
    enableRowSelection,
    handleSelectRow,
    allRows,
  };
};
