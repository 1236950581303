import {
  CommunicationDirectionModel,
  EventTypeModel,
  NetworkLog,
  OcppNetworkLogsModel,
} from '@energy-stacks/broker/feature-ocpp-network-logs-data';
import { DateRange } from '@energy-stacks/core/date-range-picker';
import {
  DEFAULT_PAGINATION_CONFIG,
  ESTable,
  ESTableBodyCell,
  ESTableBodyCompose,
  ESTableBodyRow,
  ESTableHead,
  ESTablePagination,
  ESTableWrapper,
  ESTextButton,
  Sort,
  useFitRows,
} from '@energy-stacks/core/ui';
import {
  NoTableData,
  CollapsibleRawContentRow,
  RefetchUpdatesButton,
  TableColumnSelect,
  TableDateTimeFilter,
  formatDateTime,
  useChargingStationIdentityKey,
  useStompTopic,
} from '@energy-stacks/shared';
import { Box, Stack, Typography } from '@mui/material';
import {
  ExpandedState,
  PaginationState,
  Row,
  SortingState,
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { isAfter, parseISO } from 'date-fns';
import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useLocalStorage } from 'usehooks-ts';
import { CommunicationDirection } from './CommunicationDirection';
import { CommunicationDirectionFilter } from './CommunicationDirectionFilter';
import { EventTypeFilter } from './EventTypeFilter';
import ShortUniqueId from 'short-unique-id';

export type FilterActionType =
  | 'FILTER_DATE'
  | 'FILTER_COMMUNICATION_DIRECTION'
  | 'FILTER_EVENT_TYPE'
  | 'RESET';

export interface FiltersState {
  dateRange?: DateRange | undefined;
  eventTypeFilter?: EventTypeModel | undefined;
  communicationDirectionFilter?: CommunicationDirectionModel | undefined;
}

export interface FilterAction {
  type: FilterActionType;
  payload?: Partial<FiltersState>;
}

type NetworkLogsTableProps = {
  tableId?: string;
  networkLogs: OcppNetworkLogsModel | undefined;
  onFiltersChange: (filters: FilterAction) => void;
  filtersState: FiltersState;
  pagination: PaginationState;
  onPaginationChange: Dispatch<SetStateAction<PaginationState>>;
  sorting: SortingState;
  onSortingChange: Dispatch<SetStateAction<SortingState>>;
  sortOrder: Sort;
  onRefetch: () => void;
  isFetching: boolean;
  logsAccessDatetime: Date;
  testId?: string;
};

const { randomUUID: uuid } = new ShortUniqueId({ length: 10 });

export const NetworkLogsTable: React.FC<NetworkLogsTableProps> = ({
  tableId = 'networkLogs',
  networkLogs,
  onPaginationChange,
  onSortingChange,
  sorting,
  pagination,
  onFiltersChange,
  filtersState,
  onRefetch,
  isFetching,
  logsAccessDatetime,
  testId,
}) => {
  const [expanded, setExpanded] = useState<ExpandedState>({});
  const { t } = useTranslation('chargingStations');
  const [sharedT] = useTranslation('shared');
  const [rowSeen, setRowSeen] = useState<Record<string, boolean>>({});

  const columnHelper = createColumnHelper<NetworkLog>();
  const columns = [
    columnHelper.accessor('timestamp', {
      sortingFn: 'datetime',
      header: () => t('timestamp'),
      footer: (props) => props.column.id,
      cell: (info) => (
        <Box>
          <span>{formatDateTime(info.getValue()) ?? '-'}</span>
        </Box>
      ),
    }),
    columnHelper.accessor('errorReason', {
      enableSorting: false,
      header: () => t('errorReason'),
      footer: (props) => props.column.id,
      cell: (info) => <Typography>{info.getValue() ?? '-'}</Typography>,
    }),
    columnHelper.accessor('eventType', {
      header: () => t('eventType'),
      footer: (props) => props.column.id,
      cell: (info) => <Typography>{t(info.getValue())}</Typography>,
      enableSorting: false,
    }),

    columnHelper.accessor('communicationDirection', {
      header: () => t('communicationDirection'),
      footer: (props) => props.column.id,
      cell: (info) => (
        <CommunicationDirection communicationDirection={info.getValue()} />
      ),
      enableSorting: false,
    }),
  ];

  const [persistedColumnVisibility] = useLocalStorage(
    `table-column-select-${tableId}`,
    {}
  );

  const instance = useReactTable({
    data: networkLogs?.content ?? [],
    columns,
    state: {
      pagination,
      sorting,
      expanded,
      columnVisibility: persistedColumnVisibility,
    },
    getRowId: (row) => uuid(),
    getCoreRowModel: getCoreRowModel(),
    onPaginationChange,
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onSortingChange,
    getSortedRowModel: getSortedRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    onExpandedChange: setExpanded,
    enableExpanding: true,
    manualExpanding: true,
    manualPagination: true,
    pageCount: networkLogs?.totalPages,
    enableColumnResizing: false,
  });

  const { rowsPerPageOptions } = useFitRows(
    instance,
    networkLogs?.totalElements ?? 0,
    68.5,
    83
  );

  const rows = instance.getRowModel().rows;
  const hasRows = rows.length !== 0;
  const filteredRows = instance.getFilteredRowModel().rows;

  const handleDateRangeFilterChange = useCallback(
    (dateRange: DateRange) => {
      onFiltersChange({ type: 'FILTER_DATE', payload: { dateRange } });
      instance.setPageIndex(DEFAULT_PAGINATION_CONFIG.page);
    },
    [instance, onFiltersChange]
  );

  const handleDateRangeFilterCleared = useCallback(() => {
    onFiltersChange({ type: 'FILTER_DATE', payload: undefined });
    instance.setPageIndex(DEFAULT_PAGINATION_CONFIG.page);
  }, [instance, onFiltersChange]);

  const handleCommunicationDirectionFilterChange = useCallback(
    (communicationDirectionFilter: CommunicationDirectionModel | undefined) => {
      onFiltersChange({
        type: 'FILTER_COMMUNICATION_DIRECTION',
        payload: { communicationDirectionFilter: communicationDirectionFilter },
      });
      instance.setPageIndex(DEFAULT_PAGINATION_CONFIG.page);
    },
    [instance, onFiltersChange]
  );
  const handleEventTypeFilterChange = useCallback(
    (eventTypeFilter: EventTypeModel | undefined) => {
      onFiltersChange({
        type: 'FILTER_EVENT_TYPE',
        payload: { eventTypeFilter: eventTypeFilter },
      });
      instance.setPageIndex(DEFAULT_PAGINATION_CONFIG.page);
    },
    [instance, onFiltersChange]
  );

  const hasFilters = useMemo(
    () => Object.values(filtersState).some((filter) => Boolean(filter)),
    [filtersState]
  );

  const clearAllFilters = useCallback(() => {
    onFiltersChange({
      type: 'RESET',
    });
    instance.setPageIndex(DEFAULT_PAGINATION_CONFIG.page);
  }, [instance, onFiltersChange]);

  const isRowHighlighted = (row: Row<NetworkLog>): boolean => {
    if (rowSeen[row.original.timestamp]) {
      return false;
    }
    const originalDate = parseISO(row.original.timestamp);
    return isAfter(originalDate, logsAccessDatetime);
  };

  return (
    <>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="flex-start"
      >
        <Stack direction="row" gap={3} alignItems="center" marginBottom={6}>
          <TableDateTimeFilter
            title={t('timeRange')}
            isActive={filtersState.dateRange !== undefined}
            defaultDateRange={filtersState.dateRange}
            onDateRangeApplied={handleDateRangeFilterChange}
            onDateRangeCleared={handleDateRangeFilterCleared}
          />
          <CommunicationDirectionFilter
            communicationDirectionFilter={
              filtersState.communicationDirectionFilter
            }
            onCommunicationDirectionFilterChange={
              handleCommunicationDirectionFilterChange
            }
          />
          <EventTypeFilter
            eventTypeFilter={filtersState.eventTypeFilter}
            onEventTypeFilterChange={handleEventTypeFilterChange}
          />
          {hasFilters ? (
            <ESTextButton onClick={clearAllFilters}>
              {sharedT('clearAll')}
            </ESTextButton>
          ) : null}
        </Stack>
        <Stack
          flexDirection="row"
          columnGap={2}
          sx={{ ml: 2 }}
          alignItems="center"
        >
          <RefetchLogsButton
            isFetching={isFetching}
            onRefetch={() => {
              // Invalidate all already fetched rows but not seen
              const afterLogsAccess =
                networkLogs?.content.filter((log) =>
                  isAfter(parseISO(log.timestamp), logsAccessDatetime)
                ) ?? [];
              setRowSeen((seen) => ({
                ...seen,
                ...afterLogsAccess.reduce((acc, log) => {
                  acc[log.timestamp] = true;
                  return acc;
                }, {} as { [key: string]: boolean }),
              }));

              onRefetch();
            }}
          />
          <Box sx={{ marginLeft: 'auto', px: 2 }}>
            <TableColumnSelect instance={instance} />
          </Box>
        </Stack>
      </Box>
      <ESTableWrapper>
        <ESTable>
          <ESTableHead testId={testId} instance={instance} />
          <ESTableBodyCompose>
            {rows.map((row, index) => {
              return (
                <React.Fragment key={row.id}>
                  <ESTableBodyRow data-testid={`${testId}TableRow${index}`}>
                    {row.getVisibleCells().map((cell, i) => {
                      const isExpandColumn =
                        i === 0 && cell.id.includes('expand');

                      return (
                        <ESTableBodyCell
                          sx={{
                            '&': {
                              width: !isExpandColumn
                                ? cell.column.getSize()
                                : '20px',
                            },
                          }}
                          key={cell.id}
                          onClick={() => {
                            if (rowSeen[row.original.timestamp]) {
                              return;
                            }
                            setRowSeen((seen) => ({
                              ...seen,
                              [row.original.timestamp]: true,
                            }));
                          }}
                        >
                          <Box
                            fontWeight={
                              isRowHighlighted(row) &&
                              cell.id.includes('timestamp')
                                ? 'bold'
                                : '500'
                            }
                          >
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext()
                            )}
                          </Box>
                        </ESTableBodyCell>
                      );
                    })}
                  </ESTableBodyRow>
                  <CollapsibleRawContentRow
                    expanded={row.getIsExpanded()}
                    message={row?.original?.errorReason ?? ''}
                  />
                </React.Fragment>
              );
            })}
          </ESTableBodyCompose>
        </ESTable>

        {!hasRows ? (
          <NoTableData top={83} message={t('thereAreNoNetworkLogs')} />
        ) : null}

        <ESTablePagination
          rowsPerPageOptions={rowsPerPageOptions}
          instance={instance}
          count={networkLogs?.totalElements || filteredRows.length}
        />
      </ESTableWrapper>
    </>
  );
};

const RefetchLogsButton: React.FC<{
  onRefetch: () => void;
  isFetching: boolean;
}> = ({ onRefetch, isFetching }) => {
  const identityKey = useChargingStationIdentityKey();
  const [count, setCount] = useState(0);
  useStompTopic(
    `/topic/chargingstation-ocpp-network-log-created/${identityKey}`,
    () => {
      setCount((count) => count + 1);
    }
  );

  useEffect(() => {
    if (!isFetching) {
      setCount(0);
    }
  }, [isFetching]);

  const liveIndicatorCount = count.toString().padStart(2, '0');

  return (isFetching && count > 0) || count > 0 ? (
    <Box sx={{ mr: 2 }}>
      <RefetchUpdatesButton
        testId="refetchLogsButton"
        onClick={onRefetch}
        isFetching={isFetching}
        label={liveIndicatorCount}
      />
    </Box>
  ) : null;
};
