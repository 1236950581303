import { isBefore, isEqual } from 'date-fns';
import { useCallback, useState } from 'react';
import { getDefaultDateRange } from './getDefaultDateRange';
import { DateRange } from './date-range';

export const useDateRange = (defaultDateRange: DateRange | undefined) => {
  const [dateRange, setDateRange] = useState<DateRange>(
    defaultDateRange ?? getDefaultDateRange()
  );

  const isInvalid =
    !isBefore(dateRange.startDate, dateRange.endDate) &&
    !isEqual(dateRange.startDate, dateRange.endDate);

  const setDate = useCallback(
    (dateRange: DateRange | undefined) => {
      setDateRange((dateRange || defaultDateRange) ?? getDefaultDateRange());
    },
    [setDateRange, defaultDateRange]
  );

  return {
    dateRange,
    setDateRange: setDate,
    isInvalid,
  };
};
