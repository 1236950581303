import { MenuItem, TextField, TextFieldProps } from '@mui/material';

type ESSelectProps = TextFieldProps & {
  options: (string | number)[] | { label: string; value: string | number }[];
  testId?: string;
};

export const ESSelect: React.FC<ESSelectProps> = (props) => {
  const { options, testId, ...textFieldProps } = props;
  return (
    <TextField
      inputProps={{
        'data-testid': `${testId}Input`,
      }}
      select
      variant="outlined"
      size="medium"
      fullWidth
      {...textFieldProps}
    >
      {options.map((option, index) => {
        const hasTranslations =
          typeof option !== 'string' && typeof option !== 'number';

        return (
          <MenuItem
            data-testid={`${testId}MenuItem${index}`}
            key={hasTranslations ? option.value : option}
            value={hasTranslations ? option.value : option}
            sx={{ whiteSpace: 'break-spaces' }}
          >
            {hasTranslations ? option.label : option}
          </MenuItem>
        );
      })}
    </TextField>
  );
};
