import { PowerType } from './powerType';

export function calculateMaxPowerInWatts(
  powerType: PowerType,
  maxVoltage: number,
  maxAmperage: number
) {
  return calculationMaxPowerPerTypeInWatts[powerType](maxVoltage, maxAmperage);
}

const calculationMaxPowerPerTypeInWatts: Record<
  PowerType,
  (maxVoltage: number, maxAmperage: number) => number
> = {
  ac1Phase: (maxVoltage, maxAmperage) => maxVoltage * maxAmperage,
  ac3Phase: (maxVoltage, maxAmperage) => 3 * maxVoltage * maxAmperage,
  dc: (maxVoltage, maxAmperage) => maxVoltage * maxAmperage,
};
