import {
  CommunicationDirectionModel,
  NetworkLogsCommunicationDirectionEnum,
  communicationDirectionToEnum,
} from '@energy-stacks/broker/feature-ocpp-network-logs-data';
import { TableFilterButton, stringEnumToArray } from '@energy-stacks/shared';
import { Box, Divider, MenuItem, Radio } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { CommunicationDirectionOption } from './CommunicationDirectionOption';

interface CommunicationDirectionFilterProps {
  communicationDirectionFilter: CommunicationDirectionModel | undefined;
  onCommunicationDirectionFilterChange: (
    communicationDirectionFilter: CommunicationDirectionModel | undefined
  ) => void;
}

export const CommunicationDirectionFilter: React.FC<
  CommunicationDirectionFilterProps
> = ({
  communicationDirectionFilter,
  onCommunicationDirectionFilterChange,
}) => {
  const { t } = useTranslation('chargingStations');

  const options = stringEnumToArray(NetworkLogsCommunicationDirectionEnum).map(
    (direction) => {
      return {
        label:
          communicationDirectionToEnum[
            direction as CommunicationDirectionModel
          ],
        value: direction as CommunicationDirectionModel,
      };
    }
  );

  const handleFilterChange = (filter: CommunicationDirectionModel) => {
    onCommunicationDirectionFilterChange(filter);
  };

  return (
    <TableFilterButton
      testId="communicationDirection"
      title={t('communicationDirection')}
      renderPopover={(_closePopover) => {
        return (
          <>
            <Box>
              {options.map((option) => (
                <MenuItem
                  data-testid={`communicationDirectionOption${option}`}
                  key={option.label}
                  value={option.value}
                  onClick={() => {
                    handleFilterChange(option.value);
                  }}
                >
                  <Radio
                    checked={option.value === communicationDirectionFilter}
                    value={option}
                  />
                  <CommunicationDirectionOption
                    communicationDirection={option.value}
                  />
                </MenuItem>
              ))}
            </Box>

            <Divider />
          </>
        );
      }}
      isActive={Boolean(communicationDirectionFilter)}
    />
  );
};
