import { Box, Collapse, Dialog, Divider } from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
  ESDialogTitle,
  ESDialogContent,
  ESDialogActions,
  ESButton,
  ESVirtualizedAutocomplete,
  showSnackbar,
} from '@energy-stacks/core/ui';
import { CommonDialogProps } from '@energy-stacks/shared';
import { useEffect, useMemo, useState } from 'react';
import {
  ConnectorModel,
  EditConnectorRequest,
  useEditConnectorMutation,
} from '@energy-stacks/broker/feature-charging-stations-data';
import {
  TariffModel,
  tariffApiErrors,
  useGetTariffsQuery,
} from '@energy-stacks/broker/feature-tariffs-data';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import {
  TariffElementsView,
  TariffGeneralInfoView,
} from '@energy-stacks/broker/shared';
import { IconChevronDown, IconChevronUp } from '@tabler/icons-react';
import { useGetUiBrandingQuery } from '@energy-stacks/broker/feature-settings-data';

type AssignTariffDialogProps = CommonDialogProps & {
  testId: string;
  identityKey: string;
  connector: ConnectorModel;
};

interface ConnectorFormData {
  evseId: string;
  type: ConnectorModel['connectorType'];
  connectorFormat: string;
  powerType: ConnectorModel['powerType'];
  maxVoltage: string;
  maxAmperage: string;
  maxPower: string;
  tariffId: string | null;
}

interface BrokerConnectorTariffOption {
  tariffOption: { label: string; value: string } | null;
}

export const AssignTariffDialog: React.FC<AssignTariffDialogProps> = ({
  isOpen,
  onClose,
  testId,
  identityKey,
  connector,
}) => {
  const { t } = useTranslation('tariffs');
  const [tShared] = useTranslation('shared');

  const { data: uiBranding } = useGetUiBrandingQuery();
  const hasBorderRadius =
    uiBranding?.hasBorderRadius || uiBranding?.hasBorderRadius === undefined;

  const [editConnector, { isLoading }] = useEditConnectorMutation();
  const { data: tariffs } = useGetTariffsQuery();
  const [tariff, setTariff] = useState<TariffModel>();
  const [openDetails, setOpenDetails] = useState<boolean>(false);

  const tariffOptions = useMemo(
    () =>
      tariffs?.map((tariff) => ({
        label: tariff.name,
        value: tariff.id,
      })) || [],
    [tariffs]
  );

  const defaultTariffOption = useMemo(
    () => tariffOptions.find((tariff) => tariff.value === connector.tariffId),
    [connector, tariffOptions]
  );

  const defaultConnectorValues: ConnectorFormData = {
    type: connector.connectorType ? connector.connectorType : undefined,
    evseId: connector.evseId.toString(),
    connectorFormat: connector.connectorFormat || '',
    powerType: connector.powerType ? connector.powerType : undefined,
    maxVoltage: connector.maxVoltage.toString(),
    maxAmperage: connector.maxAmperage.toString(),
    maxPower: connector.maxPower.toString(),
    tariffId: connector.tariffId || null,
  };

  const {
    handleSubmit,
    control,
    formState: { isDirty },
    reset,
  } = useForm<BrokerConnectorTariffOption>({
    mode: 'onTouched',
    defaultValues: { tariffOption: defaultTariffOption },
  });

  useEffect(() => {
    // show initial tariff in the Form
    if (defaultTariffOption) {
      reset({ tariffOption: defaultTariffOption });
    }
  }, [reset, defaultTariffOption]);

  const onSubmit: SubmitHandler<BrokerConnectorTariffOption> = (data) => {
    const requestData: EditConnectorRequest = {
      standard: defaultConnectorValues.type || null,
      evseId: defaultConnectorValues.evseId || null,
      format:
        defaultConnectorValues.connectorFormat.toLocaleUpperCase() || null,
      power_type: defaultConnectorValues.powerType || null,
      max_voltage: Number(defaultConnectorValues.maxVoltage),
      max_amperage: Number(defaultConnectorValues.maxAmperage),
      max_electric_power: Number(defaultConnectorValues.maxPower),
      tariff_id: data.tariffOption ? data.tariffOption.value : null,
    };

    editConnector({
      data: requestData,
      identityKey: identityKey,
      connectorId: connector.connectorId,
    })
      .unwrap()
      .then(() => {
        showSnackbar('success', 'connectorAssignTariffSuccessfully', 'tariffs');
        onClose();
        reset();
        setTariff(undefined);
        setOpenDetails(false);
      })
      .catch((error) => {
        showSnackbar(
          'error',
          tariffApiErrors[error?.data.errorCode] ??
            'connectorAssignTariffError',
          'tariffs'
        );
      });
  };

  return (
    <Dialog open={isOpen} onClose={onClose} fullWidth maxWidth="sm">
      <form onSubmit={handleSubmit(onSubmit)}>
        <ESDialogTitle data-testid={`assignTariff${testId}Title`}>
          {t('assignTariff')}
        </ESDialogTitle>

        <ESDialogContent
          data-testid={`assignTariff${testId}Content`}
          sx={{ height: 400 }}
        >
          <Controller
            control={control}
            rules={{ required: true }}
            render={({ field: { onChange, onBlur, value } }) => {
              return (
                <ESVirtualizedAutocomplete
                  data-testid={`${testId}TariffAutocompleteField`}
                  value={{
                    label: value?.label || '',
                    value: value?.value || '',
                  }}
                  label={t('tariff')}
                  options={tariffOptions}
                  onBlur={onBlur}
                  onChange={(_, value) => {
                    if (Array.isArray(value)) {
                      return;
                    }

                    if (value) {
                      onChange(value);
                      if (value?.value) {
                        const choosenTariff = tariffs?.find(
                          (tariff) => tariff.id === value.value
                        );
                        setTariff(choosenTariff);
                      }
                    } else {
                      setTariff(undefined);
                      setOpenDetails(false);
                    }
                  }}
                  noOptionsText={t('noTariff')}
                  disabled={isLoading}
                />
              );
            }}
            name={`tariffOption`}
          />
          {tariff ? (
            <ESButton
              data-testid={`assignTariff${testId}ViewDetails`}
              variant="text"
              sx={{
                display: 'flex',
                justifyContent: 'flex-start',
                my: 2,
                maxHeight: '8px !important',
                '&.MuiButtonBase-root:hover': {
                  bgcolor: 'transparent',
                },
              }}
              aria-label="expand row"
              size="small"
              onClick={() => setOpenDetails(!openDetails)}
              startIcon={openDetails ? <IconChevronUp /> : <IconChevronDown />}
            >
              {t('details')}
            </ESButton>
          ) : null}

          <Collapse in={openDetails} timeout="auto" unmountOnExit>
            <Box
              sx={{
                backgroundColor: 'grey.50',
                borderRadius: hasBorderRadius ? 3 : 0,
                p: 4,
              }}
            >
              {tariff ? <TariffGeneralInfoView tariff={tariff} /> : null}
              <Divider sx={{ my: 4 }} />
              {tariff?.elements && (
                <TariffElementsView tariffElements={tariff.elements} />
              )}
            </Box>
          </Collapse>
        </ESDialogContent>
        <ESDialogActions sx={{ mt: 12 }}>
          <ESButton
            data-testid={`assignTariff${testId}CancelButton`}
            onClick={() => {
              onClose();
              reset();
            }}
            color="error"
            variant="text"
          >
            {tShared('cancel')}
          </ESButton>
          <ESButton
            data-testid={`assignTariff${testId}AssignButton`}
            variant="contained"
            type="submit"
            disabled={!isDirty || !tariff}
            loading={isLoading}
          >
            {t('assignTariff')}
          </ESButton>
        </ESDialogActions>
      </form>
    </Dialog>
  );
};
