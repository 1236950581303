import { BrokerRoutes } from '@energy-stacks/broker/shared';
import { SidebarGroup } from '@energy-stacks/core/sidebar';
import { IconChargingPile } from '@tabler/icons-react';
import { useTranslation } from 'react-i18next';

export const ChargingStationsDrawerItem = () => {
  const { t } = useTranslation('chargingStations');
  const itemLabel = t('title');

  return (
    <SidebarGroup
      testId="chargingStationsSidebarButton"
      groupInitialPath={BrokerRoutes.ChargingStations}
      groupIcon={<IconChargingPile />}
      groupLabel={itemLabel}
    ></SidebarGroup>
  );
};
