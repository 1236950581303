import { useCallback, useEffect } from 'react';
import {
  ESDialogActionButton,
  ESDialogActions,
  ESDialogContent,
  ESDialogTitle,
  ESVirtualizedAutocomplete,
} from '@energy-stacks/core/ui';
import { useTranslation } from 'react-i18next';
import { Dialog } from '@mui/material';
import { useESSnackbar } from '@energy-stacks/core/ui';
import {
  useAssignChargingStationMutation,
  useGetAllLocationsBrokerQuery,
} from '@energy-stacks/broker/feature-locations-data';
import { useNavigate, useParams } from 'react-router-dom';
import { useChargingStations } from '@energy-stacks/broker/feature-charging-stations-data';
import { Controller, useForm } from 'react-hook-form';

interface AssignCSFormData {
  chargingStation: { label: string; value: string };
}

const assignCSFormDataDefaultValues: AssignCSFormData = {
  chargingStation: { label: '', value: '' },
};

export const LocationDrawerAssignChargingStation = () => {
  const { showSnackbar } = useESSnackbar();
  const { t } = useTranslation('locations');
  const navigate = useNavigate();

  const handleCloseDialog = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const methods = useForm<AssignCSFormData>({
    mode: 'onTouched',
    defaultValues: assignCSFormDataDefaultValues,
  });

  const {
    handleSubmit,
    control,
    formState: { isDirty },
    reset: resetForm,
  } = methods;

  const { locationUuid } = useParams<{ locationUuid: string }>();
  const { location, isSuccess } = useGetAllLocationsBrokerQuery(undefined, {
    selectFromResult: ({ data, isSuccess }) => ({
      location: data?.find((location) => location.uuid === locationUuid),
      isSuccess,
    }),
  });

  const {
    data,
    isError: isChargingStationError,
    refetch,
  } = useChargingStations();

  const [assignChargingStation, { isLoading }] =
    useAssignChargingStationMutation();

  // if location extracted from url doesn't exist, show error message and navigate to location details table
  useEffect(() => {
    if (isSuccess && !location) {
      showSnackbar('error', 'locationFetchingError', 'locations');
      handleCloseDialog();
    }
  }, [handleCloseDialog, isSuccess, location, showSnackbar]);

  const handleAssignChargingStation = (formData: AssignCSFormData) => {
    if (!locationUuid) {
      return;
    }
    refetch();
    const isCSIdentityKeyValid = data?.ids.some(
      (stationId) => stationId === formData.chargingStation.value
    );

    if (!isCSIdentityKeyValid || isChargingStationError) {
      showSnackbar('error', 'chargingStationDontExists', 'locations');
      resetForm({ chargingStation: { label: '', value: '' } });
      return;
    }

    assignChargingStation({
      locationUuid,
      chargingStation: formData.chargingStation.value,
    })
      .unwrap()
      .then(() => {
        showSnackbar('success', 'assingCSSuccess', 'locations');
        handleCloseDialog();
      })
      .catch(() => {
        showSnackbar('error');
      });
  };

  return (
    <Dialog open fullWidth maxWidth="sm">
      <ESDialogTitle>{t('confirmAssignTitle')}</ESDialogTitle>
      <form>
        <ESDialogContent>
          <Controller
            control={control}
            render={({ field: { onChange, onBlur, value } }) => {
              return (
                <ESVirtualizedAutocomplete
                  label={t('chargingStation')}
                  value={{ value: value.value, label: value.label }}
                  options={
                    data?.ids
                      .filter(
                        (station) =>
                          data.entities[station]?.location.uuid === 'N/A'
                      )
                      .map((station) => ({
                        label: station.toString(),
                        value: station,
                      })) ?? []
                  }
                  noOptionsText={t('noChargingStations')}
                  onChange={(_, value) => {
                    if (Array.isArray(value)) {
                      return;
                    }
                    if (value) {
                      onChange({
                        label: value.label ?? '',
                        value: value.value ?? '',
                      });
                    }
                  }}
                  onBlur={onBlur}
                />
              );
            }}
            name={'chargingStation'}
          />
        </ESDialogContent>
      </form>
      <ESDialogActions>
        <ESDialogActionButton
          disabled={isLoading}
          color="error"
          onClick={handleCloseDialog}
        >
          {t('cancel')}
        </ESDialogActionButton>
        <ESDialogActionButton
          variant="contained"
          disabled={!isDirty || isLoading}
          loading={isLoading}
          onClick={handleSubmit(handleAssignChargingStation)}
        >
          {t('assign')}
        </ESDialogActionButton>
      </ESDialogActions>
    </Dialog>
  );
};
