import { SidebarGroup } from '@energy-stacks/core/sidebar';
import { IconBox } from '@tabler/icons-react';
import { FleetRoutes } from '@energy-stacks/fleet/shared';
import { useTranslation } from 'react-i18next';

export const JobsSidebarItem = () => {
  const [t] = useTranslation('jobs');

  return (
    <SidebarGroup
      groupInitialPath={FleetRoutes.Jobs}
      groupIcon={<IconBox />}
      groupLabel={t('sidebarItem')}
      testId="jobsSidebarButton"
    ></SidebarGroup>
  );
};
