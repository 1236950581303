import { createColumnHelper, flexRender, Row } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';
import {
  ESTable,
  ESTableWrapper,
  useESTableBasic,
  ESTableHead,
  ESTableBodyCompose,
  ESTableBodyRow,
  ESTableBodyCell,
  ESTableBasicConfig,
} from '@energy-stacks/core/ui';
import { NoTableData, ExpandButton, formatDate } from '@energy-stacks/shared';
import { Box, Stack, Typography } from '@mui/material';
import React, { useMemo } from 'react';
import {
  OptimizedTourJob,
  TourJobs,
} from '@energy-stacks/fleet/feature-tours-data';
import { TourJobIdCell } from './TourJobIdCell';
import { JobDetailsRow } from './JobDetailsRow';
import { TourDetail } from './tourDetail';
import { mapTourJobsToTourDetail } from './mapTourJobsToTourDetail';
import { compareAsc, differenceInSeconds } from 'date-fns';
import { JobLocationCell } from '@energy-stacks/fleet/feature-jobs';
import { useRemovePlantIdPrefix } from '@energy-stacks/fleet/feature-business-accounts';
import {
  ContainerIcon,
  formatIntervalDuration,
  JobStatusChip,
} from '@energy-stacks/fleet/shared';
import { TourDetailsJobsTableViewMode } from './TourDetailsJobsTableViewModeToggle';
import { forecastJobs } from './forecastJobs';
import { IconClockHour5 } from '@tabler/icons-react';
import { ConfirmedDuration } from '../shared/ConfirmedDuration';
import { ConfirmedTime } from '../shared/ConfirmedTime';
import { ForecastedTime } from '../shared/ForecastedTime';

interface TourDetailsJobsTableProps extends ESTableBasicConfig<TourDetail> {
  tourJobs: TourJobs;
  tableViewMode?: TourDetailsJobsTableViewMode;
}

const ROW_HEIGHT = 90;

const JobTypeGuard: React.FC<{
  jobType: TourDetail['jobType'];
  children: React.ReactNode;
}> = ({ jobType, children }) => {
  if (jobType !== 'INBOUND' && jobType !== 'OUTBOUND') {
    return null;
  }

  return <>{children}</>;
};

export const TourDetailsJobsTable: React.FC<TourDetailsJobsTableProps> = ({
  tourJobs,
  tableViewMode = 'standard',
  hiddenColumnsIds = [],
}) => {
  const [t] = useTranslation('jobs');
  const columnHelper = createColumnHelper<TourDetail>();
  const removePlantIdPrefix = useRemovePlantIdPrefix();

  const columns = [
    {
      id: 'expand',
      size: 20,
      cell: ({ row }: { row: Row<TourDetail> }) => {
        const { jobType } = row.original;

        return (
          <JobTypeGuard jobType={jobType}>
            <ExpandButton
              onToggleExpand={() => row.toggleExpanded()}
              expanded={row.getIsExpanded()}
            />
          </JobTypeGuard>
        );
      },
    },
    columnHelper.accessor((row) => removePlantIdPrefix(row.jobId), {
      id: 'jobId',
      header: () => t('jobId'),
      footer: (props) => props.column.id,
      cell: (info) => (
        <TourJobIdCell
          jobType={info.row.original.jobType}
          jobId={removePlantIdPrefix(info.getValue())}
          contaminationViolation={info.row.original.contaminationViolation}
        />
      ),
      size: 120,
      enableSorting: false,
    }),
    columnHelper.accessor('status', {
      id: 'status',
      header: () => t('status'),
      footer: (props) => props.column.id,
      cell: (info) => <JobStatusChip status={info.getValue()} />,
      enableSorting: false,
    }),
    columnHelper.accessor('startTime', {
      header: () => t('startTime'),
      footer: (props) => props.column.id,
      cell: ({ row }) => {
        const { jobType, origin, startTime } = row.original;
        return (
          <Stack gap={2}>
            <Stack direction="row" alignItems="center">
              <ContainerIcon>
                <IconClockHour5 size={20} />
              </ContainerIcon>
              <Typography fontWeight={500} color="grey.900">
                {startTime
                  ? formatDate(startTime, 'HH:mm').toLowerCase()
                  : '---'}
              </Typography>
              {row.original.confirmedStartTime && (
                <>
                  <Box component="span" sx={{ mx: 2 }}>
                    •
                  </Box>
                  <ConfirmedTime
                    confirmedTime={row.original.confirmedStartTime}
                    plannedTime={startTime}
                  />
                </>
              )}
              {row.original.forecastedStartTime &&
                !row.original.confirmedStartTime && (
                  <>
                    <Box component="span" sx={{ mx: 2 }}>
                      •
                    </Box>
                    <ForecastedTime
                      forecastedTime={row.original.forecastedStartTime}
                    />
                  </>
                )}
            </Stack>
            <JobTypeGuard jobType={jobType}>
              <JobLocationCell
                location={origin}
                showFullAddress={false}
                // JobTypeGuard will filter out BREAKs and EMPTY_RUNs so it's safe to type cast to OptimizedTourJob['jobType']
                jobType={row.original.jobType as OptimizedTourJob['jobType']}
                locationType="origin"
                supplierName={row.original.supplier.supplierName}
              />
            </JobTypeGuard>
          </Stack>
        );
      },
      size: 120,
      enableSorting: false,
    }),
    columnHelper.accessor('endTime', {
      header: () => t('endTime'),
      footer: (props) => props.column.id,
      cell: ({ row }) => {
        const { jobType, destination, endTime } = row.original;
        return (
          <Stack gap={2}>
            <Stack direction="row" alignItems="center">
              <ContainerIcon>
                <IconClockHour5 size={20} />
              </ContainerIcon>
              <Typography fontWeight={500} color="grey.900">
                {endTime ? formatDate(endTime, 'HH:mm').toLowerCase() : '---'}
              </Typography>
              {row.original.confirmedEndTime && (
                <>
                  <Box component="span" sx={{ mx: 2 }}>
                    •
                  </Box>
                  <ConfirmedTime
                    confirmedTime={row.original.confirmedEndTime}
                    plannedTime={endTime}
                  />
                </>
              )}
              {row.original.forecastedEndTime &&
                !row.original.confirmedEndTime && (
                  <>
                    <Box component="span" sx={{ mx: 2 }}>
                      •
                    </Box>
                    <ForecastedTime
                      forecastedTime={row.original.forecastedEndTime}
                    />
                  </>
                )}
            </Stack>
            <JobTypeGuard jobType={jobType}>
              <JobLocationCell
                location={destination}
                showFullAddress={false}
                // JobTypeGuard will filter out BREAKs and EMPTY_RUNs so it's safe to type cast to OptimizedTourJob['jobType']
                jobType={row.original.jobType as OptimizedTourJob['jobType']}
                locationType="destination"
                supplierName={row.original.supplier.supplierName}
              />
            </JobTypeGuard>
          </Stack>
        );
      },
      size: 120,
      enableSorting: false,
    }),
    columnHelper.accessor('duration', {
      header: () => t('duration'),
      footer: (props) => props.column.id,
      cell: ({ row }) => {
        const startTime = row.original.startTime;
        const endTime = row.original.endTime;
        return startTime && endTime ? (
          <Stack direction="row">
            <Typography variant="inherit">
              {formatIntervalDuration(startTime, endTime)}
            </Typography>
            {row.original.confirmedEndTime &&
              row.original.confirmedStartTime && (
                <>
                  <Box component="span" sx={{ mx: 2 }}>
                    •
                  </Box>
                  <ConfirmedDuration
                    confirmedStartTime={row.original.confirmedStartTime}
                    confirmedEndTime={row.original.confirmedEndTime}
                    plannedDuration={Math.abs(
                      differenceInSeconds(
                        new Date(row.original.startTime),
                        new Date(row.original.endTime)
                      )
                    )}
                  />
                </>
              )}
          </Stack>
        ) : (
          <Typography>---</Typography>
        );
      },
      size: 120,
      enableSorting: false,
    }),
    columnHelper.accessor(
      (row) => `${row.supplier.supplierId} ${row.supplier.supplierName}`,
      {
        id: 'supplier',
        header: () => t('supplier'),
        footer: (props) => props.column.id,
        size: 150,
        enableSorting: false,
        cell: ({ row }) => {
          const { jobType } = row.original;

          return (
            <JobTypeGuard jobType={jobType}>
              <Stack gap={2}>
                <Typography variant="inherit">
                  {row.original.supplier.supplierName}
                </Typography>
                <Typography variant="inherit">
                  {removePlantIdPrefix(row.original.supplier.supplierId)}
                </Typography>
              </Stack>
            </JobTypeGuard>
          );
        },
      }
    ),
    columnHelper.accessor('product.name', {
      id: 'product',
      header: () => t('productType'),
      footer: (props) => props.column.id,
      size: 120,
      enableSorting: false,
      cell: ({ row }) => (
        <JobTypeGuard jobType={row.original.jobType}>
          <Stack direction="column" alignItems="start">
            <Typography variant="inherit">
              {row.original.product ? row.original.product.name : '---'}
            </Typography>
          </Stack>
        </JobTypeGuard>
      ),
      enableGlobalFilter: false,
    }),
    columnHelper.accessor('product.quantity', {
      id: 'quantity',
      header: () => t('quantity'),
      footer: (props) => props.column.id,
      size: 0,
      cell: ({ row }) => {
        return (
          <JobTypeGuard jobType={row.original.jobType}>
            <Typography sx={{ maxWidth: 35, textAlign: 'right' }}>
              {row.original.product
                ? row.original.product.quantity.toFixed(2)
                : '---'}
            </Typography>
          </JobTypeGuard>
        );
      },
      filterFn: 'arrIncludesSome',
      enableSorting: false,
    }),
  ];

  const tableData = useMemo(() => {
    return forecastJobs(
      mapTourJobsToTourDetail(tourJobs).sort((a, b) => {
        if (a.startTime === null || b.startTime === null) {
          return 0;
        }
        return compareAsc(new Date(a.startTime), new Date(b.startTime));
      })
    );
  }, [tourJobs]);

  const { instance, allRows: rows } = useESTableBasic(tableData, columns, {
    fitRowHeight: ROW_HEIGHT,
    hiddenColumnsIds,
  });

  return (
    <ESTableWrapper>
      <ESTable>
        <ESTableHead instance={instance} />
        <ESTableBodyCompose>
          {rows.map((row) => {
            const isProcess =
              row.original.jobType === 'BREAK' ||
              row.original.jobType === 'EMPTY_RUN';
            const rowBackgroundColor = isProcess ? 'grey.50' : '';
            const shouldHideRow =
              row.original.jobType === 'EMPTY_RUN' &&
              tableViewMode === 'compact';
            return (
              <React.Fragment key={row.id}>
                <ESTableBodyRow
                  sx={{
                    '&.MuiTableRow-root': {
                      height: ROW_HEIGHT,
                      backgroundColor: rowBackgroundColor,
                      display: shouldHideRow ? 'none' : undefined,
                    },
                  }}
                  onRowClick={
                    isProcess
                      ? undefined
                      : () => {
                          row.toggleExpanded();
                        }
                  }
                >
                  {row.getVisibleCells().map((cell) => {
                    return (
                      <ESTableBodyCell
                        sx={{
                          '&': {
                            borderColor: row.getIsExpanded()
                              ? 'transparent'
                              : undefined,
                            width: cell.column.getSize(),
                            whiteSpace: 'nowrap',
                          },
                        }}
                        key={cell.id}
                      >
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </ESTableBodyCell>
                    );
                  })}
                </ESTableBodyRow>
                <JobTypeGuard jobType={row.original.jobType}>
                  <JobDetailsRow
                    backgroundColor={rowBackgroundColor}
                    expanded={row.getIsExpanded()}
                    colSpan={instance.getAllColumns().length}
                    job={row.original}
                  />
                </JobTypeGuard>
              </React.Fragment>
            );
          })}
        </ESTableBodyCompose>
      </ESTable>
      {rows.length === 0 ? <NoTableData message={t('thereAreNoJobs')} /> : null}
    </ESTableWrapper>
  );
};
