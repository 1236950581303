export enum OperationalStatusEnum {
  available = 'available',
  blocked = 'blocked',
  charging = 'charging',
  outOfOrder = 'outOfOrder',
  unknown = 'unknown',
}

export type OperationalStatus =
  | 'available'
  | 'blocked'
  | 'charging'
  | 'outOfOrder'
  | 'unknown';
