import {
  ESDialogActionButton,
  ESDialogActions,
  ESDialogContent,
  ESTextField,
  ESVirtualizedAutocomplete,
  showSnackbar,
} from '@energy-stacks/core/ui';
import { Box, Grid } from '@mui/material';
import { Controller, SubmitHandler, useForm, useWatch } from 'react-hook-form';
import { CentralSystemField } from '../shared/CentralSystemField';
import {
  ConfirmCloseDialog,
  EditableMap,
  useConfirmCloseDialog,
} from '@energy-stacks/shared';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
  brokerLocationsApi,
  useGetAllLocationsBrokerQuery,
} from '@energy-stacks/broker/feature-locations-data';
import {
  BrokerEditChargingStationFormData,
  chargingStationApiErrors,
  useEditChargingStationMutation,
} from '@energy-stacks/broker/feature-charging-stations-data';
import { brokerEditChargePointFormValidationSchema } from './brokerEditChargePointFormValidationSchema';
import { yupResolver } from '@hookform/resolvers/yup';
import { getLocationFullAddress } from '../getLocationFullAddress';
import { useAppDispatch } from '@energy-stacks/broker/store';
import { useGetUiBrandingQuery } from '@energy-stacks/broker/feature-settings-data';

type LocationOption = {
  label: string;
  value: string;
  description: string;
};

interface EditGeneralDetailsProps {
  defaultValues: BrokerEditChargingStationFormData;
}

export const EditGeneralDetails: React.FC<EditGeneralDetailsProps> = ({
  defaultValues,
}) => {
  const [isMapReady, setIsMapReady] = useState(false);
  const [t] = useTranslation('chargingStations');
  const [tLocations] = useTranslation('locations');
  const [tShared] = useTranslation('shared');
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const {
    data: locations,
    isLoading: isFetchingLocations,
    isError: isLocationError,
  } = useGetAllLocationsBrokerQuery();
  const { data: uiBranding } = useGetUiBrandingQuery();
  const hasBorderRadius =
    uiBranding?.hasBorderRadius || uiBranding?.hasBorderRadius === undefined;

  const locationOptions = useMemo(
    () =>
      locations?.map((loc) => ({
        label: loc.name,
        value: loc.uuid,
        description: getLocationFullAddress(loc, tLocations),
      })) || [],
    [locations, tLocations]
  );

  const [editChargingStation, { isLoading: isEditing }] =
    useEditChargingStationMutation();

  const {
    control,
    handleSubmit,
    formState: { isValid, errors, isDirty: isFormDirty },
    reset: resetForm,
    setValue,
    trigger,
  } = useForm<BrokerEditChargingStationFormData>({
    resolver: yupResolver(brokerEditChargePointFormValidationSchema),
    mode: 'onTouched',
    defaultValues,
  });

  const watchLongitude = useWatch({ name: 'coordinates.longitude', control });
  const watchLatitude = useWatch({ name: 'coordinates.latitude', control });

  const {
    isConfirmCloseDialogOpen,
    handleOpenConfirmDialog,
    handleStay,
    handleLeaveWithoutSaving,
  } = useConfirmCloseDialog(isFormDirty);

  const handleCoordsChange = (
    newCoords: BrokerEditChargingStationFormData['coordinates']
  ) => {
    setValue('coordinates.latitude', newCoords.latitude.slice(0, 10), {
      shouldValidate: true,
      shouldDirty: true,
    });
    setValue('coordinates.longitude', newCoords.longitude.slice(0, 11), {
      shouldValidate: true,
      shouldDirty: true,
    });
  };

  const handleClose = useCallback(() => {
    navigate(-1);
    resetForm(defaultValues);
  }, [resetForm, navigate, defaultValues]);

  const onSubmit: SubmitHandler<BrokerEditChargingStationFormData> = (data) => {
    editChargingStation({
      identityKey: data.identityKey,
      chargingStationName: data.chargePointName || null,
      csmsUuid: data.centralSystem.value,
      coordinates:
        data.coordinates.longitude !== '' && data.coordinates.latitude !== ''
          ? data.coordinates
          : null,
      locationUuid: data.location.value || null,
    })
      .unwrap()
      .then(() => {
        // We need to manually invalidate cache for locations in order to
        // have a prepopulated field while editing
        dispatch(brokerLocationsApi.util.invalidateTags(['BrokerLocations']));
        showSnackbar(
          'success',
          'editChargingStationSuccess',
          'chargingStations'
        );
        handleClose();
      })
      .catch((error) => {
        showSnackbar(
          'error',
          chargingStationApiErrors[error.data?.errorCode],
          'chargingStations'
        );
      });
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <ESDialogContent sx={{ flexGrow: 1 }}>
          <Grid container spacing={6}>
            <Grid item xs={6}>
              <Grid container spacing={6}>
                <>
                  <Grid item xs={12}>
                    <Controller
                      control={control}
                      rules={{ required: true }}
                      render={({ field: { value } }) => {
                        return (
                          <ESTextField
                            inputProps={{
                              'data-testid': `editChargingStationIdInput`,
                            }}
                            label={t('identityKey')}
                            value={value}
                            type="text"
                            disabled
                          />
                        );
                      }}
                      name={'identityKey'}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Controller
                      control={control}
                      rules={{ required: true }}
                      render={({ field: { value, onChange, onBlur } }) => {
                        return (
                          <ESTextField
                            inputProps={{
                              'data-testid': `editChargingStationNameInput`,
                            }}
                            label={t('chargingStationName')}
                            value={value}
                            type="text"
                            onChange={onChange}
                            onBlur={onBlur}
                            error={Boolean(errors.chargePointName)}
                            helperText={
                              Boolean(errors.chargePointName) &&
                              t(`${errors.chargePointName?.message}`)
                            }
                          />
                        );
                      }}
                      name={'chargePointName'}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Controller
                      control={control}
                      rules={{ required: true }}
                      render={({ field: { value, onChange, onBlur } }) => (
                        <CentralSystemField
                          testId="editCentralSystem"
                          value={value}
                          onChange={onChange}
                          onBlur={onBlur}
                          error={Boolean(errors.centralSystem)}
                          helperText={
                            Boolean(errors.centralSystem) &&
                            t(`${errors.centralSystem?.value?.message}`)
                          }
                        />
                      )}
                      name={'centralSystem'}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Controller
                      control={control}
                      render={({ field: { value, onChange, onBlur } }) => (
                        <ESVirtualizedAutocomplete<LocationOption>
                          testId="editChargingSite"
                          label={t('locationFieldLabel')}
                          loading={isFetchingLocations || isLocationError}
                          value={{
                            label: value?.label || '',
                            value: value?.value || '',
                            description: value?.description || '',
                          }}
                          options={locationOptions}
                          onBlur={onBlur}
                          onChange={(_, value) => {
                            if (Array.isArray(value)) {
                              return;
                            }
                            const newValue = value
                              ? {
                                  label: value.label,
                                  value: value.value,
                                  description: value.description,
                                }
                              : { label: '', value: '', description: '' };
                            onChange(newValue);
                            if (value?.value) {
                              const chosenLocation = locations?.find(
                                (loc) => loc.uuid === value.value
                              );
                              handleCoordsChange(
                                chosenLocation?.coordinates ?? {
                                  latitude: '',
                                  longitude: '',
                                }
                              );
                            } else {
                              handleCoordsChange({
                                latitude: '',
                                longitude: '',
                              });
                            }
                          }}
                        />
                      )}
                      name={'location'}
                    />
                  </Grid>
                </>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Grid container spacing={6}>
                <Grid item xs={12}>
                  <Box height={370}>
                    <EditableMap
                      hasBorderRadius={hasBorderRadius}
                      center={{
                        longitude: watchLongitude || '15',
                        latitude: watchLatitude || '52',
                      }}
                      zoom={
                        watchLatitude === '' || watchLongitude === '' ? 3 : 13
                      }
                      onMapReady={() => setIsMapReady(true)}
                      onCoordsChange={handleCoordsChange}
                    />
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Controller
                    control={control}
                    render={({ field: { value, onChange, onBlur } }) => {
                      return (
                        <ESTextField
                          inputProps={{
                            'data-testid': `longitudeFieldInput`,
                          }}
                          value={value}
                          label={t('locationDialogLongitudeLabel')}
                          type="text"
                          onChange={(event) => {
                            const isValid =
                              !isNaN(Number(event?.target.value)) ||
                              event?.target.value === '-';
                            if (event === undefined || isValid) {
                              trigger('coordinates.latitude');
                              onChange(event);
                            }
                          }}
                          onBlur={onBlur}
                          error={Boolean(errors.coordinates?.longitude)}
                          helperText={
                            Boolean(errors.coordinates?.longitude) &&
                            t(`${errors.coordinates?.longitude?.message}`)
                          }
                        />
                      );
                    }}
                    name={'coordinates.longitude'}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Controller
                    control={control}
                    render={({ field: { value, onChange, onBlur } }) => {
                      return (
                        <ESTextField
                          inputProps={{
                            'data-testid': `latitudeFieldInput`,
                          }}
                          value={value}
                          label={t('locationDialogLatitudeLabel')}
                          type="text"
                          onChange={(event) => {
                            const isValid =
                              !isNaN(Number(event?.target.value)) ||
                              event?.target.value === '-';
                            if (event === undefined || isValid) {
                              trigger('coordinates.longitude');
                              onChange(event);
                            }
                          }}
                          onBlur={onBlur}
                          error={Boolean(errors.coordinates?.latitude)}
                          helperText={
                            Boolean(errors.coordinates?.latitude) &&
                            t(`${errors.coordinates?.latitude?.message}`)
                          }
                        />
                      );
                    }}
                    name={'coordinates.latitude'}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </ESDialogContent>
        <ESDialogActions>
          <ESDialogActionButton
            data-testid="editChargingStationCancelButton"
            color="error"
            onClick={handleOpenConfirmDialog}
            disabled={isEditing}
          >
            {tShared('cancel')}
          </ESDialogActionButton>
          <ESDialogActionButton
            data-testid="editChargingStationSaveButton"
            type="submit"
            disabled={!isValid || !isMapReady || isEditing || !isFormDirty}
            loading={isEditing}
            variant="contained"
          >
            {tShared('save')}
          </ESDialogActionButton>
        </ESDialogActions>
      </form>

      <ConfirmCloseDialog
        testId="editChargingStation"
        open={isConfirmCloseDialogOpen}
        onStay={handleStay}
        onLeaveWithoutSaving={handleLeaveWithoutSaving}
      />
    </>
  );
};
