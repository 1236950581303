import { ReactNode, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';

interface PortalTargetProps {
  targetId: string;
  children: ReactNode;
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const PortalTarget: React.FC<PortalTargetProps> = ({
  targetId,
  children,
}) => {
  const [targetContainer, setTargetContainer] = useState<HTMLElement | null>(
    null
  );

  useEffect(() => {
    let target: HTMLElement | null = null;
    const modalElement = document.querySelector(`.MuiDialog-root`);
    if (modalElement) {
      target = modalElement.querySelector(`#${targetId}`);
    } else {
      target = document.getElementById(targetId);
    }
    setTargetContainer(target);
  }, [targetId]);

  return targetContainer && createPortal(<>{children}</>, targetContainer);
};
