import { ServerEventSource } from './ServerEventSource';
import { ChargingStationEventSource } from './ChargingStationEventSource';
import { ArrowForwardRounded } from '@mui/icons-material';
import { Box, Stack } from '@mui/material';

export const ServerToChargingStation = () => {
  return (
    <Stack direction={'row'} alignItems="center" spacing={4}>
      <Box>
        <ServerEventSource />

        {/* hidden element to set the width so the Chips align */}
        {/* it's here for the case the width changes because of a different language */}
        <Box sx={{ visibility: 'hidden', height: 0 }}>
          <ChargingStationEventSource />
        </Box>
      </Box>

      <ArrowForwardRounded
        sx={{ color: (theme) => theme.palette.action.active }}
      />
      <ChargingStationEventSource />
    </Stack>
  );
};
