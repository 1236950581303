import { ESPaper } from '@energy-stacks/core/ui';
import { Grid } from '@mui/material';
import { APP_BAR_HEIGHT } from './AppBar';

const TOP_OFFSET = `${APP_BAR_HEIGHT}px`;

interface MainProps {
  children: React.ReactNode;
}

export const Main: React.FC<MainProps> = ({ children }) => {
  return (
    <ESPaper
      square
      sx={{
        mt: TOP_OFFSET,
      }}
    >
      <Grid
        sx={{
          height: `calc(100vh - ${TOP_OFFSET})`,
        }}
      >
        {children}
      </Grid>
    </ESPaper>
  );
};
