import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import MuiStep from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { styled, Typography } from '@mui/material';
import { FormStepperModel } from './formStep';
import { useTranslation } from 'react-i18next';

interface FormStepperProps {
  activeStep: number;
  formSteps: FormStepperModel<string>;
  fullWidth?: boolean;
}

export const FormStepper = ({
  activeStep,
  formSteps,
  fullWidth = false,
}: FormStepperProps) => {
  const [t] = useTranslation('shared');

  return (
    <Box sx={{ width: fullWidth ? '100%' : '70%', mx: 'auto' }}>
      <Box sx={{ mx: '-10px' }}>
        <Stepper activeStep={activeStep}>
          {Object.values(formSteps).map((step) => {
            const labelProps: {
              optional?: React.ReactNode;
            } = {};
            if (step.optional) {
              labelProps.optional = (
                <Typography fontSize={12}>{t('optional')}</Typography>
              );
            }
            return (
              <Step key={step.name}>
                <StepLabel {...labelProps}>{step.name}</StepLabel>
              </Step>
            );
          })}
        </Stepper>
      </Box>
    </Box>
  );
};

const Step = styled(MuiStep)(
  ({ theme }) => `
  & .MuiStepIcon-root.Mui-completed {
    color: ${theme.palette.success.main};
  })
`
);
