import { Box } from '@mui/material';
import { PropsWithChildren } from 'react';

export const ESTableFooter: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <Box
      sx={{
        px: 4,
        py: 3.25,
      }}
    >
      {children}
    </Box>
  );
};
