import { AlertStatus } from '@energy-stacks/broker/feature-alert-management-data';
import { DateRange } from '@energy-stacks/core/date-range-picker';
import { usePagination, useSort } from '@energy-stacks/core/ui';
import { ESPageLoadingIndicator, RefetchOnError } from '@energy-stacks/shared';
import { useState } from 'react';
import { ChargingStationAlertsTable } from './ChargingStationAlertsTable';
import { useParams } from 'react-router-dom';
import { useGetAlertsPerChargingStation } from '@energy-stacks/broker/feature-alert-management';

const PRIMARY_SORT = 'dateCreated';

export const ChargingStationDetailsAlertsTab = () => {
  const { identityKey } = useParams<{
    identityKey: string;
  }>();
  const { sorting, setSorting, sortOrder } = useSort();
  const { pagination, setPagination } = usePagination();
  const [alertStatus, setAlertStatus] = useState<AlertStatus>();
  const [dateRange, setDateRange] = useState<DateRange | undefined>(undefined);
  const { alerts, isLoading, isError, isFetching, refetch } =
    useGetAlertsPerChargingStation({
      identityKey: identityKey || '',
      alertStatus: alertStatus,
      filterDate: {
        dateFrom: dateRange?.startDate,
        dateTo: dateRange?.endDate,
      },
      pagination: {
        page: pagination.pageIndex,
        size: pagination.pageSize,
      },
      sort: { id: sorting[0] ? sorting[0].id : PRIMARY_SORT, order: sortOrder },
    });
  return (
    <>
      {isLoading ? <ESPageLoadingIndicator /> : null}
      {isError ? <RefetchOnError onRefetch={refetch} /> : null}
      {!isLoading && !isError && (
        <ChargingStationAlertsTable
          identityKey={identityKey || ''}
          testId="chargingStationAlerts"
          alerts={alerts}
          onPaginationChange={setPagination}
          sorting={sorting}
          pagination={pagination}
          onSortingChange={setSorting}
          sortOrder={sortOrder}
          alertStatusFilter={alertStatus}
          onAlertStatusFilterChange={setAlertStatus}
          dateRangeFilter={dateRange}
          onDateRangeFilterChange={setDateRange}
          isFetching={isFetching}
          onRefetch={refetch}
        />
      )}
    </>
  );
};
