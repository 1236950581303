import { WorkingHoursConfig } from '../locationDetailsModel';
import { RegularHoursModel } from '../regularHoursModel';

export const workingHoursConfigNormalizer = (
  regularHours: RegularHoursModel[]
): WorkingHoursConfig => {
  const begin = regularHours?.[0].periodBegin;
  const end = regularHours?.[0].periodEnd;

  const filteredRegularHours = regularHours?.filter(
    (hours) => Boolean(hours.periodBegin) ?? []
  );

  if (filteredRegularHours && filteredRegularHours.length <= 1) {
    return WorkingHoursConfig.differentTime;
  }
  if (
    filteredRegularHours.some(
      (day) => day.periodBegin !== begin || day.periodEnd !== end
    )
  ) {
    return WorkingHoursConfig.differentTime;
  }
  return WorkingHoursConfig.sameTime;
};
