import {
  Alert,
  AlertStatus,
  useGetAlertsQuery,
} from '@energy-stacks/broker/feature-alert-management-data';
import {
  BackendSortingOrderModel,
  DEFAULT_PAGINATION_CONFIG,
  DEFAULT_SORT_CONFIG,
} from '@energy-stacks/core/ui';
import { toPayloadDateBroker } from '@energy-stacks/shared';

export interface GetAlertsConfig {
  identityKey?: Alert['identityKey'];
  alertStatus?: AlertStatus;
  filterDate?: {
    dateFrom?: Date;
    dateTo?: Date;
  };
  pagination: {
    page: number;
    size: number;
  };
  sort: BackendSortingOrderModel;
}

export const useGetAlerts = (config?: GetAlertsConfig) => {
  const {
    data: alerts,
    isSuccess,
    isLoading,
    isError,
    isFetching,
    refetch,
  } = useGetAlertsQuery(
    {
      identityKey: config?.identityKey || undefined,
      alertStatus: config?.alertStatus || undefined,
      dateFrom: config?.filterDate?.dateFrom
        ? toPayloadDateBroker(config?.filterDate.dateFrom)
        : undefined,
      dateTo: config?.filterDate?.dateTo
        ? toPayloadDateBroker(config?.filterDate.dateTo)
        : undefined,
      page: config?.pagination?.page ?? DEFAULT_PAGINATION_CONFIG.page,
      size: config?.pagination?.size ?? DEFAULT_PAGINATION_CONFIG.size,
      sort: {
        id: config?.sort.id ?? '',
        order: config?.sort.order ?? DEFAULT_SORT_CONFIG.order,
      },
    },
    { refetchOnMountOrArgChange: true }
  );
  return { alerts, isSuccess, isError, isLoading, isFetching, refetch };
};
