import { CloseRounded } from '@mui/icons-material';
import { IconButton, InputAdornment, TextField } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

interface ConfigurationIntegerValueFieldProps {
  handleValueChange: (val: string) => void;
  controlerValue: string;
  minLimit: number;
  maxLimit: number;
}
export const ConfigurationIntegerValueField: React.FC<
  ConfigurationIntegerValueFieldProps
> = ({ handleValueChange, controlerValue, minLimit, maxLimit, ...rest }) => {
  const { t } = useTranslation('chargingStations');
  const [helperText, setHelperText] = useState('');

  return (
    <TextField
      onFocus={() => {
        if (maxLimit !== 0) {
          setHelperText(
            t('rangeOfValues', {
              minLimit: minLimit,
              maxLimit: maxLimit,
            })
          );
        }
      }}
      onBlur={() => {
        setHelperText('');
      }}
      variant="outlined"
      value={controlerValue}
      placeholder={controlerValue || '-'}
      fullWidth
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              edge="end"
              aria-label="clear"
              sx={{ visibility: controlerValue ? 'visible' : 'hidden' }}
              onClick={() => {
                handleValueChange('');
              }}
            >
              <CloseRounded />
            </IconButton>
          </InputAdornment>
        ),
      }}
      onChange={(e) => {
        const isNum = !isNaN(Number(e.target.value));
        const newValue = e.target.value;
        if (isNum) {
          handleValueChange(newValue);
        }
      }}
      helperText={helperText}
      sx={{ '& .MuiFormHelperText-root': { marginLeft: 0 } }}
      {...rest}
    />
  );
};
