export enum ConfigurationKeyStatus {
  Idle,
  Loading,
  Rejected,
  Accepted,
  RebootRequired,
  NotSupported,
  RequestTimeout,
}

// change order of key statuses to configure sort order, more important on top
const sortOrder: ConfigurationKeyStatus[] = [
  ConfigurationKeyStatus.RequestTimeout,
  ConfigurationKeyStatus.RebootRequired,
  ConfigurationKeyStatus.Rejected,
  ConfigurationKeyStatus.NotSupported,
  ConfigurationKeyStatus.Accepted,
  ConfigurationKeyStatus.Loading,
  ConfigurationKeyStatus.Idle,
];

export const configurationKeyStatusSorter = (
  statusA: ConfigurationKeyStatus | undefined = 0,
  statusB: ConfigurationKeyStatus | undefined = 0
) => {
  return sortOrder.indexOf(statusA) - sortOrder.indexOf(statusB);
};
