import { ESButton } from '@energy-stacks/core/ui';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as NotFountImage } from '../assets/notFound.svg';

interface NotFoundProps {
  subtitleText?: string;
  buttonText?: string;
  navigateLink: string;
}

export const NotFound: React.FC<NotFoundProps> = ({
  subtitleText = 'notFoundSubtitle',
  buttonText = 'notFoundAction',
  navigateLink,
}) => {
  const [t] = useTranslation('shared');
  const navigate = useNavigate();
  return (
    <Box textAlign="center" mt={40}>
      <NotFountImage />
      <Typography variant="h3" mb={3}>
        {t('notFoundTitle')}
      </Typography>
      <Typography variant="subtitle1" color="text.secondary" mb={11}>
        {t(subtitleText)}
      </Typography>
      <ESButton onClick={() => navigate(navigateLink)}>
        {t(buttonText)}
      </ESButton>
    </Box>
  );
};
