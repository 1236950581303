import { createColumnHelper } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';
import {
  ESTableFilters,
  useESTableBasic,
  ESTableBasicConfig,
  arrEqualsSome,
  ESTableWrapper,
  ESVirtualizedTable,
  useElementScrollRestoration,
} from '@energy-stacks/core/ui';
import {
  ClearFiltersButton,
  ESTooltip,
  NoTableData,
  TableSearchField,
  TableColumnFilter,
  TableColumnSelect,
  formatDate,
  TableRowsCount,
  TableDateRangeFilter,
} from '@energy-stacks/shared';
import { Box, Stack, Typography } from '@mui/material';
import { JobStatusChip } from '@energy-stacks/fleet/shared';
import { isEqual } from 'date-fns';
import { DateRange } from '@energy-stacks/core/date-range-picker';
import {
  JobModel,
  jobStatusFilterOptions,
  jobTypes,
} from '@energy-stacks/fleet/feature-jobs-data';
import { JobTypeIcon } from './JobTypeIcon';
import { JobQuantityCell } from './JobQuantityCell';
import { JobDueDateCell } from './JobDueDateCell';
import { sortByQuantity } from './sortByQuantity';
import { JobLocationCell } from './JobLocationCell';
import { jobsTableDueDateFilterFn } from './jobsTableDueDateFilterFn';
import { useRemovePlantIdPrefix } from '@energy-stacks/fleet/feature-business-accounts';
import { useMemo } from 'react';

interface JobsTableProps extends ESTableBasicConfig<JobModel> {
  // Used as key for storing column visibility state in local storage.
  // Needed for distinction in case multiple instances of JobsTable component are created
  jobs: JobModel[];
  enableColumnSelection?: boolean;
  defaultDateRange?: DateRange | undefined;
  dateRange?: DateRange | undefined;
  preselectedJobStatusFilters?: JobModel['status'][];
  onDateRangeChange?: (dateRange: DateRange | undefined) => void;
  scrollRestorationId?: string;
}

const ROW_HEIGHT = 90;

export const JobsTable: React.FC<JobsTableProps> = ({
  tableId = 'jobs',
  jobs,
  enableRowSelection,
  defaultRowSelection,
  onSelectionChange,
  enableColumnSelection = false,
  dateRange,
  preselectedJobStatusFilters,
  defaultDateRange,
  onDateRangeChange,
  hiddenColumnsIds = [],
  scrollRestorationId,
}) => {
  const [t] = useTranslation('jobs');
  const [tFleetShared] = useTranslation('fleetShared');
  const [tShared] = useTranslation('shared');
  const columnHelper = createColumnHelper<JobModel>();
  const removePlantIdPrefix = useRemovePlantIdPrefix();

  const columns = useMemo(
    () => [
      columnHelper.accessor((row) => removePlantIdPrefix(row.jobId), {
        id: 'jobId',
        sortingFn: 'alphanumeric',
        header: () => t('jobId'),
        footer: (props) => props.column.id,
        cell: (info) => removePlantIdPrefix(info.getValue()),
        enableGlobalFilter: true,
        enableResizing: false,
        size: 120,
      }),
      columnHelper.accessor(
        (row) =>
          `${removePlantIdPrefix(row.supplier.supplierId)} ${
            row.supplier.supplierName
          }`,
        {
          id: 'supplier',
          header: () => t('supplier'),
          footer: (props) => props.column.id,
          size: 200,
          cell: ({ row }) => {
            return (
              <Stack direction="column">
                <Typography variant="inherit">
                  {removePlantIdPrefix(row.original.supplier.supplierId)}
                </Typography>
                <Typography variant="inherit">
                  {row.original.supplier.supplierName}
                </Typography>
              </Stack>
            );
          },
          enableGlobalFilter: true,
        }
      ),
      columnHelper.accessor((row) => row.origin?.id, {
        id: 'origin',
        header: () => t('origin'),
        footer: (props) => props.column.id,
        size: 250,
        cell: ({ row, column }) => (
          <JobLocationCell
            jobType={row.original.jobType}
            locationType="origin"
            location={row.original.origin}
            supplierName={row.original.supplier.supplierName}
          />
        ),
        enableGlobalFilter: true,
      }),
      columnHelper.accessor((row) => row.destination?.id, {
        id: 'destination',
        header: () => t('destination'),
        footer: (props) => props.column.id,
        size: 250,
        cell: ({ row, column }) => (
          <JobLocationCell
            jobType={row.original.jobType}
            locationType="destination"
            location={row.original.destination}
            supplierName={row.original.supplier.supplierName}
          />
        ),
        enableGlobalFilter: true,
      }),
      columnHelper.accessor((row) => row.jobType, {
        id: 'jobType',
        header: () => t('quantity'),
        footer: (props) => props.column.id,
        cell: ({ row }) => (
          <JobQuantityCell
            jobType={row.original.jobType}
            quantity={row.original.product.quantity}
          />
        ),
        sortingFn: sortByQuantity,
        enableGlobalFilter: false,
        filterFn: arrEqualsSome,
        enableResizing: false,
      }),
      columnHelper.accessor((row) => row.product?.name, {
        id: 'product',
        header: () => t('productType'),
        footer: (props) => props.column.id,
        size: 200,
        cell: ({ row }) => (
          <Typography variant="inherit">{row.original.product.name}</Typography>
        ),
        enableGlobalFilter: false,
        enableResizing: false,
        enableSorting: false,
      }),
      columnHelper.accessor('startDate', {
        header: () => t('startTime'),
        footer: (props) => props.column.id,
        cell: (info) => {
          if (!info.getValue()) {
            return <Typography>---</Typography>;
          }
          return (
            <Stack gap={2}>
              <Typography fontWeight={500} color="grey.900">
                {formatDate(info.getValue(), 'HH:mm').toLowerCase()}
              </Typography>
            </Stack>
          );
        },
        size: 150,
        enableResizing: false,
        enableSorting: false,
      }),
      columnHelper.accessor('endDate', {
        header: () => t('endTime'),
        footer: (props) => props.column.id,
        cell: (info) => {
          if (!info.getValue()) {
            return <Typography>---</Typography>;
          }
          return (
            <Stack gap={2}>
              <Stack gap={2}>
                <Typography fontWeight={500} color="grey.900">
                  {formatDate(info.getValue(), 'HH:mm').toLowerCase()}
                </Typography>
              </Stack>
            </Stack>
          );
        },
        enableResizing: false,
        size: 150,
        enableSorting: false,
      }),
      columnHelper.accessor('dueDate', {
        header: () => t('dueDate'),
        footer: (props) => props.column.id,
        cell: (info) => <JobDueDateCell dueDate={info.getValue()} />,
        meta: {
          backgroundColor: 'grey.50',
        },
        enableResizing: false,
        enableColumnFilter: true,
        filterFn: jobsTableDueDateFilterFn,
        enableGlobalFilter: false,
      }),
      columnHelper.accessor('status', {
        header: () => t('status'),
        footer: (props) => props.column.id,
        cell: (info) => <JobStatusChip status={info.getValue()} />,
        filterFn: 'arrIncludesSome',
        enableSorting: false,
      }),
    ],
    [columnHelper, removePlantIdPrefix, t]
  );

  const {
    instance,
    rows,
    globalFilter,
    onGlobalFilterChange,
    handleSelectRow,
  } = useESTableBasic(jobs, columns, {
    tableId,
    enableRowSelection,
    onSelectionChange,
    defaultRowSelection,
    fitRowHeight: ROW_HEIGHT,
    getRowId: (row) => row.jobId,
    initialSorting: [{ id: 'dueDate', desc: false }],
    initialColumnFilters: defaultDateRange
      ? [{ id: 'dueDate', value: defaultDateRange }]
      : undefined,
    hiddenColumnsIds,
    manualPagination: true,
    enableColumnResizing: true,
  });

  const scrollCache = useElementScrollRestoration(
    scrollRestorationId
      ? {
          id: scrollRestorationId,
        }
      : undefined
  );

  return (
    <>
      <ESTableFilters>
        <ESTooltip title={t('searchJobsPlaceholder')}>
          <TableSearchField
            placeholder={t('searchJobsPlaceholder')}
            value={globalFilter}
            onChange={onGlobalFilterChange}
            tableInstance={instance}
          />
        </ESTooltip>
        <TableDateRangeFilter
          title={tShared('timeRange')}
          column={instance.getColumn('dueDate')}
          defaultDateRange={defaultDateRange}
          dateRange={dateRange}
          onDateRangeChange={onDateRangeChange}
          onDateRangeCleared={() => {
            const column = instance.getColumn('dueDate');
            column?.setFilterValue(defaultDateRange);
            onDateRangeChange?.(defaultDateRange);
          }}
          weekStartsOn={1}
        />
        {!preselectedJobStatusFilters ? (
          <TableColumnFilter
            column={instance.getColumn('status')}
            columnLabel={t('status')}
            options={jobStatusFilterOptions.map((v) => ({
              label: tFleetShared(`jobStatuses.${v}`),
              value: v,
            }))}
            isClearable
          />
        ) : null}
        <TableColumnFilter
          column={instance.getColumn('jobType')}
          columnLabel={t('jobType')}
          options={jobTypes.map((v) => ({
            label: t(`jobTypes.${v}`),
            value: v,
            Icon: <JobTypeIcon jobType={v} />,
          }))}
        />
        <ClearFiltersButton
          tableInstance={instance}
          ignoreColumnIds={
            dateRange?.startDate &&
            dateRange?.endDate &&
            defaultDateRange?.startDate &&
            defaultDateRange?.endDate &&
            isEqual(dateRange.startDate, defaultDateRange.startDate) &&
            isEqual(dateRange.endDate, defaultDateRange.endDate)
              ? ['dueDate']
              : []
          }
          clearFilters={() => {
            instance.resetColumnFilters(); // Reset filters to default values
            onDateRangeChange?.(defaultDateRange);
          }}
        />
        {enableColumnSelection ? (
          <Box sx={{ marginLeft: 'auto' }}>
            <TableColumnSelect
              instance={instance}
              hiddenColumnsIds={hiddenColumnsIds}
            />
          </Box>
        ) : null}
      </ESTableFilters>
      <ESTableWrapper>
        <ESVirtualizedTable
          instance={instance}
          rows={rows}
          rowHeight={ROW_HEIGHT}
          onRowClick={handleSelectRow}
          scrollRestorationId={scrollRestorationId}
          initialScrollTop={scrollCache.scrollY}
        />
        {rows.length === 0 ? (
          <NoTableData message={t('thereAreNoJobs')} />
        ) : null}
        <TableRowsCount
          instance={instance}
          totalCountLabel={t('totalRowsCount')}
        />
      </ESTableWrapper>
    </>
  );
};
