import React from 'react';

export const NoToursSvg = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="207"
      height="181"
      viewBox="0 0 207 181"
      fill="none"
    >
      <path
        d="M92.658 69.3555H9.55798V130.655H92.658V69.3555Z"
        fill="#F5F9FF"
      />
      <path
        d="M92.658 69.3555H9.55798V77.0005H92.658V69.3555Z"
        fill="#E3F2FD"
      />
      <path
        d="M16.228 73.3555C16.228 73.7016 16.1253 74.0399 15.933 74.3277C15.7407 74.6155 15.4674 74.8398 15.1477 74.9723C14.8279 75.1047 14.476 75.1394 14.1366 75.0718C13.7971 75.0043 13.4853 74.8376 13.2405 74.5929C12.9958 74.3482 12.8291 74.0363 12.7616 73.6969C12.6941 73.3574 12.7287 73.0055 12.8612 72.6858C12.9936 72.366 13.2179 72.0927 13.5057 71.9004C13.7935 71.7081 14.1319 71.6055 14.478 71.6055C14.9421 71.6055 15.3872 71.7898 15.7154 72.118C16.0436 72.4462 16.228 72.8913 16.228 73.3555Z"
        fill="#F2F2F2"
      />
      <path
        d="M22.118 73.3555C22.118 73.7016 22.0153 74.0399 21.8231 74.3277C21.6308 74.6155 21.3574 74.8398 21.0377 74.9723C20.7179 75.1047 20.366 75.1394 20.0266 75.0718C19.6871 75.0043 19.3753 74.8376 19.1305 74.5929C18.8858 74.3482 18.7191 74.0363 18.6516 73.6969C18.5841 73.3574 18.6187 73.0055 18.7512 72.6858C18.8836 72.366 19.1079 72.0927 19.3957 71.9004C19.6835 71.7081 20.0219 71.6055 20.368 71.6055C20.8321 71.6055 21.2772 71.7898 21.6054 72.118C21.9336 72.4462 22.118 72.8913 22.118 73.3555Z"
        fill="#F2F2F2"
      />
      <path
        d="M28.008 73.3555C28.008 73.7016 27.9054 74.0399 27.7131 74.3277C27.5208 74.6155 27.2475 74.8398 26.9277 74.9723C26.6079 75.1047 26.2561 75.1394 25.9166 75.0718C25.5771 75.0043 25.2653 74.8376 25.0206 74.5929C24.7758 74.3482 24.6091 74.0363 24.5416 73.6969C24.4741 73.3574 24.5088 73.0055 24.6412 72.6858C24.7737 72.366 24.998 72.0927 25.2857 71.9004C25.5735 71.7081 25.9119 71.6055 26.258 71.6055C26.7221 71.6055 27.1672 71.7898 27.4954 72.118C27.8236 72.4462 28.008 72.8913 28.008 73.3555Z"
        fill="#F2F2F2"
      />
      <path
        d="M65.293 93.6751L61.118 89.4951L51.108 99.5051L41.098 89.4951L36.918 93.6751L46.928 103.685L36.918 113.69L41.098 117.87L51.108 107.86L61.118 117.87L65.293 113.69L55.283 103.685L65.293 93.6751Z"
        fill="#E3F2FD"
      />
      <path
        d="M194.668 30.6904H111.568V91.9904H194.668V30.6904Z"
        fill="#F5F9FF"
      />
      <path
        d="M194.668 30.6904H111.568V38.3354H194.668V30.6904Z"
        fill="#E3F2FD"
      />
      <path
        d="M118.238 34.6904C118.238 35.0368 118.135 35.3753 117.943 35.6632C117.75 35.9511 117.477 36.1754 117.157 36.3077C116.836 36.44 116.484 36.4744 116.145 36.4064C115.805 36.3385 115.493 36.1713 115.249 35.9261C115.004 35.6809 114.838 35.3686 114.771 35.0288C114.704 34.689 114.739 34.337 114.873 34.0173C115.006 33.6976 115.231 33.4246 115.519 33.2329C115.808 33.0412 116.147 32.9394 116.493 32.9404C116.723 32.9404 116.95 32.9857 117.162 33.0737C117.374 33.1618 117.567 33.2907 117.729 33.4533C117.891 33.6159 118.019 33.8088 118.107 34.0211C118.194 34.2334 118.239 34.4609 118.238 34.6904Z"
        fill="#F2F2F2"
      />
      <path
        d="M124.133 34.6904C124.133 35.0365 124.03 35.3749 123.838 35.6627C123.646 35.9505 123.372 36.1748 123.053 36.3072C122.733 36.4397 122.381 36.4743 122.042 36.4068C121.702 36.3393 121.39 36.1726 121.146 35.9279C120.901 35.6831 120.734 35.3713 120.667 35.0318C120.599 34.6924 120.634 34.3405 120.766 34.0207C120.899 33.701 121.123 33.4277 121.411 33.2354C121.699 33.0431 122.037 32.9404 122.383 32.9404C122.847 32.9404 123.292 33.1248 123.62 33.453C123.949 33.7812 124.133 34.2263 124.133 34.6904Z"
        fill="#F2F2F2"
      />
      <path
        d="M130.008 34.6904C130.008 35.0365 129.905 35.3749 129.713 35.6627C129.521 35.9505 129.247 36.1748 128.928 36.3072C128.608 36.4397 128.256 36.4743 127.917 36.4068C127.577 36.3393 127.265 36.1726 127.021 35.9279C126.776 35.6831 126.609 35.3713 126.542 35.0318C126.474 34.6924 126.509 34.3405 126.641 34.0207C126.774 33.701 126.998 33.4277 127.286 33.2354C127.574 33.0431 127.912 32.9404 128.258 32.9404C128.722 32.9404 129.167 33.1248 129.495 33.453C129.824 33.7812 130.008 34.2263 130.008 34.6904Z"
        fill="#F2F2F2"
      />
      <path
        d="M167.308 55.01L163.128 50.835L153.118 60.84L143.108 50.835L138.933 55.01L148.943 65.02L138.933 75.03L143.108 79.205L153.118 69.2L163.128 79.205L167.308 75.03L157.298 65.02L167.308 55.01Z"
        fill="#E3F2FD"
      />
      <path
        d="M103.008 180.24C159.112 180.24 204.593 176.309 204.593 171.46C204.593 166.611 159.112 162.68 103.008 162.68C46.9041 162.68 1.42297 166.611 1.42297 171.46C1.42297 176.309 46.9041 180.24 103.008 180.24Z"
        fill="#EDF0FC"
      />
      <path
        d="M78.233 31.7099C77.8523 30.9788 77.664 30.1628 77.6858 29.3388C77.7077 28.5147 77.939 27.7098 78.358 26.9999L78.773 26.2749C79.0863 25.7939 79.3478 25.2811 79.553 24.7449C79.6635 24.3847 79.6294 23.9955 79.458 23.6599C79.018 22.7399 78.028 22.6599 76.488 23.3849C75.7408 23.7447 75.0406 24.1947 74.403 24.7249C73.7401 25.2707 73.1428 25.8916 72.623 26.5749L70.868 22.8999C72.088 21.4784 73.6009 20.3374 75.303 19.5549C77.2563 18.6249 79.0013 18.2916 80.538 18.5549C81.2668 18.6693 81.955 18.9657 82.5388 19.4167C83.1226 19.8677 83.5832 20.4587 83.878 21.1349C84.242 21.8178 84.3787 22.599 84.268 23.3649C84.0478 24.2691 83.6903 25.1341 83.208 25.9299C82.828 26.6399 82.553 27.1749 82.393 27.5349C82.2372 27.8683 82.157 28.232 82.158 28.5999C82.1705 28.9987 82.2713 29.3898 82.453 29.7449L82.668 30.1999L78.473 32.1999L78.233 31.7099ZM81.448 37.1849C80.8402 36.9589 80.344 36.5056 80.0641 35.9207C79.7843 35.3358 79.7426 34.665 79.948 34.0499C80.0596 33.7458 80.2313 33.4673 80.4529 33.2311C80.6746 32.9948 80.9416 32.8057 81.238 32.6749C81.5283 32.5304 81.8444 32.4449 82.1679 32.4234C82.4915 32.402 82.8161 32.445 83.123 32.5499C83.4287 32.6537 83.7107 32.8174 83.9523 33.0316C84.194 33.2457 84.3904 33.5059 84.5301 33.797C84.6698 34.0881 84.75 34.4041 84.766 34.7266C84.7819 35.0491 84.7333 35.3715 84.623 35.6749C84.5117 35.9803 84.3408 36.2604 84.1201 36.4991C83.8995 36.7377 83.6337 36.9301 83.338 37.0649C83.0481 37.2138 82.7307 37.3013 82.4055 37.3219C82.0803 37.3426 81.7543 37.2959 81.448 37.1849Z"
        fill="#E3F2FD"
      />
      <path
        d="M98.608 17.7051C99.0449 16.5453 99.7699 15.5158 100.715 14.7137C101.659 13.9115 102.793 13.3631 104.008 13.1201L105.238 12.8601C106.093 12.736 106.932 12.5163 107.738 12.2051C108.255 11.9715 108.659 11.544 108.863 11.0151C109.456 9.59509 108.566 8.38842 106.193 7.39509C105.04 6.91637 103.833 6.58054 102.598 6.39509C101.321 6.20085 100.026 6.15053 98.738 6.24509L101.113 0.620086C103.933 0.563954 106.732 1.11829 109.318 2.24509C112.318 3.50509 114.438 5.13342 115.678 7.13008C116.281 8.06219 116.64 9.13164 116.719 10.2392C116.799 11.3467 116.597 12.4563 116.133 13.4651C115.725 14.5534 114.98 15.4833 114.008 16.1201C112.771 16.7801 111.441 17.2471 110.063 17.5051C108.878 17.7901 108.008 18.0051 107.443 18.2001C106.912 18.3595 106.423 18.6325 106.008 19.0001C105.566 19.4188 105.225 19.9315 105.008 20.5001L104.718 21.1951L98.258 18.4901L98.608 17.7051ZM95.678 26.8051C95.492 26.3591 95.3992 25.8797 95.4052 25.3965C95.4112 24.9132 95.516 24.4363 95.713 23.9951C95.8889 23.5409 96.1576 23.1284 96.502 22.7841C96.8464 22.4397 97.2588 22.171 97.713 21.9951C98.1655 21.814 98.6499 21.7264 99.1372 21.7376C99.6244 21.7487 100.104 21.8584 100.548 22.0601C101.003 22.2409 101.418 22.5111 101.768 22.8545C102.117 23.198 102.394 23.6079 102.583 24.0601C102.771 24.5064 102.869 24.9858 102.87 25.4702C102.871 25.9546 102.776 26.4344 102.59 26.8817C102.404 27.3289 102.13 27.7346 101.786 28.0751C101.441 28.4157 101.032 28.6842 100.583 28.8651C100.129 29.0463 99.644 29.1352 99.1555 29.1266C98.667 29.118 98.1851 29.0121 97.738 28.8151C97.2749 28.6398 96.8529 28.3708 96.4984 28.0249C96.144 27.6791 95.8647 27.2638 95.678 26.8051Z"
        fill="#E3F2FD"
      />
      <path
        d="M203.363 107.685V104.815H201.453V107.685H198.583V109.595H201.453V112.465H203.363V109.595H206.233V107.685H203.363Z"
        fill="#E3F2FD"
      />
      <path
        d="M185.338 15.7999V11.4199H182.428V15.7999H178.048V18.7099H182.428V23.0899H185.338V18.7099H189.718V15.7999H185.338Z"
        fill="#E3F2FD"
      />
      <path
        d="M177.403 100.54V98.4502H176.013V100.54H173.923V101.93H176.013V104.02H177.403V101.93H179.493V100.54H177.403Z"
        fill="#E3F2FD"
      />
      <path
        d="M58.188 27.8254V25.7354H56.798V27.8254H54.708V29.2154H56.798V31.3053H58.188V29.2154H60.278V27.8254H58.188Z"
        fill="#E3F2FD"
      />
      <path
        d="M42.073 13.9999V11.1299H40.168V13.9999H37.298V15.9049H40.168V18.7799H42.073V15.9049H44.948V13.9999H42.073Z"
        fill="#E3F2FD"
      />
      <path
        d="M136.338 19.8595C136.338 20.0751 136.274 20.2859 136.154 20.4651C136.034 20.6444 135.864 20.7841 135.665 20.8666C135.466 20.9491 135.247 20.9707 135.035 20.9286C134.824 20.8865 134.63 20.7827 134.477 20.6303C134.325 20.4778 134.221 20.2836 134.179 20.0722C134.137 19.8608 134.158 19.6416 134.241 19.4424C134.323 19.2432 134.463 19.073 134.642 18.9532C134.822 18.8335 135.032 18.7695 135.248 18.7695C135.391 18.7689 135.533 18.7966 135.666 18.8512C135.798 18.9057 135.919 18.986 136.02 19.0873C136.122 19.1887 136.202 19.3091 136.256 19.4416C136.311 19.5742 136.339 19.7162 136.338 19.8595Z"
        fill="#EEF1FF"
      />
      <path
        d="M140.808 1.09C140.808 1.30558 140.744 1.51632 140.624 1.69557C140.505 1.87482 140.334 2.01453 140.135 2.09703C139.936 2.17953 139.717 2.20111 139.505 2.15905C139.294 2.117 139.1 2.01318 138.947 1.86074C138.795 1.70831 138.691 1.51409 138.649 1.30265C138.607 1.09121 138.628 0.872046 138.711 0.672874C138.793 0.473703 138.933 0.303467 139.112 0.183697C139.292 0.063926 139.502 0 139.718 0C140.007 0 140.284 0.114837 140.489 0.319252C140.693 0.523667 140.808 0.800914 140.808 1.09Z"
        fill="#EEF1FF"
      />
      <path
        d="M162.138 12.13C163.278 12.13 164.203 11.2055 164.203 10.065C164.203 8.92453 163.278 8 162.138 8C160.998 8 160.073 8.92453 160.073 10.065C160.073 11.2055 160.998 12.13 162.138 12.13Z"
        fill="#EEF1FF"
      />
      <path
        d="M205.683 87.7101C205.684 87.9271 205.621 88.1395 205.501 88.3204C205.381 88.5012 205.21 88.6423 205.009 88.7258C204.809 88.8093 204.589 88.8314 204.376 88.7893C204.163 88.7472 203.967 88.6428 203.814 88.4894C203.66 88.336 203.556 88.1404 203.514 87.9275C203.472 87.7146 203.494 87.4941 203.577 87.2938C203.661 87.0935 203.802 86.9225 203.983 86.8026C204.164 86.6826 204.376 86.6191 204.593 86.6201C204.882 86.6214 205.158 86.7367 205.362 86.9408C205.566 87.145 205.682 87.4214 205.683 87.7101Z"
        fill="#EEF1FF"
      />
      <path
        d="M204.593 121.795C204.593 122.012 204.529 122.223 204.408 122.404C204.288 122.584 204.117 122.724 203.917 122.807C203.717 122.89 203.497 122.911 203.284 122.869C203.072 122.827 202.877 122.723 202.724 122.569C202.571 122.416 202.466 122.221 202.424 122.009C202.382 121.796 202.403 121.576 202.486 121.376C202.569 121.176 202.71 121.005 202.89 120.885C203.07 120.764 203.281 120.7 203.498 120.7C203.788 120.7 204.067 120.816 204.272 121.021C204.478 121.226 204.593 121.505 204.593 121.795Z"
        fill="#EEF1FF"
      />
      <path
        d="M38.388 62.1849C38.388 62.4016 38.3236 62.6135 38.2031 62.7937C38.0826 62.9739 37.9113 63.1142 37.7109 63.197C37.5105 63.2797 37.2901 63.3011 37.0775 63.2584C36.865 63.2158 36.6699 63.111 36.5169 62.9573C36.364 62.8037 36.2601 62.6081 36.2184 62.3954C36.1767 62.1827 36.1991 61.9623 36.2828 61.7623C36.3664 61.5623 36.5076 61.3917 36.6883 61.272C36.869 61.1522 37.0812 61.0889 37.298 61.0899C37.5875 61.0912 37.8648 61.2071 38.069 61.4123C38.2733 61.6175 38.388 61.8953 38.388 62.1849Z"
        fill="#EEF1FF"
      />
      <path
        d="M19.388 18.9747C20.5285 18.9747 21.453 18.0502 21.453 16.9097C21.453 15.7693 20.5285 14.8447 19.388 14.8447C18.2475 14.8447 17.323 15.7693 17.323 16.9097C17.323 18.0502 18.2475 18.9747 19.388 18.9747Z"
        fill="#EEF1FF"
      />
      <path
        d="M60.153 5.25027C60.153 5.46685 60.0888 5.67855 59.9685 5.85862C59.8481 6.0387 59.6771 6.17904 59.4771 6.26192C59.277 6.3448 59.0568 6.36648 58.8444 6.32423C58.632 6.28198 58.4369 6.17769 58.2837 6.02456C58.1306 5.87142 58.0263 5.67631 57.9841 5.4639C57.9418 5.25149 57.9635 5.03132 58.0464 4.83123C58.1292 4.63115 58.2696 4.46013 58.4497 4.33981C58.6297 4.21949 58.8414 4.15527 59.058 4.15527C59.3484 4.15527 59.6269 4.27064 59.8323 4.47599C60.0376 4.68134 60.153 4.95986 60.153 5.25027Z"
        fill="#EEF1FF"
      />
      <path
        d="M2.50799 70.7152C2.50797 70.9324 2.44336 71.1447 2.32236 71.3251C2.20136 71.5055 2.02945 71.6458 1.82849 71.7282C1.62753 71.8106 1.4066 71.8314 1.19379 71.788C0.980984 71.7445 0.785915 71.6387 0.633391 71.484C0.480867 71.3294 0.377779 71.1329 0.337237 70.9195C0.296695 70.7061 0.320532 70.4855 0.405715 70.2857C0.490897 70.0859 0.633579 69.9159 0.815613 69.7974C0.997648 69.6789 1.21081 69.6172 1.42799 69.6202C1.7158 69.6242 1.99048 69.7413 2.1926 69.9462C2.39472 70.1511 2.50802 70.4274 2.50799 70.7152Z"
        fill="#EEF1FF"
      />
      <path
        d="M2.50798 128.885C2.50897 129.102 2.44546 129.314 2.32553 129.495C2.20559 129.676 2.03462 129.817 1.83434 129.901C1.63405 129.984 1.41347 130.006 1.20059 129.964C0.98772 129.922 0.792151 129.818 0.638712 129.664C0.485273 129.511 0.380883 129.315 0.338791 129.102C0.2967 128.889 0.318805 128.669 0.402302 128.469C0.485799 128.268 0.626923 128.097 0.807759 127.977C0.988595 127.857 1.20099 127.794 1.41798 127.795C1.70666 127.796 1.98315 127.912 2.18728 128.116C2.39141 128.32 2.50667 128.596 2.50798 128.885Z"
        fill="#EEF1FF"
      />
      <path
        d="M161.673 161.755C161.673 161.755 143.583 125.59 192.648 101.59C192.648 101.59 196.408 153.12 161.673 161.755Z"
        fill="#2196F3"
      />
      <path
        d="M163.758 157.15C163.758 151.98 164.828 146.845 166.313 141.885C166.508 141.27 166.678 140.64 166.883 140.025L167.548 138.2L168.218 136.37L168.968 134.575C169.938 132.165 171.158 129.87 172.303 127.545L174.263 124.18C174.893 123.045 175.608 121.965 176.333 120.89C177.058 119.815 177.763 118.73 178.528 117.685C179.293 116.64 180.103 115.625 180.898 114.605L182.093 113.08L183.373 111.62L185.958 108.735C186.383 108.235 186.853 107.805 187.313 107.35L188.708 106.005C189.633 105.11 190.573 104.225 191.518 103.35C190.708 104.35 189.893 105.35 189.068 106.35L187.828 107.82C187.413 108.32 186.988 108.79 186.618 109.32L184.278 112.37L183.098 113.87L182.008 115.44C181.285 116.49 180.551 117.535 179.808 118.575C179.088 119.625 178.438 120.725 177.753 121.8C176.323 123.915 175.153 126.185 173.868 128.385C171.466 132.878 169.381 137.535 167.628 142.32C165.841 147.12 164.545 152.088 163.758 157.15Z"
        fill="#E3F2FD"
      />
      <path
        opacity="0.1"
        d="M190.813 101.23C190.128 113.665 185.863 146.73 160.133 154.23C160.617 156.293 161.306 158.303 162.188 160.23C196.923 151.595 193.163 100.06 193.163 100.06C192.363 100.433 191.58 100.823 190.813 101.23Z"
        fill="#0046A0"
      />
      <path
        d="M157.248 173.395C157.248 173.395 159.823 162.395 170.588 149.04C182.383 134.42 197.768 129.16 204.153 136.185C210.353 143.005 205.083 152.43 189.118 157.245C173.153 162.06 164.128 164.12 157.248 173.395Z"
        fill="#673AB7"
      />
      <path
        d="M199.098 140.015C195.443 141.46 191.908 143.135 188.433 144.91C184.964 146.686 181.6 148.661 178.358 150.825C175.113 152.968 172.044 155.366 169.178 157.995C166.302 160.639 163.716 163.582 161.463 166.775C162.388 165.033 163.439 163.362 164.608 161.775C165.8 160.191 167.094 158.688 168.483 157.275C171.276 154.471 174.347 151.958 177.648 149.775C179.298 148.69 180.983 147.655 182.708 146.69C184.433 145.725 186.208 144.84 187.998 144.03C191.571 142.364 195.286 141.02 199.098 140.015Z"
        fill="#B39DDB"
      />
      <path
        opacity="0.1"
        d="M204.153 136.185C203.809 135.811 203.435 135.467 203.033 135.155C197.853 147.84 182.723 157.03 164.848 157.05C158.848 166.52 157.248 173.395 157.248 173.395C164.128 164.135 173.153 162.06 189.118 157.245C205.083 152.43 210.353 143.005 204.153 136.185Z"
        fill="#0046A0"
      />
      <path
        d="M64.588 167.21C64.588 167.21 27.428 183.155 6.33301 132.775C6.33301 132.775 58.008 132.03 64.588 167.21Z"
        fill="#2196F3"
      />
      <path
        d="M61.618 165.465C56.458 165.175 51.393 163.795 46.533 162.02C45.928 161.795 45.313 161.59 44.713 161.345L42.923 160.575L41.138 159.8L39.393 158.945C37.043 157.835 34.823 156.485 32.568 155.205L29.323 153.055C28.228 152.36 27.188 151.58 26.163 150.795C25.138 150.01 24.083 149.24 23.088 148.415C22.093 147.59 21.123 146.725 20.153 145.87L18.698 144.59L17.318 143.22L14.583 140.475C14.123 140.025 13.708 139.525 13.283 139.04L12.008 137.57C11.168 136.59 10.3347 135.604 9.508 134.61C10.463 135.48 11.413 136.35 12.348 137.23L13.743 138.555C14.208 138.995 14.663 139.45 15.163 139.85L18.073 142.35L19.543 143.62L21.043 144.805C22.043 145.59 23.043 146.385 24.043 147.185C25.043 147.985 26.113 148.685 27.143 149.425C29.173 150.975 31.368 152.28 33.493 153.685C37.8384 156.346 42.3644 158.7 47.038 160.73C51.7251 162.798 56.6102 164.385 61.618 165.465Z"
        fill="#E3F2FD"
      />
      <path
        opacity="0.1"
        d="M7.37801 135.19C19.753 136.62 52.508 142.785 58.473 168.91C60.5633 168.55 62.612 167.98 64.588 167.21C58.008 132.03 6.33301 132.775 6.33301 132.775C6.67967 133.595 7.02801 134.4 7.37801 135.19Z"
        fill="#0046A0"
      />
      <path
        d="M49.308 153.62C49.308 153.62 49.123 142.315 41.433 126.99C33.003 110.2 19.083 101.8 11.353 107.315C3.85299 112.665 6.99799 122.99 21.583 131.08C36.168 139.17 44.543 143.12 49.308 153.62Z"
        fill="#673AB7"
      />
      <path
        d="M15.508 112.12C18.773 114.31 21.873 116.695 24.893 119.165C27.8978 121.653 30.7591 124.308 33.463 127.12C36.1761 129.906 38.667 132.9 40.913 136.075C43.1589 139.272 45.0627 142.696 46.593 146.29C46.0644 144.392 45.3959 142.535 44.593 140.735C43.7646 138.938 42.8193 137.198 41.763 135.525C39.6325 132.186 37.1664 129.073 34.403 126.235C33.018 124.82 31.588 123.45 30.108 122.145C28.628 120.84 27.108 119.6 25.503 118.425C22.365 116.031 19.0202 113.921 15.508 112.12Z"
        fill="#B39DDB"
      />
      <path
        opacity="0.1"
        d="M11.353 107.33C11.7686 107.036 12.2085 106.778 12.668 106.56C15.038 120.06 27.883 132.24 45.348 136.06C49.188 146.585 49.308 153.645 49.308 153.645C44.543 143.145 36.163 139.195 21.583 131.105C7.00297 123.015 3.85297 112.68 11.353 107.33Z"
        fill="#0046A0"
      />
      <path
        d="M152.233 164.585C151.828 167.26 149.138 169.45 146.258 169.45H61.008C58.128 169.45 55.408 167.265 54.968 164.595L40.858 78.9947C40.418 76.3247 42.413 74.1396 45.298 74.1396H160.663C163.548 74.1396 165.573 76.3297 165.163 79.0047L152.233 164.585Z"
        fill="#B39DDB"
      />
      <path
        d="M130.843 45.5352H59.058V146.66H146.838V61.7702L130.843 45.5352Z"
        fill="#90CAF9"
      />
      <path
        d="M130.843 45.5352L146.958 61.6502H130.843V45.5352Z"
        fill="#90CAF9"
      />
      <path
        d="M127.318 77.5155L120.158 70.3555L103.008 87.5055L85.858 70.3555L78.703 77.5155L95.853 94.6605L78.703 111.81L85.858 118.97L103.008 101.82L120.158 118.97L127.318 111.81L110.168 94.6605L127.318 77.5155Z"
        fill="#E3F2FD"
      />
      <path
        d="M111.338 103.51C110.159 103.46 109.023 103.059 108.073 102.359C107.124 101.659 106.405 100.691 106.008 99.5803L102.718 88.9153C102.319 87.8058 101.599 86.84 100.65 86.1403C99.7013 85.4406 98.5658 85.0386 97.388 84.9853H38.888C38.3778 84.9641 37.8696 85.0604 37.4026 85.2667C36.9355 85.4729 36.522 85.7837 36.194 86.175C35.866 86.5663 35.6322 87.0276 35.5107 87.5236C35.3891 88.0195 35.3831 88.5367 35.493 89.0353L49.768 169.465C50.0026 170.584 50.6061 171.593 51.4819 172.328C52.3577 173.063 53.455 173.483 54.598 173.52H155.923C157.061 173.486 158.152 173.064 159.018 172.325C159.883 171.586 160.471 170.573 160.683 169.455L170.508 107.49C170.607 106.986 170.591 106.465 170.46 105.968C170.33 105.471 170.088 105.009 169.754 104.619C169.42 104.228 169.001 103.918 168.53 103.712C168.059 103.506 167.547 103.41 167.033 103.43L111.338 103.51Z"
        fill="#673AB7"
      />
      <path
        d="M97.093 141.205C97.0403 139.767 97.3552 138.34 98.0079 137.058C98.6606 135.777 99.6295 134.683 100.823 133.88L102.018 133.055C102.873 132.538 103.668 131.927 104.388 131.235C104.835 130.755 105.075 130.12 105.058 129.465C105.058 127.685 103.57 126.795 100.593 126.795C99.1476 126.803 97.7087 126.988 96.308 127.345C94.858 127.705 93.4539 128.23 92.123 128.91V121.82C95.1122 120.495 98.3538 119.837 101.623 119.89C105.396 119.89 108.396 120.68 110.623 122.26C111.684 122.985 112.545 123.966 113.124 125.113C113.704 126.26 113.984 127.535 113.938 128.82C114.001 130.166 113.629 131.496 112.878 132.615C111.856 133.875 110.645 134.97 109.288 135.86C108.151 136.69 107.318 137.328 106.788 137.775C106.295 138.185 105.895 138.696 105.618 139.275C105.339 139.912 105.204 140.604 105.223 141.3V142.175H97.123L97.093 141.205ZM98.038 152.23C97.2549 151.416 96.8174 150.331 96.8174 149.202C96.8174 148.073 97.2549 146.988 98.038 146.175C98.4409 145.779 98.9198 145.469 99.4457 145.264C99.9716 145.059 100.534 144.962 101.098 144.98C101.664 144.969 102.226 145.071 102.751 145.281C103.277 145.49 103.755 145.803 104.158 146.2C104.56 146.592 104.879 147.061 105.097 147.579C105.315 148.097 105.427 148.653 105.427 149.215C105.427 149.776 105.315 150.333 105.097 150.85C104.879 151.368 104.56 151.837 104.158 152.23C103.754 152.626 103.276 152.938 102.751 153.147C102.225 153.356 101.663 153.459 101.098 153.45C100.532 153.465 99.9683 153.365 99.4421 153.155C98.9159 152.946 98.4381 152.631 98.038 152.23Z"
        fill="white"
      />
      <path
        opacity="0.1"
        d="M167.033 103.43H160.703C160.843 104.89 160.913 106.364 160.913 107.85C160.913 141.065 125.363 167.995 81.508 167.995C70.3091 168.044 59.1802 166.227 48.578 162.62L49.798 169.49C50.0326 170.61 50.6362 171.618 51.512 172.353C52.3878 173.088 53.4851 173.508 54.628 173.545H155.923C157.061 173.511 158.152 173.089 159.018 172.35C159.883 171.611 160.471 170.599 160.683 169.48L170.508 107.49C170.607 106.986 170.591 106.465 170.46 105.968C170.33 105.471 170.088 105.009 169.754 104.619C169.42 104.228 169.001 103.918 168.53 103.712C168.059 103.507 167.547 103.41 167.033 103.43Z"
        fill="#0046A0"
      />
      <g opacity="0.3">
        <path
          d="M45.353 96.9203L46.253 102.98C46.973 107.78 47.6897 112.584 48.403 117.39C49.2797 123.24 50.153 129.092 51.023 134.945C51.7697 139.965 52.518 144.987 53.268 150.01C53.518 151.677 53.7663 153.344 54.013 155.01C54.133 155.79 54.198 156.605 54.368 157.38C54.3708 157.414 54.3708 157.447 54.368 157.48C54.4614 157.808 54.6799 158.086 54.9763 158.255C55.2728 158.423 55.6236 158.468 55.953 158.38C56.2771 158.282 56.5508 158.062 56.7174 157.767C56.884 157.472 56.9307 157.124 56.848 156.795L55.948 150.74C55.228 145.934 54.5113 141.129 53.798 136.325C52.9247 130.475 52.0513 124.625 51.178 118.775C50.428 113.752 49.6797 108.729 48.933 103.705L48.183 98.7054C48.068 97.9204 48.003 97.1103 47.833 96.3353C47.8306 96.3004 47.8306 96.2653 47.833 96.2303C47.7397 95.9027 47.521 95.6249 47.2243 95.4574C46.9276 95.2899 46.5768 95.246 46.248 95.3353C45.9243 95.4331 45.6507 95.6518 45.484 95.9461C45.3173 96.2403 45.2704 96.5874 45.353 96.9153V96.9203Z"
          fill="white"
        />
      </g>
      <path
        d="M186.508 52.3896C183.777 52.3897 181.103 53.1711 178.802 54.6416C176.501 56.1121 174.669 58.2104 173.522 60.6886C172.375 63.1668 171.961 65.9216 172.328 68.6275C172.696 71.3335 173.831 73.8776 175.598 75.9596L175.008 83.3297L181.843 80.2297C183.809 80.9066 185.898 81.1472 187.966 80.9347C190.034 80.7221 192.031 80.0615 193.818 78.9988C195.605 77.9362 197.138 76.497 198.312 74.7813C199.486 73.0657 200.273 71.1148 200.616 69.0645C200.96 67.0142 200.852 64.9137 200.302 62.909C199.751 60.9044 198.77 59.0438 197.427 57.4567C196.085 55.8696 194.412 54.5942 192.526 53.7191C190.641 52.8441 188.587 52.3904 186.508 52.3896Z"
        fill="#2196F3"
      />
      <path
        d="M185.508 74.0297C185.315 73.8388 185.184 73.5947 185.13 73.3286C185.077 73.0626 185.104 72.7866 185.208 72.536C185.312 72.2854 185.488 72.0715 185.715 71.9216C185.941 71.7718 186.207 71.6928 186.478 71.6947C186.663 71.6882 186.847 71.72 187.019 71.7879C187.191 71.8559 187.347 71.9586 187.478 72.0897C187.606 72.2166 187.708 72.3677 187.777 72.5342C187.847 72.7007 187.883 72.8793 187.883 73.0597C187.883 73.2401 187.847 73.4187 187.777 73.5852C187.708 73.7517 187.606 73.9028 187.478 74.0297C187.346 74.1591 187.19 74.2605 187.018 74.3275C186.846 74.3946 186.662 74.4259 186.478 74.4197C186.298 74.4242 186.12 74.3919 185.953 74.3248C185.786 74.2578 185.635 74.1573 185.508 74.0297ZM184.698 57.9297H188.303L187.633 69.4297H185.368L184.698 57.9297Z"
        fill="#F2F2F2"
      />
      <path
        opacity="0.1"
        d="M178.673 74.285C177.153 74.2866 175.635 74.1544 174.138 73.89C174.565 74.6217 175.055 75.3143 175.603 75.96L175.008 83.33L181.843 80.23C184.443 81.1265 187.247 81.2547 189.919 80.5994C192.59 79.9441 195.016 78.533 196.907 76.5349C198.797 74.5369 200.072 72.0365 200.578 69.3329C201.085 66.6293 200.802 63.837 199.763 61.29C196.898 68.83 188.538 74.285 178.673 74.285Z"
        fill="#0046A0"
      />
      <path
        d="M39.883 48.5204V34.9504C39.8739 34.1668 39.5586 33.4179 39.0045 32.8638C38.4505 32.3097 37.7016 31.9944 36.918 31.9854H12.183C11.3979 31.988 10.6458 32.3014 10.0911 32.857C9.53646 33.4126 9.22434 34.1653 9.22302 34.9504V52.0504C9.22434 52.835 9.53662 53.5871 10.0914 54.1419C10.6463 54.6968 11.3984 55.009 12.183 55.0104H47.008L39.883 48.5204Z"
        fill="#B39DDB"
      />
      <path
        d="M20.418 41.6198C20.418 42.9198 19.658 43.9748 18.728 43.9748C17.798 43.9748 17.038 42.9198 17.038 41.6198C17.038 40.3198 17.793 39.2598 18.728 39.2598C19.663 39.2598 20.418 40.2898 20.418 41.6198Z"
        fill="white"
      />
      <path
        d="M31.648 41.6198C31.648 42.9198 30.893 43.9748 29.958 43.9748C29.023 43.9748 28.268 42.9198 28.268 41.6198C28.268 40.3198 29.023 39.2598 29.958 39.2598C30.893 39.2598 31.648 40.2898 31.648 41.6198Z"
        fill="white"
      />
      <path
        d="M20.473 48.1848C21.0786 47.5439 21.8274 47.0556 22.658 46.7598C24.688 46.0298 26.658 46.7948 28.193 48.1848C28.608 48.5548 29.193 47.9448 28.803 47.5748C27.093 46.0348 24.868 45.1298 22.588 45.8748C21.5506 46.2052 20.6121 46.7895 19.858 47.5748C19.488 47.9848 20.093 48.5748 20.473 48.1848Z"
        fill="white"
      />
      <path
        opacity="0.1"
        d="M39.883 48.5204V34.9504C39.8739 34.1668 39.5586 33.4179 39.0045 32.8638C38.4504 32.3097 37.7015 31.9944 36.918 31.9854H34.383C34.83 33.6144 35.057 35.296 35.058 36.9854C35.058 44.1904 30.963 50.6454 24.503 55.0104H47.003L39.883 48.5204Z"
        fill="#0046A0"
      />
      <path
        d="M125.358 124.525C122.518 127.366 120.584 130.985 119.801 134.924C119.017 138.864 119.42 142.947 120.957 146.658C122.494 150.369 125.097 153.541 128.437 155.773C131.777 158.004 135.704 159.195 139.721 159.195C143.737 159.195 147.664 158.004 151.004 155.773C154.344 153.541 156.947 150.369 158.484 146.658C160.022 142.947 160.424 138.864 159.641 134.924C158.857 130.985 156.923 127.366 154.083 124.525C152.197 122.638 149.958 121.141 147.494 120.12C145.03 119.099 142.388 118.573 139.721 118.573C137.053 118.573 134.411 119.099 131.947 120.12C129.483 121.141 127.244 122.638 125.358 124.525ZM151.753 150.92C149.374 153.294 146.345 154.91 143.048 155.563C139.752 156.217 136.335 155.878 133.231 154.59C130.126 153.303 127.473 151.124 125.607 148.329C123.74 145.534 122.744 142.249 122.744 138.888C122.744 135.527 123.74 132.241 125.607 129.446C127.473 126.651 130.126 124.473 133.231 123.185C136.335 121.897 139.752 121.559 143.048 122.212C146.345 122.865 149.374 124.481 151.753 126.855C153.335 128.434 154.59 130.31 155.447 132.374C156.303 134.439 156.744 136.652 156.744 138.888C156.744 141.123 156.303 143.336 155.447 145.401C154.59 147.466 153.335 149.341 151.753 150.92Z"
        fill="#2196F3"
      />
      <path
        opacity="0.3"
        d="M139.718 155.91C149.118 155.91 156.738 148.29 156.738 138.89C156.738 129.49 149.118 121.87 139.718 121.87C130.318 121.87 122.698 129.49 122.698 138.89C122.698 148.29 130.318 155.91 139.718 155.91Z"
        fill="white"
      />
      <path
        d="M153.535 150.155L150.989 152.7L174.019 175.731L176.565 173.185L153.535 150.155Z"
        fill="#2196F3"
      />
      <path
        d="M153.532 150.16L150.987 152.706L159.469 161.188L162.014 158.642L153.532 150.16Z"
        fill="#2196F3"
      />
      <path
        d="M129.808 131.89C130.519 130.03 131.849 128.471 133.573 127.475C135.322 126.433 137.32 125.883 139.355 125.883C141.391 125.883 143.389 126.433 145.138 127.475C146.865 128.469 148.194 130.031 148.898 131.895C147.265 131.308 145.595 130.83 143.898 130.465C142.4 130.16 140.876 130.004 139.348 130C137.821 130 136.299 130.154 134.803 130.46C133.108 130.825 131.439 131.303 129.808 131.89Z"
        fill="white"
      />
    </svg>
  );
};
