/*
 * Allows alphanumerical characters ( A-Z, a-z, 0-9 )
 * Allows underscores               ( _ )
 * Allows dots                      ( . )
 * Allows tildes                    ( ~ )
 * Allows colons                    ( : )
 * Allows question marks            ( ? )
 * Allows 'number' signs            ( # )
 * Allows 'at' signs                ( @ )
 * Allows exclamation marks         ( ! )
 * Allows 'dollar' signs            ( $ )
 * Allows ampersands                ( & )
 * Allows single quotes             ( ' )
 * Allows parentheses               ( () )
 * Allows asterisks                 ( * )
 * Allows commas                    ( , )
 * Allows 'plus' signs              ( + )
 * Allows 'equal' signs             ( = )
 * Allows spaces                    (   )
 * Allows hyphens                   ( - )
 */
export const IDENTITY_KEY_REGEX = /^[a-zA-Z0-9_.~:?#@!$&'()*,+= -]+$/;
