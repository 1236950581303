import {
  ESButton,
  ESDialogActions,
  ESDialogContent,
} from '@energy-stacks/core/ui';
import { Box, Dialog, Link, Stack, Typography } from '@mui/material';
import { WelcomeIcon } from './WelcomeIcon';
import { IconExternalLink } from '@tabler/icons-react';
import { useDialog } from '@energy-stacks/shared';
import { useTranslation } from 'react-i18next';
import {
  useAppDispatch,
  useAppSelector,
  markFirstTimeAccess,
} from '@energy-stacks/fleet/store';
import { useEffectOnce } from 'usehooks-ts';

export const USER_MANUAL_URL =
  'https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Fproto%2Ffz3oa5P4jwkBsLtihHSBG3%2FWorking-file-%25F0%259F%259A%25A7%3Fpage-id%3D10775%253A26553%26type%3Ddesign%26node-id%3D11303-80702%26viewport%3D-1380%252C1458%252C0.19%26t%3DSBHewzFjtCwUdnRy-1%26scaling%3Dcontain%26starting-point-node-id%3D10775%253A26554%26mode%3Ddesign%22';

export const UserWelcomeDialog = () => {
  const isFirstTimeAccess = useAppSelector(
    (state) => state.userAccess.isFirstTimeAccess
  );
  const dispatch = useAppDispatch();
  const { t } = useTranslation('users');
  const {
    open,
    dialogProps: { isOpen, onClose },
  } = useDialog();

  useEffectOnce(() => {
    if (isFirstTimeAccess) {
      dispatch(markFirstTimeAccess());
      open();
    }
  });

  return (
    <Dialog
      open={isOpen}
      PaperProps={{
        sx: {
          maxWidth: 604,
        },
      }}
    >
      <ESDialogContent sx={{ '&.MuiDialogContent-root': { paddingX: 6 } }}>
        <Stack direction="column" justifyContent="center" alignItems="center">
          <Box>
            <WelcomeIcon />
          </Box>
          <Typography
            variant="h1"
            marginY={6}
            // TODO: Configure typography variants in through the theme
            sx={{
              color: 'grey.900',
              fontSize: '34px',
              lineheight: '42px',
              fontWeight: 700,
            }}
          >
            {t('userWelcomeDialogTitle')}
          </Typography>
          <Typography
            variant="body1"
            textAlign="center"
            marginBottom={9}
            // TODO: Configure typography variants in through the theme
            sx={{
              color: 'grey.600',
              fontSize: '16px',
              lineheight: '24px',
              fontWeight: 500,
            }}
          >
            {t('userWelcomeDialogText')}
          </Typography>
        </Stack>
      </ESDialogContent>
      <ESDialogActions sx={{ display: 'flex', justifyContent: 'center' }}>
        <ESButton
          onClick={onClose}
          variant="outlined"
          sx={{
            '&.MuiButton-outlined': {
              borderColor: 'primary.main',
              '&:hover': {
                borderColor: 'primary.main',
              },
            },
          }}
        >
          {t('userWelcomeDialogSkipButtonText')}
        </ESButton>
        <Link
          href={USER_MANUAL_URL}
          rel="noopener noreferrer"
          target="_blank"
          sx={{
            color: 'grey.100',
            marginLeft: 6,
          }}
        >
          <ESButton
            sx={{ '&.MuiLoadingButton-root': { paddingX: 7 } }}
            endIcon={<IconExternalLink />}
          >
            {t('userWelcomeDialogOpenManualText')}
          </ESButton>
        </Link>
      </ESDialogActions>
    </Dialog>
  );
};
