import { useTranslation } from 'react-i18next';
import { Chip } from '@mui/material';
import { VehicleStatus } from '@energy-stacks/fleet/feature-vehicles-data';

interface VehicleStatusChipProps {
  status: VehicleStatus;
}

interface VehicleStatusInfo {
  bgColor: string;
  color: string;
}

const vehicleStatusToChipInfoMap: Record<VehicleStatus, VehicleStatusInfo> = {
  AVAILABLE: {
    bgColor: 'success.light',
    color: 'success.dark',
  },
  ENROUTE: {
    bgColor: 'primary.light',
    color: 'primary.dark',
  },
  OUT_OF_ORDER: {
    bgColor: 'grey.100',
    color: 'grey.500',
  },
  PLANNED: {
    bgColor: 'secondary.light',
    color: 'secondary.dark',
  },
};

export const VehicleStatusChip: React.FC<VehicleStatusChipProps> = ({
  status,
}) => {
  const { bgColor, color } = vehicleStatusToChipInfoMap[status];

  const [t] = useTranslation('vehicles');

  return (
    <Chip
      size="medium"
      label={t(`vehicleStatuses.${status}`)}
      sx={{ backgroundColor: bgColor, color, fontWeight: 500 }}
    />
  );
};
