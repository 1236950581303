import { environment } from '@energy-stacks/feature-config';
import { createBaseQuery, stompClientInstance } from '@energy-stacks/shared';
import { createApi } from '@reduxjs/toolkit/query/react';
import {
  ChangeConfigurationConfirmation,
  ChangeConfigurationConfirmationDto,
  ChangeConfigurationRequestFormData,
} from './changeConfigurationRequestFormData';
import { ConfigurationConfirmation } from './configurationConfirmation';
import {
  ConfigurationModel,
  BrokerChargingStationConfigurationDto,
  BrokerChargingStationShadowConfigurationDto,
  ConfigurationModel201,
} from './configurationModel';
import { configurationKeyStatusNormalizer } from './normalizers/changeConfigurationStatusNormalizer';
import {
  UnlockConnectorConfirmation,
  UnlockConnectorConfirmation201,
} from './unlockConnectorConfirmation';
import { configurationNormalizer201 } from './normalizers/configurationNormalizer201';
import { ConfigurationDto201 } from './configurationDto';
import {
  GetBaseReportBody,
  GetBaseReportConfirmation,
  SetConfigurationVariablesConfirmation,
  SetConfigurationVariablesRequestFormData,
} from './configuration201FormData';
import { StompSubscription } from '@stomp/stompjs';
import log from 'loglevel';
import { EntityState, createEntityAdapter } from '@reduxjs/toolkit';
import { isEqual } from 'lodash-es';

interface CurrentData {
  ids: string[];
  entities: Record<string, ConfigurationModel201>;
}

const configuration201Adapter = createEntityAdapter<ConfigurationModel201>({
  selectId: (config) =>
    // Unique identifier made as there can be duplicate transactions - with same id
    config.variable + config.component,
});

const configuration201Subs: Record<string, StompSubscription[]> = {};

const unsubscribeConfiguration201 = (key: string) => {
  if (configuration201Subs[key]?.length > 0) {
    configuration201Subs[key]?.forEach((sub) => {
      try {
        sub.unsubscribe();
      } catch (e) {
        log.error(e);
      }
    });
    configuration201Subs[key] = [];
  }
};

export const brokerManagementApi = createApi({
  reducerPath: 'brokerManagement',
  tagTypes: ['Configuration', 'ConfigurationShadow', 'Variables'],
  keepUnusedDataFor: 0,
  baseQuery: createBaseQuery(
    `${environment.ocppServiceUrl}/management/chargingstations/`
  ),
  endpoints: (builder) => ({
    unlockConnector: builder.mutation<
      UnlockConnectorConfirmation,
      { connectorId: number; identityKey: string }
    >({
      query: ({ connectorId, identityKey }) => {
        return {
          url: `/${identityKey}/unlockConnector`,
          method: 'POST',
          body: {
            connectorId,
          },
        };
      },
    }),
    unlockConnector201: builder.mutation<
      UnlockConnectorConfirmation201,
      { connectorId: number; identityKey: string; evseId: number }
    >({
      query: ({ connectorId, identityKey, evseId }) => {
        return {
          url: `${environment.ocppServiceUrl}/management/v201/chargingstations/${identityKey}/unlockConnector`,
          method: 'POST',
          body: {
            evseId,
            connectorId,
          },
        };
      },
    }),
    softResetChargingStation: builder.mutation<
      ConfigurationConfirmation,
      string
    >({
      query: (identityKey) => ({
        url: `/${identityKey}/reset`,
        method: 'POST',
        body: {
          type: 'Soft',
        },
      }),
    }),
    hardResetChargingStation: builder.mutation<
      ConfigurationConfirmation,
      string
    >({
      queryFn: async (identityKey, { dispatch }, _, baseQuery) => {
        const { data, error } = await baseQuery({
          url: `/${identityKey}/reset`,
          method: 'POST',
          body: {
            type: 'Hard',
          },
        });

        /**
         * After hard reset endpoint is called and returns success, the station does not go offline immediately. We introduce artificial
         * delay to give station time to go offline. A loading indicator will be shown during this time. After the delay, we call an action
         * to mark the station as not requiring a hard reset anymore and force a refetch.
         */
        await new Promise((r) => setTimeout(r, 15000)); //
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return { data, error } as any;
      },
    }),
    getConfigurationShadow: builder.query<ConfigurationModel[], string>({
      query: (identityKey) => {
        // TODO: After backend exposes the shadow endpoint for updating configuration, move this to /chargingstations api
        // currently, we are fetching configuration from shadow/configuration controller in Swagger, but updating from management/configuration controller
        return {
          url: `${environment.ocppServiceUrl}/chargingstations/${identityKey}/shadow/configuration`,
        };
      },
      providesTags: ['ConfigurationShadow'],
      transformResponse: (
        configuration: BrokerChargingStationShadowConfigurationDto[]
      ) => {
        return (
          configuration.map((config) => ({
            currentValue: config.value ?? '',
            key: config.configurationKey ?? '-',
            readOnly: config.readonly ?? true,
            lastUpdate: `${config.dateLastUpdate}`,
          })) ?? []
        );
      },
    }),
    getConfiguration: builder.query<ConfigurationModel[], string>({
      query: (identityKey) => `/${identityKey}/configuration`,
      providesTags: ['Configuration'],
      transformResponse: (
        configuration: BrokerChargingStationConfigurationDto
      ) => {
        return (
          configuration.configurationKey.map((ocppKeyValueDto) => ({
            currentValue: ocppKeyValueDto.value ?? '',
            key: ocppKeyValueDto.key ?? '-',
            readOnly: ocppKeyValueDto.readonly ?? true,
            lastUpdate: `${ocppKeyValueDto.dateLastUpdate}`,
          })) ?? []
        );
      },
    }),
    updateConfigurationKey: builder.mutation<
      ChangeConfigurationConfirmation,
      ChangeConfigurationRequestFormData
    >({
      query: ({ identityKey, body }) => {
        return {
          url: `/${identityKey}/configuration`,
          method: 'POST',
          body,
        };
      },
      transformResponse: (response: ChangeConfigurationConfirmationDto) => {
        return {
          status: configurationKeyStatusNormalizer(response.status),
        };
      },
    }),
    getConfigurationShadow201: builder.query<
      EntityState<ConfigurationModel201>,
      string
    >({
      query: (identityKey) =>
        `${environment.ocppServiceUrl}/chargingstations/${identityKey}/shadow/variables`,
      providesTags: ['Variables'],
      transformResponse: (configuration: ConfigurationDto201[]) => {
        return configuration201Adapter.addMany(
          configuration201Adapter.getInitialState(),
          configuration.map(configurationNormalizer201)
        );
      },
      async onCacheEntryAdded(
        arg,
        { updateCachedData, cacheDataLoaded, cacheEntryRemoved, getState }
      ) {
        try {
          // wait for the initial query to resolve before proceeding
          await cacheDataLoaded;

          if (stompClientInstance && stompClientInstance.connected) {
            unsubscribeConfiguration201(arg);
            const topics = [`/topic/chargingstation-variable-updated/${arg}`];
            topics.forEach((topic) => {
              const sub = stompClientInstance?.subscribe(topic, (message) => {
                const configKeyShadow = JSON.parse(message.body);
                const configKey = configKeyShadow.shadow as ConfigurationDto201;
                const normalizedConfigKey =
                  configurationNormalizer201(configKey);

                // There are 2 mutations that are sending stomp messages to this endpoint
                // getBaseReport and setVariables. getBaseReport sends each key as a separate
                // stomp message even if the values are the same

                // Here we get the name of the cached key and we set the 'currentData' as
                // an array of keys that we have in our cache.
                const station = `getConfigurationShadow201("${arg}")`;
                const returnedData =
                  getState().brokerManagement.queries[`${station}`]?.data;

                let currentData: CurrentData | undefined;

                if (returnedData && typeof returnedData === 'object') {
                  currentData = returnedData as CurrentData;
                }

                // Here we get the targeted element from our cache based on our 'normalizedConfigKey'
                const targetedElement = currentData?.ids
                  ?.map(
                    (id) => currentData.entities[id] as ConfigurationModel201
                  )
                  .find((el: ConfigurationModel201) => {
                    if (
                      el.component === normalizedConfigKey.component &&
                      el.variable === normalizedConfigKey.variable
                    ) {
                      return el;
                    }
                    return null;
                  });

                // We compare the variableAttribute arrays since here we can have values changed
                const areValuesEqual = isEqual(
                  targetedElement?.variableAttribute,
                  normalizedConfigKey.variableAttribute
                );

                // We target the key in our configuration201Adapter
                const update = {
                  id:
                    normalizedConfigKey.variable +
                    normalizedConfigKey.component,
                  changes: normalizedConfigKey,
                };
                // Here we only update the key if the values are different so that
                // getBaseReport doesn't trigger 70+ rerenders even if values are the same
                if (targetedElement && !areValuesEqual) {
                  updateCachedData((draft) => {
                    configuration201Adapter.updateOne(draft, update);
                  });
                }
              });
              if (sub) {
                if (configuration201Subs[arg]) {
                  configuration201Subs[arg].push(sub);
                } else {
                  configuration201Subs[arg] = [sub];
                }
              }
            });
          } else {
            log.error(
              'Stomp lost connection. Could not subscribe to charging station details topics.'
            );
          }
        } catch {
          // no-op in case `cacheEntryRemoved` resolves before `cacheDataLoaded`,
          // in which case `cacheDataLoaded` will throw
        }
        // A Promise that allows you to wait for the point in time when the cache entry has been removed from the cache, by not being used/subscribed to any more in the application for too long or by dispatching api.util.resetApiState
        await cacheEntryRemoved;
        unsubscribeConfiguration201(arg);
      },
    }),
    setConfigurationVariables201: builder.mutation<
      SetConfigurationVariablesConfirmation,
      SetConfigurationVariablesRequestFormData
    >({
      query: ({ identityKey, body }) => {
        return {
          url: `${environment.ocppServiceUrl}/management/v201/chargingstations/${identityKey}/setVariables`,
          method: 'POST',
          body,
        };
      },
      invalidatesTags: ['Variables'],
    }),
    getBaseReport: builder.mutation<
      GetBaseReportConfirmation,
      GetBaseReportBody
    >({
      query: (params) => ({
        url: `${environment.ocppServiceUrl}/management/v201/chargingstations/${params.identityKey}/getBaseReport`,
        method: 'POST',
        body: {
          requestId: params.requestId,
          reportBase: params.reportBase,
        },
      }),
      invalidatesTags: ['Variables'],
    }),
  }),
});

export const {
  useUnlockConnectorMutation,
  useUnlockConnector201Mutation,
  useSoftResetChargingStationMutation,
  useHardResetChargingStationMutation,
  useGetConfigurationShadowQuery,
  useGetConfigurationShadow201Query,
  useGetConfigurationQuery,
  useUpdateConfigurationKeyMutation,
  useSetConfigurationVariables201Mutation,
  useGetBaseReportMutation,
} = brokerManagementApi;
