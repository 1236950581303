import { useGetConfigurationShadowQuery } from '@energy-stacks/broker/feature-charging-station-management-data';
import { useChargingStationIdentityKey } from '@energy-stacks/shared';

export const useConfiguration = () => {
  const identityKey = useChargingStationIdentityKey();

  const {
    data: configuration,
    isSuccess,
    isLoading,
    isError,
    refetch,
    error,
    isFetching,
  } = useGetConfigurationShadowQuery(identityKey ?? '', {
    skip: !identityKey,
    refetchOnMountOrArgChange: true,
  });

  return {
    configuration,
    isSuccess,
    isError,
    isLoading,
    refetch,
    error,
    isFetching,
  };
};
