import { useAppSelector } from '@energy-stacks/store';

export const useHasAccess = <F extends string, R>(
  features: Record<F, R[]>,
  feature: F
) => {
  const user = useAppSelector((state) => state.user.user);
  const role = user?.userRole as R;

  if (!features[feature]) {
    return false;
  }

  return role && features[feature].includes(role);
};
