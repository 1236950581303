import { Logo } from './Logo';
import { useTheme } from '@mui/material';

export const LogoOverlay = ({
  logo = <Logo />,
  backgroundColor = 'inherit',
}: {
  logo?: React.ReactNode;
  backgroundColor?: string;
}) => {
  const { palette } = useTheme();
  if (!backgroundColor) backgroundColor = palette.grey[100];

  return (
    <div
      style={{
        backgroundColor,
        height: '100vh',
        width: '100vw',
      }}
    >
      <div
        style={{
          position: 'absolute',
          top: 'calc(50% - 24px)',
          left: 'calc(50% - 78px)',
        }}
      >
        {logo}
      </div>
    </div>
  );
};
