import { resetStepper, useAppDispatch } from '@energy-stacks/store';
import { useCallback } from 'react';

export const useResetStepper = () => {
  const dispatch = useAppDispatch();

  return useCallback(() => {
    dispatch(resetStepper());
  }, [dispatch]);
};
