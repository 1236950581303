import { environment } from '@energy-stacks/feature-config';
import { createBaseQuery } from '@energy-stacks/shared';
import { createApi } from '@reduxjs/toolkit/query/react';
import { businessAccountsNormalizer } from './normalizers/businessAccountsNormalizer';
import { BusinessAccountModel } from './businessAccountModel';
import { businessAccountDetailsNormalizer } from './normalizers/businessAccountDetailsNormalizer';
import { BusinessAccountDetailsModel } from './businessAccountDetailsModel';
import { PlantModel } from './plantModel';
import { plantsNormalizer } from './normalizers/plantsNormalizer';
import { getDataByPlantId } from '@energy-stacks/fleet/shared';

export const businessAccountsApi = createApi({
  reducerPath: 'business-accounts',
  keepUnusedDataFor: 0,
  baseQuery: createBaseQuery(`${environment.ocppServiceUrl}/business-accounts`),
  tagTypes: ['BusinessAccounts'],
  endpoints: (builder) => ({
    getPlants: builder.query<PlantModel[], void>({
      query: () => '/plants',
      transformResponse: plantsNormalizer,
    }),
    getBusinessAccounts: builder.query<BusinessAccountModel[], void>({
      queryFn: async (_, api, _extraArgs, baseQuery) => {
        return getDataByPlantId({
          api,
          baseQuery,
          url: '',
          transformResponse: businessAccountsNormalizer,
        });
      },
      providesTags: ['BusinessAccounts'],
    }),
    getBusinessAccountDetails: builder.query<
      BusinessAccountDetailsModel,
      string
    >({
      query: (businessAccountId) => `/${businessAccountId}`,
      transformResponse: businessAccountDetailsNormalizer,
    }),
  }),
});

export const {
  useGetPlantsQuery,
  useGetBusinessAccountsQuery,
  useGetBusinessAccountDetailsQuery,
  usePrefetch,
} = businessAccountsApi;
