import { Box, Skeleton, Stack } from '@mui/material';

export const ChargingStationDetailsHeaderSkeleton = () => {
  return (
    <Box mb={3} display="flex" flexDirection="column">
      <Box display="flex" flexDirection="row" justifyContent="space-between">
        <Stack flex={1} gap={4} direction="row" alignItems="center">
          <Skeleton
            variant="text"
            sx={{
              width: '100%',
              maxWidth: '470px',
              minWidth: '300px',
            }}
          />
        </Stack>
        <Stack gap={4} direction="row" alignItems="center" ml={4}>
          <Skeleton
            animation="wave"
            variant="rectangular"
            sx={{
              width: '87.5px',
              height: '42px',
              borderRadius: (theme) => theme.spacing(1),
            }}
          />

          <Skeleton
            animation="wave"
            variant="circular"
            sx={{
              width: '34px',
              height: '34px',
            }}
          />
        </Stack>
      </Box>
      <Skeleton
        sx={{
          maxWidth: '300px',
          mt: (theme) => theme.spacing(1),
        }}
        variant="text"
      />
    </Box>
  );
};
