import { environment } from '@energy-stacks/feature-config';
import { appendZToDateString, createBaseQuery } from '@energy-stacks/shared';
import { createApi } from '@reduxjs/toolkit/query/react';
import { ChargingReservationModel } from './chargingReservationModel';

export const chargingReservationApi = createApi({
  reducerPath: 'charging-reservations',
  keepUnusedDataFor: 0,
  baseQuery: createBaseQuery(`${environment.ocppServiceUrl}`),
  tagTypes: ['ChargingReservations'],
  endpoints: (builder) => ({
    getChargingReservations: builder.query<
      ChargingReservationModel[],
      { dateTimeFrom: string; dateTimeTo: string }
    >({
      query: (params) => ({
        url: '/charging-reservations',
        method: 'GET',
        params,
      }),
      transformResponse: (response: ChargingReservationModel[]) => {
        return response.map((reservation) => ({
          ...reservation,
          startDate: appendZToDateString(reservation.startDate),
          endDate: appendZToDateString(reservation.endDate),
        }));
      },
      transformErrorResponse: () => [],
    }),
    createChargingSchedule: builder.mutation<void, { tours: string[] }>({
      query: (body) => ({
        url: '/schedules',
        method: 'POST',
        body: { tourUIDs: body.tours },
      }),
      invalidatesTags: ['ChargingReservations'],
    }),
  }),
});

export const {
  useGetChargingReservationsQuery,
  useCreateChargingScheduleMutation,
  usePrefetch,
} = chargingReservationApi;
