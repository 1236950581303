import React from 'react';
import { CSMSRoutes } from '@energy-stacks/csms/shared';

import { Navigate } from 'react-router-dom';
import { CsmsFeature } from './csmsFeature';
import { useCsmsFeatureGuard } from './useCsmsFeatureGuard';

interface CsmsRoleProtectedRouteProps {
  children: React.ReactNode;
  feature: CsmsFeature;
}

export const CsmsRoleProtectedRoute: React.FC<CsmsRoleProtectedRouteProps> = ({
  children,
  feature,
}) => {
  const hasAccess = useCsmsFeatureGuard(feature);

  if (!hasAccess) {
    return <Navigate to={CSMSRoutes.Dashboard} replace />;
  }

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
};
