import { useESSnackbar } from '@energy-stacks/core/ui';
import {
  ESPageLoadingIndicator,
  RefetchOnErrorPage,
} from '@energy-stacks/shared';
import { useAppSelector } from '@energy-stacks/broker/store';
import { useEffect } from 'react';
import { Configuration } from './Configuration';
import { useConfiguration } from './useConfiguration';

interface ErrorData {
  message: string;
  errorCode: string;
}

export const ChargingStationDetailsConfigurationTab = () => {
  const configurationRefetchInProgress = useAppSelector(
    (state) => state.brokerManagementState.configurationRefetchInProgress
  );
  const {
    configuration,
    isSuccess,
    isError,
    isLoading,
    refetch,
    error,
    isFetching,
  } = useConfiguration();
  const { showSnackbar } = useESSnackbar();

  useEffect(() => {
    // isError is only true when there is an error and request is over. When the request is triggered
    // again it is reset to false and error is not. We want to show error snackbar only after the request is finished.
    if (error && 'status' in error) {
      if ((error.data as ErrorData).errorCode === 'RPC_TIMEOUT' && isError) {
        showSnackbar('error', 'rpcTimeoutError', 'chargingStations');
      }
      if ((error.data as ErrorData).errorCode === 'RPC_ERROR' && isError) {
        showSnackbar('error', 'rpcError', 'chargingStations');
      }
    }
  }, [error, isError, showSnackbar]);

  if (configurationRefetchInProgress) {
    return <ESPageLoadingIndicator />;
  }

  return (
    <>
      {isLoading || isFetching ? <ESPageLoadingIndicator /> : null}
      {isError ? <RefetchOnErrorPage onRefetch={refetch} /> : null}
      {isSuccess && !isFetching ? (
        <Configuration configuration={configuration || []} />
      ) : null}
    </>
  );
};
