import MuiDrawer from '@mui/material/Drawer';
import { styled, Theme, CSSObject, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import { Grid, Stack, useMediaQuery } from '@mui/material';
import { Outlet } from 'react-router-dom';
import {
  useAppSelector,
  useAppDispatch,
  openSidebar,
  collapseSidebar,
} from '@energy-stacks/store';
import { BerryThemeProvider } from '@energy-stacks/core/berry-mui';
import { SidebarToggler } from './SidebarToggler';
import { useEffect } from 'react';

export const SIDEBAR_OPEN_WIDTH = 280;
export const SIDEBAR_CLOSED_WIDTH = 70;

interface SidebarProps {
  items?: React.ReactNode;
  bottomItems?: React.ReactNode;
  bottom?: React.ReactNode;
  logo?: React.ReactNode;
  outlet?: React.ReactNode;
  hasBorderRadius?: boolean;
}

const openedMixin = (theme: Theme): CSSObject => ({
  width: SIDEBAR_OPEN_WIDTH,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: SIDEBAR_CLOSED_WIDTH,
});

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  width: SIDEBAR_OPEN_WIDTH,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));

export const Sidebar: React.FC<SidebarProps> = ({
  items,
  bottomItems,
  bottom,
  logo,
  outlet = <Outlet />,
  hasBorderRadius = true,
}) => {
  const sidebarOpened = useAppSelector((state) => state.sidebar.sidebarOpened);
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('lg'));

  useEffect(() => {
    if (!matches) {
      dispatch(collapseSidebar());
    } else {
      dispatch(openSidebar());
    }
  }, [dispatch, matches]);

  return (
    <Box sx={{ display: 'flex' }}>
      <Drawer
        sx={{
          '&.MuiDrawer-docked .MuiDrawer-paper': {
            height: '100%',
            backgroundColor: 'dark.main',
            border: 'none',
          },
        }}
        variant="permanent"
        open={sidebarOpened}
      >
        <Grid
          container
          flexDirection="column"
          justifyContent="space-between"
          sx={(theme) => ({
            maxWidth: theme.spacing(70),
            pt: 2,
            pb: 5,
            px: 5,
          })}
          flex={1}
        >
          {/* Use dark theme locally per sidebar design */}
          <BerryThemeProvider mode="dark" hasBorderRadius={hasBorderRadius}>
            <List
              sx={{
                width: '100%',
                flex: 1,
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Box>
                {sidebarOpened ? (
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        height: 48,
                        width: 190,
                      }}
                    >
                      {logo}
                    </Box>

                    <SidebarToggler />
                  </Stack>
                ) : (
                  <Stack direction="row" justifyContent="center">
                    <SidebarToggler />
                  </Stack>
                )}
              </Box>

              <Box mt={8} />
              <Stack direction="column" justifyContent="space-between" flex={1}>
                <Box
                  display="flex"
                  flexDirection="column"
                  sx={
                    sidebarOpened
                      ? {
                          alignItems: 'start',
                        }
                      : { alignItems: 'center' }
                  }
                >
                  {items}
                </Box>
                <Box
                  display="flex"
                  flexDirection="column"
                  sx={
                    sidebarOpened
                      ? {
                          alignItems: 'start',
                        }
                      : { alignItems: 'center' }
                  }
                >
                  {bottomItems}
                </Box>
              </Stack>
            </List>
            <Box
              display="flex"
              flexDirection="column"
              width="100%"
              alignItems={sidebarOpened ? 'start' : 'center'}
            >
              {bottom ? bottom : null}
            </Box>
          </BerryThemeProvider>
        </Grid>
      </Drawer>
      <Box flex={1} width={SIDEBAR_OPEN_WIDTH}>
        {outlet}
      </Box>
    </Box>
  );
};
