import { useMemo } from 'react';

import { useAppSelector } from '@energy-stacks/fleet-is/store';
import {
  useGetCoreVehicleBrandsQuery,
  useGetVehicleBrandImagesQuery,
} from '@energy-stacks/fleet/feature-vehicles-data';

export const useCacheVehicleImages = () => {
  const loginState = useAppSelector((state) => state.user.loginState);

  const { data: vehicleBrands } = useGetCoreVehicleBrandsQuery(undefined, {
    skip: loginState !== 'loggedIn',
  });
  const brandIdsWithImages = useMemo(
    () =>
      vehicleBrands
        ?.filter((vb) => vb.has_brand_image === 'true') // TODO: Waiting for BE to change into boolean; Change DTO in coreVehicleDto as well.
        ?.map((vb) => vb.uuid) ?? [],
    [vehicleBrands]
  );
  useGetVehicleBrandImagesQuery(brandIdsWithImages, {
    skip: loginState !== 'loggedIn',
  });
};
