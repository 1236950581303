export const editTourApiErrors: Record<string, string> = {
  REQUEST_CONTAINS_DUPLICATE_JOB_UIDS: 'duplicateJobIds',
  END_DATE_NOT_EQUAL_TO_START_DATE: 'endDateNotEqualToStartDate',
  END_DATETIME_IS_BEFORE_START_DATETIME: 'endDateBeforeStartDate',
  OPTIMIZE_TOUR_JOBS_OUTSIDE_OF_GLOBAL_TIME_WINDOW:
    'jobsOutsideGlobalTimeWindow',
  INVALID_OPTIMIZE_TOUR_GLOBAL_TIME_WINDOW: 'invalideGlobalTimeWindow',
  INVALID_JOB_ORDER_SEQUENCE: 'invalidJobOrderSequence',
  VEHICLE_NOT_AVAILABLE_FOR_TOUR_PLANNING: 'vehicleNotAvailable',
};
