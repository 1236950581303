interface StatusWise {
  status: string;
}

export const sortByStatus = <T extends StatusWise>(array: T[]) => {
  return array.sort((a, b) => {
    if (a.status < b.status) {
      return -1;
    }
    if (a.status > b.status) {
      return 1;
    }
    return 0;
  });
};
