import { getDay, getHours, getMinutes, isSameDay } from 'date-fns';

export const hours = [
  0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
  22, 23, 0,
];

const maxSlots = 24 * 4;

// Returns time slot for drawing for the given start and end date
// Can return { startSlot: -1, endSlot: -1 } if the slots are overlapping with current day
export const getSlots = (
  startDate: Date,
  endDate: Date,
  selectedDate: Date
) => {
  let startSlot = getHours(startDate) * 4;
  let endSlot = getHours(endDate) * 4;

  if (getMinutes(startDate) > 0) {
    startSlot += getMinutes(startDate) / 15;
  }

  if (getMinutes(endDate) > 0) {
    endSlot += getMinutes(endDate) / 15;
  }

  if (
    !isSameDay(startDate, selectedDate) &&
    !isSameDay(endDate, selectedDate)
  ) {
    return { startSlot: -1, endSlot: -1 };
  } else {
    if (getDay(endDate) > getDay(selectedDate)) {
      endSlot = maxSlots + 1;
    }

    if (getDay(startDate) < getDay(selectedDate)) {
      startSlot = 0;
    }
  }

  return { startSlot, endSlot };
};
