// TODO: Move to core/utils
export const stringEnumToArray = (
  e: Record<string, string | number>
): string[] => {
  const enumStringValues = Object.values(e).filter(
    (v) => typeof v === 'string'
  ) as string[];

  return enumStringValues;
};
