import { SidebarGroup } from '@energy-stacks/core/sidebar';
import { useTranslation } from 'react-i18next';
import { IconMapPin } from '@tabler/icons-react';
import { BrokerRoutes } from '@energy-stacks/broker/shared';

export const LocationsDrawerItem = () => {
  const { t } = useTranslation('locations');
  const itemLabel = t('drawerItemLabel');
  return (
    <SidebarGroup
      testId="chargingSitesSidebarButton"
      groupInitialPath={BrokerRoutes.Locations}
      groupIcon={<IconMapPin />}
      groupLabel={itemLabel}
    ></SidebarGroup>
  );
};
