import { VehicleDto } from './vehicleDto';
import { VehicleModel } from './vehicleModel';

export const vehiclesNormalizer = (vehicles: VehicleDto[]): VehicleModel[] => {
  return vehicles.map((vehicle) => ({
    plantId: vehicle.depotId,
    vehicleId: vehicle.vehicleIdentificationNumber,
    brandId: vehicle.brandUuid,
    brandAndModel: `${vehicle.brandName} ${vehicle.model}`,
    name: vehicle.name,
    licencePlate: vehicle.licensePlateNumber,
    weight: vehicle.maxLoadingWeight,
    volume: vehicle.maxLoadingVolume,
    electricVehicleData: {
      soc: vehicle.electricVehicleData?.soc,
      moSoc: vehicle.electricVehicleData?.moSoc,
    },
    tour: vehicle.tour
      ? {
          tourId: vehicle.tour.tourUid,
          startDate: vehicle.tour.startDate,
          status: vehicle.tour.status,
        }
      : null,
    status: vehicle.status,
  }));
};
