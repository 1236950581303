export const ownerEntityApiErrors: Record<string, string> = {
  OWNER_NAME_PROVIDER_EXISTS: 'nameAlreadyexist',
  OWNER_EMAIL_EXISTS: 'emailAlreadyexist',
  OWNER_ENTITY_EXISTS: 'entityAlreadyexist',
  OWNER_ENTITY_NOT_FOUND: 'entityNotFound',
  USER_ROLE_NOT_AUTHENTICATED: 'userNotAuthenticated',
  USER_HAS_NO_PROVIDER: 'userHasNoProvider',
  USER_ROLE_IS_NOT_OWNER: 'userRoleIsNotOwner',
  USER_BELONGS_TO_AN_OWNER: 'userBelongsToAnOwner',
  OWNER_LOCATION_NOT_EXISTS: 'ownerLocationDoesntExist',
};
