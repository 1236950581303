import { businessAccountsApi } from '@energy-stacks/fleet/feature-business-accounts-data';
import { toursApi } from '@energy-stacks/fleet/feature-tours-data';
import { usersFleetApi } from '@energy-stacks/fleet/feature-users-data';
import { vehiclesApi } from '@energy-stacks/fleet-is/feature-vehicles-data';
import { jobsApi } from '@energy-stacks/fleet/feature-jobs-data';
import {
  AnyAction,
  ThunkAction,
  combineReducers,
  configureStore,
} from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/dist/query';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import {
  APP_FEATURE_KEY,
  appReducer,
  SIDEBAR_FEATURE_KEY,
  USER_FEATURE_KEY,
  userReducer,
  sidebarReducer,
  THEME_FEATURE_KEY,
  themeReducer,
  STEPPER_FEATURE_KEY,
  stepperReducer,
} from '@energy-stacks/store';
import { PLANT_FEATURE_KEY, plantReducer } from '@energy-stacks/fleet/store';
import {
  USER_ACCESS_FEATURE_KEY,
  userAccessReducer,
} from '@energy-stacks/fleet/store';
import { dashboardApi } from '@energy-stacks/broker/feature-dashboard-data';
import { usersBrokerApi } from '@energy-stacks/broker/feature-users-data';
import { chargingStationsApi } from '@energy-stacks/broker/feature-charging-stations-data';
import { settingsApi } from '@energy-stacks/broker/feature-settings-data';
import { brokerTransactionsApi } from '@energy-stacks/broker/feature-transactions-data';
import { brokerLocationsApi } from '@energy-stacks/broker/feature-locations-data';
import {
  addChargingStationReducer,
  ADD_CHARGING_STATION_FEATURE_KEY,
  brokerManagementReducer,
  BROKER_MANAGEMENT_FEATURE_KEY,
  CONFIGURATION_FEATURE_KEY,
  configurationReducer,
} from '@energy-stacks/broker/store';
import { centralSystemsApi as brokerCentralSystemsApi } from '@energy-stacks/broker/feature-system-preferences-data';
import { brokerManagementApi } from '@energy-stacks/broker/feature-charging-station-management-data';
import { ocppMessageLogsApi as brokerOcppMessageLogsApi } from '@energy-stacks/broker/feature-ocpp-message-logs-data';
import { tariffsApi as brokerTariffsApi } from '@energy-stacks/broker/feature-tariffs-data';
import { chargingReservationApi } from '@energy-stacks/fleet-is/feature-charging-schedule-data';

const persistConfig = {
  key: 'FLEET-IS',
  storage,
  // Must keep a whitelist value here or
  // the whole store will be persisted
  whitelist: [THEME_FEATURE_KEY, PLANT_FEATURE_KEY, USER_ACCESS_FEATURE_KEY],
};

export const rootReducer = combineReducers({
  [THEME_FEATURE_KEY]: themeReducer,
  [USER_FEATURE_KEY]: userReducer,
  [SIDEBAR_FEATURE_KEY]: sidebarReducer,
  [PLANT_FEATURE_KEY]: plantReducer,
  [APP_FEATURE_KEY]: appReducer,
  [USER_ACCESS_FEATURE_KEY]: userAccessReducer,
  [ADD_CHARGING_STATION_FEATURE_KEY]: addChargingStationReducer,
  [STEPPER_FEATURE_KEY]: stepperReducer,
  [BROKER_MANAGEMENT_FEATURE_KEY]: brokerManagementReducer,
  [CONFIGURATION_FEATURE_KEY]: configurationReducer,
  [vehiclesApi.reducerPath]: vehiclesApi.reducer,
  [dashboardApi.reducerPath]: dashboardApi.reducer,
  [settingsApi.reducerPath]: settingsApi.reducer,
  [brokerTransactionsApi.reducerPath]: brokerTransactionsApi.reducer,
  [brokerCentralSystemsApi.reducerPath]: brokerCentralSystemsApi.reducer,
  [brokerLocationsApi.reducerPath]: brokerLocationsApi.reducer,
  [brokerManagementApi.reducerPath]: brokerManagementApi.reducer,
  [chargingStationsApi.reducerPath]: chargingStationsApi.reducer,
  [brokerOcppMessageLogsApi.reducerPath]: brokerOcppMessageLogsApi.reducer,
  [brokerTariffsApi.reducerPath]: brokerTariffsApi.reducer,
  [toursApi.reducerPath]: toursApi.reducer,
  [businessAccountsApi.reducerPath]: businessAccountsApi.reducer,
  [usersBrokerApi.reducerPath]: usersBrokerApi.reducer,
  [chargingReservationApi.reducerPath]: chargingReservationApi.reducer,
  [jobsApi.reducerPath]: jobsApi.reducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const initStore = (preloadedState = {}) => {
  return configureStore({
    reducer: persistedReducer,
    preloadedState,
    // Additional middleware can be passed to this array
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: {
          ignoredActions: ['persist/PERSIST'],
        },
      }).concat(
        vehiclesApi.middleware,
        dashboardApi.middleware,
        chargingStationsApi.middleware,
        settingsApi.middleware,
        brokerTransactionsApi.middleware,
        brokerCentralSystemsApi.middleware,
        brokerLocationsApi.middleware,
        brokerManagementApi.middleware,
        brokerOcppMessageLogsApi.middleware,
        brokerTariffsApi.middleware,
        toursApi.middleware,
        businessAccountsApi.middleware,
        usersBrokerApi.middleware,
        jobsApi.middleware,
        chargingReservationApi.middleware
      ),
    devTools:
      (typeof process !== 'undefined' &&
        process.env['NODE_ENV'] !== 'production') ||
      !!import.meta,

    // Optional Redux store enhancers
    enhancers: [],
  });
};

export const store = initStore();

export const persistor = persistStore(store);

setupListeners(store.dispatch);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof rootReducer>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  AnyAction
>;
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
