import { Box, Paper, Stack, Typography } from '@mui/material';

import { useTranslation } from 'react-i18next';
import { ESButton } from '@energy-stacks/core/ui';

const DATE_RANGE_PICKER_WIDTH = 340;

interface DatePickerWrapperProps {
  children: React.ReactNode;
  onClearFilter: () => void;
  onApplyFilter: () => void;
  isDateInvalid: boolean;
}

export const DatePickerWrapper: React.FC<DatePickerWrapperProps> = ({
  children,
  onClearFilter,
  onApplyFilter,
  isDateInvalid,
}) => {
  const { t } = useTranslation('shared');

  return (
    <Paper
      sx={{ width: DATE_RANGE_PICKER_WIDTH, border: 'none' }}
      data-testid="datePickerWrapper"
    >
      {children}
      <Stack direction="row" px={3} py={4} alignItems="center" spacing={1.5}>
        {isDateInvalid && (
          <Typography
            color="error"
            justifySelf={'start'}
            sx={{ wordBreak: 'break-word' }}
          >
            {t('datePickerError')}
          </Typography>
        )}

        <Box flex={1} />
        <ESButton
          size="small"
          variant="text"
          color="info"
          onClick={onClearFilter}
          testId="clearDateRangeFilterButton"
        >
          {t('clear')}
        </ESButton>
        <ESButton
          testId="applyDateRangeFilterButton"
          size="small"
          disabled={isDateInvalid}
          onClick={onApplyFilter}
        >
          {t('apply')}
        </ESButton>
      </Stack>
    </Paper>
  );
};
