import { Dialog } from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
  ESDialogTitle,
  ESDialogContent,
  ESDialogActions,
  ESButton,
} from '@energy-stacks/core/ui';

interface EditTourInfoDialogProps {
  onClose: () => void;
  isOpen?: boolean;
}

export const EditTourInfoDialog: React.FC<EditTourInfoDialogProps> = ({
  onClose,
  isOpen = true,
}) => {
  const { t } = useTranslation('tours');

  return (
    <Dialog open={isOpen} onClose={onClose} fullWidth maxWidth="sm">
      <ESDialogTitle data-testid="editTourDialogTitle">
        {t('cannotEditTourDialog.title')}
      </ESDialogTitle>
      <ESDialogContent data-testid="editTourDialogContent">
        {t('cannotEditTourDialog.message')}
      </ESDialogContent>
      <ESDialogActions>
        <ESButton
          data-testid="editTourSaveButton"
          variant="contained"
          onClick={onClose}
        >
          {t('cannotEditTourDialog.button')}
        </ESButton>
      </ESDialogActions>
    </Dialog>
  );
};
