import DeviceHubRoundedIcon from '@mui/icons-material/DeviceHubRounded';
import { useTranslation } from 'react-i18next';
import { EventSourceChip } from './EventSourceChip';

interface BrokerEventSourceProps {
  inheritCursor?: boolean;
}

export const BrokerEventSource: React.FC<BrokerEventSourceProps> = ({
  inheritCursor,
}) => {
  const { t } = useTranslation('chargingStations');
  return (
    <EventSourceChip
      icon={<DeviceHubRoundedIcon />}
      label={t('broker')}
      inheritCursor={inheritCursor}
    />
  );
};
