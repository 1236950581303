import { Chip, useTheme } from '@mui/material';
import { IconBattery2 } from '@tabler/icons-react';

export const SocChip = ({ percentage }: { percentage: number | undefined }) => {
  const { palette, spacing } = useTheme();

  if (percentage === undefined) {
    return null;
  }

  let socColor = palette.warning[800];
  if (percentage > 75) {
    socColor = palette.success.dark;
  } else if (percentage < 26) {
    socColor = palette.error.dark;
  }

  return (
    <Chip
      variant="outlined"
      size="medium"
      icon={
        <IconBattery2 color={socColor} height={spacing(4)} width={spacing(4)} />
      }
      label={`SOC: ${percentage}%`}
      sx={{
        color: socColor,
        borderColor: socColor,
        fontSize: 14,
        fontWeight: 500,
        pl: 2,
        py: 1,
      }}
    />
  );
};
