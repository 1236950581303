import { Box, Card, Paper, Typography } from '@mui/material';
import { FC, ReactNode } from 'react';
import { IconCar, IconClockHour3, IconPigMoney } from '@tabler/icons-react';
import { useTranslation } from 'react-i18next';

const optimizeOptionVariants = ['time', 'costs', 'drivers'] as const;
export type OptimizeOptionVariant = (typeof optimizeOptionVariants)[number];

type OptimizeOptionCardProps = {
  variant: OptimizeOptionVariant;
  selected: boolean;
  disabled?: boolean;
  onSelect: (variant: OptimizeOptionVariant) => void;
};

const variantToIconMap: { [variant in OptimizeOptionVariant]: ReactNode } = {
  time: <IconClockHour3 />,
  costs: <IconPigMoney />,
  drivers: <IconCar />,
};

export const OptimizeOptionCard: FC<OptimizeOptionCardProps> = ({
  variant,
  selected,
  disabled,
  onSelect,
}) => {
  const [t] = useTranslation('tours');

  return (
    <Card
      onClick={() => {
        if (!disabled) {
          onSelect(variant);
        }
      }}
      sx={{
        border: '1px solid',
        borderColor: selected && !disabled ? 'primary.main' : 'grey.100',
        opacity: disabled ? 0.5 : 1,
        p: 6,
        cursor: 'default',
        '&:hover':
          !disabled && !selected
            ? {
                borderColor: 'primary.200',
                cursor: 'pointer',
              }
            : {},
      }}
    >
      <Box display="flex" alignItems="center">
        <Box
          sx={{
            alignItems: 'center',
            color: 'primary.800',
            display: 'flex',
            mr: 2.5,
          }}
        >
          {variantToIconMap[variant]}
        </Box>
        <Typography
          variant="h4"
          sx={{
            color: 'grey.900',
            fontWeight: 700,
          }}
        >
          {t(`optimizeOptionDetails.titles.${variant}`)}
        </Typography>
      </Box>
      <Paper
        sx={{
          backgroundColor: 'grey.50',
          border: 'none',
          borderRadius: 3,
          mt: 3,
          p: 4,
        }}
      >
        <Typography
          variant="body2"
          sx={{
            '&': {
              display: '-webkit-box',
              WebkitLineClamp: '5',
              WebkitBoxOrient: 'vertical',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              minHeight: 105,
            },
          }}
        >
          {t(`optimizeOptionDetails.descriptions.${variant}`)}
        </Typography>
      </Paper>
    </Card>
  );
};
