import { Box, Stack } from '@mui/material';

export const ESTableFilters = ({ children }: { children: React.ReactNode }) => {
  return (
    <Box display="flex" mb={8} pr={3} alignItems="center">
      <Box flex={1}>
        <Stack direction="row" gap={3} alignItems="center">
          {children}
        </Stack>
      </Box>
    </Box>
  );
};
