import { isAfter, isValid } from 'date-fns';
import * as yup from 'yup';

export const workingHoursValidationSchema = yup.object().shape({
  startTime: yup.mixed().when('isWorkingDay', {
    is: true,
    then: yup
      .date()
      .nullable()
      .typeError('invalidTime')
      .required('startTimeRequired'),
    otherwise: yup.mixed().notRequired(),
  }),
  endTime: yup.mixed().when('isWorkingDay', {
    is: true,
    then: yup
      .date()
      .nullable()
      .typeError('invalidTime')
      .when('startTime', {
        is: (startTime: Date | null) => isValid(startTime),
        then: yup
          .date()
          .nullable()
          .typeError('invalidTime')
          .test(
            'range',
            'endTimeBeforeStartTime',
            (endTime, { parent: { startTime } }) => {
              if (!endTime || !isValid(endTime)) return true;
              return isAfter(endTime, startTime);
            }
          ),
      })
      .required('endTimeRequired'),
    otherwise: yup.mixed().notRequired(),
  }),
  isWorkingDay: yup.boolean(),
});
