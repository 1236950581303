import { DialogContent, DialogContentProps } from '@mui/material';
import { useIsElementScrollable } from '../useIsElementScrollable';

export const ESDialogContent: React.FC<DialogContentProps> = ({
  sx,
  ...rest
}) => {
  return (
    <DialogContent sx={{ pt: 0, pb: 4, pl: 10, pr: 10, ...sx }} {...rest} />
  );
};

/**
 * Automatically detects if content is scrollable and displays default MUI dividers
 * around the content if that is the case.
 */
export const ESScrollIndicatorDialogContent: React.FC<DialogContentProps> = ({
  sx,
  ...rest
}) => {
  const contentScrollable = useIsElementScrollable(
    '#es-dialog-scrollable-content'
  );

  return (
    <ESDialogContent
      id="es-dialog-scrollable-content"
      dividers={contentScrollable}
      sx={{ pt: 0, pb: 4, pl: 10, pr: 10, ...sx }}
      {...rest}
    />
  );
};
