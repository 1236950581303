import { createBaseQuery } from '@energy-stacks/shared';
import { createApi } from '@reduxjs/toolkit/query/react';
import { environment } from '@energy-stacks/feature-config';
import { TransactionTimeSlicesModel } from './transactionsModel';

export const dashboardApi = createApi({
  reducerPath: 'dashboard',
  tagTypes: ['Transactions', 'ChargingStations'],
  baseQuery: createBaseQuery(`${environment.ocppServiceUrl}/dashboard/stats/`),
  endpoints: (builder) => ({
    getTransactionTimeSlices: builder.query<
      TransactionTimeSlicesModel[],
      { dateStart: string }
    >({
      query: (queryParams) => ({
        url: '/transactionTimeSlices',
        method: 'GET',
        params: {
          dateStart: queryParams.dateStart,
        },
      }),
    }),
  }),
});

export const { useGetTransactionTimeSlicesQuery } = dashboardApi;
