import { SxProps, Theme } from '@mui/material';

export const STICKY_COLUMN_CLASS_NAME = 'es-sticky-column';

const STICKY_COLUMN_MAX_WIDTH = 100;
export const STICKY_COLUMN_SX = (theme: Theme): SxProps<Theme> => ({
  position: 'sticky',
  right: 0,
  borderLeft: `1px solid ${theme.palette.grey[100]}`,
  backgroundColor: theme.palette.common.white,
  maxWidth: STICKY_COLUMN_MAX_WIDTH,
});
