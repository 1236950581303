import { Grid, Skeleton } from '@mui/material';

export const DashboardSkeleton = () => {
  return (
    <Grid container spacing={6}>
      <Grid container item spacing={6}>
        <Grid
          item
          container
          spacing={6}
          xs={12}
          sx={{ paddingTop: '10px !important ' }}
        >
          <Grid item xl={6} lg={12} md={12} sm={12} xs={12}>
            <Grid container item spacing={6} sx={{ pt: 3 }}>
              <Grid item xs={6}>
                <Skeleton
                  variant="rectangular"
                  animation="wave"
                  width="100%"
                  height={166}
                />
              </Grid>
              <Grid item xs={6}>
                <Skeleton
                  variant="rectangular"
                  animation="wave"
                  width="100%"
                  height={166}
                />
              </Grid>
              <Grid item xs={6}>
                <Skeleton
                  variant="rectangular"
                  animation="wave"
                  width="100%"
                  height={166}
                />
              </Grid>
              <Grid item xs={6}>
                <Skeleton
                  variant="rectangular"
                  animation="wave"
                  width="100%"
                  height={166}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xl={6} lg={12} md={12} sm={12} xs={12}>
            <Skeleton
              variant="rectangular"
              animation="wave"
              width="100%"
              height={358}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid container item spacing={6}>
        <Grid item xs={6}>
          <Skeleton
            variant="rectangular"
            animation="wave"
            width="100%"
            height={166}
          />
        </Grid>
        <Grid item xs={6}>
          <Skeleton
            variant="rectangular"
            animation="wave"
            width="100%"
            height={166}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};
