import React, { FC } from 'react';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ImportToursButton } from '@energy-stacks/fleet-is/shared';
import { NoToursSvg } from './NoToursSvg';

interface NoToursProps {
  description?: string;
  showImportToursButton?: boolean;
}

export const NoTours: FC<NoToursProps> = ({
  description,
  showImportToursButton = false,
}) => {
  const { t } = useTranslation('tours');

  return (
    <Box
      sx={{
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        margin: 'auto',
        width: '436px',
        textAlign: 'center',
        gap: 7,
        color: '#29314F',
      }}
    >
      <NoToursSvg />
      <Typography variant="h3">{t('noImportedTours')}</Typography>
      {description?.length && (
        <Typography variant="body1" fontWeight={400}>
          {t(description)}
        </Typography>
      )}
      {showImportToursButton && <ImportToursButton />}
    </Box>
  );
};
