import React, { useMemo } from 'react';

// material-ui
import { CssBaseline, StyledEngineProvider } from '@mui/material';
import {
  createTheme,
  ThemeOptions,
  ThemeProvider,
  Theme,
  // Breakpoint,
} from '@mui/material/styles';

// // project import
import Palette from './palette';
import Typography from './typography';

import { componentStyleOverrides } from './compStyleOverride';
import { customShadows } from './shadows';

// // types
import { CustomShadowProps } from '../types/default-theme';
import { TypographyOptions } from '@mui/material/styles/createTypography';

import '@fontsource/poppins/300.css';
import '@fontsource/poppins/400.css';
import '@fontsource/poppins/500.css';
import '@fontsource/poppins/700.css';

interface Props {
  mode?: 'light' | 'dark';
  children: React.ReactNode;
  hasBorderRadius?: boolean;
}

export interface BorderRadius {
  xxs: string;
  xs: string;
  sm: string;
  md: string;
  lg: string;
  xl: string;
  xxl: string;
}

const borderRadiusMap: BorderRadius = {
  xxs: '4px',
  xs: '6px',
  sm: '8px',
  md: '12px',
  lg: '16px',
  xl: '20px',
  xxl: '50%',
};

const noBorderRadiusMap: BorderRadius = {
  xxs: '0px',
  xs: '0px',
  sm: '0px',
  md: '0px',
  lg: '0px',
  xl: '0px',
  xxl: '0px',
};

export const BerryThemeProvider = ({
  children,
  mode = 'light',
  hasBorderRadius = true,
}: Props) => {
  const borderRadius: BorderRadius = Object.freeze(
    hasBorderRadius ? borderRadiusMap : noBorderRadiusMap
  );

  const fontFamily = `'Poppins', sans-serif`;
  const outlinedFilled = true;
  const presetColor = 'default';
  const rtlLayout = false;

  const theme: Theme = useMemo<Theme>(
    () => Palette(mode, presetColor),
    [mode, presetColor]
  );

  const themeTypography: TypographyOptions = useMemo<TypographyOptions>(
    () => Typography(theme, borderRadius, fontFamily),
    [theme, borderRadius, fontFamily]
  );
  const themeCustomShadows: CustomShadowProps = useMemo<CustomShadowProps>(
    () => customShadows(mode, theme),
    [mode, theme]
  );

  const themeOptions: ThemeOptions = useMemo(
    () => ({
      direction: rtlLayout ? 'rtl' : 'ltr',
      palette: theme.palette,
      spacing: 4,
      breakpoints: {
        values: {
          xs: 0,
          sm: 700, // default 600 -> 700 is the max width of containers seen in design, assume default for all
          md: 900,
          lg: 1150,
          xl: 1536,
          tablet: 800,
          laptop: 1024,
          desktop: 1400,
        },
      },
      mixins: {
        toolbar: {
          minHeight: '48px',
          padding: '16px',
          '@media (min-width: 600px)': {
            minHeight: '48px',
          },
        },
      },
      typography: themeTypography,
      customShadows: themeCustomShadows,
    }),
    [rtlLayout, theme, themeCustomShadows, themeTypography]
  );

  const themes: Theme = createTheme(themeOptions);
  themes.components = useMemo(
    () => componentStyleOverrides(themes, borderRadius, outlinedFilled),
    [themes, borderRadius, outlinedFilled]
  );

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={themes}>
        <CssBaseline />
        {children}
      </ThemeProvider>
    </StyledEngineProvider>
  );
};
