import { PayloadAction, createSlice } from '@reduxjs/toolkit';

const initialState = {
  isEntityAddUserDisabled: false,
};

export const entityDetailsSlice = createSlice({
  name: 'entityDetailsSlice',
  initialState,
  reducers: {
    setIsEntityAddUserDisabled: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isEntityAddUserDisabled = payload;
    },
  },
});

export const { setIsEntityAddUserDisabled } = entityDetailsSlice.actions;

export const entityDetailsReducer = entityDetailsSlice.reducer;
