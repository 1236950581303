import { ESPopover } from '@energy-stacks/core/ui';
import { ContaminationViolation } from '@energy-stacks/fleet/feature-tours-data';
import { Box, SxProps } from '@mui/material';
import { IconAlertTriangle } from '@tabler/icons-react';
import { FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';

type JobContaminationWarningProps = {
  conflictingJobId: ContaminationViolation['conflictingJobId'];
  conflictingJobOrigin: ContaminationViolation['conflictingJobOrigin'];
  sx?: SxProps;
};

export const JobContaminationWarning: FC<JobContaminationWarningProps> = ({
  conflictingJobId,
  conflictingJobOrigin,
  sx,
}) => {
  const [t] = useTranslation('tours');

  return (
    <ESPopover
      content={
        <Trans
          t={t}
          i18nKey={`contaminationWarningPopover.job.${conflictingJobOrigin}`}
          values={{
            conflictingJobId,
          }}
        >
          This job violates the contamination rules due to its position after
          job <strong>#conflictingJobId</strong>
          <br />
          Remove or rearrange the jobs in this tour to resolve contamination
          rule violation.
        </Trans>
      }
    >
      <Box
        data-testid="jobContaminationWarning"
        sx={{
          alignItems: 'center',
          display: 'flex',
          color: 'error.dark',
          ...sx,
        }}
      >
        <IconAlertTriangle />
      </Box>
    </ESPopover>
  );
};
