import {
  LATITUDE_OCPI_REGEX,
  LATITUDE_REGEX,
  LONGITUDE_OCPI_REGEX,
  LONGITUDE_REGEX,
} from '@energy-stacks/shared';
import * as yup from 'yup';

export const brokerLocationFormValidationSchema = yup.object().shape({
  locationName: yup
    .string()
    .required('locationRequired')
    .max(255, 'locationNameTooLong'),
  zipCode: yup.string().nullable().max(10, 'locationZipCodeTooLong'),
  streetNameAndNumber: yup
    .string()
    .nullable()
    .max(45, 'streetNameAndNumberTooLong'),
  city: yup.string().nullable().max(45, 'cityTooLong'),
  country: yup.object().shape({
    label: yup.string(),
    value: yup.string(),
  }),
  state: yup.string().nullable().max(20, 'stateTooLong'),
  lat: yup
    .string()
    .required('latitudeRequired')
    .matches(LATITUDE_REGEX, {
      message: 'latitudeValidation',
      excludeEmptyString: true,
    })
    .matches(LATITUDE_OCPI_REGEX, {
      message: 'coordinateDecimals',
      excludeEmptyString: true,
    }),
  lng: yup
    .string()
    .required('longitudeRequired')
    .matches(LONGITUDE_REGEX, {
      message: 'longitudeValidation',
      excludeEmptyString: true,
    })
    .matches(LONGITUDE_OCPI_REGEX, {
      message: 'coordinateDecimals',
      excludeEmptyString: true,
    }),
});
