import { ConnectorModel } from '@energy-stacks/broker/feature-charging-stations-data';
import {
  ESTooltip,
  formatDateTime,
  timeAgoFromDate,
} from '@energy-stacks/shared';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Stack,
  Tab,
  Tabs,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ConnectorStatus } from './ConnectorStatus';
import { ConnectorIcon } from '@energy-stacks/broker/shared';
import { useState } from 'react';
import { ESMenu, ESMenuItem } from '@energy-stacks/core/ui';
import { EditConnectorDialog } from './edit/EditConnectorDialog';
import { ConnectorGeneralTab } from './ConnectorGeneralTab';
import { ConnectorTariffsTab } from './ConnectorTariffsTab';
import { ViewMode } from '../protocol-v1.6/Connectors';
import { useGetUiBrandingQuery } from '@energy-stacks/broker/feature-settings-data';
import { useGetTariffQuery } from '@energy-stacks/broker/feature-tariffs-data';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { UnlockConnectorDialog201 } from './UnlockConnectorDialog201';
import { ChangeConnectorAvailabilityStatusDialog201 } from './change-availability/ChangeConnectorAvailabilityStatusDialog201';
import { TriggerConnectorMessageDialog201 } from './trigger-message/TriggerConnectorMessageDialog201';
import { useGetRegisteredServicesQuery } from '@energy-stacks/broker/feature-registered-services-data';
import { AssignTariffDialog } from './AssignTariffDialog';

interface ConnectorCardProps {
  connector: ConnectorModel;
  chargingStationIdentityKey: string;
  testId: string;
  viewMode: ViewMode;
  chargingStationOnline: boolean;
}

type ConnectorTabs = {
  label: string;
  value: number;
};

const multipleTabs: ConnectorTabs[] = [
  { label: 'connectorGeneralTab', value: 1 },
  { label: 'connectorTariffTab', value: 2 },
];
const singleTab: ConnectorTabs[] = [{ label: 'connectorGeneralTab', value: 1 }];

type ConnectorDialog =
  | 'edit'
  | 'unlock'
  | 'triggerMessage'
  | 'changeStatus'
  | 'assignTariff';

export const ConnectorCard201: React.FC<ConnectorCardProps> = ({
  connector,
  chargingStationIdentityKey,
  testId,
  viewMode,
  chargingStationOnline,
}) => {
  const { data: availableServices } = useGetRegisteredServicesQuery();
  const isTarriffServiceAvailable =
    availableServices?.includes('TARIFF-SERVICE');
  const tabs = isTarriffServiceAvailable ? multipleTabs : singleTab;
  const [t] = useTranslation('chargingStations');
  const [currentTabValue, setCurrentTabValue] = useState(tabs[0].value);
  const [openedDialog, setOpenedDialog] = useState<ConnectorDialog>();
  const { data: uiBranding } = useGetUiBrandingQuery();
  const hasBorderRadius =
    uiBranding?.hasBorderRadius || uiBranding?.hasBorderRadius === undefined;
  const { data: tariff } = useGetTariffQuery(connector.tariffId ?? skipToken);

  return (
    <Card
      data-testid={`connectorCard${testId}`}
      sx={{
        backgroundColor: (theme) => theme.palette.grey[50],
        borderRadius: hasBorderRadius ? (theme) => theme.spacing(3) : 0,
        border: (theme) => `1px solid ${theme.palette.grey[100]}`,
      }}
    >
      <CardHeader
        data-testid={`connectorCardHeader${testId}`}
        sx={{ p: 4, pb: 0 }}
        disableTypography
        title={
          <Stack
            alignItems="center"
            flexDirection="row"
            justifyContent="space-between"
          >
            <Stack direction="row" alignItems="center">
              <ConnectorIcon
                connectorType={connector.connectorType}
                size={36}
              />
              <Box ml={5}>
                <Stack direction="row" alignItems="center" gap={3}>
                  <Typography
                    data-testid={`connector${testId}Title`}
                    variant="body2"
                    fontWeight={700}
                  >
                    {`${t('connector')} ${connector.connectorId}`}
                  </Typography>
                  <ConnectorStatus
                    testId={testId}
                    connectorStatus={connector.connectorStatus}
                    colorDisabled={chargingStationOnline ? false : true}
                  />
                </Stack>
              </Box>
            </Stack>
            <Stack direction="row" alignItems="center" gap={4} ml={4}>
              {connector.dateLastUpdate ? (
                <ESTooltip title={formatDateTime(connector.dateLastUpdate)}>
                  <Typography
                    color={(theme) => theme.palette.text.secondary}
                    variant="caption"
                  >
                    {`${t('lastUpdate')}: ${timeAgoFromDate(
                      connector.dateLastUpdate
                    )}`}
                  </Typography>
                </ESTooltip>
              ) : null}

              <ESMenu
                testId={`connectorCard${testId}`}
                disableMenu={!!openedDialog}
              >
                <ESMenuItem
                  testId={`connector${testId}EditMenuItem`}
                  onClick={() => setOpenedDialog('edit')}
                >
                  {t('edit')}
                </ESMenuItem>
                <ESMenuItem
                  testId={`connector${testId}UnlockMenuItem`}
                  onClick={() => setOpenedDialog('unlock')}
                >
                  {t('unlockConnector')}
                </ESMenuItem>
                <ESMenuItem
                  testId={`connector${testId}TriggerMessageItem`}
                  onClick={() => setOpenedDialog('triggerMessage')}
                >
                  {t('remoteTriggerMessage')}
                </ESMenuItem>
                <ESMenuItem
                  testId={`connector${testId}ChangeStatusItem`}
                  onClick={() => setOpenedDialog('changeStatus')}
                >
                  {t('changeStatus')}
                </ESMenuItem>
                {isTarriffServiceAvailable ? (
                  <ESMenuItem
                    testId={`connector${testId}AssignTariffItem`}
                    onClick={() => setOpenedDialog('assignTariff')}
                  >
                    {t('assignTariff')}
                  </ESMenuItem>
                ) : null}
              </ESMenu>
            </Stack>

            <EditConnectorDialog
              testId={testId}
              connector={connector}
              isOpen={openedDialog === 'edit'}
              onClose={() => setOpenedDialog(undefined)}
            />
            <UnlockConnectorDialog201
              testId={testId}
              connectorId={connector.connectorId}
              isOpen={openedDialog === 'unlock'}
              onClose={() => setOpenedDialog(undefined)}
            />
            <TriggerConnectorMessageDialog201
              testId={testId}
              identityKey={chargingStationIdentityKey}
              connectorId={connector.connectorId}
              isOpen={openedDialog === 'triggerMessage'}
              onClose={() => setOpenedDialog(undefined)}
            />
            <ChangeConnectorAvailabilityStatusDialog201
              testId={testId}
              identityKey={chargingStationIdentityKey}
              currentStatus={connector.connectorStatus}
              connectorId={connector.connectorId}
              isOpen={openedDialog === 'changeStatus'}
              onClose={() => setOpenedDialog(undefined)}
              evseId={connector.connectorId}
            />
            <AssignTariffDialog
              testId={testId}
              identityKey={chargingStationIdentityKey}
              connector={connector}
              isOpen={openedDialog === 'assignTariff'}
              onClose={() => setOpenedDialog(undefined)}
            />
          </Stack>
        }
      />
      <Divider sx={{ mt: 3, mx: 4 }} />
      <CardContent
        data-testid={`connectorCardBody${testId}`}
        sx={{
          p: 4,
          pt: 0,
          // scenario: when switching tabs, height should not jump
          minHeight: viewMode === 'grid' ? '307.31px' : '136px',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Tabs
          data-testid={`connectorTabs`}
          value={currentTabValue}
          indicatorColor="primary"
          textColor="primary"
          onChange={(_, newValue) => setCurrentTabValue(newValue)}
          variant="standard"
          sx={{
            '& .MuiTabs-flexContainer': {
              borderBottom: 'none',
            },
            pb: 4,
          }}
        >
          {tabs.map((tab) => (
            <Tab
              key={tab.value}
              data-testid={`tab${tab.value}`}
              label={t(tab.label)}
              value={tab.value}
            />
          ))}
        </Tabs>

        {currentTabValue === tabs[0].value && (
          <ConnectorGeneralTab
            connector={connector}
            testId={testId}
            viewMode={viewMode}
          />
        )}
        {tabs.length > 1 && currentTabValue === tabs[1].value && (
          <ConnectorTariffsTab
            connector={connector}
            testId={testId}
            onAssignTariff={() => setOpenedDialog('assignTariff')}
            viewMode={viewMode}
            tariff={tariff?.id ? tariff : undefined}
          />
        )}
      </CardContent>
    </Card>
  );
};
