import { createBaseQuery } from '@energy-stacks/shared';
import { createApi } from '@reduxjs/toolkit/query/react';
import { CentralSystemDto } from './centralSystemDto';
import { environment } from '@energy-stacks/feature-config';
import { CentralSystemModel } from './centralSystemModel';

export const centralSystemsApi = createApi({
  reducerPath: 'csmsCentralSystemsApi',
  tagTypes: ['CentralSystems'],
  baseQuery: createBaseQuery(`${environment.ocppServiceUrl}/broker/csms`),
  endpoints: (builder) => ({
    getCentralSystems: builder.query<CentralSystemModel[], void>({
      query: () => '/',
      providesTags: ['CentralSystems'],
      transformResponse: (dtos: CentralSystemDto[]) =>
        dtos.map((dto) => ({
          uuid: dto.uuid,
          displayName: dto.displayName,
          csmsUrl: dto.csmsUrl,
          isDefault: dto.isDefault,
        })),
    }),
    getCentralSystem: builder.query<CentralSystemModel, string>({
      query: (uuid) => `/${uuid}`,
    }),
  }),
});

export const { useGetCentralSystemsQuery, useGetCentralSystemQuery } =
  centralSystemsApi;
