import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const CHAdeMO_FemaleIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props}>
      <path d="M11.9343 8.62461C13.2492 8.62461 14.3187 7.55509 14.3187 6.24016C14.3187 4.92523 13.2492 3.85571 11.9343 3.85571C10.6193 3.85571 9.54982 4.92523 9.54982 6.24016C9.54982 7.55509 10.6193 8.62461 11.9343 8.62461ZM11.9343 4.4002C12.949 4.4002 13.7742 5.22548 13.7742 6.24016C13.7742 7.25485 12.949 8.08013 11.9343 8.08013C10.9196 8.08013 10.0943 7.25485 10.0943 6.24016C10.0943 5.22548 10.9196 4.4002 11.9343 4.4002Z" />
      <path d="M12.6864 12.0027C12.6864 14.0722 14.3701 15.7554 16.4391 15.7554C18.5081 15.7554 20.1917 14.0718 20.1917 12.0027C20.1917 9.93371 18.5081 8.25009 16.4391 8.25009C14.3701 8.25009 12.6864 9.93371 12.6864 12.0027ZM16.4391 9.22238C17.9722 9.22238 19.2195 10.4696 19.2195 12.0027C19.2195 13.5358 17.9722 14.7831 16.4391 14.7831C14.906 14.7831 13.6587 13.5358 13.6587 12.0027C13.6587 10.4696 14.906 9.22238 16.4391 9.22238Z" />
      <path d="M11.6204 6.94489L11.9339 6.45757L12.2462 6.94489H12.5892L12.1077 6.21994L12.546 5.53583H12.2162L11.9393 5.98853L11.657 5.53583H11.3245L11.7608 6.20944L11.2793 6.94489H11.6204Z" />
      <path d="M3.84055 12.0027C3.84055 13.9555 5.42927 15.5442 7.38201 15.5442C9.33476 15.5442 10.9235 13.9555 10.9235 12.0027C10.9235 10.05 9.33476 8.46124 7.38201 8.46124C5.42927 8.46163 3.84055 10.05 3.84055 12.0027ZM7.38201 9.43392C8.79845 9.43392 9.95119 10.5863 9.95119 12.0031C9.95119 13.4199 8.79883 14.5723 7.38201 14.5723C5.96558 14.5723 4.81284 13.4199 4.81284 12.0031C4.81284 10.5863 5.96558 9.43392 7.38201 9.43392Z" />
      <path d="M12.546 17.3674H12.2162L11.9393 17.8201L11.657 17.3674H11.3245L11.7608 18.041L11.2793 18.7765H11.6204L11.9339 18.2892L12.2462 18.7765H12.5892L12.1077 18.0515L12.546 17.3674Z" />
      <path d="M4.05212 5.25932L3.13972 6.10754L3.27001 6.24833L3.94867 6.97871L3.9475 6.98066L3.98756 7.02422C3.08605 8.46865 2.56451 10.1748 2.56451 12.0027C2.56451 17.1928 6.76325 21.4017 11.9491 21.4196C17.1267 21.4021 21.3336 17.1847 21.3336 12.0027C21.3336 10.3179 20.8817 8.66311 20.0264 7.21712L19.9218 7.04016L20.7883 6.10793L17.5277 3.0775L16.772 3.89422C16.7693 3.89267 16.7669 3.89111 16.7642 3.88955L16.7417 3.91406L16.5523 3.80671C15.1529 3.01333 13.5623 2.59174 11.9502 2.58591C10.2063 2.59174 8.57443 3.07166 7.17589 3.90355L7.06699 3.78571L6.40973 3.07866L4.05212 5.25932ZM20.6818 12.0027C20.6818 16.8074 16.7868 20.7028 11.9817 20.7028C7.17667 20.7028 3.28167 16.8078 3.28167 12.0027C3.28167 7.19767 7.17667 3.30268 11.9817 3.30268C16.7868 3.30268 20.6818 7.19806 20.6818 12.0027Z" />
      <path d="M11.9343 15.6873C10.6193 15.6873 9.54982 16.7568 9.54982 18.0718C9.54982 19.3863 10.6193 20.4562 11.9343 20.4562C13.2492 20.4562 14.3187 19.3867 14.3187 18.0718C14.3187 16.7572 13.2488 15.6873 11.9343 15.6873ZM11.9343 19.9117C10.9196 19.9117 10.0943 19.0864 10.0943 18.0718C10.0943 17.0571 10.9196 16.2318 11.9343 16.2318C12.949 16.2318 13.7742 17.0571 13.7742 18.0718C13.7742 19.0864 12.9486 19.9117 11.9343 19.9117Z" />
    </SvgIcon>
  );
};
