import { Tooltip, Typography, TypographyProps } from '@mui/material';
import { useCallback, useLayoutEffect, useRef, useState } from 'react';
import { useEffectOnce } from 'usehooks-ts';

type ESEllipsisTypographyProps = Pick<
  TypographyProps,
  'variant' | 'color' | 'fontWeight'
> & {
  showTooltip?: boolean;
  maxWidth?: number;
  children: string;
};

export const ESEllipsisTypography: React.FC<ESEllipsisTypographyProps> = ({
  children,
  maxWidth,
  variant,
  color,
  fontWeight,
  showTooltip = true,
}) => {
  const textRef = useRef<HTMLParagraphElement>(null);
  const [isOverflowing, setIsOverflowing] = useState(false);

  const handleElementResize = useCallback(() => {
    const element = textRef.current;

    if (element) {
      setIsOverflowing(element.scrollWidth > element.clientWidth);
    }
  }, []);

  useEffectOnce(() => {
    handleElementResize();
  });

  useLayoutEffect(() => {
    const textEl = textRef.current;
    if (textEl) {
      textEl.addEventListener('resize', handleElementResize);
    }
    return () => {
      if (textEl) {
        textEl.removeEventListener('resize', handleElementResize);
      }
    };
  }, [handleElementResize]);

  return (
    // TODO: ESTooltip component is in shared lib. It should be moved to core.
    <Tooltip title={isOverflowing && showTooltip ? children : ''}>
      <Typography
        ref={textRef}
        whiteSpace="nowrap"
        maxWidth={maxWidth}
        variant={variant}
        color={color}
        fontWeight={fontWeight}
        sx={{
          textOverflow: 'ellipsis',
          overflow: 'hidden',
        }}
      >
        {children}
      </Typography>
    </Tooltip>
  );
};
