import { TabbedScreenBoundaryWithNavigation } from './TabbedScreenBoundary';
import { Box } from '@mui/material';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { Link, Navigate, Route, Routes } from 'react-router-dom';
import { useRouteMatch } from '../useRouteMatch';

export type TabConfig = {
  tabRootPath: string;
  tabs: {
    path: string;
    component: React.FC;
    banner?: React.FC;
    name: string;
    onHover?: () => void;
    onClick?: () => void;
  }[];
};

export interface TabbedScreenProps {
  config: TabConfig;
  ScreenBoundaryComponent?: React.FunctionComponent;
}

export const TabbedScreen: React.FC<TabbedScreenProps> = ({
  config,
  ScreenBoundaryComponent = TabbedScreenBoundaryWithNavigation,
}) => {
  const routeMatch = useRouteMatch(
    config.tabs.map((tab) => `${config.tabRootPath}/${tab.path}`)
  );

  const currentTab = routeMatch?.match.pattern.path ?? false;

  return (
    <Box
      sx={(theme) => ({
        backgroundColor: theme.palette.background.default,
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        height: 'calc(100vh - 125px)', // 125 -- Page heading
      })}
    >
      <Box
        sx={(theme) => ({
          borderBottom: 1,
          borderColor: 'divider',
          backgroundColor: theme.palette.background.paper,
          px: 4,
        })}
      >
        <Tabs value={currentTab} aria-label="tabs" variant="scrollable">
          {config.tabs.map((tab) => {
            return (
              <Tab
                key={tab.name}
                label={tab.name}
                value={`${config.tabRootPath}/${tab.path}`}
                to={tab.path}
                component={Link}
                onMouseEnter={tab.onHover}
                onClick={tab.onClick}
              />
            );
          })}
        </Tabs>
      </Box>

      {config.tabs.map((tab) => {
        if (!tab.banner || currentTab !== `${config.tabRootPath}/${tab.path}`) {
          return null;
        }

        return <tab.banner key={tab.path} />;
      })}

      <Routes>
        {config.tabs.map((tab) => (
          <Route
            key={tab.path}
            path={`${tab.path}/*`}
            element={
              /** Ternary until tabs for 2.0.1 OcppProtocol are ready */
              <ScreenBoundaryComponent>
                <tab.component />
              </ScreenBoundaryComponent>
            }
          />
        ))}

        {/* if user enters a non-existent tab in url, default to first tab, e.g. General tab in Charging Station details */}
        <Route
          path="*"
          element={<Navigate to={config.tabs[0].path} replace />}
        />
      </Routes>
    </Box>
  );
};
