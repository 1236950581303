import { TabConfig } from '@energy-stacks/core/ui';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ChargingStationDetailsGeneralTab } from './protocol-v1.6/ChargingStationDetailsGeneralTab';
import { ChargingStationDetailsConfigurationTab } from './protocol-v1.6/configuration-tab/ChargingStationDetailsConfigurationTab';
import { HardResetRequiredBanner } from './protocol-v1.6/configuration-tab/HardResetRequiredBanner';
import { ChargingStationDetailsLogsTab } from './protocol-v1.6/logs/ChargingStationDetailsLogsTab';
import { ChargingStationDetailsTransactionsTab } from './protocol-v1.6/transactions/ChargingStationDetailsTransactionsTab';
import { ChargingStationDetailsConfigurationTabV201 } from './protocol-v201/ChargingStationDetailsConfigurationTabV201';
import { ChargingStationDetailsGeneralTabV201 } from './protocol-v201/ChargingStationDetailsGeneralTabV201';
import { ChargingStationDetailsLogsTabV201 } from './protocol-v201/logs/ChargingStationDetailsLogsTabV201';
import { ChargingStationDetailsSecurityEventsTabV201 } from './protocol-v201/security-events/ChargingStationDetailsSecurityEventsTabV201';
import { BrokerRoutes } from '@energy-stacks/broker/shared';
import { OcppProtocol } from '@energy-stacks/shared';
import { ChargingStationDetailsTransactionsTabV201 } from './protocol-v201/transactions/ChargingStationDetailsTransactionsTabV201';
import { ChargingStationDetailsNetworkLogsTab } from './network-logs/ChargingStationDetailsNetworkLogsTab';
import { ChargingStationDetailsAlertsTab } from './protocol-v1.6/alerts/ChargingStationDetailsAlertsTab';

export const useChargingStationDetailsTabs = (
  ocppProtocol: OcppProtocol | undefined
) => {
  const [t] = useTranslation('chargingStations');
  const [tTransactions] = useTranslation('transactions');

  const config: TabConfig = useMemo(
    () =>
      ocppProtocol === 'V_201'
        ? {
            /** Placeholders until tabs for 2.0.1 OcppProtocol are ready */
            tabRootPath: `${BrokerRoutes.ChargingStations}/${BrokerRoutes.ChargingStationDetails}`,
            tabs: [
              {
                component: ChargingStationDetailsGeneralTabV201,
                name: t('information'),
                path: BrokerRoutes.ChargingStationDetailsGeneralTabV201,
              },
              {
                component: ChargingStationDetailsConfigurationTabV201,
                name: t('configuration'),
                path: BrokerRoutes.ChargingStationDetailsConfigurationTabV201,
              },
              {
                component: ChargingStationDetailsTransactionsTabV201,
                name: tTransactions('transactions'),
                path: BrokerRoutes.ChargingStationDetailsTransactionsTabV201,
              },
              {
                component: ChargingStationDetailsLogsTabV201,
                name: t('logs'),
                path: BrokerRoutes.ChargingStationDetailsLogsTabV201,
              },
              {
                component: ChargingStationDetailsSecurityEventsTabV201,
                name: t('securityEvents'),
                path: BrokerRoutes.ChargingStationDetailsSecurityEventsTabV201,
              },
              {
                component: ChargingStationDetailsNetworkLogsTab,
                name: t('networkLogs'),
                path: BrokerRoutes.ChargingStationDetailsNetworkLogsTab,
              },
            ],
          }
        : {
            tabRootPath: `${BrokerRoutes.ChargingStations}/${BrokerRoutes.ChargingStationDetails}`,
            tabs: [
              {
                component: ChargingStationDetailsGeneralTab,
                name: t('general'),
                path: BrokerRoutes.ChargingStationDetailsGeneralTab,
              },
              {
                component: ChargingStationDetailsConfigurationTab,
                name: t('configuration'),
                path: BrokerRoutes.ChargingStationDetailsConfigurationTab,
                banner: HardResetRequiredBanner,
              },
              {
                component: ChargingStationDetailsTransactionsTab,
                name: tTransactions('transactions'),
                path: BrokerRoutes.ChargingStationDetailsTransactionsTab,
              },
              {
                component: ChargingStationDetailsLogsTab,
                name: t('logs'),
                path: BrokerRoutes.ChargingStationDetailsLogsTab,
              },
              {
                component: ChargingStationDetailsNetworkLogsTab,
                name: t('networkLogs'),
                path: BrokerRoutes.ChargingStationDetailsNetworkLogsTab,
              },
              {
                component: ChargingStationDetailsAlertsTab,
                name: t('alerts'),
                path: BrokerRoutes.ChargingStationDetailsAlertsTab,
              },
            ],
          },
    [ocppProtocol, t, tTransactions]
  );

  return { config };
};
