import { MessageDirection } from '../ocppMessageLogEntry';
import { MessageDirectionModel } from '../ocppMessageLogsModel';

export const messageDirectionNormalizer = (
  messageDirection: MessageDirection | undefined
): MessageDirectionModel => {
  if (!messageDirection) {
    return 'unknown';
  }
  return messageDirectionMap[messageDirection];
};

const messageDirectionMap: Record<MessageDirection, MessageDirectionModel> = {
  CS_2_CSMS: 'chargingStationToServer',
  CSMS_2_CS: 'serverToChargingStation',
};
