import {
  ConfigurationModel201,
  useGetConfigurationShadow201Query,
} from '@energy-stacks/broker/feature-charging-station-management-data';
import { ConfigurationTable201 } from './configuration-tab/ConfigurationTable201';
import {
  ESPageLoadingIndicator,
  RefetchOnError,
  useChargingStationIdentityKey,
} from '@energy-stacks/shared';
import { useAppSelector } from '@energy-stacks/broker/store';
import { useMemo } from 'react';

export const ChargingStationDetailsConfigurationTabV201 = () => {
  const configurationRefetchInProgress = useAppSelector(
    (state) => state.brokerManagementState.configurationRefetchInProgress
  );
  const identityKey = useChargingStationIdentityKey();
  const { data, isError, isFetching, refetch, isSuccess, isLoading } =
    useGetConfigurationShadow201Query(identityKey, {
      refetchOnMountOrArgChange: true,
      skip: !identityKey,
    });

  const configurations = useMemo(
    () => data?.ids?.map((id) => data.entities[id] as ConfigurationModel201),
    [data]
  );

  const config = configurations?.flatMap((config) => {
    return config.variableAttribute.map((attribute) => {
      return {
        component: config.component,
        variable: config.variable,
        variableCharacteristics: config.variableCharacteristics,
        variableAttribute: { ...attribute, value: attribute.value || '' },
        dateLastUpdate: config.dateLastUpdate,
      };
    });
  });

  if (configurationRefetchInProgress) {
    return <ESPageLoadingIndicator />;
  }

  return (
    <>
      {isError ? <RefetchOnError onRefetch={refetch} /> : null}
      {isLoading || isFetching ? <ESPageLoadingIndicator /> : null}
      {!isLoading && !isFetching && isSuccess && config ? (
        <ConfigurationTable201
          configuration={config || []}
          identityKey={identityKey}
        />
      ) : null}
    </>
  );
};
