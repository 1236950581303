import { Currency, TypeOfTariff } from './chargingStationDto';

export type AddTariffDataDto = {
  currency: Currency;
  type: TypeOfTariff | null;
  elements: TariffElementDto[];
  tariff_alt_text?: DisplayTextDto[];
  start_date_time?: string;
  end_date_time?: string;
  energy_mix?: EnergyMixDto;
};

export type TariffElementDto = {
  restrictions?: RestrictionsDto;
  price_components: PriceComponentDto[];
};
type DisplayTextDto = {
  language: string;
  text: string;
};
type EnergyMixDto = {
  is_green_energy: boolean;
  energy_sources: EnergySource[];
  environ_impact: EnvironmentalImpact[];
  supplier_name: string;
  energy_product_name: string;
};

export type RestrictionsDto = {
  reservation?: ReservationEnum;
  start_time?: string;
  end_time?: string;
  start_date?: string;
  end_date?: string;
  min_kwh?: number;
  max_kwh?: number;
  min_current?: number;
  max_current?: number;
  min_power?: number;
  max_power?: number;
  min_duration?: number;
  max_duration?: number;
  day_of_week?: DayOfWeek[];
};
export type PriceComponentDto = {
  type: PriceType;
  price: number;
  vat?: number;
  step_size: number;
};
type EnergySource = {
  source: SourceType;
  percentage: number;
};
type EnvironmentalImpact = {
  category: CategoryType;
  amount: number;
};

type ReservationEnum = 'RESERVATION' | 'RESERVATION_EXPIRES';
export type DayOfWeek = typeof daysOfWeek[number];
type PriceType = typeof priceTypes[number];
type SourceType =
  | 'NUCLEAR'
  | 'GENERAL_FOSSIL'
  | 'COAL'
  | 'GAS'
  | 'GENERAL_GREEN'
  | 'SOLAR'
  | 'WIND'
  | 'WATER';
type CategoryType = 'NUCLEAR_WASTE' | 'CARBON_DIOXIDE';
export const priceTypes = ['ENERGY', 'FLAT', 'PARKING_TIME', 'TIME'] as const;
export const daysOfWeek = [
  'MONDAY',
  'TUESDAY',
  'WEDNESDAY',
  'THURSDAY',
  'FRIDAY',
  'SATURDAY',
  'SUNDAY',
] as const;
