import { alpha, Box, styled } from '@mui/material';
import {
  DateRange as DateRangeCalendar,
  Range,
  RangeFocus,
} from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { addDays, endOfDay, isBefore, startOfDay, subDays } from 'date-fns';
import { DateRange, StartOfWeek } from './date-range';
import { useState } from 'react';

enum DateRangeClasses {
  Wrapper = 'date-range',
  NextPrevButton = 'date-range--nextPrevButton',
  InRange = 'date-range--inRange',
  Edge = 'date-range--edge',
  DayNumber = 'date-range--dayNumber',
  DayPassive = 'date-range--dayPassive',
  MonthAndYearWrapper = 'date-range--monthAndYearWrapper',
}

const DateRangeWrapper = styled(Box)(({ theme }) => ({
  [`& .${DateRangeClasses.Wrapper}`]: {
    fontFamily: theme.typography.fontFamily,
    paddingTop: 0,
  },
  [`& .${DateRangeClasses.Wrapper} select`]: {
    fontSize: theme.typography.body1.fontSize,
    fontWeight: theme.typography.body1.fontWeight,
    fontFamily: theme.typography.fontFamily,
  },
  [`& .${DateRangeClasses.MonthAndYearWrapper}`]: {
    paddingTop: 0,
  },
  [`& .${DateRangeClasses.MonthAndYearWrapper} select option`]: {
    textAlign: 'left',
  },
  [`& .${DateRangeClasses.Wrapper} select:hover`]: {
    backgroundColor: theme.palette.common.white,
  },
  [`& .${DateRangeClasses.NextPrevButton}`]: {
    borderRadius: '1000px',
    backgroundColor: theme.palette.grey[300],
  },
  [`& .${DateRangeClasses.InRange}`]: {
    backgroundColor: alpha(theme.palette.primary.main, 0.12),
  },
  [`& .${DateRangeClasses.InRange} ~ .${DateRangeClasses.DayNumber} span`]: {
    color: `${theme.palette.text.primary} !important`,
  },
  [`& .${DateRangeClasses.Edge}`]: {
    backgroundColor: alpha(theme.palette.primary.main, 0.12),
  },
  [`& .${DateRangeClasses.Edge}::after`]: {
    content: "''",
    width: '100%',
    height: '100%',
    backgroundColor: theme.palette.primary.main,
    position: 'absolute',
    borderRadius: '1000px',
    left: 0,
  },
}));

interface DateRangePickerCalendarProps {
  dateRange: DateRange;
  onDateRangeChange: (newRange: DateRange) => void;
  weekStartsOn?: StartOfWeek;
}

export const DateRangePickerCalendar: React.FC<
  DateRangePickerCalendarProps
> = ({ dateRange, onDateRangeChange, weekStartsOn }) => {
  const {
    startDate,
    endDate,
    minDate: initialMinDate,
    maxStartDateOffset,
    disabledDay: newDisabledDay,
  } = dateRange;
  const [maxDate, setMaxDate] = useState<Date | undefined>(undefined);
  const [minDate, setMinDate] = useState<Date | undefined>(initialMinDate);
  const [disabledDay, setDisabledDay] = useState<
    ((date: Date) => boolean) | undefined
  >(() => newDisabledDay);

  return (
    <DateRangeWrapper>
      {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
      {/* @ts-ignore */}
      <DateRangeCalendar
        data-testid="dateRangePickerCalendar"
        className={DateRangeClasses.Wrapper}
        classNames={{
          nextPrevButton: DateRangeClasses.NextPrevButton,
          inRange: DateRangeClasses.InRange,
          startEdge: DateRangeClasses.Edge,
          endEdge: DateRangeClasses.Edge,
          dayNumber: DateRangeClasses.DayNumber,
          dayPassive: DateRangeClasses.DayPassive,
          monthAndYearWrapper: DateRangeClasses.MonthAndYearWrapper,
        }}
        ranges={[
          {
            startDate: startDate,
            endDate: endDate,
            key: 'selection',
          },
        ]}
        maxDate={maxDate}
        minDate={minDate}
        weekStartsOn={weekStartsOn}
        disabledDay={disabledDay}
        onChange={(item) => {
          const range = item['selection'] as Range | undefined;
          if (range) {
            const { startDate, endDate } = range;
            if (startDate && endDate) {
              const newMaxDate = maxStartDateOffset
                ? addDays(endOfDay(startDate), maxStartDateOffset)
                : undefined;

              const newMinDate = maxStartDateOffset
                ? subDays(endOfDay(startDate), maxStartDateOffset)
                : undefined;

              if (newMaxDate) setMaxDate(newMaxDate);
              if (initialMinDate && newMinDate) {
                setMinDate(
                  isBefore(newMinDate, initialMinDate)
                    ? initialMinDate
                    : newMinDate
                );
              }
              if (newDisabledDay) setDisabledDay(() => disabledDay);

              onDateRangeChange({
                ...dateRange,
                startDate: startOfDay(startDate),
                endDate: endOfDay(endDate),
              });
            }
          }
        }}
        onRangeFocusChange={(newFocusedRange: RangeFocus) => {
          // When newFocused range is [0, 0] we know that user has selected the full range
          // so we reset the maxDate and minDate to default values
          if (newFocusedRange[0] === 0 && newFocusedRange[1] === 0) {
            setMaxDate(undefined);
            setMinDate(initialMinDate);
          }
        }}
        showDateDisplay={false}
      />
    </DateRangeWrapper>
  );
};
