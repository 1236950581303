export const addProviderApiErrors: Record<string, string> = {
  PROVIDER_NAME_ALREADY_EXISTS: 'providerNameAlreadyExists',
  PROVIDER_ALREADY_EXISTS: 'providerIdAlreadyExists',
  PROVIDER_NAME_NOT_FOUND: 'providerNameNotFound',
  PROVIDER_ID_NOT_FOUND: 'providerIdNotFound',
  PROVIDER_COUNTRY_CODE_DOES_NOT_EXISTS: 'providerCountryCodeNotValid',
};

export const editProviderApiErrors: Record<string, string> = {
  PROVIDER_NAME_ALREADY_EXISTS: 'providerNameAlreadyExists',
  PROVIDER_ALREADY_EXISTS: 'providerIdAlreadyExists',
  PROVIDER_NAME_NOT_FOUND: 'providerNameNotFound',
  PROVIDER_ID_NOT_FOUND: 'providerIdNotFound',
  PROVIDER_COUNTRY_CODE_DOES_NOT_EXISTS: 'providerCountryCodeNotValid',
};
