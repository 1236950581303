import { ChargingStationEventSource } from './ChargingStationEventSource';
import { ArrowForwardRounded } from '@mui/icons-material';
import { Stack } from '@mui/material';
import { TranslatorEventSource } from './TranslatorEventSource';

export const ChargingStationToTranslator = () => {
  return (
    <Stack direction={'row'} alignItems="center" spacing={4}>
      <ChargingStationEventSource />
      <ArrowForwardRounded
        sx={{ color: (theme) => theme.palette.action.active }}
      />
      <TranslatorEventSource />
    </Stack>
  );
};
