import React, { useCallback } from 'react';
import {
  useDeleteBrokerLocationMutation,
  useGetAllLocationsBrokerQuery,
} from '@energy-stacks/broker/feature-locations-data';
import { useESSnackbar } from '@energy-stacks/core/ui';
import { useNavigate, useParams } from 'react-router-dom';
import { BrokerDeleteLocationInfoDialog } from './BrokerDeleteLocationInfoDialog';
import { BrokerDeleteLocationConfirmDialog } from './BrokerDeleteLocationConfirmDialog';

interface BrokerDeleteLocationProps {
  locationId?: string;
  onClose?: () => void;
  isOpen?: boolean;
}

export const BrokerDeleteLocation: React.FC<BrokerDeleteLocationProps> = ({
  locationId,
  onClose,
  isOpen = true,
}) => {
  const navigate = useNavigate();
  const { showSnackbar } = useESSnackbar();

  const { locationUuid } = useParams<{ locationUuid: string }>();

  const [deleteLocation] = useDeleteBrokerLocationMutation();
  const { location: locationToDelete } = useGetAllLocationsBrokerQuery(
    undefined,
    {
      selectFromResult: ({ data }) => ({
        location: data?.find(
          (location) =>
            location.uuid === locationUuid || location.uuid === locationId
        ),
      }),
    }
  );

  const handleCloseDialog = useCallback(() => {
    onClose ? onClose() : navigate(-1);
  }, [navigate, onClose]);

  const handleDeleteLocation = useCallback(() => {
    if (!locationToDelete?.uuid) {
      return;
    }
    return deleteLocation(locationToDelete.uuid)
      .unwrap()
      .then(() => {
        showSnackbar('success', 'deleteLocationSuccessMessage', 'locations');
        navigate(-1);
      })
      .catch(() => {
        showSnackbar('error');
      });
  }, [locationToDelete?.uuid, deleteLocation, showSnackbar, navigate]);

  if (!locationToDelete) {
    return null;
  }

  return locationToDelete.chargingStations.length > 0 ? (
    <BrokerDeleteLocationInfoDialog
      isOpen={isOpen}
      onClose={handleCloseDialog}
    />
  ) : (
    <BrokerDeleteLocationConfirmDialog
      isOpen={isOpen}
      onCancel={handleCloseDialog}
      onConfirm={handleDeleteLocation}
    />
  );
};
