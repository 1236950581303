import { BusinessAccountDetailsModel } from '@energy-stacks/fleet/feature-business-accounts-data';
import { Chip, Typography } from '@mui/material';

type TankTypeChipProps = {
  variant: keyof BusinessAccountDetailsModel['locations'] | '';
  label: string;
};

const variantColorsMap: Record<
  TankTypeChipProps['variant'],
  { backgroundColor: string; color: string }
> = {
  degassed: {
    backgroundColor: 'warning.light',
    color: 'warning.800',
  },
  raw: {
    backgroundColor: 'cyan.50',
    color: 'cyan.600',
  },
  '': {
    backgroundColor: 'grey.200',
    color: 'grey.600',
  },
};

export const TankTypeChip: React.FC<TankTypeChipProps> = ({
  variant,
  label,
}) => {
  if (!label || !variant) {
    return <Typography>---</Typography>;
  }

  return (
    <Chip
      label={label.toUpperCase()}
      sx={{
        ...variantColorsMap[variant],
        fontWeight: 500,
      }}
    />
  );
};
