export interface TransactionModel {
  identityKey: string;
  chargingStationName: string;
  transactionId: number | string;
  evseId: string;
  sessionStart: string;
  sessionStop: string;
  duration: string;
  consumption: string;
  connectorId: number;
  tokenUid: string;
  transactionStatus: TransactionStatusEnum;
  transactionFinishReason: TransactionFinishReasonEnum;
}

export enum TransactionStatusEnum {
  successFinish = 'successFinish',
  errorFinish = 'errorFinish',
  charging = 'charging',
}

export enum TransactionFinishReasonEnum {
  deAuthorized = 'deAuthorized',
  emergencyStop = 'emergencyStop',
  eVDisconnected = 'eVDisconnected',
  hardReset = 'hardResetReason',
  local = 'local',
  other = 'other',
  powerLoss = 'powerLoss',
  reboot = 'reboot',
  remote = 'remote',
  softReset = 'softResetReason',
  unlockCommand = 'unlockCommand',
}

export interface TransactionSearchModel {
  transactions?: TransactionModel[];
  totalElements: number;
  totalPages: number;
}
