import { Box, Grid, Typography } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { BROKER_ADD_CHARGING_STATION_FORM_HEIGHT } from './BrokerAddChargingStationForm';
import { BrokerConnectorFormData } from '@energy-stacks/broker/feature-charging-stations-data';
import { useGetUiBrandingQuery } from '@energy-stacks/broker/feature-settings-data';

export const BrokerAddChargingStationOverview = () => {
  const [t] = useTranslation('chargingStations');
  const { getValues } = useFormContext();
  const values = getValues();
  const { data: uiBranding } = useGetUiBrandingQuery();
  const hasBorderRadius =
    uiBranding?.hasBorderRadius || uiBranding?.hasBorderRadius === undefined;

  return (
    <Grid spacing={6} container data-testid="addStationOverviewContainer">
      <Grid item xs={12} sm={6}>
        <OverviewBox
          text={t('identityKeyOverView')}
          formValue={values['identityKey']}
          testId="identityKeyOverViewBox"
        />
        <OverviewBox
          text={t('name')}
          formValue={values['chargePointName'] || 'N/A'}
          testId="nameOverViewBox"
        />
        <OverviewBox
          text={t('centralSystem')}
          formValue={values['centralSystem'].label || 'N/A'}
          testId="centralSystemOverViewBox"
        />
        <OverviewBox
          text={t('chargingSite')}
          formValue={values['location'].label || 'N/A'}
          testId="chargingSiteOverViewBox"
        />
      </Grid>
      <Grid item xs={12} sm={6} mb={4}>
        {values['connectors'].length ? (
          <Box
            borderRadius={hasBorderRadius ? 5 : 0}
            sx={{
              backgroundColor: 'grey.50',
              borderTop: (theme) => `1px solid ${theme.palette.grey[100]}`,
              maxHeight: `${BROKER_ADD_CHARGING_STATION_FORM_HEIGHT}px`,
              overflowY: 'scroll',
            }}
          >
            {values['connectors'].map(
              (_: BrokerConnectorFormData, index: number) => {
                return (
                  <Box
                    p={4}
                    sx={{
                      borderBottom: (theme) =>
                        `1px solid ${theme.palette.grey[100]}`,
                    }}
                    key={index}
                  >
                    {t('connector')} {index + 1}
                  </Box>
                );
              }
            )}
          </Box>
        ) : null}
      </Grid>
    </Grid>
  );
};

interface OverviewBoxProps {
  text: string;
  formValue: string;
  testId?: string;
}

const OverviewBox: React.FC<OverviewBoxProps> = ({
  text,
  formValue,
  testId,
}) => {
  return (
    <Box mb={6} data-testid={testId}>
      <Typography mb={1} variant="body1" color="grey.500">
        {text}
      </Typography>
      <Typography variant="subtitle1">{formValue}</Typography>
    </Box>
  );
};
