import { TariffModel } from '@energy-stacks/broker/feature-tariffs-data';
import { StackValuePair } from '@energy-stacks/core/ui';
import { formatDateTime } from '@energy-stacks/shared';
import { Box, Typography } from '@mui/material';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

type TariffGeneralInfoViewProps = {
  tariff: TariffModel;
};

export const TariffGeneralInfoView = ({
  tariff,
}: TariffGeneralInfoViewProps) => {
  const { t } = useTranslation('tariffs');

  const generalTariffValues = useMemo(() => {
    return {
      tariffId: tariff.id,
      tariffCurrency: tariff.currency,
      tariffType: tariff.type ? t(tariff.type) : '-',
      tariffValidFrom: tariff.startDateTime
        ? formatDateTime(tariff.startDateTime)
        : '-',
      tariffExpires: tariff.endDateTime
        ? formatDateTime(tariff.endDateTime)
        : '-',
    };
  }, [
    t,
    tariff.currency,
    tariff.endDateTime,
    tariff.id,
    tariff.startDateTime,
    tariff.type,
  ]);

  return (
    <Box>
      <Typography
        variant="h4"
        fontWeight={700}
        lineHeight={1.5}
        color={'grey.900'}
        sx={{ pb: 2 }}
      >
        {t('generalInfo')}
      </Typography>
      {Object.entries(generalTariffValues).map((section) => {
        return (
          <StackValuePair
            key={section[0]}
            property={t(section[0])}
            value={section[1]}
          />
        );
      })}
    </Box>
  );
};
