import { ConnectorModel } from '@energy-stacks/broker/feature-charging-stations-data';
import { formatCurrentAndMaxPower } from '@energy-stacks/shared';
import { Box, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface ConnectorGeneralTabListViewProps {
  connector: ConnectorModel;
  testId: string;
}

export const ConnectorGeneralTabListView = ({
  connector,
  testId,
}: ConnectorGeneralTabListViewProps) => {
  const [t] = useTranslation('chargingStations');
  return (
    <>
      <Box display="flex" justifyContent="space-between" pr={4}>
        <Stack data-testid={`connector${testId}Type`}>
          <Typography
            variant="body2"
            fontWeight={400}
            marginBottom={1.5}
            color="grey.600"
          >
            {t('type')}
          </Typography>
          <Typography variant="body2" color="grey.900" fontWeight={500}>
            {connector.connectorType
              ? t(`connectorTypes.${connector.connectorType}`)
              : 'N/A'}
          </Typography>
        </Stack>
        <Stack data-testid={`connector${testId}Format`}>
          <Typography
            variant="body2"
            fontWeight={400}
            marginBottom={1.5}
            color="grey.600"
          >
            {t('format')}
          </Typography>
          <Typography variant="body2" color="grey.900" fontWeight={500}>
            {connector.connectorFormat ? t(connector.connectorFormat) : 'N/A'}
          </Typography>
        </Stack>

        <Stack data-testid={`connector${testId}Power`}>
          <Typography
            variant="body2"
            fontWeight={400}
            marginBottom={1.5}
            color="grey.600"
          >
            {t('powerType')}
          </Typography>
          <Typography variant="body2" color="grey.900" fontWeight={500}>
            {connector.powerType
              ? t(`powerTypes.${connector.powerType}`)
              : 'N/A'}
          </Typography>
        </Stack>

        <Stack data-testid={`connector${testId}ConnectorVoltage`}>
          <Typography
            variant="body2"
            fontWeight={400}
            marginBottom={1.5}
            color="grey.600"
          >
            {t('connectorVoltage')}
          </Typography>
          <Typography variant="body2" color="grey.900" fontWeight={500}>
            {`${connector.currentVoltage}/${connector.maxVoltage} V`}
          </Typography>
        </Stack>

        <Stack data-testid={`connector${testId}ConnectorAmperage`}>
          <Typography
            variant="body2"
            fontWeight={400}
            marginBottom={1.5}
            color="grey.600"
          >
            {t('connectorAmperage')}
          </Typography>
          <Typography variant="body2" color="grey.900" fontWeight={500}>
            {`${connector.currentAmperage}/${connector.maxAmperage} A`}
          </Typography>
        </Stack>

        <Stack data-testid={`connector${testId}ConnectorPower`}>
          <Typography
            variant="body2"
            fontWeight={400}
            marginBottom={1.5}
            color="grey.600"
          >
            {t('connectorPower')}
          </Typography>
          <Typography variant="body2" color="grey.900" fontWeight={500}>
            {formatCurrentAndMaxPower(
              connector.currentPower,
              connector.maxPower
            )}
          </Typography>
        </Stack>
      </Box>
    </>
  );
};
