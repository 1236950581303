import { formatDuration } from '@energy-stacks/core/ui';
import { isNil } from 'lodash-es';
import { BrokerTransactionDto, Reason } from '../brokerTransactionDto';
import {
  TransactionFinishReasonEnum,
  TransactionModel,
  TransactionStatusEnum,
} from '../transactionModel';
import { appendZToDateString } from '@energy-stacks/shared';

export const transactionNormalizer = (
  transaction: BrokerTransactionDto
): TransactionModel => {
  const localizedTimestampStart = appendZToDateString(
    transaction.timestampStart
  );
  const localizedTimestampStop = appendZToDateString(transaction.timestampStop);
  return {
    connectorId: transaction.connectorId,
    chargingStationName: transaction.chargingStationName,
    identityKey: transaction.identityKey,
    evseId: transaction.evseId,
    tokenUid: transaction.idTagStart,
    transactionId: transaction.transactionId,
    sessionStart: appendZToDateString(transaction.timestampStart),
    sessionStop: appendZToDateString(transaction.timestampStop),
    duration: durationRule(localizedTimestampStart, localizedTimestampStop),
    consumption: consumptionRule(transaction.meterStart, transaction.meterStop),
    transactionStatus: transactionStatusRule(
      localizedTimestampStop,
      transaction.reason ?? 'Other'
    ),
    transactionFinishReason: reasonNormalizer(transaction.reason),
  };
};

const durationRule = (dateStart: string, dateEnd: string | null) => {
  if (!dateEnd) {
    return '-';
  }
  const startDate = new Date(dateStart);
  const endDate = new Date(dateEnd);
  return formatDuration(startDate, endDate);
};

const consumptionRule = (
  meterStart: number | undefined,
  meterStop: number | undefined
) => {
  if (isNil(meterStart) || isNil(meterStop)) {
    return '-';
  }
  const consumption = meterStop - meterStart;
  return `${consumption / 1000} kWh`;
};

// Deduct transaction status from timestampEnd field for now. BE will send us the status field in the future
const transactionStatusRule = (
  dateEnd: string | null,
  transactionFinishReason: Reason
): TransactionStatusEnum => {
  if (isNil(dateEnd) || !dateEnd) {
    return TransactionStatusEnum.charging;
  }
  if (errorFinishReasons.includes(transactionFinishReason)) {
    return TransactionStatusEnum.errorFinish;
  }
  return TransactionStatusEnum.successFinish;
};

const reasonNormalizer = (
  transactionFinishReason: Reason | undefined
): TransactionFinishReasonEnum => {
  if (!transactionFinishReason) {
    return TransactionFinishReasonEnum.other;
  }
  return transactionFinishReasonToReasonMap[transactionFinishReason];
};

const transactionFinishReasonToReasonMap: Record<
  Reason,
  TransactionModel['transactionFinishReason']
> = {
  DeAuthorized: TransactionFinishReasonEnum.deAuthorized,
  EmergencyStop: TransactionFinishReasonEnum.emergencyStop,
  EVDisconnected: TransactionFinishReasonEnum.eVDisconnected,
  HardReset: TransactionFinishReasonEnum.hardReset,
  Local: TransactionFinishReasonEnum.local,
  Other: TransactionFinishReasonEnum.other,
  PowerLoss: TransactionFinishReasonEnum.powerLoss,
  Reboot: TransactionFinishReasonEnum.reboot,
  Remote: TransactionFinishReasonEnum.remote,
  SoftReset: TransactionFinishReasonEnum.softReset,
  UnlockCommand: TransactionFinishReasonEnum.unlockCommand,
} as const;

const errorFinishReasons: Partial<Reason>[] = [
  'EmergencyStop',
  'HardReset',
  'SoftReset',
  'Reboot',
  'PowerLoss',
];
