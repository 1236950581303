import {
  ESButton,
  ESTable,
  ESTableBodyCell,
  ESTableBodyRow,
  ESTableHead,
} from '@energy-stacks/core/ui';
import { Paper, Stack, TableBody } from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { ConfigurationFormEntry } from '../configurationFormEntry';

interface ConfigurationOverviewTableProps {
  configuration: ConfigurationFormEntry[];
  onCancel: () => void;
  onSend: () => void;
}

export const ConfigurationOverviewTable: React.FC<
  ConfigurationOverviewTableProps
> = ({ configuration, onCancel, onSend }) => {
  const [t] = useTranslation('chargingStations');
  const columnHelper = createColumnHelper<{
    key: string;
    newValue: string;
  }>();

  const columns = [
    columnHelper.accessor('key', {
      id: 'key',
      header: () => t('configurationKey'),
      footer: (props) => props.column.id,
      cell: (info) => info.getValue() || '-',
      enableSorting: false,
    }),
    columnHelper.accessor('newValue', {
      header: () => t('newValue'),
      footer: (props) => props.column.id,
      cell: (info) => info.getValue() || '-',
      enableSorting: false,
    }),
  ];

  const instance = useReactTable({
    data: configuration,
    columns: columns,
    getCoreRowModel: getCoreRowModel(),
  });

  const rows = instance.getRowModel().rows;

  return (
    <Paper
      variant="outlined"
      sx={{ display: 'flex', flexDirection: 'column', flex: 1 }}
    >
      <ESTable contentStyle={{ minWidth: 'unset' }}>
        <ESTableHead instance={instance} />
        <TableBody
          id="es-table-body-scrollable-content"
          sx={{
            // per design, the last table row has a bottom border, but table footer has a top border, the two borders look bad when stacked, so we remove the row border
            'tr:last-child > td': {
              borderBottom: 0,
            },
          }}
        >
          {rows.map((row) => {
            return (
              <ESTableBodyRow key={row.id} selected>
                {row.getVisibleCells().map((cell) => {
                  return (
                    <ESTableBodyCell key={cell.id}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </ESTableBodyCell>
                  );
                })}
              </ESTableBodyRow>
            );
          })}
        </TableBody>
      </ESTable>
      <Stack
        gap={3}
        direction="row"
        justifyContent="end"
        sx={{
          p: 4,
          borderTop: (theme) => `1px solid ${theme.palette.divider}`,
        }}
      >
        <ESButton variant="text" color="error" onClick={onCancel}>
          {t('cancel')}
        </ESButton>
        <ESButton onClick={onSend}>{t('send')}</ESButton>
      </Stack>
    </Paper>
  );
};
