import { OcppProtocol } from '@energy-stacks/shared';
import { useTranslation } from 'react-i18next';
import { OcppProtocolChip } from './OcppProtocolChip';

interface OcppProtocolChipProps {
  protocol: OcppProtocol;
}

const ChargingStationProtocolsMapper: Record<OcppProtocol, string> = {
  'N/A': 'N/A',
  V_15: 'V_16',
  V_20: 'V_16',
  V_16: 'V_16',
  V_201: 'V_201',
};

export const ChargingStationProtocolChip: React.FC<OcppProtocolChipProps> = ({
  protocol,
}) => {
  const { t } = useTranslation('chargingStations');

  return protocol === 'N/A' ? (
    <OcppProtocolChip label={t('emptyOccpProtocol')} />
  ) : (
    <OcppProtocolChip label={t(ChargingStationProtocolsMapper[protocol])} />
  );
};
