import { env } from './lib/environments/environment';

const domain = env['NX_DOMAIN'];

export const environment = {
  type: env['NX_TYPE'],
  auth: {
    authority: env['NX_AUTH_AUTHORITY'],
    client_id: env['NX_AUTH_CLIENT_ID'],
    userInfoEndpoint: env['NX_AUTH_USER_INFO_ENDPOINT'],
    scope: env['NX_AUTH_OPEN_ID_SCOPE'],
  },
  domain,
  mocking: env['NX_MOCKING'] === 'true',
  mockingIntegration: env['NX_MOCKING_INTEGRATION'] === 'true',
  stompUrl: env['NX_STOMP_URL'],
  ocppServiceUrl: env['NX_OCPP_SERVICE_URL'],
  googleApiKey: env['NX_GOOGLE_API_KEY'],
  storageServerUrl: env['NX_STORAGE_SERVER_URL'],
  storybook: env['STORYBOOK'] === 'true',
};
