import { createSlice } from '@reduxjs/toolkit';

export const BROKER_MANAGEMENT_FEATURE_KEY = 'brokerManagementState';

export interface BrokerManagementState {
  chargingStationCoreManagementAction: boolean;
  configurationRefetchInProgress: boolean;
}

const initialState: BrokerManagementState = {
  chargingStationCoreManagementAction: false,
  configurationRefetchInProgress: false,
};

export const brokerManagementState = createSlice({
  name: BROKER_MANAGEMENT_FEATURE_KEY,
  initialState,
  reducers: {
    startChargingStationCoreManagementAction: (state) => {
      state.chargingStationCoreManagementAction = true;
    },
    endChargingStationCoreManagementAction: (state) => {
      state.chargingStationCoreManagementAction = false;
    },
    startConfigurationRefetch: (state) => {
      state.configurationRefetchInProgress = true;
    },
    endConfigurationRefetch: (state) => {
      state.configurationRefetchInProgress = false;
    },
  },
});

export const {
  startChargingStationCoreManagementAction,
  endChargingStationCoreManagementAction,
  startConfigurationRefetch,
  endConfigurationRefetch,
} = brokerManagementState.actions;

export const brokerManagementReducer = brokerManagementState.reducer;
