import { useCallback, useEffect } from 'react';

const THRESHOLD = 60;
const SCROLL_SPEED = 10;

export const useScrollOnDrag = <T extends HTMLElement>({
  scrollContainer,
  threshold = THRESHOLD,
  scrollSpeed = SCROLL_SPEED,
}: {
  scrollContainer: T | null;
  threshold?: number;
  scrollSpeed?: number;
}) => {
  const handleDragOver = useCallback(
    (e: DragEvent) => {
      if (!scrollContainer) {
        return;
      }
      const rect = scrollContainer.getBoundingClientRect();
      const offsetY = e.clientY - rect.top;

      if (offsetY < threshold) {
        scrollContainer.scrollTop -= scrollSpeed;
      } else if (offsetY > rect.height - threshold) {
        scrollContainer.scrollTop += scrollSpeed;
      }
    },
    [scrollContainer, scrollSpeed, threshold]
  );

  useEffect(() => {
    scrollContainer?.addEventListener('dragover', handleDragOver);

    return () => {
      scrollContainer?.removeEventListener('dragover', handleDragOver);
    };
  }, [handleDragOver, scrollContainer]);
};
