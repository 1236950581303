import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const CcsMaleIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props}>
      <path d="M12.0264 14.1519C15.6671 14.1519 18.6287 11.1903 18.6287 7.54965C18.6287 6.24639 18.258 5.01703 17.5265 3.89695C16.7164 2.65553 12.5095 2.70454 12.0295 2.70454C11.5492 2.69559 7.44967 2.54002 6.53455 3.88412C5.80767 4.9513 5.42419 6.21878 5.42419 7.54965C5.42458 11.1903 8.38579 14.1519 12.0264 14.1519ZM6.99853 4.2672C7.83664 3.03628 11.5897 3.17901 12.0295 3.18718C12.469 3.18718 16.3205 3.14246 17.0621 4.27887C17.7318 5.30444 18.071 6.42996 18.071 7.62315C18.071 10.9562 15.3594 13.6681 12.026 13.6681C8.69264 13.6681 5.9819 10.9565 5.9819 7.62315C5.98229 6.40507 6.33348 5.24416 6.99853 4.2672Z" />
      <path d="M10.5101 9.51834C9.99671 9.51834 9.57901 9.93604 9.57901 10.4494C9.57901 10.9628 9.99671 11.3805 10.5101 11.3805C11.0234 11.3805 11.4411 10.9628 11.4411 10.4494C11.4411 9.93604 11.0234 9.51834 10.5101 9.51834Z" />
      <path d="M13.4285 9.51834C12.9155 9.51834 12.4978 9.93604 12.4978 10.4494C12.4978 10.9628 12.9151 11.3805 13.4285 11.3805C13.9418 11.3805 14.3595 10.9628 14.3595 10.4494C14.3595 9.93604 13.9418 9.51834 13.4285 9.51834Z" />
      <path d="M12.0614 8.72728C12.5744 8.72728 12.9921 8.30958 12.9921 7.79622C12.9921 7.28324 12.5748 6.86554 12.0614 6.86554C11.5481 6.86554 11.1304 7.28285 11.1304 7.79622C11.1304 8.30958 11.5481 8.72728 12.0614 8.72728Z" />
      <path d="M10.6124 6.1135C10.8524 6.05684 11.001 5.81636 10.9443 5.57639C10.8877 5.33641 10.6472 5.18781 10.4072 5.24448C10.1673 5.30114 10.0187 5.54161 10.0753 5.78159C10.132 6.02156 10.3725 6.17016 10.6124 6.1135Z" />
      <path d="M13.46 6.12624C13.7066 6.12624 13.9069 5.92595 13.9069 5.67976C13.9069 5.43357 13.7062 5.23328 13.46 5.23328C13.2138 5.23328 13.0135 5.43357 13.0135 5.67976C13.0135 5.92595 13.2138 6.12624 13.46 6.12624Z" />
      <path d="M9.97376 7.79585C9.97376 7.28287 9.55607 6.86517 9.0427 6.86517C8.52933 6.86517 8.11163 7.28248 8.11163 7.79585C8.11163 8.30922 8.52933 8.72691 9.0427 8.72691C9.55607 8.72691 9.97376 8.30961 9.97376 7.79585Z" />
      <path d="M15.0106 8.72728C15.524 8.72728 15.9417 8.30958 15.9417 7.79622C15.9417 7.28324 15.524 6.86554 15.0106 6.86554C14.4972 6.86554 14.0795 7.28285 14.0795 7.79622C14.0795 8.30958 14.4972 8.72728 15.0106 8.72728Z" />
      <path d="M12.0264 13.1259C15.0604 13.1259 17.5288 10.6575 17.5288 7.62354C17.5288 6.53691 17.2188 5.51135 16.6079 4.57562C16.1734 3.9098 13.6478 3.7729 12.0525 3.75734C11.7857 3.74178 11.4952 3.75734 11.1813 3.75734C9.70266 3.75734 7.86037 3.96463 7.44695 4.57251C6.84297 5.45923 6.52444 6.51397 6.52444 7.62354C6.52483 10.6575 8.9925 13.1259 12.0264 13.1259ZM10.51 11.8293C9.74933 11.8293 9.13018 11.2101 9.13018 10.4494C9.13018 9.68867 9.74933 9.06952 10.51 9.06952C11.2708 9.06952 11.8899 9.68867 11.8899 10.4494C11.8899 11.2101 11.2708 11.8293 10.51 11.8293ZM13.4285 11.8293C12.6678 11.8293 12.049 11.2101 12.049 10.4494C12.049 9.68867 12.6678 9.06952 13.4285 9.06952C14.1892 9.06952 14.8083 9.68867 14.8083 10.4494C14.8083 11.2101 14.1896 11.8293 13.4285 11.8293ZM16.3905 7.79583C16.3905 8.55654 15.7713 9.1757 15.0106 9.1757C14.2499 9.1757 13.6307 8.55654 13.6307 7.79583C13.6307 7.03511 14.2499 6.41635 15.0106 6.41635C15.7713 6.41674 16.3905 7.0355 16.3905 7.79583ZM13.46 4.78446C13.9539 4.78446 14.3556 5.18621 14.3556 5.67974C14.3556 6.17328 13.9539 6.57503 13.46 6.57503C12.9664 6.57503 12.5647 6.17328 12.5647 5.67974C12.5647 5.18621 12.9664 4.78446 13.46 4.78446ZM12.0614 6.41674C12.8222 6.41674 13.4409 7.0355 13.4409 7.79622C13.4409 8.55693 12.8222 9.17608 12.0614 9.17608C11.3007 9.17608 10.6816 8.55693 10.6816 7.79622C10.6816 7.0355 11.3007 6.41674 12.0614 6.41674ZM10.51 4.78446C11.0036 4.78446 11.4053 5.18621 11.4053 5.67974C11.4053 6.17328 11.0036 6.57503 10.51 6.57503C10.0165 6.57503 9.61477 6.17328 9.61477 5.67974C9.61477 5.18621 10.0165 4.78446 10.51 4.78446ZM7.6628 7.79583C7.6628 7.03511 8.28195 6.41635 9.04267 6.41635C9.80339 6.41635 10.4225 7.03511 10.4225 7.79583C10.4225 8.55654 9.80339 9.1757 9.04267 9.1757C8.28195 9.1757 7.6628 8.55693 7.6628 7.79583Z" />
      <path d="M14.8611 18.7585C15.3074 18.6861 15.6105 18.2656 15.538 17.8192C15.4656 17.3729 15.0451 17.0699 14.5988 17.1423C14.1525 17.2147 13.8494 17.6352 13.9218 18.0815C13.9943 18.5279 14.4148 18.8309 14.8611 18.7585Z" />
      <path d="M10.0984 18.2638C10.2714 17.8461 10.0731 17.3672 9.65535 17.1941C9.23762 17.0211 8.75871 17.2195 8.58567 17.6372C8.41264 18.0549 8.61101 18.5338 9.02874 18.7069C9.44647 18.8799 9.92538 18.6815 10.0984 18.2638Z" />
      <path d="M17.7116 15.0903C17.1461 14.5038 16.3535 14.1935 15.4193 14.1935H8.63082C7.69664 14.1935 6.90403 14.5034 6.33855 15.0903C5.71006 15.7421 5.39154 16.6892 5.39154 17.9049C5.39154 19.1207 5.71006 20.0677 6.33855 20.7195C6.90403 21.306 7.69664 21.6163 8.63082 21.6163H15.4193C16.3535 21.6163 17.1461 21.3064 17.7116 20.7195C18.3401 20.0677 18.6586 19.1207 18.6586 17.9049C18.6586 16.6892 18.3401 15.7421 17.7116 15.0903ZM17.271 20.3205C16.7809 20.8128 16.0891 21.073 15.2692 21.073H8.78678C7.96694 21.073 7.27467 20.8128 6.78503 20.3205C6.23315 19.7659 5.95352 18.9484 5.95352 17.8913C5.95352 16.8338 6.23315 16.0167 6.78503 15.4621C7.27506 14.9698 7.96733 14.7096 8.78678 14.7096H15.2692C16.0891 14.7096 16.7813 14.9698 17.271 15.4621C17.8228 16.0167 18.1025 16.8338 18.1025 17.8913C18.1025 18.9484 17.8228 19.7659 17.271 20.3205Z" />
      <path d="M15.2692 15.2541H8.78678C7.31084 15.2541 6.49762 16.1906 6.49762 17.8913C6.49762 19.592 7.31045 20.5285 8.78678 20.5285H15.2692C16.7452 20.5285 17.5584 19.592 17.5584 17.8913C17.5584 16.1906 16.7452 15.2541 15.2692 15.2541ZM9.34137 19.9969C8.21313 19.9969 7.2949 19.0787 7.2949 17.9504C7.2949 16.8222 8.21313 15.9039 9.34137 15.9039C10.4696 15.9039 11.3878 16.8222 11.3878 17.9504C11.3878 19.0787 10.47 19.9969 9.34137 19.9969ZM14.7294 19.9969C13.6012 19.9969 12.6829 19.0787 12.6829 17.9504C12.6829 16.8222 13.6012 15.9039 14.7294 15.9039C15.8577 15.9039 16.7759 16.8222 16.7759 17.9504C16.7759 19.0787 15.858 19.9969 14.7294 19.9969Z" />
    </SvgIcon>
  );
};
