import { BrokerLocationModel } from '@energy-stacks/broker/feature-locations-data';
import {
  ESMenuItem,
  ESTable,
  ESTableBody,
  ESTableHead,
  ESTablePagination,
  ESTableWrapper,
  useESTableBasic,
} from '@energy-stacks/core/ui';
import { MoreOptionsMenu, NoTableData } from '@energy-stacks/shared';
import { createColumnHelper } from '@tanstack/react-table';
import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { LocationChargingStatusStationIcons } from './LocationChargingStationStatusIcons';

type LocationsTableProps = {
  data: BrokerLocationModel[];
  globalFilter: string;
  filteredLocations: (locations: BrokerLocationModel[]) => void;
  testId?: string;
};

export const LocationsTable: FC<LocationsTableProps> = ({
  data,
  globalFilter,
  filteredLocations,
  testId,
}) => {
  const { t } = useTranslation('locations');
  const navigate = useNavigate();

  const columnHelper = createColumnHelper<BrokerLocationModel>();
  const columns = [
    columnHelper.accessor('name', {
      sortingFn: 'alphanumeric',
      header: () => t('nameColumnLabel'),
      cell: (info) => info.getValue() || '-',
    }),
    columnHelper.accessor('address', {
      sortingFn: 'alphanumeric',
      header: () => t('addressColumnLabel'),
      cell: (info) => {
        const locationAddress = info.row.original.address
          ? `${info.row.original.address}, `
          : '';
        const zipCodeAndCity =
          info.row.original.postalCode || info.row.original.city
            ? `${info.row.original.postalCode} ${info.row.original.city}, `
            : '';

        const locationCountry = info.row.original.country.label
          ? `${t(info.row.original.country.label)}`
          : '';
        const fullAddress = locationAddress + zipCodeAndCity + locationCountry;

        return fullAddress || '-';
      },
    }),
    columnHelper.accessor('chargingStations', {
      header: () => t('numberOfCSColumnLabel'),
      cell: (info) => (
        <LocationChargingStatusStationIcons
          chargingStations={info.getValue()}
        />
      ),
      enableSorting: false,
    }),
    columnHelper.display({
      id: 'actions',
      header: () => t('actionsColumnLabel'),
      cell: (info) => (
        <MoreOptionsMenu testId={info.row.original.uuid}>
          <ESMenuItem
            testId={`${info.row.original.uuid}EditMenuItem`}
            onClick={() => navigate(`${info.row.original.uuid}/edit`)}
          >
            {t('rowActionsEditItemLabel')}
          </ESMenuItem>
          <ESMenuItem
            testId={`${info.row.original.uuid}EditMenuItem`}
            onClick={() => navigate(`${info.row.original.uuid}/delete`)}
          >
            {t('rowActionsDeleteItemLabel')}
          </ESMenuItem>
        </MoreOptionsMenu>
      ),
    }),
  ];

  const { instance, rows, rowsPerPageOptions } = useESTableBasic(data, columns);
  const hasRows = rows.length !== 0;

  useEffect(() => {
    instance.setGlobalFilter(globalFilter);
    filteredLocations(rows.map((row) => row.original));
  }, [globalFilter, instance, rows, filteredLocations]);

  return (
    <ESTableWrapper>
      <ESTable>
        <ESTableHead testId={testId} instance={instance} />
        <ESTableBody
          testId={testId}
          rows={rows}
          onRowClick={(row) => navigate(`${row.original.uuid}/details`)}
        />
      </ESTable>

      {!hasRows ? <NoTableData message={t('noLocationsInTable')} /> : null}

      <ESTablePagination
        rowsPerPageOptions={rowsPerPageOptions}
        instance={instance}
      />
    </ESTableWrapper>
  );
};
