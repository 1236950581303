import { NoGeneralDetails, RefetchOnError } from '@energy-stacks/shared';
import { useChargingStationDetails } from '../useChargingStationDetails';
import { ConnectorGroupsV201 } from './ConnectorGroupsV201';

export const ChargingStationDetailsConnectorsTabV201 = () => {
  const { chargingStationDetails, isSuccess, isError, refetch } =
    useChargingStationDetails();

  return (
    <>
      {isError ? <RefetchOnError onRefetch={refetch} /> : null}
      {!chargingStationDetails && isSuccess ? (
        <NoGeneralDetails onRefetch={refetch} />
      ) : (
        <ConnectorGroupsV201 />
      )}
    </>
  );
};
