import { ESMenu, ESMenuItem } from '@energy-stacks/core/ui';
import { BrokerHardReset } from '../../BrokerHardReset';
import { BrokerSoftReset } from '../../BrokerSoftReset';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRefreshConfiguration } from './useRefreshConfiguration';

type ConfigurationMenuDialog = 'hardReset' | 'softReset';

export const ConfigurationMenu = () => {
  const { t } = useTranslation('chargingStations');

  const retrieveConfiguration = useRefreshConfiguration();

  const [openedDialog, setOpenedDialog] = useState<ConfigurationMenuDialog>();

  return (
    <>
      <ESMenu testId={'configurationMenuItems'} disableMenu={!!openedDialog}>
        <ESMenuItem
          testId={'retrieveConfigurationMenuItem'}
          onClick={() => retrieveConfiguration()}
        >
          {t('retrieveConfiguration')}
        </ESMenuItem>
        <ESMenuItem
          testId={'softResetConfigurationMenuItem'}
          onClick={() => setOpenedDialog('softReset')}
        >
          {t('softReset')}
        </ESMenuItem>
        <ESMenuItem
          testId={'hardResetConfigurationMenuItem'}
          onClick={() => setOpenedDialog('hardReset')}
        >
          {t('hardReset')}
        </ESMenuItem>
      </ESMenu>

      <BrokerSoftReset
        isOpen={openedDialog === 'softReset'}
        onClose={() => setOpenedDialog(undefined)}
      />

      <BrokerHardReset
        isOpen={openedDialog === 'hardReset'}
        onClose={() => setOpenedDialog(undefined)}
      />
    </>
  );
};
