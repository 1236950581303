import { getCSStatus } from '@energy-stacks/broker/feature-charging-stations-data';
import { BrokerLocationDto } from '../brokerLocationDto';
import { BrokerLocationModel } from '../brokerLocationModel';

import { countryNormalizer } from './countryNormalizer';

export const brokerLocationNormalizer = (
  location: BrokerLocationDto
): BrokerLocationModel => {
  return {
    uuid: location.uuid,
    name: location.name ?? '',
    address: location.address ?? '',
    city: location.city ?? '',
    postalCode: location.postalCode ?? '',
    state: location.state ?? '',
    country: countryNormalizer(location.country),
    coordinates: location.coordinates || { latitude: '0', longitude: '0' },
    chargingStations: location.chargingStations.map((station) => ({
      identityKey: station.identityKey,
      chargingStationName: station.chargingStationName ?? '',
      chargingStationModel: station.chargingStationModel ?? '',
      csmsUuid: station.csmsUuid,
      // guard against older versions of BE which return null for the whole shadow, check can eventually be removed
      // for this reason, check for null outside the transformer to keep transsformer implementation intact
      shadow: getCSStatus(station.shadow?.chargingStationStatus),
    })),
  };
};
