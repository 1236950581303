import {
  AnyAction,
  combineReducers,
  configureStore,
  ThunkAction,
} from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { persistStore, persistReducer } from 'redux-persist';
import {
  APP_FEATURE_KEY,
  appReducer,
  USER_FEATURE_KEY,
  userReducer,
  SIDEBAR_FEATURE_KEY,
  sidebarReducer,
  THEME_FEATURE_KEY,
  themeReducer,
  STEPPER_FEATURE_KEY,
  stepperReducer,
} from '@energy-stacks/store';
import {
  addChargingStationReducer,
  ADD_CHARGING_STATION_FEATURE_KEY,
} from './add-charging-station.slice';
import {
  configurationReducer,
  CONFIGURATION_FEATURE_KEY,
} from './configuration.slice';
import { centralSystemsApi as brokerCentralSystemsApi } from '@energy-stacks/broker/feature-system-preferences-data';
import { brokerTransactionsApi } from '@energy-stacks/broker/feature-transactions-data';
import { chargingStationsApi } from '@energy-stacks/broker/feature-charging-stations-data';
import { setupListeners } from '@reduxjs/toolkit/dist/query';
import { usersBrokerApi } from '@energy-stacks/broker/feature-users-data';
import { ocppMessageLogsApi as brokerOcppMessageLogsApi } from '@energy-stacks/broker/feature-ocpp-message-logs-data';
import { ocppNetworkLogsApi as brokerOcppNetworkLogsApi } from '@energy-stacks/broker/feature-ocpp-network-logs-data';
import storage from 'redux-persist/lib/storage';
import { dashboardApi } from '@energy-stacks/broker/feature-dashboard-data';
import {
  brokerManagementReducer,
  BROKER_MANAGEMENT_FEATURE_KEY,
} from './brokerManagement.slice';
import { brokerManagementApi } from '@energy-stacks/broker/feature-charging-station-management-data';
import { brokerImprintApi } from '@energy-stacks/broker/feature-imprint-data';
import { brokerLocationsApi } from '@energy-stacks/broker/feature-locations-data';
import { alertManagementApi } from '@energy-stacks/broker/feature-alert-management-data';
import { settingsApi } from '@energy-stacks/broker/feature-settings-data';
import { apiKeysApi as brokerApiKeysApi } from '@energy-stacks/broker/feature-system-preferences-data';
import { listenerMiddleware } from './listenerMiddleware';
import { BRANDING_FEATURE_KEY, brandingReducer } from './branding.slice';
import { tariffsApi as brokerTariffsApi } from '@energy-stacks/broker/feature-tariffs-data';
import {
  configuration201Reducer,
  CONFIGURATION201_FEATURE_KEY,
} from './configuration201.slice';
import { registeredServicesApi } from '@energy-stacks/broker/feature-registered-services-data';

const persistConfig = {
  key: 'BROKER',
  storage,
  // Must keep a whitelist value here or
  // the whole store will be persisted
  whitelist: [THEME_FEATURE_KEY],
};

export const rootReducer = combineReducers({
  [THEME_FEATURE_KEY]: themeReducer,
  [USER_FEATURE_KEY]: userReducer,
  [SIDEBAR_FEATURE_KEY]: sidebarReducer,
  [ADD_CHARGING_STATION_FEATURE_KEY]: addChargingStationReducer,
  [CONFIGURATION_FEATURE_KEY]: configurationReducer,
  [CONFIGURATION201_FEATURE_KEY]: configuration201Reducer,
  [BROKER_MANAGEMENT_FEATURE_KEY]: brokerManagementReducer,
  [BRANDING_FEATURE_KEY]: brandingReducer,
  [STEPPER_FEATURE_KEY]: stepperReducer,
  [APP_FEATURE_KEY]: appReducer,
  [brokerCentralSystemsApi.reducerPath]: brokerCentralSystemsApi.reducer,
  [brokerManagementApi.reducerPath]: brokerManagementApi.reducer,
  [brokerTransactionsApi.reducerPath]: brokerTransactionsApi.reducer,
  [usersBrokerApi.reducerPath]: usersBrokerApi.reducer,
  [chargingStationsApi.reducerPath]: chargingStationsApi.reducer,
  [brokerOcppMessageLogsApi.reducerPath]: brokerOcppMessageLogsApi.reducer,
  [brokerOcppNetworkLogsApi.reducerPath]: brokerOcppNetworkLogsApi.reducer,
  [dashboardApi.reducerPath]: dashboardApi.reducer,
  [brokerImprintApi.reducerPath]: brokerImprintApi.reducer,
  [brokerApiKeysApi.reducerPath]: brokerApiKeysApi.reducer,
  [brokerLocationsApi.reducerPath]: brokerLocationsApi.reducer,
  [alertManagementApi.reducerPath]: alertManagementApi.reducer,
  [settingsApi.reducerPath]: settingsApi.reducer,
  [brokerTariffsApi.reducerPath]: brokerTariffsApi.reducer,
  [registeredServicesApi.reducerPath]: registeredServicesApi.reducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const initStore = (preloadedState = {}) =>
  configureStore({
    preloadedState,
    reducer: persistedReducer,
    // Additional middleware can be passed to this array
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: {
          ignoredActions: ['persist/PERSIST'],
        },
      })
        .prepend(listenerMiddleware.middleware)
        .concat(
          brokerCentralSystemsApi.middleware,
          chargingStationsApi.middleware,
          usersBrokerApi.middleware,
          brokerOcppMessageLogsApi.middleware,
          brokerOcppNetworkLogsApi.middleware,
          brokerTransactionsApi.middleware,
          brokerManagementApi.middleware,
          dashboardApi.middleware,
          brokerImprintApi.middleware,
          brokerLocationsApi.middleware,
          alertManagementApi.middleware,
          brokerApiKeysApi.middleware,
          settingsApi.middleware,
          brokerTariffsApi.middleware,
          registeredServicesApi.middleware
        ),
    devTools:
      (typeof process !== 'undefined' &&
        process.env['NODE_ENV'] !== 'production') ||
      !!import.meta,

    // Optional Redux store enhancers
    enhancers: [],
  });

export const store = initStore();
export const persistor = persistStore(store);

setupListeners(store.dispatch);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof rootReducer>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  AnyAction
>;
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
