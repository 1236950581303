import { EditVehicleGeneralInfoPayload } from '../editVehicleGeneralInfoPayload';
import { EditVehicleGeneralInfoData } from '../vehiclesApi';

export const editVehicleGeneralInfoToPayload = (
  data: EditVehicleGeneralInfoData
): EditVehicleGeneralInfoPayload => ({
  depotId: data.plantId,
  name: data.name,
  vehicleCoreUuid: data.coreVehicleId,
  licensePlateNumber: data.licencePlate ? data.licencePlate : undefined,
  status: data.status,
});
