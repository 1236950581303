import { Hours } from '../hours';
import { OpeningHours } from '../locationDetailsModel';

export const openingHoursNormalizer = (
  openingTimes: Hours | undefined
): OpeningHours => {
  const regularHoursWeekdays =
    openingTimes?.regular_hours?.map((day) => day.weekday) ?? [];

  const openedOnWeekends =
    regularHoursWeekdays?.includes(6) || regularHoursWeekdays?.includes(7);

  if (openingTimes?.twentyfourseven) {
    return OpeningHours.nonStop;
  }
  if (openedOnWeekends) {
    return OpeningHours.customDays;
  }
  return OpeningHours.workdaysOnly;
};
