import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const TypeEF: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props}>
      <circle cx="8" cy="13" r="2" />
      <circle cx="16" cy="13" r="2" />
      <path d="M12 10C13.1029 10 14 9.10292 14 8C14 6.89708 13.1029 6 12 6C10.8971 6 10 6.89708 10 8C10 9.10292 10.8971 10 12 10ZM12 6.4567C12.8511 6.4567 13.5433 7.14892 13.5433 8C13.5433 8.85108 12.8511 9.5433 12 9.5433C11.1489 9.5433 10.4567 8.85108 10.4567 8C10.4567 7.14892 11.1489 6.4567 12 6.4567Z" />
      <path d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21ZM12 20.4C16.6392 20.4 20.4 16.6392 20.4 12C20.4 7.36078 16.6392 3.59998 12 3.59998C7.36078 3.59998 3.59998 7.36078 3.59998 12C3.59998 16.6392 7.36078 20.4 12 20.4Z" />
    </SvgIcon>
  );
};
