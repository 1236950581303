import { colors } from '@mui/material';

export const vehicleColors: string[] = [
  colors.cyan[500],
  colors.indigo[200],
  colors.lime[500],
  colors.cyan[900],
  colors.pink[600],
  colors.cyan[200],
  colors.indigo[600],
  colors.pink[300],
  colors.brown[500],
  colors.purple[500],
  colors.red[500],
];
