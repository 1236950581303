import EvStationRoundedIcon from '@mui/icons-material/EvStationRounded';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { EventSourceChip } from './EventSourceChip';

interface ChargingStationEventProps {
  inheritCursor?: boolean;
}

export const ChargingStationEventSource: React.FC<
  ChargingStationEventProps
> = ({ inheritCursor }) => {
  const { t } = useTranslation('chargingStations');
  return (
    <EventSourceChip
      icon={<EvStationRoundedIcon />}
      label={t('chargingStation')}
      inheritCursor={inheritCursor}
    />
  );
};
