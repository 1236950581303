import { FC } from 'react';
import { InfoWindowF } from '@react-google-maps/api';
import { Box, Link, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { BrokerLocationModel } from '@energy-stacks/broker/feature-locations-data';
import { LocationChargingStatusStationIcons } from './LocationChargingStationStatusIcons';

type LocationPopupProps = {
  location: BrokerLocationModel;
  onClose: () => void;
  onNavigate?: (value: string) => void;
};

export const LocationPopup: FC<LocationPopupProps> = ({
  location,
  onClose,
  onNavigate,
}) => {
  const [t] = useTranslation('locations');
  const navigate = useNavigate();
  const { spacing } = useTheme();

  return (
    <InfoWindowF
      position={{
        lat: Number(location.coordinates.latitude),
        lng: Number(location.coordinates.longitude),
      }}
      options={{ pixelOffset: new google.maps.Size(0, -60) }}
      onCloseClick={onClose}
    >
      <Box sx={{ padding: 0.5 }}>
        <Box
          sx={{
            mb: 1,
            mr: 2,
            maxWidth: spacing(60),
          }}
        >
          <Typography
            variant="caption"
            sx={{ color: 'text.dark', fontWeight: 700 }}
          >
            {location.name}
          </Typography>
        </Box>
        <LocationChargingStatusStationIcons
          chargingStations={location.chargingStations}
        />
        <Link
          onClick={() =>
            onNavigate
              ? onNavigate(location.uuid)
              : navigate(`${location.uuid}/details`)
          }
          sx={{
            mb: 3.5,
            fontSize: spacing(2.5),
            fontWeight: 500,
            '&:hover': { cursor: 'pointer' },
          }}
        >
          {t('viewLocationDetailsLinkLabel')}
        </Link>
      </Box>
    </InfoWindowF>
  );
};
