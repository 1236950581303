import { SecurityEventDto } from '../securityEventsDto';
import { SecurityEventModel } from '../securityEventsModel';

export const securityEventNormalizer = (
  event: SecurityEventDto
): SecurityEventModel => {
  return {
    id: event.identityKey,
    type: event.type,
    timestamp: event.timestamp ?? '',
    techInfo: event.techInfo ?? '',
  };
};
