import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export const LOCATION_DETAILS_FEATURE_KEY = 'locationDetails';

export type EditableLocationDetailsForm =
  | 'general-form'
  | 'advanced-form'
  | 'opening-hours-form';

export interface LocationDetailsState {
  currentEditableLocationDetailsForm: EditableLocationDetailsForm | 'none';
}

const initialState: LocationDetailsState = {
  currentEditableLocationDetailsForm: 'none',
};

export const locationDetailsSlice = createSlice({
  name: LOCATION_DETAILS_FEATURE_KEY,
  initialState,
  reducers: {
    setCurrentEditableLocationDetailsForm: (
      state,
      { payload }: PayloadAction<EditableLocationDetailsForm>
    ) => {
      state.currentEditableLocationDetailsForm = payload;
    },
    resetEditLocationDetailsForm: (state) => {
      state.currentEditableLocationDetailsForm = 'none';
    },
  },
});

export const {
  setCurrentEditableLocationDetailsForm,
  resetEditLocationDetailsForm,
} = locationDetailsSlice.actions;

export const locationDetailsReducer = locationDetailsSlice.reducer;
