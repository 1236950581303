import React, { useEffect, useMemo } from 'react';
import {
  ESDialogActionButton,
  ESDialogActions,
  ESDialogContent,
  ESDialogTitle,
  ESTextField,
  ESVirtualizedAutocomplete,
  useESSnackbar,
} from '@energy-stacks/core/ui';
import { Dialog, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { SubmitHandler, useForm, Controller } from 'react-hook-form';
import {
  connectorPowerTypes,
  ConnectorModel,
  useEditConnectorMutation,
  BrokerConnectorFormData,
  EditConnectorRequest,
} from '@energy-stacks/broker/feature-charging-stations-data';
import {
  ConfirmCloseDialog,
  stringEnumToArray,
  useChargingStationIdentityKey,
  useConfirmCloseDialog,
} from '@energy-stacks/shared';
import { yupResolver } from '@hookform/resolvers/yup';
import { editConnectorFormSchema } from './editConnectorFormSchema';
import { editConnectorApiErrors } from './editConnectorApiErrors';
import { ConnectorStandard } from '@energy-stacks/data';
interface EditConnectorDialogProps {
  onClose: () => void;
  isOpen: boolean;
  connector: ConnectorModel;
  testId?: string;
}
enum ConnectorFormatEnum {
  socket = 'Socket',
  cable = 'Cable',
}

export const EditConnectorDialog: React.FC<EditConnectorDialogProps> = ({
  onClose,
  isOpen,
  connector,
  testId,
}) => {
  const { t } = useTranslation('chargingStations');

  const defaultValues: BrokerConnectorFormData = useMemo(() => {
    return {
      type: {
        label: connector.connectorType
          ? t(`connectorTypes.${connector.connectorType}`)
          : '',
        value: connector.connectorType,
      },
      evseId: connector.evseId.toString(),
      connectorFormat: {
        label: t(connector.connectorFormat),
        value: connector.connectorFormat || '',
      },
      powerType: {
        label: connector.powerType
          ? t(`powerTypes.${connector.powerType}`)
          : '',
        value: connector.powerType ? connector.powerType : undefined,
      },
      maxVoltage: connector.maxVoltage.toString(),
      maxAmperage: connector.maxAmperage.toString(),
      maxPower: connector.maxPower.toString(),
      tariffId: connector.tariffId,
    };
  }, [t, connector]);

  const {
    control,
    handleSubmit,
    reset,
    formState: { isValid, errors, isDirty: isFormDirty },
  } = useForm({
    resolver: yupResolver(editConnectorFormSchema),
    mode: 'onTouched',
    defaultValues,
  });
  const { showSnackbar } = useESSnackbar();
  const [editConnector, { isLoading }] = useEditConnectorMutation();
  const identityKey = useChargingStationIdentityKey();

  const {
    isConfirmCloseDialogOpen,
    handleOpenConfirmDialog,
    handleStay,
    handleLeaveWithoutSaving,
  } = useConfirmCloseDialog(isFormDirty);

  useEffect(() => {
    if (defaultValues) {
      reset(defaultValues);
    }
  }, [defaultValues, reset]);

  const onSubmit: SubmitHandler<BrokerConnectorFormData> = (data) => {
    const requestData: EditConnectorRequest = {
      standard: data.type.value || null,
      evseId: data.evseId || null,
      format: data.connectorFormat.value.toLocaleUpperCase() || null,
      power_type: data.powerType.value || null,
      max_voltage: Number(data.maxVoltage),
      max_amperage: Number(data.maxAmperage),
      max_electric_power: Number(data.maxPower),
      tariff_id: data.tariffId || null,
    };

    editConnector({
      data: requestData,
      identityKey,
      connectorId: connector.connectorId,
    })
      .unwrap()
      .then(() => {
        showSnackbar(
          'success',
          'editConnectorSuccessfully',
          'chargingStations'
        );
        onClose();
      })
      .catch((error) => {
        showSnackbar(
          'error',
          editConnectorApiErrors[error.data?.errorCode],
          'chargingStations'
        );
      });
  };

  return (
    <Dialog open={isOpen} onClose={onClose} fullWidth maxWidth="sm">
      <ESDialogTitle>{t('editConnector')}</ESDialogTitle>
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <ESDialogContent>
          <Box mb={6}>
            <Controller
              control={control}
              rules={{ required: true }}
              render={({ field: { onChange, onBlur, value } }) => {
                return (
                  <ESVirtualizedAutocomplete
                    testId={`connector${testId}Type`}
                    value={{ value: value.value ?? '', label: value.label }}
                    label={t('type')}
                    options={stringEnumToArray(ConnectorStandard).map((v) => ({
                      label: t(`connectorTypes.${v}`),
                      value: v,
                    }))}
                    onBlur={onBlur}
                    onChange={(_, value) => {
                      if (Array.isArray(value)) {
                        return;
                      }
                      if (value) {
                        onChange({
                          value: value.value ?? '',
                          label: value.label ?? '',
                        });
                      }
                    }}
                    disabled={isLoading}
                    noOptionsText={t('noConnectorType')}
                  />
                );
              }}
              name={'type'}
            />
          </Box>
          <Box mb={6}>
            <Controller
              control={control}
              rules={{ required: true }}
              render={({ field: { onChange, onBlur, value } }) => {
                return (
                  <ESTextField
                    inputProps={{ 'data-testid': `connector${testId}EvseId` }}
                    label={t('evseId')}
                    placeholder={t('evseIdPlaceholder')}
                    type="text"
                    onChange={onChange}
                    onBlur={onBlur}
                    error={Boolean(errors.evseId)}
                    helperText={
                      Boolean(errors.evseId) &&
                      t(`${errors.evseId?.message}`, {
                        value: t('evseId'),
                      })
                    }
                    value={value}
                  />
                );
              }}
              name={'evseId'}
            />
          </Box>
          <Box mb={6}>
            <Controller
              control={control}
              rules={{ required: true }}
              render={({ field: { onChange, onBlur, value } }) => {
                return (
                  <ESVirtualizedAutocomplete
                    testId={`connector${testId}ConnectorFormat`}
                    value={{ value: value.value, label: value.label }}
                    label={t('connectorFormat')}
                    options={stringEnumToArray(ConnectorFormatEnum).map(
                      (v) => ({
                        value: v,
                        label: v,
                      })
                    )}
                    onBlur={onBlur}
                    onChange={(_, value) => {
                      if (Array.isArray(value)) {
                        return;
                      }
                      if (value) {
                        onChange({
                          value: value.value ?? '',
                          label: value.label ?? '',
                        });
                      }
                    }}
                    disabled={isLoading}
                    noOptionsText={t('noConnectorFormat')}
                  />
                );
              }}
              name={'connectorFormat'}
            />
          </Box>
          <Box mb={6}>
            <Controller
              control={control}
              rules={{ required: true }}
              render={({ field: { onChange, onBlur, value } }) => {
                return (
                  <ESVirtualizedAutocomplete
                    testId={`connector${testId}PowerType`}
                    value={{ value: value.value, label: value.label }}
                    label={t('powerType')}
                    options={connectorPowerTypes.map((type) => ({
                      label: t(`powerTypes.${type}`),
                      value: type,
                    }))}
                    onBlur={onBlur}
                    onChange={(_, value) => {
                      if (Array.isArray(value)) {
                        return;
                      }
                      if (value) {
                        onChange({
                          value: value.value ?? '',
                          label: value.label ?? '',
                        });
                      }
                    }}
                    disabled={isLoading}
                    noOptionsText={t('noConnectorPowerType')}
                  />
                );
              }}
              name={'powerType'}
            />
          </Box>
          <Box mb={6}>
            <Controller
              control={control}
              rules={{ required: true }}
              render={({ field: { onChange, onBlur, value } }) => {
                return (
                  <ESTextField
                    inputProps={{
                      'data-testid': `connector${testId}MaxVoltage`,
                    }}
                    label={t('voltage')}
                    type="text"
                    onChange={onChange}
                    onBlur={onBlur}
                    error={Boolean(errors.maxVoltage)}
                    helperText={
                      Boolean(errors.maxVoltage) &&
                      t(`${errors.maxVoltage?.message}`, {
                        value: 'Max voltage',
                      })
                    }
                    value={value}
                  />
                );
              }}
              name={'maxVoltage'}
            />
          </Box>
          <Box mb={6}>
            <Controller
              control={control}
              rules={{ required: true }}
              render={({ field: { onChange, onBlur, value } }) => {
                return (
                  <ESTextField
                    inputProps={{
                      'data-testid': `connector${testId}MaxAmperage`,
                    }}
                    label={t('maxAmperage')}
                    type="text"
                    onChange={onChange}
                    onBlur={onBlur}
                    error={Boolean(errors.maxAmperage)}
                    helperText={
                      Boolean(errors.maxAmperage) &&
                      t(`${errors.maxAmperage?.message}`, {
                        value: 'Max amperage',
                      })
                    }
                    value={value}
                  />
                );
              }}
              name={'maxAmperage'}
            />
          </Box>
          <Box mb={6}>
            <Controller
              control={control}
              rules={{ required: true }}
              render={({ field: { onChange, onBlur, value } }) => {
                return (
                  <ESTextField
                    inputProps={{ 'data-testid': `connector${testId}MaxPower` }}
                    label={t('maxPower')}
                    type="text"
                    onChange={onChange}
                    onBlur={onBlur}
                    error={Boolean(errors.maxPower)}
                    helperText={
                      Boolean(errors.maxPower) &&
                      t(`${errors.maxPower?.message}`, { value: 'Max power' })
                    }
                    value={value}
                  />
                );
              }}
              name={'maxPower'}
            />
          </Box>
        </ESDialogContent>
        <ESDialogActions>
          <ESDialogActionButton
            data-testid={`connector${testId}CancelButton`}
            disabled={isLoading}
            variant="text"
            color="error"
            onClick={isFormDirty ? handleOpenConfirmDialog : onClose}
          >
            {t('cancel')}
          </ESDialogActionButton>
          <ESDialogActionButton
            data-testid={`connector${testId}SaveButton`}
            variant="contained"
            type="submit"
            disabled={!isValid || !isFormDirty}
            loading={isLoading}
          >
            {t('save')}
          </ESDialogActionButton>
        </ESDialogActions>
      </form>
      <ConfirmCloseDialog
        open={isConfirmCloseDialogOpen}
        onStay={handleStay}
        onLeaveWithoutSaving={handleLeaveWithoutSaving}
      />
    </Dialog>
  );
};
