import { environment } from '@energy-stacks/feature-config';
import { SortingOrderModel } from '@energy-stacks/core/ui';
import { createBaseQuery } from '@energy-stacks/shared';
import { createApi } from '@reduxjs/toolkit/query/react';
import { MessageDirectionEnum } from './messageDirectionEnum';
import { messageDirectionNormalizer } from './normalizers/messageDirectionNormalizer';
import { ocppActionNormalizer } from './normalizers/ocppActionNormalizer';
import { ProcedureName } from './ocppMessageLogEntry';
import { OcppMessageLogsModel } from './ocppMessageLogsModel';
import { PageOcppMessageLogEntry } from './pageOcppMessageLogEntry';
import { messageTypeNormalizer } from './normalizers/messageTypeNormalizer';
import { MessageTypeEnum } from './messageTypeEnum';
import { appendZToDateString, formatDateTime } from '@energy-stacks/shared';

export interface GetOcppMessageLogsBody {
  identityKey: string;
  timeFrom?: string;
  timeTo?: string;
  messageDirections?: MessageDirectionEnum[];
  messageTypes?: MessageTypeEnum[];
  page: number;
  size: number;
  sort: SortingOrderModel;
  procedureNames?: ProcedureName[];
}

export interface GetFilteredLogsRequestBody {
  timeFrom?: string;
  timeTo?: string;
  messageTypes?: MessageTypeEnum[];
  ocppProcedures?: ProcedureName[];
  messageDirections?: MessageDirectionEnum[];
}

type SortColumn = 'timestamp';

// NOTE: Ocpp message logs can be sorted only by timestamp BE field
const SORT_COLUMN: SortColumn = 'timestamp';
const FORMAT = 'yyyy-MM-dd-HH-mm-ss';

export const ocppMessageLogsApi = createApi({
  reducerPath: 'brokerOcppMessageLogsApi',
  tagTypes: ['OcppMessageLogs'],
  keepUnusedDataFor: 0,
  baseQuery: createBaseQuery(`${environment.ocppServiceUrl}/ocpp-messages/`),
  endpoints: (builder) => ({
    getOcppMessageLogs: builder.query<
      OcppMessageLogsModel,
      GetOcppMessageLogsBody
    >({
      query: (searchParams) => ({
        url: `search`,
        method: 'GET',
        params: {
          ...searchParams,
          sort: [`${SORT_COLUMN},${searchParams.sort.order}`],
        },
      }),
      providesTags: ['OcppMessageLogs'],
      transformResponse: (
        ocppMessageLogs: PageOcppMessageLogEntry
      ): OcppMessageLogsModel => {
        return {
          totalElements: ocppMessageLogs.totalElements ?? 0,
          totalPages: ocppMessageLogs.totalPages ?? -1,
          messages:
            ocppMessageLogs?.content?.map((log) => ({
              messageDirection: messageDirectionNormalizer(
                log.messageDirection
              ),
              messageType: messageTypeNormalizer(log.messageType),
              timestamp: appendZToDateString(log.timestamp),
              ocppAction: ocppActionNormalizer(log.procedureName),
              messageUuid: log.messageUuid ?? 'N/A',
              message: log.rawMessage ?? 'N/A',
            })) ?? [],
        };
      },
    }),
    getOcppLogsCsv: builder.query<
      File,
      { identityKey: string; params: GetFilteredLogsRequestBody }
    >({
      query: ({ identityKey, params }) => ({
        url: `/${identityKey}/csv`,
        params: {
          ...params,
          timeFrom: appendZToDateString(params.timeFrom),
          timeTo: appendZToDateString(params.timeTo),
        },
        responseHandler: async (response) => {
          const blob = await response.blob();
          const url = window.URL.createObjectURL(blob);

          const filename =
            response.headers.get('content-disposition')?.split('=')[1] ??
            `${formatDateTime(
              new Date().toUTCString(),
              FORMAT
            )}-${identityKey}.csv`;

          const link = document.createElement('a');

          link.style.visibility = 'hidden';
          link.href = url;
          link.setAttribute('download', filename);

          document.body.appendChild(link);

          link.click();

          document.body.removeChild(link);
        },
      }),
    }),
    getOcppLogsZip: builder.query<
      File,
      { identityKey: string; params: GetFilteredLogsRequestBody }
    >({
      query: ({ identityKey, params }) => ({
        url: `/${identityKey}/zip`,
        params: {
          ...params,
          timeFrom: appendZToDateString(params.timeFrom),
          timeTo: appendZToDateString(params.timeTo),
        },
        responseHandler: async (response) => {
          const blob = await response.blob();
          const url = window.URL.createObjectURL(blob);
          const filename =
            response.headers.get('content-disposition')?.split('=')[1] ??
            `${formatDateTime(
              new Date().toUTCString(),
              FORMAT
            )}-${identityKey}.zip`;

          const link = document.createElement('a');

          link.style.visibility = 'hidden';
          link.href = url;
          link.setAttribute('download', filename);

          document.body.appendChild(link);

          link.click();

          document.body.removeChild(link);
        },
      }),
    }),
  }),
});

export const {
  useGetOcppMessageLogsQuery,
  useLazyGetOcppLogsCsvQuery,
  useLazyGetOcppLogsZipQuery,
} = ocppMessageLogsApi;
