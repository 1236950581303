import {
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material';

type ESProfileSectionListItemProps = {
  listItemText: string;
  onClick?: () => void;
  Icon: React.ReactNode;
  testId: string;
  EndIcon?: React.ReactNode;
  href?: string;
};

export const ESProfileSectionListItem: React.FC<
  ESProfileSectionListItemProps
> = ({ listItemText, onClick, testId, Icon, EndIcon, href }) => {
  return (
    <ListItemButton
      data-testid={testId}
      disableRipple={true}
      sx={{
        ':hover': {
          backgroundColor: 'grey.100',
          '& .MuiListItemIcon-root': {
            color: 'grey.500',
          },
        },
      }}
      onClick={onClick}
      href={href}
      target="_blank"
      rel="noopener noreferrer"
      component="a"
    >
      <ListItemIcon
        sx={{
          color: 'grey.500',
        }}
      >
        {Icon}
      </ListItemIcon>
      <ListItemText
        primary={
          <Typography sx={{ color: 'grey.600' }} variant="body2">
            {listItemText}
          </Typography>
        }
      />
      {EndIcon ? (
        <ListItemIcon
          sx={{
            color: 'grey.500',
          }}
        >
          {EndIcon}
        </ListItemIcon>
      ) : null}
    </ListItemButton>
  );
};
