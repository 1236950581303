import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { createColumnHelper } from '@tanstack/react-table';
import { Typography, Box } from '@mui/material';
import { Stack } from '@mui/system';
import { isWithinInterval, parseISO } from 'date-fns';
import {
  ESTable,
  ESTableBasicConfig,
  ESTableBody,
  ESTableFilters,
  ESTableHead,
  ESTablePagination,
  ESTableWrapper,
  useESTableBasic,
} from '@energy-stacks/core/ui';
import { TourModel } from '@energy-stacks/fleet/feature-tours-data';
import {
  ESTooltip,
  TableSearchField,
  formatDate,
  formatDateTime,
  TimeDistance,
  NoTableData,
  TableRowsCount,
  TableDateRangeFilter,
} from '@energy-stacks/shared';
import { DateRange } from '@energy-stacks/core/date-range-picker';
import {
  TourIdCell,
  TourStatusChip,
  ToursDurationCell,
  ToursQuantityCell,
  sortByQuantity,
} from '@energy-stacks/fleet/feature-tours';
import { NoTours } from '@energy-stacks/fleet-is/feature-tours';
import { UpdateTours } from './UpdateTours';

interface ToursTableProps extends ESTableBasicConfig<TourModel> {
  tours: TourModel[];
  enableColumnSelection: boolean;
  defaultDateRange?: DateRange | undefined;
  dateRange?: DateRange | undefined;
  onDateRangeChange?: (dateRange: DateRange | undefined) => void;
  onToursRefetch: () => void;
}

export const ToursTable: FC<ToursTableProps> = ({
  enableRowSelection,
  tours,
  onToursRefetch,
  defaultRowSelection,
  onSelectionChange,
  dateRange,
  defaultDateRange,
  onDateRangeChange,
}) => {
  const [t] = useTranslation('chargingSchedule');
  const [tShared] = useTranslation('shared');
  const columnHelper = createColumnHelper<TourModel>();
  const columns = [
    columnHelper.accessor('tourId', {
      sortingFn: 'alphanumeric',
      header: () => t('tourIdColumnHeader'),
      footer: (props) => props.column.id,
      cell: (info) => <TourIdCell tourId={info.getValue()} />,
      enableGlobalFilter: true,
    }),
    columnHelper.accessor('vehicle.name', {
      id: 'vehicle',
      header: () => t('vehicleColumnHeader'),
      footer: (props) => props.column.id,
      cell: ({ row }) => {
        const { name } = row.original.vehicle;
        return (
          <Stack direction="row" gap={3} alignItems="center">
            <Typography variant="inherit">{name}</Typography>
          </Stack>
        );
      },
      enableGlobalFilter: true,
      filterFn: 'arrIncludesSome',
      size: 200,
    }),
    columnHelper.accessor('status', {
      header: () => t('statusColumnHeader'),
      footer: (props) => props.column.id,
      cell: (info) => <TourStatusChip status={info.getValue()} />,
      filterFn: 'arrIncludesSome',
      size: 150,
      enableGlobalFilter: false,
    }),
    columnHelper.accessor('date', {
      header: () => t('dateColumnHeader'),
      footer: (props) => props.column.id,
      cell: (info) => (
        <Typography variant="inherit">{formatDate(info.getValue())}</Typography>
      ),
      enableGlobalFilter: false,
      enableColumnFilter: true,
      filterFn: (row, _, filterValue: DateRange | undefined) => {
        if (!filterValue) {
          return true;
        }

        return isWithinInterval(parseISO(row.original.date), {
          start: filterValue.startDate,
          end: filterValue.endDate,
        });
      },
    }),
    columnHelper.accessor('startDate', {
      header: () => t('startTimeColumnHeader'),
      footer: (props) => props.column.id,
      cell: (info) => (
        <Typography variant="inherit">
          {formatDate(info.getValue(), 'hh:mm a').toLowerCase()}
        </Typography>
      ),
      enableGlobalFilter: false,
    }),
    columnHelper.accessor('endDate', {
      header: () => t('endTimeColumnHeader'),
      footer: (props) => props.column.id,
      cell: (info) => (
        <Typography variant="inherit">
          {formatDate(info.getValue(), 'hh:mm a').toLowerCase()}
        </Typography>
      ),
      enableGlobalFilter: false,
    }),
    columnHelper.accessor('duration.time', {
      header: () => t('durationColumnHeader'),
      footer: (props) => props.column.id,
      cell: ({ row }) => (
        <ToursDurationCell
          startTime={row.original.startDate}
          endTime={row.original.endDate}
          distance={row.original.duration.distance}
        />
      ),
      enableGlobalFilter: false,
    }),
    columnHelper.accessor('totalJobs', {
      id: 'jobs',
      header: () => t('jobsColumnHeader'),
      footer: (props) => props.column.id,
      cell: ({ row }) => row.original.totalJobs,
      size: 80,
      enableGlobalFilter: false,
    }),
    columnHelper.accessor('quantity', {
      header: () => t('quantityColumnHeader'),
      footer: (props) => props.column.id,
      cell: ({ row }) => <ToursQuantityCell quantity={row.original.quantity} />,
      sortingFn: sortByQuantity<TourModel>,
      size: 180,
    }),
  ];

  const {
    instance,
    rows,
    globalFilter,
    onGlobalFilterChange,
    rowsPerPageOptions,
    handleSelectRow,
  } = useESTableBasic(tours, columns, {
    onSelectionChange,
    defaultRowSelection,
    enableRowSelection,
    fitRowHeight: 74,
    getRowId: (row) => row.tourId,
    initialColumnFilters: defaultDateRange
      ? [{ id: 'date', value: defaultDateRange }]
      : undefined,
  });
  const hasRows = rows.length !== 0;

  // TODO: Adjust this part when BE is ready
  const lastUpdated = tours.reduce(
    (a, b) => {
      return new Date(a.startDate).getTime() > new Date(b.startDate).getTime()
        ? a
        : b;
    },
    { startDate: null } as unknown as TourModel
  );
  return (
    <>
      <Box display="flex" sx={{ backgroundColor: 'transparent' }}>
        <Box flex={1}>
          <ESTableFilters>
            <ESTooltip title={t('searchToursPlaceholder')}>
              <TableSearchField
                placeholder={t('searchToursPlaceholder')}
                value={globalFilter}
                onChange={onGlobalFilterChange}
                tableInstance={instance}
              />
            </ESTooltip>
            <TableDateRangeFilter
              title={tShared('timeRange')}
              column={instance.getColumn('date')}
              onDateRangeChange={onDateRangeChange}
              defaultDateRange={defaultDateRange}
              dateRange={dateRange}
            />
          </ESTableFilters>
        </Box>
        <Stack
          direction="row"
          gap={4}
          pb={8}
          alignItems="center"
          display={rows.length === 0 ? 'none' : 'flex'}
        >
          {lastUpdated && (
            <ESTooltip title={formatDateTime(lastUpdated.startDate)}>
              <Stack direction="row" gap={1}>
                <Typography fontSize={12}>{t('lastUpdated')}</Typography>
                <TimeDistance date={lastUpdated.startDate} />
              </Stack>
            </ESTooltip>
          )}
          <UpdateTours onUpdate={onToursRefetch} disabled />
        </Stack>
      </Box>

      <ESTableWrapper>
        {tours.length ? (
          <>
            <ESTable>
              <ESTableHead testId={'toursTableHeader'} instance={instance} />
              <ESTableBody
                testId={'toursTable'}
                onRowClick={handleSelectRow}
                rows={rows}
              />
              {!hasRows ? <NoTableData message={t('thereAreNoTours')} /> : null}
            </ESTable>
            <TableRowsCount instance={instance} />
            <ESTablePagination
              rowsPerPageOptions={rowsPerPageOptions}
              instance={instance}
            />
          </>
        ) : (
          // There are no tours at all
          <NoTours
            description="importToursDescription"
            showImportToursButton={true}
          />
        )}
      </ESTableWrapper>
    </>
  );
};
