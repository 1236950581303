import { BrokerEventSource } from './ocpp-message-logs/BrokerEventSource';
import { ChargingStationEventSource } from './ocpp-message-logs/ChargingStationEventSource';
import { ArrowForwardRounded } from '@mui/icons-material';
import { Box, Stack } from '@mui/material';
import { ServerEventSource } from './ocpp-message-logs/ServerEventSource';

export const BrokerToServer = () => {
  return (
    <Stack direction={'row'} alignItems="center" spacing={4}>
      <Box>
        <BrokerEventSource />

        {/* hidden element to set the width so the Chips align */}
        {/* it's here for the case the width changes because of a different language */}
        <Box sx={{ visibility: 'hidden', height: 0 }}>
          <ChargingStationEventSource />
        </Box>
      </Box>

      <ArrowForwardRounded
        sx={{ color: (theme) => theme.palette.action.active }}
      />
      <ServerEventSource />
    </Stack>
  );
};
