import { Card, CardContent, CardProps } from '@mui/material';
import React from 'react';

interface ESFormCardProps extends CardProps {
  children: React.ReactNode;
}

export const ESFormCard: React.FC<ESFormCardProps> = ({ children }) => {
  return (
    <Card
      sx={{
        p: 6,
        '&.MuiCardContent-root:last-child': {
          pb: 9,
        },
      }}
    >
      <CardContent
        sx={{
          p: 0,
          '&.MuiCardContent-root:last-child': {
            pb: 0,
          },
        }}
      >
        {children}
      </CardContent>
    </Card>
  );
};
