import { ESVirtualizedAutocomplete } from '@energy-stacks/core/ui';
import { ChargingStationGroupModel } from '@energy-stacks/csms/feature-charging-station-groups-data';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useChargingStationGroupsByLocationId } from './useChargingStationGroupsByLocationId';

interface ChargingStationGroupFieldProps {
  onChange: (value: { label: string; value: string }) => void;
  value: { label: string; value: string };
  disabled?: boolean;
  required?: boolean;
  onBlur: () => void;
  error?: boolean;
  helperText?: React.ReactNode;
  locationId: string;
  canFetchGroups: boolean;
}

export const ChargingStationGroupField: React.FC<
  ChargingStationGroupFieldProps
> = ({
  onChange,
  locationId,
  canFetchGroups,
  onBlur,
  value,
  disabled,
  required,
  error,
  helperText,
}) => {
  const { t } = useTranslation('chargingStations');

  const { chargingStationGroups, isFetching } =
    useChargingStationGroupsByLocationId(locationId ?? '', canFetchGroups);

  const [options, setOptions] = useState<ChargingStationGroupModel[]>();

  useEffect(() => {
    // Set autocomplete options to empty array whenever network request is sent
    // in order not to display previously cached chargingStationGroups and avoid warning in the console
    if (isFetching) {
      setOptions([]);
      return;
    }

    setOptions(chargingStationGroups);
  }, [chargingStationGroups, isFetching]);

  return (
    <ESVirtualizedAutocomplete
      label={t('chargingStationGroup')}
      required={required}
      error={error}
      loading={isFetching}
      helperText={helperText}
      value={value}
      options={
        options?.map((chargingStaitonGroup) => ({
          value: chargingStaitonGroup.chargingStationGroupUuid,
          label: chargingStaitonGroup.chargingStationGroupName,
        })) ?? []
      }
      noOptionsText={t('noChargingStationGroups')}
      onChange={(_, value) => {
        if (Array.isArray(value)) {
          return;
        }

        if (value) {
          onChange({ value: value.value ?? '', label: value.label ?? '' });
        }
      }}
      disabled={disabled || isFetching}
      onBlur={onBlur}
    />
  );
};
