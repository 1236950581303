import { createRef } from 'react';
import { SnackbarProvider, OptionsObject } from 'notistack';
import { translate } from './useESSnackbar';
import { ESSnackbar } from './ESSnackbar';

const notistackRef = createRef<any>();

interface ESSnackbarProviderProps {
  children: React.ReactNode;
  maxSnack: number;
}

export const ESSnackbarProvider: React.FC<ESSnackbarProviderProps> = ({
  children,
  maxSnack,
}) => {
  return (
    <SnackbarProvider
      ref={notistackRef}
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      maxSnack={maxSnack}
      autoHideDuration={5000}
      Components={{
        success: ESSnackbar,
        error: ESSnackbar,
        warning: ESSnackbar,
        info: ESSnackbar,
        default: ESSnackbar,
      }}
    >
      {children}
    </SnackbarProvider>
  );
};

export const showSnackbar = (
  alertType: OptionsObject['variant'],
  messageKey: string,
  namespace = 'shared',
  interpolationValue?: { [key: string]: string } | undefined
) => {
  const isGenericError = messageKey === 'genericErrorMessage';
  const messageWithTranslation = translate(
    !!messageKey && !isGenericError ? namespace : 'shared',
    messageKey ? messageKey : 'genericErrorMessage',
    interpolationValue
  );
  notistackRef.current?.enqueueSnackbar(messageWithTranslation, {
    variant: alertType,
  });
};
