import { useTranslation } from 'react-i18next';
import { IconRecharging } from '@tabler/icons-react';
import { BrokerRoutes } from '@energy-stacks/broker/shared';
import { SidebarGroup } from '@energy-stacks/core/sidebar';

export const ChargingScheduleDrawerItem = () => {
  const { t } = useTranslation('chargingScheduleResult');
  const itemLabel = t('sidebarItem');

  return (
    <SidebarGroup
      testId="chargingScheduleSidebarButton"
      groupInitialPath={BrokerRoutes.ChargingSchedule}
      groupIcon={<IconRecharging />}
      groupLabel={itemLabel}
    ></SidebarGroup>
  );
};
