import React, { useCallback } from 'react';
import {
  ESDialogActionButton,
  ESDialogActions,
  ESDialogTitle,
} from '@energy-stacks/core/ui';
import { useTranslation } from 'react-i18next';
import { Dialog } from '@mui/material';
import { useESSnackbar } from '@energy-stacks/core/ui';
import { useUnassignChargingStationMutation } from '@energy-stacks/broker/feature-locations-data';

interface LocationDrawerUnassignDialogProps {
  locationId: string | undefined;
  chargingStations: string[];
  isOpen: boolean;
  onClose: () => void;
  testId?: string;
}

export const LocationDrawerUnassignDialog: React.FC<
  LocationDrawerUnassignDialogProps
> = ({ locationId, chargingStations, isOpen, onClose, testId }) => {
  const { showSnackbar } = useESSnackbar();
  const { t } = useTranslation('locations');

  const [unassignChargingStation, { isLoading }] =
    useUnassignChargingStationMutation();

  const handleUnassignChargingStation = useCallback(() => {
    if (!locationId) {
      return;
    }
    return unassignChargingStation({
      locationUuid: locationId,
      chargingStations: chargingStations,
    })
      .unwrap()
      .then(() => {
        showSnackbar(
          'success',
          'unassignChargingStationSuccessMessage',
          'locations'
        );
      })
      .catch(() => {
        showSnackbar('error');
      })
      .finally(onClose);
  }, [
    locationId,
    unassignChargingStation,
    chargingStations,
    showSnackbar,
    onClose,
  ]);

  return (
    <>
      <Dialog open={isOpen} fullWidth maxWidth="xs">
        <ESDialogTitle>{t('confirmUnassignTitle')}</ESDialogTitle>
        <ESDialogActions>
          <ESDialogActionButton
            color="error"
            onClick={onClose}
            disabled={isLoading}
          >
            {t('cancel')}
          </ESDialogActionButton>
          <ESDialogActionButton
            variant="contained"
            loading={isLoading}
            disabled={isLoading}
            onClick={handleUnassignChargingStation}
          >
            {t('unassign')}
          </ESDialogActionButton>
        </ESDialogActions>
      </Dialog>
    </>
  );
};
