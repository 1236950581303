import { SortingOrderModel } from '@energy-stacks/core/ui';
import { environment } from '@energy-stacks/feature-config';
import { appendZToDateString, createBaseQuery } from '@energy-stacks/shared';
import { createApi } from '@reduxjs/toolkit/query/react';
import {
  communicationDirectionNormalizer,
  communicationDirectionToEnum,
} from './normalizers/communicationDirectionNormalizer';
import {
  eventTypeNormalizer,
  eventTypeToEnum,
} from './normalizers/eventTypeNormalizer';
import {
  CommunicationDirectionModel,
  EventTypeModel,
  OcppNetworkLogsModel,
} from './ocppNetworkLogsModel';
import { PageNetworkLogsEntry } from './pageNetworkLogsEntry';

export interface GetOcppNetworkLogsBody {
  identityKey: string;
  timeFrom?: string;
  timeTo?: string;
  websocketInteraction: CommunicationDirectionModel | undefined;
  eventType: EventTypeModel | undefined;
  page: number;
  size: number;
  sort: SortingOrderModel;
}

type SortColumn = 'timestamp';

// NOTE: Network logs can be sorted only by timestamp BE field
const SORT_COLUMN: SortColumn = 'timestamp';

export const ocppNetworkLogsApi = createApi({
  reducerPath: 'brokerOcppNetworkLogsApi',
  tagTypes: ['OcppNetworkLogs'],
  keepUnusedDataFor: 0,
  baseQuery: createBaseQuery(`${environment.ocppServiceUrl}/network-logs/`),
  endpoints: (builder) => ({
    getOcppNetworkLogs: builder.query<
      OcppNetworkLogsModel,
      GetOcppNetworkLogsBody
    >({
      query: (searchParams) => ({
        url: `${searchParams.identityKey}/search`,
        method: 'GET',
        params: {
          ...searchParams,
          eventType: searchParams.eventType
            ? eventTypeToEnum[searchParams.eventType]
            : undefined,
          websocketInteraction: searchParams.websocketInteraction
            ? communicationDirectionToEnum[searchParams.websocketInteraction]
            : undefined,
          sort: [`${SORT_COLUMN},${searchParams.sort.order}`],
        },
      }),
      providesTags: ['OcppNetworkLogs'],
      transformResponse: (
        ocppNetworkLogs: PageNetworkLogsEntry
      ): OcppNetworkLogsModel => {
        return {
          totalElements: ocppNetworkLogs.totalElements ?? 0,
          totalPages: ocppNetworkLogs.totalPages ?? -1,
          content:
            ocppNetworkLogs?.content?.map((log) => ({
              communicationDirection: communicationDirectionNormalizer(
                log.webSocketInteraction
              ),
              eventType: eventTypeNormalizer(log.eventType),
              timestamp: appendZToDateString(log.timestamp),
              errorReason: log.errorReason,
            })) ?? [],
        };
      },
    }),
  }),
});

export const { useGetOcppNetworkLogsQuery } = ocppNetworkLogsApi;
