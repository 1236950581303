import { TourDetail } from './tourDetail';

export const forecastJobs = (tourDetails: TourDetail[]): TourDetail[] => {
  let lastConfirmedEndTimeOrForecast: string | null = null;

  tourDetails.forEach((job) => {
    if (job.forecastedStartTime && !job.confirmedEndTime) {
      job.forecastedEndTime = new Date(
        new Date(job.forecastedStartTime).getTime() + job.duration * 1000
      ).toISOString();
    } else if (job.confirmedStartTime && !job.confirmedEndTime) {
      job.forecastedEndTime = new Date(
        new Date(job.confirmedStartTime).getTime() + job.duration * 1000
      ).toISOString();
    }

    if (!job.confirmedStartTime && lastConfirmedEndTimeOrForecast) {
      job.forecastedStartTime = lastConfirmedEndTimeOrForecast;
      job.forecastedEndTime = new Date(
        new Date(lastConfirmedEndTimeOrForecast).getTime() + job.duration * 1000
      ).toISOString();
    }

    if (job.forecastedEndTime || job.confirmedEndTime) {
      lastConfirmedEndTimeOrForecast =
        job.confirmedEndTime || job.forecastedEndTime;
    }
  });

  return tourDetails;
};
