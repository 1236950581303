import { useCallback } from 'react';

export interface UsePromptDisplayParams {
  shouldPrompt: boolean;
  promptMessage: string;
  onAction: () => void;
}

export const usePromptDisplay = ({
  shouldPrompt,
  promptMessage,
  onAction,
}: UsePromptDisplayParams) => {
  return useCallback(() => {
    if (!shouldPrompt) {
      onAction();
      return;
    }

    const isConfirmed = window.confirm(promptMessage);
    if (isConfirmed) {
      onAction();
    }
  }, [onAction, promptMessage, shouldPrompt]);
};
