import { ConnectorModel } from '@energy-stacks/broker/feature-charging-stations-data';
import { ViewMode } from '../protocol-v1.6/Connectors';
import { ConnectorTariffsTabListView } from './ConnectorTariffsTabListView';
import { ConnectorTariffsTabCardView } from './ConnectorTariffsTabCardView';
import { TariffModel } from '@energy-stacks/broker/feature-tariffs-data';
import { useState } from 'react';
import { ConnectorTariffElementsPopper } from './ConnectorTariffElementsPopper';
import { TariffElementsView } from '@energy-stacks/broker/shared';

interface ConnectorTariffsTabProps {
  connector: ConnectorModel;
  testId: string;
  onAssignTariff: () => void;
  viewMode: ViewMode;
  tariff: TariffModel | undefined;
}

export const ConnectorTariffsTab = ({
  connector,
  testId,
  onAssignTariff,
  viewMode,
  tariff,
}: ConnectorTariffsTabProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleViewDetails = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleCloseViewDetails = (
    event:
      | React.MouseEvent<HTMLDivElement>
      | MouseEvent
      | TouchEvent
      | React.MouseEvent<HTMLButtonElement>
  ) => {
    if (anchorEl && anchorEl.contains(event.target as Node)) {
      return;
    }

    setAnchorEl(null);
  };

  return (
    <>
      {viewMode === 'grid' ? (
        <ConnectorTariffsTabCardView
          testId={testId}
          onAssignTariff={onAssignTariff}
          onViewDetails={handleViewDetails}
          tariff={tariff}
        />
      ) : (
        <ConnectorTariffsTabListView
          testId={testId}
          onAssignTariff={onAssignTariff}
          onViewDetails={handleViewDetails}
          tariff={tariff}
        />
      )}
      <ConnectorTariffElementsPopper
        anchorEl={anchorEl}
        handleClose={handleCloseViewDetails}
        popperContent={
          tariff?.elements ? (
            <TariffElementsView tariffElements={tariff.elements} />
          ) : null
        }
      />
    </>
  );
};
