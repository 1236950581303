import { ESButton, usePopover } from '@energy-stacks/core/ui';
import { ESTooltip } from '@energy-stacks/shared';
import { useAppSelector } from '@energy-stacks/store';
import { Fab } from '@mui/material';
import { IconPlus } from '@tabler/icons-react';
import { useTranslation } from 'react-i18next';
import { CreatePopover } from './CreatePopover';

export const CreateButtonWithPopup = () => {
  const { anchorEl, openPopover, closePopover, popoverOpen } = usePopover();
  const sidebarOpened = useAppSelector((state) => state.sidebar.sidebarOpened);
  const { t } = useTranslation('intelligentScheduling');

  if (!sidebarOpened) {
    return (
      <>
        <ESTooltip title={t('createScheduleButtonLabel')} placement="right">
          <Fab
            data-testid="createScheduleButtonSidebar"
            color="primary"
            size="medium"
            sx={{ boxShadow: 'none' }}
            onClick={openPopover}
          >
            <IconPlus />
          </Fab>
        </ESTooltip>
        <CreatePopover
          anchorEl={anchorEl}
          popoverOpen={popoverOpen}
          closePopover={closePopover}
        />
      </>
    );
  }

  return (
    <>
      <ESButton
        data-testid="createScheduleButtonSidebar"
        onClick={openPopover}
        sx={{
          width: '100%',
        }}
        startIcon={<IconPlus />}
      >
        {t('create')}
      </ESButton>
      <CreatePopover
        anchorEl={anchorEl}
        popoverOpen={popoverOpen}
        closePopover={closePopover}
      />
    </>
  );
};
