import { AlertReason } from '@energy-stacks/broker/feature-alert-management-data';
import { Chip } from '@mui/material';
import { useTranslation } from 'react-i18next';

type AlertReasonCellProps = {
  alertReason: AlertReason;
};

export const AlertReasonCell: React.FC<AlertReasonCellProps> = ({
  alertReason,
}) => {
  const [t] = useTranslation('alertManagement');

  return (
    <Chip
      label={t(alertReason)}
      sx={{
        backgroundColor:
          alertReason === 'OFFLINE' ? 'grey.300' : 'orange.light',
        color: alertReason === 'OFFLINE' ? 'grey.900' : 'error.dark',
        width: 77,
        cursor: 'pointer',
      }}
    />
  );
};
