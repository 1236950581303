import { Alert, Box, Switch, Typography } from '@mui/material';
import { IconAlertTriangle } from '@tabler/icons-react';
import { Dispatch, FC, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';

type ContaminationGlobalAlertProps = {
  count: number;
  isSwitchOn: boolean;
  onSwitchChange: Dispatch<SetStateAction<boolean>>;
};

export const ContaminationGlobalAlert: FC<ContaminationGlobalAlertProps> = ({
  count,
  isSwitchOn,
  onSwitchChange,
}) => {
  const [t] = useTranslation('tours');

  if (count === 0) {
    return null;
  }

  return (
    <Alert
      variant="outlined"
      severity="error"
      icon={<IconAlertTriangle />}
      action={
        <Box alignItems="center" display="flex">
          <Typography
            variant="inherit"
            noWrap
            sx={{ color: 'grey.600', marginLeft: 5 }}
          >
            {t('contaminationGlobalAlertActionLabel')}
          </Typography>
          <Switch
            data-testid="globalContaminationWarningSwitch"
            checked={isSwitchOn}
            onChange={(_, checked) => onSwitchChange(checked)}
          />
        </Box>
      }
      sx={{
        borderColor: 'error.main',
        borderRadius: 1,
        borderStyle: 'solid',
        borderWidth: 1,
        color: 'error.dark',
        py: 0.5,
        width: '100%',
        '.MuiAlert-icon': {
          color: 'error.dark',
        },
        '.MuiAlert-action': {
          p: 0,
        },
        '.MuiSwitch-track': {
          opacity: 0.6,
        },
      }}
    >
      {t('contaminationGlobalAlertMessage', { count })}
    </Alert>
  );
};
