import { Grid } from '@mui/material';
import { FunctionComponent, ReactNode, SVGProps } from 'react';

const ICON_SIZE = 20;

export const TourInfoHeaderItemLayout = ({
  Icon,
  children,
}: {
  Icon?: FunctionComponent<SVGProps<SVGSVGElement>>;
  children: ReactNode;
}) => {
  return (
    <Grid
      item
      gap={2}
      sm="auto"
      xs={12}
      sx={{
        alignItems: 'center',
        backgroundColor: 'grey.100',
        borderRadius: 1.5,
        color: 'grey.900',
        display: 'flex',
        px: 1.5,
        py: 1.25,
      }}
    >
      {Icon && <Icon height={ICON_SIZE} width={ICON_SIZE} />}
      {children}
    </Grid>
  );
};
