import { formatDuration } from '@energy-stacks/core/ui';
import { TourModel } from '@energy-stacks/fleet/feature-tours-data';
import { Typography } from '@mui/material';
import { TourIdCell } from '@energy-stacks/fleet/feature-tours';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

type VehicleUpcomingTour = {
  tourId: string;
  startDate?: string;
  status: TourModel['status'];
};

export const VehicleUpcomingTour: FC<VehicleUpcomingTour> = ({
  tourId,
  startDate,
  status,
}) => {
  const [t] = useTranslation('vehicles');

  if (status === 'ENROUTE') {
    return <TourIdCell tourId={tourId} />;
  } else if (status === 'PLANNED' && startDate) {
    return (
      <Typography
        variant="inherit"
        whiteSpace="break-spaces"
        sx={{ color: 'grey.500', fontStyle: 'italic', fontWeight: 400 }}
      >{`${t('nextTourPrefix')} ${formatDuration(
        new Date(),
        new Date(startDate),
        'dd:hh:mm'
      )}`}</Typography>
    );
  } else {
    return null;
  }
};
