import { toPayloadDate } from '@energy-stacks/shared';
import { AddChargingStationFormData } from './addChargingStationFormData';
import { ChargingStationCreateRequest } from './addChargingStationFormDataDto';
import { operationalStatusToCSOperationStatusDto } from './normalizers/chargingStationOperationalStatusNormalizer';

export const mapAddChargingStationFormDataToPayload = (
  addChargingStationFormData: AddChargingStationFormData
): ChargingStationCreateRequest => {
  return {
    chargingStationName: addChargingStationFormData.name || undefined,
    operationalStatus: addChargingStationFormData.operationalStatus
      ? operationalStatusToCSOperationStatusDto[
          addChargingStationFormData.operationalStatus
        ]
      : undefined,
    communicationType:
      addChargingStationFormData.communicationType || undefined,
    installationDate: addChargingStationFormData.installationDate
      ? toPayloadDate(addChargingStationFormData.installationDate)
      : null,
    contactPerson: addChargingStationFormData.contactPerson || undefined,
    loadmanagementAvailable: addChargingStationFormData.loadManagementSupported,
    calibrationLawCompliant: addChargingStationFormData.calibrationLawCompliant,
    authenticationMethods: addChargingStationFormData.authorizationMethods,
    locationId: addChargingStationFormData.location?.value || undefined,
    evses: addChargingStationFormData.evses.map((evseFEModel) => {
      return {
        evseId: evseFEModel.evseId,
        connectors: evseFEModel.connectors.map((connectorFEModel) => ({
          id: connectorFEModel.connectorId,
          standard: connectorFEModel.type.value,
          power_type: connectorFEModel.powerType.value,
          format: connectorFEModel.connectorFormat.value,
          max_voltage: Number(connectorFEModel.voltage),
          max_amperage: Number(connectorFEModel.maxAmperage),
          max_electric_power: connectorFEModel.maxPower
            ? Number(connectorFEModel.maxPower)
            : undefined,
          last_updated: toPayloadDate(new Date()), // TODO: remove last_updated when BE is ready, should be generated on the BE
        })),
      };
    }),
    identityKey: addChargingStationFormData.identityKey,
    userName: addChargingStationFormData.username || undefined,
    password: addChargingStationFormData.password || undefined,
    csmsUuid:
      addChargingStationFormData.defaultCentralSystem.value || undefined,
    chargingStationGroupUuid:
      addChargingStationFormData.chargingStationGroup?.value || undefined,
  };
};
