import { JobModel } from '@energy-stacks/fleet/feature-jobs-data';
import { formatDate } from '@energy-stacks/shared';
import { Stack, Typography } from '@mui/material';

export const JobDueDateCell = ({
  dueDate,
}: {
  dueDate: JobModel['dueDate'] | undefined;
}) => {
  if (!dueDate) {
    return <Typography>---</Typography>;
  }

  return (
    <Stack direction="column" gap={1} alignItems="start">
      <Typography fontWeight={700} color="grey.900">
        {formatDate(dueDate, 'dd/MM/yyyy')}
      </Typography>
      <Typography>{formatDate(dueDate, 'h:mm a').toLowerCase()}</Typography>
    </Stack>
  );
};
