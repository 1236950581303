export interface OcppMessageLogsModel {
  messages: OcppMessage[];
  totalElements: number;
  totalPages: number;
}

export interface OcppMessage {
  timestamp: string | null;
  ocppAction: OcppAction;
  messageDirection: MessageDirectionModel;
  messageUuid: string;
  message: string;
}

export type OcppAction =
  | 'authorize'
  | 'bootNotification'
  | 'cancelReservation'
  | 'changeAvailability'
  | 'changeConfiguration'
  | 'clearCache'
  | 'clearChargingProfile'
  | 'dataTransfer'
  | 'diagnosticsStatusNotification'
  | 'firmwareStatusNotification'
  | 'getConfiguration'
  | 'getDiagnostics'
  | 'getLocalListVersion'
  | 'heartbeat'
  | 'meterValues'
  | 'remoteStartTransaction'
  | 'remoteStopTransaction'
  | 'reserveNow'
  | 'reset'
  | 'sendLocalList'
  | 'setChargingProfile'
  | 'startTransaction'
  | 'stopTransaction'
  | 'statusNotification'
  | 'unlockConnector'
  | 'updateFirmware'
  | 'triggerMessage'
  | 'getCompositeSchedule'
  | 'unknown';

export enum OcppActionEnum {
  bootNotification = 'bootNotification',
  cancelReservation = 'cancelReservation',
  changeAvailability = 'changeAvailability',
  changeConfiguration = 'changeConfiguration',
  authorize = 'authorize',
  clearCache = 'clearCache',
  clearChargingProfile = 'clearChargingProfile',
  dataTransfer = 'dataTransfer',
  diagnosticsStatusNotification = 'diagnosticsStatusNotification',
  firmwareStatusNotification = 'firmwareStatusNotification',
  getConfiguration = 'getConfiguration',
  getDiagnostics = 'getDiagnostics',
  getLocalListVersion = 'getLocalListVersion',
  heartbeat = 'heartbeat',
  meterValues = 'meterValues',
  remoteStartTransaction = 'remoteStartTransaction',
  remoteStopTransaction = 'remoteStopTransaction',
  reserveNow = 'reserveNow',
  reset = 'reset',
  sendLocalList = 'sendLocalList',
  setChargingProfile = 'setChargingProfile',
  startTransaction = 'startTransaction',
  stopTransaction = 'stopTransaction',
  statusNotification = 'statusNotification',
  unlockConnector = 'unlockConnector',
  updateFirmware = 'updateFirmware',
  triggerMessage = 'triggerMessage',
  getCompositeSchedule = 'getCompositeSchedule',
  unknown = 'unknown',
}

export type MessageDirectionModel =
  | 'chargingStationToServer'
  | 'serverToChargingStation'
  | 'unknown';
