import { Box } from '@mui/material';
import { ESLoadingIndicator } from './ESLoadingIndicator';

export const ESPageLoadingIndicator = () => {
  return (
    <Box sx={{ mt: 24 }}>
      <ESLoadingIndicator />
    </Box>
  );
};
