import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Typography } from '@mui/material';
import { FC, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { MoSocSection } from './MoSocSection';
import { OptimizeChargingSection } from './OptimizeChargingSection';
import { CreateChargingScheduleState } from '../useCreateChargingScheduleState';

type OptimizeChargingStepProps = {
  isMoSocIndividual: CreateChargingScheduleState['isMoSocIndividual'];
  onIsMoSocIndividualChange: (isMoSocIndividual: boolean) => void;
  fleetMoSoc: CreateChargingScheduleState['fleetMoSoc'];
  onFleetMoSocChange: (fleetMoSoc: string) => void;
  isPriceOptimized: CreateChargingScheduleState['isPriceOptimized'];
  isSolarOptimized: CreateChargingScheduleState['isSolarOptimized'];
};

type OptimizeChargingFormData = {
  isMoSocIndividual: boolean;
  fleetMoSoc: string;
};

const optimizeChargingValidationSchema = yup.object().shape({
  isMoSocIndividual: yup.boolean(),
  fleetMoSoc: yup.string().when('isMoSocIndividual', {
    is: true,
    then: yup.string().notRequired(),
    otherwise: yup
      .string()
      .required('optimizeChargingFormErrors.fleetMoSocRequired')
      .test(
        'range',
        'optimizeChargingFormErrors.fleetMoSocOutOfRange',
        (value) => Number(value) >= 10 && Number(value) <= 100
      ),
  }),
});

export const OptimizeChargingStep: FC<OptimizeChargingStepProps> = ({
  isMoSocIndividual,
  onIsMoSocIndividualChange,
  fleetMoSoc,
  onFleetMoSocChange,
  isPriceOptimized,
  isSolarOptimized,
}) => {
  const [t] = useTranslation('chargingSchedule');

  const defaultOptimizeChargingValues: OptimizeChargingFormData =
    useMemo(() => {
      return {
        isMoSocIndividual,
        fleetMoSoc,
      };
    }, [isMoSocIndividual, fleetMoSoc]);
  const methods = useForm<OptimizeChargingFormData>({
    defaultValues: defaultOptimizeChargingValues,
    mode: 'onTouched',
    resolver: yupResolver(optimizeChargingValidationSchema),
  });

  return (
    <>
      <Typography
        variant="h3"
        sx={{
          color: 'grey.900',
          fontWeight: 700,
          mb: 6.5,
          mt: 4,
          textAlign: 'center',
        }}
      >
        {t('optimizeChargingStepTitle')}
      </Typography>
      <Box
        sx={{
          backgroundColor: 'background.paper',
          borderRadius: 2,
          display: 'flex',
          justifyContent: 'space-between',
          mx: 4,
          p: 6,
        }}
      >
        <FormProvider {...methods}>
          <MoSocSection
            onIsMoSocIndividualChange={onIsMoSocIndividualChange}
            onFleetMoSocChange={onFleetMoSocChange}
          />
          <OptimizeChargingSection
            isPriceOptimized={isPriceOptimized}
            isSolarOptimized={isSolarOptimized}
          />
          {/* Vehicle readiness */}
        </FormProvider>
      </Box>
    </>
  );
};
