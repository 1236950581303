import { Coords, MapMarkerWrapper } from '@energy-stacks/fleet/shared';
import { abbr } from './numberAbbr';
import { useState } from 'react';

interface MarkerProps extends Coords {
  /** Marker text content */
  text?: string;
  /** Apply container styles like width, height, zIndex  */
  style?: React.CSSProperties;
  strokeColor?: string;
  isActive?: boolean;
  onClick?: () => void;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
}

const HOVER_WIDTH = 30;
const HOVER_HEIGHT = 34;
const DEFAULT_WIDTH = 24;
const DEFAULT_HEIGHT = 28;

/** LocationMarker to be positioned on the map and display object counts.
 *
 * Abbreviates numbers by using Intl.NumberFormat(en-GB, short) in order to fit and display content properly. */
export const LocationMarker: React.FC<MarkerProps> = ({
  text = '1',
  style = {
    width: DEFAULT_WIDTH,
    height: DEFAULT_HEIGHT,
    marginTop: -28,
    marginLeft: -12,
  },
  onClick,
  isActive = false,
  strokeColor = '#1565C0',
  onMouseEnter,
  onMouseLeave,
}) => {
  const [hasHover, setHasHover] = useState(false);
  const numberContent = parseInt(text, 10);
  const content = isNaN(numberContent)
    ? text.charCodeAt(0) > 255
      ? text.slice(0, 2)
      : text.slice(0, 1)
    : abbr(numberContent);
  return (
    <MapMarkerWrapper
      style={{
        ...style,
        width: hasHover || isActive ? HOVER_WIDTH : DEFAULT_WIDTH,
        height: hasHover || isActive ? HOVER_HEIGHT : DEFAULT_HEIGHT,
      }}
      onMouseEnter={() => {
        onMouseEnter?.();
        setHasHover(true);
      }}
      onMouseLeave={() => {
        onMouseLeave?.();
        setHasHover(false);
      }}
      onClick={onClick}
    >
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 28" fill="none">
        <path
          d="M12 26.1094L11.5146 26.6811C11.7945 26.9188 12.2055 26.9188 12.4854 26.6811L12 26.1094ZM12 26.1094C12.4854 26.6811 12.4856 26.681 12.4857 26.6808L12.4863 26.6804L12.488 26.6789L12.4941 26.6737L12.5171 26.654C12.537 26.6369 12.5661 26.6119 12.6038 26.5792C12.6791 26.5138 12.7888 26.418 12.9278 26.2944C13.2059 26.0473 13.6019 25.6892 14.0765 25.2426C15.0248 24.3502 16.2915 23.1 17.5606 21.6723C18.827 20.2475 20.1107 18.6294 21.0816 17.0012C22.0423 15.3905 22.75 13.6832 22.75 12.1003C22.75 6.16762 17.9364 1.35938 12 1.35938C6.06358 1.35938 1.25 6.16762 1.25 12.1003C1.25 13.6832 1.95774 15.3905 2.91835 17.0012C3.88934 18.6294 5.17296 20.2475 6.43944 21.6723C7.7085 23.1 8.97523 24.3502 9.9235 25.2426C10.3981 25.6892 10.7941 26.0473 11.0722 26.2944C11.2112 26.418 11.3209 26.5138 11.3962 26.5792C11.4339 26.6119 11.463 26.6369 11.4829 26.654L11.5059 26.6737L11.512 26.6789L11.5137 26.6804L11.5143 26.6808C11.5144 26.681 11.5146 26.6811 12 26.1094Z"
          fill={isActive ? strokeColor : 'white'}
          stroke={isActive ? 'white' : strokeColor}
          strokeWidth={1.5}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <text
          textLength={
            !isNaN(numberContent)
              ? `${
                  parseInt(text, 10) === 1
                    ? 18
                    : Math.min(20 * text.length + 10, 65)
                }%`
              : text.charCodeAt(0) > 255
              ? 14
              : 9
          }
          fontSize={14}
          fontFamily="Poppins"
          fontStyle="normal"
          fontWeight={700}
          lengthAdjust="spacingAndGlyphs"
          x="50%"
          y="50%"
          fill={isActive ? 'white' : strokeColor}
          dominantBaseline="middle"
          textAnchor="middle"
        >
          {content}
        </text>
      </svg>
    </MapMarkerWrapper>
  );
};
