import { useContext } from 'react';
import { EditTourDetailsContext } from './EditTourDetailsContext';

export const useEditTourDetailsContext = () => {
  const context = useContext(EditTourDetailsContext);
  if (context === undefined) {
    throw new Error(
      'useEditTourDetailsContext must be used within a EditTourDetailsContext.Provider'
    );
  }
  return context;
};
