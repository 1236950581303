import { TariffDto } from '../chargingStationDto';
import { TariffModel } from '../TariffModel';
import { appendZToDateString } from '@energy-stacks/shared';

export const tariffNormalizer = (tariff: TariffDto): TariffModel => {
  return {
    id: tariff.id,
    type: tariff.type,
    name: getTariffName(tariff.tariff_alt_text),
    startDateTime:
      tariff.start_date_time && appendZToDateString(tariff.start_date_time),
    endDateTime:
      tariff.end_date_time && appendZToDateString(tariff.end_date_time),
  };
};

const getTariffName = (tariffAltText: TariffDto['tariff_alt_text'] | null) => {
  const englishTextObj = tariffAltText?.find(
    (altTextObj) => altTextObj.language === 'en'
  );
  return englishTextObj?.text ?? 'N/A';
};
