import { useCallback } from 'react';
import { useFormContext } from 'react-hook-form';

export const useMondayToFridayChange = () => {
  const { getValues, setValue } = useFormContext();

  // When monday-friday value is changed either thorugh the input field or the toggle
  // the monday values are set to all the days except saturday
  const handleMondayToFridayChange = useCallback(
    (newTime: Date | null, period: 'startTime' | 'endTime') => {
      const isMondayWorkingDay = getValues(
        'workingHours.regular.MONDAY.isWorkingDay'
      );
      Object.keys(getValues('workingHours.regular')).forEach((day) => {
        if (day === 'SATURDAY' || day === 'SUNDAY') {
          return;
        }
        setValue(`workingHours.regular.${day}.${period}`, newTime, {
          shouldDirty: true,
          shouldValidate: true,
        });
        setValue(
          `workingHours.regular.${day}.isWorkingDay`,
          isMondayWorkingDay,
          {
            shouldDirty: true,
            shouldValidate: true,
          }
        );
      });
    },
    [getValues, setValue]
  );

  // If monday-friday is toggled off then all the days except Saturday and Sunday are set to off
  const handleMondayToFridayDayOffToggle = useCallback(
    (areWorkingDays: boolean) => {
      Object.keys(getValues('workingHours.regular')).forEach((day) => {
        if (day === 'SATURDAY' || day === 'SUNDAY') {
          return;
        }
        setValue(`workingHours.regular.${day}.isWorkingDay`, areWorkingDays, {
          shouldDirty: true,
        });
      });
    },
    [getValues, setValue]
  );

  return {
    handleMondayToFridayChange,
    handleMondayToFridayDayOffToggle,
  };
};
