import { GeoLocation } from './brokerLocationFormData';
import { ChargingStationStatus } from '@energy-stacks/broker/feature-charging-stations-data';

export interface BrokerLocationChargingStation {
  identityKey: string;
  chargingStationName?: string;
  chargingStationModel?: string;
  csmsUuid: string;
  shadow: ChargingStationStatus;
}

export interface BrokerLocationModel {
  uuid: string;
  name: string;
  address: string;
  city: string;
  country: { label: string; value: string };
  postalCode: string;
  state: string;
  coordinates: GeoLocation;
  chargingStations: BrokerLocationChargingStation[];
}

export enum BrokerShadowChargingStationStatusEnum {
  available = 'available',
  faulted = 'faulted',
  unavailable = 'unavailable',
}
