import React, { useCallback, useState } from 'react';
import { Column } from '@tanstack/react-table';
import {
  MenuItem,
  Checkbox,
  ListItemText,
  Divider,
  Box,
  Stack,
} from '@mui/material';
import { xor } from 'lodash-es';
import { useTranslation } from 'react-i18next';
import { SearchTextField } from './SearchTextField';
import { TableFilterButton } from './TableFilterButton';
import { ESTooltip } from './ESTooltip';
import { useUpdateEffect } from 'usehooks-ts';

export type FilterOption = {
  value: string;
  label: string;
  Icon?: React.ReactNode;
};

export interface TableColumnFilterProps {
  columnLabel: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  column: Column<any, unknown> | undefined;
  options: FilterOption[];
  isSearchable?: boolean;
  isClearable?: boolean;
  hasTooltip?: boolean;
  testId?: string;
  onApplyFilter?: (option: FilterOption) => void;
  initialFilters?: string[];
}

export const TableColumnFilter: React.FC<TableColumnFilterProps> = ({
  columnLabel,
  column,
  options,
  isSearchable = false,
  isClearable = false,
  hasTooltip = false,
  testId,
  initialFilters = [],
}) => {
  const [t] = useTranslation('shared');
  const [inputValue, setInputValue] = useState<string>('');

  const [checkedFilters, setCheckedFilters] =
    useState<string[]>(initialFilters);

  //NOTE: we are not using debounce at this moment. We are waiting real product to se how many lists items will be in real
  const searchedOptions = isSearchable // If filter options are not searchable do not filter them on every input change
    ? options.filter((option) => {
        return option.label.toLowerCase().includes(inputValue.toLowerCase());
      })
    : options;

  // reset internal checked state when filters are reset externally(e.g. from "Clear all" button)
  useUpdateEffect(() => {
    if (column && !column.getIsFiltered()) {
      setCheckedFilters([]);
    }
  }, [column]);

  const handleApplyFilter = useCallback(
    (option: FilterOption) => {
      if (!column) {
        return;
      }

      setCheckedFilters(xor(checkedFilters, [option.value]));
      column &&
        column.setFilterValue((old: string[]) => {
          return xor(old, [option.value]);
        });
    },
    [checkedFilters, column]
  );

  return (
    <TableFilterButton
      testId={testId}
      title={columnLabel}
      appliedFiltersCount={checkedFilters.length}
      renderPopover={() => {
        return (
          <Box sx={{ minWidth: 160 }}>
            {isSearchable ? (
              <Box
                sx={{
                  position: 'sticky',
                  p: 3,
                  top: 0,
                  backgroundColor: ({ palette }) => palette.background.paper,
                  zIndex: 2,
                  '& .MuiInput-root': {
                    paddingY: 1,
                  },
                }}
              >
                <SearchTextField
                  value={inputValue}
                  autoFocus
                  onChange={setInputValue}
                  testId={testId}
                />
              </Box>
            ) : null}
            {searchedOptions.length < 1 ? (
              <Box p={3}>
                <ListItemText primary={t('noSearchOptions')} />
              </Box>
            ) : (
              searchedOptions.map((option, index) => {
                return (
                  <MenuItem
                    data-testid={`${testId}MenuItem${index}`}
                    key={option.value}
                    value={option.value}
                    onClick={() => {
                      handleApplyFilter(option);
                    }}
                  >
                    <Stack
                      direction="row"
                      alignItems="center"
                      gap={2.5}
                      marginRight={2.5}
                    >
                      <Checkbox
                        sx={{ p: 0 }}
                        checked={checkedFilters.includes(option.value)}
                      />
                      {option.Icon ? option.Icon : null}
                    </Stack>

                    <ESTooltip title={hasTooltip ? option.label : ''}>
                      <ListItemText
                        primary={option.label}
                        sx={{
                          '& > span': {
                            maxWidth: 500,
                            textOverflow: 'ellipsis',
                            overflow: 'hidden',
                          },
                        }}
                        onClick={(event) => {
                          event.stopPropagation();
                          handleApplyFilter(option);
                        }}
                      />
                    </ESTooltip>
                  </MenuItem>
                );
              })
            )}
            {isClearable ? (
              <Box
                sx={{
                  position: 'sticky',
                  bottom: 0,
                  backgroundColor: ({ palette }) => palette.background.paper,
                  zIndex: 2,
                  '& .MuiMenuItem-root': {
                    paddingY: 3,
                  },
                }}
              >
                <Divider />
                <MenuItem
                  disabled={checkedFilters.length === 0}
                  onClick={() => {
                    setCheckedFilters([]);
                    column && column.setFilterValue([]);
                  }}
                >
                  <ListItemText
                    sx={{
                      '& .MuiListItemText-primary': { color: 'primary.main' },
                    }}
                    primary={t('clearAll')}
                  />
                </MenuItem>
              </Box>
            ) : null}
          </Box>
        );
      }}
      isActive={checkedFilters.length > 0}
    />
  );
};
