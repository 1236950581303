import { ESScreenDrawer } from '@energy-stacks/feature-screen-drawer';
import { useNavigate } from 'react-router-dom';
import { ChargingStationDetails } from './ChargingStationDetails';
import { BrokerRoutes } from '@energy-stacks/broker/shared';

export const ChargingStationDetailsSlider: React.FC = () => {
  const navigate = useNavigate();
  return (
    <ESScreenDrawer
      fullScreen
      closeButtonTopPosition={85}
      onClose={() => navigate(BrokerRoutes.ChargingStations)}
    >
      <ChargingStationDetails />
    </ESScreenDrawer>
  );
};
