import { ErrorBoundary } from 'react-error-boundary';
import { ErrorFallback } from './ErrorFallback';
import { ESRoutes } from '../ESRoutes';

interface ESErrorBoundaryProps {
  children: React.ReactNode;
}

export const ESErrorBoundary: React.FC<ESErrorBoundaryProps> = ({
  children,
}) => {
  return (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    <ErrorBoundary
      FallbackComponent={({ error, resetErrorBoundary }) => (
        <ErrorFallback
          error={error}
          resetErrorBoundary={resetErrorBoundary}
          navigationLink={ESRoutes.Root}
        />
      )}
    >
      {children}
    </ErrorBoundary>
  );
};
