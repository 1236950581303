import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const CcsType1FemaleIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props}>
      <path d="M17.271 15.4765C16.7809 14.9842 16.089 14.724 15.2692 14.724H8.78677C7.96694 14.724 7.27467 14.9842 6.78502 15.4765C6.23315 16.0311 5.95352 16.8482 5.95352 17.9057C5.95352 18.9628 6.23315 19.7803 6.78502 20.3349C7.27506 20.8272 7.96733 21.0874 8.78677 21.0874H15.2692C16.089 21.0874 16.7813 20.8272 17.271 20.3349C17.8228 19.7803 18.1025 18.9628 18.1025 17.9057C18.1025 16.8486 17.8228 16.0311 17.271 15.4765ZM15.2692 20.5429H8.78677C7.31084 20.5429 6.49761 19.6064 6.49761 17.9057C6.49761 16.205 7.31045 15.2685 8.78677 15.2685H15.2692C16.7451 15.2685 17.5584 16.205 17.5584 17.9057C17.5584 19.6064 16.7451 20.5429 15.2692 20.5429Z" />
      <path d="M9.34137 15.9183C8.21312 15.9183 7.29489 16.8366 7.29489 17.9648C7.29489 19.093 8.21312 20.0113 9.34137 20.0113C10.4696 20.0113 11.3878 19.093 11.3878 17.9648C11.3878 16.8366 10.47 15.9183 9.34137 15.9183ZM9.34137 18.7835C8.88945 18.7835 8.5227 18.4171 8.5227 17.9648C8.5227 17.5129 8.88906 17.1461 9.34137 17.1461C9.79329 17.1461 10.16 17.5125 10.16 17.9648C10.16 18.4167 9.79329 18.7835 9.34137 18.7835Z" />
      <path d="M14.7294 15.9183C13.6012 15.9183 12.6829 16.8366 12.6829 17.9648C12.6829 19.093 13.6012 20.0113 14.7294 20.0113C15.8576 20.0113 16.7759 19.093 16.7759 17.9648C16.7759 16.8366 15.858 15.9183 14.7294 15.9183ZM14.7294 18.7835C14.2775 18.7835 13.9107 18.4171 13.9107 17.9648C13.9107 17.5129 14.2771 17.1461 14.7294 17.1461C15.1813 17.1461 15.5481 17.5125 15.5481 17.9648C15.5481 18.4167 15.1813 18.7835 14.7294 18.7835Z" />
      <path d="M12.0615 13.3935C14.9511 13.3935 17.3021 11.0425 17.3021 8.15285C17.3021 5.2632 14.9511 2.9122 12.0615 2.9122C9.1718 2.9122 6.8208 5.2632 6.8208 8.15285C6.8208 11.0425 9.17142 13.3935 12.0615 13.3935ZM12.0615 3.47107C14.6431 3.47107 16.7432 5.57122 16.7432 8.15285C16.7432 10.7345 14.6431 12.8346 12.0615 12.8346C9.47983 12.8346 7.37967 10.7345 7.37967 8.15285C7.37967 5.57122 9.47983 3.47107 12.0615 3.47107Z" />
      <path d="M10.2662 7.72736C10.9033 7.72736 11.4217 7.20893 11.4217 6.57189C11.4217 5.93484 10.9033 5.41681 10.2662 5.41681C9.62917 5.41681 9.11075 5.93484 9.11075 6.57189C9.11075 7.20932 9.62917 7.72736 10.2662 7.72736ZM10.2662 6.17247C10.4867 6.17247 10.666 6.35176 10.666 6.57228C10.666 6.79279 10.4867 6.97208 10.2662 6.97208C10.0457 6.97208 9.86641 6.79279 9.86641 6.57228C9.86641 6.35176 10.0457 6.17247 10.2662 6.17247Z" />
      <path d="M10.9511 10.7601C10.9511 11.3972 11.4695 11.9156 12.1066 11.9156C12.7436 11.9156 13.262 11.3972 13.262 10.7601C13.262 10.1231 12.7436 9.60504 12.1066 9.60504C11.4695 9.60504 10.9511 10.1235 10.9511 10.7601ZM12.1066 10.3607C12.3271 10.3607 12.5064 10.54 12.5064 10.7605C12.5064 10.981 12.3271 11.1603 12.1066 11.1603C11.886 11.1603 11.7067 10.981 11.7067 10.7605C11.7067 10.54 11.886 10.3607 12.1066 10.3607Z" />
      <path d="M13.8454 7.72736C14.4825 7.72736 15.0009 7.20893 15.0009 6.57189C15.0009 5.93484 14.4825 5.41681 13.8454 5.41681C13.2084 5.41681 12.6899 5.93484 12.6899 6.57189C12.6899 7.20932 13.2084 7.72736 13.8454 7.72736ZM13.8454 6.17247C14.0659 6.17247 14.2452 6.35176 14.2452 6.57228C14.2452 6.79279 14.0659 6.97208 13.8454 6.97208C13.6249 6.97208 13.4456 6.79279 13.4456 6.57228C13.4456 6.35176 13.6249 6.17247 13.8454 6.17247Z" />
      <path d="M9.45766 10.1678C9.84502 10.1678 10.1604 9.85277 10.1604 9.46541C10.1604 9.07805 9.84502 8.76303 9.45766 8.76303C9.0703 8.76303 8.75528 9.07805 8.75528 9.46541C8.75528 9.85277 9.0703 10.1678 9.45766 10.1678ZM9.45766 9.26123C9.57045 9.26123 9.66223 9.35302 9.66223 9.46541C9.66223 9.57781 9.57045 9.66959 9.45766 9.66959C9.34488 9.66959 9.25348 9.57781 9.25348 9.46541C9.25348 9.35302 9.34488 9.26123 9.45766 9.26123Z" />
      <path d="M13.9621 9.46541C13.9621 9.85277 14.2771 10.1678 14.6644 10.1678C15.0518 10.1678 15.3672 9.85277 15.3672 9.46541C15.3672 9.07805 15.0518 8.76303 14.6644 8.76303C14.2771 8.76303 13.9621 9.07805 13.9621 9.46541ZM14.869 9.46541C14.869 9.5782 14.7772 9.66959 14.6644 9.66959C14.5517 9.66959 14.4603 9.57781 14.4603 9.46541C14.4603 9.35302 14.5521 9.26123 14.6644 9.26123C14.7772 9.26123 14.869 9.35263 14.869 9.46541Z" />
    </SvgIcon>
  );
};
