import {
  JobModel,
  useGetJobPoolQuery,
} from '@energy-stacks/fleet/feature-jobs-data';
import { useAppSelector } from '@energy-stacks/fleet/store';
import { toPayloadDate } from '@energy-stacks/shared';
import { useEffect, useState } from 'react';

type Params = {
  jobIds: JobModel['jobId'][];
  startDate: string | undefined;
  endDate: string | undefined;
};

type JobPoolFetchingState = 'idle' | 'loading' | 'error';

export const useJobsPool = (
  { jobIds, startDate, endDate }: Params,
  { skip = false }
) => {
  const [jobPool, setJobPool] = useState<JobModel[]>();
  const [jobPoolFetchingState, setJobPoolFetchingState] =
    useState<JobPoolFetchingState>('idle');
  const selectedPlantId = useAppSelector(
    (state) => state.plant.selectedPlantId
  );

  const { data, isError, isLoading, refetch, isFetching } = useGetJobPoolQuery(
    {
      jobUids: jobIds,
      depotId: selectedPlantId || '',
      status: 'TO_DO',
      endDate: toPayloadDate(
        endDate ? new Date(endDate) : new Date(),
        'yyyy-MM-dd'
      ),
      startDate: toPayloadDate(
        startDate ? new Date(startDate) : new Date(),
        'yyyy-MM-dd'
      ),
    },
    { skip }
  );

  useEffect(() => {
    if (isFetching) {
      setJobPool(undefined);
      setJobPoolFetchingState('loading');
    }
  }, [isFetching]);

  useEffect(() => {
    if (data && !jobPool) {
      setJobPool(data);
      setJobPoolFetchingState('idle');
    }
  }, [data, jobPool]);

  useEffect(() => {
    if (isLoading) {
      setJobPoolFetchingState('loading');
    }
  }, [isLoading]);

  useEffect(() => {
    if (isError) {
      setJobPoolFetchingState('error');
    }
  }, [isError]);

  return {
    jobPool,
    setJobPool,
    isLoadingJobPool: jobPoolFetchingState === 'loading',
    isGetJobPoolError: jobPoolFetchingState === 'error',
    refetch,
  };
};
