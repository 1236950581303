import React from 'react';
import { ESMenuItem, useESMenuContext } from '@energy-stacks/core/ui';
import { useTranslation } from 'react-i18next';

interface LocationDetailsDrawerEditMenuItemProps {
  onClick: () => void;
}

export const LocationDetailsDrawerEditMenuItem: React.FC<
  LocationDetailsDrawerEditMenuItemProps
> = ({ onClick }) => {
  const { t } = useTranslation('locations');
  const { closeESMenu } = useESMenuContext();

  return (
    <ESMenuItem
      onClick={() => {
        onClick();
        closeESMenu();
      }}
    >
      {t('rowActionsEditItemLabel')}
    </ESMenuItem>
  );
};
