import { toPayloadDateBroker } from '@energy-stacks/shared';
import { DateTimePicker, LocalizationProvider } from '@mui/lab';
import { TextField } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

interface ConfigurationDateTimeValueFieldProps {
  handleValueChange: (val: string) => void;
  controlerValue: string;
}
export const ConfigurationDateTimeValueField: React.FC<
  ConfigurationDateTimeValueFieldProps
> = ({ handleValueChange, controlerValue }) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DateTimePicker
        onChange={(value) => {
          const date = value !== null ? new Date(value) : null;

          if (date instanceof Date && !isNaN(date.getTime())) {
            handleValueChange(toPayloadDateBroker(date));
          } else {
            handleValueChange('');
          }
        }}
        views={['year', 'month', 'day', 'hours', 'minutes', 'seconds']}
        inputFormat="yyyy/MM/dd hh:mm:ss a"
        value={controlerValue}
        renderInput={(params) => {
          return (
            <TextField type="date" disabled={true} {...params} error={false} />
          );
        }}
      />
    </LocalizationProvider>
  );
};
