import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const CcsFemaleIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props}>
      <path d="M17.271 15.4621C16.7809 14.9697 16.089 14.7096 15.2692 14.7096H8.78677C7.96694 14.7096 7.27467 14.9697 6.78502 15.4621C6.23315 16.0167 5.95352 16.8338 5.95352 17.8913C5.95352 18.9484 6.23315 19.7659 6.78502 20.3205C7.27506 20.8128 7.96733 21.073 8.78677 21.073H15.2692C16.089 21.073 16.7813 20.8128 17.271 20.3205C17.8228 19.7659 18.1025 18.9484 18.1025 17.8913C18.1025 16.8338 17.8228 16.0167 17.271 15.4621ZM15.2692 20.5285H8.78677C7.31084 20.5285 6.49761 19.592 6.49761 17.8913C6.49761 16.1906 7.31045 15.254 8.78677 15.254H15.2692C16.7451 15.254 17.5584 16.1906 17.5584 17.8913C17.5584 19.592 16.7451 20.5285 15.2692 20.5285Z" />
      <path d="M10.5101 6.57503C11.0036 6.57503 11.4053 6.17328 11.4053 5.67974C11.4053 5.18621 11.0036 4.78445 10.5101 4.78445C10.0165 4.78445 9.61478 5.18621 9.61478 5.67974C9.61478 6.17328 10.0165 6.57503 10.5101 6.57503ZM10.5101 5.23327C10.7562 5.23327 10.9565 5.43356 10.9565 5.67974C10.9565 5.92593 10.7562 6.12622 10.5101 6.12622C10.2639 6.12622 10.0636 5.92593 10.0636 5.67974C10.0636 5.43356 10.2639 5.23327 10.5101 5.23327Z" />
      <path d="M13.46 6.57503C13.9539 6.57503 14.3557 6.17328 14.3557 5.67974C14.3557 5.18621 13.9539 4.78445 13.46 4.78445C12.9664 4.78445 12.5647 5.18621 12.5647 5.67974C12.5647 6.17328 12.9664 6.57503 13.46 6.57503ZM13.46 5.23327C13.7066 5.23327 13.9069 5.43356 13.9069 5.67974C13.9069 5.92593 13.7062 6.12622 13.46 6.12622C13.2138 6.12622 13.0135 5.92593 13.0135 5.67974C13.0135 5.43356 13.2138 5.23327 13.46 5.23327Z" />
      <path d="M13.6307 7.79581C13.6307 8.55652 14.2499 9.17568 15.0106 9.17568C15.7713 9.17568 16.3905 8.55652 16.3905 7.79581C16.3905 7.03509 15.7713 6.41632 15.0106 6.41632C14.2495 6.41671 13.6307 7.03547 13.6307 7.79581ZM15.0106 6.86552C15.524 6.86552 15.9417 7.28283 15.9417 7.79619C15.9417 8.30956 15.524 8.72726 15.0106 8.72726C14.4972 8.72726 14.0795 8.30956 14.0795 7.79619C14.0795 7.28283 14.4972 6.86552 15.0106 6.86552Z" />
      <path d="M10.5101 9.06949C9.74934 9.06949 9.13019 9.68865 9.13019 10.4494C9.13019 11.2101 9.74934 11.8293 10.5101 11.8293C11.2708 11.8293 11.8899 11.2101 11.8899 10.4494C11.8899 9.68865 11.2708 9.06949 10.5101 9.06949ZM10.5101 11.3804C9.99669 11.3804 9.57899 10.9627 9.57899 10.4494C9.57899 9.936 9.99669 9.5183 10.5101 9.5183C11.0234 9.5183 11.4411 9.936 11.4411 10.4494C11.4411 10.9627 11.0234 11.3804 10.5101 11.3804Z" />
      <path d="M10.4226 7.79581C10.4226 7.03509 9.8034 6.41632 9.04268 6.41632C8.28197 6.41632 7.66281 7.03509 7.66281 7.79581C7.66281 8.55652 8.28197 9.17568 9.04268 9.17568C9.8034 9.17568 10.4226 8.55691 10.4226 7.79581ZM8.11162 7.79581C8.11162 7.28283 8.52932 6.86513 9.04268 6.86513C9.55605 6.86513 9.97375 7.28244 9.97375 7.79581C9.97375 8.30917 9.55605 8.72687 9.04268 8.72687C8.52932 8.72687 8.11162 8.30956 8.11162 7.79581Z" />
      <path d="M13.4285 9.06949C12.6678 9.06949 12.049 9.68865 12.049 10.4494C12.049 11.2101 12.6678 11.8293 13.4285 11.8293C14.1892 11.8293 14.8084 11.2101 14.8084 10.4494C14.8084 9.68865 14.1896 9.06949 13.4285 9.06949ZM13.4285 11.3804C12.9155 11.3804 12.4978 10.9627 12.4978 10.4494C12.4978 9.936 12.9151 9.5183 13.4285 9.5183C13.9419 9.5183 14.3596 9.936 14.3596 10.4494C14.3596 10.9627 13.9419 11.3804 13.4285 11.3804Z" />
      <path d="M10.6816 7.79581C10.6816 8.55652 11.3007 9.17568 12.0615 9.17568C12.8222 9.17568 13.4409 8.55652 13.4409 7.79581C13.4409 7.03509 12.8222 6.41632 12.0615 6.41632C11.3007 6.41671 10.6816 7.03547 10.6816 7.79581ZM12.0615 6.86552C12.5744 6.86552 12.9921 7.28283 12.9921 7.79619C12.9921 8.30956 12.5748 8.72726 12.0615 8.72726C11.5481 8.72726 11.1304 8.30956 11.1304 7.79619C11.1304 7.28283 11.5481 6.86552 12.0615 6.86552Z" />
      <path d="M12.0264 13.6681C15.3595 13.6681 18.0714 10.9565 18.0714 7.62313C18.0714 6.42994 17.7318 5.30441 17.0625 4.27884C16.3209 3.14204 12.469 3.18716 12.0299 3.18716C11.5901 3.17899 7.83704 3.03626 6.99893 4.26718C6.33349 5.24413 5.9823 6.40505 5.9823 7.62352C5.9823 10.9565 8.69343 13.6681 12.0264 13.6681ZM7.44696 4.57247C7.86076 3.96499 9.70267 3.75731 11.1813 3.75731C11.4952 3.75731 11.7857 3.74175 12.0525 3.75731C13.6478 3.77286 16.1735 3.90976 16.6079 4.57559C17.2189 5.51132 17.5288 6.53689 17.5288 7.62352C17.5288 10.6575 15.0604 13.1259 12.0264 13.1259C8.99251 13.1259 6.52445 10.6575 6.52445 7.62352C6.52484 6.51394 6.84336 5.45881 7.44696 4.57247Z" />
      <path d="M9.34137 15.9039C8.21312 15.9039 7.29489 16.8221 7.29489 17.9504C7.29489 19.0786 8.21312 19.9969 9.34137 19.9969C10.4696 19.9969 11.3878 19.0786 11.3878 17.9504C11.3878 16.8221 10.47 15.9039 9.34137 15.9039ZM9.34137 18.7687C8.88945 18.7687 8.5227 18.4023 8.5227 17.95C8.5227 17.4981 8.88906 17.1313 9.34137 17.1313C9.79329 17.1313 10.16 17.4977 10.16 17.95C10.16 18.4023 9.79329 18.7687 9.34137 18.7687Z" />
      <path d="M14.7294 15.9039C13.6012 15.9039 12.6829 16.8221 12.6829 17.9504C12.6829 19.0786 13.6012 19.9969 14.7294 19.9969C15.8576 19.9969 16.7759 19.0786 16.7759 17.9504C16.7759 16.8221 15.858 15.9039 14.7294 15.9039ZM14.7294 18.7687C14.2775 18.7687 13.9107 18.4023 13.9107 17.95C13.9107 17.4981 14.2771 17.1313 14.7294 17.1313C15.1813 17.1313 15.5481 17.4977 15.5481 17.95C15.5481 18.4023 15.1813 18.7687 14.7294 18.7687Z" />
    </SvgIcon>
  );
};
