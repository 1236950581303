import { useRef, useState, useEffect, useContext } from 'react';
import { Freeze } from 'react-freeze';
import { ESPage, ESPageProps } from '@energy-stacks/shared';
import { FleetLocationContext } from './FleetLocationContext';

export const FleetPage: React.FC<ESPageProps> = (props) => {
  const locationState = useContext(FleetLocationContext);

  const pageRef = useRef<HTMLDivElement | null>(null);
  const [placeholder, setPlaceholder] = useState<string | null>(null);

  useEffect(() => {
    if (!locationState || !locationState?.locationWithBackground) {
      return;
    }

    if (locationState.shouldBackgroundFreeze && pageRef.current) {
      setPlaceholder(pageRef.current.innerHTML);
    } else {
      setPlaceholder(null);
    }
  }, [locationState]);

  return (
    <Freeze
      freeze={!!placeholder}
      placeholder={
        placeholder && <div dangerouslySetInnerHTML={{ __html: placeholder }} />
      }
    >
      <div ref={pageRef}>
        <ESPage {...props} />
      </div>
    </Freeze>
  );
};
