import { userRoleNormalizer } from './userRoleNormalizer';
import { ExtendedBrokerUserDto } from '../extendedBrokerUserDto';
import { ExtendedBrokerUserModel } from '../extendedUserModel';

export const extendedBrokerUserModelNormalizer = (
  user: ExtendedBrokerUserDto
): ExtendedBrokerUserModel => ({
  userId: user.userId,
  displayName: user.displayName,
  emailAddress: user.emailAddress,
  isActive: user.isActive,
  userRole: userRoleNormalizer(user.userRole),
  providerId: user.providerId,
});
