import { Typography } from '@mui/material';
import { ConfigurationKeyStatus } from './configurationKeyStatus';
import { ListItemCell, ListItemContainer } from './ListItemContainer';
import { EditConfigurationListItemStatus } from './EditConfigurationListItemStatus';

// Hardcoded list item to be displayed in hard reset confirmation dialog
export const HardResetRequiredListItem: React.FC<{
  configurationKey: string;
  newValue: string;
}> = ({ configurationKey, newValue }) => {
  return (
    <ListItemContainer>
      <ListItemCell>
        <Typography variant="body2">{configurationKey}</Typography>
      </ListItemCell>

      <ListItemCell textAlign="center">
        <Typography variant="body2">{newValue}</Typography>
      </ListItemCell>

      <ListItemCell textAlign="end">
        <EditConfigurationListItemStatus
          status={ConfigurationKeyStatus.RebootRequired}
          onRetry={() => {}}
        />
      </ListItemCell>
    </ListItemContainer>
  );
};
