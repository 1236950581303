import { useNavigate } from 'react-router-dom';
import { useCallback } from 'react';
import { useAppLocation } from '@energy-stacks/shared';

export const useNavigateBack = (fallbackRoute?: string) => {
  const navigate = useNavigate();
  const location = useAppLocation();

  return useCallback(() => {
    setTimeout(() => {
      if ((location.state && location.state.background) || !fallbackRoute) {
        navigate(-1);
      } else {
        navigate(fallbackRoute);
      }
    }, 0);
  }, [location.state, navigate, fallbackRoute]);
};
