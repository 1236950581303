import { FilterFn } from '@tanstack/react-table';

export const arrEqualsSome: FilterFn<any> = (
  row,
  columnId: string,
  filterValue: Array<string>
) => filterValue.some((fv) => fv === row.getValue(columnId));

arrEqualsSome.autoRemove = (val) => {
  return !val || !val?.length;
};
