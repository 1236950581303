import { ChargingStationGroupModel } from '@energy-stacks/csms/feature-charging-station-groups-data';
import {
  ActivityAmenity,
  FoodAndDrinkAmenity,
  ShoppingAmenity,
  TransportationAmenity,
} from './amenityModel';
import { Country } from './country';
import { LocationParkingType } from './locationModel';
import { RegularHoursModel } from './regularHoursModel';
import { Entity } from './addChargingLocationFormData';

export interface LocationDetailsModel {
  name: string;

  fullAddress: string;
  address: string;
  postalCode: string;
  city: string;
  country: Country;
  locationId: string;

  /**
   * One of IANA tzdata’s TZ-values representing the time zone of the location.
   * For example: "Europe/Oslo", "Europe/Zurich".
   * (http://www.iana.org/time-zones)
   */
  timeZone: string;
  isTwentyFourSeven: boolean;
  regularHours: RegularHoursModel[];
  openingHours: OpeningHours;
  workingHoursConfig: WorkingHoursConfig;
  chargeWhenClosed: boolean;

  locationType: LocationParkingType;
  energySupplier: string;
  greenEnergy: boolean;

  // Information about nearby points of interest
  transportationAmenities: TransportationAmenity[];
  foodAndDrinkAmenities: FoodAndDrinkAmenity[];
  activityAmenities: ActivityAmenity[];
  shoppingAmenities: ShoppingAmenity[];
  // chargingStationGroups: string[];
  chargingStationGroups: ChargingStationGroupModel[];
  owner: Entity;
}

export enum OpeningHours {
  workdaysOnly = 'workdaysOnly',
  customDays = 'customDays',
  nonStop = 'nonStop',
}

export enum WorkingHoursConfig {
  sameTime = 'sameTime',
  differentTime = 'differentTime',
}

export type LocationAmenities = Pick<
  LocationDetailsModel,
  | 'transportationAmenities'
  | 'foodAndDrinkAmenities'
  | 'activityAmenities'
  | 'shoppingAmenities'
>;
