import { Grid } from '@mui/material';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { Logo } from './Logo';
import React, { FunctionComponent } from 'react';

export const APP_BAR_HEIGHT = 0;

export const AppBar: FunctionComponent<{
  right: React.ReactNode;
  left: React.ReactNode;
}> = ({ right, left }) => {
  return (
    <MuiAppBar
      position="fixed"
      variant="outlined"
      elevation={0}
      sx={{
        flexGrow: 1,
        backgroundColor: 'background.paper',
        zIndex: (theme) => theme.zIndex.drawer + 1,
      }}
    >
      <Toolbar
        sx={{
          height: `${APP_BAR_HEIGHT}px`,
          '&.MuiToolbar-root': {
            paddingRight: 0,
            paddingLeft: 0,
          },
        }}
      >
        <Grid container alignItems="center" flexGrow={1}>
          {left}
          <Logo />
        </Grid>
        {right}
      </Toolbar>
    </MuiAppBar>
  );
};
