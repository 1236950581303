import { ESPageLoadingIndicator, RefetchOnError } from '@energy-stacks/shared';
import { DateRange } from '@energy-stacks/core/date-range-picker';
import { useTours } from '@energy-stacks/fleet/feature-tours';
import { TourModel } from '@energy-stacks/fleet/feature-tours-data';
import { ToursTable } from './ToursTable';

interface ToursProps {
  onToursSelected: (tours: TourModel['tourId'][]) => void;
  selectedTourIds: TourModel['tourId'][] | undefined;
  defaultDateRange?: DateRange | undefined;
  dateRange?: DateRange | undefined;
  onDateRangeChange?: (dateRange: DateRange | undefined) => void;
}

export const Tours: React.FC<ToursProps> = ({
  onToursSelected,
  dateRange,
  defaultDateRange,
  onDateRangeChange,
  selectedTourIds,
}) => {
  const { data, isLoading, isError, refetch } = useTours();

  return (
    <>
      {isLoading ? <ESPageLoadingIndicator /> : null}
      {isError ? <RefetchOnError onRefetch={refetch} /> : null}
      {data ? (
        <ToursTable
          tableId="charging-schedule-wizard-tours-table"
          tours={data}
          onToursRefetch={refetch}
          enableColumnSelection={false}
          enableRowSelection
          defaultRowSelection={(item) =>
            !!selectedTourIds?.includes(item.tourId)
          }
          onSelectionChange={(toursRowSelection) => {
            onToursSelected(Object.keys(toursRowSelection));
          }}
          defaultDateRange={defaultDateRange}
          dateRange={dateRange}
          onDateRangeChange={onDateRangeChange}
        />
      ) : null}
    </>
  );
};
