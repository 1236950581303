import { SortingOrderModel } from '@energy-stacks/core/ui';
import { environment } from '@energy-stacks/feature-config';
import { createBaseQuery } from '@energy-stacks/shared';
import { createApi } from '@reduxjs/toolkit/query/react';
import { CdrModel } from './cdrModel';
import { cdrTokenNormalizer } from './normalizers/cdrTokenNormalizer';
import { PageCdrDto } from './pageCdrDto';
import { appendZToDateString } from '@energy-stacks/shared';

export interface CdrsSortingModel extends SortingOrderModel {
  column: string;
}

type SortColumn = 'startDateTime';

export const DEFAULT_CDRS_SORT_COLUMN: SortColumn = 'startDateTime';

export interface GetCdrsByEvseIdsBody {
  evseIds: string[];
  startDateTime: string | undefined;
  endDateTime: string | undefined;
  page: number;
  size: number;
  sort: CdrsSortingModel;
}

export const csmsTransactionsApi = createApi({
  reducerPath: 'csmsTransactionsApi',
  tagTypes: ['CdrsByEvseIds'],
  baseQuery: createBaseQuery(`${environment.ocppServiceUrl}/transactions`),
  endpoints: (builder) => ({
    getCdrsByEvseIds: builder.query<CdrModel, GetCdrsByEvseIdsBody>({
      query: (searchParams) => ({
        url: '/cdrs/by-evseIds',
        method: 'GET',
        params: {
          ...searchParams,
          sort: [`${searchParams.sort.column},${searchParams.sort.order}`],
        },
      }),
      providesTags: ['CdrsByEvseIds'],
      transformResponse: (cdrs: PageCdrDto): CdrModel => {
        return {
          totalElements: cdrs.totalElements ?? 0,
          totalPages: cdrs.totalPages ?? -1,
          cdrs:
            cdrs?.content?.map((cdr) => ({
              cdrId: cdr.cdr_id,
              sessionStart: appendZToDateString(cdr.start_date_time),
              sessionEnd: appendZToDateString(cdr.end_date_time),
              duration: cdr.total_time,
              consumption: cdr.total_energy,
              cdrToken: cdrTokenNormalizer(cdr.cdr_token),
              totalCost: cdr.total_cost.incl_vat ?? 0,
            })) ?? [],
        };
      },
    }),
  }),
});

export const { useGetCdrsByEvseIdsQuery } = csmsTransactionsApi;
