import { environment } from '@energy-stacks/feature-config';
import { createBaseQuery } from '@energy-stacks/shared';
import { createApi } from '@reduxjs/toolkit/query/react';
import { toUserRoleDto } from './normalizers/userRoleNormalizer';
import { UpdateUserRequest } from './updateUserRequest';
import { ExtendedBrokerUserDto } from './extendedBrokerUserDto';
import { ExtendedBrokerUserModel } from './extendedUserModel';
import { extendedBrokerUserModelNormalizer } from './normalizers/extendedUserModelNormalizer';
import { EditUserFormData } from './userFormData';
import { BrokerUserAccessRequestDto } from './brokerUserAccessRequestDto';
import { brokerUserAccessRequestNormalizer } from './normalizers/brokerUserAccessRequestNormalizer';
import { BrokerUserAccessRequestModel } from './brokerUserAccessRequestModel';

export const usersBrokerApi = createApi({
  reducerPath: 'usersBrokerApi',
  tagTypes: ['Users', 'UserAccessRequests', 'Avatar'],
  baseQuery: createBaseQuery(`${environment.ocppServiceUrl}/users`),
  refetchOnFocus: true,
  endpoints: (builder) => ({
    getUsers: builder.query<ExtendedBrokerUserModel[], void>({
      query: () => '/',
      keepUnusedDataFor: 0,
      providesTags: ['Users'],
      transformResponse: (users: ExtendedBrokerUserDto[]) => {
        return users.map(extendedBrokerUserModelNormalizer);
      },
    }),
    editUser: builder.mutation<ExtendedBrokerUserModel, EditUserFormData>({
      query: (data) => {
        const requestBody: UpdateUserRequest = {
          userRole: toUserRoleDto(data.userRole),
          isActive: data.isActive,
        };
        return {
          url: `/${data.userId}`,
          method: 'PUT',
          body: requestBody,
        };
      },
      invalidatesTags: ['Users'],
      transformResponse: extendedBrokerUserModelNormalizer,
    }),
    getUserRequests: builder.query<BrokerUserAccessRequestModel[], void>({
      query: () => '/requests',
      providesTags: ['UserAccessRequests'],
      transformResponse: (userRequests: BrokerUserAccessRequestDto[]) =>
        userRequests.map(brokerUserAccessRequestNormalizer),
    }),
    editUserRequest: builder.mutation<
      ExtendedBrokerUserModel,
      EditUserFormData
    >({
      query: (data) => {
        const requestBody: UpdateUserRequest = {
          userRole: toUserRoleDto(data.userRole),
          isActive: data.isActive,
        };
        return {
          url: `/${data.userId}`,
          method: 'PUT',
          body: requestBody,
        };
      },
      transformResponse: extendedBrokerUserModelNormalizer,
    }),
    deleteUser: builder.mutation<void, string>({
      query: (userId) => {
        return {
          url: `/${userId}`,
          method: 'DELETE',
        };
      },
      invalidatesTags: ['Users'],
    }),
    deleteUserRequest: builder.mutation<void, { userId: string }>({
      query: (data) => {
        return {
          url: `/requests/${data.userId}`,
          method: 'DELETE',
        };
      },
    }),
    requestAccess: builder.mutation<void, void>({
      query: () => ({
        url: '/me/access',
        method: 'POST',
      }),
    }),
    refetchUsersAndRequests: builder.mutation<null, void>({
      queryFn: () => ({ data: null }),
      invalidatesTags: ['UserAccessRequests', 'Users'],
    }),
    getUserProfilePicture: builder.query<string, void>({
      query: () => ({
        url: '/me/picture',
        responseHandler: async (response) => {
          const blob = await response.blob();
          return new Promise<string>((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = () => {
              const base64String = reader.result as string;
              resolve(base64String);
            };
            reader.onerror = () => {
              reject(new Error('Failed to read image file'));
            };
            reader.readAsDataURL(blob);
          });
        },
      }),
      providesTags: ['Avatar'],
    }),
    addUserProfilePicture: builder.mutation<void, FormData>({
      query: (body) => ({
        url: '/me/picture',
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['Avatar'],
    }),
    deleteUserProfilePicture: builder.mutation<{ success: boolean }, void>({
      query: () => ({
        url: `/me/picture`,
        method: 'DELETE',
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(
            usersBrokerApi.util.updateQueryData(
              'getUserProfilePicture',
              undefined,
              () => undefined
            )
          );
        } catch {}
      },
    }),
    getUser: builder.query<ExtendedBrokerUserModel, void>({
      query: () => ({
        url: '/me',
      }),
      transformResponse: extendedBrokerUserModelNormalizer,
    }),
    getProfilePicture: builder.query<string, string | undefined>({
      query: (userId) => ({
        url: `/${userId}/picture`,
        responseHandler: async (response) => {
          const blob = await response.blob();
          return new Promise<string>((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = () => {
              const base64String = reader.result as string;
              resolve(base64String);
            };
            reader.onerror = () => {
              reject(new Error('Failed to read image file'));
            };
            reader.readAsDataURL(blob);
          });
        },
      }),
    }),
  }),
});

export const {
  useGetUsersQuery,
  useEditUserMutation,
  useEditUserRequestMutation,
  useDeleteUserRequestMutation,
  useRequestAccessMutation,
  useGetUserRequestsQuery,
  useRefetchUsersAndRequestsMutation,
  useGetUserProfilePictureQuery,
  useAddUserProfilePictureMutation,
  useDeleteUserProfilePictureMutation,
  useGetUserQuery,
  useGetProfilePictureQuery,
  useDeleteUserMutation,
} = usersBrokerApi;
