import { Coords, MapMarkerWrapper } from '@energy-stacks/fleet/shared';

interface TankClusterMarker extends Coords {
  onClick: () => void;
  pointCount: number;
  style?: React.CSSProperties;
}

export const TankClusterMarker: React.FC<TankClusterMarker> = ({
  onClick,
  style,
  pointCount,
}) => {
  return (
    <MapMarkerWrapper style={style} onClick={onClick}>
      <svg
        width="42"
        height="42"
        viewBox="0 0 42 42"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="Ellipse 59" filter="url(#filter0_d_5437_35403)">
          <circle cx="21" cy="19" r="17" fill="white" />
        </g>
        <defs>
          <filter
            id="filter0_d_5437_35403"
            x="0"
            y="0"
            width="42"
            height="42"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="2" />
            <feGaussianBlur stdDeviation="2" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.227451 0 0 0 0 0.262745 0 0 0 0 0.407843 0 0 0 0.45 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_5437_35403"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_5437_35403"
              result="shape"
            />
          </filter>
        </defs>
        <text
          fontSize={14}
          fontFamily="Poppins"
          fontStyle="normal"
          fontWeight={700}
          lengthAdjust="spacingAndGlyphs"
          x="50%"
          y="50%"
          fill="black"
          dominantBaseline="middle"
          textAnchor="middle"
        >
          {pointCount}
        </text>
      </svg>
    </MapMarkerWrapper>
  );
};
