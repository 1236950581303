import {
  ESFullScreenLoadingIndicator,
  formatDate,
  useDocumentTitle,
} from '@energy-stacks/shared';
import { useTranslation } from 'react-i18next';
import { Stack, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';
import React, { useCallback, useState } from 'react';
import { useESSnackbar } from '@energy-stacks/core/ui';
import {
  OptimizedTourJob,
  OptimizedTourModel,
  SkippedJob,
  useEditTourDetailsMutation,
} from '@energy-stacks/fleet/feature-tours-data';
import { editTourApiErrors } from './editTourApiErrors';
import { TourDetailsEditMode } from './TourDetailsEditMode';
import { EditTourDetailsContextProvider } from './EditTourDetailsContext';
import { TourDndTableHeader } from '../tour-details/TourDndTableHeader';
import { VehicleChip } from '../VehicleChip';
import { IconCalendarDue } from '@tabler/icons-react';
import { TourDetailsEditModeHeader } from './TourDetailsEditModeHeader';
import { TourInfoHeaderItemLayout } from '../shared/TourInfoHeaderItemLayout';
import { TourDetails } from '../create-tour-steps/ReviewStep';
import { SkippedJobsAlert } from '../create-tour-steps/SkippedJobsAlert';
import { useInvalidateJobPool } from '@energy-stacks/fleet/feature-jobs';
import { mapTourJobsToTourDetail } from '../tour-details/mapTourJobsToTourDetail';
import { forecastJobs } from '../tour-details/forecastJobs';

type EditTourDetailsPageProps = {
  tourDetails: OptimizedTourModel;
  onCancelEditing: () => void;
};

export const EditTourDetailsPage: React.FC<EditTourDetailsPageProps> = ({
  tourDetails,
  onCancelEditing,
}) => {
  const { tourId } = useParams<{ tourId: string }>();
  const [t] = useTranslation('tours');
  useDocumentTitle(t('pageTitle'));
  const [tourDetailsState, setTourDetailsState] = useState<TourDetails>({
    index: 0,
    tour: tourDetails,
    initialTour: tourDetails,
  });
  const { showSnackbar } = useESSnackbar();
  const [editTourDetails, { isLoading: isEditingTour }] =
    useEditTourDetailsMutation();
  const [skippedJobs, setSkippedJobs] = useState<SkippedJob[]>([]);
  const invalidateJobPool = useInvalidateJobPool();

  const handleRecalculateTour = useCallback(() => {
    if (!tourDetailsState.tour || !tourId) {
      return;
    }

    editTourDetails({
      body: {
        jobs: tourDetailsState.tour.tourJobs.jobs.map((job) => ({
          jobUID: job.jobId,
          jobPrecedence: job.visitOrder,
        })),
      },
      tourId,
      tourNote: tourDetailsState.tour.note,
    })
      .unwrap()
      .then((response) => {
        if (response.skippedJobs.length) {
          invalidateJobPool();
        }
        setTourDetailsState({
          index: 0,
          tour: response.optimizedTour,
          initialTour: response.optimizedTour,
        });
        setSkippedJobs(response.skippedJobs);
        showSnackbar('success', 'editTourSuccess', 'tours');
      })
      .catch((error) => {
        const errorCode = error.data?.errorCode;
        showSnackbar(
          'error',
          errorCode && editTourApiErrors[errorCode]
            ? `editTourDetailsApiErrors.${editTourApiErrors[errorCode]}`
            : undefined,
          'tours'
        );
      });
  }, [
    editTourDetails,
    invalidateJobPool,
    showSnackbar,
    tourDetailsState.tour,
    tourId,
  ]);

  return (
    <EditTourDetailsContextProvider
      config={{
        isEditTourModeActive: true,
        tourDetails: tourDetailsState,
        jobIds: tourDetails
          ? tourDetails.tourJobs.jobs.map((tourJob) => tourJob.jobId)
          : [],
        onCancelEditing,
        onTourDetailsChange: (value) => {
          if (!value) {
            // Should never be undefined
            return;
          }
          setTourDetailsState(value);
        },
      }}
    >
      <Stack sx={{ minHeight: '100%' }} gap={4}>
        <SkippedJobsAlert
          skippedJobs={skippedJobs ?? []}
          totalJobsCount={
            skippedJobs
              ? tourDetails.tourJobs.jobs.length + skippedJobs.length
              : 0
          }
          onDismiss={() => setSkippedJobs([])}
        />
        <Stack direction="column" gap={6}>
          <TourDetailsEditModeHeader
            onRecalculateTour={handleRecalculateTour}
            shouldDisableSave={
              !tourDetailsState ||
              !tourDetailsState.tour.tourJobs.jobs.length ||
              tourDetailsState.tour.tourJobs.jobs.some(
                (job) => job.status === 'CANCELLED' || job.status === 'FAILED'
              )
            }
          />
        </Stack>
        <TourDetailsEditMode
          tourJobs={forecastTourJobs(
            tourDetailsState.tour.tourJobs,
            tourDetails.plantId
          )}
          TourJobsTableHeader={
            <TourDndTableHeader>
              <VehicleChip title={tourDetails.vehicleName} index={0} />
              <TourInfoHeaderItemLayout Icon={IconCalendarDue}>
                <Typography variant="body1" color="grey.500">
                  {formatDate(tourDetails.startDate)}
                </Typography>
              </TourInfoHeaderItemLayout>
            </TourDndTableHeader>
          }
        />
      </Stack>
      {isEditingTour ? (
        <ESFullScreenLoadingIndicator text={t('editTourDetailsLoadingText')} />
      ) : null}
    </EditTourDetailsContextProvider>
  );
};

// Needed in order to have forecasted times when editing tour based on which we should display time window warnings or not if the tour is ENROUTE
function forecastTourJobs(
  tourJobs: OptimizedTourModel['tourJobs'],
  plantId: OptimizedTourModel['plantId']
): OptimizedTourJob[] {
  return forecastJobs(
    mapTourJobsToTourDetail({
      jobs: tourJobs.jobs,
      processes: tourJobs.processes,
    })
  )
    .filter((job) => job.jobType === 'INBOUND' || job.jobType === 'OUTBOUND')
    .map((job, index) => ({
      jobId: job.jobId,
      plantId: plantId || '',
      supplier: job.supplier,
      jobType: job.jobType as 'INBOUND' | 'OUTBOUND',
      startTime: job.startTime,
      endTime: job.endTime,
      confirmedStartTime: job.confirmedStartTime,
      confirmedEndTime: job.confirmedEndTime,
      visitOrder: index,
      duration: job.duration,
      product: job.product,
      destination: job.destination,
      origin: job.origin,
      contaminationViolation: job.contaminationViolation || null,
      status: job.status,
      datePickUpTill: job.datePickUpTill,
      dateDropOffTill: job.dateDropOffTill,
      datePickUpFrom: job.datePickUpFrom,
      dateDropOffFrom: job.dateDropOffFrom,
      forecastedStartTime: job.forecastedStartTime,
      forecastedEndTime: job.forecastedEndTime,
    }));
}
