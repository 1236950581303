import {
  TransactionModel,
  useGetTransactionsByIdentityKeyQuery,
} from '@energy-stacks/broker/feature-transactions-data';
import {
  useChargingStationIdentityKey,
  toPayloadDateBroker,
} from '@energy-stacks/shared';
import { useMemo } from 'react';

interface GetTransactionsConfig {
  filterDate: {
    timeFrom?: Date;
    timeTo?: Date;
  };
}

export const useTransactions = (config?: GetTransactionsConfig) => {
  const identityKey = useChargingStationIdentityKey();

  const { data, isLoading, error, isSuccess, refetch, isError } =
    useGetTransactionsByIdentityKeyQuery(
      {
        identityKey,
        timeFrom: config?.filterDate.timeFrom
          ? toPayloadDateBroker(config?.filterDate.timeFrom)
          : undefined,
        timeTo: config?.filterDate.timeTo
          ? toPayloadDateBroker(config?.filterDate.timeTo)
          : undefined,
      },
      { skip: !identityKey, refetchOnMountOrArgChange: true }
    );

  // Manually map transactions from dictionary to array since we are depending on dateRange filter
  // This is what is actually done inside selectAll selector from createEntityAdapter
  const transactions = useMemo(
    () => data?.ids?.map((id) => data.entities[id] as TransactionModel),
    [data]
  );

  return {
    transactions,
    isLoading,
    error,
    isSuccess,
    refetch,
    isError,
  };
};
