import React from 'react';
import { FleetPage } from '@energy-stacks/fleet/shared';
import { ToursTable } from './ToursTable';
import { useTranslation } from 'react-i18next';
import {
  ESPageLoadingIndicator,
  RefetchOnError,
  useDocumentTitle,
} from '@energy-stacks/shared';
import { useTours } from './useTours';

export const ToursPage = () => {
  const [t] = useTranslation('tours');
  const pageTitle = t('pageTitle');
  useDocumentTitle(pageTitle);
  const { data, isLoading, isError, refetch } = useTours();

  return (
    <FleetPage title={t('tours')}>
      {isLoading ? <ESPageLoadingIndicator /> : null}
      {isError ? <RefetchOnError onRefetch={refetch} /> : null}
      {data ? (
        <>
          <ToursTable
            tours={data}
            enableColumnSelection
            persistPagination={true}
          />
        </>
      ) : null}
    </FleetPage>
  );
};
