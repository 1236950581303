import { useGetPlantsQuery } from '@energy-stacks/fleet/feature-business-accounts-data';
import {
  useAppSelector,
  useAppDispatch,
  setSelectedPlantId,
  setPlantIds,
} from '@energy-stacks/fleet/store';
import { useEffect } from 'react';

export const useSelectPlantIds = () => {
  const dispatch = useAppDispatch();
  const loginState = useAppSelector((state) => state.user.loginState);
  const selectedPlantId = useAppSelector(
    (state) => state.plant.selectedPlantId
  );
  const {
    data: plants,
    isLoading,
    isFetching,
    isError,
  } = useGetPlantsQuery(undefined, {
    skip: loginState !== 'loggedIn',
  });

  useEffect(() => {
    if (isError) {
      dispatch(setSelectedPlantId(null));
      dispatch(setPlantIds(null));
    }
  }, [dispatch, isError]);

  useEffect(() => {
    if (!plants) return;

    const plantIds = plants.map((plant) => plant.id);

    if (plantIds.length > 0) {
      dispatch(setPlantIds(plantIds));
      // If there is no selectedPlantId persisted in the store, select the first plantId
      if (!selectedPlantId) {
        dispatch(setSelectedPlantId(plantIds[0]));
        return;
      }

      // If there is selectedPlantId but it's not in the list of plantIds, select the first plantId
      if (!plantIds.includes(selectedPlantId)) {
        dispatch(setSelectedPlantId(plantIds[0]));
      }
    }
  }, [dispatch, plants, selectedPlantId]);

  return { isLoadingPlantIds: isLoading && !isFetching };
};
