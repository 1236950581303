import { appendZToDateString } from '@energy-stacks/shared';
import { VehicleDto } from '../vehicleDto';
import { VehicleModel } from '../vehicleModel';

export const vehiclesNormalizer = (
  vehiclesDto: VehicleDto[]
): VehicleModel[] => {
  return vehiclesDto.map(vehicleNormalizer);
};

const vehicleNormalizer = (vehicle: VehicleDto): VehicleModel => ({
  plantId: vehicle.depotId,
  vehicleId: vehicle.vehicleIdentificationNumber,
  brandId: vehicle.brandUuid,
  brandAndModel: `${vehicle.brandName} ${vehicle.model}`,
  name: vehicle.name,
  licencePlate: vehicle.licensePlateNumber,
  weight: vehicle.maxLoadingWeight,
  volume: vehicle.maxLoadingVolume,
  status: vehicle.status,
  isPartiallyPlanned: vehicle.partiallyPlanned,
  startTour: appendZToDateString(vehicle.startTour),
  endTour: appendZToDateString(vehicle.endTour),
});
