import { useParams } from 'react-router-dom';

export const useChargingStationIdentityKey = () => {
  const { identityKey } = useParams<{
    identityKey: string;
    chargingStationName: string;
  }>();

  return identityKey ?? '';
};
