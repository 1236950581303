import { useGetChargingStationGroupsByLocationIdQuery } from '@energy-stacks/csms/feature-charging-station-groups-data';

type UseChargingStationGroupsByLocationId = {
  pollingInterval?: number;
  refetchOnReconnect?: boolean;
  refetchOnFocus?: boolean;
  refetchOnMountOrArgChange?: boolean | number;
};

export const useChargingStationGroupsByLocationId = (
  locationId: string,
  canFetchGroups: boolean,
  options?: UseChargingStationGroupsByLocationId
) => {
  const { data: chargingStationGroups, isFetching } =
    useGetChargingStationGroupsByLocationIdQuery(locationId, {
      skip: !locationId || !canFetchGroups,
      ...options,
    });

  return { chargingStationGroups, isFetching };
};
