import { Dialog } from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
  ESDialogTitle,
  ESDialogContent,
  ESDialogActions,
  ESButton,
} from '@energy-stacks/core/ui';
import { CommonDialogProps } from '../../hooks/useDialog';

type HardResetDialogProps = CommonDialogProps & { loading?: boolean };

export const HardResetDialog: React.FC<HardResetDialogProps> = ({
  isOpen,
  loading,
  onClose,
  onCallToAction,
}) => {
  const { t } = useTranslation('chargingStations');

  return (
    <Dialog open={isOpen} onClose={onClose} fullWidth maxWidth="sm">
      <ESDialogTitle data-testid="hardResetTitle">
        {t('proceedWithHardResetOfChargingStation')}
      </ESDialogTitle>
      <ESDialogContent data-testid="hardResetContent">
        {t('hardResetConfirmationDialogDescription')}
      </ESDialogContent>
      <ESDialogActions>
        <ESButton
          data-testid="hardResetCancelButton"
          variant="text"
          color="error"
          onClick={onClose}
        >
          {t('cancel')}
        </ESButton>
        <ESButton
          data-testid="hardResetResetButton"
          variant="contained"
          color="error"
          onClick={onCallToAction}
          loading={loading}
          disabled={loading}
        >
          {t('hardReset')}
        </ESButton>
      </ESDialogActions>
    </Dialog>
  );
};
