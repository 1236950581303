import { ESButton, ESPopover, ESStep, ESStepper } from '@energy-stacks/core/ui';
import { Box, IconButton, Zoom } from '@mui/material';
import { IconArrowLeft } from '@tabler/icons-react';

interface StepperProps {
  handleNextStep: () => void;
  handlePreviousStep: () => void;
  currentStep: number;
  canGoToPrevStep: boolean;
  canGoToNextStep: boolean;
  disableNextButton?: boolean;
  nextButtonText: string;
  steps: ESStep[];
  buttonVariant?: 'contained' | 'outlined';
  infoMessage?: string;
  disablePreviousButton?: boolean;
}

export const Stepper: React.FC<StepperProps> = ({
  handleNextStep,
  handlePreviousStep,
  currentStep,
  canGoToPrevStep,
  canGoToNextStep,
  disableNextButton,
  nextButtonText,
  steps,
  buttonVariant,
  infoMessage,
  disablePreviousButton = false,
}) => {
  return (
    <Box
      display="flex"
      flexDirection="row"
      alignItems="center"
      sx={{
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'row',
        px: 6,
        py: 1,
      }}
    >
      <Zoom in={canGoToPrevStep}>
        <IconButton
          onClick={handlePreviousStep}
          disabled={disablePreviousButton}
          sx={{
            color: 'grey.50',
            '&.Mui-disabled': {
              color: 'grey.600',
            },
          }}
        >
          <IconArrowLeft stroke={1} />
        </IconButton>
      </Zoom>
      <ESStepper steps={steps} activeStep={currentStep} />
      <ESPopover content={infoMessage}>
        <ESButton
          variant={
            buttonVariant ?? (canGoToNextStep ? 'outlined' : 'contained')
          }
          sx={{
            color: 'primary.light',
            height: 44,
            '&.MuiButton-outlined:hover': {
              backgroundColor: 'rgba(255, 255, 255, 0.1)',
              borderColor: 'primary.light',
            },
            '&.MuiLoadingButton-root:disabled': {
              opacity: 0.5,
              color: 'primary.light',
            },
          }}
          onClick={handleNextStep}
          disabled={disableNextButton}
        >
          {nextButtonText}
        </ESButton>
      </ESPopover>
    </Box>
  );
};
