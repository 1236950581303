import ArrowDropDownRoundedIcon from '@mui/icons-material/ArrowDropDownRounded';
import ArrowDropUpRoundedIcon from '@mui/icons-material/ArrowDropUpRounded';
import { IconButton } from '@mui/material';

interface ExpandButtonProps {
  expanded: boolean;
  onToggleExpand: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

export const ExpandButton: React.FC<ExpandButtonProps> = ({
  expanded,
  onToggleExpand,
}) => {
  return (
    <IconButton
      onClick={(e) => {
        e.stopPropagation();
        onToggleExpand(e);
      }}
      size="small"
    >
      {expanded ? <ArrowDropUpRoundedIcon /> : <ArrowDropDownRoundedIcon />}
    </IconButton>
  );
};
