import { Box, Switch, Typography } from '@mui/material';
import { IconReceipt2, IconSun } from '@tabler/icons-react';
import { useTranslation } from 'react-i18next';
import { SectionTitle } from './SectionTitle';
import { FC } from 'react';

type OptimizeChargingSectionProps = {
  isPriceOptimized: boolean;
  isSolarOptimized: boolean;
};
export const OptimizeChargingSection: FC<OptimizeChargingSectionProps> = ({
  isPriceOptimized,
  isSolarOptimized,
}) => {
  const [t] = useTranslation('chargingSchedule');

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          mr: 5,
        }}
      >
        <SectionTitle
          title={t('chargingOptionLabels.price')}
          Icon={IconReceipt2}
        />
        <Box sx={{ alignItems: 'center', display: 'flex', my: 4 }}>
          <Switch
            checked={isPriceOptimized}
            disabled
            sx={{
              ml: -2,
              '.MuiSwitch-track': {
                opacity: 0.7,
              },
            }}
          />
          <Typography variant="h5">
            {t(`toggleSwitchLabels.${isPriceOptimized ? 'on' : 'off'}`)}
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <SectionTitle title={t('chargingOptionLabels.solar')} Icon={IconSun} />
        <Box sx={{ alignItems: 'center', display: 'flex', my: 4 }}>
          <Switch
            checked={isSolarOptimized}
            disabled
            sx={{
              ml: -2,
              '.MuiSwitch-track': {
                opacity: 0.7,
              },
            }}
          />
          <Typography variant="h5">
            {t(`toggleSwitchLabels.${isSolarOptimized ? 'on' : 'off'}`)}
          </Typography>
        </Box>
      </Box>
    </>
  );
};
