import { useEffect, useState } from 'react';
import Drawer from '@mui/material/Drawer';
import { useAppSelector } from '@energy-stacks/store';
import { CloseDrawerButton } from './CloseDrawerButton';
import {
  SIDEBAR_OPEN_WIDTH,
  SIDEBAR_CLOSED_WIDTH,
} from '@energy-stacks/core/sidebar';

interface ESScreenDrawerProps {
  children: React.ReactNode;
  fullScreen?: boolean;
  width?: number;
  closeButtonTopPosition?: number;
  onClose: () => void;
}

export const ESScreenDrawer: React.FC<ESScreenDrawerProps> = ({
  children,
  fullScreen,
  width,
  closeButtonTopPosition,
  onClose,
}) => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const sidebarOpened = useAppSelector((state) => state.sidebar.sidebarOpened);
  const fullScreenWidth = `calc(100% - ${
    sidebarOpened ? `${SIDEBAR_OPEN_WIDTH}px` : `${SIDEBAR_CLOSED_WIDTH}px`
  })`;

  useEffect(() => setDrawerOpen(true), []);

  return (
    <Drawer
      anchor="right"
      variant="temporary"
      PaperProps={{
        sx: {
          maxWidth: fullScreenWidth,
          width: fullScreen ? fullScreenWidth : width,
          overflowY: 'unset', // Needed to override default property in order to position back button outside of PaperComponent
        },
      }}
      SlideProps={{
        onExited: () => onClose(), // Call onCLose only after exiting transition is done
      }}
      ModalProps={{
        // Removes backdrop from sidebar
        sx: {
          left: sidebarOpened
            ? `${SIDEBAR_OPEN_WIDTH}px`
            : `${SIDEBAR_CLOSED_WIDTH}px`,
        },
        BackdropProps: {
          sx: {
            position: 'absolute',
          },
          onClick: () => setDrawerOpen(false),
        },
      }}
      open={drawerOpen}
    >
      <CloseDrawerButton
        top={closeButtonTopPosition}
        onClick={() => setDrawerOpen(false)}
      />
      {children}
    </Drawer>
  );
};
