import { useGetVehiclesQuery } from '@energy-stacks/fleet/feature-vehicles-data';
import { toPayloadDate } from '@energy-stacks/shared';
import { useAppSelector } from '@energy-stacks/fleet/store';

export interface GetVehiclesConfig {
  dateFrom: Date | undefined;
  dateTo: Date | undefined;
}

export const useVehicles = (config?: GetVehiclesConfig) => {
  const selectedPlantId = useAppSelector(
    (state) => state.plant.selectedPlantId
  );
  const { data, ...rest } = useGetVehiclesQuery({
    dateFrom: config?.dateFrom ? toPayloadDate(config?.dateFrom) : undefined,
    dateTo: config?.dateTo ? toPayloadDate(config?.dateTo) : undefined,
  });

  return {
    data: data && data.filter((vehicle) => vehicle.plantId === selectedPlantId),
    ...rest,
  };
};
