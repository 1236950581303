import { environment } from '@energy-stacks/feature-config';
import { createBaseQuery } from '@energy-stacks/shared';
import { createApi } from '@reduxjs/toolkit/query/react';
import { FleetUserModel } from './fleetUserModel';
import { fleetUserModelNormalizer } from './normalizers/fleetUserModelNormalizer';

export const usersFleetApi = createApi({
  reducerPath: 'usersFleetApi',
  baseQuery: createBaseQuery(`${environment.ocppServiceUrl}/users`),
  endpoints: (builder) => ({
    getUser: builder.query<FleetUserModel, void>({
      query: () => ({
        url: '/me',
      }),
      transformResponse: fleetUserModelNormalizer,
    }),
  }),
});

export const { useGetUserQuery } = usersFleetApi;
