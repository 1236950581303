import {
  OcppAction,
  OcppActionEnum,
} from '@energy-stacks/broker/feature-ocpp-message-logs-data';
import {
  SearchTextField,
  stringEnumToArray,
  TableFilterButton,
  FilterPopupClearButton,
} from '@energy-stacks/shared';
import {
  Box,
  Checkbox,
  Divider,
  ListItemText,
  MenuItem,
  Typography,
} from '@mui/material';
import { xor } from 'lodash-es';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface OcppMessageLogsOcppActionFilterProps {
  ocppActionFilter: OcppAction[] | undefined;
  onOcppActionFilterChange: (
    ocppActionFilter: OcppAction[] | undefined
  ) => void;
}
type OcppActionOption = { label: string; value: OcppActionEnum };

export const OcppMessageLogsOcppActionFilter: React.FC<
  OcppMessageLogsOcppActionFilterProps
> = ({ ocppActionFilter, onOcppActionFilterChange }) => {
  const { t } = useTranslation('chargingStations');
  const [inputValue, setInputValue] = useState<string>('');

  const options: OcppActionOption[] = stringEnumToArray(OcppActionEnum)
    .sort()
    .map((option) => {
      return { label: t(option), value: option as OcppActionEnum };
    });

  const searchedOptions = options.filter((option) =>
    option.label.toLowerCase().includes(inputValue.toLowerCase())
  );

  const [checkedFilters, setCheckedFilters] = useState<OcppActionEnum[]>([]);

  // Handling filters externally e.g Clear all filters
  useEffect(() => {
    if (!ocppActionFilter) {
      setCheckedFilters([]);
    }
  }, [ocppActionFilter]);

  useEffect(() => {
    if (checkedFilters.length > 0) {
      onOcppActionFilterChange(checkedFilters);
    } else {
      onOcppActionFilterChange(undefined);
    }
  }, [checkedFilters, onOcppActionFilterChange]);

  return (
    <TableFilterButton
      testId="OcppAction"
      appliedFiltersCount={checkedFilters.length}
      title={t('ocppAction')}
      renderPopover={(closePopover) => {
        return (
          <>
            <Box
              p={3}
              sx={{
                position: 'sticky',
                top: 0,
                backgroundColor: ({ palette }) => palette.background.paper,
                zIndex: 2,
                '& .MuiInput-root': {
                  paddingY: 1,
                },
              }}
            >
              <SearchTextField
                value={inputValue}
                autoFocus
                onChange={setInputValue}
              />
            </Box>

            <Box
              sx={{
                maxHeight: 450,
                minWidth: 176,
              }}
            >
              {searchedOptions.length < 1 ? (
                <Box p={3}>
                  <ListItemText primary={t('noSearchOptions')} />
                </Box>
              ) : (
                <Box>
                  {searchedOptions.map((ocppAction) => {
                    return (
                      <MenuItem
                        data-testid={`actionFilterOption${ocppAction}`}
                        key={ocppAction.value}
                        value={ocppAction.value}
                        onClick={() => {
                          setCheckedFilters(
                            xor(checkedFilters, [
                              ocppAction.value as OcppActionEnum,
                            ])
                          );
                        }}
                      >
                        <Checkbox
                          sx={{ p: 0, mr: 2.5 }}
                          checked={checkedFilters.includes(
                            ocppAction.value as OcppActionEnum
                          )}
                        />
                        <Typography>{ocppAction.label}</Typography>
                      </MenuItem>
                    );
                  })}
                </Box>
              )}

              <Box
                sx={{
                  position: 'sticky',
                  bottom: 0,
                  backgroundColor: ({ palette }) => palette.background.paper,
                  zIndex: 2,
                  '& .MuiMenuItem-root': {
                    paddingY: 3,
                  },
                }}
              >
                <Divider />
                <FilterPopupClearButton
                  onClick={() => {
                    setCheckedFilters([]);
                    closePopover();
                  }}
                  disabled={!ocppActionFilter}
                />
              </Box>
            </Box>
          </>
        );
      }}
      isActive={Boolean(ocppActionFilter)}
    />
  );
};
