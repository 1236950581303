import { BrokerRoutes } from '@energy-stacks/broker/shared';
import { ESAddItemButton } from '@energy-stacks/core/ui';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

interface BrokerAddChargingStationButtonProps {
  testId?: string;
}

export const BrokerAddChargingStationButton: React.FC<
  BrokerAddChargingStationButtonProps
> = ({ testId }) => {
  const { t } = useTranslation('chargingStations');
  const navigate = useNavigate();

  return (
    <ESAddItemButton
      data-testid={`${testId}AddButton`}
      onClick={() =>
        navigate(
          `${BrokerRoutes.ChargingStations}/${BrokerRoutes.AddChargingStation}`
        )
      }
      title={t('addChargingStationCallToAction')}
    />
  );
};
