export interface OcppMessageLogsModel {
  messages: OcppMessage[];
  totalElements: number;
  totalPages: number;
}

export interface OcppMessage {
  timestamp: string;
  ocppAction: OcppAction;
  messageDirection: MessageDirectionModel;
  messageUuid: string;
  message: string;
  messageType: MessageTypeModel;
}

export type OcppAction =
  | 'authorize'
  | 'bootNotification'
  | 'cancelReservation'
  | 'changeAvailability'
  | 'changeConfiguration'
  | 'clearCache'
  | 'clearChargingProfile'
  | 'dataTransfer'
  | 'diagnosticsStatusNotification'
  | 'firmwareStatusNotification'
  | 'getConfiguration'
  | 'getDiagnostics'
  | 'getLocalListVersion'
  | 'heartbeat'
  | 'meterValues'
  | 'remoteStartTransaction'
  | 'remoteStopTransaction'
  | 'reserveNow'
  | 'reset'
  | 'sendLocalList'
  | 'setChargingProfile'
  | 'startTransaction'
  | 'stopTransaction'
  | 'statusNotification'
  | 'unlockConnector'
  | 'updateFirmware'
  | 'triggerMessage'
  | 'getCompositeSchedule'
  | 'get15118EVCertificate'
  | 'getCertificateStatus'
  | 'getBaseReport'
  | 'getLog'
  | 'logStatusNotification'
  | 'notifyChargingLimit'
  | 'notifyCustomerInformation'
  | 'notifyDisplayMessages'
  | 'notifyEVChargingNeeds'
  | 'notifyEVChargingSchedule'
  | 'notifyEvent'
  | 'notifyMonitoringReport'
  | 'notifyReport'
  | 'reportChargingProfiles'
  | 'requestStartTransaction'
  | 'requestStopTransaction'
  | 'reservationStatusUpdate'
  | 'securityEventNotification'
  | 'setVariables'
  | 'signCertificate'
  | 'transactionEvent';

export enum OcppActionEnum {
  bootNotification = 'bootNotification',
  cancelReservation = 'cancelReservation',
  changeAvailability = 'changeAvailability',
  changeConfiguration = 'changeConfiguration',
  authorize = 'authorize',
  clearCache = 'clearCache',
  clearChargingProfile = 'clearChargingProfile',
  dataTransfer = 'dataTransfer',
  diagnosticsStatusNotification = 'diagnosticsStatusNotification',
  firmwareStatusNotification = 'firmwareStatusNotification',
  getConfiguration = 'getConfiguration',
  getDiagnostics = 'getDiagnostics',
  getLocalListVersion = 'getLocalListVersion',
  heartbeat = 'heartbeat',
  meterValues = 'meterValues',
  remoteStartTransaction = 'remoteStartTransaction',
  remoteStopTransaction = 'remoteStopTransaction',
  reserveNow = 'reserveNow',
  reset = 'reset',
  sendLocalList = 'sendLocalList',
  setChargingProfile = 'setChargingProfile',
  startTransaction = 'startTransaction',
  stopTransaction = 'stopTransaction',
  statusNotification = 'statusNotification',
  unlockConnector = 'unlockConnector',
  updateFirmware = 'updateFirmware',
  triggerMessage = 'triggerMessage',
  getCompositeSchedule = 'getCompositeSchedule',
  get15118EVCertificate = 'get15118EVCertificate',
  getCertificateStatus = 'getCertificateStatus',
  getBaseReport = 'getBaseReport',
  getLog = 'getLog',
  logStatusNotification = 'logStatusNotification',
  notifyChargingLimit = 'notifyChargingLimit',
  notifyCustomerInformation = 'notifyCustomerInformation',
  notifyDisplayMessages = 'notifyDisplayMessages',
  notifyEVChargingNeeds = 'notifyEVChargingNeeds',
  notifyEVChargingSchedule = 'notifyEVChargingSchedule',
  notifyEvent = 'notifyEvent',
  notifyMonitoringReport = 'notifyMonitoringReport',
  notifyReport = 'notifyReport',
  reportChargingProfiles = 'reportChargingProfiles',
  requestStartTransaction = 'requestStartTransaction',
  requestStopTransaction = 'requestStopTransaction',
  reservationStatusUpdate = 'reservationStatusUpdate',
  securityEventNotification = 'securityEventNotification',
  setVariables = 'setVariables',
  signCertificate = 'signCertificate',
  transactionEvent = 'transactionEvent',
}

export type MessageDirectionModel =
  | 'chargingStationToServer'
  | 'serverToChargingStation'
  | 'brokerToChargingStation'
  | 'chargingStationToBroker'
  | 'chargingStationToTranslator'
  | 'translatorToChargingStation'
  | 'ServerToTranslator'
  | 'translatorToServer';

export type MessageTypeModel = 'call' | 'callResult' | 'callError';
