import { Dialog, DialogProps } from '@mui/material';
import { FormEventHandler } from 'react';

interface ESFormDialogProps
  extends Pick<DialogProps, 'fullWidth' | 'open' | 'onClose'> {
  children: React.ReactNode;
  onSubmit: FormEventHandler<HTMLFormElement>;
  testId?: string;
}

export const ESFormDialog: React.FC<ESFormDialogProps> = ({
  open,
  fullWidth,
  onClose,
  onSubmit,
  children,
  testId,
}) => {
  return (
    <Dialog open={open} onClose={onClose} fullWidth={fullWidth}>
      <form
        onSubmit={onSubmit}
        autoComplete="off"
        style={{
          overflowY: 'auto',
          display: 'flex',
          flexDirection: 'column',
        }}
        data-testid={testId}
      >
        {children}
      </form>
    </Dialog>
  );
};
