import { appendZToDateString } from '@energy-stacks/shared';
import { OptimizedTourModel } from '../optimizedTourModel';
import { emptyRunsMetricsNormalizer } from './emptyRunsNormalizer';
import {
  optimizedTourJobsNormalizer,
  TourDetailsDto,
} from './optimizedTourJobsNormalizer';
import { transitionsNormalizer } from './transitionsNormalizer';

export const tourDetailsNormalizer = (
  tourDetailsDto: TourDetailsDto
): OptimizedTourModel => {
  const tourJobs = optimizedTourJobsNormalizer({
    contaminationRuleViolations: tourDetailsDto.contaminationRuleViolations,
    jobs: tourDetailsDto.jobs,
    transitionMetrics: tourDetailsDto.transitionMetrics,
    vehicleBreak: tourDetailsDto.vehicleBreak,
  });

  return {
    id: tourDetailsDto.tourUid,
    vehicleName: tourDetailsDto.vehicle.name,
    vehicleId: tourDetailsDto.vehicle.vehicleIdentificationNumber,
    date: appendZToDateString(tourDetailsDto.startDate),
    startDate: appendZToDateString(tourDetailsDto.startDate),
    endDate: appendZToDateString(tourDetailsDto.endDate),
    duration: tourDetailsDto.totalTime,
    distance: tourDetailsDto.totalDistance,
    tourJobs: {
      jobs: tourJobs.jobs,
      processes: [...tourJobs.processes],
    },
    totalJobs: tourJobs.jobs.length,
    polyline: {
      points: tourDetailsDto.polyline,
    },
    status: tourDetailsDto.status,
    emptyRunsMetrics: emptyRunsMetricsNormalizer(
      tourDetailsDto.transitionMetrics
    ),
    transitions: tourDetailsDto.transitionMetrics
      ? transitionsNormalizer(tourDetailsDto.transitionMetrics.transitions)
      : [],
    note: tourDetailsDto.notes || '',
  };
};
