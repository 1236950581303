import { ESMap } from '@energy-stacks/core/ui';
import { Box, IconButton, Typography, useTheme } from '@mui/material';
import { MarkerF } from '@react-google-maps/api';
import { FC, useEffect, useState } from 'react';
import locationPOIMarker from '../icons/locationPOIMarker.svg';
import locationFaultedPOIMarker from '../icons/locationFaultedPOIMarker.svg';
import Geocode from 'react-geocode';
import { useTranslation } from 'react-i18next';
import { IconMapPin, IconPencil } from '@tabler/icons-react';
import { BrokerLocationModel } from '@energy-stacks/broker/feature-locations-data';
import { coordsToNumbers } from '@energy-stacks/shared';
import { useGetUiBrandingQuery } from '@energy-stacks/broker/feature-settings-data';

type LocationDetailsMapProps = {
  location: BrokerLocationModel;
  onEditClick: () => void;
};

export const LocationDetailsMap: FC<LocationDetailsMapProps> = ({
  location,
  onEditClick,
}) => {
  const { palette, shadows, spacing } = useTheme();
  const [t] = useTranslation('locations');
  const [address, setAddress] = useState('');
  const mapCenter = coordsToNumbers(location.coordinates);
  const { data: uiBranding } = useGetUiBrandingQuery();
  const hasBorderRadius =
    uiBranding?.hasBorderRadius || uiBranding?.hasBorderRadius === undefined;

  useEffect(() => {
    const getAddressFromcoordinates = async () => {
      const latitudeStr = location.coordinates.latitude;
      const longitudeStr = location.coordinates.longitude;
      const addressResult = await Geocode.fromLatLng(latitudeStr, longitudeStr);
      return addressResult;
    };
    getAddressFromcoordinates()
      .then((res) => setAddress(res.results[0].formatted_address))
      .catch(() => setAddress(t('locationDetailsSearchAddressError')));

    return () => setAddress('');
  }, [location, t]);

  return (
    <Box
      sx={{
        flex: 1,
        mb: 6,
        minHeight: 350,
      }}
    >
      <ESMap
        hasBorderRadius={hasBorderRadius}
        center={mapCenter}
        zoom={14}
        options={{
          mapTypeControl: true,
          mapTypeControlOptions: {
            style: google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
            position: google.maps.ControlPosition.TOP_LEFT,
            mapTypeIds: [
              google.maps.MapTypeId.ROADMAP,
              google.maps.MapTypeId.SATELLITE,
            ],
          },
        }}
      >
        <MarkerF
          draggable={false}
          clickable={false}
          position={mapCenter}
          icon={{
            url: location.chargingStations.find((cs) => cs.shadow === 'faulted')
              ? locationFaultedPOIMarker
              : locationPOIMarker,
            scaledSize: new google.maps.Size(50, 50),
          }}
        />
        <Box
          borderRadius={hasBorderRadius ? 3 : 0}
          // absolute positioning - fixed values
          sx={{
            display: 'flex',
            flexDirection: 'column',
            backgroundColor: 'background.paper',
            boxShadow: shadows[1],
            p: 3,
            position: 'absolute',
            left: spacing(4),
            bottom: spacing(7),
            minWidth: 'min-content',
            maxWidth: `calc(100% - ${spacing(8)})`,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexWrap: 'nowrap',
              justifyContent: 'space-between',
              mb: 2.5, // Set to 1 after edit icon button implementation
            }}
          >
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'row',
                width: '100%',
              }}
            >
              <Box
                sx={{
                  alignItems: 'center',
                  display: 'flex',
                  flex: 1,
                  flexDirection: 'row',
                }}
              >
                <IconMapPin size={18} color={palette.grey[500]} />
                <Typography
                  sx={{
                    color: palette.grey[900],
                    flexGrow: 1,
                    ml: 2,
                  }}
                >
                  {t('locationDetailsAddressLabel')}
                </Typography>
              </Box>
              <IconButton onClick={onEditClick} size="small">
                <IconPencil color={palette.grey[500]} />
              </IconButton>
            </Box>
          </Box>
          <Box display="flex">
            <Typography
              sx={{
                color: palette.grey[900],
                fontWeight: '500',
              }}
            >
              {address}
            </Typography>
          </Box>
        </Box>
      </ESMap>
    </Box>
  );
};
