import { Stack, Chip, Typography, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';

type TanksCountCellProps = {
  rawCount: number;
  degassedCount: number;
};

export const TanksCountCell: React.FC<TanksCountCellProps> = ({
  rawCount,
  degassedCount,
}) => {
  const [t] = useTranslation('businessAccounts');
  return (
    <Stack
      direction="column"
      gap={2}
      sx={{ width: 'auto', display: 'inline-flex' }}
    >
      <Chip
        size="medium"
        label={
          <Stack direction="row" justifyContent="space-between" width="100%">
            <Typography fontWeight={500}>{t('raw')}</Typography>
            <Box width={10} />
            <Typography fontWeight={500}>{rawCount}</Typography>
          </Stack>
        }
        sx={{
          backgroundColor: 'cyan.50',
          color: 'cyan.600',
          '& .MuiChip-label': {
            width: '100%',
          },
        }}
      />
      <Chip
        size="medium"
        label={
          <Stack direction="row" justifyContent="space-between" width="100%">
            <Typography fontWeight={500}>{t('degassed')}</Typography>
            <Box width={10} />
            <Typography fontWeight={500}>{degassedCount}</Typography>
          </Stack>
        }
        sx={{
          backgroundColor: 'warning.light',
          color: 'warning.800',
          fontWeight: 500,
          '& .MuiChip-label': {
            width: '100%',
          },
        }}
      />
    </Stack>
  );
};
