import { CSSProperties, useRef } from 'react';
import { useDragLayer } from 'react-dnd';
import { ESTableRowDragPreview } from './ESTableRowDragPreview';

const layerStyles: CSSProperties = {
  position: 'fixed',
  pointerEvents: 'none',
  zIndex: 100,
  left: 0,
  top: 0,
  width: '100%',
  height: '100%',
};

export const ESTableRowDragLayer = () => {
  const containerElementRef = useRef<HTMLDivElement>(null);
  const { isDragging, item, initialOffset, initialClientOffset, clientOffset } =
    useDragLayer((monitor) => ({
      item: monitor.getItem(),
      initialOffset: monitor.getInitialSourceClientOffset(),
      initialClientOffset: monitor.getInitialClientOffset(),
      currentOffset: monitor.getSourceClientOffset(),
      clientOffset: monitor.getClientOffset(),
      isDragging: monitor.isDragging(),
      monitor,
    }));

  if (!item || !item.row) {
    return null;
  }

  const containerEl = document.getElementById('container');

  const idx = (initialClientOffset?.x ?? 0) - (initialOffset?.x ?? 0);
  const idy = (initialClientOffset?.y ?? 0) - (initialOffset?.y ?? 0);

  const targetRect = containerEl?.getBoundingClientRect();
  // Position relative to parent container
  const left = (clientOffset?.x ?? 0) - (targetRect?.left ?? 0) - idx * 0.5;
  const top = (clientOffset?.y ?? 0) - (targetRect?.top ?? 0) - idy * 0.5;

  const transform = `translate(${left}px, ${top}px)`;

  return (
    <div
      id="container"
      style={{
        ...layerStyles,
        display: isDragging ? 'initial' : 'none',
      }}
      ref={containerElementRef}
    >
      <div
        style={{
          transform,
          WebkitTransform: transform,
        }}
      >
        <ESTableRowDragPreview item={item} />
      </div>
    </div>
  );
};
