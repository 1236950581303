import { WorkingHoursDto } from '@energy-stacks/fleet/shared';
import { EditVehicleOperatingHoursFormData } from '../editVehicleOperatingHoursFormData';
import { regularWorkingHoursToPayload } from './regularWorkingHoursToPayload';
import { exceptionalWorkingHoursToPayload } from './exceptionalWorkingHoursToPayload';

export const workingHoursToPayload = (
  workingHours: EditVehicleOperatingHoursFormData['workingHours']
): Omit<WorkingHoursDto, 'twentyfourseven'> => {
  const regularHours = regularWorkingHoursToPayload(workingHours.regular);
  const exceptionalHours = exceptionalWorkingHoursToPayload(
    workingHours.exceptional
  );

  return {
    ...regularHours,
    ...exceptionalHours,
  };
};
