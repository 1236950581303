import {
  ESTable,
  ESTableBody,
  ESTableHead,
  useESTableBasic,
} from '@energy-stacks/core/ui';
import {
  BusinessAccountDetailsModel,
  BusinessAccountLocation,
} from '@energy-stacks/fleet/feature-business-accounts-data';
import { ProductIcon } from '@energy-stacks/fleet/shared';
import { ESTooltip, NoTableData } from '@energy-stacks/shared';
import { Box, Chip, Typography, useTheme } from '@mui/material';
import { IconInfoCircle } from '@tabler/icons-react';
import { createColumnHelper } from '@tanstack/react-table';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useRemovePlantIdPrefix } from '../useRemovePlantIdPrefix';

type TanksCardContentProps = {
  variant: keyof BusinessAccountDetailsModel['locations'];
  tanks: BusinessAccountLocation[] | undefined;
};

const variantColorsMap: Record<
  TanksCardContentProps['variant'],
  { backgroundColor: string; color: string }
> = {
  degassed: {
    backgroundColor: 'warning.light',
    color: 'warning.800',
  },
  raw: {
    backgroundColor: 'cyan.50',
    color: 'cyan.600',
  },
};

export const TanksCard: FC<TanksCardContentProps> = ({ variant, tanks }) => {
  const [t] = useTranslation('businessAccounts');
  const theme = useTheme();
  const cellPadding = `0px ${theme.spacing(6)} 0px 0px`;
  const removePlantIdPrefix = useRemovePlantIdPrefix();

  const columnHelper = createColumnHelper<BusinessAccountLocation>();
  const columns = [
    columnHelper.accessor('id', {
      id: 'tankCode',
      header: () => t('tankCodeColumnHeader'),
      footer: (props) => props.column.id,
      cell: (info) => {
        return (
          <Chip
            label={removePlantIdPrefix(info.getValue()).toUpperCase()}
            sx={{
              ...variantColorsMap[variant],
              fontWeight: 500,
            }}
          />
        );
      },
      meta: { cellPadding },
    }),
    columnHelper.accessor('type', {
      id: 'type',
      header: () => t('tankTypeColumnHeader'),
      footer: (props) => props.column.id,
      cell: (info) => {
        const description = info.row.original.address.description;
        return (
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            <ProductIcon risk={info.row.original.risk} type={info.getValue()} />
            {description && (
              <ESTooltip title={description}>
                <Box
                  sx={{
                    alignItems: 'center',
                    display: 'flex',
                    marginLeft: 1.25,
                  }}
                >
                  <IconInfoCircle
                    style={{
                      color: '#697586',
                      width: 16,
                    }}
                  />
                </Box>
              </ESTooltip>
            )}
          </Box>
        );
      },
      meta: { cellPadding },
    }),
    columnHelper.accessor('address', {
      id: 'location',
      header: () => t('tankLocationColumnHeader'),
      footer: (props) => props.column.id,
      cell: (info) => (
        <Typography variant="body2">{info.getValue().street}</Typography>
      ),
      meta: { cellPadding },
    }),
  ];
  const { instance, rows } = useESTableBasic(tanks, columns, {
    initialSorting: [{ id: 'tankCode', desc: false }],
    manualPagination: true,
  });

  return (
    <Box
      sx={{
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        position: 'relative',
      }}
    >
      <ESTable minWidth={0}>
        <ESTableHead instance={instance} fontWeight={500} height={52} />
        <ESTableBody rows={rows} />
      </ESTable>
      {rows.length === 0 ? (
        <NoTableData message={t('thereAreNoTanks')} />
      ) : null}
    </Box>
  );
};
