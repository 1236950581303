export const formatCurrentAndMaxPower = (
  currentPower: number,
  maxPower: number
) => {
  if (currentPower < 1000 && maxPower < 1000 && maxPower !== 0) {
    return `${currentPower}/${maxPower} W`;
  }

  return `${currentPower / 1000}/${maxPower / 1000} kW`;
};
