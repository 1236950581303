import { CsmsUserDto } from '../csmsUserDto';
import { CsmsUserModel } from '../csmsUserModel';
import { userRoleNormalizer } from './userRoleNormalizer';

export const csmsUserModelNormalizer = (
  userDto: CsmsUserDto
): CsmsUserModel => {
  return {
    providerId: userDto.providerId,
    userRole: userRoleNormalizer(userDto.userRole),
    isActive: userDto.isActive,
    emailAddress: userDto.emailAddress,
    displayName: userDto.displayName,
  };
};
