import { TabbedScreen, useESSnackbar } from '@energy-stacks/core/ui';
import {
  ErrorApiResponse,
  NotFound,
  useDocumentTitle,
} from '@energy-stacks/shared';
import { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useChargingStationDetails } from './useChargingStationDetails';
import { Box } from '@mui/material';
import { ChargingStationCoreManagementActionLoading } from './ChargingStationCoreManagementActionLoading';
import { useChargingStationDetailsTabs } from './useChargingStationDetailsTabs';
import { ChargingStationDetailsHeader } from './ChargingStationDetailsHeader';
import { chargingStationApiErrors } from '@energy-stacks/broker/feature-charging-stations-data';
import { BrokerRoutes } from '@energy-stacks/broker/shared';
import { ChargingStationDetailsSkeleton } from './ChargingStationDetailsSkeleton';

export const ChargingStationDetails = () => {
  const [t] = useTranslation('chargingStations');
  useDocumentTitle(t('title'));

  const { chargingStationDetails, error, isLoading } =
    useChargingStationDetails();

  const { config } = useChargingStationDetailsTabs(
    chargingStationDetails?.ocppProtocol
  );
  const { showSnackbar } = useESSnackbar();

  useEffect(() => {
    if (error) {
      if ('data' in error) {
        // You can access all properties of `FetchBaseQueryError` type here
        const response = error.data as ErrorApiResponse;
        error.data
          ? showSnackbar(
              'error',
              chargingStationApiErrors[response.errorCode],
              'chargingStations'
            )
          : showSnackbar('error');
      } else {
        showSnackbar('error');
      }
    }
  }, [error, showSnackbar]);

  if (isLoading) {
    return <ChargingStationDetailsSkeleton />;
  }

  return (
    <>
      {error ? (
        <NotFound
          subtitleText="goToChargingStations"
          buttonText="goToChargingStationsButtonText"
          navigateLink={BrokerRoutes.ChargingStations}
        />
      ) : (
        <>
          <Box pt={6} pb={2} px={8}>
            <ChargingStationDetailsHeader />
          </Box>
          <TabbedScreen config={config} />
        </>
      )}
      <ChargingStationCoreManagementActionLoading />
      <Outlet />
    </>
  );
};
