import { environment } from '@energy-stacks/feature-config';
import { createBaseQuery } from '@energy-stacks/shared';
import { createApi } from '@reduxjs/toolkit/query/react';
import { AddProviderFormData } from './addProviderFormData';
import { CsmsProviderDto } from './csmsProviderDto';
import { CsmsProviderModel } from './csmsProviderModel';
import { csmsProviderNormalizer } from './normalizers/csmsProviderNormalizer';

type EditProviderPayload = {
  providerName: string;
};

export const providersApi = createApi({
  reducerPath: 'providersApi',
  tagTypes: ['Providers'],
  baseQuery: createBaseQuery(`${environment.ocppServiceUrl}/providers`),
  endpoints: (builder) => ({
    getProviders: builder.query<CsmsProviderModel[], void>({
      query: () => '/',
      providesTags: ['Providers'],
      transformResponse: (providers: CsmsProviderDto[]) => {
        return providers.map(csmsProviderNormalizer);
      },
    }),
    addProvider: builder.mutation<CsmsProviderModel, AddProviderFormData>({
      query: (body) => ({
        url: '/',
        method: 'POST',
        body: {
          providerName: body.providerName,
          providerId: body.providerId,
        },
      }),
      transformResponse: (provider: CsmsProviderDto) => {
        return csmsProviderNormalizer(provider);
      },
      invalidatesTags: (result, _error) => (result ? ['Providers'] : []),
    }),
    editProvider: builder.mutation<
      CsmsProviderModel,
      EditProviderPayload & {
        providerNameToUpdate: CsmsProviderModel['providerName'];
      }
    >({
      query: (body) => ({
        url: `/${body.providerNameToUpdate}`,
        method: 'PUT',
        body: {
          providerName: body.providerName,
        },
      }),
      transformResponse: (provider: CsmsProviderDto) => {
        return csmsProviderNormalizer(provider);
      },
      invalidatesTags: (result, _error) => (result ? ['Providers'] : []),
    }),
  }),
});

export const {
  useGetProvidersQuery,
  useAddProviderMutation,
  useEditProviderMutation,
} = providersApi;
