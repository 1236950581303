import { WhitelistDto } from '../mobilityTokensDto';
import { MobilityTokenWhitelist } from '../mobilityTokenModel';

export const mobilityTokenWithelistNormalizer = (
  whitelist: WhitelistDto
): MobilityTokenWhitelist => {
  return mobilityTokenWhitelistMap[whitelist];
};

const mobilityTokenWhitelistMap: Record<WhitelistDto, MobilityTokenWhitelist> =
  {
    ALWAYS: 'always',
    ALLOWED: 'allowed',
    ALLOWED_OFFLINE: 'allowedOffline',
    NEVER: 'never',
  };
