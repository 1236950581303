import { environment } from '@energy-stacks/feature-config';
import { createBaseQuery } from '@energy-stacks/shared';
import { createApi } from '@reduxjs/toolkit/query/react';
import { SaveOwnerEntityFormData } from './saveOwnerEntityFormData';
import { GetOwnerEntityDto } from './ownerEntityDto';
import {
  OwnerEntityModel,
  SaveOwnerEntityModel,
  GetOwnerEntityModel,
} from './ownerEntityModel';
import { ownerEntityNormalizer } from './normalizers/ownerEntityNormalizer';
import { AddEntityUserFormData } from './addEntityUserFormData';

export interface EditOwnerEntityPayload {
  editEntityFormData: SaveOwnerEntityFormData;
  ownerId: string;
}

export const ownerEntityApi = createApi({
  reducerPath: 'ownerEntityApi',
  baseQuery: createBaseQuery(`${environment.ocppServiceUrl}/`),
  tagTypes: ['Entities', 'Entity'],
  endpoints: (builder) => ({
    getOwnerEntities: builder.query<OwnerEntityModel[], void>({
      query: () => 'owners/',
      transformResponse: (entities: GetOwnerEntityDto) =>
        ownerEntityNormalizer(entities),
      providesTags: ['Entities'],
    }),
    addOwnerEntity: builder.mutation<
      SaveOwnerEntityModel,
      SaveOwnerEntityFormData
    >({
      query: (entity) => ({
        url: 'owners/',
        method: 'POST',
        body: { ...entity, emailAddress: entity.emailAddress || undefined },
      }),
      invalidatesTags: (result) => (result ? ['Entities'] : []),
    }),
    getOwnerEntity: builder.query<GetOwnerEntityModel, string>({
      query: (ownerId) => ({
        url: `owners/${ownerId}`,
      }),
      providesTags: (result, _error, _arg) => [
        {
          type: 'Entity',
          id: result?.ownerId,
        },
      ],
    }),
    editOwnerEntity: builder.mutation<
      SaveOwnerEntityModel,
      EditOwnerEntityPayload
    >({
      query: ({ editEntityFormData, ownerId }) => ({
        url: `owners/${ownerId}`,
        method: 'PUT',
        body: {
          ...editEntityFormData,
          emailAddress: editEntityFormData.emailAddress || undefined,
        },
      }),
      invalidatesTags: (result, _error, arg) =>
        result ? [{ type: 'Entity', id: arg.ownerId }, 'Entities'] : [],
    }),
    addEntityUser: builder.mutation<
      { success: boolean },
      {
        addEntityUserFormData: AddEntityUserFormData;
        ownerId: OwnerEntityModel['ownerId'];
      }
    >({
      query: ({ addEntityUserFormData, ownerId }) => ({
        url: `owner-users/${ownerId}`,
        method: 'POST',
        params: {
          userId: addEntityUserFormData.userId,
        },
      }),
      invalidatesTags: (result, _error, arg) =>
        result ? [{ type: 'Entity', id: arg.ownerId }, 'Entities'] : [],
    }),
    removeEntityUser: builder.mutation<
      { success: boolean },
      {
        userId: string;
        ownerId: OwnerEntityModel['ownerId'];
      }
    >({
      query: ({ userId, ownerId }) => ({
        url: `owner-users/${ownerId}`,
        method: 'PUT',
        params: {
          userId,
        },
      }),
      invalidatesTags: (result, _error, arg) =>
        result ? [{ type: 'Entity', id: arg.ownerId }, 'Entities'] : [],
    }),
    removeEntityLocation: builder.mutation<
      { success: boolean },
      {
        locationId: string;
        ownerId: OwnerEntityModel['ownerId'];
      }
    >({
      query: ({ locationId }) => ({
        url: `owner-locations/${locationId}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, _error, arg) =>
        result ? [{ type: 'Entity', id: arg.ownerId }, 'Entities'] : [],
    }),
  }),
});

export const {
  useGetOwnerEntitiesQuery,
  useAddOwnerEntityMutation,
  useGetOwnerEntityQuery,
  useEditOwnerEntityMutation,
  useAddEntityUserMutation,
  useRemoveEntityUserMutation,
  useRemoveEntityLocationMutation,
} = ownerEntityApi;
