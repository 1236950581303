import { ConnectorStandard } from '@energy-stacks/data';
import { ReactComponent as ChademoIcon } from './icons/chademo.svg';
import { ReactComponent as DomesticFIcon } from './icons/domesticF.svg';
import { ReactComponent as Iec62196T1Icon } from './icons/Iec62196T1.svg';
import { ReactComponent as Iec62196T1ComboIcon } from './icons/Iec62196T1Combo.svg';
import { ReactComponent as Iec62196T2Icon } from './icons/Iec62196T2.svg';
import { ReactComponent as Iec62196T2Combo } from './icons/Iec62196T2Combo.svg';
import { ReactComponent as Iec62196T3CIcon } from './icons/Iec62196T3C.svg';
import { ReactComponent as Unknown } from './icons/unknown.svg';

export const connectorStandardToIconMap: {
  [type in ConnectorStandard]: React.FunctionComponent<
    React.SVGProps<SVGSVGElement>
  >;
} = {
  CHADEMO: ChademoIcon,
  DOMESTIC_F: DomesticFIcon,
  IEC_62196_T1: Iec62196T1Icon,
  IEC_62196_T1_COMBO: Iec62196T1ComboIcon,
  IEC_62196_T2: Iec62196T2Icon,
  IEC_62196_T2_COMBO: Iec62196T2Combo,
  IEC_62196_T3C: Iec62196T3CIcon,
  UNKNOWN: Unknown,
};
