import { useHardResetChargingStationMutation } from '@energy-stacks/broker/feature-charging-station-management-data';
import { useESSnackbar } from '@energy-stacks/core/ui';
import {
  HardResetDialog,
  useChargingStationIdentityKey,
} from '@energy-stacks/shared';
import {
  startChargingStationCoreManagementAction,
  endChargingStationCoreManagementAction,
  useAppDispatch,
  clearHardResetRequired,
} from '@energy-stacks/broker/store';
import { useCallback } from 'react';

interface BrokerHardReset {
  isOpen: boolean;
  onClose: () => void;
}

export const BrokerHardReset: React.FC<BrokerHardReset> = ({
  isOpen,
  onClose,
}) => {
  const [hardResetChargingStation] = useHardResetChargingStationMutation();
  const identityKey = useChargingStationIdentityKey();
  const { showSnackbar } = useESSnackbar();
  const dispatch = useAppDispatch();

  const handleHardReset = useCallback(() => {
    dispatch(startChargingStationCoreManagementAction());
    hardResetChargingStation(identityKey)
      .unwrap()
      .then((response) => {
        if (response.status === 'Accepted') {
          dispatch(clearHardResetRequired(identityKey));
          showSnackbar('success', 'hardResetAccepted', 'chargingStations');
          return;
        }
        if (response.status === 'Rejected') {
          showSnackbar('error', 'hardResetRejected', 'chargingStations');
          return;
        }
      })
      .catch((error) => {
        if (error.data.errorCode === 'RPC_TIMEOUT') {
          showSnackbar('error', 'rpcTimeoutError', 'chargingStations');
          return;
        }
        if (error.data.errorCode === 'RPC_ERROR') {
          showSnackbar('error', 'rpcError', 'chargingStations');
          return;
        }
        showSnackbar('error');
      })
      .finally(() => {
        dispatch(endChargingStationCoreManagementAction());
      });
  }, [dispatch, hardResetChargingStation, identityKey, showSnackbar]);

  return (
    <HardResetDialog
      isOpen={isOpen}
      onClose={onClose}
      onCallToAction={() => {
        handleHardReset();
        onClose();
      }}
    />
  );
};
