import { Box, Typography, useTheme } from '@mui/material';
import { ESApexChart } from '../ESApexChart';
import { Props } from 'react-apexcharts';
import { useTranslation } from 'react-i18next';
import { colors } from '@mui/material';
import {
  ChargingStationModel,
  ConnectorStatusEnum,
  selectAllStations,
  useChargingStations,
} from '@energy-stacks/broker/feature-charging-stations-data';
import { useSelector } from 'react-redux';
import { useGetUiBrandingQuery } from '@energy-stacks/broker/feature-settings-data';
import { useMemo } from 'react';

type ConnectorsCountMap = Record<ConnectorStatusEnum, number>;
type ChargingStationsCountMap = Record<
  ChargingStationModel['csStatus'],
  number
>;

export const StationsAndConnectorsCardChart = () => {
  const { t } = useTranslation('dashboard');
  const theme = useTheme();
  useChargingStations({ pollingInterval: 20 * 1000 });
  const chargingStations = useSelector(selectAllStations);
  const { data: uiBranding } = useGetUiBrandingQuery();
  const hasBorderRadius =
    uiBranding?.hasBorderRadius || uiBranding?.hasBorderRadius === undefined;

  const chargingStationsCountMap: ChargingStationsCountMap = (
    chargingStations ?? []
  ).reduce(
    (acc, { csStatus }) => {
      if (['online', 'offline', 'faulted', 'provisioned'].includes(csStatus)) {
        return {
          ...acc,
          [csStatus]: ++acc[csStatus],
        };
      }
      return acc;
    },
    {
      online: 0,
      offline: 0,
      faulted: 0,
      provisioned: 0,
    } as ChargingStationsCountMap
  );

  const totalConnectors = chargingStations?.reduce((accumulator, obj) => {
    return accumulator + obj.connectors.length;
  }, 0);

  const connectorsCountMap: ConnectorsCountMap = (chargingStations ?? [])
    .map((cs) => cs.connectors)
    .flat()
    .reduce(
      (acc, { connectorStatus }) => ({
        ...acc,
        [connectorStatus]: ++acc[connectorStatus],
      }),
      {
        undefined: 0,
        available: 0,
        preparing: 0,
        charging: 0,
        occupied: 0,
        suspendedEVSE: 0,
        suspendedEV: 0,
        finishing: 0,
        reserved: 0,
        unavailable: 0,
        faulted: 0,
      } as ConnectorsCountMap
    );

  const connectorSeriesData = useMemo(() => {
    return [
      {
        name: t('available'),
        data: [connectorsCountMap['available']],
        color: theme.palette.success.dark,
      },
      {
        name: t('preparing'),
        data: [connectorsCountMap['preparing']],
        color: colors.indigo[300],
      },
      {
        name: t('occupied'),
        data: [connectorsCountMap['occupied']],
        color: theme.palette.primary.dark,
      },
      {
        name: t('charging'),
        data: [connectorsCountMap['charging']],
        color: theme.palette.primary.dark,
      },
      {
        name: t('suspendedEVSE'),
        data: [connectorsCountMap['suspendedEVSE']],
        color: theme.palette.secondary.main,
      },
      {
        name: t('suspendedEV'),
        data: [connectorsCountMap['suspendedEV']],
        color: theme.palette.pink[300],
      },
      {
        name: t('finishing'),
        data: [connectorsCountMap['finishing']],
        color: theme.palette.cyan[300],
      },
      {
        name: t('reserved'),
        data: [connectorsCountMap['reserved']],
        color: theme.palette.warning.main,
      },
      {
        name: t('unavailable'),
        data: [connectorsCountMap['unavailable']],
        color: colors.blueGrey[400],
      },
      {
        name: t('undefinedConnectors'),
        data: [connectorsCountMap['undefined']],
        color: colors.blueGrey[300],
      },
      {
        name: t('faulted'),
        data: [connectorsCountMap['faulted']],
        color: theme.palette.orange.main,
      },
    ];
  }, [
    connectorsCountMap,
    t,
    theme.palette.cyan,
    theme.palette.orange.main,
    theme.palette.pink,
    theme.palette.primary.dark,
    theme.palette.secondary.main,
    theme.palette.success.dark,
    theme.palette.warning.main,
  ]);

  const filteredConnectorData = connectorSeriesData.filter(
    (obj) => obj.data[0] !== 0
  );

  const chargingStationData = [
    {
      name: t('online', { context: 'status' }),
      data: [chargingStationsCountMap['online']],
      color: theme.palette.success.dark,
    },
    {
      name: t('provisioned', { context: 'status' }),
      data: [chargingStationsCountMap['provisioned']],
      color: colors.orange[900],
    },
    {
      name: t('offline', { context: 'status' }),
      data: [chargingStationsCountMap['offline']],
      color: colors.blueGrey[300],
    },
    {
      name: t('faulted'),
      data: [chargingStationsCountMap['faulted']],
      color: theme.palette.orange.main,
    },
  ];

  const filteredChargingStationData = chargingStationData.filter(
    (obj) => obj.data[0] !== 0
  );
  const chartDataConnectors: Props = useMemo(() => {
    return {
      height: 100,
      type: 'bar',
      options: {
        chart: {
          id: 'bar-chart',
          stacked: true,
          stackType: '100%',
          sparkline: {
            enabled: true,
          },
          toolbar: {
            show: false,
          },
          zoom: {
            enabled: true,
          },
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              legend: {
                position: 'top',
                offsetY: 58,
              },
            },
          },
        ],
        plotOptions: {
          bar: {
            horizontal: true,
            barHeight: '10px',
          },
        },
        xaxis: {
          labels: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          categories: [''],
        },
        yaxis: {
          labels: {
            show: false,
          },
        },
        legend: {
          show: true,
          fontFamily: theme.typography.fontFamily,
          position: 'top',
          offsetY: 58,
          height: 46,
          labels: {
            useSeriesColors: false,
          },
          markers: {
            width: 8,
            height: 8,
            radius: 4,
          },
          itemMargin: {
            horizontal: 15,
          },
        },
        fill: {
          type: 'solid',
        },
        dataLabels: {
          enabled: false,
        },
      },
      series: filteredConnectorData,
    };
  }, [filteredConnectorData, theme.typography.fontFamily]);

  const chartDataChargingStations: Props = {
    height: 100,
    type: 'bar',
    options: {
      chart: {
        id: 'bar-chart',
        stacked: true,
        stackType: '100%',
        sparkline: {
          enabled: true,
        },
        toolbar: {
          show: false,
        },
        zoom: {
          enabled: true,
        },
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            legend: {
              position: 'top',
              offsetY: 58,
            },
          },
        },
      ],
      plotOptions: {
        bar: {
          horizontal: true,
          barHeight: '10px',
        },
      },
      xaxis: {
        labels: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        categories: [''],
      },
      yaxis: {
        labels: {
          show: false,
        },
      },
      legend: {
        show: true,
        fontFamily: theme.typography.fontFamily,
        position: 'top',
        offsetY: 58,
        height: 46,
        labels: {
          useSeriesColors: false,
        },
        markers: {
          width: 8,
          height: 8,
          radius: 4,
        },
        itemMargin: {
          horizontal: 15,
        },
      },
      fill: {
        type: 'solid',
      },
      dataLabels: {
        enabled: false,
      },
      grid: {
        padding: {
          top: -50,
        },
      },
    },
    series: filteredChargingStationData,
  };
  return (
    <Box
      display="flex"
      gap={6}
      width="100%"
      sx={{
        [theme.breakpoints.down('lg')]: {
          flexWrap: 'wrap',
        },
      }}
    >
      <Box
        borderRadius={hasBorderRadius ? 3 : 0}
        sx={{
          backgroundColor: 'background.paper',
          width: '100%',
        }}
      >
        <Box p={5}>
          <Box display="flex" alignItems="center" gap={3}>
            <Typography fontSize={20}>{t('chargingStations')}</Typography>
            <Typography variant="h4" fontSize={24}>
              {(chargingStations ?? []).length}
            </Typography>
          </Box>
          <ESApexChart chartData={chartDataChargingStations} />
        </Box>
      </Box>
      <Box
        borderRadius={hasBorderRadius ? 3 : 0}
        sx={{
          backgroundColor: 'background.paper',
          width: '100%',
        }}
      >
        <Box p={5}>
          <Box display="flex" alignItems="center" gap={3}>
            <Typography fontSize={20}>{t('connectors')}</Typography>
            <Typography variant="h4" fontSize={24}>
              {totalConnectors}
            </Typography>
          </Box>
          <ESApexChart chartData={chartDataConnectors} />
        </Box>
      </Box>
    </Box>
  );
};
