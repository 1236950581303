import { MobilityTokenType } from '../mobilityTokenModel';
import { MobilityTokenTypeDto } from '../mobilityTokensDto';

export const mobilityTokenTypeNormalizer = (
  type: MobilityTokenTypeDto
): MobilityTokenType => {
  return mobilityTokenTypeMap[type];
};

const mobilityTokenTypeMap: Record<MobilityTokenTypeDto, MobilityTokenType> = {
  AD_HOC_USER: MobilityTokenType.adHocUser,
  APP_USER: MobilityTokenType.appUser,
  OTHER: MobilityTokenType.other,
  RFID: MobilityTokenType.rfidCard,
};
