import { DateRange } from '@energy-stacks/core/date-range-picker';
import { JobModel } from '@energy-stacks/fleet/feature-jobs-data';
import { VehicleModel } from '@energy-stacks/fleet/feature-vehicles-data';
import { OptimizeOptionVariant } from './OptimizeOptionCard';
import { useCallback, useMemo, useReducer } from 'react';
import { endOfWeek, startOfWeek, addDays } from 'date-fns';
import { OptimizedTourModel } from '@energy-stacks/fleet/feature-tours-data';

type CreateTourAction =
  | { type: 'FILTER_DATE'; payload: { dateRange: DateRange } }
  | { type: 'SET_JOBS'; payload: { selectedJobIds: JobModel['jobId'][] } }
  | {
      type: 'SET_VEHICLES';
      payload: { selectedVehicleIds: VehicleModel['vehicleId'][] };
    }
  | { type: 'SET_OPTIMIZATION'; payload: { optimize: OptimizeOptionVariant } }
  | {
      type: 'SET_TOURS';
      payload: { selectedTours: OptimizedTourModel[] };
    };
interface CreateTourState {
  dateRange: DateRange;
  selectedJobIds: JobModel['jobId'][] | undefined;
  selectedVehicleIds: VehicleModel['vehicleId'][] | undefined;
  optimize: OptimizeOptionVariant;
  selectedTours: OptimizedTourModel[] | undefined;
}

const createTourReducer = (
  state: CreateTourState,
  action: CreateTourAction
): CreateTourState => {
  const { type, payload } = action;
  switch (type) {
    case 'FILTER_DATE':
      return {
        ...state,
        dateRange: { ...state.dateRange, ...payload?.dateRange },
      };
    case 'SET_JOBS':
      return {
        ...state,
        selectedJobIds: payload?.selectedJobIds,
      };
    case 'SET_VEHICLES':
      return {
        ...state,
        selectedVehicleIds: payload?.selectedVehicleIds,
      };
    case 'SET_OPTIMIZATION':
      return {
        ...state,
        optimize: payload?.optimize ?? 'time',
      };
    case 'SET_TOURS':
      return {
        ...state,
        selectedTours: payload?.selectedTours,
      };
    default:
      return state;
  }
};

export const useCreateTourState = () => {
  const defaultDateRange: DateRange = useMemo(
    () => ({
      minDate: new Date(),
      maxStartDateOffset: 6,
      startDate: addDays(startOfWeek(new Date(), { weekStartsOn: 1 }), 7),
      endDate: addDays(endOfWeek(new Date(), { weekStartsOn: 1 }), 7),
      showShortcuts: false,
    }),
    []
  );

  const [state, dispatch] = useReducer(createTourReducer, {
    dateRange: defaultDateRange,
    selectedJobIds: undefined,
    selectedVehicleIds: undefined,
    optimize: 'time',
    selectedTours: undefined,
  });

  const setSelectedJobIds = useCallback((jobIds: JobModel['jobId'][]) => {
    dispatch({ type: 'SET_JOBS', payload: { selectedJobIds: jobIds } });
  }, []);

  const setSelectedVehicleIds = useCallback(
    (vehicleIds: VehicleModel['vehicleId'][]) => {
      dispatch({
        type: 'SET_VEHICLES',
        payload: { selectedVehicleIds: vehicleIds },
      });
    },
    []
  );

  const setOptimization = useCallback((optimize: OptimizeOptionVariant) => {
    dispatch({ type: 'SET_OPTIMIZATION', payload: { optimize } });
  }, []);

  const setDateRange = useCallback((dateRange: DateRange) => {
    dispatch({ type: 'FILTER_DATE', payload: { dateRange } });
  }, []);

  const setSelectedTours = useCallback((tours: OptimizedTourModel[]) => {
    dispatch({ type: 'SET_TOURS', payload: { selectedTours: tours } });
  }, []);

  return {
    state,
    setSelectedJobIds,
    setSelectedVehicleIds,
    setOptimization,
    setSelectedTours,
    setDateRange,
    defaultDateRange,
  };
};
