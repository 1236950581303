import { environment } from '@energy-stacks/feature-config';
import { createBaseQuery } from '@energy-stacks/shared';
import { createApi } from '@reduxjs/toolkit/query/react';
import { AddChargingLocationFormData } from './addChargingLocationFormData';
import { LocationDetailsDto } from './locationDetailsDto';
import { LocationDetailsFormData } from './locationDetailsFormData';
import { LocationDetailsModel } from './locationDetailsModel';
import { LocationDto } from './locationDto';
import { LocationModel } from './locationModel';
import { mapAddLocationFormDataToPayload } from './mapAddLocationFormDataToPayload';
import { mapEditLocationFormDataToPayload } from './mapEditLocationFormDataToPayload';
import { locationDetailsNormalizer } from './normalizers/locationDetailsNormalizer';
import { locationNormalizer } from './normalizers/locationNormalizer';

export const locationsApi = createApi({
  reducerPath: 'locationsApi',
  tagTypes: ['Locations', 'LocationDetails'],
  baseQuery: createBaseQuery(`${environment.ocppServiceUrl}/locations`),
  endpoints: (builder) => ({
    getLocations: builder.query<LocationModel[], void>({
      query: () => '/',
      providesTags: ['Locations'],
      transformResponse: (locations: LocationDto[]) => {
        return locations.map(locationNormalizer);
      },
    }),
    deleteLocation: builder.mutation<
      { success: boolean },
      LocationModel['locationId']
    >({
      query: (locationId) => ({
        url: `/${locationId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Locations'],
    }),
    addLocation: builder.mutation<
      LocationDetailsModel,
      AddChargingLocationFormData
    >({
      query: (body) => ({
        url: `/`,
        method: 'POST',
        body: mapAddLocationFormDataToPayload(body),
      }),
      invalidatesTags: ['Locations'],
      transformResponse: (location: LocationDetailsDto) =>
        locationDetailsNormalizer(location),
    }),
    getLocationDetails: builder.query<
      LocationDetailsModel,
      LocationModel['locationId']
    >({
      query: (locationId) => `/${locationId}`,
      providesTags: (result, _error, _arg) => [
        {
          type: 'LocationDetails',
          id: result?.locationId,
        },
      ],
      transformResponse: (location: LocationDetailsDto) =>
        locationDetailsNormalizer(location),
    }),
    editLocation: builder.mutation<
      LocationDetailsModel,
      {
        location: LocationDetailsFormData;
        locationId: LocationDetailsModel['locationId'];
      }
    >({
      query: (data) => ({
        url: `/${data.locationId}`,
        method: 'PUT',
        body: mapEditLocationFormDataToPayload(data.location),
      }),
      invalidatesTags: (result, _error, arg) =>
        result
          ? [{ type: 'LocationDetails', id: arg.locationId }, 'Locations']
          : [],
    }),
  }),
});

export const {
  useGetLocationsQuery,
  useDeleteLocationMutation,
  useAddLocationMutation,
  useGetLocationDetailsQuery,
  useEditLocationMutation,
} = locationsApi;
