import React from 'react';
import { Chip, colors } from '@mui/material';
import {
  CheckRounded,
  CloseRounded,
  DoNotDisturbRounded,
  HourglassEmptyRounded,
} from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { ConfigurationKeyStatus } from './configurationKeyStatus';
import { ReactComponent as HardResetIcon } from './icons/hardReset.svg';

export const ConfigurationKeyStatusChip: React.FC<{
  status: ConfigurationKeyStatus;
}> = ({ status }) => {
  const { t } = useTranslation('chargingStations');

  const statusToChip: Record<ConfigurationKeyStatus, React.ReactNode> = {
    [ConfigurationKeyStatus.Accepted]: (
      <Chip
        size="small"
        icon={
          <CheckRounded fontSize="small" sx={{ fill: colors.green[900] }} />
        }
        sx={{
          backgroundColor: colors.green[100],
          color: colors.green[900],
        }}
        label={t('accepted')}
      />
    ),
    [ConfigurationKeyStatus.NotSupported]: (
      <Chip
        disabled
        size="small"
        icon={<DoNotDisturbRounded fontSize="small" />}
        label={t('notSupported')}
      />
    ),
    [ConfigurationKeyStatus.RebootRequired]: (
      <Chip
        size="small"
        icon={<HardResetIcon fontSize="small" fill={colors.orange[900]} />}
        sx={{
          backgroundColor: colors.orange[100],
          color: colors.orange[900],
        }}
        label={t('rebootRequired')}
      />
    ),
    [ConfigurationKeyStatus.Rejected]: (
      <Chip
        size="small"
        icon={
          <CloseRounded
            fontSize="small"
            sx={{ fill: colors.deepOrange[900] }}
          />
        }
        sx={{
          backgroundColor: colors.deepOrange[100],
          color: colors.deepOrange[900],
        }}
        label={t('rejected')}
      />
    ),
    [ConfigurationKeyStatus.RequestTimeout]: (
      <Chip
        size="small"
        icon={<HourglassEmptyRounded fontSize="small" />}
        sx={{
          backgroundColor: colors.blueGrey[100],
          color: colors.blueGrey[900],
        }}
        label={t('requestTimeout')}
      />
    ),
    [ConfigurationKeyStatus.Idle]: null,
    [ConfigurationKeyStatus.Loading]: null,
  };

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>{statusToChip[status]}</>
  );
};
