import { toPayloadDate, toPayloadDateFullDay } from '@energy-stacks/shared';
import { OptimizedTourJob, OptimizedTourModel } from './optimizedTourModel';
import { EditVirtualTourDetailsPayload } from './editVirtualTourDetailsPayload';

export const editVirtualTourDetailsDataToPayload = ({
  tourDetails,
  previousTourJobs,
  nextTourJobs,
}: {
  tourDetails: OptimizedTourModel;
  previousTourJobs?: OptimizedTourJob[];
  nextTourJobs?: OptimizedTourJob[];
}): EditVirtualTourDetailsPayload => {
  return {
    startRouteTime: toPayloadDateFullDay(tourDetails.startDate).startOfDay,
    endRouteTime: toPayloadDateFullDay(tourDetails.endDate).endOfDay,
    vehicleIdentificationNumber: tourDetails.vehicleId,
    jobs: tourDetails.tourJobs.jobs.map((job) => ({
      jobUID: job.jobId,
      jobPrecedence: job.visitOrder,
    })),
    precedenceTourRuleRequests:
      previousTourJobs || nextTourJobs
        ? {
            previousVirtualTourRuleRequests: previousTourJobs
              ? previousTourJobs.map((job) => {
                  return {
                    destinationLocationUid: job.destination?.id || '',
                    originLocationUid: job.origin?.id || '',
                    jobUid: job.jobId,
                    jobType: job.jobType,
                    jobStartDate: toPayloadDate(new Date(job.startTime)),
                    productUid: job.product?.id || '',
                  };
                })
              : undefined,
            nextVirtualTourRuleRequests: nextTourJobs
              ? nextTourJobs.map((job) => {
                  return {
                    destinationLocationUid: job.destination?.id || '',
                    originLocationUid: job.origin?.id || '',
                    jobUid: job.jobId,
                    jobType: job.jobType,
                    jobStartDate: toPayloadDate(new Date(job.startTime)),
                    productUid: job.product?.id || '',
                  };
                })
              : undefined,
          }
        : undefined,
  };
};
