import { ESTextField } from '@energy-stacks/core/ui';
import {
  EditVehicleOperatingHoursFormData,
  RegularWorkingHoursInfo,
} from '@energy-stacks/fleet/feature-vehicles-data';
import {
  Box,
  Grid,
  IconButton,
  Switch,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import { DatePicker, TimePicker } from '@mui/x-date-pickers';
import { IconCalendar, IconTrash } from '@tabler/icons-react';
import { addDays, addMinutes, subMinutes } from 'date-fns';
import { ChangeEvent, FC, useCallback } from 'react';
import {
  Controller,
  UseFieldArrayRemove,
  useFormContext,
  useWatch,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';

type ExceptionalOperatingHoursInputsProps = {
  fieldIndex: number;
  disabled?: boolean;
  onRemove: UseFieldArrayRemove;
  defaultOperatingTime: Pick<RegularWorkingHoursInfo, 'startTime' | 'endTime'>;
};

const tomorrow = addDays(new Date(), 1);

export const ExceptionalOperatingHoursInputs: FC<
  ExceptionalOperatingHoursInputsProps
> = ({ fieldIndex, disabled, onRemove, defaultOperatingTime }) => {
  const [t] = useTranslation('vehicles');
  const { palette } = useTheme();
  const {
    control,
    formState: { errors },
    getValues,
    setValue,
    trigger,
  } = useFormContext();
  const fieldNameStringPrefix = `workingHours.exceptional.${fieldIndex}`;
  const isOperatingValue = useWatch({
    name: `${fieldNameStringPrefix}.isOperating`,
    control,
  });

  const exceptionErrors = errors.workingHours?.exceptional?.[fieldIndex];

  const dateState = {
    name: `${fieldNameStringPrefix}.date`,
    error: Boolean(exceptionErrors?.date),
    helperText:
      Boolean(exceptionErrors?.date) &&
      t(`vehicleFormErrors.${exceptionErrors?.date?.message}`),
  };
  const startTimeState = {
    name: `${fieldNameStringPrefix}.startTime`,
    error: Boolean(exceptionErrors?.startTime),
    helperText:
      Boolean(exceptionErrors?.startTime) &&
      t(`vehicleFormErrors.${exceptionErrors?.startTime?.message}`),
  };
  const endTimeState = {
    name: `${fieldNameStringPrefix}.endTime`,
    error: Boolean(exceptionErrors?.endTime),
    helperText:
      Boolean(exceptionErrors?.endTime) &&
      t(`vehicleFormErrors.${exceptionErrors?.endTime?.message}`),
  };

  const startTimeValue = useWatch({ name: startTimeState.name });
  const endTimeValue = useWatch({ name: endTimeState.name });

  const shouldDisableDate = useCallback(
    (d: Date) =>
      !!getValues()
        .workingHours.exceptional.map(
          (
            e: EditVehicleOperatingHoursFormData['workingHours']['exceptional'][number]
          ) => e.date?.toDateString()
        )
        .find((date: string) => date === d.toDateString()),
    [getValues]
  );

  const setTimesOnSwitchChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const date = new Date(
        getValues().workingHours.exceptional[fieldIndex].date
      );
      if (e.target.checked) {
        date.setHours(defaultOperatingTime.startTime?.getHours() ?? 0);
        date.setMinutes(defaultOperatingTime.startTime?.getMinutes() ?? 0);
        setValue(startTimeState.name, date);
        date.setHours(defaultOperatingTime.endTime?.getHours() ?? 23);
        date.setMinutes(defaultOperatingTime.endTime?.getMinutes() ?? 59);
        setValue(endTimeState.name, date);
      } else {
        date.setHours(0);
        date.setMinutes(0);
        setValue(startTimeState.name, date);
        date.setHours(23);
        date.setMinutes(59);
        setValue(endTimeState.name, date);
      }
    },
    [
      getValues,
      fieldIndex,
      defaultOperatingTime,
      setValue,
      startTimeState.name,
      endTimeState.name,
    ]
  );

  return (
    <Grid container spacing={4} marginTop={6}>
      <Grid item xs={1.75}>
        <Controller
          control={control}
          render={({ field: { value, onChange } }) => (
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex',
                height: 50,
                ml: -2,
              }}
            >
              <Switch
                checked={value}
                disabled={disabled}
                onChange={(e) => {
                  onChange(e);
                  setTimesOnSwitchChange(e);
                  trigger([startTimeState.name, endTimeState.name]);
                }}
              />
              <Typography sx={{ opacity: !disabled ? 1 : 0.5 }}>
                {t(
                  `exceptionalHoursInputLabels.${
                    isOperatingValue ? 'switchOn' : 'switchOff'
                  }`
                )}
              </Typography>
            </Box>
          )}
          name={`${fieldNameStringPrefix}.isOperating`}
        />
      </Grid>
      <Grid item xs={3.35}>
        <Controller
          control={control}
          render={({ field: { value, onChange, onBlur } }) => (
            <DatePicker
              label={t('exceptionalHoursInputLabels.date')}
              value={value}
              inputFormat="dd.MM.yyyy"
              minDate={tomorrow}
              shouldDisableDate={shouldDisableDate}
              disabled={disabled}
              onChange={(e) => {
                onChange(e);
                trigger(`workingHours.exceptional`);
              }}
              components={{
                OpenPickerIcon: IconCalendar,
              }}
              renderInput={(props) => (
                <ESTextField
                  {...props}
                  required
                  onChange={(e) => {
                    if (!e || !props.onChange) {
                      return;
                    }
                    props.onChange(e);
                  }}
                  value={props.value as string | undefined}
                  onBlur={onBlur}
                  error={dateState.error}
                  helperText={dateState.helperText}
                />
              )}
            />
          )}
          name={dateState.name}
        />
      </Grid>
      <Grid item xs={3}>
        <Controller
          control={control}
          render={({ field: { value, onChange, onBlur } }) => (
            <TimePicker
              label={t('exceptionalHoursInputLabels.startTime')}
              value={applyTimeToToday(value)}
              ampm={false}
              maxTime={subMinutes(endTimeValue, 5)}
              disabled={!isOperatingValue || disabled}
              onChange={(newTime) => {
                onChange(newTime);
                trigger(endTimeState.name);
                onBlur();
              }}
              renderInput={({ inputProps, ...rest }) => (
                <TextField
                  {...rest}
                  variant="outlined"
                  size="small"
                  onBlur={onBlur}
                  required={isOperatingValue}
                  disabled={!isOperatingValue || disabled}
                  error={startTimeState.error}
                  helperText={startTimeState.helperText}
                  inputProps={{
                    ...inputProps,
                    placeholder: 'hh:mm',
                  }}
                />
              )}
            />
          )}
          name={startTimeState.name}
        />
      </Grid>
      <Grid item xs={3}>
        <Controller
          control={control}
          render={({ field: { value, onChange, onBlur } }) => (
            <TimePicker
              label={t('exceptionalHoursInputLabels.endTime')}
              value={applyTimeToToday(value)}
              ampm={false}
              minTime={addMinutes(startTimeValue, 5)}
              disabled={!isOperatingValue || disabled}
              onChange={(newTime) => {
                onChange(newTime);
                trigger(startTimeState.name);
                onBlur();
              }}
              renderInput={({ inputProps, ...rest }) => (
                <TextField
                  {...rest}
                  variant="outlined"
                  size="small"
                  onBlur={onBlur}
                  required={isOperatingValue}
                  disabled={!isOperatingValue || disabled}
                  error={endTimeState.error}
                  helperText={endTimeState.helperText}
                  inputProps={{
                    ...inputProps,
                    placeholder: 'hh:mm',
                  }}
                />
              )}
            />
          )}
          name={endTimeState.name}
        />
      </Grid>
      <Grid item xs={0.8}>
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            height: 50,
          }}
        >
          <IconButton
            disabled={disabled}
            onClick={() => {
              onRemove(fieldIndex);
              trigger('workingHours.exceptional');
            }}
          >
            <IconTrash
              color={palette.error.main}
              opacity={!disabled ? 1 : 0.5}
            />
          </IconButton>
        </Box>
      </Grid>
    </Grid>
  );
};

// Needed for keyboard input to work properly in TimePicker
// https://github.com/mui/mui-x/issues/6587
// Fixed in next major version of @mui/x-date-pickers (v.6)
const applyTimeToToday = (value: Date | null) => {
  if (!value) {
    return value;
  } else {
    const today = new Date();
    today.setHours(value.getHours());
    today.setMinutes(value.getMinutes());
    today.setSeconds(0);
    today.setMilliseconds(0);

    return today;
  }
};
