import { Button, Popover } from '@mui/material';
import { IconCalendarTime } from '@tabler/icons-react';
import React from 'react';

interface TableDateFilterButtonProps {
  title: string | React.ReactNode;
  isActive?: boolean;
  PickerComponent: React.ReactNode;
  anchorEl: HTMLButtonElement | null;
  popoverOpen: boolean;
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  onClose: () => void;
  testId?: string;
}

export const TableDateFilterButton: React.FC<TableDateFilterButtonProps> = ({
  title,
  isActive,
  PickerComponent,
  onClick,
  anchorEl,
  popoverOpen,
  onClose,
  testId,
}) => {
  return (
    <>
      <Button
        onClick={onClick}
        variant="outlined"
        disableRipple
        data-testid={testId ? `${testId}DateRangeFilterButton` : undefined}
        sx={{
          textTransform: 'none',
          borderRadius: 3,
          color: ({ palette }) =>
            isActive ? palette.primary.main : palette.text.secondary,
          '&.MuiButton-outlined': {
            borderColor: ({ palette }) =>
              isActive ? palette.primary.main : palette.grey[300],
          },
          '&:focus': {
            borderColor: ({ palette }) => palette.primary.main,
            color: ({ palette }) => palette.primary.main,
          },
          transition: 'none',
        }}
      >
        {title}
        <IconCalendarTime style={{ marginLeft: 10, width: 20, height: 20 }} />
      </Button>

      <Popover
        open={popoverOpen}
        anchorEl={anchorEl}
        onClose={onClose}
        anchorPosition={{
          top: anchorEl ? anchorEl.getBoundingClientRect().bottom + 13 : 0,
          left: anchorEl ? anchorEl.getBoundingClientRect().left : 0,
        }}
        PaperProps={{
          sx: {
            boxShadow: '0px 2px 1px -1px rgba(56, 67, 107, 0.2)',
          },
        }}
        anchorReference="anchorPosition"
      >
        {PickerComponent}
      </Popover>
    </>
  );
};
