import { Container, SxProps, Theme } from '@mui/material';

interface FormStepContentContainerProps {
  children: React.ReactNode;
  sx?: SxProps<Theme> | undefined;
}

export const FormStepContentContainer: React.FC<
  FormStepContentContainerProps
> = ({ children, sx }) => {
  return (
    <Container maxWidth={'sm'} sx={{ mx: 'auto', ...sx }}>
      {children}
    </Container>
  );
};
