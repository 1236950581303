import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export const CONFIGURATION201_FEATURE_KEY = 'configurationState201';

export interface Configuration201State {
  configurationCounter: number;
}

const initialState: Configuration201State = {
  configurationCounter: 0,
};

export const configuration201Slice = createSlice({
  name: CONFIGURATION201_FEATURE_KEY,
  initialState,
  reducers: {
    setConfigurationCounter: (state, { payload }: PayloadAction<number>) => {
      state.configurationCounter = payload;
    },
  },
});

export const { setConfigurationCounter } = configuration201Slice.actions;

export const configuration201Reducer = configuration201Slice.reducer;
