import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const Type2MaleIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props}>
      <path d="M9.77462 13.6517C9.02052 13.6517 8.4072 14.265 8.4072 15.0191C8.4072 15.7733 9.02052 16.3866 9.77462 16.3866C10.5287 16.3866 11.1421 15.7733 11.1421 15.0191C11.1421 14.2654 10.5287 13.6517 9.77462 13.6517Z" />
      <path d="M7.61925 9.75555C6.86515 9.75555 6.25183 10.3685 6.25183 11.1222C6.25183 11.8763 6.86515 12.4896 7.61925 12.4896C8.37336 12.4896 8.98667 11.8763 8.98667 11.1222C8.98667 10.3689 8.37336 9.75555 7.61925 9.75555Z" />
      <path d="M13.4199 11.1226C13.4199 10.3689 12.807 9.75595 12.0533 9.75595C11.2992 9.75595 10.6859 10.3689 10.6859 11.1226C10.6859 11.8767 11.2992 12.49 12.0533 12.49C12.8066 12.49 13.4199 11.8763 13.4199 11.1226Z" />
      <path d="M9.77461 8.6701C10.1367 8.6701 10.4303 8.37653 10.4303 8.01439C10.4303 7.65225 10.1367 7.35867 9.77461 7.35867C9.41247 7.35867 9.1189 7.65225 9.1189 8.01439C9.1189 8.37653 9.41247 8.6701 9.77461 8.6701Z" />
      <path d="M14.1071 8.66972C14.4692 8.66972 14.7636 8.37569 14.7636 8.014C14.7636 7.6523 14.4692 7.35828 14.1071 7.35828C13.7458 7.35828 13.4514 7.6523 13.4514 8.014C13.4514 8.37569 13.7458 8.66972 14.1071 8.66972Z" />
      <path d="M14.0609 13.6517C13.3071 13.6517 12.6942 14.265 12.6942 15.0191C12.6942 15.7733 13.3071 16.3866 14.0609 16.3866C14.815 16.3866 15.4283 15.7733 15.4283 15.0191C15.4283 14.2654 14.815 13.6517 14.0609 13.6517Z" />
      <path d="M18.7306 6.39261C18.0924 5.41449 14.3829 5.21381 12.0401 5.19086C11.6484 5.16792 11.2214 5.19086 10.7605 5.19086C8.58882 5.19086 5.88313 5.49538 5.27564 6.38795C4.38853 7.69004 3.92105 9.23948 3.92105 10.869C3.92105 15.3249 7.54575 18.9503 12.0016 18.9503C16.4574 18.9503 20.0828 15.3249 20.0828 10.869C20.0828 9.27332 19.6278 7.76704 18.7306 6.39261ZM14.1071 6.69947C14.8325 6.69947 15.4225 7.28907 15.4225 8.01401C15.4225 8.73895 14.8325 9.32854 14.1071 9.32854C13.3822 9.32854 12.7926 8.73895 12.7926 8.01401C12.7926 7.28945 13.3822 6.69947 14.1071 6.69947ZM9.77462 6.69947C10.4996 6.69947 11.0892 7.28907 11.0892 8.01401C11.0892 8.73895 10.4996 9.32854 9.77462 9.32854C9.04968 9.32854 8.46009 8.73895 8.46009 8.01401C8.4597 7.28945 9.04968 6.69947 9.77462 6.69947ZM5.59261 11.1226C5.59261 10.0056 6.5019 9.09675 7.61925 9.09675C8.73661 9.09675 9.64589 10.0056 9.64589 11.1226C9.64589 12.24 8.73661 13.1493 7.61925 13.1493C6.5019 13.1493 5.59261 12.24 5.59261 11.1226ZM9.77462 17.0462C8.65727 17.0462 7.74798 16.1369 7.74798 15.0196C7.74798 13.9022 8.65727 12.9929 9.77462 12.9929C10.892 12.9929 11.8013 13.9022 11.8013 15.0196C11.8013 16.1369 10.892 17.0462 9.77462 17.0462ZM10.0266 11.1226C10.0266 10.0056 10.9359 9.09675 12.0533 9.09675C13.1702 9.09675 14.0791 10.0056 14.0791 11.1226C14.0791 12.24 13.1702 13.1493 12.0533 13.1493C10.9355 13.1489 10.0266 12.24 10.0266 11.1226ZM14.0609 17.0462C12.9439 17.0462 12.035 16.1369 12.035 15.0196C12.035 13.9022 12.9439 12.9929 14.0609 12.9929C15.1782 12.9929 16.0875 13.9022 16.0875 15.0196C16.0875 16.1369 15.1782 17.0462 14.0609 17.0462ZM16.3842 13.1489C15.2669 13.1489 14.3576 12.2396 14.3576 11.1222C14.3576 10.0053 15.2669 9.09636 16.3842 9.09636C17.5016 9.09636 18.4109 10.0053 18.4109 11.1222C18.4109 12.24 17.5016 13.1489 16.3842 13.1489Z" />
      <path d="M20.1334 5.20329C18.9239 3.34972 12.6433 3.42284 11.9269 3.42284C11.2097 3.40961 5.08973 3.17704 3.72308 5.18385C2.63801 6.77685 2.06552 8.6697 2.06552 10.6567C2.06552 16.0918 6.48633 20.5134 11.9214 20.5134C17.3565 20.5134 21.7781 16.0918 21.7781 10.6567C21.7785 8.71093 21.2255 6.87563 20.1334 5.20329ZM12.0016 19.7468C7.10626 19.7468 3.12454 15.7643 3.12454 10.869C3.12454 9.07962 3.64024 7.37461 4.61759 5.9399C5.84812 4.13222 11.3606 4.34185 12.0066 4.35351C12.6518 4.35351 18.3086 4.2874 19.398 5.95701C20.3815 7.46328 20.8797 9.11618 20.8797 10.869C20.8797 15.7643 16.8972 19.7468 12.0016 19.7468Z" />
      <path d="M16.3842 9.75555C15.6301 9.75555 15.0168 10.3685 15.0168 11.1222C15.0168 11.8763 15.6301 12.4896 16.3842 12.4896C17.1383 12.4896 17.7516 11.8763 17.7516 11.1222C17.7516 10.3689 17.1383 9.75555 16.3842 9.75555Z" />
    </SvgIcon>
  );
};
