import { useOidcUser } from '@axa-fr/react-oidc';
import { CsmsUserDto } from './csmsUserDto';
import { csmsUserModelNormalizer } from './normalizers/csmsUserModelNormalizer';

export const useCSMSUserInfo = () => {
  const userInfo = useOidcUser();
  const userDto = userInfo.oidcUser as unknown as CsmsUserDto | null;
  const user = userDto ? csmsUserModelNormalizer(userDto) : null;

  return {
    user,
    loadingState: userInfo.oidcUserLoadingState,
  };
};
