import { ESFullScreenDialog } from '@energy-stacks/core/ui';
import { EditConfigurationOverview } from './EditConfigurationOverview';

export const EditConfiguration = () => {
  return (
    <ESFullScreenDialog isOpen>
      <EditConfigurationOverview />
    </ESFullScreenDialog>
  );
};
