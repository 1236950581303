import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import { forwardRef, ReactElement, Ref } from 'react';
import { Dialog } from '@mui/material';

interface ESFullScreenDialogProps {
  isOpen: boolean;
  children: React.ReactNode;
  onExited?: () => void;
}

export const ESFullScreenDialog: React.FC<ESFullScreenDialogProps> = ({
  children,
  isOpen,
  onExited,
}) => {
  return (
    <Dialog
      fullScreen
      open={isOpen}
      TransitionComponent={Transition}
      TransitionProps={{
        onExited,
      }}
      // TODO: Custom theme needed. MUI defaults not cover this light/dark color from the design
      PaperProps={{ sx: { bgcolor: 'background.paper' } }}
    >
      {children}
    </Dialog>
  );
};

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: ReactElement;
  },
  ref: Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});
