import {
  ESButton,
  ESDialogActions,
  ESDialogContent,
  ESDialogTitle,
} from '@energy-stacks/core/ui';
import { Dialog, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface ESErrorDialogProps {
  open: boolean;
  onCancel: () => void;
  onTryAgain: () => void;
  errorMessage?: string;
}

export const ESErrorDialog: React.FC<ESErrorDialogProps> = ({
  open,
  onCancel,
  onTryAgain,
  errorMessage,
}) => {
  const { t } = useTranslation('shared');

  return (
    <Dialog
      open={open}
      PaperProps={{
        sx: {
          minWidth: 428,
        },
      }}
    >
      <ESDialogTitle>{t('errorDialogTitle')}</ESDialogTitle>
      <ESDialogContent>
        <Typography variant="body1">
          {errorMessage ? errorMessage : t('errorDialogContent')}
        </Typography>
      </ESDialogContent>
      <ESDialogActions>
        <ESButton variant="text" color="error" onClick={onCancel}>
          {t('cancel')}
        </ESButton>
        <ESButton onClick={onTryAgain}>{t('tryAgain')}</ESButton>
      </ESDialogActions>
    </Dialog>
  );
};
