import { CloseRounded } from '@mui/icons-material';
import {
  IconButton,
  InputAdornment,
  TextField,
  TextFieldProps,
} from '@mui/material';
import { Control, Controller, useFieldArray } from 'react-hook-form';
import { ConfigurationFormData } from './ConfigurationTable';

type NewConfigurationFieldProps = TextFieldProps & {
  control: Control<ConfigurationFormData, object>;
  index: number;
};

export const NewConfigurationField: React.FC<NewConfigurationFieldProps> = ({
  control,
  index,
  placeholder,
  ...rest
}) => {
  const { fields, update } = useFieldArray({
    control,
    name: 'configuration',
  });
  return (
    <Controller
      control={control}
      render={({ field: { onChange, onBlur, value } }) => {
        const handleValueChange = (newValue: string) => {
          onChange(newValue);
          update(index, {
            ...fields[index],
            newValue: newValue,
            key: fields[index].key,
          });
        };

        return (
          <TextField
            variant="outlined"
            size="small"
            value={value}
            placeholder={placeholder}
            fullWidth
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    edge="end"
                    aria-label="clear"
                    sx={{ visibility: value ? 'visible' : 'hidden' }}
                    onClick={() => {
                      handleValueChange('');
                    }}
                  >
                    <CloseRounded />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            onChange={(e) => {
              const newValue = e.target.value;
              handleValueChange(newValue);
            }}
            onBlur={onBlur}
            {...rest}
          />
        );
      }}
      name={`configuration.${index}.newValue`}
    />
  );
};
