import { ESVirtualizedAutocomplete } from '@energy-stacks/core/ui';
import { useGetCentralSystemsQuery } from '@energy-stacks/broker/feature-system-preferences-data';
import { useTranslation } from 'react-i18next';

interface CentralSystemFieldProps {
  value: { label: string; value: string } | undefined;
  onChange: (value: { label: string; value: string }) => void;
  onBlur: () => void;
  error: boolean;
  disabled?: boolean;
  helperText: React.ReactNode;
  testId?: string;
}

export const CentralSystemField: React.FC<CentralSystemFieldProps> = ({
  value,
  onBlur,
  onChange,
  error,
  disabled,
  helperText,
  testId,
}) => {
  const { data: centralSystems, isLoading } = useGetCentralSystemsQuery();

  const [t] = useTranslation('chargingStations');

  return (
    <ESVirtualizedAutocomplete
      testId={testId}
      data-testid={testId}
      value={{
        value: value?.value ?? '',
        label: value?.label ?? '',
      }}
      options={
        centralSystems?.map((centralSystem) => ({
          value: centralSystem.uuid,
          label: centralSystem.displayName,
        })) ?? []
      }
      label={t('centralSystem')}
      required
      error={error}
      helperText={helperText}
      loading={isLoading}
      disabled={disabled}
      onChange={(_, value) => {
        if (Array.isArray(value)) {
          return;
        }
        if (value) {
          onChange({ label: value.label, value: value.value });
        }
      }}
      onBlur={onBlur}
    />
  );
};
