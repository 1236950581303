import {
  selectAllStations,
  useChargingStations,
} from '@energy-stacks/broker/feature-charging-stations-data';
import { useSelector } from 'react-redux';
import { ChargingStationsTable } from './charging-stations-table/ChargingStationsTable';

export const ChargingStations = () => {
  useChargingStations();
  const chargingStations = useSelector(selectAllStations);

  return (
    <ChargingStationsTable
      testId="chargingStations"
      chargingStations={chargingStations}
    />
  );
};
