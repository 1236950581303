import { Box, Typography } from '@mui/material';

interface NoDataProps {
  title: string;
  subtitle?: string;
  ActionComponent?: React.ReactNode;
}

export const NoData: React.FC<NoDataProps> = ({
  title,
  subtitle,
  ActionComponent,
}) => {
  return (
    <Box mt={24} textAlign="center">
      <Box sx={{ mb: 11 }}>
        <Typography variant="h4">{title}</Typography>
        {subtitle ? (
          <Typography variant="subtitle1" color={'text.secondary'}>
            {subtitle}
          </Typography>
        ) : null}
      </Box>
      {ActionComponent}
    </Box>
  );
};
