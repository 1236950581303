import { TourModel } from '@energy-stacks/fleet/feature-tours-data';
import { formatIntervalDuration } from '@energy-stacks/fleet/shared';
import { Box, Typography } from '@mui/material';

export interface TourDurationCellProps {
  startTime: TourModel['startDate'];
  endTime: TourModel['endDate'];
  distance: TourModel['duration']['distance'];
}

export const ToursDurationCell: React.FC<TourDurationCellProps> = ({
  startTime,
  endTime,
  distance,
}) => {
  return (
    <Typography variant="inherit" color="grey.900">
      {formatIntervalDuration(startTime, endTime)}
      <Box component="span" sx={{ mx: 1 }}>
        •
      </Box>
      {`${distance.toFixed(1)} km`}
    </Typography>
  );
};
