import { ESButton } from './ESButton';
import { ButtonProps, Typography } from '@mui/material';

type ESTextButtonProps = Pick<ButtonProps, 'sx'> & {
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  children: React.ReactNode;
  disabled?: boolean;
  testId?: string;
};

// Clickable text. Preserves button sizing to fit layouts properly when placed next to other buttons.
export const ESTextButton: React.FC<ESTextButtonProps> = ({
  onClick,
  children,
  disabled,
  sx,
  testId,
}) => {
  return (
    <ESButton
      data-testid={testId}
      variant="text"
      size="small"
      color="inherit"
      disabled={disabled}
      sx={{
        textTransform: 'none',
        minWidth: 'initial',
        '&.MuiButtonBase-root:hover': {
          bgcolor: 'transparent',
        },
        ...sx,
      }}
      onClick={onClick}
      disableRipple
    >
      <Typography
        color="primary"
        fontWeight={({ typography }) => typography.fontWeightMedium}
      >
        {children}
      </Typography>
    </ESButton>
  );
};
