import { useCallback, useState } from 'react';
import { ESToggleButtonGroup } from '@energy-stacks/core/ui';
import { IconMenu } from '@tabler/icons-react';
import { IconMenu2 } from '@tabler/icons-react';
import { useTranslation } from 'react-i18next';

export type TourDetailsJobsTableViewMode = 'standard' | 'compact';

type TourDetailsJobsTableViewModeToggleProps = {
  onTableViewModeChange: (tableViewMode: TourDetailsJobsTableViewMode) => void;
  initialTableViewMode?: TourDetailsJobsTableViewMode;
};

export const TourDetailsJobsTableViewModeToggle: React.FC<
  TourDetailsJobsTableViewModeToggleProps
> = ({ onTableViewModeChange, initialTableViewMode = 'standard' }) => {
  const [t] = useTranslation('tours');
  const [viewMode, setViewMode] = useState<TourDetailsJobsTableViewMode>(
    () => initialTableViewMode
  );

  const handleTableViewModeChange = useCallback(
    (value: TourDetailsJobsTableViewMode) => {
      setViewMode(value);
      onTableViewModeChange(value);
    },
    [onTableViewModeChange]
  );

  return (
    <ESToggleButtonGroup<TourDetailsJobsTableViewMode>
      value={viewMode}
      onChange={handleTableViewModeChange}
      testId="tourDetailsJobsTableViewMode"
      enforceValueSet
      toggleOptions={[
        {
          value: 'standard',
          icon: <IconMenu2 stroke={2} />,
          tooltipText: t('tourDetailsJobsTableViewMode.standard'),
        },
        {
          value: 'compact',
          icon: <IconMenu stroke={2} />,
          tooltipText: t('tourDetailsJobsTableViewMode.compact'),
        },
      ]}
    />
  );
};
