import { TourModel } from './tourModel';

export type EditTourNoteRequestParam =
  | {
      value: string;
      resetValue: false;
    }
  | {
      value: null;
      resetValue: true;
    };

export const editTourNoteDataToPayload = (
  note: TourModel['note']
): EditTourNoteRequestParam => {
  return note.trim()
    ? {
        value: note,
        resetValue: false,
      }
    : {
        value: null,
        resetValue: true,
      };
};
