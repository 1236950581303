import { CSOperationStatusDto } from '../chargingStationDto';
import {
  OperationalStatus,
  OperationalStatusEnum,
} from '../operationalStatusModel';

export const chargingStationOperationalStatusNormalizer = (
  operationalStatus: CSOperationStatusDto
): OperationalStatus => {
  return operationalStatusMap[operationalStatus];
};

const operationalStatusMap: Record<CSOperationStatusDto, OperationalStatus> = {
  AVAILABLE: OperationalStatusEnum.available,
  BLOCKED: OperationalStatusEnum.blocked,
  CHARGING: OperationalStatusEnum.charging,
  OUTOFORDER: OperationalStatusEnum.outOfOrder,
};

export const operationalStatusToCSOperationStatusDto: Record<
  OperationalStatus,
  CSOperationStatusDto | undefined
> = {
  [OperationalStatusEnum.available]: 'AVAILABLE',
  [OperationalStatusEnum.blocked]: 'BLOCKED',
  [OperationalStatusEnum.charging]: 'CHARGING',
  [OperationalStatusEnum.outOfOrder]: 'OUTOFORDER',
  [OperationalStatusEnum.unknown]: undefined,
};
