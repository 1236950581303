import {
  Badge,
  Box,
  ListItemButton,
  ListItemText,
  Typography,
} from '@mui/material';
import { forwardRef, useMemo } from 'react';
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps,
  useLocation,
} from 'react-router-dom';
import { useAppSelector } from '@energy-stacks/store';

interface SidebarItemProps {
  itemLabel: string;
  path: string;
  groupSidebarItem?: boolean;
  showBadge?: boolean;
}

export const SidebarItem: React.FC<SidebarItemProps> = ({
  itemLabel,
  path,
  groupSidebarItem = false,
  showBadge,
}) => {
  const location = useLocation();
  const sidebarOpened = useAppSelector((state) => state.sidebar.sidebarOpened);
  const itemSelected = useMemo(
    () => location.pathname.includes(path),
    [location.pathname, path]
  );

  const renderLink = useMemo(
    () =>
      forwardRef<HTMLAnchorElement, Omit<RouterLinkProps, 'to'>>(function Link(
        itemProps,
        ref
      ) {
        return (
          <RouterLink to={path} ref={ref} {...itemProps} role={undefined} />
        );
      }),
    [path]
  );

  return (
    <ListItemButton
      disableRipple
      component={renderLink}
      sx={(theme) => ({
        py: 0,
        pl: groupSidebarItem && sidebarOpened ? 8 : 4,
        borderRadius: theme.shape.borderRadius,
        mx: 2,
        '&:hover': {
          backgroundColor: 'unset',
        },
      })}
    >
      <Box
        sx={{
          ml: '11px',
          mr: 7,
          height: '50px',
          borderLeftWidth: '1px',
          borderLeftColor: 'divider',
          borderLeftStyle: 'solid',
        }}
      />
      <Badge color="error" badgeContent="" variant="dot" invisible={!showBadge}>
        <ListItemText
          sx={{ mr: 2 }}
          primary={
            <Typography variant={itemSelected ? 'h5' : 'body1'} color="inherit">
              {itemLabel}
            </Typography>
          }
        />
      </Badge>
    </ListItemButton>
  );
};
