import { ESButton } from '@energy-stacks/core/ui';
import { Box, Grid } from '@mui/material';
import React from 'react';
import { IconPlus } from '@tabler/icons-react';
import { useFieldArray } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { BrokerAddChargingStationConnectorItem } from './BrokerAddChargingStationConnectorItem';
import { brokerAddChargingStationDefaultConnector } from './brokerAddChargingStationDefaultConnector';
import { BROKER_ADD_CHARGING_STATION_FORM_HEIGHT } from './BrokerAddChargingStationForm';

export const BrokerAddChargingStationConnectorsForm = () => {
  const [t] = useTranslation('chargingStations');

  const {
    fields: connectors,
    prepend,
    remove,
  } = useFieldArray({
    name: 'connectors',
  });

  return (
    <Box>
      <ESButton
        data-testid="addConnectorButton"
        size="medium"
        variant="outlined"
        startIcon={<IconPlus />}
        onClick={() => {
          prepend(brokerAddChargingStationDefaultConnector);
        }}
        sx={{
          '&.MuiButton-outlined': {
            borderColor: (theme) => theme.palette.primary.main,
          },
          mb: 6,
        }}
      >
        {t('addConnector')}
      </ESButton>
      <Box mx={-4}>
        <Grid
          columnSpacing={6}
          container
          sx={{
            maxHeight: `${BROKER_ADD_CHARGING_STATION_FORM_HEIGHT}px`,
            overflow: 'scroll',
            pl: 4,
            pr: 2,
          }}
        >
          {connectors.map((field, index) => {
            return (
              <Grid item xs={12} sm={6} key={field.id} mb={6}>
                <BrokerAddChargingStationConnectorItem
                  testId={`connector${index}`}
                  connectorNumber={connectors.length - index}
                  connectorIndex={index}
                  onRemove={remove}
                />
              </Grid>
            );
          })}
        </Grid>
      </Box>
    </Box>
  );
};
