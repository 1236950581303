import { UserRole as CSMSUserRole } from '@energy-stacks/csms/feature-users-data';
import { CsmsFeature } from './csmsFeature';

export const csmsFeatureAccess: Record<CsmsFeature, CSMSUserRole[]> = {
  userManagement: ['admin', 'superAdmin'],
  userEditing: ['superAdmin'],
  addLocation: ['admin', 'operator'],
  deleteLocation: ['admin'],
  editLocation: ['admin', 'operator', 'owner'],
  addChargingStationGroup: ['admin', 'operator'],
  updateChargingStationGroup: ['admin', 'operator'],
  deleteChargingStationGroup: ['admin', 'operator'],
  getChargingStationGroups: ['admin', 'operator'],
  addMobilityToken: ['admin', 'operator', 'owner'],
  editMobilityToken: ['admin', 'operator', 'owner'],
  addChargingStation: ['admin', 'operator', 'owner'],
  deleteChargingStation: ['admin', 'operator', 'owner'],
  editChargingStation: ['admin', 'operator', 'owner'],
  updateEvse: ['admin', 'operator', 'owner'],
  evseChargingAction: ['admin', 'operator', 'owner'],
  saveConfiguration: ['admin', 'operator', 'owner'],
  resetChargingStation: ['admin', 'operator', 'owner'],
  providerManagement: ['superAdmin'],
  addProvider: ['superAdmin'],
  editProvider: ['superAdmin'],
  accessRequestManagement: ['superAdmin'],
  entityManagement: ['admin'],
  addEntity: ['admin'],
  editEntity: ['admin'],
  editImprint: ['superAdmin'],
  locations: ['admin', 'operator', 'superAdmin'],
  tariffsManagement: ['admin', 'operator', 'owner'],
};
