export const chargingStationApiErrors: Record<string, string> = {
  CHARGING_STATION_NAME_ALREADY_EXISTS: 'chargingStationNameExists',
  CHARGING_STATION_IDENTITY_KEY_ALREADY_EXISTS:
    'chargingStationIdentityKeyExists',
  BAD_REQUEST: 'csmsNotFound',
  NOT_FOUND: 'chargingStationNotFound',
  ALREADY_EXISTS: 'chargingStationExists',
  CHARGING_STATION_IDENTITY_KEY_NOT_FOUND: 'chargingStationIdentityKeyNotFound',
  CHARGING_STATION_NOT_FOUND: 'chargingStationNotFound',
  EVSE_ID_NOT_UNIQUE: 'evseIdAlreadyExists',
  EVSE_ID_DUPLICATES: 'evseIdDuplicates',
};
