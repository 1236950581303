import { usePopover } from '@energy-stacks/core/ui';
import { ExpandLessRounded, ExpandMoreRounded } from '@mui/icons-material';
import { Button, Chip, Popover } from '@mui/material';
import React from 'react';

interface TableFilterButtonProps {
  title: string | React.ReactNode;
  isActive?: boolean;
  renderPopover: (closePopover: () => void) => React.ReactNode;
  appliedFiltersCount?: number;
  testId?: string;
}

export const TableFilterButton: React.FC<TableFilterButtonProps> = ({
  title,
  renderPopover,
  isActive,
  appliedFiltersCount,
  testId,
}) => {
  const { anchorEl, openPopover, closePopover, popoverOpen } = usePopover();

  return (
    <>
      <Button
        data-testid={`${testId}Filter`}
        onClick={openPopover}
        variant="outlined"
        disableRipple
        sx={{
          textTransform: 'none',
          color: ({ palette }) =>
            isActive ? palette.primary.main : palette.text.secondary,
          '&.MuiButton-outlined': {
            borderColor: ({ palette }) =>
              isActive ? palette.primary.main : palette.grey[300],
          },
          '&:focus': {
            borderColor: ({ palette }) => palette.primary.main,
            color: ({ palette }) => palette.primary.main,
          },
          transition: 'none',
        }}
      >
        {title}
        {popoverOpen ? (
          <ExpandLessRounded sx={{ ml: 2, width: 16, height: 16 }} />
        ) : (
          <ExpandMoreRounded sx={{ ml: 2, width: 16, height: 16 }} />
        )}
        {isActive ? (
          <Chip
            sx={{
              width: '20px',
              textAlign: 'center',
              height: '20px',
              ml: 2,
              pointerEvents: 'none',
              borderRadius: 9,
              '& .MuiChip-label': {
                overflow: 'unset',
              },
              fontSize: '12px',
            }}
            size="small"
            label={
              appliedFiltersCount
                ? appliedFiltersCount.toString().padStart(2, '0')
                : `01`
            }
            color="primary"
          />
        ) : null}
      </Button>

      <Popover
        open={popoverOpen}
        anchorEl={anchorEl}
        onClose={closePopover}
        anchorPosition={{
          top: anchorEl ? anchorEl.getBoundingClientRect().bottom + 13 : 0,
          left: anchorEl ? anchorEl.getBoundingClientRect().left : 0,
        }}
        PaperProps={{
          sx: {
            boxShadow: '0px 2px 1px -1px rgba(56, 67, 107, 0.2)',
          },
        }}
        anchorReference="anchorPosition"
      >
        {renderPopover(closePopover)}
      </Popover>
    </>
  );
};
