import { environment } from '@energy-stacks/feature-config';
import { createBaseQuery } from '@energy-stacks/shared';
import { createApi } from '@reduxjs/toolkit/query/react';
import { AddChargingStationGroupFormData } from './addChargingStationGroupFromData';
import { ChargingStationGroupDto } from './chargingStationGroupDto';
import { ChargingStationGroupModel } from './chargingStationGroupModel';
import { EditChargingStationGroupFormData } from './editChargingStationGroupFromData';
import { mapAddChargingStationGroupFormDataToPayload } from './mapAddChargingStationGroupFormDataToPayload';
import { chargingStationGroupsNormalizer } from './normalizers/chargingStationGroupsNormalizer';

export const chargingStationGroupsApi = createApi({
  reducerPath: 'chargingStationGroupsApi',
  baseQuery: createBaseQuery(
    `${environment.ocppServiceUrl}/chargingstation-groups`
  ),
  endpoints: (builder) => ({
    addChargingStationGroup: builder.mutation<
      ChargingStationGroupDto,
      {
        chargingStationGroup: AddChargingStationGroupFormData;
        locationId: ChargingStationGroupModel['locationId'];
      }
    >({
      query: (data) => ({
        url: '/',
        method: 'POST',
        body: mapAddChargingStationGroupFormDataToPayload(data),
      }),
    }),
    editChargingStationGroup: builder.mutation<
      ChargingStationGroupDto,
      {
        chargingStationGroup: EditChargingStationGroupFormData;
        chargingStationGroupUuid: ChargingStationGroupModel['chargingStationGroupUuid'];
      }
    >({
      query: (data) => ({
        url: `/${data.chargingStationGroupUuid}`,
        method: 'PUT',
        body: {
          chargingStationGroupName:
            data.chargingStationGroup.chargingStationGroupName,
        },
      }),
    }),
    deleteChargingStationGroup: builder.mutation<
      { success: boolean },
      ChargingStationGroupModel['chargingStationGroupUuid']
    >({
      query: (chargingStationGroupUuid) => ({
        url: `/${chargingStationGroupUuid}`,
        method: 'DELETE',
      }),
    }),
    getChargingStationGroupsByLocationId: builder.query<
      ChargingStationGroupModel[],
      ChargingStationGroupModel['locationId']
    >({
      query: (locationId) => `/by-location/${locationId}`,
      transformResponse: (chargingStationGroups: ChargingStationGroupDto[]) =>
        chargingStationGroupsNormalizer(chargingStationGroups),
    }),
  }),
});

export const {
  useAddChargingStationGroupMutation,
  useEditChargingStationGroupMutation,
  useDeleteChargingStationGroupMutation,
  useGetChargingStationGroupsByLocationIdQuery,
} = chargingStationGroupsApi;
