export interface ChangeConfigurationRequestDto {
  key: string;
  value: string;
}
export interface ChangeConfigurationRequestFormData {
  body: ChangeConfigurationRequestDto;
  identityKey: string;
}

export enum ChangeConfigurationStatus {
  Accepted,
  Rejected,
  RebootRequired,
  NotSupported,
}

export type ChangeConfigurationStatusDto =
  | 'Accepted'
  | 'Rejected'
  | 'RebootRequired'
  | 'NotSupported';

export interface ChangeConfigurationConfirmationDto {
  status: ChangeConfigurationStatusDto;
}
export interface ChangeConfigurationConfirmation {
  status: ChangeConfigurationStatus;
}
