import { Popover, PopoverOrigin } from '@mui/material';
import { usePopover } from './usePopover';
import React from 'react';

interface ESPopoverProps {
  content?: React.ReactNode;
  anchorOrigin?: PopoverOrigin;
  transformOrigin?: PopoverOrigin;
  children: React.ReactNode;
  breakWord?: boolean;
}

export const ESPopover: React.FC<ESPopoverProps> = ({
  content,
  anchorOrigin = {
    vertical: 'bottom',
    horizontal: 'left',
  },
  transformOrigin = {
    vertical: 'top',
    horizontal: 'left',
  },
  children,
  breakWord = false,
}) => {
  const { anchorEl, openPopover, closePopover, popoverOpen } = usePopover();
  const popoverId = popoverOpen ? 'es-popover' : undefined;

  if (!content) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{children}</>;
  }

  // Sometimes ESPopover will be used to show some info on disabled elements when hovered.
  // For example, a disabled <button> element (independent of Material-UI) does not fire events in a manner to support proper behavior of the Popover.
  // Here is an example from MUI docs where they wrap the element in a span so that events can still be listened to
  // https://mui.com/material-ui/react-tooltip/#disabled-elements, and if element is not disabled we want to keep the accessibility of the element
  const renderChildren = () => {
    return React.Children.map(children, (child) => {
      if (React.isValidElement(child) && child.props.disabled) {
        return (
          <span
            onMouseEnter={openPopover}
            onMouseLeave={closePopover}
            aria-owns={popoverOpen ? popoverId : undefined}
            aria-haspopup="true"
          >
            {child}
          </span>
        );
      }

      return React.cloneElement(child as React.ReactElement, {
        onMouseEnter: openPopover,
        onMouseLeave: closePopover,
        'aria-haspopup': true,
        'aria-owns': popoverOpen ? popoverId : undefined,
      });
    });
  };

  return (
    <>
      <Popover
        open={popoverOpen}
        anchorEl={anchorEl}
        id={popoverId}
        sx={{
          pointerEvents: 'none',
        }}
        PaperProps={{
          sx: {
            boxShadow:
              '0px 1px 10px 0px rgba(0, 0, 0, 0.12), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 2px 4px -1px rgba(0, 0, 0, 0.20)',
            width: 300,
            px: 4,
            py: 2,
            wordBreak: breakWord ? 'break-word' : undefined,
          },
          elevation: 2,
        }}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
        onClose={closePopover}
        disableRestoreFocus
      >
        {content}
      </Popover>
      {renderChildren()}
    </>
  );
};
