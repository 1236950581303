import {
  Autocomplete,
  AutocompleteChangeReason,
  autocompleteClasses,
  AutocompleteProps,
  Box,
  Popper,
  Stack,
  styled,
  Tooltip,
  Typography,
} from '@mui/material';
import React, {
  createContext,
  forwardRef,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { ListChildComponentProps, VariableSizeList } from 'react-window';
import { ESTextField } from './ESTextField';
import { IconChevronDown } from '@tabler/icons-react';

/*
*
*
*
  Long term solution will be wrapper component which will expose "virtualized" prop based on which the wrapper will return either the virtualized list of options or basic MUI select
*
*
*
*/

type Option<T> = {
  label: string;
  description?: string;
} & T;

type ItemDataSet = [React.ReactNode, string, string];

export type ESVirtualizedAutocompleteProps<T> = Omit<
  AutocompleteProps<Option<T>, boolean | undefined, undefined, undefined> & {
    required?: boolean;
    disabled?: boolean;
    label: string;
    autoFocus?: boolean;
    error?: boolean | undefined;
    helperText?: React.ReactNode;
    testId?: string;
  },
  'renderInput'
>;

function shouldDisableClearable<T>(
  value: Option<T> | Option<T>[] | null | undefined
): boolean {
  if (Array.isArray(value)) {
    return !value.length;
  }

  if (value) {
    return Object.values(value).every((value) => !value);
  }

  return false;
}

export const ESVirtualizedAutocomplete = <T,>({
  onChange,
  value,
  label,
  autoFocus,
  error,
  helperText,
  required,
  disabled,
  testId,
  ...props
}: ESVirtualizedAutocompleteProps<T>) => {
  return (
    <Autocomplete
      value={value}
      isOptionEqualToValue={(option, value) => {
        // Initially label is empty and it is not matching with any of options.
        // This prevents warning on the console.
        if (!value.label) {
          return true;
        }
        return option.label === value.label;
      }}
      getOptionLabel={(option) => option.label}
      onChange={onChange}
      renderInput={(params) => (
        <ESTextField
          {...params}
          autoFocus={autoFocus}
          label={label}
          required={required}
          error={error}
          helperText={helperText}
          inputProps={{
            'data-testid': `${testId}Input`,
            ...params.inputProps,
            // Since MUI Autocomplete component doesn't work well with chromium browsers autofill,
            // meaning that it will only open autocomplete without firing any event, this is the workaround for preventing that.
            autoComplete: 'new-password',
          }}
        />
      )}
      renderOption={(props, option) =>
        [props, option.label, option.description] as React.ReactNode
      }
      ListboxComponent={ListboxComponent}
      popupIcon={!disabled ? <IconChevronDown size={20} /> : null}
      //@ts-expect-error Placeholder prop issue
      PopperComponent={StyledPopper}
      disabled={disabled}
      disableClearable={shouldDisableClearable(value)}
      disableListWrap
      fullWidth
      onInputChange={(e, value, reason) => {
        if (reason === 'clear') {
          onChange?.(e, { label: value } as Option<T>, reason);
        }
        if (reason === 'input' && !value) {
          onChange?.(
            e,
            { label: value } as Option<T>,
            reason as AutocompleteChangeReason
          );
        }
      }}
      sx={{
        '& .MuiAutocomplete-clearIndicator': {
          height: 20,
          width: 20,
        },
        '& .MuiAutocomplete-popupIndicator': {
          ml: 2,
          mr: 4,
        },
      }}
      {...props}
      options={(props.options as Option<T>[]).sort((x, y) =>
        x.label.toLowerCase().localeCompare(y.label.toLowerCase())
      )}
    />
  );
};

const OuterElementContext = createContext({});

const OuterElementType = forwardRef<HTMLDivElement>((props, ref) => {
  const outerProps = useContext(OuterElementContext);
  return <div ref={ref} {...props} {...outerProps} />;
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function useResetCache(data: any) {
  const ref = useRef<VariableSizeList>(null);
  useEffect(() => {
    if (ref.current != null) {
      ref.current.resetAfterIndex(0, true);
    }
  }, [data]);
  return ref;
}

// Adapter for react-window
const ListboxComponent = forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLElement>
>(function ListboxComponent(props, ref) {
  const { children, ...other } = props;
  const itemData: ItemDataSet[] = [];
  (children as ItemDataSet[]).forEach((child) => {
    itemData.push(child);
  });

  const itemCount = itemData.length;
  const itemHeight = 44;
  const itemHeightWithDescription = 64;

  const getChildSize = (index: number) => {
    return itemData[index][2] ? itemHeightWithDescription : itemHeight;
  };

  const getHeight = () => {
    const items = itemCount > 8 ? itemData.slice(0, 8) : itemData;
    return items.reduce((acc, next) => {
      if (next[2]) return (acc += itemHeightWithDescription);
      else return (acc += itemHeight);
    }, 0);
  };

  const gridRef = useResetCache(itemCount);

  return (
    <div ref={ref} style={{ padding: 0 }}>
      <OuterElementContext.Provider value={other}>
        {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
        {/* @ts-ignore */}
        <VariableSizeList
          itemData={itemData}
          height={getHeight()}
          width="100%"
          ref={gridRef}
          outerElementType={OuterElementType as any}
          innerElementType="ul"
          itemSize={getChildSize}
          overscanCount={5}
          itemCount={itemCount}
          style={{ overflowX: 'hidden' }}
        >
          {ListItem}
        </VariableSizeList>
      </OuterElementContext.Provider>
    </div>
  );
});

const StyledPopper = styled(Popper)({
  [`& .${autocompleteClasses.listbox}`]: {
    boxSizing: 'border-box',
    padding: 0,
    margin: 0,
    '& ul': {
      padding: 0,
      margin: 0,
    },
  },
});

const ListItem = (props: ListChildComponentProps) => {
  const { data, index, style } = props;
  const dataSet = data[index];

  const [isContentTruncated, setIsContentTruncated] = useState(false);
  const [rowWidth, setRowWidth] = useState<'auto' | '100%'>('auto');
  const listItemRef = useRef<HTMLDivElement>(null);

  const inlineStyle = {
    ...style,
    top: style.top as number,
    width: rowWidth,
  };

  useEffect(() => {
    const list = listItemRef.current?.parentElement;
    const listWidth = list?.offsetWidth;
    const listItemWidth = Number(listItemRef.current?.offsetWidth);
    if (listWidth && listItemWidth > listWidth) {
      setIsContentTruncated(true);
    }
    setRowWidth('100%');
  }, []);

  if (!isContentTruncated) {
    return (
      <Box
        data-testid={dataSet[1]?.replaceAll(' ', '-')}
        component="li"
        ref={listItemRef}
        {...dataSet[0]}
        style={inlineStyle}
      >
        <Stack direction="column">
          <Typography noWrap>{dataSet[1]}</Typography>
          {dataSet[2] && <Typography noWrap>{dataSet[2]}</Typography>}
        </Stack>
      </Box>
    );
  }

  return (
    <Tooltip
      title={
        <>
          <Typography>{dataSet[1]}</Typography>
          <Typography>{dataSet[2]}</Typography>
        </>
      }
      placement="right"
      disableInteractive
      enterDelay={600}
      enterNextDelay={600}
      arrow
      PopperProps={{
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [0, -17],
            },
          },
        ],
      }}
      style={inlineStyle}
    >
      <Box
        data-testid={dataSet[1].replaceAll(' ', '-')}
        ref={listItemRef}
        component="li"
        {...dataSet[0]}
      >
        <Stack direction="column" width="100%">
          <Typography noWrap display="block">
            {dataSet[1]}
          </Typography>
          {dataSet[2] && (
            <Typography noWrap display="block">
              {dataSet[2]}
            </Typography>
          )}
        </Stack>
      </Box>
    </Tooltip>
  );
};
