import { Card, CardProps, useTheme } from '@mui/material';

/**
 * Square paper used to contain table action buttons: filters, search field, action buttons...
 */
export const TableSection: React.FC<CardProps> = ({
  children,
  sx,
  ...rest
}) => {
  const theme = useTheme();

  return (
    <Card
      {...rest}
      variant="elevation"
      sx={{
        borderRadius: 0,
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        p: 4,
        borderBottom: `1px solid ${theme.palette.divider}`,
        ...sx,
      }}
    >
      {children}
    </Card>
  );
};

/**
 * Displayed on top of all tables seen in the EnergyStacks design. Serves as a container for filter button,
 * search field, "add new item" button. It is a simple card with border radius removed at the bottom so it can
 * be seamlessly placed over the table.
 */
export const TableToolbar: React.FC<CardProps> = ({
  children,
  sx,
  ...rest
}) => {
  return (
    <Card
      {...rest}
      variant="elevation"
      sx={{
        backgroundColor: (theme) => theme.palette.background.default,
        borderRadius: 0,
        paddingBottom: 5,
        ...sx,
      }}
    >
      {children}
    </Card>
  );
};
