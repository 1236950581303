import { DateRange } from '@energy-stacks/core/date-range-picker';
import { JobModel } from '@energy-stacks/fleet/feature-jobs-data';
import { FilterFn } from '@tanstack/react-table';
import { areIntervalsOverlapping, isAfter, parseISO } from 'date-fns';

export const jobsTableDueDateFilterFn: FilterFn<JobModel> = (
  row,
  _,
  filterValue: DateRange | undefined
) => {
  if (!filterValue) {
    return true;
  }

  if (
    isAfter(
      parseISO(row.original.earliestStartDate),
      parseISO(row.original.dueDate)
    )
  ) {
    return false;
  }

  return areIntervalsOverlapping(
    {
      start: filterValue.startDate,
      end: filterValue.endDate,
    },
    {
      start: parseISO(row.original.earliestStartDate),
      end: parseISO(row.original.dueDate),
    }
  );
};
