export interface MobilityTokenModel {
  type: MobilityTokenType;
  issuer: string;
  whitelist: MobilityTokenWhitelist;
  uid: string;
  countryCode: string;
  partyId: string;
  contractId: string;
  visualNumber: string;
  groupId: string;
  valid: boolean;
  lastUpdated: string;
  expiryDate: string;
}

export enum MobilityTokenType {
  adHocUser = 'adHocUser',
  appUser = 'appUser',
  other = 'other',
  rfidCard = 'rfidCard',
}

export type MobilityTokenWhitelist =
  | 'always'
  | 'allowed'
  | 'allowedOffline'
  | 'never';
