import { useGetPlantsQuery } from '@energy-stacks/fleet/feature-business-accounts-data';
import { useAppSelector } from '@energy-stacks/fleet/store';

export const useSelectedPlant = () => {
  const selectedPlantId = useAppSelector(
    (state) => state.plant.selectedPlantId
  );

  const { selectedPlant } = useGetPlantsQuery(undefined, {
    selectFromResult: ({ data }) => ({
      selectedPlant: data?.find((plant) => plant.id === selectedPlantId),
    }),
    skip: !selectedPlantId,
  });

  return selectedPlant;
};
