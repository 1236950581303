import {
  appendZToDateString,
  createBaseQuery,
  handleFileDownload,
} from '@energy-stacks/shared';
import { createApi } from '@reduxjs/toolkit/query/react';
import { environment } from '@energy-stacks/feature-config';
import { AlertManagementModel } from './alertsModel';
import { PageAlertManagementEntry } from './pageAlertManagementEntry';
import { alertsManagementNormalizer } from './normalizers/alertNormalizer';
import {
  GetAlertManagementAlertsBody,
  GetDownloadAlertsFile,
} from './getAlertsRequestBody';
import {
  GetAlertsPerChargingStationRequestBody,
  GetDownloadAlertsPerChargingStation,
} from './getAlertsPerChargingStationRequestBody';

export const alertManagementApi = createApi({
  reducerPath: 'alertManagement',
  tagTypes: ['Alerts', 'AlertsPerChargingStation'],
  baseQuery: createBaseQuery(`${environment.ocppServiceUrl}/alert-management`),
  endpoints: (builder) => ({
    getAlerts: builder.query<
      AlertManagementModel,
      GetAlertManagementAlertsBody
    >({
      query: (searchParams) => ({
        url: `/alerts`,
        method: 'GET',
        params: {
          ...searchParams,
          sort: [`${searchParams.sort.id},${searchParams.sort.order}`],
        },
      }),
      providesTags: ['Alerts'],
      transformResponse: (
        response: PageAlertManagementEntry
      ): AlertManagementModel => {
        return alertsManagementNormalizer(response);
      },
    }),
    getAlertsPerChargingStation: builder.query<
      AlertManagementModel,
      GetAlertsPerChargingStationRequestBody
    >({
      query: ({ identityKey, ...rest }) => ({
        url: `/alerts/${identityKey}/search`,
        method: 'GET',
        params: {
          ...rest,
          sort: [`${rest.sort.id},${rest.sort.order}`],
        },
      }),
      providesTags: (_result, _error, args) => [
        {
          type: 'AlertsPerChargingStation',
          id: args.identityKey,
        },
      ],
      transformResponse: (
        response: PageAlertManagementEntry
      ): AlertManagementModel => {
        return alertsManagementNormalizer(response);
      },
    }),
    getAlertsCsv: builder.query<File, { params: GetDownloadAlertsFile }>({
      query: ({ params }) => ({
        url: `/alerts/csv`,
        params: {
          ...params,
          dateFrom: appendZToDateString(params.dateFrom),
          dateTo: appendZToDateString(params.dateTo),
        },
        responseHandler: async (response) => {
          await handleFileDownload(response, 'csv');
        },
      }),
    }),
    getAlertsCsvPerChargingStation: builder.query<
      File,
      GetDownloadAlertsPerChargingStation
    >({
      query: ({ identityKey, ...rest }) => ({
        url: `/alerts/${identityKey}/csv`,
        params: {
          ...rest,
          dateFrom: appendZToDateString(rest.dateFrom),
          dateTo: appendZToDateString(rest.dateTo),
        },
        responseHandler: async (response) => {
          await handleFileDownload(response, 'csv');
        },
      }),
    }),
    getAlertsZip: builder.query<File, { params: GetDownloadAlertsFile }>({
      query: ({ params }) => ({
        url: `/alerts/zip`,
        params: {
          ...params,
          dateFrom: appendZToDateString(params.dateFrom),
          dateTo: appendZToDateString(params.dateTo),
        },
        responseHandler: async (response) => {
          await handleFileDownload(response, 'zip');
        },
      }),
    }),
    getAlertsZipPerChargingStation: builder.query<File, GetDownloadAlertsFile>({
      query: ({ identityKey, ...rest }) => ({
        url: `/alerts/${identityKey}/zip`,
        params: {
          ...rest,
          dateFrom: appendZToDateString(rest.dateFrom),
          dateTo: appendZToDateString(rest.dateTo),
        },
        responseHandler: async (response) => {
          await handleFileDownload(response, 'zip');
        },
      }),
    }),
  }),
});

export const {
  useGetAlertsQuery,
  useLazyGetAlertsCsvQuery,
  useLazyGetAlertsZipQuery,
  useGetAlertsPerChargingStationQuery,
  useLazyGetAlertsCsvPerChargingStationQuery,
  useLazyGetAlertsZipPerChargingStationQuery,
} = alertManagementApi;
