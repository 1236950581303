import { Box, Grid, Paper, Skeleton } from '@mui/material';
import { range } from 'lodash-es';

export const ChargingStationDetailsSkeleton = () => {
  return (
    <Paper
      variant="outlined"
      sx={{
        display: 'flex',
        border: 'none',
        borderRadius: (theme) => theme.spacing(3),
        width: '100%',
        height: '100%',
      }}
    >
      <Grid
        container
        columns={2}
        rowSpacing={6}
        sx={{ pt: 5.5, height: 'fit-content' }}
      >
        <Grid container columns={2} rowSpacing={5} sx={{ p: 5 }}>
          <Grid item xs={2} sx={{ py: 6 }}>
            <Skeleton
              variant="text"
              sx={{
                width: '40%',
              }}
            />
          </Grid>
          {range(0, 14).map((i) => (
            <Grid item key={i} xs={2}>
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
              >
                <Skeleton
                  variant="text"
                  sx={{
                    width: '30%',
                  }}
                />
                <Skeleton
                  variant="text"
                  sx={{
                    width: '20%',
                  }}
                />
              </Box>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Paper>
  );
};
