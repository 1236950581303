import { Backdrop } from '@mui/material';
import React from 'react';
import { ESCircularProgress } from './ESCircularProgress';

interface ESLoaderProps {
  isLoading: boolean;
}

export const ESLoader: React.FC<ESLoaderProps> = ({ isLoading }) => {
  return (
    <Backdrop
      open={isLoading}
      sx={{
        zIndex: 10000,
      }}
    >
      <ESCircularProgress size="large" />
    </Backdrop>
  );
};
