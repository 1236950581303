/**
 * Allows alphanumerical characters (A-Z, a-z, 0-9)
 * Allows   -
 * Allows   _
 * Allows   !
 * Allows   @
 * Allows   #
 * Allows   $
 * Allows   ^
 * Allows   &
 * Allows   *
 * Allows   (
 * Allows   )
 * Allows   =
 * Allows   :
 */

export const EVSE_ID_SPECIAL_CHARACTERS_REGEX = /^[A-Za-z0-9_!@#$^&*()=:-]+$/;
