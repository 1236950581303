import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { BerryThemeProvider } from '@energy-stacks/core/berry-mui';
import { ESSnackbarProvider } from '@energy-stacks/core/ui';
import { CssBaseline, GlobalStyles } from '@mui/material';
import { useAppSelector } from '@energy-stacks/store';
import { environment } from '@energy-stacks/feature-config';

interface ESAppProviderProps {
  children: React.ReactNode;
  hasBorderRadius?: boolean;
}

const GLOBAL_STYLES = {
  body: {
    margin: 0,
  },
};

export const ESAppProvider: React.FC<ESAppProviderProps> = ({
  children,
  hasBorderRadius = true,
}) => {
  const themeMode = useAppSelector((state) => state.theme.themeState);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <BerryThemeProvider mode={themeMode} hasBorderRadius={hasBorderRadius}>
        <ESSnackbarProvider maxSnack={environment.type === 'local' ? 3 : 1}>
          <CssBaseline />
          <GlobalStyles styles={GLOBAL_STYLES} />
          {children}
        </ESSnackbarProvider>
      </BerryThemeProvider>
    </LocalizationProvider>
  );
};
