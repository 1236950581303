import { UserRole } from '../userRole';
import { UserRoleDto } from '../userRoleDto';

export const userRoleNormalizer = (userRole: UserRoleDto): UserRole => {
  return userRoleMap[userRole] ?? 'noRole';
};

const userRoleMap: Record<UserRoleDto, UserRole> = {
  BROKER_ADMIN: 'admin',
  BROKER_USER: 'operator',
};

export const toUserRoleDto = (userRole: UserRole): UserRoleDto => {
  const map: Record<UserRole, UserRoleDto> = {
    admin: 'BROKER_ADMIN',
    operator: 'BROKER_USER',
    noRole: null as unknown as UserRoleDto,
  } as const;

  return map[userRole];
};
