import { formatDurationInSeconds } from '@energy-stacks/fleet/shared';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

type TourDurationCellEmptyRunsProps = {
  emptyRunsCount: number;
  totalEmptyRunsDurationInSeconds: number;
};

export const TourDurationCellEmptyRuns: React.FC<
  TourDurationCellEmptyRunsProps
> = ({ emptyRunsCount, totalEmptyRunsDurationInSeconds }) => {
  const [t] = useTranslation('tours');
  return (
    <Typography variant="inherit" fontWeight={300}>
      {formatDurationInSeconds(totalEmptyRunsDurationInSeconds)}
      <Box component="span" sx={{ mx: 2 }}>
        •
      </Box>
      {t('emptyRunsCount', {
        count: emptyRunsCount,
      })}
    </Typography>
  );
};
