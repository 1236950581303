import { Dialog } from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
  ESDialogTitle,
  ESDialogContent,
  ESDialogActions,
  ESButton,
  useESSnackbar,
} from '@energy-stacks/core/ui';
import {
  CommonDialogProps,
  useChargingStationIdentityKey,
} from '@energy-stacks/shared';
import { useUnlockConnector201Mutation } from '@energy-stacks/broker/feature-charging-station-management-data';
import {
  endChargingStationCoreManagementAction,
  startChargingStationCoreManagementAction,
  useAppDispatch,
} from '@energy-stacks/broker/store';
import { useCallback } from 'react';
import { ConnectorModel } from '@energy-stacks/broker/feature-charging-stations-data';

type UnlockConnectorDialogProps = CommonDialogProps & {
  loading?: boolean;
  testId?: string;
  connectorId: ConnectorModel['connectorId'];
};

export const UnlockConnectorDialog201: React.FC<UnlockConnectorDialogProps> = ({
  isOpen,
  loading,
  onClose,
  testId,
  connectorId,
}) => {
  const { t } = useTranslation('chargingStations');
  const [unlockConnector] = useUnlockConnector201Mutation();

  const identityKey = useChargingStationIdentityKey();
  const { showSnackbar } = useESSnackbar();
  const dispatch = useAppDispatch();

  const handleUnlockConnector = useCallback(() => {
    onClose();
    dispatch(startChargingStationCoreManagementAction());
    unlockConnector({
      identityKey,
      connectorId: connectorId,
      evseId: connectorId,
    })
      .unwrap()
      .then((response) => {
        if (response.status === 'Unlocked') {
          showSnackbar('success', 'connectorUnlocked', 'chargingStations');
          return;
        }
        if (response.status === 'UnlockFailed') {
          showSnackbar('error', 'unlockConnectorFailed', 'chargingStations');
          return;
        }
        if (response.status === 'UnknownConnector') {
          showSnackbar('error', 'unlockConnectorUnknown', 'chargingStations');
          return;
        }
        if (response.status === 'OngoingAuthorizedTransaction') {
          showSnackbar(
            'error',
            'unlockConnectorOngoingAuthorizedTransaction',
            'chargingStations'
          );
          return;
        }
      })
      .catch((error) => {
        if (error.data.errorCode === 'RPC_TIMEOUT') {
          showSnackbar('error', 'rpcTimeoutError', 'chargingStations');
          return;
        }
        if (error.data.errorCode === 'RPC_ERROR') {
          showSnackbar('error', 'rpcError', 'chargingStations');
          return;
        }

        showSnackbar('error');
      })
      .finally(() => {
        dispatch(endChargingStationCoreManagementAction());
      });
  }, [
    connectorId,
    dispatch,
    onClose,
    identityKey,
    showSnackbar,
    unlockConnector,
  ]);

  return (
    <Dialog open={isOpen} onClose={onClose} fullWidth maxWidth="sm">
      <ESDialogTitle data-testid={`unlockConnector${testId}Title`}>
        {t('proceedWithUnlockingConnector')}
      </ESDialogTitle>
      <ESDialogContent data-testid={`unlockConnector${testId}Content`}>
        {t('unlockConnectorConfirmationDialogDescription')}
      </ESDialogContent>
      <ESDialogActions>
        <ESButton
          data-testid={`unlockConnector${testId}KeepItLockedButton`}
          variant="text"
          color="error"
          onClick={onClose}
        >
          {t('keepItLocked')}
        </ESButton>
        <ESButton
          data-testid={`unlockConnector${testId}UnlockButton`}
          variant="contained"
          onClick={handleUnlockConnector}
          loading={loading}
          disabled={loading}
        >
          {t('unlock')}
        </ESButton>
      </ESDialogActions>
    </Dialog>
  );
};
