import { CloseRounded } from '@mui/icons-material';
import { IconButton, InputAdornment, TextField } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

interface ConfigurationStringValueFieldProps {
  handleValueChange: (val: string) => void;
  controlerValue: string;
  maxLimit: number;
}
export const ConfigurationStringValueField: React.FC<
  ConfigurationStringValueFieldProps
> = ({ handleValueChange, controlerValue, maxLimit, ...rest }) => {
  const { t } = useTranslation('chargingStations');
  const [helperText, setHelperText] = useState('');
  return (
    <TextField
      onFocus={() => {
        setHelperText(
          t('maximumCharNumber', {
            maxLimit: maxLimit,
          })
        );
      }}
      onBlur={() => {
        setHelperText('');
      }}
      variant="outlined"
      value={controlerValue}
      placeholder={controlerValue || '-'}
      fullWidth
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              edge="end"
              aria-label="clear"
              sx={{ visibility: controlerValue ? 'visible' : 'hidden' }}
              onClick={() => {
                handleValueChange('');
              }}
            >
              <CloseRounded />
            </IconButton>
          </InputAdornment>
        ),
      }}
      onChange={(e) => {
        const newValue = e.target.value;
        handleValueChange(newValue);
      }}
      {...rest}
      helperText={helperText}
      sx={{ '& .MuiFormHelperText-root': { marginLeft: 0 } }}
    />
  );
};
