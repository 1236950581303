import { businessAccountsApi } from '@energy-stacks/fleet/feature-business-accounts-data';
import { jobsApi } from '@energy-stacks/fleet/feature-jobs-data';
import { toursApi } from '@energy-stacks/fleet/feature-tours-data';
import { usersFleetApi } from '@energy-stacks/fleet/feature-users-data';
import { vehiclesApi } from '@energy-stacks/fleet/feature-vehicles-data';
import {
  AnyAction,
  ThunkAction,
  combineReducers,
  configureStore,
} from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/dist/query';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import {
  APP_FEATURE_KEY,
  appReducer,
  SIDEBAR_FEATURE_KEY,
  USER_FEATURE_KEY,
  userReducer,
  sidebarReducer,
  THEME_FEATURE_KEY,
  themeReducer,
} from '@energy-stacks/store';
import { PLANT_FEATURE_KEY, plantReducer } from './plant.slice';
import { USER_ACCESS_FEATURE_KEY, userAccessReducer } from './userAccess.slice';

const persistConfig = {
  key: 'FLEET',
  storage,
  // Must keep a whitelist value here or
  // the whole store will be persisted
  whitelist: [THEME_FEATURE_KEY, PLANT_FEATURE_KEY, USER_ACCESS_FEATURE_KEY],
};

export const rootReducer = combineReducers({
  [THEME_FEATURE_KEY]: themeReducer,
  [USER_FEATURE_KEY]: userReducer,
  [SIDEBAR_FEATURE_KEY]: sidebarReducer,
  [PLANT_FEATURE_KEY]: plantReducer,
  [APP_FEATURE_KEY]: appReducer,
  [USER_ACCESS_FEATURE_KEY]: userAccessReducer,
  [jobsApi.reducerPath]: jobsApi.reducer,
  [vehiclesApi.reducerPath]: vehiclesApi.reducer,
  [toursApi.reducerPath]: toursApi.reducer,
  [businessAccountsApi.reducerPath]: businessAccountsApi.reducer,
  [usersFleetApi.reducerPath]: usersFleetApi.reducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const initStore = (preloadedState = {}) => {
  return configureStore({
    reducer: persistedReducer,
    preloadedState,
    // Additional middleware can be passed to this array
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: {
          ignoredActions: ['persist/PERSIST'],
        },
      }).concat(
        jobsApi.middleware,
        vehiclesApi.middleware,
        toursApi.middleware,
        businessAccountsApi.middleware,
        usersFleetApi.middleware
      ),
    devTools:
      (typeof process !== 'undefined' &&
        process.env['NODE_ENV'] !== 'production') ||
      !!import.meta,

    // Optional Redux store enhancers
    enhancers: [],
  });
};

export const store = initStore();

export const persistor = persistStore(store);

setupListeners(store.dispatch);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof rootReducer>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  AnyAction
>;
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
