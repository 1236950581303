import { useEffect, useRef } from 'react';
import { groupBy } from 'lodash-es';

const VISIBILITY_THRESHOLD = 0.3;

export const useTableIntersectionObserver = <T>(
  rootSelector: string,
  targetSelector: string,
  onGainedVisibility: (rowIds: Element[]) => void,
  onLostVisibility: (rowIds: Element[]) => void,
  // necessary to re-run the effect when the data changes
  rows: T[],
  enabled: boolean
) => {
  const onLostVisibilityRef = useRef(onLostVisibility);
  const onGainedVisibilityRef = useRef(onGainedVisibility);
  useEffect(() => {
    if (!enabled) {
      return () => {};
    }
    const observer = new IntersectionObserver(
      (entries) => {
        const isIntersecting = groupBy(
          entries,
          (entry) => entry.isIntersecting
        );
        onLostVisibilityRef.current(
          isIntersecting['false']?.map((entry) => entry.target) ?? []
        );
        onGainedVisibilityRef.current(
          isIntersecting['true']?.map((entry) => entry.target) ?? []
        );
      },
      {
        // If null, then screen's viewport is used
        root: document.querySelector(rootSelector),
        threshold: VISIBILITY_THRESHOLD,
      }
    );
    const targetElements = document.querySelectorAll(targetSelector);
    targetElements.forEach((element) => {
      observer.observe(element);
    });

    return () => {
      if (observer) {
        observer.disconnect();
      }
    };
  }, [rootSelector, targetSelector, rows, enabled]);
};
