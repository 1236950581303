import { ESMenu, ESMenuProps } from '@energy-stacks/core/ui';
import React from 'react';

interface MoreOptionsMenuProps extends Omit<ESMenuProps, 'iconColor'> {}

export const MoreOptionsMenu: React.FC<MoreOptionsMenuProps> = ({
  children,
  ...rest
}) => {
  return <ESMenu {...rest}>{children}</ESMenu>;
};
