import { ESScreenDrawer } from '@energy-stacks/feature-screen-drawer';
import { Box, Paper, Skeleton, Typography } from '@mui/material';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import { LocationDetailsMap } from './LocationDetailsMap';
import { LocationDetailsChargingStations } from './LocationDetailsChargingStations';
import { useGetLocationDetailsQuery } from '@energy-stacks/broker/feature-locations-data';
import { useEffect } from 'react';
import { ESMenu, showSnackbar } from '@energy-stacks/core/ui';
import { LocationDetailsDrawerEditMenuItem } from './LocationDetailsDrawerEditMenuItem';
import { LocationDetailsDrawerDeleteMenuItem } from './LocationDetailsDrawerDeleteMenuItem';
import { BrokerRoutes } from '@energy-stacks/broker/shared';
import { BrokerEditLocation } from '../edit-location/BrokerEditLocation';
import { useDialog } from '@energy-stacks/shared';

export const LocationDetailsDrawer = () => {
  const navigate = useNavigate();
  const { locationId } = useParams<{
    locationId: string;
  }>();

  const { data: location, isError } = useGetLocationDetailsQuery(locationId);
  const { dialogProps, open: openEditDialog } = useDialog();

  useEffect(() => {
    if (isError) {
      showSnackbar('error', 'locationDetailsFetchingError', 'locations');
    }
  }, [isError]);

  return (
    <ESScreenDrawer
      width={576}
      onClose={() => navigate(BrokerRoutes.Locations)}
    >
      <Box
        sx={{
          alignItems: 'center',
          backgroundColor: 'background.paper',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          p: 4.5,
          pl: 8,
        }}
      >
        <Typography
          variant="h3"
          textTransform="capitalize"
          sx={{ wordBreak: 'break-all', fontWeight: 700, color: 'dark.main' }}
        >
          {location ? location.name : <Skeleton />}
        </Typography>
        <ESMenu>
          <LocationDetailsDrawerEditMenuItem onClick={openEditDialog} />
          <LocationDetailsDrawerDeleteMenuItem locationId={location?.uuid} />
        </ESMenu>
      </Box>
      <Paper
        sx={{
          backgroundColor: 'background.default',
          border: 'none',
          borderRadius: 'unset',
          height: '100%',
          overflowY: 'auto',
          p: 6,
          pb: 11,
        }}
      >
        {!location ? (
          <>
            <Skeleton
              variant="rectangular"
              height={440}
              width="100%"
              sx={{ mb: 6 }}
            />
            <Skeleton variant="rectangular" height={240} width="100%" />
          </>
        ) : (
          <Box
            sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}
          >
            <LocationDetailsMap
              location={location}
              onEditClick={openEditDialog}
            />
            <LocationDetailsChargingStations
              locationId={location.uuid}
              chargingStations={location.chargingStations}
            />
          </Box>
        )}
      </Paper>
      <Outlet />
      <BrokerEditLocation
        isOpen={dialogProps.isOpen}
        onClose={() => {
          dialogProps.onClose();
        }}
        locationId={locationId}
      />
    </ESScreenDrawer>
  );
};
