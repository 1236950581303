import {
  Grid,
  TextField,
  Typography,
  Switch,
  FormControlLabel,
  Box,
} from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { TimePicker } from '@mui/x-date-pickers';
import { useMondayToFridayChange } from './useMondayToFridayChange';
import { WeekDay } from '@energy-stacks/fleet/shared';
import { addMinutes, subMinutes } from 'date-fns';

type DayWorkingHoursProps = {
  day: WeekDay | 'mondayToFriday';
};

export const DayWorkingHours: FC<DayWorkingHoursProps> = ({ day }) => {
  const [t] = useTranslation('vehicles');
  const {
    control,
    formState: { errors },
    trigger,
  } = useFormContext();

  const { handleMondayToFridayChange, handleMondayToFridayDayOffToggle } =
    useMondayToFridayChange();

  const fieldNameStringPrefix =
    day === 'mondayToFriday'
      ? 'workingHours.regular.MONDAY'
      : `workingHours.regular.${day}`;

  const startTimeState = {
    name: `${fieldNameStringPrefix}.startTime`,
    error:
      day === 'mondayToFriday'
        ? Boolean(errors.workingHours?.regular?.['MONDAY']?.startTime)
        : Boolean(errors.workingHours?.regular?.[day]?.startTime),
    helperText:
      day === 'mondayToFriday'
        ? Boolean(errors.workingHours?.regular?.['MONDAY']?.startTime) &&
          t(
            `vehicleFormErrors.${errors.workingHours?.regular?.['MONDAY']?.startTime?.message}`
          )
        : Boolean(errors.workingHours?.regular?.[day]?.startTime) &&
          t(
            `vehicleFormErrors.${errors.workingHours?.regular?.[day]?.startTime?.message}`
          ),
  };
  const endTimeState = {
    name: `${fieldNameStringPrefix}.endTime`,
    error:
      day === 'mondayToFriday'
        ? Boolean(errors.workingHours?.regular?.['MONDAY']?.endTime)
        : Boolean(errors.workingHours?.regular?.[day]?.endTime),
    helperText:
      day === 'mondayToFriday'
        ? Boolean(errors.workingHours?.regular?.['MONDAY']?.endTime) &&
          t(
            `vehicleFormErrors.${errors.workingHours?.regular?.['MONDAY']?.endTime?.message}`
          )
        : Boolean(errors.workingHours?.regular?.[day]?.endTime) &&
          t(
            `vehicleFormErrors.${errors.workingHours?.regular?.[day]?.endTime?.message}`
          ),
  };

  const startTimeValue = useWatch({ name: startTimeState.name });
  const endTimeValue = useWatch({ name: endTimeState.name });
  const isWorkingDay = useWatch({
    name: `${fieldNameStringPrefix}.isWorkingDay`,
  });

  return (
    <Grid container spacing={6} marginBottom={6}>
      <Grid item xs={3}>
        <Box height={48} display="flex" alignItems="center">
          <Typography>{t(`weekDays.${day}`)}</Typography>
        </Box>
      </Grid>
      <Grid item xs={3.5}>
        <Controller
          control={control}
          render={({ field: { onChange, onBlur, value } }) => {
            return (
              <TimePicker
                value={value}
                ampm={false}
                maxTime={subMinutes(endTimeValue, 5)}
                disabled={!isWorkingDay}
                onChange={(newTime) => {
                  if (day === 'mondayToFriday') {
                    handleMondayToFridayChange(newTime, 'startTime');
                  } else {
                    onChange(newTime);
                  }
                  trigger(endTimeState.name);
                  onBlur();
                }}
                renderInput={({ inputProps, ...rest }) => (
                  <TextField
                    {...rest}
                    variant="outlined"
                    size="small"
                    onBlur={onBlur}
                    disabled={!isWorkingDay}
                    error={startTimeState.error}
                    helperText={startTimeState.helperText}
                    inputProps={{
                      ...inputProps,
                      placeholder: 'hh:mm',
                    }}
                  />
                )}
              />
            );
          }}
          name={startTimeState.name}
        />
      </Grid>
      <Grid item xs={3.5}>
        <Controller
          control={control}
          render={({ field: { onChange, onBlur, value } }) => {
            return (
              <TimePicker
                value={value}
                ampm={false}
                minTime={addMinutes(startTimeValue, 5)}
                disabled={!isWorkingDay}
                onChange={(newTime) => {
                  if (day === 'mondayToFriday') {
                    handleMondayToFridayChange(newTime, 'endTime');
                  } else {
                    onChange(newTime);
                  }
                  trigger(startTimeState.name);
                  onBlur();
                }}
                renderInput={({ inputProps, ...rest }) => (
                  <TextField
                    {...rest}
                    variant="outlined"
                    size="small"
                    disabled={!isWorkingDay}
                    error={endTimeState.error}
                    onBlur={onBlur}
                    helperText={endTimeState.helperText}
                    inputProps={{
                      ...inputProps,
                      placeholder: 'hh:mm',
                    }}
                  />
                )}
              />
            );
          }}
          name={endTimeState.name}
        />
      </Grid>
      <Grid item xs={2}>
        <FormControlLabel
          control={
            <Controller
              control={control}
              render={({ field: { onChange, value } }) => (
                <Box height={48} display="flex" alignItems="center">
                  <Switch
                    checked={value}
                    onChange={(e) => {
                      if (day === 'mondayToFriday' && !e.target.checked) {
                        handleMondayToFridayDayOffToggle(e.target.checked);
                        return;
                      }
                      onChange(e);
                      trigger([startTimeState.name, endTimeState.name]);
                    }}
                  />
                </Box>
              )}
              name={`${fieldNameStringPrefix}.isWorkingDay`}
            />
          }
          label={
            isWorkingDay
              ? t('workingHoursToggleOn')
              : t('workingHoursToggleOff')
          }
        />
      </Grid>
    </Grid>
  );
};
