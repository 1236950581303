import { useCallback, useEffect } from 'react';
import {
  ESDialogActionButton,
  ESDialogActions,
  ESDialogContent,
  ESDialogTitle,
  ESTextField,
  ESVirtualizedAutocomplete,
  useESSnackbar,
} from '@energy-stacks/core/ui';
import { Dialog, Grid } from '@mui/material';
import { Controller, FormProvider, useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import {
  BrokerLocationFormData,
  Country,
  GeoLocation,
  countries,
  useEditBrokerLocationsMutation,
  useGetAllLocationsBrokerQuery,
} from '@energy-stacks/broker/feature-locations-data';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  BrokerAddLocationGoogleMap,
  INITIAL_LATITUDE,
  INITIAL_LONGITUDE,
} from '../shared/BrokerAddLocationGoogleMap';
import { brokerLocationFormValidationSchema } from '../shared/brokerLocationFormValidationSchema';

const editLocationFormDefaultValues: BrokerLocationFormData = {
  uuid: '',
  locationName: '',
  streetNameAndNumber: '',
  city: '',
  zipCode: '',
  state: '',
  country: {
    label: '',
    value: '',
  },
  lat: INITIAL_LATITUDE,
  lng: INITIAL_LONGITUDE,
};

interface BrokerEditLocationProps {
  isOpen?: boolean;
  locationId?: string;
  onClose?: () => void;
}

export const BrokerEditLocation: React.FC<BrokerEditLocationProps> = ({
  isOpen = true,
  locationId,
  onClose,
}) => {
  const { t } = useTranslation('locations');
  const [tShared] = useTranslation('shared');

  const navigate = useNavigate();
  const { showSnackbar } = useESSnackbar();

  const methods = useForm<BrokerLocationFormData>({
    mode: 'onTouched',
    defaultValues: editLocationFormDefaultValues,
    resolver: yupResolver(brokerLocationFormValidationSchema),
  });
  const {
    handleSubmit,
    control,
    formState: { isValid, isDirty, errors },
    setValue,
    reset: resetForm,
  } = methods;
  const lat = useWatch({ control, name: 'lat' });
  const lng = useWatch({ control, name: 'lng' });

  const [editLocation, { isLoading }] = useEditBrokerLocationsMutation();

  const handleCoordsChange = (newCoords: GeoLocation) => {
    setValue('lat', newCoords.latitude.toString().slice(0, 10), {
      shouldValidate: true,
      shouldDirty: true,
    });
    setValue('lng', newCoords.longitude.toString().slice(0, 11), {
      shouldValidate: true,
      shouldDirty: true,
    });
  };

  const { locationUuid } = useParams<{ locationUuid: string }>();
  const { location, isSuccess } = useGetAllLocationsBrokerQuery(undefined, {
    selectFromResult: ({ data, isSuccess }) => ({
      location: data?.find(
        (location) =>
          location.uuid === locationUuid || location.uuid === locationId
      ),
      isSuccess,
    }),
  });

  const closeDialog = useCallback(() => {
    onClose ? onClose() : navigate(-1);
  }, [navigate, onClose]);

  // if location extracted from url doesn't exist, show error message and navigate to locations table
  useEffect(() => {
    if (isSuccess && !location) {
      showSnackbar('error', 'locationDetailsFetchingError', 'locations');
      closeDialog();
    }
  }, [isSuccess, location, navigate, showSnackbar, t, closeDialog]);

  useEffect(() => {
    if (!location) {
      return;
    }

    resetForm({
      uuid: location.uuid,
      locationName: location.name,
      city: location.city,
      lat: location.coordinates.latitude,
      lng: location.coordinates.longitude,
      state: location.state,
      streetNameAndNumber: location.address,
      zipCode: location.postalCode,
      country: {
        label: location.country.label ? t(location.country.label) : '',
        value: location.country.value,
      },
    });
  }, [location, resetForm, setValue, locationUuid, t]);

  const onSubmit = (data: BrokerLocationFormData) => {
    editLocation(data)
      .unwrap()
      .then(() => {
        showSnackbar('success', 'editBrokerLocationSuccess', 'locations');
        closeDialog();
      })
      .catch(() => {
        showSnackbar('error');
      });
  };

  return (
    <FormProvider {...methods}>
      <Dialog open={isOpen} onClose={closeDialog} maxWidth="lg" fullWidth>
        <ESDialogTitle>{t('editLocationTitle')}</ESDialogTitle>
        <form>
          <ESDialogContent sx={{ flexGrow: 1 }}>
            <Grid container spacing={6}>
              <Grid item xs={6}>
                <Grid container spacing={6}>
                  <Grid item xs={12}>
                    <Controller
                      control={control}
                      rules={{ required: true }}
                      render={({ field: { onChange, onBlur, value } }) => {
                        return (
                          <ESTextField
                            inputProps={{
                              'data-testid': `chargingSiteNameInput`,
                            }}
                            label={t('locationName')}
                            required
                            value={value}
                            onChange={onChange}
                            onBlur={onBlur}
                            error={Boolean(errors.locationName)}
                            helperText={
                              Boolean(errors.locationName) &&
                              t(`${errors.locationName?.message}`)
                            }
                          />
                        );
                      }}
                      name={'locationName'}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Controller
                      control={control}
                      render={({ field: { onChange, onBlur, value } }) => {
                        return (
                          <ESTextField
                            inputProps={{
                              'data-testid': `streetNameAndNumberInput`,
                            }}
                            label={t('streetNameAndNumber')}
                            value={value}
                            onChange={onChange}
                            onBlur={onBlur}
                            error={Boolean(errors.streetNameAndNumber)}
                            helperText={
                              Boolean(errors.streetNameAndNumber) &&
                              t(`${errors.streetNameAndNumber?.message}`)
                            }
                          />
                        );
                      }}
                      name={'streetNameAndNumber'}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Controller
                      control={control}
                      render={({ field: { onChange, onBlur, value } }) => {
                        return (
                          <ESTextField
                            inputProps={{
                              'data-testid': `zipCodeInput`,
                            }}
                            label={t('zipCode')}
                            value={value}
                            onChange={onChange}
                            onBlur={onBlur}
                            error={Boolean(errors.zipCode)}
                            helperText={
                              Boolean(errors.zipCode) &&
                              t(`${errors.zipCode?.message}`)
                            }
                          />
                        );
                      }}
                      name={'zipCode'}
                    />
                  </Grid>
                  <Grid item xs={8}>
                    <Controller
                      control={control}
                      render={({ field: { onChange, onBlur, value } }) => {
                        return (
                          <ESTextField
                            inputProps={{
                              'data-testid': `cityInput`,
                            }}
                            label={t('city')}
                            value={value}
                            onChange={onChange}
                            onBlur={onBlur}
                            error={Boolean(errors.city)}
                            helperText={
                              Boolean(errors.city) &&
                              t(`${errors.city?.message}`)
                            }
                          />
                        );
                      }}
                      name={'city'}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Controller
                      control={control}
                      render={({ field: { onChange, onBlur, value } }) => {
                        return (
                          <ESTextField
                            inputProps={{
                              'data-testid': `stateInput`,
                            }}
                            label={t('state')}
                            value={value}
                            onChange={onChange}
                            onBlur={onBlur}
                            error={Boolean(errors.state)}
                            helperText={
                              Boolean(errors.state) &&
                              t(`${errors.state?.message}`)
                            }
                          />
                        );
                      }}
                      name={'state'}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Controller
                      control={control}
                      render={({ field: { onChange, onBlur, value } }) => {
                        return (
                          <ESVirtualizedAutocomplete<Country>
                            testId="countryInput"
                            label={t('country')}
                            value={{
                              value: value.value,
                              label: value.label,
                            }}
                            options={countries.map((country) => ({
                              label: t(country.label),
                              value: country.value,
                            }))}
                            noOptionsText={t('noCountries')}
                            onChange={(_, value) => {
                              if (Array.isArray(value)) {
                                return;
                              }
                              if (value) {
                                onChange({
                                  label: value.label ?? '',
                                  value: value.value ?? '',
                                });
                              }
                            }}
                            onBlur={onBlur}
                          />
                        );
                      }}
                      name={'country'}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={6}>
                <BrokerAddLocationGoogleMap
                  center={{
                    latitude: lat,
                    longitude: lng,
                  }}
                  onCoordsChange={handleCoordsChange}
                />
              </Grid>
            </Grid>
          </ESDialogContent>
          <ESDialogActions>
            <ESDialogActionButton
              testId="editSiteCancel"
              color="error"
              disabled={isLoading}
              onClick={closeDialog}
            >
              {tShared('cancel')}
            </ESDialogActionButton>
            <ESDialogActionButton
              testId="editSiteSave"
              disabled={!isValid || !isDirty}
              loading={isLoading}
              type="submit"
              variant="contained"
              onClick={handleSubmit(onSubmit)}
            >
              {t('save')}
            </ESDialogActionButton>
          </ESDialogActions>
        </form>
      </Dialog>
    </FormProvider>
  );
};
