export const WelcomeIcon = () => {
  return (
    <svg
      width="237"
      height="236"
      viewBox="0 0 237 236"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M193.704 26.4263C163.051 26.9091 36.7543 27.1717 5.16435 26.7701C3.28859 26.7466 3.14612 23.5497 5.08781 23.5497C37.6516 23.5897 150.222 22.9692 193.643 23.9029C195.733 23.9453 195.166 26.4028 193.704 26.4263Z"
        fill="#364152"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M156.089 118.239C153.974 119.152 151.727 119.721 149.433 119.926C149.653 122.622 149.623 125.334 149.346 128.025C149.315 128.17 149.235 128.3 149.118 128.392C149.001 128.483 148.856 128.53 148.707 128.525C148.559 128.519 148.418 128.462 148.308 128.362C148.197 128.263 148.127 128.127 148.107 127.98C147.887 126.14 148.401 124.146 148.03 120.017C145.276 120.091 143.986 119.237 141.566 118.909C135.549 118.087 129.765 116.035 124.576 112.88C124.421 112.761 124.32 112.587 124.293 112.395C124.267 112.203 124.317 112.007 124.433 111.852C129.758 105.35 133.852 97.9314 136.515 89.9609C136.83 89.0601 137.872 87.8496 137.877 89.4004C137.89 93.3756 137.426 97.3485 137.426 101.33C137.426 108.555 137.618 116.284 144.657 118.1C148.973 119.21 152.991 117.802 155.663 117.088C156.297 116.913 156.768 117.91 156.089 118.239Z"
        fill="#364152"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M145.899 102.779C145.282 103.975 147.385 103.833 148.646 103.893C148.805 103.906 148.955 103.976 149.068 104.089C149.181 104.202 149.25 104.351 149.264 104.51C149.277 104.669 149.234 104.828 149.141 104.958C149.048 105.088 148.913 105.181 148.758 105.221C147.924 105.334 147.077 105.286 146.261 105.08C144.803 104.778 143.69 104.241 144.484 102.607C144.997 101.505 145.58 100.436 146.229 99.4082C146.308 99.2833 146.431 99.1921 146.573 99.1515C146.715 99.1103 146.867 99.122 147.001 99.185C147.134 99.248 147.241 99.3575 147.3 99.4929C147.359 99.6284 147.366 99.7808 147.322 99.9216C146.921 100.909 146.445 101.864 145.899 102.779Z"
        fill="#364152"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M153.889 98.1759C153.903 98.9318 153.959 100.078 152.845 100.107C151.948 100.132 151.696 99.3381 151.701 98.2771C151.705 96.366 153.843 95.8338 153.889 98.1759Z"
        fill="#364152"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M143.384 98.1285C143.365 98.8515 143.058 99.5333 142.223 99.4992C141.526 99.4709 141.275 98.5289 141.295 97.9084C141.324 96.9923 141.613 96.5248 142.192 96.3976C143.081 96.2021 143.409 97.2219 143.384 98.1285Z"
        fill="#364152"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M142.578 92.7348C142.111 92.6807 141.639 92.7584 141.214 92.9586C140.789 93.1587 140.429 93.4737 140.173 93.8676C139.528 94.8214 138.838 94.0383 139.083 93.4731C139.397 92.7861 139.924 92.2179 140.585 91.8517C141.246 91.4861 142.007 91.3419 142.757 91.4408C142.924 91.469 143.074 91.562 143.174 91.6986C143.275 91.8358 143.318 92.0066 143.294 92.1743C143.271 92.3427 143.183 92.4952 143.049 92.6C142.916 92.7042 142.747 92.7531 142.578 92.7348Z"
        fill="#364152"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M152.169 92.573C152.022 92.5718 151.88 92.5188 151.769 92.4229C151.658 92.3269 151.584 92.1944 151.561 92.049C151.539 91.9036 151.568 91.7552 151.645 91.6298C151.722 91.5044 151.841 91.4108 151.981 91.3649C152.879 91.1724 153.818 91.3113 154.622 91.7564C155.426 92.2009 156.042 92.9221 156.356 93.7858C156.551 94.4605 155.65 95.014 155.308 94.2568C155.038 93.6746 154.585 93.1953 154.019 92.8921C153.453 92.5883 152.804 92.4764 152.169 92.573Z"
        fill="#364152"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M146.56 109.113C147.066 110.253 147.965 111.174 149.092 111.708C150.219 112.242 151.501 112.353 152.704 112.023C153.944 111.622 154.987 110.766 155.621 109.627C156.255 108.488 156.433 107.151 156.119 105.885C155.975 105.297 155.448 105.092 154.814 105.591C152.163 107.68 150.202 108.006 147.07 108.058C146.561 108.066 146.263 108.522 146.56 109.113Z"
        fill="#364152"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M60.9063 131.79C61.1866 132.321 60.4024 132.891 59.9643 132.496C57.4292 130.202 52.7875 126.962 51.4781 123.901C49.7118 119.779 50.6762 114.618 47.1814 111.284C47.0554 111.176 46.9757 111.024 46.9589 110.859C46.9421 110.694 46.9893 110.529 47.091 110.398C47.1926 110.267 47.3408 110.18 47.5047 110.155C47.6686 110.13 47.8358 110.17 47.9715 110.265C51.8337 112.982 51.1013 118.301 52.8899 122.158C54.7033 126.064 58.9187 128.033 60.9063 131.79Z"
        fill="#364152"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M35.0755 125.776C31.4727 125.062 28.103 123.466 25.267 121.132C24.3568 120.33 25.4471 119.684 26.1112 120.133C28.9317 122.125 32.1597 123.467 35.5619 124.06C38.8825 124.551 42.2631 124.464 45.5541 123.803C46.3654 123.695 46.3478 124.881 45.7896 125.216C43.2509 126.741 37.9251 126.404 35.0755 125.776Z"
        fill="#364152"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.1032 123.94C19.5992 123.387 20.447 122.654 21.0004 123.054C24.7794 125.954 29.1183 128.038 33.7433 129.177C37.3559 130.001 36.3539 131.692 33.111 131.023C28.1423 130.077 23.5941 127.6 20.1032 123.94Z"
        fill="#364152"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M34.1396 136.843C29.8476 136.595 23.9036 134.616 20.682 131.793C20.5687 131.69 20.496 131.549 20.4771 131.397C20.4582 131.245 20.4942 131.091 20.5788 130.963C20.6633 130.835 20.7908 130.742 20.9382 130.699C21.0856 130.658 21.2432 130.669 21.3826 130.733C26.2798 133.133 28.8915 134.352 34.3645 135.031C37.5414 135.427 37.4484 137.035 34.1396 136.843Z"
        fill="#364152"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M53.1089 142.338C53.972 142.926 52.9912 144.376 52.1116 143.855C47.4699 141.123 42.1841 142.796 37.142 142.787C32.2257 142.871 27.4096 141.394 23.3853 138.569C22.8048 138.134 23.4442 137.265 24.0577 137.546C28.6653 139.873 33.7832 141.007 38.9424 140.843C43.7078 140.555 48.8723 139.453 53.1089 142.338Z"
        fill="#364152"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M231.745 211.961C184.615 212.534 50.4263 211.353 21.7636 211.019C19.9173 210.997 18.4524 208.913 21.7106 208.899C58.5663 208.709 176.859 208.754 231.676 209.259C233.835 209.273 233.841 211.935 231.745 211.961Z"
        fill="#9E26B7"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M140.93 126.766C130.568 128.415 110.72 139.494 99.9116 153.788C110.156 152.237 140.371 151.972 149.053 152.99C145.786 149.012 142.169 145.336 138.244 142.004C139.751 140.277 141.169 138.933 142.409 137.751C139.928 136.221 135.849 133.778 134.049 132.938C136.088 130.613 138.398 128.541 140.93 126.766Z"
        fill="#9E26B7"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M163.586 138.282C164.914 139.606 166.048 141.112 166.951 142.756C163.319 144.838 156.274 148.737 152.022 153.255C156.082 153.787 158.734 155.757 160.147 161.733C160.754 164.296 161.593 168.409 162.424 173.026C170.117 171.345 177.937 170.311 185.802 169.935C184.422 166.402 182.577 163.067 180.316 160.021C179.831 159.379 180.731 158.564 181.266 159.187C183.997 162.321 186.111 165.944 187.494 169.865C191.017 169.656 194.551 169.706 198.067 170.014C198.868 170.105 198.877 171.302 198.048 171.282C186.174 170.895 174.299 172.014 162.707 174.612C163.89 181.362 165.004 188.904 165.354 194.185C183.605 194.007 213.214 203.025 217.803 192.146C228.08 167.783 196.867 129.329 174.658 125.963C176.533 128.557 177.846 131.515 178.512 134.646C176.079 135.49 166.777 137.705 163.586 138.282Z"
        fill="#9E26B7"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M149.924 145.194C146.191 135.879 146.293 130.92 145.228 126.354C145.203 126.196 145.12 126.054 144.993 125.957C144.867 125.859 144.708 125.814 144.549 125.831C144.391 125.847 144.245 125.924 144.141 126.045C144.038 126.167 143.985 126.323 143.993 126.482C143.955 131.994 146.764 141.175 149.044 146.454C149.117 146.581 149.218 146.69 149.34 146.772C149.463 146.853 149.602 146.905 149.748 146.922C149.894 146.939 150.042 146.921 150.18 146.87C150.317 146.82 150.441 146.738 150.541 146.63C157.928 136.373 161.618 132.667 170.088 126.089C170.219 125.996 170.31 125.855 170.34 125.696C170.37 125.538 170.338 125.373 170.25 125.238C170.161 125.103 170.024 125.007 169.867 124.971C169.71 124.935 169.544 124.961 169.406 125.044C161.545 130.29 154.902 137.161 149.924 145.194Z"
        fill="#9E26B7"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M89.8703 154.85C82.6051 150.421 70.2896 139.523 65.2405 132.629C60.1667 137.804 55.6524 143.5 51.7734 149.623C60.965 163.179 75.5213 177.802 84.6434 182.051C83.5836 177.531 83.314 165.039 83.5836 161.052C83.8532 157.065 86.8582 155.471 89.8703 154.85Z"
        fill="#9E26B7"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M48.2397 154.645C50.4935 154.551 49.3348 152.971 48.374 152.952C35.5392 152.7 21.7837 152.387 12.107 152.537C12.8547 96.6214 12.4072 61.34 12.7134 29.8336C12.7216 28.9987 11.47 29.1271 11.4323 29.9219C10.21 55.536 10.9613 124.514 10.0193 152.347C9.9863 153.3 10.0522 153.909 12.1152 154.001C17.8932 154.26 23.524 154.271 29.335 154.371C34.6749 154.464 43.0646 154.862 48.2397 154.645Z"
        fill="#9E26B7"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M188.854 99.1455C189.421 87.6649 189.244 76.0324 189.296 64.527C189.327 57.7258 189.635 39.4651 189.559 30.5032C189.546 28.9512 187.945 29.8355 187.929 30.6209C187.619 45.8378 186.817 83.8333 187.399 99.162C187.449 100.453 188.797 100.434 188.854 99.1455Z"
        fill="#9E26B7"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30.7125 64.0563C30.7125 60.3236 30.7443 56.5933 30.7125 52.8606C47.5248 52.764 64.3478 52.6687 81.1577 52.3649C81.1719 55.6313 81.1577 58.8976 81.1577 62.164C65.1002 62.575 48.6799 63.1802 32.5741 63.4687C31.5367 63.4875 31.7051 65.0371 32.7083 65.0501C48.9708 65.2467 65.7325 64.2094 82.0456 63.7866C82.2617 63.7643 82.4601 63.6589 82.6002 63.4934C82.7403 63.3274 82.811 63.1143 82.7968 62.8976C82.7968 59.0837 82.7839 55.2686 82.8098 51.4547C82.7868 51.2371 82.6808 51.0369 82.5131 50.8961C82.3458 50.7551 82.131 50.6844 81.9125 50.6987C64.5432 50.7093 47.1621 50.946 29.7928 51.178C29.5708 51.2009 29.3662 51.3091 29.2222 51.4797C29.0782 51.6503 29.0058 51.87 29.0204 52.0929C29.117 56.1199 29.3218 60.1376 29.4608 64.1623C29.4808 64.9806 30.7113 64.8652 30.7125 64.0563Z"
        fill="#9E26B7"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M29.7326 90.9706C47.2915 90.2853 65.3697 90.2053 82.8214 89.003C82.9492 89.006 83.0757 88.9807 83.1929 88.9283C83.3095 88.8759 83.4131 88.7987 83.4961 88.7016C83.5791 88.6044 83.6392 88.4896 83.6721 88.3666C83.7057 88.243 83.711 88.114 83.688 87.988C83.3795 85.4988 83.0699 83.0096 82.746 80.5227C82.6106 79.49 81.061 79.8433 81.1741 80.8571C81.4096 83.0178 81.671 85.1773 81.9277 87.3333C64.8798 88.0528 47.6813 88.5391 30.7064 89.0831C30.7064 85.7072 30.6888 82.3266 30.7841 78.9566C47.9462 78.7788 64.8139 78.5009 82.1644 78.6422C82.3316 78.6452 82.4935 78.5827 82.6159 78.4685C82.7384 78.3537 82.8114 78.1965 82.8196 78.0293C82.8279 77.8621 82.7714 77.6984 82.6607 77.5724C82.5506 77.4465 82.3957 77.3682 82.2291 77.354C64.8115 76.5521 47.321 76.9054 29.8304 76.9407C29.6948 76.9337 29.5591 76.9531 29.4312 76.9984C29.3032 77.0437 29.1855 77.1138 29.0848 77.2051C28.9841 77.2963 28.9024 77.4064 28.8444 77.5289C28.7865 77.6519 28.7534 77.785 28.7471 77.9204C28.8919 81.9392 28.8377 85.9721 28.8519 89.9957C28.8427 90.2406 28.9297 90.4802 29.0942 90.6621C29.2587 90.8441 29.4878 90.9547 29.7326 90.9706Z"
        fill="#9E26B7"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M81.3768 114.55C72.9459 114.662 62.2201 114.495 53.5714 114.904C52.8472 114.938 52.8802 116.414 53.5902 116.426C61.377 116.559 73.1614 116.174 82.1634 116.364C83.1584 116.385 83.2043 115.422 83.0877 114.706C82.5067 111.192 82.4749 107.608 82.9947 104.085C83.0189 103.958 83.0141 103.827 82.9806 103.702C82.9476 103.577 82.8864 103.461 82.8028 103.362C82.7186 103.264 82.6138 103.186 82.4955 103.133C82.3777 103.081 82.2494 103.056 82.1198 103.059C64.9896 103.464 47.8546 104.098 30.7255 104.372C30.4952 104.388 30.28 104.492 30.125 104.663C29.97 104.834 29.8872 105.059 29.8942 105.289C29.8636 108.996 29.8706 112.708 29.8447 116.41C29.8608 116.653 29.971 116.881 30.152 117.046C30.333 117.21 30.5708 117.297 30.815 117.289C35.7605 117.108 39.6545 117.135 44.6012 116.83C45.4254 116.779 45.3559 115.292 44.5387 115.29C39.9018 115.277 36.3257 115.328 31.6899 115.424C31.6722 112.314 31.6687 109.205 31.6475 106.095C48.1584 105.506 64.6822 105.247 81.1967 104.785C80.8105 108.034 80.8711 111.319 81.3768 114.55Z"
        fill="#9E26B7"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M121.406 125.53C122.186 125.715 122.398 124.463 121.642 124.242C113.396 121.615 106.48 115.911 102.331 108.316C92.7566 91.2163 98.6806 68.8073 114.235 57.4397C128.012 47.3709 157.607 47.5758 167.15 64.0337C167.727 65.0287 169.226 64.4847 168.745 63.4262C161.285 46.9824 131.004 44.6274 116.155 53.6941C98.6701 64.374 90.5606 87.4719 99.2223 106.403C103.579 115.926 111.671 123.224 121.406 125.53Z"
        fill="#9E26B7"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M188.716 128.977C189.016 126.438 188.749 119.675 188.76 115.717C188.76 114.297 187.427 114.819 187.379 115.413C187.096 119.931 187.084 124.46 187.344 128.979C187.396 129.654 188.639 129.643 188.716 128.977Z"
        fill="#9E26B7"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M192.939 198.619C185.911 197.794 171.336 196.891 161.871 196.735C161.871 191.301 156.673 160.232 153.366 157.633C150.198 155.144 90.108 154.68 87.627 159.4C84.3654 165.612 89.756 204.1 92.7327 205.116C97.5757 206.765 189.628 206.49 193.644 206.176C195.744 206.017 196.043 198.98 192.939 198.619ZM106.176 161.34C102.686 161.705 99.3467 162.188 95.866 162.698C92.8528 163.139 92.6397 163.771 92.3123 166.663C91.8972 170.096 91.6982 173.553 91.7165 177.012C91.7165 177.748 90.6461 177.941 90.5484 177.166C90.098 173.197 90.128 169.188 90.6379 165.226C90.9747 162.626 91.9332 161.458 94.5237 160.929C98.3541 160.156 102.264 159.849 106.168 160.016C106.326 160.039 106.472 160.118 106.577 160.239C106.682 160.359 106.74 160.514 106.741 160.674C106.742 160.835 106.685 160.99 106.582 161.112C106.478 161.234 106.335 161.315 106.176 161.34ZM121.561 187.633C119.071 188.104 116.373 186.757 115.402 182.958C114.558 179.661 116.108 177.173 118.847 176.472C125.406 174.796 127.802 186.44 121.561 187.633Z"
        fill="#364152"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M189.645 109.02C178.447 97.9242 185.122 82.3683 177.96 74.8405C176.606 73.3804 174.777 72.449 172.8 72.2118C170.823 71.9745 168.825 72.4473 167.164 73.5453C161.647 64.5739 150.395 62.4026 140.815 65.3876C130.374 68.641 126.633 81.3874 135.015 85.4898C139.407 87.64 154.826 87.8225 159.647 86.8887C160.032 90.8804 161.526 94.6849 163.958 97.8736C164.207 98.1739 164.556 98.5895 164.97 98.2269C165.418 97.823 165.946 97.5168 166.519 97.3273C167.091 97.1377 167.697 97.0688 168.298 97.1259C168.898 97.183 169.481 97.3638 170.008 97.6575C170.535 97.9513 170.995 98.3511 171.359 98.8321C171.694 99.3272 171.928 99.8836 172.048 100.469C172.167 101.055 172.17 101.659 172.055 102.246C171.941 102.833 171.711 103.391 171.38 103.889C171.049 104.386 170.623 104.814 170.127 105.147C168.687 106.1 167.66 105.961 166.047 105.879C165.541 105.855 165.447 106.277 165.413 106.664C164.915 112.273 164.363 118.674 165.219 124.253C165.241 124.408 165.322 124.548 165.446 124.644C165.57 124.739 165.726 124.783 165.882 124.765C166.037 124.747 166.18 124.669 166.279 124.549C166.377 124.427 166.425 124.273 166.412 124.117C166.423 122.605 166.367 121.093 166.317 119.58C174.201 120.462 184.182 115.851 189.668 110.579C190.537 109.753 189.916 109.288 189.645 109.02ZM147.231 70.638C145.309 70.6033 143.407 71.0354 141.689 71.8968C139.78 72.8129 136.54 75.0902 136.757 77.4781C136.822 78.1941 135.793 78.6321 135.526 77.8938C134.236 74.3354 137.825 71.5553 140.218 70.2495C142.349 69.0867 144.737 68.4732 147.165 68.4656C150.272 68.4691 148.989 70.7122 147.231 70.638Z"
        fill="#364152"
      />
    </svg>
  );
};
