import { ProcedureName } from '../ocppMessageLogEntry';
import { OcppAction } from '../ocppMessageLogsModel';

export const ocppActionNormalizer = (
  procedureName: ProcedureName | undefined
): OcppAction => {
  if (!procedureName) {
    return 'unknown';
  }
  return ocppActionMap[procedureName] || 'unknown';
};

const ocppActionMap: Record<ProcedureName, OcppAction> = {
  AUTHORIZE: 'authorize',
  BOOT_NOTIFICATION: 'bootNotification',
  CANCEL_RESERVATION: 'cancelReservation',
  CHANGE_AVAILABILITY: 'changeAvailability',
  CHANGE_CONFIGURATION: 'changeConfiguration',
  CLEAR_CACHE: 'clearCache',
  CLEAR_CHARGING_PROFILE: 'clearChargingProfile',
  DATA_TRANSFER: 'dataTransfer',
  DIAGNOSTICS_STATUS_NOTIFICATION: 'diagnosticsStatusNotification',
  FIRMWARE_STATUS_NOTIFICATION: 'firmwareStatusNotification',
  GET_CONFIGURATION: 'getConfiguration',
  GET_DIAGNOSTICS: 'getDiagnostics',
  GET_LOCAL_LIST_VERSION: 'getLocalListVersion',
  HEARTBEAT: 'heartbeat',
  METER_VALUES: 'meterValues',
  REMOTE_START_TRANSACTION: 'remoteStartTransaction',
  REMOTE_STOP_TRANSACTION: 'remoteStopTransaction',
  RESERVE_NOW: 'reserveNow',
  RESET: 'reset',
  SEND_LOCAL_LIST: 'sendLocalList',
  SET_CHARGING_PROFILE: 'setChargingProfile',
  START_TRANSACTION: 'startTransaction',
  STOP_TRANSACTION: 'stopTransaction',
  STATUS_NOTIFICATION: 'statusNotification',
  UNLOCK_CONNECTOR: 'unlockConnector',
  UPDATE_FIRMWARE: 'updateFirmware',
  TRIGGER_MESSAGE: 'triggerMessage',
  GET_COMPOSITE_SCHEDULE: 'getCompositeSchedule',
};
