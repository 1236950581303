export const FleetIsRoutes = {
  Dashboard: '/dashboard',
  ChargingStations: '/charging-stations',
  EditChargingStation: 'edit',
  ChargingStationDetailsConfigurationTab: '16/configuration',
  ChargingStationDetailsConfigurationTabEdit: 'edit',
  ChargingStationDetailsGeneralTab: '16/general',
  AddChargingStation: '/charging-stations/add',
  DeleteChargingStation: ':identityKey/delete',
  EditChargingStationFromTable: ':chargingStationName/:identityKey/edit',
  Tours: '/tours',
  CreateTour: '/create-tour',
  CreateChargingSchedule: '/create-charging-schedule',
  DeleteTour: '/tours/:tourId/delete',
  TourDetails: '/tours/:tourId',
  ChargingStationDetails: ':chargingStationName/:identityKey',
  ChargingSchedule: '/charging-schedule',
  Vehicles: '/vehicles',
  AddVehicle: '/vehicles/add',
  DeleteVehicle: '/vehicles/:vehicleId/delete',
  EditVehicle: '/vehicles/:vehicleId/edit',
  BusinessAccounts: '/business-accounts',
  BusinessAccountDetails: '/business-accounts/:businessAccountId',
};
