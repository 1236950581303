import { FormControlLabel, Switch } from '@mui/material';
import { useState } from 'react';

interface ConfigurationBooleanValueFieldProps {
  handleValueChange: (val: string) => void;
  formValue: string;
  controlerValue: string;
}
export const ConfigurationBooleanValueField: React.FC<
  ConfigurationBooleanValueFieldProps
> = ({ handleValueChange, formValue, controlerValue, ...rest }) => {
  const [checkedValue, setCheckedValue] = useState(
    controlerValue === 'true' ? true : false
  );

  return (
    <FormControlLabel
      sx={{ margin: 0 }}
      control={
        <Switch
          checked={checkedValue}
          value={controlerValue}
          onChange={(e) => {
            const value = e.target.checked.toString();
            handleValueChange(value);
            setCheckedValue(e.target.checked);
          }}
          {...rest}
        />
      }
      label={formValue === 'true' ? 'True' : 'False'}
      labelPlacement="start"
    />
  );
};
