import { useCallback, useState } from 'react';
import { LocationsTable } from './LocationsTable';
import { LocationsMap } from './LocationsMap';
import { Box } from '@mui/material';
import { ESAddItemButton } from '@energy-stacks/core/ui';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useGetAllLocationsBrokerQuery } from '@energy-stacks/broker/feature-locations-data';
import { BrokerLocationModel } from '@energy-stacks/broker/feature-locations-data';
import { BrokerRoutes } from '@energy-stacks/broker/shared';
import { SearchTextField } from '@energy-stacks/shared';

export const Locations = () => {
  const { t } = useTranslation('locations');
  const navigate = useNavigate();

  const { data: locations } = useGetAllLocationsBrokerQuery();

  const [globalFilter, setGlobalFilter] = useState<string>('');
  const [filteredLocations, setFilteredLocations] = useState<
    BrokerLocationModel[]
  >(locations || []);
  const [focusedLocationId, setFocusedLocationId] = useState<
    BrokerLocationModel['uuid'] | null
  >(null);

  const onFilteredLocations = useCallback(
    (locations: BrokerLocationModel[]) => {
      setFilteredLocations(locations);
    },
    []
  );

  return (
    <>
      <Box display="flex" mb={6} pr={3} alignItems="center">
        <Box flex={1}>
          <SearchTextField
            testId="chargingSites"
            value={globalFilter}
            onChange={setGlobalFilter}
          />
        </Box>
        <Box display="flex">
          <ESAddItemButton
            data-testid="addChargingSiteButton"
            onClick={() =>
              navigate(`${BrokerRoutes.Locations}/${BrokerRoutes.AddLocation}`)
            }
            title={t('addLocationsButtonTooltip')}
          />
        </Box>
      </Box>
      <Box height="40%" marginBottom={4}>
        <LocationsMap
          data={filteredLocations}
          focusedLocationId={focusedLocationId}
          setFocusedLocationId={setFocusedLocationId}
        />
      </Box>
      <LocationsTable
        testId="chargingSites"
        data={locations || []}
        globalFilter={globalFilter}
        filteredLocations={onFilteredLocations}
      />
    </>
  );
};
