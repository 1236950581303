import { Box } from '@mui/material';
import React from 'react';

type AccountDetailsIconWrapperProps = {
  children: React.ReactNode;
};

export const AccountDetailsIconWrapper: React.FC<
  AccountDetailsIconWrapperProps
> = ({ children }) => {
  return (
    <Box
      bgcolor="grey.100"
      sx={(theme) => ({
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: theme.spacing(2),
        width: theme.spacing(7),
        height: theme.spacing(7),
        color: theme.palette.grey['500'],
      })}
    >
      {React.Children.map(children, (child) => {
        if (React.isValidElement(child)) {
          return React.cloneElement(child as React.ReactElement, {
            ...child.props,
            size: 20,
          });
        }
        return child;
      })}
    </Box>
  );
};
