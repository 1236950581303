import { compact } from 'lodash-es';
import {
  EvseDto,
  EvseDtoStatus,
  ConnectorDto,
  ConnectorDtoStandard,
  ConnectorDtoPowerType,
  StatusScheduleDto,
  ConnectorDtoFormat,
} from './chargingStationDto';
import { ConnectorFormat, ConnectorModel } from './connectorModel';
import { ConnectorType } from './connectorType';
import { EvseModel, ScheduledStatus } from './evseModel';
import { EvseStatus } from './evseStatus';
import { PowerType } from './powerType';
import { appendZToDateString } from '@energy-stacks/shared';

export const evsesRule = (evseDtos: EvseDto[] | undefined): EvseModel[] => {
  if (!evseDtos) return [];
  return compact(evseDtos.map(evseNormalizer));
};

function evseNormalizer(evseDto: EvseDto | undefined): EvseModel | null {
  if (!evseDto || !evseDto.evse_id) return null;

  return {
    evseId: evseDto.evse_id,
    lastUpdate: appendZToDateString(evseDto.last_updated),
    currentStatus: evseStatusNormalizer(evseDto.status),
    connectors: connectorsRule(evseDto.connectors),
    statusSchedule: evseDto.status_schedule
      ? statusScheduleNormalizer(evseDto.status_schedule)
      : null,
  };
}

// TBD
function evseStatusNormalizer(status: EvseDtoStatus | undefined): EvseStatus {
  if (!status) return EvseStatus.UNAVAILABLE;

  const statusMap: Record<EvseDtoStatus, EvseStatus> = {
    AVAILABLE: EvseStatus.AVAILABLE,
    BLOCKED: EvseStatus.RESERVED, // Alternative: UNAVAILABLE
    CHARGING: EvseStatus.CHARGING,
    INOPERATIVE: EvseStatus.FAULTED, // Alternative: UNAVAILABLE
    OUTOFORDER: EvseStatus.FAULTED,
    REMOVED: EvseStatus.UNAVAILABLE,
    PLANNED: EvseStatus.RESERVED, // Alternative: CHARGING
    RESERVED: EvseStatus.RESERVED,
    UNKNOWN: EvseStatus.NEW, // Alternative: UNAVAILABLE but then how do we get status new?
  };
  return statusMap[status] ?? EvseStatus.UNAVAILABLE;
}

function connectorsRule(
  connectorDtos: ConnectorDto[] | undefined
): ConnectorModel[] {
  if (!connectorDtos) return [];
  return compact(connectorDtos.map(connectorNormalizer));
}

function connectorNormalizer(
  connectorDto: ConnectorDto | undefined
): ConnectorModel | null {
  if (!connectorDto || !connectorDto.id) return null;
  const powerTypeNormalized = connectorPowerTypeNormalizer(
    connectorDto.power_type
  );
  const normalizedConnector = {
    id: connectorDto.id,
    connectorType: connectorStandardNormalizer(connectorDto.standard),
    connectorFormat: formatNormalizer(connectorDto.format),
    powerType: powerTypeNormalized,
    maxPower: connectorDto.max_electric_power,
    maxAmperage: connectorDto.max_amperage ?? 0,
    maxVoltage: connectorDto.max_voltage ?? 0,
    tariffIds: connectorDto.tariff_ids || [],
    termsAndConditions: connectorDto.terms_and_conditions,
    lastUpdated: appendZToDateString(connectorDto.last_updated),
  };
  return normalizedConnector;
}

// TBD
function connectorStandardNormalizer(
  standard: ConnectorDtoStandard | undefined
): ConnectorType {
  if (!standard) return ConnectorType.UNKNOWN;

  const connectorStandardToTypeMap: Record<
    ConnectorDtoStandard,
    ConnectorType
  > = {
    CHADEMO: ConnectorType.CHAdeMO,
    IEC_62196_T2: ConnectorType.TYPE_2,
    GBT_AC: ConnectorType.TYPE_2,
    IEC_62196_T1: ConnectorType.CCS_TYPE_1_MALE,
    IEC_62196_T2_COMBO: ConnectorType.CCS,
    IEC_62196_T3A: ConnectorType.TYPE_EF,
    UNKNOWN: ConnectorType.UNKNOWN,
  };
  const type = connectorStandardToTypeMap[standard];
  return type ?? ConnectorType.UNKNOWN;
}

function connectorPowerTypeNormalizer(
  powerType: ConnectorDtoPowerType
): PowerType {
  const powerTypeMap: Record<ConnectorDtoPowerType, PowerType> = {
    AC_1_PHASE: PowerType.AC_1_PHASE,
    AC_3_PHASE: PowerType.AC_3_PHASE,
    DC: PowerType.DC,
  };
  return powerTypeMap[powerType];
}

function statusScheduleNormalizer(
  statusSchedule: StatusScheduleDto[]
): ScheduledStatus[] {
  return statusSchedule.map((statusObj) => ({
    status: evseStatusNormalizer(statusObj.status),
    periodBegin: appendZToDateString(statusObj.period_begin),
    periodEnd: appendZToDateString(statusObj.period_end),
  }));
}

function formatNormalizer(format: ConnectorDtoFormat): ConnectorFormat {
  const connectorFormatMap: Record<ConnectorDtoFormat, ConnectorFormat> = {
    SOCKET: 'SOCKET',
    CABLE: 'CABLE',
  };
  return connectorFormatMap[format];
}
