import { Paper, Skeleton, useMediaQuery } from '@mui/material';
import { range } from 'lodash-es';

export const ConnectorSkeleton = ({
  numberOfConnectors,
}: {
  numberOfConnectors: number;
}) => {
  const isFullHD = useMediaQuery('(min-width: 1920px)');

  return (
    <Paper
      sx={{
        height: isFullHD
          ? `calc(56px + (min(${numberOfConnectors},3) * 108px))`
          : numberOfConnectors > 1
          ? '100%'
          : 'unset',
        minHeight:
          numberOfConnectors > 1 ? (!isFullHD ? '693px' : 'unset') : 'unset',
        maxHeight: isFullHD
          ? 'calc(100vh - 750px - 72px - 95px)'
          : 'calc(100vh - 290px - 72px - 150px)',
        display: 'flex',
        flexDirection: 'column',
        gap: 4,
        p: 4,
      }}
    >
      <Skeleton
        variant="text"
        sx={{
          width: '40%',
        }}
      />

      {range(0, Math.min(numberOfConnectors, 3)).map((i) => (
        <Skeleton
          key={i}
          variant="rectangular"
          animation="wave"
          width="100%"
          height={isFullHD ? '92px' : '226px'}
        />
      ))}
    </Paper>
  );
};
