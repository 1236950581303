import { brokerManagementApi } from '@energy-stacks/broker/feature-charging-station-management-data';
import { useESSnackbar } from '@energy-stacks/core/ui';
import { useChargingStationIdentityKey } from '@energy-stacks/shared';
import {
  endConfigurationRefetch,
  startConfigurationRefetch,
  useAppDispatch,
} from '@energy-stacks/broker/store';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

/**
 * Retrieve/Refresh Configuration button handles a specific edge case in Broker app:
 *
 * Configuration table is populated from shadow configuration. This is to ensure that we always show the latest
 * known configuration, even if the station is offline or incorrectly configured at the moment.
 *
 * Shadow is updated on each successful configuration key update. However, to solve possible inconsistencies
 * between shadow and actual configuration, we also allow to manually refresh the shadow configuration. This
 * is done by:
 *
 * 1. call getConfiguration
 * 2. this synchronizes the shadow configuration with the actual configuration
 * 3. call getConfigurationShadow with the latest values
 */

enum StationConnectionStatus {
  StationNeverWentOnline,
  StationCurrentlyOffline,
  StationOnline,
}

export const useRefreshConfiguration = () => {
  const dispatch = useAppDispatch();
  const { showSnackbar } = useESSnackbar();
  const identityKey = useChargingStationIdentityKey();
  const { t } = useTranslation('chargingStations');

  const refreshConfiguration = useCallback(async () => {
    // Immediately trigger a loading indicator in configuration tab. Using a combination of isConfigurationFetching and isShadowConfigurationFetching does not immediately set its internal rtk-query state to true, so it won't be reliable.
    dispatch(startConfigurationRefetch());

    return dispatch(
      brokerManagementApi.endpoints.getConfiguration.initiate(identityKey)
    )
      .unwrap()
      .then(() => {
        return new Promise((resolve) => {
          // safeguard since old implementation did not propagate the change immediately, probably due to microservice communication delay
          setTimeout(resolve, 1000);
          return resolve(StationConnectionStatus.StationOnline);
        });
      })
      .catch((error) => {
        // when get configuration fails with a 404 - NOT_FOUND, it means that the station never went online, never sent a boot notification and never filled the shadow configuration
        if (error.data.errorCode === 'NOT_FOUND') {
          return StationConnectionStatus.StationNeverWentOnline;
        }

        // if station is offline of incorrectly configured, getConfiguration will fail with RPC_TIMEOUT. Suppress error, but pass this information down the promise chain
        return StationConnectionStatus.StationCurrentlyOffline;
      })
      .then(async (_stationConnectionStatus) => {
        const stationConnectionStatus =
          _stationConnectionStatus as StationConnectionStatus; // TypeScript not inferring correct type from promise chain above

        dispatch(brokerManagementApi.util.resetApiState()); // to prevent returning cached data, clear cache before refetching shadow
        return dispatch(
          brokerManagementApi.endpoints.getConfigurationShadow.initiate(
            identityKey
          )
        )
          .unwrap()
          .then(() => {
            // only if shadow configuration was fetched successfully, pass station status down the promise chain, otherwise, show generic error
            return stationConnectionStatus;
          })
          .catch(() => {
            // fetching shadow configuration can not RPC_TIMEOUT, show generic error
            showSnackbar('error');
          });
      })
      .then((stationConnectionStatus) => {
        if (
          stationConnectionStatus ===
          StationConnectionStatus.StationNeverWentOnline
        ) {
          showSnackbar('error', t('stationNeverWentOnlineErrorMessage'));
        }

        if (
          stationConnectionStatus ===
          StationConnectionStatus.StationCurrentlyOffline
        ) {
          showSnackbar('warning', t('configurationKeysOutdatedWarning'));
        }

        return dispatch(endConfigurationRefetch());
      });
  }, [identityKey, dispatch, showSnackbar, t]);

  return refreshConfiguration;
};
