import { BrokerRoutes } from '@energy-stacks/broker/shared';
import { SidebarGroup } from '@energy-stacks/core/sidebar';
import { IconDashboard } from '@tabler/icons-react';
import { useTranslation } from 'react-i18next';

export const DashboardDrawerItem = () => {
  const { t } = useTranslation('dashboard');
  const itemLabel = t('title');

  return (
    <SidebarGroup
      testId="dashboardSidebarButton"
      groupInitialPath={BrokerRoutes.Dashboard}
      groupIcon={<IconDashboard />}
      groupLabel={itemLabel}
    ></SidebarGroup>
  );
};
