import { OptimizedTours } from '../optimizedTourModel';
import { OptimizeToursWebResponse } from '../optimizeToursWebResponse';
import { optimizedTourNormalizer } from './optimizedTourNormalizer';
import { skippedJobsNormalizer } from './skippedJobsNormalizer';

export const optimizedToursNormalizer = (
  optimizedToursResponse: OptimizeToursWebResponse
): OptimizedTours => {
  return {
    tours: (optimizedToursResponse.tours ?? []).map(optimizedTourNormalizer),
    skippedJobs: skippedJobsNormalizer(
      optimizedToursResponse.skippedJobs ?? []
    ),
  };
};
