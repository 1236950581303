import { UserRole } from '../userRole';
import { UserRoleDto } from '../userRoleDto';

export const userRoleNormalizer = (userRole: UserRoleDto): UserRole => {
  return userRoleMap[userRole] ?? 'noRole';
};

const userRoleMap: Record<UserRoleDto, UserRole> = {
  CPO_ADMIN: 'admin',
  SUPER_ADMIN: 'superAdmin',
  CPO_USER: 'operator',
  CPO_OWNER: 'owner',
};

export const toUserRoleDto = (userRole: UserRole): UserRoleDto => {
  const map: Record<UserRole, UserRoleDto> = {
    admin: 'CPO_ADMIN',
    operator: 'CPO_USER',
    owner: 'CPO_OWNER',
    superAdmin: 'SUPER_ADMIN',
    noRole: null as unknown as UserRoleDto,
  } as const;

  return map[userRole];
};
