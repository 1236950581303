/**
 * List of price components that describe the pricing of a tariff.
 */
export interface PriceComponent {
  /**
   * Type of tariff dimension.
   */
  type: PriceComponentType;
  /**
   * Price per unit (excl. VAT) for this tariff dimension.
   */
  price: number;
  /**
   * Applicable VAT percentage for this tariff dimension. If omitted, no VAT is applicable. Not providing a VAT is different from 0% VAT, which would be a value of 0.0 here.
   */
  vat?: number;
  /**
   * Minimum amount to be billed. This unit will be billed in this step_size blocks. Amounts that are less then this step_size are rounded up to the given step_size. For example: if type is TIME and step_size has a value of 300, then time will be billed in blocks of 5 minutes. If 6 minutes were used, 10 minutes (2 blocks of step_size) will be billed.
   */
  stepSize: number;
}

export type PriceComponentType = 'ENERGY' | 'FLAT' | 'PARKING_TIME' | 'TIME';

export enum PriceComponentEnum {
  ENERGY = 'ENERGY',
  FLAT = 'FLAT',
  PARKINGTIME = 'PARKING_TIME',
  TIME = 'TIME',
}
