import { NoData } from '../NoData';
import { RefetchButton } from '../RefetchButton';
import { useTranslation } from 'react-i18next';

interface NoGeneralDetailsProps {
  onRefetch: () => void;
}

export const NoGeneralDetails: React.FC<NoGeneralDetailsProps> = ({
  onRefetch,
}) => {
  const { t } = useTranslation('chargingStations');
  return (
    <NoData
      title={t('noStationDetailsTitle')}
      subtitle={t('noStationDetailsSubtitle')}
      ActionComponent={<RefetchButton onRefetch={onRefetch} />}
    />
  );
};
