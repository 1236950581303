import {
  getEditVehicleDefaultGeneralInfo,
  getEditVehicleDefaultOperatingHours,
} from '@energy-stacks/fleet/feature-vehicles';
import { VehicleDetailsModel } from '@energy-stacks/fleet/feature-vehicles-data';

export const getEditVehicleDefaultValues = (
  vehicle: VehicleDetailsModel | undefined
) => {
  return {
    ...getEditVehicleDefaultGeneralInfo(vehicle),
    ...getEditVehicleDefaultOperatingHours(vehicle),
  };
};
