import { createBaseQuery, stompClientInstance } from '@energy-stacks/shared';
import { createApi } from '@reduxjs/toolkit/query/react';
import { ChargingStationDto } from './chargingStationDto';
import { ChargingStationModel } from './chargingStationModel';
import { environment } from '@energy-stacks/feature-config';
import {
  chargingStationBrokerShadowNormalizer,
  chargingStationBrokerDetailsNormalizer,
  chargingStationDetailsNormalizer,
} from './normalizers/chargingStationDetailsNormalizer';
import {
  ChargingStationDetailsModel,
  GeoLocation,
} from './chargingStationDetailsModel';
import {
  chargingStationsNormalizer,
  chargingStationsBrokerNormalizer,
} from './normalizers/chargingStationNormalizer';
import { ChangeAvailabilityRequestOption } from './changeAvailabilityRequestOption';
import {
  TriggerMessageRequestOption,
  TriggerMessageRequestOption201,
} from './triggerMessageRequestOption';
import { StompSubscription } from '@stomp/stompjs';
import {
  createEntityAdapter,
  createSelector,
  EntityState,
} from '@reduxjs/toolkit';
import log from 'loglevel';
import { connectorShadowNormalizer } from './normalizers/connectorShadowNormalizer';
import { chargingStationDetailsSubscriptionState } from './chargingStationDetailsSubscriptionState';
import { ResetV201RequestOption } from './resetV201RequestOption';

export interface BrokerAddChargePointForm {
  identityKey: string;
  chargingStationName: string | undefined;
  csmsUuid: string;
  coordinates: GeoLocation | null;
  connectors: EditConnectorRequest[];
  locationUuid: string | null;
}

export interface BrokerEditChargingStationForm {
  chargingStationName: string | null;
  csmsUuid: string;
  coordinates: GeoLocation | null;
  locationUuid: string | null;
}

export interface ClearAuthorizationCacheConfirmation {
  status: 'Accepted' | 'Rejected';
}

export interface ChangeAvailabilityConfirmation {
  status: 'Accepted' | 'Rejected' | 'Scheduled';
}

export interface ChangeAvailabilityConfirmation201 {
  status: 'Accepted' | 'Faulted' | 'Occupied' | 'Rejected' | 'Unavailable';
}

export interface ResetV201Confirmation {
  status: 'Accepted' | 'Rejected' | 'Scheduled';
  statusInfo?: {
    reasonCode: string;
    additionalInfo?: string;
  };
}

export interface ChangeAvailabilityRequestBody {
  connectorId: number;
  type: ChangeAvailabilityRequestOption;
}

export interface ChangeAvailability201RequestBody {
  evse: {
    id: number;
    connectorId?: number;
  };
  operationalStatus: ChangeAvailabilityRequestOption;
}

export interface TriggerMessageRequestBody {
  connectorId?: number;
  requestedMessage: TriggerMessageRequestOption;
}

export interface TriggerMessage201RequestBody {
  requestedMessage: TriggerMessageRequestOption201;
  evse: {
    id: number;
    connectorId?: number;
  };
}

export interface ResetV201RequestBody {
  type: ResetV201RequestOption;
  evseId?: number;
}

export interface TriggerMessageConfirmation {
  status: 'Accepted' | 'Rejected' | 'NotImplemented';
}

export interface ChangeCoordinatesRequestBody {
  chargingStationModel: string;
  chargingStationName: string;
  csmsUuid: string;
  coordinates: GeoLocation;
}

export interface ChangeCoordinatesConfirmation {
  status: 'Accepted' | 'Rejected';
}
const chargingStationsAdapter = createEntityAdapter<ChargingStationModel>({
  selectId: (station) => station.identityKey,
});

export interface EditConnectorRequest {
  evseId: string | null;
  standard: string | null;
  format: string | null;
  power_type: string | null;
  max_voltage: number;
  max_amperage: number;
  max_electric_power: number;
  tariff_id: string | null;
}

const initialState = chargingStationsAdapter.getInitialState();

let chargingStationsSubs: StompSubscription[] = [];

export const unsubscribeChargingStations = () => {
  if (chargingStationsSubs.length > 0) {
    chargingStationsSubs.forEach((sub) => {
      try {
        sub.unsubscribe();
      } catch (e) {
        log.error(e);
      }
    });
    chargingStationsSubs = [];
  }
};

const { subscribeToChargingStationDetails, unsubscribeChargingStationDetails } =
  chargingStationDetailsSubscriptionState();

export const chargingStationsApi = createApi({
  reducerPath: 'chargingStations',
  tagTypes: ['ChargingStations', 'ChargingStationDetails'],
  keepUnusedDataFor: 0,
  baseQuery: createBaseQuery(`${environment.ocppServiceUrl}/`),
  endpoints: (builder) => ({
    getChargingStations: builder.query<EntityState<ChargingStationModel>, void>(
      {
        query: () => 'chargingstations',
        providesTags: ['ChargingStations'],
        transformResponse: (response: ChargingStationDto[]) => {
          return chargingStationsAdapter.addMany(
            chargingStationsAdapter.getInitialState(),
            chargingStationsNormalizer(response)
          );
        },
        async onCacheEntryAdded(
          arg,
          { updateCachedData, cacheDataLoaded, cacheEntryRemoved }
        ) {
          try {
            // wait for the initial query to resolve before proceeding
            await cacheDataLoaded;

            if (stompClientInstance && stompClientInstance.connected) {
              unsubscribeChargingStations();
              const topics = [
                '/topic/chargingstation-created',
                '/topic/chargingstation-deleted',
              ];
              topics.forEach((topic) => {
                const sub = stompClientInstance?.subscribe(topic, (message) => {
                  if (message.body === undefined) return;

                  if (topic === '/topic/chargingstation-connector-updated') {
                    const connectorUpdate = JSON.parse(message.body);
                    const connector = connectorShadowNormalizer(
                      connectorUpdate.shadow
                    );
                    updateCachedData((draft) => {
                      const entity =
                        draft.entities[connectorUpdate.identityKey];
                      if (entity) {
                        const index = entity.connectors.findIndex(
                          (currentConnector) =>
                            currentConnector.connectorId ===
                            connector.connectorId
                        );
                        if (index !== -1) {
                          entity.connectors[index] = connector;
                        }
                      }
                    });
                    return;
                  }

                  if (topic === '/topic/chargingstation-deleted') {
                    const identityKey = JSON.parse(message.body).identityKey;
                    updateCachedData((draft) => {
                      chargingStationsAdapter.removeOne(draft, identityKey);
                    });
                    return;
                  }

                  if (topic === '/topic/chargingstation-created') {
                    const chargingStation = chargingStationsBrokerNormalizer(
                      JSON.parse(message.body)
                    ) as ChargingStationModel;
                    updateCachedData((draft) => {
                      chargingStationsAdapter.upsertOne(draft, chargingStation);
                    });
                  }
                });
                if (sub) {
                  chargingStationsSubs.push(sub);
                }
              });
            } else {
              log.error(
                'Stomp lost connection. Could not subscribe to charging station topics.'
              );
            }
          } catch {
            // no-op in case `cacheEntryRemoved` resolves before `cacheDataLoaded`,
            // in which case `cacheDataLoaded` will throw
          }
          // A Promise that allows you to wait for the point in time when the cache entry has been removed from the cache, by not being used/subscribed to any more in the application for too long or by dispatching api.util.resetApiState
          await cacheEntryRemoved;
          // perform cleanup steps once the `cacheEntryRemoved` promise resolves
          unsubscribeChargingStations();
        },
      }
    ),
    addChargePoint: builder.mutation<
      ChargingStationDto,
      BrokerAddChargePointForm
    >({
      query: (body) => ({
        url: 'chargingstations',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['ChargingStations'],
    }),
    deleteChargingStation: builder.mutation<ChargingStationDto, string>({
      query: (identityKey) => ({
        url: `chargingstations/${identityKey}`,
        method: 'DELETE',
      }),
      async onQueryStarted(identityKey, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(
            chargingStationsApi.util.updateQueryData(
              'getChargingStations',
              undefined,
              (draft) => {
                chargingStationsAdapter.removeOne(draft, identityKey);
              }
            )
          );
        } catch {}
      },
    }),
    getChargingStationDetails: builder.query<
      ChargingStationDetailsModel,
      { identityKey: string; listenToUpdates: boolean }
    >({
      providesTags: (result, _error, _arg) => [
        {
          type: 'ChargingStationDetails',
          id: result?.identityKey,
        },
      ],
      query: ({ identityKey }) => `chargingstations/${identityKey}`,
      transformResponse: (response: ChargingStationDto) => {
        return chargingStationDetailsNormalizer(response);
      },
      async onCacheEntryAdded(
        arg,
        { updateCachedData, cacheDataLoaded, cacheEntryRemoved }
      ) {
        unsubscribeChargingStationDetails(arg.identityKey);
        // For some business cases we dont need stomp, since it causes unnescesarry rerenders (example: form in configuration tab)
        // By default - stomp on CS details will not be connected, if needed - we should provide listenToUpdates - true.
        if (!arg.listenToUpdates) return;
        try {
          // wait for the initial query to resolve before proceeding
          await cacheDataLoaded;

          if (stompClientInstance && stompClientInstance.connected) {
            const topics = [
              `/topic/chargingstation-updated/${arg.identityKey}`,
              `/topic/chargingstation-shadow-updated/${arg.identityKey}`,
              `/topic/chargingstation-connector-updated/${arg.identityKey}`,
            ];
            topics.forEach((topic) => {
              const sub = stompClientInstance?.subscribe(topic, (message) => {
                if (message.body === undefined) return;
                if (
                  topic ===
                  `/topic/chargingstation-connector-updated/${arg.identityKey}`
                ) {
                  const connector = connectorShadowNormalizer(
                    JSON.parse(message.body).shadow
                  );
                  updateCachedData((draft) => {
                    const index = draft.connectors.findIndex(
                      (currentConnector) =>
                        currentConnector.connectorId === connector.connectorId
                    );
                    if (index !== -1) {
                      draft.connectors[index] = connector;
                    }
                  });
                  return;
                }
                if (
                  topic ===
                  `/topic/chargingstation-shadow-updated/${arg.identityKey}`
                ) {
                  const shadow = chargingStationBrokerShadowNormalizer(
                    JSON.parse(message.body)
                  );
                  updateCachedData((draft) => {
                    Object.entries({
                      identityKey: arg.identityKey,
                      ...shadow,
                    }).forEach(([key, value]) => {
                      (draft as Record<string, unknown>)[key] = value;
                    });
                  });
                } else {
                  const chargingStation = JSON.parse(message.body);
                  updateCachedData((draft) => {
                    Object.entries(
                      chargingStationBrokerDetailsNormalizer(chargingStation)
                    ).forEach(([key, value]) => {
                      (draft as Record<string, unknown>)[key] = value;
                    });
                  });
                }
              });
              subscribeToChargingStationDetails(arg.identityKey, sub);
            });
          } else {
            log.error(
              'Stomp lost connection. Could not subscribe to charging station details topics.'
            );
          }
        } catch {
          // no-op in case `cacheEntryRemoved` resolves before `cacheDataLoaded`,
          // in which case `cacheDataLoaded` will throw
        }
        // A Promise that allows you to wait for the point in time when the cache entry has been removed from the cache, by not being used/subscribed to any more in the application for too long or by dispatching api.util.resetApiState
        await cacheEntryRemoved;
        // perform cleanup steps once the `cacheEntryRemoved` promise resolves
        unsubscribeChargingStationDetails(arg.identityKey);
      },
    }),
    editChargingStation: builder.mutation<
      ChargingStationDto,
      BrokerEditChargingStationForm & Pick<ChargingStationDto, 'identityKey'>
    >({
      query: (body) => ({
        url: `chargingstations/${body.identityKey}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: (result, _error, arg) =>
        result
          ? [
              { type: 'ChargingStationDetails', id: arg.identityKey },
              'ChargingStations',
            ]
          : [],
    }),
    clearAuthorizationCache: builder.mutation<
      ClearAuthorizationCacheConfirmation,
      {
        identityKey: string;
      }
    >({
      query: (args) => ({
        url: `management/chargingstations/${args.identityKey}/clear-cache`,
        method: 'POST',
      }),
    }),
    clearAuthorizationCache201: builder.mutation<
      ClearAuthorizationCacheConfirmation,
      {
        identityKey: string;
      }
    >({
      query: (args) => ({
        url: `management/v201/chargingstations/${args.identityKey}/clearCache`,
        method: 'POST',
      }),
    }),
    editCoordinates: builder.mutation<
      ChangeCoordinatesConfirmation,
      {
        identityKey: string;
        chargingStationModel: string;
        chargingStationName: string;
        csmsUuid: string;
        coordinates: GeoLocation;
      }
    >({
      query: (args) => {
        const body: ChangeCoordinatesRequestBody = {
          chargingStationModel: args.chargingStationModel,
          chargingStationName: args.chargingStationName,
          csmsUuid: args.csmsUuid,
          coordinates: args.coordinates,
        };

        return {
          url: `chargingstations/${args.identityKey}`,
          method: 'PUT',
          body,
        };
      },
      invalidatesTags: (result, _error, arg) =>
        result ? [{ type: 'ChargingStationDetails', id: arg.identityKey }] : [],
    }),
    changeChargingStationAvailability: builder.mutation<
      ChangeAvailabilityConfirmation,
      {
        identityKey: string;
        availability: ChangeAvailabilityRequestOption;
        connectorId: number;
      }
    >({
      queryFn: async (args, { dispatch }, _, baseQuery) => {
        const body: ChangeAvailabilityRequestBody = {
          connectorId: args.connectorId,
          type: args.availability,
        };

        const { data, error } = await baseQuery({
          url: `management/chargingstations/${args.identityKey}/changeAvailability`,
          method: 'POST',
          body,
        });

        /**
         * After "change availability" endpoint is called and returns success, the station/connector does not update its status immediately.
         * We introduce a delay to allow the station to update its status before updating UI.
         *
         * This workaround will become redundant once realtime statuses are implemented for the station and connector.
         */
        await new Promise((r) => setTimeout(r, 7000));

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return { data, error } as any;
      },
      invalidatesTags: (result, _error, arg) =>
        result ? [{ type: 'ChargingStationDetails', id: arg.identityKey }] : [],
    }),
    changeChargingStationAvailability201: builder.mutation<
      ChangeAvailabilityConfirmation201,
      {
        identityKey: string;
        operationalStatus: ChangeAvailabilityRequestOption;
        evse: {
          id: number;
          connectorId?: number;
        };
      }
    >({
      query: (args) => {
        const body: ChangeAvailability201RequestBody = {
          evse: {
            id: args.evse.id,
            connectorId: args.evse.connectorId,
          },
          operationalStatus: args.operationalStatus,
        };

        return {
          url: `management/v201/chargingstations/${args.identityKey}/changeAvailability`,
          method: 'POST',
          body,
        };
      },
      invalidatesTags: (result, _error, arg) =>
        result ? [{ type: 'ChargingStationDetails', id: arg.identityKey }] : [],
    }),
    triggerMessage: builder.mutation<
      TriggerMessageConfirmation,
      {
        identityKey: string;
        requestedMessage: TriggerMessageRequestOption;
        connectorId?: number;
      }
    >({
      query: (args) => {
        const body: TriggerMessageRequestBody = {
          connectorId: args.connectorId,
          requestedMessage: args.requestedMessage,
        };

        return {
          url: `management/chargingstations/${args.identityKey}/triggerMessage`,
          method: 'POST',
          body,
        };
      },
    }),
    triggerMessage201: builder.mutation<
      TriggerMessageConfirmation,
      {
        identityKey: string;
        requestedMessage: TriggerMessageRequestOption201;
        evse: {
          id: number;
          connectorId?: number;
        };
      }
    >({
      query: (args) => {
        const body: TriggerMessage201RequestBody = {
          requestedMessage: args.requestedMessage,
          evse: {
            id: args.evse.id,
            connectorId: args.evse.connectorId,
          },
        };

        return {
          url: `management/v201/chargingstations/${args.identityKey}/triggerMessage`,
          method: 'POST',
          body,
        };
      },
    }),

    resetV201: builder.mutation<
      ResetV201Confirmation,
      {
        identityKey: string;
        type: ResetV201RequestOption;
        evseId?: number;
      }
    >({
      query: (args) => {
        const body: ResetV201RequestBody = {
          type: args.type,
          evseId: args.evseId,
        };

        return {
          url: `management/v201/chargingstations/${args.identityKey}/reset`,
          method: 'POST',
          body,
        };
      },
    }),

    editConnector: builder.mutation<
      void,
      {
        data: EditConnectorRequest;
        identityKey: string;
        connectorId: number;
      }
    >({
      query: ({ data, identityKey, connectorId }) => {
        return {
          url: `/connectors/${identityKey}/${connectorId}`,
          method: 'PUT',
          body: data,
        };
      },
      invalidatesTags: (result, _error, arg) =>
        result ? [{ type: 'ChargingStationDetails', id: arg.identityKey }] : [],
    }),
    doesEvseIdExist: builder.query<boolean, string>({
      query: (evseId) => `connectors/exists/${evseId}`,
    }),
  }),
});

const selectChargingStations =
  chargingStationsApi.endpoints.getChargingStations.select();

const selectAllChargingStations = createSelector(
  selectChargingStations,
  (chargingStations) => chargingStations?.data ?? initialState
);

export const { selectAll: selectAllStations } =
  chargingStationsAdapter.getSelectors(
    //@ts-expect-error state is of type RootState - cannot import to avoid circular dependency
    (state) => selectAllChargingStations(state) ?? initialState
  );

export const {
  useGetChargingStationsQuery,
  useAddChargePointMutation,
  useDeleteChargingStationMutation,
  useEditChargingStationMutation,
  useEditCoordinatesMutation,
  useGetChargingStationDetailsQuery,
  useClearAuthorizationCacheMutation,
  useClearAuthorizationCache201Mutation,
  useChangeChargingStationAvailabilityMutation,
  useChangeChargingStationAvailability201Mutation,
  useTriggerMessageMutation,
  useTriggerMessage201Mutation,
  useResetV201Mutation,
  useEditConnectorMutation,
  useDoesEvseIdExistQuery,
} = chargingStationsApi;
