import { RefetchUpdatesButton, useStompTopic } from '@energy-stacks/shared';
import { useEffect, useState } from 'react';

type RefetchAlertsButtonProps = {
  testId?: string;
  onRefetch: () => void;
  isFetching: boolean;
};

export const RefetchAlertsButton: React.FC<RefetchAlertsButtonProps> = ({
  onRefetch,
  isFetching,
  testId,
}) => {
  const [hasNewAlerts, setHasNewAlerts] = useState(false);

  useStompTopic(`/topic/alert-created`, () => {
    setHasNewAlerts(true);
  });
  useStompTopic(`/topic/alert-updated`, () => {
    setHasNewAlerts(true);
  });

  useEffect(() => {
    if (!isFetching) {
      setHasNewAlerts(false);
    }
  }, [isFetching]);

  return hasNewAlerts ? (
    <RefetchUpdatesButton
      testId={`${testId}RefetchButton`}
      onClick={onRefetch}
      label="!"
      isFetching={isFetching}
    />
  ) : null;
};
