import { GetOwnerEntityDto } from '../ownerEntityDto';
import { OwnerEntityModel } from '../ownerEntityModel';

export const ownerEntityNormalizer = (
  ownerEntityDto: GetOwnerEntityDto
): OwnerEntityModel[] => {
  return ownerEntityDto.ownerUserLocationItemsList.map((entity) => ({
    contactPerson: entity.contactPerson ?? 'N/A',
    locations: entity.locations ?? 'N/A',
    ownerId: entity.ownerId ?? 'N/A',
    ownerName: entity.ownerName ?? 'N/A',
    phoneNumber: entity.phoneNumber ?? 'N/A',
    users: entity.users ?? 'N/A',
  }));
};
