import React, { useMemo } from 'react';
import { formatDateTime, toPayloadDate } from '@energy-stacks/shared';
import CardWidget from '../CardWidget';
import { useGetTransactionTimeSlicesQuery } from '@energy-stacks/broker/feature-dashboard-data';
import { Props } from 'react-apexcharts';
import { IconActivity } from '@tabler/icons-react';
import { useTranslation } from 'react-i18next';

export const SuccessRateWidget = () => {
  const { t } = useTranslation('dashboard');
  const last24hr = new Date(new Date().getTime() - 24 * 60 * 60 * 1000);
  const [chosenDate, setChosenDate] = React.useState<Date>(last24hr);
  const { data, isLoading } = useGetTransactionTimeSlicesQuery(
    {
      dateStart: toPayloadDate(chosenDate),
    },
    { pollingInterval: 1000 * 30 }
  );

  const lastTimeSlice = data?.slice(-1)[0];

  const successRate = useMemo(() => {
    if (!lastTimeSlice) return 0;

    const { issueTransactionsLast30Days, completedTransactionsLast30Days } =
      lastTimeSlice;

    if (
      issueTransactionsLast30Days === 0 &&
      completedTransactionsLast30Days === 0
    ) {
      return 0;
    }

    return (
      (1 - issueTransactionsLast30Days / completedTransactionsLast30Days) *
        100 || 0
    );
  }, [lastTimeSlice]);

  const plottedSuccessRate = useMemo(() => {
    if (!data) return [];

    return data.map((slice) => {
      const {
        issueTransactionsLast30Days,
        completedTransactionsLast30Days,
        dateSlice,
      } = slice;

      if (
        issueTransactionsLast30Days === 0 &&
        completedTransactionsLast30Days === 0
      ) {
        return {
          rate: 0,
          timestamp: formatDateTime(dateSlice),
        };
      }

      const successRate =
        (1 - issueTransactionsLast30Days / completedTransactionsLast30Days) *
          100 || 0;

      return {
        rate: Math.round(successRate),
        timestamp: formatDateTime(dateSlice),
      };
    });
  }, [data]);

  const chartData: Props = {
    type: 'line',
    height: 90,
    options: {
      chart: {
        animations: {
          enabled: false,
        },
        sparkline: {
          enabled: true,
        },
      },
      dataLabels: {
        enabled: false,
      },
      grid: {
        padding: {
          top: 7,
          bottom: 7,
          left: 7,
          right: 7,
        },
      },
      colors: ['#fff'],
      fill: {
        type: 'solid',
        opacity: 1,
      },
      stroke: {
        curve: 'smooth',
        width: 3,
      },
      yaxis: {
        min: 0,
        max: 100,
      },
      tooltip: {
        theme: 'dark',
        fixed: {
          enabled: false,
        },
        x: {
          show: true,
        },
        y: {
          title: {
            formatter: (seriesName: string) => seriesName,
          },
          formatter: (val: number) => val + '%',
        },
        marker: {
          show: false,
        },
      },
    },
    series: [
      {
        name: t('successRate'),
        data: plottedSuccessRate.map((successRate) => {
          return {
            x: successRate.timestamp,
            y: successRate.rate,
          };
        }),
      },
    ],
  };

  return (
    <CardWidget
      chartData={chartData}
      color="blue"
      isLoading={isLoading}
      value={Math.round(successRate) + '%'}
      name={t('chargingSuccessRate')}
      icon={<IconActivity />}
      onDateChange={setChosenDate}
    />
  );
};
