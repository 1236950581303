import { toPayloadDate } from '@energy-stacks/shared';
import { CreateTourPayload } from './createTourPayload';
import { OptimizedTourModel } from './optimizedTourModel';
import { transitionsToPayload } from './transitionsToPayload';

export const createToursDataToPayload = (
  tours: OptimizedTourModel[]
): CreateTourPayload[] => {
  return tours.map((tour) => ({
    vehicleIdentificationNumber: tour.vehicleId,
    endDate: toPayloadDate(new Date(tour.endDate)),
    startDate: toPayloadDate(new Date(tour.startDate)),
    polyline: tour.polyline,
    totalTime: tour.duration,
    totalDistance: tour.distance,
    jobs: tour.tourJobs.jobs.map((job) => {
      return {
        jobUid: job.jobId,
        startDate: toPayloadDate(new Date(job.startTime)),
        endDate: toPayloadDate(new Date(job.endTime)),
      };
    }),
    vehicleBreak: tour.tourJobs.processes
      .filter((process) => process.type === 'BREAK')
      .map((process) => ({
        jobUid: process.id,
        startDateTime: toPayloadDate(new Date(process.startTime)),
        endDateTime: toPayloadDate(new Date(process.endTime)),
      }))[0],
    transitions: transitionsToPayload(tour.transitions),
  }));
};
