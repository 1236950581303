import { ESTextField, ESVirtualizedAutocomplete } from '@energy-stacks/core/ui';
import { stringEnumToArray } from '@energy-stacks/shared';
import { Box, Grid, IconButton, Typography, useTheme } from '@mui/material';
import { IconX } from '@tabler/icons-react';
import React, { useEffect, useMemo, useState } from 'react';
import {
  Controller,
  UseFieldArrayRemove,
  useFormContext,
} from 'react-hook-form';
import {
  connectorPowerTypes,
  useDoesEvseIdExistQuery,
} from '@energy-stacks/broker/feature-charging-stations-data';
import { useTranslation } from 'react-i18next';
import { ConnectorStandard } from '@energy-stacks/data';
import { useGetUiBrandingQuery } from '@energy-stacks/broker/feature-settings-data';
import { debounce } from 'lodash-es';

enum ConnectorFormatEnum {
  socket = 'Socket',
  cable = 'Cable',
}

interface BrokerAddChargingStationConnectorItemProps {
  connectorNumber: number;
  onRemove: UseFieldArrayRemove;
  connectorIndex: number;
  testId?: string;
}

export const BrokerAddChargingStationConnectorItem: React.FC<
  BrokerAddChargingStationConnectorItemProps
> = ({ connectorIndex, onRemove, connectorNumber, testId }) => {
  const { t } = useTranslation('chargingStations');
  const { palette } = useTheme();
  const {
    getValues,
    control,
    formState: { errors },
  } = useFormContext();
  const values = getValues();
  const { data: uiBranding } = useGetUiBrandingQuery();
  const hasBorderRadius =
    uiBranding?.hasBorderRadius || uiBranding?.hasBorderRadius === undefined;

  const [evseId, setEvseId] = useState('');
  const { data: evseIdExists } = useDoesEvseIdExistQuery(evseId, {
    skip: evseId === '',
  });

  useEffect(() => {
    if (values['connectors'][connectorIndex].evseId !== '' && evseId === '') {
      setEvseId(values['connectors'][connectorIndex].evseId);
    }
  }, [connectorIndex, evseId, values]);

  const debouncedOnChange = useMemo(
    () =>
      debounce((e) => {
        setEvseId(e.target.value);
      }, 300),
    []
  );

  return (
    <Box
      borderRadius={hasBorderRadius ? 3 : 0}
      sx={{
        border: `1px solid ${palette.grey[300]}`,
      }}
    >
      <Box
        sx={{
          borderBottom: `1px solid ${palette.grey[300]}`,
          px: 4,
          py: 1,
        }}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <Typography variant="h5" sx={{ fontWeight: 'bold' }}>
          {t('connector')} {connectorNumber}
        </Typography>
        <IconButton onClick={() => onRemove(connectorIndex)}>
          <IconX />
        </IconButton>
      </Box>
      <Box
        sx={{
          px: 4,
          py: 6,
        }}
      >
        <Box mb={4}>
          <Controller
            control={control}
            rules={{ required: true }}
            render={({ field: { onChange, onBlur, value } }) => {
              return (
                <ESTextField
                  label={t('evseId')}
                  placeholder={t('evseIdPlaceholder')}
                  type="text"
                  onChange={(e) => {
                    onChange(e);
                    debouncedOnChange(e);
                  }}
                  onBlur={onBlur}
                  error={
                    Boolean(errors['connectors']?.[connectorIndex]?.evseId) ||
                    (evseIdExists && evseId !== '')
                  }
                  helperText={
                    (evseIdExists && evseId !== ''
                      ? t('evseIdAlreadyTaken')
                      : null) ||
                    (Boolean(errors['connectors']?.[connectorIndex]?.evseId) &&
                      t(
                        `${errors['connectors']?.[connectorIndex]?.evseId?.message}`,
                        {
                          value: t('evseId'),
                        }
                      ))
                  }
                  value={value}
                  autoFocus={values['connectors'].length === connectorNumber}
                  inputProps={{
                    'data-testid': `${testId}EvseIdTextField`,
                  }}
                />
              );
            }}
            name={`connectors.${connectorIndex}.evseId`}
          />
        </Box>

        <Grid container spacing={4}>
          <Grid item xs={6}>
            <Box>
              <Controller
                control={control}
                rules={{ required: true }}
                render={({ field: { onChange, onBlur, value } }) => {
                  return (
                    <ESVirtualizedAutocomplete
                      data-testid={`${testId}TypeTextField`}
                      testId={`${testId}TypeTextField`}
                      value={{
                        value: value.value ?? '',
                        label: value.label ?? '',
                      }}
                      label={t('type')}
                      options={stringEnumToArray(ConnectorStandard).map(
                        (v) => ({
                          label: t(`connectorTypes.${v}`),
                          value: v,
                        })
                      )}
                      onBlur={onBlur}
                      onChange={(_, value) => {
                        if (Array.isArray(value)) {
                          return;
                        }
                        if (value) {
                          onChange({
                            value: value.value ?? '',
                            label: value.label ?? '',
                          });
                        }
                      }}
                      noOptionsText={t('noConnectorType')}
                    />
                  );
                }}
                name={`connectors.${connectorIndex}.type`}
              />
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box>
              <Controller
                control={control}
                rules={{ required: true }}
                render={({ field: { onChange, onBlur, value } }) => {
                  return (
                    <ESVirtualizedAutocomplete
                      data-testid={`${testId}FormatTextField`}
                      testId={`${testId}FormatTextField`}
                      value={{ value: value.value, label: value.label }}
                      label={t('connectorFormat')}
                      options={stringEnumToArray(ConnectorFormatEnum).map(
                        (v) => ({
                          value: v,
                          label: v,
                        })
                      )}
                      onBlur={onBlur}
                      onChange={(_, value) => {
                        if (Array.isArray(value)) {
                          return;
                        }
                        if (value) {
                          onChange({
                            value: value.value ?? '',
                            label: value.label ?? '',
                          });
                        }
                      }}
                      noOptionsText={t('noConnectorFormat')}
                    />
                  );
                }}
                name={`connectors.${connectorIndex}.connectorFormat`}
              />
            </Box>
          </Grid>

          <Grid item xs={6}>
            <Box>
              <Controller
                control={control}
                rules={{ required: true }}
                render={({ field: { onChange, onBlur, value } }) => {
                  return (
                    <ESVirtualizedAutocomplete
                      data-testid={`${testId}PowerTypeTextField`}
                      testId={`${testId}PowerTypeTextField`}
                      value={{ value: value.value, label: value.label }}
                      label={t('powerType')}
                      options={connectorPowerTypes.map((type) => ({
                        label: t(`powerTypes.${type}`),
                        value: type,
                      }))}
                      onBlur={onBlur}
                      onChange={(_, value) => {
                        if (Array.isArray(value)) {
                          return;
                        }
                        if (value) {
                          onChange({
                            value: value.value ?? '',
                            label: value.label ?? '',
                          });
                        }
                      }}
                      noOptionsText={t('noConnectorPowerType')}
                    />
                  );
                }}
                name={`connectors.${connectorIndex}.powerType`}
              />
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box>
              <Controller
                control={control}
                rules={{ required: true }}
                render={({ field: { onChange, onBlur, value } }) => {
                  return (
                    <ESTextField
                      inputProps={{
                        'data-testid': `${testId}MaxVoltageTextField`,
                      }}
                      label={t('voltage')}
                      type="text"
                      onChange={onChange}
                      onBlur={onBlur}
                      error={Boolean(
                        errors['connectors']?.[connectorIndex]?.maxVoltage
                      )}
                      helperText={
                        Boolean(
                          errors['connectors']?.[connectorIndex]?.maxVoltage
                        ) &&
                        t(
                          `${errors['connectors']?.[connectorIndex]?.maxVoltage?.message}`,
                          {
                            value: 'Max voltage',
                          }
                        )
                      }
                      value={value}
                    />
                  );
                }}
                name={`connectors.${connectorIndex}.maxVoltage`}
              />
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box>
              <Controller
                control={control}
                rules={{ required: true }}
                render={({ field: { onChange, onBlur, value } }) => {
                  return (
                    <ESTextField
                      inputProps={{
                        'data-testid': `${testId}MaxAmperageTextField`,
                      }}
                      label={t('maxAmperage')}
                      type="text"
                      onChange={onChange}
                      onBlur={onBlur}
                      error={Boolean(
                        errors['connectors']?.[connectorIndex]?.maxAmperage
                      )}
                      helperText={
                        Boolean(
                          errors['connectors']?.[connectorIndex]?.maxAmperage
                        ) &&
                        t(
                          `${errors['connectors']?.[connectorIndex]?.maxAmperage?.message}`,
                          {
                            value: 'Max amperage',
                          }
                        )
                      }
                      value={value}
                    />
                  );
                }}
                name={`connectors.${connectorIndex}.maxAmperage`}
              />
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box>
              <Controller
                control={control}
                rules={{ required: true }}
                render={({ field: { onChange, onBlur, value } }) => {
                  return (
                    <ESTextField
                      inputProps={{
                        'data-testid': `${testId}MaxPowerTextField`,
                      }}
                      label={t('maxPower')}
                      type="text"
                      onChange={onChange}
                      onBlur={onBlur}
                      error={Boolean(
                        errors['connectors']?.[connectorIndex]?.maxPower
                      )}
                      helperText={
                        Boolean(
                          errors['connectors']?.[connectorIndex]?.maxPower
                        ) &&
                        t(
                          `${errors['connectors']?.[connectorIndex]?.maxPower?.message}`,
                          {
                            value: 'Max power',
                          }
                        )
                      }
                      value={value}
                    />
                  );
                }}
                name={`connectors.${connectorIndex}.maxPower`}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};
